<div class="card">
    <div class="row">
        <div class="col-xl-4 col-lg-6">
            <div class="card-body">
                <h4 class="card-title">Reviews</h4>
                <h5 class="card-subtitle">Overview of Review</h5>
                <h2 class="font-medium m-t-40 m-b-0">25426</h2>
                <span class="text-muted">This month we got 346 New Reviews</span>
                <div class="image-box m-t-30 m-b-30">
                    <a href="#" class="m-r-10" data-toggle="tooltip" data-placement="top" title="" data-original-title="Simmons">
                        <img src="assets/images/users/1.jpg" class="rounded-circle" width="45" alt="user">
                    </a>
                    <a href="#" class="m-r-10" data-toggle="tooltip" data-placement="top" title="" data-original-title="Fitz">
                        <img src="assets/images/users/2.jpg" class="rounded-circle" width="45" alt="user">
                    </a>
                    <a href="#" class="m-r-10" data-toggle="tooltip" data-placement="top" title="" data-original-title="Phil">
                        <img src="assets/images/users/3.jpg" class="rounded-circle" width="45" alt="user">
                    </a>
                    <a href="#" class="m-r-10" data-toggle="tooltip" data-placement="top" title="" data-original-title="Melinda">
                        <img src="assets/images/users/4.jpg" class="rounded-circle" width="45" alt="user">
                    </a>
                </div>
                <a href="javascript:void(0)" class="btn btn-lg btn-info waves-effect waves-light">Checkout All Reviews</a>
            </div>
        </div>
        <div class="col-xl-8 col-lg-6 border-left">
            <div class="card-body">
                <ul class="list-style-none">
                    <li class="m-t-30">
                        <div class="d-flex align-items-center">
                            <i class="mdi mdi-emoticon-happy display-5 text-muted"></i>
                            <div class="m-l-10">
                                <h5 class="m-b-0">Positive Reviews</h5>
                                <span class="text-muted">25547 Reviews</span>
                            </div>
                        </div>
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 47%" aria-valuenow="47" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </li>
                    <li class="m-t-40">
                        <div class="d-flex align-items-center">
                            <i class="mdi mdi-emoticon-sad display-5 text-muted"></i>
                            <div class="m-l-10">
                                <h5 class="m-b-0">Negative Reviews</h5>
                                <span class="text-muted">5547 Reviews</span>
                            </div>
                        </div>
                        <div class="progress">
                            <div class="progress-bar bg-orange" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </li>
                    <li class="m-t-40 m-b-40">
                        <div class="d-flex align-items-center">
                            <i class="mdi mdi-emoticon-neutral display-5 text-muted"></i>
                            <div class="m-l-10">
                                <h5 class="m-b-0">Neutral Reviews</h5>
                                <span class="text-muted">547 Reviews</span>
                            </div>
                        </div>
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>