<div class="leave-planning-wrapper">
  <div class="leave-planning-grid-wrapper">
    <div>
    </div>
    <div>
      <label>Jauary</label>
    </div>
    <div>
      <label>February</label>
    </div>
    <div>
      <label>March</label>
    </div>
    <div>
      <label>April</label>
    </div>
    <div>
      <label>May</label>
    </div>
    <div>
      <label>June</label>
    </div>
    <div>
      <label>July</label>
    </div>
    <div>
      <label>August</label>
    </div>
    <div>
      <label>September</label>
    </div>
    <div>
      <label>October</label>
    </div>
    <div>
      <label>November</label>
    </div>
    <div>
      <label>December</label>
    </div>

    <!--<div>
      <label>Mohommad</label>
    </div>
    <div></div>
    <div class="blue-section start">
      <div></div>
    </div>
    <div class="blue-section">
      <div></div>
    </div>
    <div class="blue-section end">
      <div></div>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>

    <div>
      <label>John</label>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div class="red-section"></div>
    <div class="red-section"></div>
    <div class="red-section"></div>
    <div class="red-section"></div>
    <div class="red-section"></div>
    <div></div>

    <div>
      <label>Heshan</label>
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div class="purple-section"></div>
    <div></div>
    <div></div>-->

    <ng-container *ngFor="let leavePlan of leavePlanFilteredList">
      <div>
        <label>{{leavePlan?.leaveData?.employee}}</label>
      </div>
      <ng-container *ngFor="let monthSec of leavePlan?.monthSectionArr">
        <ng-container *ngIf="monthSec?.enableVisual === 1">
          <ng-container *ngIf="monthSec?.startAndEndMonth !== 1">
            <ng-container *ngIf="monthSec?.startOrMidOrEndMonth === 0">
              <div class="blue-section start">
                <div style="width: {{monthSec?.startMonthPercentage}}%; background-color: #{{leavePlan?.randColor}};"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="monthSec?.startOrMidOrEndMonth === 1">
              <div class="blue-section">
                <div style="width: 100%; background-color: #{{monthSec?.randColor}};"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="monthSec?.startOrMidOrEndMonth === 2">
              <div class="blue-section end">
                <div style="width: {{monthSec?.endMonthPercentage}}%; background-color: #{{leavePlan?.randColor}};"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="monthSec?.startOrMidOrEndMonth === 3">
              <div>
                <div></div>
              </div>
            </ng-container>
            <ng-container *ngIf="monthSec?.startOrMidOrEndMonth === 4">
              <div>
                <div></div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="monthSec?.startAndEndMonth === 1">
            <div class="blue-section">
              <div style="width: {{monthSec?.middlePercentage}}%; margin-left: {{monthSec?.startingDateMarginPercentage}}%; background-color: #{{leavePlan?.randColor}};"></div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="monthSec?.enableVisual !== 1">
          <div>
            <div></div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>
</div>
