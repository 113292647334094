<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/big/auth-bg.jpg) no-repeat center center;">
    <div class="auth-box">
        <div id="loginform">
            <div class="logo">
                <span class="db"><img src="assets/images/login-logo.png" class="login-logo" alt="logo" /></span>
                <h5 class="font-medium m-b-20">Sign In to Admin</h5>
            </div>

            <div *ngIf="showFlag">
                <div class="row">
                    <input class="form-control" type="number" [(ngModel)]="employeeNo"  placeholder="Employee Number">
                </div>

                <div class="row mt-3">
                    <button (click)="nextStep()" class="btn btn-primary" [disabled]="isLoginDisabled"> Login </button>
                </div>
            </div>

            <div *ngIf="!showFlag">
                <div class="row">
                    <input class="form-control" type="text" [(ngModel)]="verificationCode" placeholder="Verification Code">
                </div>

                <div class="row mt-3">
                    <button class="btn btn-primary" (click)="loginByEmpNo()" [disabled] ="isProceedDisabled"> Continue </button>
                </div>
            </div>
            
        </div>    
    </div>
</div>

