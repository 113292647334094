import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(
      public authService: AuthService,
      public http: HttpClient
  ) {
  }

  login(data): Observable<any> {
    return this.authService.login(data);
  }

  tryGenerateOTP(data): Observable<boolean> {
    return this.authService.tryGenerateOTP(data);
  }

  getUser(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(environment.host + '/' + 'api/users', httpOptions);
  }

  getCompanies(UserCode: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(environment.host + '/' + `api/companies-by-user?userCode=${UserCode}`, httpOptions);
  }


  generateOTP(chanel: string, userName: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('userName', userName);
    params = params.append('chanel', chanel);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };
    return this.http.get(environment.host + '/api/otp-generate', httpOptions);
  }

  validateOTP(otp: string, userName: string, compCode: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('userName', userName);
    params = params.append('otp', otp);
    params = params.append('compCode', compCode);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };
    return this.http.get(environment.host + '/api/otp-validate', httpOptions);
  }

  validateEmployeeNo(empno: number): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
    };
    return this.http.get(environment.host + '/api/authenticate/validate-empno?empno=' + empno, httpOptions);
  }

  loginByEmpNoAndCompCode(empno: number, compCode: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
    };
    return this.http.get(environment.host + '/api/authenticate/loginByEmpNoAndCompCode?empNo=' + empno + '&compCode=' + compCode, httpOptions);
  }

  loginByEmpNo(empNo: number, verificationCode: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
    };
    return this.http.get(environment.host + '/api/authenticate/login-by-empno?empno=' + empNo + '&otp=' + verificationCode, httpOptions);
  }

  getRoles(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
    };
    return this.http.get(environment.host + '/api/roles', httpOptions);
  }
}
