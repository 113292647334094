import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HrDocumentRouting} from './hrdocument.routing';
import {HrdocrequestComponent} from './hrdocrequest/hrdocrequest.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {HrdocapprovalComponent} from './hrdocapproval/hrdocapproval.component';
import {ListComponent} from './list/list.component';
import {DataTablesModule} from 'angular-datatables';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(HrDocumentRouting),
    NgbModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    NgxDatatableModule,
    DataTablesModule,
    MatPaginatorModule
  ],
  declarations: [
    HrdocrequestComponent,
    HrdocapprovalComponent,
    ListComponent
  ]
})
export class HrdocumentModule {}
