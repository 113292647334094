<br>
<div class="col-md-12 p-5 shadowdiv">
            <div id="JVSelector" *ngIf="!show">
                <br>
                <div class="row">
        
                    <div class="col-sm">
                        <h4> Select Period and Voucher </h4>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"> <label> Period </label> </div>
        
                    <div class="col-sm">
                        <select class=" form-control ">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
        
                    <div class="col-sm">
                        <input class=" form-control ">
                    </div>
        
                    <div class="col-sm">
                        <input class=" form-control ">
                    </div>
                </div>
                <br>
        
                <div class="row">
                    <div class="col-3"> <label> Voucher Period </label> </div>
        
                    <div class="col">
                        <select class=" form-control ">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
        
                </div>
        
                <br>
        
                <div class="row">
                    <div class="col">
                        <button class="btn btn-primary mr-3" (click)="show=!show" > OK </button>
                        <button class="btn btn-primary"> SAVE </button>
                    </div>
        
        
                </div>
        
            </div>
            <div *ngIf="show">
                <div class="">
                    <h4>Cash Receipt Voucher</h4>
                    <hr />
                </div>
                <div class="card-body">
                    <form [formGroup]="cashreceipt">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Document Number
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <input type="text" formControlName="Document1" class="form-control">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="text" formControlName="Document2" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Cash GL Code
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <input type="text" formControlName="CashGLCode" class="form-control">
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" disabled class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        SL Code
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <input type="text" formControlName="SLCode" class="form-control">
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" disabled class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Reference
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="Reference" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Being
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="Being" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Recieved From
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <input type="text" formControlName="RecievedFrom" class="form-control">
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="row">
                                                    <div class="col-sm-5">
                                                        Amount
                                                    </div>
                                                    <div class="col-sm-7">
                                                        <input type="text" formControlName="Amount"
                                                            class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="row mt-2">
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <input type="checkbox" disabled
                                                    class="form-check-input">
                                            </div>
                                            <div class="col-sm-9">
                                                Rejected
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <input type="checkbox" disabled
                                                    class="form-check-input">
                                            </div>
                                            <div class="col-sm-9">
                                                Passed
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <input type="checkbox" disabled
                                                    class="form-check-input">
                                            </div>
                                            <div class="col-sm-9">
                                                Confirmed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Date
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="Date" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Cost Center
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="CostCenter1" class="form-control">
                                        <input type="text" formControlName="CostCenter2" class="form-control mt-2">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3">
                                        Ref. Date
                                    </div>
                                    <div class="col-sm-5">
                                        <input type="text" formControlName="RefDate" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                Currency
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="text" formControlName="Currency" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <button class="btn btn-primary">
                                            Settlement
                                        </button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                Exchange Rate
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="text" formControlName="ExchangeRate" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <button class="btn btn-primary">
                                            Emo Loan
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-1">
                                <button class="btn btn-success mt-6">
                                    Pass
                                </button>
                            </div>
                        </div>
                    </form>
                    <br />
                    <br />
                    <hr />
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    SN
                                </div>
                                <div class="col-sm-8">
                                    GL Code
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div style="overflow: auto;">
                                <table class="table table-bordered">
                                    <thead>
                                        <th>Dr. Amount</th>
                                        <th>Cr. Amount</th>
                                        <th>Costcenter</th>
                                        <th>GL Code</th>
                                        <th>SL Code</th>
                                        <th>Bank Code</th>
                                        <th>Invoice No.</th>
                                        <th>Invoice Date</th>
                                        <th>Description</th>
                                        <th>Ship No.</th>
                                        <th>Ship Exp Code</th>
                                        <th>Asset Exp</th>
                                        <th>Due Date</th>
                                        <th>LPO No</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <form [formGroup]="cashreceipt1">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        GL Desc
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="GLDesc" form class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        Branch
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <input type="text" formControlName="Branch" class="form-control">
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" disabled class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        Cost Desc
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="CostDesc" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        BOQ Desc
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="BOQDesc" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        Category Desc
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="CategoryDesc" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-4 mt-2">
                                        Asset Run Cost
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" formControlName="AssetRunCost" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row mt-2">
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-sm-6 mt-2">
                                                Settled Amount
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="text" formControlName="SettledAmount" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row">
                                            <div class="col-sm-6 mt-2">
                                                Area
                                            </div>
                                            <div class="col-sm-6">
                                                <input type="text" formControlName="Area" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3 mt-2">
                                        Cost centre
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="Costcentre" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3 mt-2">
                                        Exp Desc.
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="ExpDesc" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3 mt-2">
                                        Bank GL
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="BankGL" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3 mt-2">
                                        Bank SL
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="BankSL" class="form-control">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-sm-3 mt-2">
                                        Bank Desc
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" formControlName="BankDesc" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
