import { Routes } from '@angular/router';
import {DashboardMComponent} from './dashboard-mView/dashboard-m.component';
import {DashboardMAuthGuardService} from './dashboard-m-auth-guard.service';



export const DashboardMRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'dashboardM',
        component: DashboardMComponent,
        canActivate: [DashboardMAuthGuardService]
      }
    ]
  }
];
