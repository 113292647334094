import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EmployeeEnquiryRouting } from './erp.routing'
import { FormsModule, FormBuilder,ReactiveFormsModule  } from '@angular/forms'
import { DataTablesModule } from "angular-datatables";
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';

import { GenralledgerComponent } from './genralledger/genralledger.component';
import { InventoryPricingComponent } from './inventorypricing/inventorypricing.component';
import { JournalvoucherComponent } from './journalvoucher/journalvoucher.component';
import { BankpaymentComponent } from './bankpayment/bankpayment.component';
import { CashpaymentComponent } from './cashpayment/cashpayment.component';
import { BankreceiptComponent } from './bankreceipt/bankreceipt.component';
import { CashapprovalComponent } from './cashapproval/cashapproval.component';
import { CashreceiptComponent } from './cashreceipt/cashreceipt.component';
import { CashpaymentapprovalComponent } from './cashpaymentapproval/cashpaymentapproval.component';
import { MaterialComponent } from './material/material.component';

@NgModule({
  declarations: [GenralledgerComponent, InventoryPricingComponent, JournalvoucherComponent, BankpaymentComponent, CashpaymentComponent, BankreceiptComponent, CashapprovalComponent, CashreceiptComponent, CashpaymentapprovalComponent, MaterialComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(EmployeeEnquiryRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [ ]
})
export class ErpModule { }
