import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {UomService} from '../../shared/service/uom.service';

@Component({
  selector: 'app-storebinlocations',
  templateUrl: './storebinlocations.component.html',
  styleUrls: ['./storebinlocations.component.css']
})
export class StorebinlocationsComponent implements OnInit {

  rows = [];
  columns = [{ prop: 'binLocation', name: 'Bin Location' }, { prop: 'code', name: 'Bin Location Code' }, { prop: 'description', name: 'Store Master Description' }, { prop: 'createdBy', name: 'Created By' }, {name: 'Actions', width: 50}];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(
    private uomService: UomService
  ) { }

  ngOnInit(): void {
    this.uomService.getAllStoreBinLocations().subscribe(uomList => {
      this.rows = uomList;
    });
  }

}
