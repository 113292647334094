import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {UomService} from '../../shared/service/uom.service';

@Component({
  selector: 'app-othercharges',
  templateUrl: './othercharges.component.html',
  styleUrls: ['./othercharges.component.css']
})
export class OtherchargesComponent implements OnInit {

  rows = [];
  columns = [{ prop: 'code', name: 'Code' }, { prop: 'description', name: 'Description' }, { prop: 'updatedBy', name: 'Updated By' }, {name: 'Actions', width: 50}];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(
    private uomService: UomService
  ) { }

  ngOnInit(): void {
    this.uomService.getAllOtherCharges().subscribe(otherChargesList => {
      console.log(otherChargesList);
      this.rows = otherChargesList;
    });
  }

}
