import { Component, OnInit } from '@angular/core';
import {ChartsService} from '../../shared/service/charts.service';
import * as HighCharts from 'highcharts';

@Component({
  selector: 'app-employeesalarytotal',
  templateUrl: './employeesalarytotal.component.html',
  styleUrls: ['./employeesalarytotal.component.css']
})
export class EmployeesalarytotalComponent implements OnInit {

  chart: any;

  constructor(
    private chartsService: ChartsService
  ) { }

  ngOnInit(): void {
    this.chartsService.getBySalaryTotal().subscribe(chartData => {
      console.log(chartData);
      if (chartData && chartData.length > 0) {
        HighCharts.chart('salaryTotalChart', {
          chart: {
            type: 'column'
          },
          title: {
            text: 'Employee Salary By Total'
          },
          xAxis: {
            type: 'category',
            labels: {
              rotation: -45,
              style: {
                fontSize: '13px',
                fontFamily: 'Verdana, sans-serif'
              }
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Employees'
            }
          },
          legend: {
            enabled: false
          },
          series: [{
            name: 'Employees',
            type: 'column',
            data: chartData,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              format: '{point.y:.1f}',
              y: 1
            }
          }]
        });
        
      }
    });
  }

}
