<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="card-group">
                    <div class="card m-t-30 border-top border-left">
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-10">Silver</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">$</span>24
                            </h2>
                            <p>Per Month</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20">
                                    <i class="ti-user m-r-5"></i>3 Members
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-mobile m-r-5"></i>Single Device
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-server m-r-5"></i>50GB Storage
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-reload m-r-5"></i>Monthly Backups
                                </li>
                                <li class="border-bottom p-20">
                                    <button class="btn btn-success waves-effect waves-light">Sign up</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-top m-t-30">
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-10 text-center">Gold</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">$</span>34
                            </h2>
                            <p>Per Month</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20">
                                    <i class="ti-user m-r-5"></i>5 Members
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-mobile m-r-5"></i>Single Device
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-server m-r-5"></i>80GB Storage
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-reload m-r-5"></i>Monthly Backups
                                </li>
                                <li class="border-bottom p-20">
                                    <button class="btn btn-success waves-effect waves-light">Sign up</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card bg-light border-top border-left">
                        <h5 class="price-label text-white text-center bg-info">Popular</h5>
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-40 text-center">Platinum</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">$</span>45
                            </h2>
                            <p>Per Month</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20">
                                    <i class="ti-user m-r-5"></i>10 Members
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-mobile m-r-5"></i>Single Device
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-server m-r-5"></i>120GB Storage
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-reload m-r-5"></i>Monthly Backups
                                </li>
                                <li class="border-bottom p-20">
                                    <button class="btn btn-lg btn-info waves-effect waves-light">Sign up</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card border-top m-t-30">
                        <div class="p-15 text-center">
                            <h4 class="card-title m-t-10 text-center">Diamond</h4>
                            <h2 class="display-5 position-relative m-t-20 m-b-10">
                                <span class="price-sign">$</span>54
                            </h2>
                            <p>Per Month</p>
                        </div>
                        <div class="border-top">
                            <ul class="list-style-none text-center">
                                <li class="border-bottom p-20">
                                    <i class="ti-user m-r-5"></i>15 Members
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-mobile m-r-5"></i>Single Device
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-server m-r-5"></i>1TB Storage
                                </li>
                                <li class="border-bottom p-20">
                                    <i class="ti-reload m-r-5"></i>Monthly Backups
                                </li>
                                <li class="border-bottom p-20">
                                    <button class="btn btn-success waves-effect waves-light">Sign up</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>