import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {RecruitmentCandidateSelectionService} from '../../../shared/service/recruitment-candidate-selection-service';
import {CandidateSelectionSearchDto} from '../../../shared/dto/recruitment/selection/candidateSelectionSearchDto';
import {CandidateSelectionDto} from '../../../shared/dto/recruitment/selection/candidateSelectionDto';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './candidateSelectionList.component.html',
  styleUrls: ['./candidateSelectionList.component.css']
})
export class CandidateSelectionListComponent implements OnInit {

  rows: CandidateSelectionDto[];
  sessionObject = {
    user: '',
    token: '',
    company: ''
  };
  candidateSelectionFormGroup: FormGroup;
  candidateSelectionSearch: CandidateSelectionSearchDto;
  columns = [
    {prop: 'id', name: 'Id'},
    {prop: 'compCode', name: 'Company'},
    {prop: 'candidateID', name: 'Candidate'},
    {prop: 'recruitmentRequest', name: 'Recruitment Request'},
    {prop: 'status', name: 'Status'},
    {prop: 'action', name: 'Action', width: 50}
  ];
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;
  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(private candidateSelectionService: RecruitmentCandidateSelectionService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.instantiateForm();
    this.loadCandidates();
  }

  instantiateForm() {
    this.candidateSelectionFormGroup = this.formBuilder.group({
      id: [''],
      compCode: [''],
      candidateId: [''],
    });
  }

  searchByPageCandidates(event) {
    this.candidateSelectionSearch = this.candidateSelectionFormGroup.value;
    this.spinner.show();
    this.candidateSelectionService.search(this.candidateSelectionSearch, event.pageIndex, event.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  searchCandidatesSelection() {
    this.candidateSelectionSearch = this.candidateSelectionFormGroup.value;
    this.spinner.show();
    this.candidateSelectionService.search(this.candidateSelectionSearch, 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  loadCandidates() {
    this.spinner.show();
    this.candidateSelectionService.search(new CandidateSelectionSearchDto(), 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  initializeSessionObject() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.sessionObject.token = SessionObject.id_token;
    this.sessionObject.user = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.sessionObject.company = SessionObject?.glUserMastersDetailsDTO?.compCode;
  }

  listDetails(id) {
    this.router.navigate(['/recruitment/candidate/selection/details'], {queryParams: {id: id}});
  }

  delete(id) {
    if (confirm('Are you sure you want to delete the candidate selection [' + id + '] ?')) {
      this.spinner.show();
      this.candidateSelectionService.delete(id).subscribe(data => {
        this.spinner.hide();
        this._toastr.success('The candidate selection [' + id + '] was successfully deleted !');
        this.loadCandidates();
      }, error => {
        this._toastr.error(error);
        this.spinner.hide();
      });
    }
  }

  confirm(id) {
    if (confirm('Are you sure you want to confirm the Candidate Selection [' + id + '] ?')) {
      this.spinner.show();
      this.candidateSelectionService.confirm(id).subscribe(data => {
        this.spinner.hide();
        this._toastr.success('The candidate selection [' + id + '] was successfully confirmed !');
        this.loadCandidates();
      }, error => {
        this._toastr.error(error);
        this.spinner.hide();
      });
    }
  }


  reject(id) {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {
        const remark = result.value;
        const candidateSelectionDto = new CandidateSelectionDto();
        candidateSelectionDto.id = id;
        candidateSelectionDto.rejectRemark = remark;
        this.spinner.show();
        this.candidateSelectionService.reject(candidateSelectionDto).subscribe(data => {
          this.spinner.hide();
          this._toastr.success('The candidate selection [' + id + '] was successfully rejected !');
          this.loadCandidates();
        }, error => {
          this._toastr.error(error);
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }

    });
  }

  getStatus(row) {
    const status = row.status;
    const approvalLevel = row.approvalLevel;
    if (status === 'PENDING') {
      return 'Pending';
    } else if (status === 'PASSED') {
      return 'Passed';
    } else if (status === 'CONFIRMING') {
      return 'Confirm ' + approvalLevel;
    } else if (status === 'CONFIRMED') {
      return 'Confirmed';
    } else if (status === 'REJECTED') {
      return 'Rejected';
    }
    return 'N/A';
  }
}
