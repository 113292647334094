<div class="row">
  <!-- column -->
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Employee Enquiry</h4>
        <div class="row">
          <div class="col-lg-12">
            <form [formGroup]="employeeFormGroup" name="employeeForm" (ngSubmit)="onSearchSubmit()">
              <table class="table">
                <tbody>
                <tr>
                  <td>
                    <p>Company</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="companyCodeFrom" type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="companyCodeTo" type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Employee</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="empNoFrom" type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="empNoTo" type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Profession</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openStartProfession(professionModel)">
                        <input type="text" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedProfessionFrom && selectedProfessionFrom.length > 0">
                          <ng-container *ngFor="let selectedProfessionFromArr of selectedProfessionFrom">
                            <label>
                              <span>{{selectedProfessionFromArr.description}}  ({{selectedProfessionFromArr.compCode}})</span> <a (click)="removeProfessionFilter(selectedProfessionFromArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Position</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openPosition(PositionsModal)">
                        <input type="text" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedPositions && selectedPositions.length > 0">
                          <ng-container *ngFor="let selectedPositionArr of selectedPositions">
                            <label>
                              <span>{{selectedPositionArr.description}} ({{selectedPositionArr.compCode}})</span> <a (click)="removePositionFilter(selectedPositionArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Nationality</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openStartNationality(nationalityModel)">
                        <input type="text" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedNationalityFrom && selectedNationalityFrom.length > 0">
                          <ng-container *ngFor="let selectedNationalityFromArr of selectedNationalityFrom">
                            <label>
                              <span>{{selectedNationalityFromArr.description}}</span> <a (click)="removeNationalityFilter(selectedNationalityFromArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Cost Centre</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openCostCenterFrom(costCenterModelFrom)">
                        <input type="text" [value]="selectedFromCostCenter?.description" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedCostCenterFrom && selectedCostCenterFrom.length > 0">
                          <ng-container *ngFor="let selectedCostFromArr of selectedCostCenterFrom">
                            <label>
                              <span>{{selectedCostFromArr.description}}</span> <a (click)="removeCostFilter(selectedCostFromArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                  <!--<td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <a (click)="openCostCenterTo(costCenterModelTo)">
                        <input type="text" [value]="selectedToCostCenter?.description" class="form-control"/>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>-->
                </tr>
                <tr>
                  <td>
                    <p>Department</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openDepartment(departmentModel)">
                        <input type="text" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedDepartmentFrom && selectedDepartmentFrom.length > 0">
                          <ng-container *ngFor="let selectedDepartmentFromArr of selectedDepartmentFrom">
                            <label>
                              <span>{{selectedDepartmentFromArr.description}}  ({{selectedDepartmentFromArr.company}})</span> <a (click)="removeDepartmentFilter(selectedDepartmentFromArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
                <!--  Removed on 14/08/2021 - Discussed with Samy, this field should not be included on the form
                <tr>
                  <td>
                    <p>City</p>
                  </td>
                  <td colspan="6">
                    <div class="form-group">
                      <a (click)="openCity(cityModal)">
                        <input type="text" class="form-control"/>
                      </a>
                      <div class="tagged-section">
                        <ng-container *ngIf="selectedCityFrom && selectedCityFrom.length > 0">
                          <ng-container *ngFor="let selectedCityFromArr of selectedCityFrom">
                            <label>
                              <span>{{selectedCityFromArr.description}}</span> <a (click)="removeCityFilter(selectedCityFromArr)"><i class="close"></i></a>
                            </label>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                </tr>
                -->
                <tr>
                  <td>
                    <p>Location</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Qualification</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                </tr><!--
                <tr>
                  <td>
                    <p>Grade</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" readonly class="form-control"/>
                    </div>
                  </td>
                </tr>
              -->
                <tr>
                  <td>
                    <p>Joining Date</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="joiningDateFrom" type="text" ngbDatepicker [minDate]="{year: 1950, month: 1, day: 1}"
                             #fromDate="ngbDatepicker" (click)="fromDate.toggle()" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" ngbDatepicker formControlName="joiningDateTo" [minDate]="{year: 1950, month: 1, day: 1}"
                             #toDate="ngbDatepicker" (click)="toDate.toggle()" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Birth Date</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text"  formControlName="birthDateFrom" ngbDatepicker [minDate]="{year: 1920, month: 1, day: 1}"
                             #fromBirthDate="ngbDatepicker" (click)="fromBirthDate.toggle()" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="text" ngbDatepicker formControlName="birthDateTo" [minDate]="{year: 1920, month: 1, day: 1}"
                             #toBirthDate="ngbDatepicker" (click)="toBirthDate.toggle()" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Basic Salary</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="basicSalaryFrom" type="text" class="form-control"/>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="basicSalaryTo" type="text" class="form-control"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Total Salary</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="totalSalaryFrom" type="text" class="form-control"/>
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input formControlName="totalSalaryTo" type="text" class="form-control"/>
                    </div>
                  </td>
                </tr>
             <!--
                  <tr>
                  <td>
                    <p>Basic Salary</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                </tr>
             -->
               <!--
                  <tr>
                  <td>
                    <p>Grade</p>
                  </td>
                  <td>
                    <p>From</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                  </td>
                  <td>
                    <p>To</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input type="number" class="form-control"/>
                    </div>
                  </td>
                  <td>
                    <button class="btn btn-primary btn-euquiry">Enquiry</button>
                  </td>
                </tr>
               -->
               <tr>
                <td>
                  <button class="btn btn-primary btn-euquiry">Enquiry</button> 
                </td>
               </tr>
              <tr *ngIf="isEnquiryButtonPressed">
                <td >
                  <label   for="docType">Document Type</label>
                  <select (change)="setReportType($event.target.value)" class="form-select" name="reportType" id="reportType">
                    <option value="PDF">PDF</option>
                    <option value="CSV">CSV</option>
                  </select>
                </td>
                <td >
                  <div class="form-group">
                    <a (click)="openDisplayFields(DisplayFieldsModal)">
                      <input type="text" class="form-control"/>
                    </a>
                   <!-- <div class="tagged-section">
                      <ng-container *ngIf="selectedPositions && selectedPositions.length > 0">
                        <ng-container *ngFor="let selectedPositionArr of selectedPositions">
                          <label>
                            <span>{{selectedPositionArr.description}}</span> <a (click)="removePositionFilter(selectedPositionArr)"><i class="close"></i></a>
                          </label>
                        </ng-container>
                      </ng-container>
                    </div>-->
                  </div>
                </td>
                <td>
                  <button class="btn btn-primary" (click)="exportEmployeeReport()">Export</button>
                </td>
              </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="col-lg-12">
		    <ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
						  [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
			 <ng-container *ngFor='let column of columns'>
			   <ngx-datatable-column *ngIf='column.name === "Company"' name="Company" prop="company">
				 <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.company}}
				   </span>
				 </ng-template>
			   </ngx-datatable-column>
			   <ngx-datatable-column *ngIf='column.name === "Employee No"' name="Employee No" prop="empno">
				 <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.empno}}
				   </span>
				 </ng-template>
			   </ngx-datatable-column>
			   <ngx-datatable-column *ngIf='column.name === "Name"' name="Name" prop="name">
				 <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.name}}
				   </span>
				 </ng-template>
			   </ngx-datatable-column>
			   <ngx-datatable-column *ngIf='column.name === "Employee Type"' name="Employee Type" prop="employeetype">
				 <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.employeetype}}
				   </span>
				 </ng-template>
			   </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Nationality"' name="Nationality" prop="nationality">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.nationality}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Profession"' name="Profession" prop="profession">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.profession}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Departement"' name="Departement" prop="departement">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.departement}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "City"' name="City" prop="city">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.city}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Position"' name="Position" prop="positions">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.position}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Cost Center"' name="Cost Center" prop="costcenter">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.costsenter}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Joining Date"' name="Joining Date" prop="joindate">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.doj}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
         <ngx-datatable-column *ngIf='column.name === "Birth Date"' name="Birth Date" prop="birthday">
           <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
				   <span>
					   {{row?.dob}}
				   </span>
           </ng-template>
         </ngx-datatable-column>
			   <ngx-datatable-column *ngIf='column.name === "Action"' name="Action" prop="action">
				 <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
           <a routerLink="/provision/employee-detail/{{row?.empno}}" class="btn btn-primary">Check Details</a>
				 </ng-template>
			   </ngx-datatable-column>
			 </ng-container>
		   </ngx-datatable>

          <div>

          </div>

          <div class="pagination-set">
            <ng-container *ngIf="searchResultEmployeePagesArray && searchResultEmployeePagesArray.length > 0">
              <div class="row">
                <div class="col-lg-10">
                  <ul>
                    <li>
                      <a (click)="searchEmployeePaginated($event, 0)" class="btn btn-pagination">First</a>
                    </li>
                    <li *ngIf="activeSearchEmployee !== 0">
                      <a (click)="searchEmployeePaginated($event, (activeSearchEmployee - 1))" class="btn btn-pagination">Prev</a>
                    </li>
                    <li *ngIf="activeSearchEmployee>2 ">
                      <a (click)="searchEmployeePaginated($event, 1)" class="btn btn-pagination">1</a>
                    </li>
                    <li *ngIf="activeSearchEmployee>3">
                      <a (click)="searchEmployeePaginated($event, 2)" class="btn btn-pagination">...</a>
                    </li>
                    <ng-container *ngFor="let searchResultEmployeePagesArr of searchResultEmployeePagesArray; let i = index">
                      <ng-container *ngIf="(i - 3) < activeSearchEmployee && (i + 3) > activeSearchEmployee">
                        <li>
                          <ng-container *ngIf="i == activeSearchEmployee">
                            <a class="btn btn-pagination active" (click)="searchEmployeePaginated($event, i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activeSearchEmployee">
                            <a class="btn btn-pagination" (click)="searchEmployeePaginated($event, i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                    </ng-container>
                    <li *ngIf="searchResultEmployeePagesArray.length-activeSearchEmployee>4">
                      <a (click)="searchEmployeePaginated($event, (searchResultEmployeePagesArray.length - 2))" class="btn btn-pagination">...</a>
                    </li>
                    <li *ngIf="searchResultEmployeePagesArray.length-activeSearchEmployee>3 ">
                      <a (click)="searchEmployeePaginated($event, (searchResultEmployeePagesArray.length - 1))" class="btn btn-pagination">{{searchResultEmployeePagesArray.length}}</a>
                    </li>
                    <li *ngIf="activeSearchEmployee !== (searchResultEmployeePagesArray.length - 1)">
                      <a (click)="searchEmployeePaginated($event, (activeSearchEmployee + 1))" class="btn btn-pagination">Next</a>
                    </li>
                    <li>
                      <a (click)="searchEmployeePaginated($event, searchResultEmployeePagesArray.length - 1)" class="btn btn-pagination">Last</a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-2">
                  <div class="form-group">
                    <div class="select-padding-employee">
                      <select class="form-control" (change)="searchEmployeePaginatedDdl($event)">
                        <ng-container *ngFor="let searchResultEmployeePagesArr of searchResultEmployeePagesArray; let i = index">
                          <ng-container *ngIf="i === activeSearchEmployee">
                            <option selected [value]="i">
                              {{i + 1}}
                            </option>
                          </ng-container>
                          <ng-container *ngIf="i !== activeSearchEmployee">
                            <option [value]="i">
                              {{i + 1}}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
		 </div>
      </div>
    </div>
  </div>
</div>

<ng-template #professionModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">From - Profession</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Profession" class="form-control" (keyup)="searchProfessionList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayProfession of arrayProfessionList">
                      <ng-contaner *ngIf="selectedProfessionCodeFrom.indexOf(arrayProfession.code) > -1">
                        <label><input checked (change)="checkProfessionFrom($event, arrayProfession)" type="checkbox"/>{{arrayProfession.description}} ({{arrayProfession.compCode}})</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedProfessionCodeFrom.indexOf(arrayProfession.code) <= -1">
                        <label><input (change)="checkProfessionFrom($event, arrayProfession)" type="checkbox"/>{{arrayProfession.description}} ({{arrayProfession.compCode}})</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalProfessionCount of totalProfessionPagesArray; let i=index">
                        <ng-container *ngIf="i === activeProfessionFrom">
                          <li class="active">
                            <a (click)="paginatedProfessionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeProfessionFrom">
                          <li>
                            <a (click)="paginatedProfessionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #nationalityModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nationality</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Nationality" class="form-control" (keyup)="searchNationalityList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayNationality of arrayNationalityList">
                      <ng-contaner *ngIf="selectedNationalityCodeFrom.indexOf(arrayNationality.id) > -1">
                        <label><input checked (change)="checkNationality($event, arrayNationality)" type="checkbox"/>{{arrayNationality.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedNationalityCodeFrom.indexOf(arrayNationality.id) <= -1">
                        <label><input (change)="checkNationality($event, arrayNationality)" type="checkbox"/>{{arrayNationality.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalNationalityCount of totalNationalityPagesArray; let i=index">
                        <ng-container *ngIf="i === activeNationalityFrom">
                          <li class="active">
                            <a (click)="paginatedNationalityTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeNationalityFrom">
                          <li>
                            <a (click)="paginatedNationalityTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<ng-template #departmentModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Departments</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Department" class="form-control" (keyup)="searchDepartmentList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayDepartment of arrayDepartmentList">
                      <ng-contaner *ngIf="selectedDepartmentCodeFrom.indexOf(arrayDepartment.code) > -1">
                        <label><input checked (change)="checkDepartment($event, arrayDepartment)" type="checkbox"/>{{arrayDepartment.description}} ({{arrayDepartment.company}})</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedDepartmentCodeFrom.indexOf(arrayDepartment.code) <= -1">
                        <label><input (change)="checkDepartment($event, arrayDepartment)" type="checkbox"/>{{arrayDepartment.description}} ({{arrayDepartment.company}})</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalNationalityCount of totalDepartmentPagesArray; let i=index">
                        <ng-container *ngIf="i === activeDepartmentFrom">
                          <li class="active">
                            <a (click)="paginatedDepartmentTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeDepartmentFrom">
                          <li>
                            <a (click)="paginatedDepartmentTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #cityModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cities</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search City" class="form-control" (keyup)="searchCityList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayCity of arrayCityList">
                      <ng-contaner *ngIf="selectedCityCodeFrom.indexOf(arrayCity.description) > -1">
                        <label><input checked (change)="checkCity($event, arrayCity)" type="checkbox"/>{{arrayCity.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedCityCodeFrom.indexOf(arrayCity.description) <= -1">
                        <label><input (change)="checkCity($event, arrayCity)" type="checkbox"/>{{arrayCity.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalCityCount of totalCityPagesArray; let i=index">
                        <ng-container *ngIf="i === activeCityFrom">
                          <li class="active">
                            <a (click)="paginatedCityTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeCityFrom">
                          <li>
                            <a (click)="paginatedCityTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<ng-template #costCenterModelFrom let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cost Centers - From</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Cost Center From" class="form-control" (keyup)="searchCostCenterFrom($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayCostCenterData of arrayCostCentersFrom">
                      <!--<ng-contaner *ngIf="selectedFromCostCenter && arrayCostCenterData.id === selectedFromCostCenter.id">-->
                      <ng-contaner *ngIf="selectedCostCenterCodeFrom.indexOf(arrayCostCenterData.code) > -1">
                        <label><input checked (change)="checkCostCenterFrom($event, arrayCostCenterData)" name="costCenterFrom" type="checkbox"/>{{arrayCostCenterData.description}}</label>
                      </ng-contaner>
                      <!--<ng-contaner *ngIf="selectedFromCostCenter && arrayCostCenterData.id !== selectedFromCostCenter.id">-->
                      <ng-contaner *ngIf="selectedCostCenterCodeFrom.indexOf(arrayCostCenterData.code) <= -1">
                        <label><input (change)="checkCostCenterFrom($event, arrayCostCenterData)" name="costCenterFrom" type="checkbox"/>{{arrayCostCenterData.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalNationalityCount of totalCostCenterArray; let i=index">
                        <ng-container *ngIf="i === activeCostCenterFrom">
                          <li class="active">
                            <a (click)="paginatedCostCenterFrom(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeCostCenterFrom">
                          <li>
                            <a (click)="paginatedCostCenterFrom(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #costCenterModelTo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cost Centers - To</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Cost Center From" class="form-control" (keyup)="searchCostCenterTo($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayCostCenterDataTo of arrayCostCenters">
                      <ng-contaner *ngIf="selectedToCostCenter && arrayCostCenterDataTo.id === selectedToCostCenter.id">
                        <label><input checked (change)="checkCostCenterTo($event, arrayCostCenterDataTo)" name="costCenterTo" type="radio"/>{{arrayCostCenterDataTo.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedToCostCenter && arrayCostCenterDataTo.id !== selectedToCostCenter.id">
                        <label><input (change)="checkCostCenterTo($event, arrayCostCenterDataTo)" name="costCenterTo" type="radio"/>{{arrayCostCenterDataTo.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="!selectedToCostCenter">
                        <label><input (change)="checkCostCenterTo($event, arrayCostCenterDataTo)" name="costCenterTo" type="radio"/>{{arrayCostCenterDataTo.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalNationalityCount of totalCostCenterArray; let i=index">
                        <ng-container *ngIf="i === activeCostCenterTo">
                          <li class="active">
                            <a (click)="paginatedCostCenterTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeCostCenterTo">
                          <li>
                            <a (click)="paginatedCostCenterTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #PositionsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Positions</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Positions" class="form-control" (keyup)="searchPositionList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayPositionArr of arrayPositions">
                      <ng-contaner *ngIf="selectedPositionsCodes.indexOf(arrayPositionArr.catId) > -1">
                        <label><input checked (change)="checkPosition($event, arrayPositionArr)" type="checkbox"/>{{arrayPositionArr.description}}  ({{arrayPositionArr.compCode}})</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedPositionsCodes.indexOf(arrayPositionArr.catId) <= -1">
                        <label><input (change)="checkPosition($event, arrayPositionArr)" type="checkbox"/>{{arrayPositionArr.description}}  ({{arrayPositionArr.compCode}})</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalPositionCount of totalPositionPagesArray; let i=index">
                        <ng-container *ngIf="i === activePositionFrom">
                          <li class="active">
                            <a (click)="paginatedPositionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activePositionFrom">
                          <li>
                            <a (click)="paginatedPositionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>


<ng-template #DisplayFieldsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Display Fields</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let displayColumn of displayColumns">
                      <ng-contaner *ngIf="selectedDisplayColumns.indexOf(displayColumn.code) > -1">
                        <label><input checked (change)="checkDisplayColumn($event, displayColumn.code)" type="checkbox"/>{{displayColumn.label}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedDisplayColumns.indexOf(displayColumn.code) <= -1">
                        <label><input (change)="checkDisplayColumn($event, displayColumn.code)" type="checkbox"/>{{displayColumn.label}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
