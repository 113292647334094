<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Bar chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a bar chart</h6>
                <div class="barchrt">
                    <x-chartist class="" [data]="barChart1.data" [type]="barChart1.type" [options]="barChart1.options" [responsiveOptions]="barChart1.responsiveOptions"
                        [events]="barChart1.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Line Area chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Line chart</h6>
                <div class="linearea">
                    <x-chartist class="" [data]="lineChart1.data" [type]="lineChart1.type" [options]="lineChart1.options" [responsiveOptions]="lineChart1.responsiveOptions"
                        [events]="lineChart1.events"> </x-chartist>
                </div>
            </div>
        </div> 
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Line chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Line chart</h6>
                <div class="linearea">
                    <x-chartist class="" [data]="lineChart2.data" [type]="lineChart2.type" [options]="lineChart2.options" [responsiveOptions]="lineChart2.responsiveOptions"
                        [events]="lineChart2.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Line chart 2 Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Line chart</h6>
                <div class="linearea">
                    <x-chartist class="" [data]="lineChart3.data" [type]="lineChart3.type" [options]="lineChart3.options" [responsiveOptions]="lineChart3.responsiveOptions"
                        [events]="lineChart3.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Scatter chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Scatter chart</h6>
                <div class="linearea">
                    <x-chartist class="" [data]="scatterChart1.data" [type]="scatterChart1.type" [options]="scatterChart1.options" [responsiveOptions]="scatterChart1.responsiveOptions"
                        [events]="scatterChart1.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pie chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Pie chart</h6>
                <div class="piechart">
                    <x-chartist class="" [data]="pieChart1.data" [type]="pieChart1.type" [options]="pieChart1.options" [responsiveOptions]="pieChart1.responsiveOptions"
                        [events]="pieChart1.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Donute chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Pie chart</h6>
                <div class="piechart">
                    <x-chartist class="" [data]="donuteChart1.data" [type]="donuteChart1.type" [options]="donuteChart1.options" [responsiveOptions]="donuteChart1.responsiveOptions"
                        [events]="donuteChart1.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Bipollar chart Example</h4>
                <h6 class="card-subtitle">This is the simple data export from the json file and creating a Bipollar chart</h6>
                <div class="barchrt">
                    <x-chartist class="" [data]="bipollarChart1.data" [type]="bipollarChart1.type" [options]="bipollarChart1.options" [responsiveOptions]="bipollarChart1.responsiveOptions"
                        [events]="bipollarChart1.events"> </x-chartist>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <app-dynamic-chart class="barchrt linearea"></app-dynamic-chart>
            </div>
        </div>
    </div>
</div>