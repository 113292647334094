import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {EmployeeEnquiry} from '../../shared/model/employee-enquiry';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EmployeesService} from '../../shared/service/employees.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import {date} from 'ngx-custom-validators/src/app/date/validator';
import { EMPLOYEE_REPORT_DISPLAY_COLUMNS } from 'src/app/app.constants';

declare var require: any;
const data: any = require('./enquiry.json');

@Component({
  selector: 'app-enquiryview',
  templateUrl: './enquiryview.component.html',
  styleUrls: ['./enquiryview.component.css']
})
export class EnquiryviewComponent implements OnInit {

  editing = {};
  rows = [];
  temp = [...data];

  employeeEnquiry: EmployeeEnquiry;
  employeeFormGroup: FormGroup;

  totalProfessionPages: any;
  totalProfessionPagesArray: any[];
  arrayProfessionList: any[] = [];
  arrayFullProfessionalList: any[];
  arrayFullProfessionalSearchList: any[];
  activeProfessionFrom: any;
  selectedProfessionFrom: any[] = [];
  selectedProfessionCodeFrom: any[] = [];
  professionSearch: any;
  professionFromList = '';

  totalNationalityPages: any = [];
  arrayNationalityFullList: any = [];
  totalNationalityPagesArray: any[];
  arrayNationalityList: any[] = [];
  arrayFullNationalityList: any[] = [];
  arrayFullNationalitySearchList: any[] = [];
  activeNationalityFrom: any;
  selectedNationalityFrom: any[] = [];
  selectedNationalityCodeFrom: any[] = [];
  nationalitySearch: any;
  nationalityList = '';

  totalDepartmentPages: any;
  totalDepartmentPagesArray: any[];
  arrayDepartmentList: any[] = [];
  arrayFullDepartmentList: any[] = [];
  arrayFullDepartmentSearchList: any[] = [];
  activeDepartmentFrom: any;
  selectedDepartmentFrom: any[] = [];
  selectedDepartmentCodeFrom: any[] = [];
  departmentSearch: any;
  departmentList = '';

  totalCityPages: any;
  totalCityPagesArray: any[];
  arrayCityList: any[] = [];
  arrayFullCityList: any[] = [];
  arrayFullCitySearchList: any[] = [];
  activeCityFrom: any;
  selectedCityFrom: any[] = [];
  selectedCityCodeFrom: any[] = [];
  citySearch: any;
  cityList = '';

  totalCostCenterPages: any;
  totalCostCenterArray: any[];
  totalCostCenterToPages: any;
  totalCostCenterToArray: any[];
  arrayCostCenters: any[] = [];
  arrayCostFullCenters: any[] = [];
  arrayCostFullSearchCenters: any[] = [];
  arrayCostCentersFrom: any[] = [];
  arrayCostFullCentersFrom: any[] = [];
  arrayCostFullSearchCentersFrom: any[] = [];

  selectedCostCenterFrom: any[] = [];
  selectedCostCenterCodeFrom: any[] = [];

  selectedFromCostCenter: any;
  selectedToCostCenter: any;
  activeCostCenterFrom: any;
  activeCostCenterTo: any;
  searchFromCostCenter: any;
  searchToCostCenter: any;

  arrayPositions: any[] = [];
  arrayFullPositions: any[] = [];
  arrayFullSearchPositions: any[] = [];
  selectedPositions: any[] = [];
  displayColumns = EMPLOYEE_REPORT_DISPLAY_COLUMNS;
  selectedDisplayColumns: any[] = [];
  selectedPositionsCodes: any[] = [];
  activePositionFrom: any;
  totalPositionPages: any;
  positionSearch: any;
  totalPositionPagesArray: any[];

  searchResultEmployeePages: any;
  searchResultEmployeePagesArray: any[] = [];
  searchResultEmployeeCurrentPagesArray: any[] = [];
  activeSearchEmployee: any;

  isEnquiryButtonPressed : boolean = false;
  reportType : string = "PDF";

  columns = [{ prop: 'company', name: 'Company' }, { prop: 'empno', name: 'Employee No' }, {prop: 'name', name: 'Name'}, {prop: 'employeetype', name: 'Employee Type'}];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeesService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    this.temp = [...data];
  }

  ngOnInit(): void {
    this.employeeFormGroup = this.formBuilder.group({
      companyCodeFrom: [''],
      companyCodeTo: [''],
      empNoFrom: [''],
      empNoTo: [''],
      departmentFrom: [''],
      departmentTo: [''],
      joiningDateFrom: [''],
      joiningDateTo: [''],
      birthDateFrom: [''],
      birthDateTo: [''],
      basicSalaryFrom : [''],
      basicSalaryTo : [''],
      totalSalaryFrom : [''],
      totalSalaryTo : ['']
    });

    this.employeeService.getPrefessions().subscribe(searchProfessionData => {
      console.log(searchProfessionData);
      this.totalProfessionPages = Math.ceil(searchProfessionData.length / 10);
      this.totalProfessionPagesArray = new Array(this.totalProfessionPages);
      this.activeProfessionFrom = 0;
      this.arrayFullProfessionalList = searchProfessionData;

      this.arrayFullProfessionalList.map((currElement, index) => {
        if (index <= 10) {
          this.arrayProfessionList.push(currElement);
        }
      });

      console.log(this.arrayProfessionList);

    });

    this.employeeService.getNationalities().subscribe(searchNationalityData => {
      this.totalNationalityPages = Math.ceil((searchNationalityData.length / 10));
      this.totalNationalityPagesArray = new Array(this.totalNationalityPages);
      this.activeNationalityFrom = 0;
      this.arrayFullNationalityList = searchNationalityData;
      this.arrayFullNationalityList.map((currElement, index) => {
        if (index <= 10) {
          this.arrayNationalityList.push(currElement);
        }
      });
      //this.arrayNationalityList = searchNationalityData.content;
    });

    this.employeeService.getDepartments().subscribe(searchDepartmentData => {
      this.totalDepartmentPages = Math.ceil(searchDepartmentData.length / 10);
      this.totalDepartmentPagesArray = new Array(this.totalNationalityPages);
      this.activeDepartmentFrom = 0;
      this.arrayFullDepartmentList = searchDepartmentData;
      this.arrayFullDepartmentList.map((currElement, index) => {
        if (index <= 10) {
          this.arrayDepartmentList.push(currElement);
        }
      });
      //this.arrayDepartmentList = searchDepartmentData;
    });

    /*    
    this.employeeService.getCities().subscribe(cityData => {
      console.log(cityData);
      this.totalCityPages = Math.ceil(cityData.length / 10);
      this.totalCityPagesArray = new Array(this.totalCityPages);
      this.activeCityFrom = 0;
      this.arrayFullCityList = cityData;
      this.arrayFullCityList.map((currElement, index) => {
        if (index <= 10) {
          this.arrayCityList.push(currElement);
        }
      });
      
      //this.arrayCityList = cityData;
    });
    */


    this.employeeService.getCostCenters().subscribe(costCenterData => {
      console.log(costCenterData);
      this.totalCostCenterPages = Math.ceil(costCenterData.length / 10);
      this.totalCostCenterArray = new Array(this.totalCostCenterPages);

      this.activeCostCenterFrom = 0;
      this.activeCostCenterTo = 0;

      this.arrayCostFullCenters = costCenterData;
      this.arrayCostFullCentersFrom = costCenterData;

      this.arrayCostFullCenters.map((currElement, index) => {
        if (index <= 10) {
          this.arrayCostCenters.push(currElement);
        }
      });

      this.arrayCostFullCentersFrom.map((currElement, index) => {
        if (index <= 10) {
          this.arrayCostCentersFrom.push(currElement);
        }
      });
    });

    this.employeeService.getPositions().subscribe(allPositions => {
      console.log(allPositions);

      this.totalPositionPages = Math.ceil(allPositions.length / 10);
      this.totalPositionPagesArray = new Array(this.totalPositionPages);
      this.activePositionFrom = 0;
      this.arrayFullPositions = allPositions;

      this.arrayFullPositions.map((currElement, index) => {
        if (index <= 10) {
          this.arrayPositions.push(currElement);
        }
      });
      //this.arrayPositions = allPositions;
    });
  }

  onSearchSubmit() {

    console.log(this.selectedFromCostCenter);
    this.spinner.show();
    this.isEnquiryButtonPressed = true;
    this.employeeEnquiry = this.employeeFormGroup.value;
    this.employeeEnquiry.professions = this.selectedProfessionFrom;
    this.employeeEnquiry.positions = this.selectedPositions;
    this.employeeEnquiry.nationalities = this.selectedNationalityFrom;
    this.employeeEnquiry.departments = this.selectedDepartmentFrom;
    this.employeeEnquiry.cities = this.selectedCityFrom;
    this.employeeEnquiry.joiningDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateFrom);
    this.employeeEnquiry.joiningDateTo = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateTo);
    this.employeeEnquiry.birthDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.birthDateFrom);
    this.employeeEnquiry.birthDateTo = this.getDateFromPickerDate(this.employeeEnquiry.birthDateTo);
    this.employeeEnquiry.costCenters = this.selectedCostCenterFrom;
    this.employeeEnquiry.displayColumns = this.selectedDisplayColumns;
    console.log(this.employeeEnquiry);

    this.employeeService.searchEmployee(this.employeeEnquiry, 0, 10).subscribe(searchResult => {

      console.log(searchResult);
      this.spinner.hide();

      this.columns = [{ prop: 'company', name: 'Company' }, { prop: 'empno', name: 'Employee No' }, {prop: 'name', name: 'Name'}, {prop: 'employeetype', name: 'Employee Type'}];

      console.log(this.employeeEnquiry.joiningDateFrom);

      if ((this.employeeEnquiry.joiningDateFrom && this.employeeEnquiry.joiningDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.joiningDateTo && this.employeeEnquiry.joiningDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'joindate', name: 'Joining Date'});
      }

      if ((this.employeeEnquiry.birthDateFrom && this.employeeEnquiry.birthDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.birthDateTo && this.employeeEnquiry.birthDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'birthday', name: 'Birth Date'});
      }

      /*if ((this.employeeEnquiry.costCenterFrom && this.employeeEnquiry.costCenterFrom !== '')) {
        this.columns.push({prop: 'costcenter', name: 'Cost Center'});
      }*/

      if (this.employeeEnquiry.nationalities && this.employeeEnquiry.nationalities.length > 0) {
        this.columns.push({prop: 'nationality', name: 'Nationality'});
      }

      if (this.employeeEnquiry.professions && this.employeeEnquiry.professions.length > 0) {
        this.columns.push({prop: 'professions', name: 'Profession'});
      }

      if (this.employeeEnquiry.departments && this.employeeEnquiry.departments.length > 0) {
        this.columns.push({prop: 'departement', name: 'Departement'});
      }

      if (this.employeeEnquiry.cities && this.employeeEnquiry.cities.length > 0) {
        this.columns.push({prop: 'city', name: 'City'});
      }

      if (this.employeeEnquiry.positions && this.employeeEnquiry.positions.length > 0) {
        this.columns.push({prop: 'positions', name: 'Position'});
      }

      this.columns.push({name: 'Action', prop: 'action'});

      console.log(searchResult);

      this.searchResultEmployeePages = searchResult.totalPages;
      this.activeSearchEmployee = 0;

      let searchResultEmployeePagesCurrent;

      if (this.searchResultEmployeePages < 5) {
        searchResultEmployeePagesCurrent = searchResult.totalPages;
      } else {
        searchResultEmployeePagesCurrent = 5;
      }

      this.searchResultEmployeePagesArray = new Array(this.searchResultEmployeePages);
      this.searchResultEmployeeCurrentPagesArray = new Array(searchResultEmployeePagesCurrent);

      this.rows = searchResult.content.map(employeeData => {
        return {company: employeeData.companyName, empno: employeeData.empNo, name: employeeData.name, employeetype: employeeData?.employeeType?.description, nationality: employeeData?.nationality, profession: employeeData?.profession, position: employeeData?.employeeType?.description, departement: employeeData?.departement?.description, costsenter: employeeData?.costCenter, doj: employeeData?.doj, dob: employeeData?.dob};
      });
    }, errorSection => {
      this.spinner.hide();
    });
  }

  searchEmployeePaginated(event: any, index: any) {
    console.log(index);
    this.spinner.show();

    this.employeeEnquiry = this.employeeFormGroup.value;
    this.employeeEnquiry.professions = this.selectedProfessionFrom;
    this.employeeEnquiry.positions = this.selectedPositions;
    this.employeeEnquiry.nationalities = this.selectedNationalityFrom;
    this.employeeEnquiry.departments = this.selectedDepartmentFrom;
    this.employeeEnquiry.cities = this.selectedCityFrom;
    this.employeeEnquiry.joiningDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateFrom);
    this.employeeEnquiry.joiningDateTo = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateTo);
    this.employeeEnquiry.birthDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.birthDateFrom);
    this.employeeEnquiry.birthDateTo = this.getDateFromPickerDate(this.employeeEnquiry.birthDateTo);
    this.employeeEnquiry.costCenters = this.selectedCostCenterFrom;

    console.log(this.employeeEnquiry);

    this.employeeService.searchEmployee(this.employeeEnquiry, index, 10).subscribe(searchResult => {

      console.log(searchResult);
      this.spinner.hide();

      this.columns = [{ prop: 'company', name: 'Company' }, { prop: 'empno', name: 'Employee No' }, {prop: 'name', name: 'Name'}, {prop: 'employeetype', name: 'Employee Type'}];

      console.log(this.employeeEnquiry.joiningDateFrom);

      if ((this.employeeEnquiry.joiningDateFrom && this.employeeEnquiry.joiningDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.joiningDateTo && this.employeeEnquiry.joiningDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'joindate', name: 'Joining Date'});
      }

      if ((this.employeeEnquiry.birthDateFrom && this.employeeEnquiry.birthDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.birthDateTo && this.employeeEnquiry.birthDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'birthday', name: 'Birth Date'});
      }

      /*if ((this.employeeEnquiry.costCenterFrom && this.employeeEnquiry.costCenterFrom !== '') || (this.employeeEnquiry.costCenterTo && this.employeeEnquiry.costCenterTo !== '')) {
        this.columns.push({prop: 'costcenter', name: 'Cost Center'});
      }*/

      if (this.employeeEnquiry.nationalities && this.employeeEnquiry.nationalities.length > 0) {
        this.columns.push({prop: 'nationality', name: 'Nationality'});
      }

      if (this.employeeEnquiry.professions && this.employeeEnquiry.professions.length > 0) {
        this.columns.push({prop: 'professions', name: 'Profession'});
      }

      if (this.employeeEnquiry.departments && this.employeeEnquiry.departments.length > 0) {
        this.columns.push({prop: 'departement', name: 'Departement'});
      }

      if (this.employeeEnquiry.cities && this.employeeEnquiry.cities.length > 0) {
        this.columns.push({prop: 'city', name: 'City'});
      }

      if (this.employeeEnquiry.positions && this.employeeEnquiry.positions.length > 0) {
        this.columns.push({prop: 'positions', name: 'Position'});
      }

      this.columns.push({name: 'Action', prop: 'action'});

      console.log(searchResult);

      this.searchResultEmployeePages = searchResult.totalPages;
      this.activeSearchEmployee = index;
      this.searchResultEmployeePagesArray = new Array(this.searchResultEmployeePages);

      /*
      let searchResultEmployeePagesCurrent;

      if (this.searchResultEmployeePages < 5) {
        searchResultEmployeePagesCurrent = searchResult.totalPages;
      } else {
        searchResultEmployeePagesCurrent = 5;
      }

      this.searchResultEmployeePagesArray = new Array(this.searchResultEmployeePages);
      this.searchResultEmployeeCurrentPagesArray = new Array(searchResultEmployeePagesCurrent);
      */

      this.rows = searchResult.content.map(employeeData => {
        return {company: employeeData.company.name, empno: employeeData.empNo, name: employeeData.name, employeetype: employeeData?.employeeType?.description, nationality: employeeData?.nationality?.description, profession: employeeData?.profession?.description, position: employeeData?.employeeType?.description, departement: employeeData?.departement?.description, costsenter: employeeData?.costCenter?.description, doj: employeeData?.doj, dob: employeeData?.dob};
      });
      this.spinner.hide();
    }, errorSection => {
      this.spinner.hide();
    });
  }

  searchEmployeePaginatedDdl(event) {
    let index = event.target.value;
    console.log(index);
    this.spinner.show();

    this.employeeEnquiry = this.employeeFormGroup.value;
    this.employeeEnquiry.professions = this.selectedProfessionFrom;
    this.employeeEnquiry.positions = this.selectedPositions;
    this.employeeEnquiry.nationalities = this.selectedNationalityFrom;
    this.employeeEnquiry.departments = this.selectedDepartmentFrom;
    this.employeeEnquiry.cities = this.selectedCityFrom;
    this.employeeEnquiry.joiningDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateFrom);
    this.employeeEnquiry.joiningDateTo = this.getDateFromPickerDate(this.employeeEnquiry.joiningDateTo);
    this.employeeEnquiry.birthDateFrom = this.getDateFromPickerDate(this.employeeEnquiry.birthDateFrom);
    this.employeeEnquiry.birthDateTo = this.getDateFromPickerDate(this.employeeEnquiry.birthDateTo);
    this.employeeEnquiry.costCenters = this.selectedCostCenterFrom;

    console.log(this.employeeEnquiry);

    this.employeeService.searchEmployee(this.employeeEnquiry, index, 10).subscribe(searchResult => {

      console.log(searchResult);
      this.spinner.hide();

      this.columns = [{ prop: 'company', name: 'Company' }, { prop: 'empno', name: 'Employee No' }, {prop: 'name', name: 'Name'}, {prop: 'employeetype', name: 'Employee Type'}];

      console.log(this.employeeEnquiry.joiningDateFrom);

      if ((this.employeeEnquiry.joiningDateFrom && this.employeeEnquiry.joiningDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.joiningDateTo && this.employeeEnquiry.joiningDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'joindate', name: 'Joining Date'});
      }

      if ((this.employeeEnquiry.birthDateFrom && this.employeeEnquiry.birthDateFrom.toString() !== 'Invalid Date') || (this.employeeEnquiry.birthDateTo && this.employeeEnquiry.birthDateTo.toString() !== 'Invalid Date')) {
        this.columns.push({prop: 'birthday', name: 'Birth Date'});
      }

      /*if ((this.employeeEnquiry.costCenterFrom && this.employeeEnquiry.costCenterFrom !== '') || (this.employeeEnquiry.costCenterTo && this.employeeEnquiry.costCenterTo !== '')) {
        this.columns.push({prop: 'costcenter', name: 'Cost Center'});
      }*/

      if (this.employeeEnquiry.nationalities && this.employeeEnquiry.nationalities.length > 0) {
        this.columns.push({prop: 'nationality', name: 'Nationality'});
      }

      if (this.employeeEnquiry.professions && this.employeeEnquiry.professions.length > 0) {
        this.columns.push({prop: 'professions', name: 'Profession'});
      }

      if (this.employeeEnquiry.departments && this.employeeEnquiry.departments.length > 0) {
        this.columns.push({prop: 'departement', name: 'Departement'});
      }

      if (this.employeeEnquiry.cities && this.employeeEnquiry.cities.length > 0) {
        this.columns.push({prop: 'city', name: 'City'});
      }

      if (this.employeeEnquiry.positions && this.employeeEnquiry.positions.length > 0) {
        this.columns.push({prop: 'positions', name: 'Position'});
      }

      this.columns.push({name: 'Action', prop: 'action'});

      this.searchResultEmployeePages = searchResult.totalPages;
      this.activeSearchEmployee = index;
      this.searchResultEmployeePagesArray = new Array(this.searchResultEmployeePages);

      console.log(this.activeSearchEmployee);

      /*
      let searchResultEmployeePagesCurrent;

      if (this.searchResultEmployeePages < 5) {
        searchResultEmployeePagesCurrent = searchResult.totalPages;
      } else {
        searchResultEmployeePagesCurrent = 5;
      }

      this.searchResultEmployeePagesArray = new Array(this.searchResultEmployeePages);
      this.searchResultEmployeeCurrentPagesArray = new Array(searchResultEmployeePagesCurrent);
      */

      this.rows = searchResult.content.map(employeeData => {
        return {company: employeeData.company.name, empno: employeeData.empNo, name: employeeData.name, employeetype: employeeData?.employeeType?.description, nationality: employeeData?.nationality?.description, profession: employeeData?.profession?.description, position: employeeData?.employeeType?.description, departement: employeeData?.departement?.description, costsenter: employeeData?.costCenter?.description, doj: employeeData?.doj, dob: employeeData?.dob};
      });
      this.spinner.hide();
    }, errorSection => {
      this.spinner.hide();
    });
  }

  checkCostCenterFrom(event, costCenterFrom) {
    //this.selectedFromCostCenter = costCenterFrom;

    //selectedCostCenterFrom

    if (event.target.checked === true) {
      this.selectedCostCenterFrom.push(costCenterFrom);
      this.selectedCostCenterCodeFrom.push(costCenterFrom.code);
    } else {
      this.selectedCostCenterFrom = this.selectedCostCenterFrom.filter(positionData => {
        if (costCenterFrom.code !== positionData.code) {
          return positionData;
        }
      });
      this.selectedCostCenterCodeFrom = this.selectedCostCenterCodeFrom.filter(positionCode => {
        if (positionCode !== costCenterFrom.code) {
          return positionCode;
        }
      });
    }
    console.log(this.selectedCostCenterFrom);

  }

  removeCostFilter(coseCenter) {
    this.selectedCostCenterFrom = this.selectedCostCenterFrom.filter(costData => {
      if (coseCenter.code !== costData.code) {
        return costData;
      }
    });
    this.selectedCostCenterCodeFrom = this.selectedCostCenterCodeFrom.filter(costDataCode => {
      console.log(costDataCode);
      if (costDataCode !== coseCenter.code) {
        return costDataCode;
      }
    });
  }

  checkCostCenterTo(event, costCenterTo) {
    this.selectedToCostCenter = costCenterTo;
  }

  openCostCenterFrom(modalCostCenterFrom) {

    this.arrayCostCentersFrom = [];
    this.searchFromCostCenter = '';
    let searchResult = this.arrayCostFullCentersFrom.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.searchFromCostCenter)) {
        return searchData;
      }
      return false;
    });

    this.totalCostCenterPages = Math.ceil(searchResult.length / 10);
    this.activeCostCenterFrom = 0;
    this.totalCostCenterArray = new Array(this.totalCostCenterPages);

    this.arrayCostFullSearchCentersFrom = searchResult;

    this.arrayCostFullSearchCentersFrom.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCostCentersFrom.push(currElement);
      }
    });

    this.modalService.open(modalCostCenterFrom, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openCostCenterTo(modalCostCenterTo) {

    this.arrayCostCenters = [];
    this.searchToCostCenter = '';
    let searchResult = this.arrayCostFullCenters.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.searchToCostCenter)) {
        return searchData;
      }
      return false;
    });

    this.totalCostCenterToPages = Math.ceil(searchResult.length / 10);
    this.activeCostCenterTo = 0;
    this.totalCostCenterToArray = new Array(this.totalCostCenterToPages);

    this.arrayCostFullSearchCenters = searchResult;

    this.arrayCostFullSearchCenters.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCostCenters.push(currElement);
      }
    });

    this.modalService.open(modalCostCenterTo, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openStartProfession(modalStartProfession) {
    this.arrayProfessionList = [];
    this.professionSearch = '';
    let searchResult = this.arrayFullProfessionalList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.professionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalProfessionPages = Math.ceil(searchResult.length / 10);
    this.activeProfessionFrom = 0;
    this.totalProfessionPagesArray = new Array(this.totalProfessionPages);

    this.arrayFullProfessionalSearchList = searchResult;

    this.arrayFullProfessionalSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayProfessionList.push(currElement);
      }
    });
    this.modalService.open(modalStartProfession, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openPosition(modalPosition) {
    this.arrayPositions = [];
    this.positionSearch = '';
    let searchResult = this.arrayFullPositions.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.positionSearch)) {
        return searchData;
      }
      return false;
    });


    this.totalPositionPages = Math.ceil(searchResult.length / 10);
    this.activePositionFrom = 0;
    this.totalPositionPagesArray = new Array(this.totalPositionPages);

    this.arrayFullSearchPositions = searchResult;

    this.arrayFullSearchPositions.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayPositions.push(currElement);
      }
    });
    this.modalService.open(modalPosition, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openDisplayFields(displayFieldsModal){
    this.modalService.open(displayFieldsModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  checkPosition(event, position) {
    console.log(position);
    if (event.target.checked === true) {
      this.selectedPositions.push(position);
      this.selectedPositionsCodes.push(position.id);
    } else {
      this.selectedPositions = this.selectedPositions.filter(positionData => {
        if (position.catId !== positionData.catId) {
          return positionData;
        }
      });
      this.selectedPositionsCodes = this.selectedPositionsCodes.filter(positionCode => {
        if (positionCode !== position.catId) {
          return positionCode;
        }
      });
    }

    console.log(this.selectedPositions);

  }

  
  checkDisplayColumn(event, displayColumn) {
    if (event.target.checked === true) {
      this.selectedDisplayColumns.push(displayColumn);
      //this.selectedPositionsCodes.push(displayColumn.id);
    } else {
      this.selectedDisplayColumns = this.selectedDisplayColumns.filter(displayColumnData => {
        console.log(displayColumn + "==> "+ displayColumnData.code);
        if (displayColumn !== displayColumnData.id) {
          return displayColumnData;
        }
      });
    /*  this.selectedPositionsCodes = this.selectedPositionsCodes.filter(positionCode => {
        if (positionCode !== displayColumn.id) {
          return positionCode;
        }
      });*/
    }
  }

  removePositionFilter(position) {
    this.selectedPositions = this.selectedPositions.filter(positionData => {
      if (position.catId !== positionData.catId) {
        return positionData;
      }
    });
    this.selectedPositionsCodes = this.selectedPositionsCodes.filter(positionCode => {
      if (positionCode !== position.catId) {
        return positionCode;
      }
    });
  }

  openDepartment(modalDepartment) {
    this.arrayDepartmentList = [];
    this.departmentSearch = '';
    let searchResult = this.arrayFullDepartmentList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.departmentSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalDepartmentPages = Math.ceil(searchResult.length / 10);
    this.activeDepartmentFrom = 0;
    this.totalDepartmentPagesArray = new Array(this.totalDepartmentPages);

    this.arrayFullDepartmentSearchList = searchResult;

    this.arrayFullDepartmentSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayDepartmentList.push(currElement);
      }
    });
    this.modalService.open(modalDepartment, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openStartNationality(modalStartNationality) {
    this.arrayNationalityList = [];
    this.nationalitySearch = '';
    let searchResult = this.arrayFullNationalityList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.nationalitySearch)) {
        return searchData;
      }
      return false;
    });

    this.totalNationalityPages = Math.ceil(searchResult.length / 10);
    this.activeNationalityFrom = 0;
    this.totalNationalityPagesArray = new Array(this.totalNationalityPages);

    this.arrayFullNationalitySearchList = searchResult;

    this.arrayFullNationalitySearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayNationalityList.push(currElement);
      }
    });
    this.modalService.open(modalStartNationality, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openCity(modalCity) {

    this.arrayCityList = [];
    this.citySearch = '';
    let searchResult = this.arrayFullCityList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.citySearch)) {
        return searchData;
      }
      return false;
    });

    this.totalCityPages = Math.ceil(searchResult.length / 10);
    this.activeCityFrom = 0;
    this.totalCityPagesArray = new Array(this.totalCityPages);

    this.arrayFullCitySearchList = searchResult;

    this.arrayFullCitySearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCityList.push(currElement);
      }
    });

    this.modalService.open(modalCity, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  removeProfessionFilter(professionFrom) {
    const code = professionFrom.code + professionFrom.compCode;
    this.selectedProfessionFrom = this.selectedProfessionFrom.filter(professionFromData => {
      if (code !== professionFromData.code + professionFromData.compCode) {
        return professionFromData;
      }
    });
    this.selectedProfessionCodeFrom = this.selectedProfessionCodeFrom.filter(professionFromCode => {
      if (professionFromCode !== code) {
        return professionFromCode;
      }
    });
  }

  checkProfessionFrom(event, professionFrom) {
    const code = professionFrom.code + professionFrom.compCode;
    if (event.target.checked === true) {
      this.selectedProfessionFrom.push(professionFrom);
      this.selectedProfessionCodeFrom.push(code);
    } else {
      this.selectedProfessionFrom = this.selectedProfessionFrom.filter(professionFromData => {
        if (code !== professionFromData.code + professionFromData.compCode) {
          return professionFromData;
        }
      });
      this.selectedProfessionCodeFrom = this.selectedProfessionCodeFrom.filter(professionFromCode => {
        if (professionFromCode !== code) {
          return professionFromCode;
        }
      });
    }

    console.log(this.selectedProfessionFrom);

    let result = '';
    this.selectedProfessionFrom.forEach(selectedProf => {
      if (result !== '') {
        result = result + ',' + selectedProf.description;
      } else {
        result = result + selectedProf.description;
      }

    });
    this.professionFromList = result;

  }

  removeNationalityFilter(nationality) {
    this.selectedNationalityFrom = this.selectedNationalityFrom.filter(nationalityData => {
      if (nationality.id !== nationalityData.id) {
        return nationalityData;
      }
    });
    this.selectedNationalityCodeFrom = this.selectedNationalityCodeFrom.filter(nationalityFromCode => {
      if (nationalityFromCode !== nationality.id) {
        return nationalityFromCode;
      }
    });
  }

  checkNationality(event, nationality) {
    if (event.target.checked === true) {
      this.selectedNationalityFrom.push(nationality);
      this.selectedNationalityCodeFrom.push(nationality.id);
    } else {
      this.selectedNationalityFrom = this.selectedNationalityFrom.filter(nationalityData => {
        if (nationality.id !== nationalityData.id) {
          return nationalityData;
        }
      });
      this.selectedNationalityCodeFrom = this.selectedNationalityCodeFrom.filter(nationalityFromCode => {
        console.log(nationalityFromCode);
        if (nationalityFromCode !== nationality.id) {
          return nationalityFromCode;
        }
      });
    }

    console.log(this.selectedNationalityFrom);

    let result = '';
    this.selectedNationalityFrom.forEach(selectedProf => {
      if (result !== '') {
        result = result + ',' + selectedProf.description;
      } else {
        result = result + selectedProf.description;
      }

    });
    this.nationalityList = result;

  }

  removeDepartmentFilter(department) {
    const code = department.code + department.company;
    this.selectedDepartmentFrom = this.selectedDepartmentFrom.filter(departmentData => {
      if (code !== departmentData.code + departmentData.company) {
        return departmentData;
      }
    });
    this.selectedDepartmentCodeFrom = this.selectedDepartmentCodeFrom.filter(departmentFromCode => {
      console.log(departmentFromCode);
      if (departmentFromCode !== code) {
        return departmentFromCode;
      }
    });
  }

  checkDepartment(event, department) {
    const code = department.code + department.company;
    if (event.target.checked === true) {
      this.selectedDepartmentFrom.push(department);
      this.selectedDepartmentCodeFrom.push(code);
    } else {
      this.selectedDepartmentFrom = this.selectedDepartmentFrom.filter(departmentData => {
        if (code !== departmentData.code + departmentData.company) {
          return departmentData;
        }
      });
      this.selectedDepartmentCodeFrom = this.selectedDepartmentCodeFrom.filter(departmentFromCode => {
        console.log(departmentFromCode);
        if (departmentFromCode !== code) {
          return departmentFromCode;
        }
      });
    }

    console.log(this.selectedDepartmentFrom);

    let result = '';
    this.selectedDepartmentFrom.forEach(selectedProf => {
      if (result !== '') {
        result = result + ',' + selectedProf.description;
      } else {
        result = result + selectedProf.description;
      }

    });
    this.departmentList = result;

  }

  removeCityFilter(citySelect) {
    this.selectedCityFrom = this.selectedCityFrom.filter(cityData => {
      if (citySelect.description !== cityData.description) {
        return cityData;
      }
    });
    this.selectedCityCodeFrom = this.selectedCityCodeFrom.filter(cityFromCode => {
      console.log(cityFromCode);
      if (cityFromCode !== citySelect.description) {
        return cityFromCode;
      }
    });
  }

  checkCity(event, city) {
    if (event.target.checked === true) {
      this.selectedCityFrom.push(city);
      this.selectedCityCodeFrom.push(city.description);
    } else {
      this.selectedCityFrom = this.selectedCityFrom.filter(cityData => {
        if (city.description !== cityData.description) {
          return cityData;
        }
      });
      this.selectedCityCodeFrom = this.selectedCityCodeFrom.filter(cityFromCode => {
        console.log(cityFromCode);
        if (cityFromCode !== city.description) {
          return cityFromCode;
        }
      });
    }
  }

  checkProfessionFromActive(professionFrom) {
    return this.selectedProfessionFrom.filter(professionFromData => {
      console.log(professionFrom);
      if (professionFromData.code === professionFrom.code) {
        return true;
      } else {
        return false;
      }
    });
  }

  paginatedProfessionTo(paginationNum) {
    this.activeProfessionFrom = paginationNum;
    /*this.employeeService.getPrefessions(paginationNum, 10).subscribe(searchProfessionData => {
      console.log(searchProfessionData);
      this.arrayProfessionList = searchProfessionData.content;
    });*/

    this.arrayProfessionList = [];

    if (this.arrayFullProfessionalSearchList && this.arrayFullProfessionalSearchList.length > 0) {
      this.arrayFullProfessionalSearchList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayProfessionList.push(currElement);
        }
      });
    } else {
      this.arrayFullProfessionalList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayProfessionList.push(currElement);
        }
      });
    }

  }

  searchProfessionList(event) {
    this.arrayProfessionList = [];
    this.professionSearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullProfessionalList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.professionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalProfessionPages = Math.ceil(searchResult.length / 10);
    this.activeProfessionFrom = 0;
    this.totalProfessionPagesArray = new Array(this.totalProfessionPages);

    this.arrayFullProfessionalSearchList = searchResult;

    this.arrayFullProfessionalSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayProfessionList.push(currElement);
      }
    });
  }

  paginatedNationalityTo(paginationNum) {
    this.activeNationalityFrom = paginationNum;
    this.arrayNationalityList = [];

    if (this.arrayFullNationalitySearchList && this.arrayFullNationalitySearchList.length > 0) {
      this.arrayFullNationalitySearchList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayNationalityList.push(currElement);
        }
      });
    } else {
      this.arrayFullNationalityList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayNationalityList.push(currElement);
        }
      });
    }
  }

  searchNationalityList(event) {
    this.arrayNationalityList = [];
    this.nationalitySearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullNationalityList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.nationalitySearch)) {
        return searchData;
      }
      return false;
    });

    this.totalNationalityPages = Math.ceil(searchResult.length / 10);
    this.activeNationalityFrom = 0;
    this.totalNationalityPagesArray = new Array(this.totalNationalityPages);

    this.arrayFullNationalitySearchList = searchResult;

    this.arrayFullNationalitySearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayNationalityList.push(currElement);
      }
    });
  }

  paginatedDepartmentTo(paginationNum) {
    this.activeDepartmentFrom = paginationNum;

    this.arrayDepartmentList = [];

    if (this.arrayFullDepartmentSearchList && this.arrayFullDepartmentSearchList.length > 0) {
      this.arrayFullDepartmentSearchList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayDepartmentList.push(currElement);
        }
      });
    } else {
      this.arrayFullDepartmentList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayDepartmentList.push(currElement);
        }
      });
    }
  }

  searchDepartmentList(event) {
    this.arrayDepartmentList = [];
    this.departmentSearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullDepartmentList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.departmentSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalDepartmentPages = Math.ceil(searchResult.length / 10);
    this.activeDepartmentFrom = 0;
    this.totalDepartmentPagesArray = new Array(this.totalDepartmentPages);

    this.arrayFullDepartmentSearchList = searchResult;

    this.arrayFullDepartmentSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayDepartmentList.push(currElement);
      }
    });
  }

  paginatedCityTo(paginationNum) {
    this.activeCityFrom = paginationNum;

    this.arrayCityList = [];

    if (this.arrayFullCitySearchList && this.arrayFullCitySearchList.length > 0) {
      this.arrayFullCitySearchList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCityList.push(currElement);
        }
      });
    } else {
      this.arrayFullCityList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCityList.push(currElement);
        }
      });
    }
  }

  searchCityList(event) {
    this.arrayCityList = [];
    this.citySearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullCityList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.citySearch)) {
        return searchData;
      }
      return false;
    });

    this.totalCityPages = Math.ceil(searchResult.length / 10);
    this.activeCityFrom = 0;
    this.totalCityPagesArray = new Array(this.totalCityPages);

    this.arrayFullCitySearchList = searchResult;

    this.arrayFullCitySearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCityList.push(currElement);
      }
    });
  }

  paginatedCostCenterTo(paginationNum) {
    this.activeDepartmentFrom = paginationNum;

    this.activeCostCenterTo = paginationNum;
    this.arrayCostCenters = [];

    if (this.arrayCostFullSearchCenters && this.arrayCostFullSearchCenters.length > 0) {
      this.arrayCostFullSearchCenters.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCostCenters.push(currElement);
        }
      });
    } else {
      this.arrayCostFullCenters.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCostCenters.push(currElement);
        }
      });
    }

    /*this.employeeService.getCostCenters(paginationNum, 10).subscribe(searchDeptData => {
      console.log(searchDeptData);
      this.arrayCostCenters = searchDeptData.content;
    });*/
  }

  searchCostCenterTo(event) {
    this.arrayCostCenters = [];
    this.searchToCostCenter = event.target.value.toLowerCase();
    let searchResult = this.arrayCostFullCenters.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.searchToCostCenter)) {
        return searchData;
      }
      return false;
    });

    this.totalCostCenterToPages = Math.ceil(searchResult.length / 10);
    this.activeCostCenterTo = 0;
    this.totalCostCenterToArray = new Array(this.totalCostCenterToPages);

    this.arrayCostFullSearchCenters = searchResult;

    this.arrayCostFullSearchCenters.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCostCenters.push(currElement);
      }
    });
  }

  paginatedCostCenterFrom(paginationNum) {
    this.activeCostCenterFrom = paginationNum;
    this.arrayCostCentersFrom = [];

    if (this.arrayCostFullSearchCentersFrom && this.arrayCostFullSearchCentersFrom.length > 0) {
      this.arrayCostFullSearchCentersFrom.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCostCentersFrom.push(currElement);
        }
      });
    } else {
      this.arrayCostFullCentersFrom.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayCostCentersFrom.push(currElement);
        }
      });
    }

    /*this.employeeService.getCostCenters(paginationNum, 10).subscribe(searchDeptData => {
      console.log(searchDeptData);
      this.arrayCostCentersFrom = searchDeptData.content;
    });*/
  }

  searchCostCenterFrom(event) {
    this.arrayCostCentersFrom = [];
    this.searchFromCostCenter = event.target.value.toLowerCase();
    let searchResult = this.arrayCostFullCentersFrom.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.searchFromCostCenter)) {
        return searchData;
      }
      return false;
    });

    this.totalCostCenterPages = Math.ceil(searchResult.length / 10);
    this.activeCostCenterFrom = 0;
    this.totalCostCenterArray = new Array(this.totalCostCenterPages);

    this.arrayCostFullSearchCentersFrom = searchResult;

    this.arrayCostFullSearchCentersFrom.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayCostCentersFrom.push(currElement);
      }
    });
  }

  paginatedPositionTo(paginationNum) {
    this.activePositionFrom = paginationNum;
    this.arrayPositions = [];

    if (this.arrayFullSearchPositions && this.arrayFullSearchPositions.length > 0) {
      this.arrayFullSearchPositions.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayPositions.push(currElement);
        }
      });
    } else {
      this.arrayFullPositions.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayPositions.push(currElement);
        }
      });
    }
  }

  searchPositionList(event) {
    this.arrayPositions = [];
    this.positionSearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullPositions.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.positionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalPositionPages = Math.ceil(searchResult.length / 10);
    this.activePositionFrom = 0;
    this.totalPositionPagesArray = new Array(this.totalPositionPages);

    this.arrayFullSearchPositions = searchResult;

    this.arrayFullSearchPositions.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayPositions.push(currElement);
      }
    });
  }

  setReportType(reportType : any){
    this.reportType = reportType;
  }

  exportEmployeeReport(){
    this.spinner.show();
    this.employeeService.exportEmployeeReport(this.employeeEnquiry , this.reportType).subscribe(data =>  {

      const blob = new Blob([data],{type: this.getResponseType(this.reportType)});
      if(window.navigator && window.navigator.msSaveOrOpenBlob){
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const value = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = value;
      link.download =  this.getReportFilename(this.reportType);
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      setTimeout(function() {
        window.URL.revokeObjectURL(value);
        link.remove;
      },100);

    });
    this.spinner.hide();
  }

  private getResponseType(reportType : any) : string {
    if(reportType == "PDF"){
      return 'application/pdf';
    }
    if(reportType == "CSV"){
      return 'text/csv';
    }
    return 'application/pdf';
  }

  private getReportFilename(reportType : any) : string {
    if(reportType == "PDF"){
      return 'employeesReport.pdf';
    }
    if(reportType == "CSV"){
      return 'employeesReport.csv';
    }
    return 'employeesReport.pdf';
  }

  private getDateFromPickerDate(pickerDate : any) : Date {
    if (pickerDate != null){
      let date = new Date(pickerDate.year, pickerDate.month -1, pickerDate.day, 0, 0, 0, 0);
      return date;
    }
    return null;
  }

}
