import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from 'src/app/shared/service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from 'src/app/shared/service/company.service';
import {ICompany} from '../../shared/model/i-company';
import {AuthService} from 'src/app/shared/service/auth.service';


@Component({
  selector: 'app-login3',
  templateUrl: './login3.component.html',
  styleUrls: ['./login3.component.css']
})
export class Login3Component implements OnInit {

  showFlag:boolean = true;
  employeeNo :number;
  verificationCode :string;
  isLoginDisabled : boolean = false;
  isProceedDisabled : boolean = false;
  allLoginData: any;
  returnUrl: string;

  constructor(private userService :UserService,private toastrService :ToastrService, private router: Router,
              private route: ActivatedRoute,
              private companyService:CompanyService,
              private authService : AuthService ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'leaves/list';
  }

  nextStep() {
    this.isLoginDisabled = true;
    this.userService.validateEmployeeNo(this.employeeNo).subscribe(data=>{
      this.isLoginDisabled = false;
      if(data===true){
        this.showFlag = false;
        this.toastrService.success("Verification code is  sent to your  email");
      }else{
        this.showFlag = true;
        this.toastrService.error("Employee Number not valid");
      }
    },err=>{
      this.isLoginDisabled = false;
      this.showFlag = true;
      this.toastrService.error("Error Occurred");
    })
  }

  loginByEmpNo() {
    this.isProceedDisabled = true;
    this.userService.loginByEmpNo(this.employeeNo, this.verificationCode).subscribe(data => {
      this.isProceedDisabled = false;
      if (data) {
        this.allLoginData = data;
        localStorage.setItem('loginsessiondata', JSON.stringify(this.allLoginData));
        this.authService.onAuthenticationSuccess(data, true, false);
        this.companyService.getAll().subscribe(allcompanies => {
          localStorage.setItem('Companies', JSON.stringify(allcompanies));
        });
        this.toastrService.success('Verification Successfull');
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.toastrService.error('Invalid Verification code');
      }
    }, err => {
      this.isProceedDisabled = false;
      this.toastrService.error(err?.error?.detail);
    });
  }
}
