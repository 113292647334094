<!-- Row -->
<div class="row">
    <!-- Column -->
    <div class="col-lg-4">
        <div class="card">
            <img class="card-img-top img-responsive" src="assets/images/big/img4.jpg" alt="Card image cap">
            <div class="card-body">
                <div class="d-flex no-block align-items-center m-b-15">
                    <span>
                        <i class="ti-calendar"></i> 20 May 2018</span>
                    <div class="ml-auto">
                        <a href="javascript:void(0)" class="link">
                            <i class="ti-comments"></i> 3 Comments</a>
                    </div>
                </div>
                <h3 class="font-normal">Featured Hydroflora Pots Garden &amp; Outdoors</h3>
                <p class="m-b-0 m-t-10">Titudin venenatis ipsum ac feugiat. Vestibulum ullamcorper quam.</p>
                <button class="btn btn-success btn-rounded waves-effect waves-light m-t-20">Read more</button>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-4">
        <div class="card">
            <img class="card-img-top img-responsive" src="assets/images/big/img5.jpg" alt="Card image cap">
            <div class="card-body">
                <div class="d-flex no-block align-items-center m-b-15">
                    <span>
                        <i class="ti-calendar"></i> 19 May 2018</span>
                    <div class="ml-auto">
                        <a href="javascript:void(0)" class="link">
                            <i class="ti-comments"></i> 5 Comments</a>
                    </div>
                </div>
                <h3 class="font-normal">Featured Hydroflora Pots Garden &amp; Outdoors</h3>
                <p class="m-b-0 m-t-10">Titudin venenatis ipsum ac feugiat. Vestibulum ullamcorper quam.</p>
                <button class="btn btn-success btn-rounded waves-effect waves-light m-t-20">Read more</button>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-4">
        <div class="card">
            <img class="card-img-top img-responsive" src="assets/images/big/img6.jpg" alt="Card image cap">
            <div class="card-body">
                <div class="d-flex no-block align-items-center m-b-15">
                    <span>
                        <i class="ti-calendar"></i> 29 May 2018</span>
                    <div class="ml-auto">
                        <a href="javascript:void(0)" class="link">
                            <i class="ti-comments"></i> 6 Comments</a>
                    </div>
                </div>
                <h3 class="font-normal">Featured Hydroflora Pots Garden &amp; Outdoors</h3>
                <p class="m-b-0 m-t-10">Titudin venenatis ipsum ac feugiat. Vestibulum ullamcorper quam.</p>
                <button class="btn btn-success btn-rounded waves-effect waves-light m-t-20">Read more</button>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>
<!-- Row -->
<!-- Row -->
<div class="row">
    <!-- Column -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h3>26%</h3>
                        <h6 class="card-subtitle">Total Product</h6>
                    </div>
                    <div class="col-12">
                        <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" style="width: 85%; height: 6px;" aria-valuenow="25" aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h3>60%</h3>
                        <h6 class="card-subtitle">Pending Product</h6>
                    </div>
                    <div class="col-12">
                        <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" style="width: 40%; height: 6px;" aria-valuenow="25" aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h3>63%</h3>
                        <h6 class="card-subtitle">Selling Products</h6>
                    </div>
                    <div class="col-12">
                        <div class="progress">
                            <div class="progress-bar bg-danger" role="progressbar" style="width: 56%; height: 6px;" aria-valuenow="25" aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-3 col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h3>29%</h3>
                        <h6 class="card-subtitle">Buying Products</h6>
                    </div>
                    <div class="col-12">
                        <div class="progress">
                            <div class="progress-bar bg-inverse" role="progressbar" style="width: 26%; height: 6px;" aria-valuenow="25" aria-valuemin="0"
                                aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>
<!-- Row -->
<!-- Row -->
<div class="row">
    <!-- col -->
    <div class="col-lg-3 col-md-6">
        <div class="card bg-info">
            <div class="card-body">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item active flex-column">
                            <i class="ti-world font-20 text-white"></i>
                            <p class="text-white">25th Jan</p>
                            <h3 class="text-white font-medium">BBC World News - World - BBC Latest News</h3>
                            <div class="text-white m-t-10">
                                <i>- john doe</i>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-world font-20 text-white"></i>
                            <p class="text-white">26th Jan</p>
                            <h3 class="text-white font-medium">BBC World News - World - BBC Latest News</h3>
                            <div class="text-white m-t-10">
                                <i>- john doe</i>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-world font-20 text-white"></i>
                            <p class="text-white">27th Jan</p>
                            <h3 class="text-white font-medium">BBC World News - World - BBC Latest News</h3>
                            <div class="text-white m-t-10">
                                <i>- john doe</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-3 col-md-6">
        <div class="card bg-primary">
            <div class="card-body">
                <div id="myCarousel2" class="carousel slide vert" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item active flex-column">
                            <i class="ti-apple font-20 text-white"></i>
                            <p class="text-white">20th Jan</p>
                            <h3 class="text-white font-medium">Apple Reports Second Quarter Results</h3>
                            <div class="text-white m-t-10">
                                <i>- Steve Jobs</i>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-apple font-20 text-white"></i>
                            <p class="text-white">19th Jan</p>
                            <h3 class="text-white font-medium">Apple Reports Second Quarter Results</h3>
                            <div class="text-white m-t-10">
                                <i>- Steve Jobs</i>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-apple font-20 text-white"></i>
                            <p class="text-white">18th Jan</p>
                            <h3 class="text-white font-medium">Apple Reports Second Quarter Results</h3>
                            <div class="text-white m-t-10">
                                <i>- Steve Jobs</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-3 col-md-6">
        <div class="card bg-inverse">
            <div class="card-body">
                <div id="myCarousel3" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item active flex-column">
                            <i class="ti-skype font-20 text-white"></i>
                            <p class="text-white">1th Jan</p>
                            <h3 class="text-white font-medium">Now Get
                                <span class="font-bold">50% Off</span> on calling</h3>
                            <div>
                                <button class="btn btn-secondary b-0 waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-skype font-20 text-white"></i>
                            <p class="text-white">2th Jan</p>
                            <h3 class="text-white font-medium">Now Get
                                <span class="font-bold">50% Off</span> on calling</h3>
                            <div>
                                <button class="btn btn-secondary b-0 waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-skype font-20 text-white"></i>
                            <p class="text-white">3th Jan</p>
                            <h3 class="text-white font-medium">Now Get
                                <span class="font-bold">50% Off</span> on calling</h3>
                            <div>
                                <button class="btn btn-secondary b-0 waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-3 col-md-6">
        <div class="card bg-white">
            <div class="card-body">
                <div id="myCarousel4" class="carousel vert slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item active flex-column">
                            <i class="ti-wordpress font-20"></i>
                            <p>25th Jan</p>
                            <h3>Dynamic website with responsive</h3>
                            <div>
                                <button class="btn btn-info justify-content-start waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-wordpress font-20"></i>
                            <p>25th Jan</p>
                            <h3>Dynamic website with responsive</h3>
                            <div>
                                <button class="btn btn-success d-inline waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <i class="ti-wordpress font-20"></i>
                            <p>25th Jan</p>
                            <h3>Dynamic website with responsive</h3>
                            <div>
                                <button class="btn btn-primary waves-effect waves-light m-t-15">Default</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
</div>
<!-- Row -->
<!-- Row -->
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <img class="card-img-top" src="assets/images/img3.jpg" alt="Card image cap" style="max-height: 450px">
            <div class="card-img-overlay">
                <div class="text-white">
                    <span>
                        <i class="wi wi-day-sunny display-4"></i>
                    </span>
                    <br>
                    <span class="display-6">20°
                        <span class="font-20">C</span>
                    </span>
                    <span class="font-20">/</span>
                    <span class="font-20">7°
                        <span class="font-size-16">C</span>
                    </span>
                    <p class="font-14 m-b-0">THURSDAY 01.08.2018</p>
                </div>
            </div>
            <div class="card-footer bg-white">
                <div class="row">
                    <div class="col-12">
                        <div class="row text-center">
                            <div class="col-6 col-md-2 border-right">
                                <div class="m-b-10">TUE</div>
                                <i class="wi wi-day-cloudy font-20 m-b-10"></i>
                                <div>24°
                                    <span>C</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-2 border-right">
                                <div class="m-b-10">WED</div>
                                <i class="wi wi-day-cloudy font-20 m-b-10"></i>
                                <div>21°
                                    <span>C</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-2 border-right">
                                <div class="m-b-10">THU</div>
                                <i class="wi wi-day-sunny font-20 m-b-10"></i>
                                <div>25°
                                    <span>C</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-2 border-right">
                                <div class="m-b-10">FRI</div>
                                <i class="wi wi-day-cloudy-gusts font-20 m-b-10"></i>
                                <div>20°
                                    <span>C</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-2 border-right">
                                <div class="m-b-10">SAT</div>
                                <i class="wi wi-day-lightning font-20 m-b-10"></i>
                                <div>18°
                                    <span>C</span>
                                </div>
                            </div>
                            <div class="col-6 col-md-2">
                                <div class="m-b-10">SUN</div>
                                <i class="wi wi-day-storm-showers font-20 m-b-10"></i>
                                <div>14°
                                    <span>C</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="card border-bottom border-info">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>120</h2>
                        <h6 class="text-info">News Feed</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-info display-6">
                            <i class="ti-notepad"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-bottom border-cyan">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>150</h2>
                        <h6 class="text-cyan">Invoices</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-cyan display-6">
                            <i class="ti-clipboard"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-bottom border-success">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>450</h2>
                        <h6 class="text-success">Revenue</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-success display-6">
                            <i class="ti-wallet"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-bottom border-orange">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>100</h2>
                        <h6 class="text-orange">Sales</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-orange display-6">
                            <i class="ti-stats-down"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="card border-left border-orange">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-orange display-6">
                            <i class="ti-user"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>290</h2>
                        <h6 class="text-orange">New Customers</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-left border-info">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-info display-6">
                            <i class="ti-comments"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>120</h2>
                        <h6 class="text-info">New Messages</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-left border-cyan">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-cyan display-6">
                            <i class="ti-comments-smiley"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>600</h2>
                        <h6 class="text-cyan">New Notification</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-left border-success">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-success display-6">
                            <i class="ti-layout-media-left-alt"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>270</h2>
                        <h6 class="text-success">New Projects</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="card border-top border-success">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>120</h2>
                        <h6 class="text-success">Top Locations</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-success display-6">
                            <i class="ti-map-alt"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-top border-dark">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>201</h2>
                        <h6 class="text-dark">Top Activity Pages</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-dark display-6">
                            <i class="ti-check-box"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-top border-info">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>140</h2>
                        <h6 class="text-info">Top Sales</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-info display-6">
                            <i class="ti-bar-chart-alt"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-top border-cyan">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <h2>160</h2>
                        <h6 class="text-cyan">Top Feeds</h6>
                    </div>
                    <div class="ml-auto">
                        <span class="text-cyan display-6">
                            <i class="ti-receipt"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-3 col-md-6">
        <div class="card border-right border-dark">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-dark display-6">
                            <i class="ti-layout-slider-alt"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>360</h2>
                        <h6 class="text-dark">Device Variations</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-right border-info">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-info display-6">
                            <i class="ti-pie-chart"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>120</h2>
                        <h6 class="text-info">Net Income</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-right border-cyan">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-cyan display-6">
                            <i class="ti-panel"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>471</h2>
                        <h6 class="text-cyan">Sales Performance</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3 col-md-6">
        <div class="card border-right border-success">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <div>
                        <span class="text-success display-6">
                            <i class="ti-layers-alt"></i>
                        </span>
                    </div>
                    <div class="ml-auto">
                        <h2>150</h2>
                        <h6 class="text-success">Monthly Sales</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-md-6">
        <div class="card-group">
            <!-- Column -->
            <div class="card bg-success">
                <div class="card-body text-center text-white">
                    <div class="p-20">
                        <h2>27° C</h2>
                        <h5 class="font-light m-t-20">Lester, London</h5>
                    </div>
                </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="card bg-success">
                <div class="card-body text-center text-white">
                    <span class="display-6">
                        <i class="wi wi-hail"></i>
                    </span>
                    <div class="m-t-15">
                        <h5 class="font-light">12.10.2018</h5>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>
    </div>
    <div class="col-md-6">
        <div class="card text-white">
            <img class="card-img" src="assets/images/background/weatherbg.jpg" alt="Card image">
            <div class="card-img-overlay text-white">
                <div class="d-flex no-block align-items-center">
                    <h5 class="font-medium">Lester, London</h5>
                    <div class="ml-auto">
                        <span>
                            <i class="ti-location-pin"></i>
                        </span>
                    </div>
                </div>
                <span>
                    <i class="display-6 wi wi-sandstorm"></i>
                </span>
                <div class="d-inline-block m-l-15">
                    <span class="display-6">14°</span>
                    <span class="font-24">C</span>
                    <span class="font-18">/ 7° C</span>
                </div>
                <div class="m-t-10 m-b-10">
                    <h5 class="m-b-0">WEDNESDAY</h5>
                    <h6>1.8.2018</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->
<!-- row -->
<div class="row">
    <div class="col-md-6">
        <div class="card text-center">
            <div class="card-body bg-cyan text-white">
                <span>
                    <i class="display-6 wi wi-sandstorm"></i>
                </span>
                <div class="d-inline-block m-l-15">
                    <span class="display-6">14°</span>
                    <span class="font-24">C</span>
                    <span class="font-18">/ 7° C</span>
                </div>
            </div>
            <div class="card-footer text-cyan bg-white">
                5 May 2018
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body bg-info text-white text-center">
                <div class="d-inline-block m-l-15">
                    <span class="display-6">4°</span>
                    <span class="font-24">C</span>
                    <span class="font-18">/ 7° C</span>
                </div>
                <span class="m-l-10">
                    <i class="display-6 wi wi-day-cloudy"></i>
                </span>
            </div>
            <div class="card-footer text-info bg-white">
                <div class="d-flex no-block align-items-center">
                    <h5 class="m-b-0">
                        <i class="wi wi-day-storm-showers"></i> 15kmph</h5>
                    <div class="ml-auto">
                        <h5 class="m-b-0">40%</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-3">
        <div class="card bg-inverse text-white">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <a href="JavaScript: void(0);">
                        <i class="display-6 cc ETH text-white" title="ETH"></i>
                    </a>
                    <div class="m-l-15 m-t-10">
                        <h4 class="font-medium m-b-0">Ethereum</h4>
                        <h5>$3589.00k</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-cyan text-white">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <a href="JavaScript: void(0);">
                        <i class="display-6 cc DASH-alt text-white" title="LTC"></i>
                    </a>
                    <div class="m-l-15 m-t-10">
                        <h4 class="font-medium m-b-0">Dash</h4>
                        <h5>$900.00k</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-orange text-white">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <a href="JavaScript: void(0);">
                        <i class="display-6 cc BTC-alt text-white" title="BTC"></i>
                    </a>
                    <div class="m-l-15 m-t-10">
                        <h4 class="font-medium m-b-0">Bitcoin</h4>
                        <h5>$284.50k</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <div class="card bg-info text-white">
            <div class="card-body">
                <div class="d-flex no-block align-items-center">
                    <a href="JavaScript: void(0);">
                        <i class="display-6 cc AMP-alt text-white" title="AMP"></i>
                    </a>
                    <div class="m-l-15 m-t-10">
                        <h4 class="font-medium m-b-0">Ripple</h4>
                        <h5>$650.80k</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-orange">
            <div class="card-body">
                <div id="myCarousel1" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc BTC text-white display-6 m-r-15" title="BTC"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitcoin</h5>
                                    <h6 class="text-white">Realestate</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 0.08</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.06</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -20.10</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc BTC text-white display-6 m-r-15" title="BTC"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitcoin</h5>
                                    <h6 class="text-white">Realestate</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 2.08</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -3.06</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -21.01</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc BTC text-white display-6 m-r-15" title="BTC"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitcoin</h5>
                                    <h6 class="text-white">Realestate</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 0.12</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.06</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -0.08</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-success">
            <div class="card-body">
                <div id="myCarousel22" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc EOS-alt text-white display-6 m-r-15" title="EOS"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">EOS</h5>
                                    <h6 class="text-white">Banking</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 0.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -5.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -0.10</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc EOS-alt text-white display-6 m-r-15" title="EOS"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">EOS</h5>
                                    <h6 class="text-white">Banking</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.08</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.01</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc EOS-alt text-white display-6 m-r-15" title="EOS"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">EOS</h5>
                                    <h6 class="text-white">Banking</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 2.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.00</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -2.08</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-dark">
            <div class="card-body">
                <div id="myCarousel33" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc ETH text-white display-6 m-r-15"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Ethereum</h5>
                                    <h6 class="text-white">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -5.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -20.10</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc ETH text-white display-6 m-r-15"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Ethereum</h5>
                                    <h6 class="text-white">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 2.08</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.08</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc ETH text-white display-6 m-r-15"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Ethereum</h5>
                                    <h6 class="text-white">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.02</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -3.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -10.00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-secondary">
            <div class="card-body">
                <div id="myCarousel44" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc BTM-alt text-white display-6 m-r-15" title="BTM"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitmark</h5>
                                    <h6 class="text-white">Advertising</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 0.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -2.06</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.10</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc BTM-alt text-white display-6 m-r-15" title="BTM"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitmark</h5>
                                    <h6 class="text-white">Advertising</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 2.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -3.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -2.01</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc BTM-alt text-white display-6 m-r-15" title="BTM"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Bitmark</h5>
                                    <h6 class="text-white">Advertising</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 0.12</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1s.08</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-cyan">
            <div class="card-body">
                <div id="myCarousel45" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc DASH-alt text-white display-6 m-r-15" title="DASH"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Dash</h5>
                                    <h6 class="text-white">Trading</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -0.20</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc DASH-alt text-white display-6 m-r-15" title="DASH"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Dash</h5>
                                    <h6 class="text-white">Trading</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.06</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.01</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block align-items-center m-r-15">
                                <i class="cc DASH-alt text-white display-6 m-r-15" title="DASH"></i>
                                <div class="m-t-10">
                                    <h5 class="text-white font-medium">Dash</h5>
                                    <h6 class="text-white">Trading</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto"></div>
                                </div>
                            </div>
                            <div class="row text-center text-white m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -0.08</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- col -->
    <div class="col-lg-4 col-md-6">
        <div class="card bg-light">
            <div class="card-body">
                <div id="myCarousel56" class="carousel slide" data-ride="carousel">
                    <!-- Carousel items -->
                    <div class="carousel-inner">
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc GEMZ-alt text-dark display-6 m-r-15" title="GEMZ"></i>
                                <div class="m-t-10">
                                    <h5 class="text-dark font-medium">GetGems</h5>
                                    <h6 class="text-dark">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto1"></div>
                                </div>
                            </div>
                            <div class="row text-center text-dark m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.10</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc GEMZ-alt text-dark display-6 m-r-15" title="GEMZ"></i>
                                <div class="m-t-10">
                                    <h5 class="text-dark font-medium">GetGems</h5>
                                    <h6 class="text-dark">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto1"></div>
                                </div>
                            </div>
                            <div class="row text-center text-dark m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 1.18</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -1.11</p>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item flex-column active">
                            <div class="d-flex no-block al m-r-15ign-items-center">
                                <i class="cc GEMZ-alt text-dark display-6 m-r-15" title="GEMZ"></i>
                                <div class="m-t-10">
                                    <h5 class="text-dark font-medium">GetGems</h5>
                                    <h6 class="text-dark">Exchange</h6>
                                </div>
                                <div class="ml-auto m-t-15">
                                    <div class="crypto1"></div>
                                </div>
                            </div>
                            <div class="row text-center text-dark m-t-30">
                                <div class="col-4">
                                    <span class="font-14">% 1h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> 12.16</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 24h</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-down"></i> -1.10</p>
                                </div>
                                <div class="col-4">
                                    <span class="font-14">% 7d</span>
                                    <p class="font-medium">
                                        <i class="fa fa-arrow-up"></i> -2.18</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <!-- Column -->
    <div class="col-sm-12 col-md-6">
        <div class="card bg-success">
            <div class="card-body text-white">
                <div class="d-flex flex-row">
                    <div class="align-self-center display-6">
                        <i class="ti-wallet"></i>
                    </div>
                    <div class="p-10 align-self-center">
                        <h4 class="m-b-0">Total Income</h4>
                        <span>Income</span>
                    </div>
                    <div class="ml-auto align-self-center">
                        <h2 class="font-medium m-b-0">$2900</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-sm-12 col-md-6">
        <div class="card bg-info">
            <div class="card-body text-white">
                <div class="d-flex flex-row">
                    <div class="display-6 align-self-center">
                        <i class="ti-user"></i>
                    </div>
                    <div class="p-10 align-self-center">
                        <h4 class="m-b-0">Total Products</h4>
                        <span>Users</span>
                    </div>
                    <div class="ml-auto align-self-center">
                        <h2 class="font-medium m-b-0">$2690</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-sm-12 col-md-6">
        <div class="card bg-cyan">
            <div class="card-body text-white">
                <div class="d-flex flex-row">
                    <div class="display-6 align-self-center">
                        <i class="ti-calendar"></i>
                    </div>
                    <div class="p-10 align-self-center">
                        <h4 class="m-b-0">Total Members</h4>
                        <span>My birthday</span>
                    </div>
                    <div class="ml-auto align-self-center">
                        <h2 class="font-medium m-b-0">20 March</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-sm-12 col-md-6">
        <div class="card bg-orange">
            <div class="card-body text-white">
                <div class="d-flex flex-row">
                    <div class="display-6 align-self-center">
                        <i class="ti-settings"></i>
                    </div>
                    <div class="p-10 align-self-center">
                        <h4 class="m-b-0">Total Tasks</h4>
                        <span>pending</span>
                    </div>
                    <div class="ml-auto align-self-center">
                        <h2 class="font-medium m-b-0">6540</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>