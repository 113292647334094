import {Component, OnInit, ViewChild} from '@angular/core';
import {ILeaveRequestHeader} from '../../shared/model/ileave-request';
import {Router} from '@angular/router';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgxSpinnerService} from 'ngx-spinner';
import {CompanyService} from 'src/app/shared/service/company.service';
import {EmployeesService} from 'src/app/shared/service/employees.service';
import {ToastrService} from 'ngx-toastr';
import DateUtils from '../../utils/DateUtils';

@Component({
  selector: 'app-leavereturn',
  templateUrl: './leavereturn.component.html',
  styleUrls: ['./leavereturn.component.css']
})
export class LeavereturnComponent implements OnInit {

  leaveReturnList: ILeaveRequestHeader[] = [];
  selectedCompany: any;

  editing = {};
  rows = [];
  companies = [];
  disabledClass: string;

  sessionObject = {
    user: '',
    token: '',
    company: ''
  };

  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;

  reorderable = true;

  columns = [
    {prop: 'company', name: 'Company'},
    {prop: 'empNo', name: 'Employee Number'},
    {prop: 'name', name: 'Employee Name'},
    {prop: 'fromDate', name: 'From Date'},
    {prop: 'toDate', name: 'To Date'},
  ];

  scrollBarHorizontal = (window.innerWidth < 1200);

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  constructor(
      private router: Router,
      private leaveRequestService: LeaveRequestService,
      private companyService: CompanyService,
      private employeeService: EmployeesService,
      private spinner: NgxSpinnerService,
      private _toastr: ToastrService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 1200);
    };
  }


  ngOnInit(): void {
    this.initializeSessionObject();
    this.loadRequestApprovals(0, this.pageSize);
    this.loadCompanies();
  }


  initializeSessionObject() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.sessionObject.token = SessionObject.id_token;
    this.sessionObject.user = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.sessionObject.company = SessionObject?.glUserMastersDetailsDTO?.compCode;
  }

  loadCompanies() {
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
    });
  }

  loadRequestApprovals(page: number, size: number) {
    this.spinner.show();
    this.leaveRequestService.searchLeaveReturn(this.selectedCompany, page, size).subscribe((data: any) => {
      this.leaveReturnList = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }


  filterLeaveRequestsByCompany(event) {
    this.selectedCompany = event.target.value !== 'all' ? event.target.value : null;
    this.loadRequestApprovals(0, this.pageSize);
  }


  searchPaginated(event: any) {
    this.loadRequestApprovals(event.pageIndex, event.pageSize);
  }


  getCompanyByCode(code: string): any {
    return this.companies.filter(company => company.code === code)[0];
  }

  private formatDate(date: string | null): string {
    return date ? DateUtils.get(date, 'DD-MM-YYYY') : '';
  }
}
