<div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4 class="card-title">Modal with default options</h4>
				<h6 class="card-subtitle">This is basic toggletype accordion</h6>
				<!--- ------------------
					Modal with date picker (NgbdModalBasicComponent)  
					---------------------->
				<ng-template #content1 let-modal>
					<div class="modal-header">
						<h4 class="modal-title" id="modal-basic-title">Profile update</h4>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<form>
							<div class="form-group">
								<label for="dateOfBirth">Date of birth</label>
								<div class="input-group">
									<input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
									<div class="input-group-append">
										<button class="btn btn-outline-secondary" (click)="dp.toggle()" type="button"><i class="fa fa-calendar"></i></button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
					</div>
				</ng-template>

				<button class="btn btn-lg btn-outline-primary" (click)="open1(content1)">Launch demo modal</button>

				
				<div class="m-t-20">{{ closeResult }}</div>
			</div>
		</div>
	</div>
	<!--- ------------------
	Component as Content (NgbdModalBasicComponent)  
	---------------------->
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4 class="card-title">Component as Content</h4>
				<h6 class="card-subtitle">You can pass an existing component as content of the modal window.</h6>
				<ng-template #content2 let-modal>
					<div class="modal-header">
						<h4 class="modal-title">Modal title</h4>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body"><p>One fine body&hellip;</p></div>
					<div class="modal-footer"><button type="button" class="btn btn-inverse" (click)="modal.dismiss('Cross click')">Close</button></div>
				</ng-template>

				<button class="btn btn-lg btn-outline-primary" (click)="open2(content2)">Launch demo modal</button>
			</div>
		</div>
	</div>
	<!--- ------------------
	Modal With Options (NgbdModalBasicComponent)  
	---------------------->
	<div class="col-md-12">
		<div class="card">
			<div class="card-body">
				<h4 class="card-title">Modal With Options</h4>
				<h6 class="card-subtitle">You can pass an existing component as content of the modal window.</h6>
				<ng-template #content3 let-modal>
					<div class="modal-header">
						<h4 class="modal-title">Modal title</h4>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>One fine body&hellip;</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
					</div>
				</ng-template>

				<button class="btn btn-outline-primary mb-2 mr-2" (click)="openWindowCustomClass(content3)">Modal with window custom class</button>
				<button class="btn btn-outline-primary mb-2 mr-2" (click)="openBackDropCustomClass(content3)">
					Modal with backdrop custom class
				</button>
				<button class="btn btn-outline-primary mb-2 mr-2" (click)="openSm(content3)">Small modal</button>
				<button class="btn btn-outline-primary mb-2 mr-2" (click)="openLg(content3)">Large modal</button>
				<button class="btn btn-outline-primary mb-2 mr-2" (click)="openVerticallyCentered(content3)">Modal vertically centered</button>
			</div>
		</div>
	</div>
	
	<!--- ------------------
	Stacked modals
	---------------------->
	<div  class="col-md-12">
	 	<div class="card">
	 		<div class="card-body">
	 			<h4 class="card-title">Stacked modals <span class="badge badge-success text-white">New</span></h4>

	 			<ng-template #contentstack let-modal>
					<div class="modal-header">
						<h4 class="modal-title">Modal title</h4>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>One fine body&hellip;</p>
						<p><button class="btn btn-lg btn-outline-primary" (click)="opensubmodal(contentsubmodal)">Launch demo modal</button></p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
					</div>
				</ng-template>


				<ng-template #contentsubmodal let-modal>
					<div class="modal-header">
						<h4 class="modal-title">Modal title</h4>
						<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>Sub Modal is open</p>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
					</div>
				</ng-template>

	 			<button class="btn btn-lg btn-outline-primary" (click)="opensubmodal(contentstack)">Launch demo modal</button>
	 		</div>
	 	</div>
	</div>
</div>
