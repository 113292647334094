import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AdminService} from 'src/app/shared/service/admin.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {HrDocumentRequestService} from '../../shared/service/hr-document-request.service';


interface SessionObject
{
    CompanyCode?: string;
    BranchCode?: string;
    Token?: string;
    FinancialCode?: string;
    FYearID?:string;
    DocumentType?: string;
    ModuleCode?: string;
    UserName?: string;
}

@Component({
  selector: 'app-approval-management',
  templateUrl: './approval-management.component.html',
  styleUrls: ['./approval-management.component.css']
})
export class ApprovalManagementComponent implements OnInit {

  appliedApprovalsLevelList:any = [];
  financialYearsList : any = [];
  Session: SessionObject = {
    BranchCode: "",
    FinancialCode: "",
    CompanyCode: "",
    ModuleCode: "",
    DocumentType: "LR",
    FYearID:""
  };
  TotalPages:number;
  vouchersList : any=[];
  selectedYear : string;
  selectedVoucherDocType: string;
  usCode: string;
  isSaveDisabled : boolean = false;
  searchResultCurrentPagesArray: any[] = [];
  activePageRequest: any;
  pageSize : number = 5;
  pageResults: any;
  searchResultPagesArray: any[] = [];
  initialFlag = true;
  pages:{selected: boolean; pagenumber: number}[] = [];
  selectedPage: number = 1;
  apprLevelsApplied : number;

  docTypesList:any = [];
  docCodesList : any [];


  constructor(private hrDocumentRequestService: HrDocumentRequestService, private leaveRequestService: LeaveRequestService, private adminService : AdminService,private toastrService : ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentType = "L1";
    this.Session.ModuleCode = "PP"; 
    this.Session.BranchCode = "01";
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode
    this.Session.FYearID = SessionObject.glUserMastersDetailsDTO.currentFinancialYear;
    this.selectedYear = this.Session.FYearID;
    this.selectedVoucherDocType = this.Session.DocumentType;
    this.usCode = "L01";
    this.selectedVoucherDocType = this.Session.DocumentType;
    this.getAppliedApprovalLevels(this.Session.CompanyCode, this.Session.FYearID, this.selectedVoucherDocType,this.usCode,0,this.pageSize);
    this.getAllFinancialYears();
    this.getAllVouchersData(this.Session.CompanyCode, this.Session.FYearID);
    this.getAllDocCodesBasedOnDocTypeAndfinYear(this.Session.CompanyCode, Number(this.Session.FYearID), this.selectedVoucherDocType);

  }


  getAppliedApprovalLevels(userCompCode : string, usFyerId : string ,docType:string, compCode: string,page : number,pageSize : number){
    this.adminService.getUserPpDocsforAdminManagement(userCompCode,usFyerId,docType,compCode,page,pageSize).subscribe(data=>{
      this.appliedApprovalsLevelList = data?.content;
      this.setPaginationInformations(data,0);
    })
  }

  getAllFinancialYears(){
    this.adminService.getAllFinancialYears(this.Session.CompanyCode).subscribe(data=>{
        this.financialYearsList = data;
    })
  }

  selectedFinancialYear(value){
    this.selectedYear = value;
    this.getAppliedApprovalLevels(this.Session.CompanyCode , this.selectedYear, this.selectedVoucherDocType,this.usCode,0,this.pageSize);
    this.getAllVouchersData(this.Session.CompanyCode, this.selectedYear);
    this.getAllDocCodesBasedOnDocTypeAndfinYear(this.Session.CompanyCode, Number(this.selectedYear), this.selectedVoucherDocType,)
  }


  getAllVouchersData(company : string , financialYear : string ){
    this.adminService.getAllVouchers(company,Number(financialYear)).subscribe(data=>{
        this.vouchersList = data;
    });

  }

  selectedDocumentType(value){
    this.selectedVoucherDocType =  value;
    this.getAppliedApprovalLevels(this.Session.CompanyCode, this.selectedYear, this.selectedVoucherDocType, this.usCode,0,this.pageSize);
    this.getAllDocCodesBasedOnDocTypeAndfinYear(this.Session.CompanyCode, Number(this.selectedYear), this.selectedVoucherDocType,)
  }

  selectedDocCode(data){
    this.usCode = data;
    this.getAppliedApprovalLevels(this.Session.CompanyCode, this.selectedYear, this.selectedVoucherDocType, this.usCode,0,this.pageSize);
  }


  saveApprLevelsApplied(data){
    this.isSaveDisabled = true;
    this.apprLevelsApplied;
    this.adminService.saveApprovalLevelDetils(data).subscribe(res=>{
      this.isSaveDisabled = false;
      this.toastrService.success("Approval Level Saved successfully")
    },err=>{
      this.isSaveDisabled = false;
      this.toastrService.error("Error Occurred while saving the details");
    });
  }


  displayPagination(PageNumber){
    if(this.selectedPage <= 3)
      return (PageNumber <= 5)
    if(this.selectedPage > 3 && this.selectedPage < this.TotalPages-2)
      return (PageNumber >= this.selectedPage-2 && PageNumber <= this.selectedPage+2)

    if(this.selectedPage >= this.TotalPages-2) 
      return (PageNumber >= this.TotalPages-4)
  }
  selectPage(e){
    this.pages.forEach(x=>x.selected = x.pagenumber == this.selectedPage? true: false)
  }


  searchPaginated(index: any){
    this.activePageRequest = index;
    this.spinner.show();
    this.adminService.getUserPpDocsforAdminManagement(this.Session.CompanyCode, this.Session.FYearID, this.selectedVoucherDocType,this.usCode,index,this.pageSize).subscribe(data=>{
      this.appliedApprovalsLevelList = data?.content;
      this.setPaginationInformations(data,index);
      if (this.initialFlag){
            for(let i=1;i<= data.totalPages; i++)
            {
              this.pages.push({selected: i==1? true : false, pagenumber: i});
            }
            this.initialFlag = false;
            this.TotalPages = data.totalPages
          }
          this.spinner.hide();
    })
  }


  private setPaginationInformations(data: any, page: number) {
    this.pageResults = data.totalPages;
    this.activePageRequest = page;
    let searchResultPagesCurrent;
    if (this.pageResults < 5) {
      searchResultPagesCurrent = data.totalPages;
    } else {
      searchResultPagesCurrent = 5;
    }

    this.searchResultPagesArray = new Array(this.pageResults);
    this.searchResultCurrentPagesArray = new Array(searchResultPagesCurrent);
  }

  getAllUserDocumentTypes(){
    this.adminService.getAllDocumentTypes().subscribe(data=>{
        this.docTypesList = data;
    })
  }
  getAllDocCodesBasedOnDocTypeAndfinYear(CompanyCode : string,FYearID : number, VoucherDocType : string){
    this.adminService.getDocCodesBasedOnCompCodeDocTypeAndFinYear(CompanyCode,FYearID,VoucherDocType).subscribe(data=>{
      this.docCodesList = data;
    })
  }

  sendPendingLeaveRequestsReport() {
    this.spinner.show();
    this.leaveRequestService.sendPendingLeaveRequestsReport().subscribe(
        data => {
          this.spinner.hide();
          this.toastrService.success('The Email was sent');
        },
        err => {
          this.spinner.hide();
          this.toastrService.error('Error Occurred while sending Email');
        });
  }

  sendPendingHrDocumentRequestsReport() {
    this.spinner.show();
    this.hrDocumentRequestService.sendPendingHrDocumentRequestsReport().subscribe(
        data => {
          this.spinner.hide();
          this.toastrService.success('The Email was sent');
        },
        err => {
          this.spinner.hide();
          this.toastrService.error('Error Occurred while sending Email');
        });

  }

}
