<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Stock Master</h4>
        <ngb-tabset>
          <ngb-tab title="Stock Item">
            <ng-template ngbTabContent>

              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-8 col-md-12">
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Group
                              </label>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" disabled class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Sub Group
                              </label>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" disabled class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Item Code
                              </label>
                            </div>
                            <div class="col-lg-8 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" disabled class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Description
                              </label>
                            </div>
                            <div class="col-lg-10 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Part Number
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-1 col-md-6 col-sm-12">
                              <label>
                                UOM
                              </label>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input disabled type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Stock Type
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>
                                  <input type="radio"/> <span> Stock</span>
                                </label>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <label>
                                  <input type="radio"/> <span> Non Stock</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Item Brand
                              </label>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Item Origin
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Remarks
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Thickness
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Active/ Inactive
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <select class="form-control">
                                  <option>Active</option>
                                  <option>Inactive</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Size
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Vat Type
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <select class="form-control">
                                  <option>Applied</option>
                                  <option>Non-Applied</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Color
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Vat Percentage
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Cost
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </div>
                          <hr/>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Production Time
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Loss Percentage
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <input type="number" class="form-control"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Portion Weight
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                No. of Portion
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <input type="number" class="form-control"/>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <label>
                                Portion Cost
                              </label>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-12">
                              <div class="form-group">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                <input type="checkbox"/><span> Include in Other Receips</span>
                              </label>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                <input type="checkbox"/><span> Bill of meterial</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                      <div class="row row-full-height">
                        <div class="card">
                          <div class="card-body">
                            <div class="col-lg-12"><h5> Last Purchase </h5></div>
                            <div class="col-lg-12">
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Doc </label></div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                                <div class="col-lg-5 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Date </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Price </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="number" class="form-control" disabled/></div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Supplier </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="number" class="form-control" disabled/></div>
                                </div>
                                <div class="col-lg-12 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="number" class="form-control" disabled/></div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12"><h5>Purchase YTD</h5></div>
                            <div class="col-lg-12">
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Quantity </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Value </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-12 text-center">
                                  <h6>Last Year</h6>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-12"><label> Value </label></div>
                                <div class="col-lg-9 col-md-6 col-sm-12">
                                  <div class="form-group"><input type="text" class="form-control" disabled/></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-lg-12">
                                  <h5>Client Price</h5>
                                </div>
                                <div class="col-lg-12">
                                  <div class="table-responsive-sm">
                                    <table class="table">
                                      <thead>
                                      <tr>
                                        <th scope="col">Sale Group</th>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Description</th>
                                        <th scope="col text-right">Price</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <th scope="row">A</th>
                                        <td>New Customer</td>
                                        <td>Description</td>
                                        <td>$100.00</td>
                                        <td>01-01-2021</td>
                                        <td>30-01-2021</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">A</th>
                                        <td>New Customer</td>
                                        <td>Description</td>
                                        <td>$100.00</td>
                                        <td>01-01-2021</td>
                                        <td>30-01-2021</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">A</th>
                                        <td>New Customer</td>
                                        <td>Description</td>
                                        <td>$100.00</td>
                                        <td>01-01-2021</td>
                                        <td>30-01-2021</td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                  <div class="row">
                                    <div class="col-lg-2 col-md-6 col-col-sm-12">
                                      <label>Sales Group Desc</label>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                      <div class="form-group">
                                        <input type="text" class="form-control" disabled/>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Supplier</b></ng-template>
            <ng-template ngbTabContent>

            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Unit</b></ng-template>
            <ng-template ngbTabContent></ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Substitute</b></ng-template>
            <ng-template ngbTabContent></ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Bin Location</b></ng-template>
            <ng-template ngbTabContent></ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>File Attachment</b></ng-template>
            <ng-template ngbTabContent></ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Item Details</b></ng-template>
            <ng-template ngbTabContent></ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>
