<!-- Navbar for Wizard Starts -->
<ul class="wizard-navbar">
        <div class="liner"></div>

        <li class="active flex-fill">
            <a [ngClass]="(page==='Personal')?'nav-link active':'nav-link'" uiSrefActive="active" uiSref="personal" data-toggle="tab"
                title="personal">
                <span class="round-tabs one">
                    <i class="icon-User"></i>
                </span>
            </a>
        </li>
        <li class="flex-fill">
            <a [ngClass]="(page==='Work')?'nav-link active':'nav-link'" uiSrefActive="active" uiSref="work" data-toggle="tab" title="work">
                <span class="round-tabs two">
                    <i class="icon-Shopping-Bag"></i>
                </span> 
            </a>
        </li>
        <li class="flex-fill">
            <a [ngClass]="(page==='Address')?'nav-link active':'nav-link'" uiSrefActive="active" uiSref="address" data-toggle="tab" title="address">
                <span class="round-tabs three">
                    <i class="icon-Location-2"></i>
                </span>
            </a>
        </li>
        <li class="flex-fill">
            <a [ngClass]="(page==='Result')?'nav-link active':'nav-link'" uiSrefActive="active" uiSref="result" data-toggle="tab" title="completed">
                <span class="round-tabs four">
                    <i class="icon-Cursor"></i>
                </span>
            </a>
        </li>
</ul>
<!-- Navbar for Wizard Ends -->