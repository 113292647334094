import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ICompany} from '../../shared/model/i-company';
import {CompanyService} from '../../shared/service/company.service';
import {MastercategoryService} from '../../shared/service/mastercategory.service';
import {IcategoryGrades} from '../../shared/model/icategory-grades';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';


declare var require: any;

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.css']
})
export class CategorylistComponent implements OnInit {

  @ViewChild('closeSaveGrade') closeSaveGrade: ElementRef;

  closeResult: string;
  editing = {};
  rows = [];
  listCompany: ICompany[];
  listCategoryGrade: IcategoryGrades[];
  companyName: any;
  currentUser: any;
  percentageData: any[];

  disableCompletion: any[];

  trxTypesList: any[];
  currentGradeData: any;
  totalPercentage: any;

  currentModalSalaryDefinition: any;

  gradeRegModal: NgbModalRef;
  selectedCompany: ICompany;
  differencePercentageList: any[];
  tempDifferencePercentageList: any[];

  gradeWiseAllwData: any[];
  percentageDataList: any[];
  percentageNewDataList: any[];

  loadingIndicator = true;
  reorderable = true;

  percentageCalForm: FormGroup;
  percentageNewCalForm: FormGroup;
  percentageInputList: FormArray;
  percentageNewInputList: FormArray;

  savedDataSuccess: any;

  columns = [{prop: 'code', name: 'Code'}, {prop: 'description', name: 'Description'}, {
    prop: 'salaryfrom',
    name: 'Salary From'
  }, {prop: 'salaryto', name: 'Salary To'}, {prop: 'createdBy', name: 'Created By'}, {name: 'Action', prop: 'action'}];


  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  constructor(
    private modalService: NgbModal,
    private companyService: CompanyService,
    private categoryService: MastercategoryService,
    private fb: FormBuilder,
    private _toastr: ToastrService,

  ) {
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }

  saveGradeForm = this.fb.group({
    company: [''],
    salarygrade: [''],
    description: [''],
    salaryFrom: [''],
    salaryTo: [''],
    code: ['']
  });


  open1(content1, gradeData) {
    this.percentageDataList = [];
    this.totalPercentage = 0;

    this.percentageCalForm = this.fb.group({
      percentageInputList: this.fb.array([])
    });
    this.currentGradeData = gradeData;

    this.categoryService.getAllCompanyPrecentageTypes(gradeData.code).subscribe(gradeWiseAllwData => {

      const percentageInput = this.percentageCalForm.controls.percentageInputList as FormArray;

      gradeWiseAllwData.forEach((gradeWiseAllwArr, index) => {
        this.percentageDataList.push({description: gradeWiseAllwArr.description, trxTypeCode: gradeWiseAllwArr.trxTypeCode, code: gradeWiseAllwArr.code});
        percentageInput.push(
          this.fb.group({
            percentage: new FormControl(gradeWiseAllwArr.percentage)
          })
        );
      });

      let allPercentages = this.percentageCalForm.controls.percentageInputList as FormArray;
      let totalPercentage = allPercentages.value.reduce((acc, cur) => {
        return acc + cur.percentage;
      }, 0);

      this.totalPercentage = totalPercentage;
    });

    this.gradeRegModal = this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'});
    this.gradeRegModal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  savePercentageData(event, gradeData) {

    this.savedDataSuccess = null;
    let allPercentages = this.percentageCalForm.controls.percentageInputList as FormArray;
    let totalPercentage = allPercentages.value.reduce((acc, cur) => {
      return acc + cur.percentage;
    }, 0);

    if (totalPercentage <= 100) {
      this.totalPercentage = totalPercentage;
      if (event.target.value >= 0 && event.target.value <= 100) {

        let precentageUpdate = {code: gradeData.code, description: gradeData.description, percentage: event.target.value};
        this.categoryService.updateGradePercentages(precentageUpdate).subscribe(savedUpdateData => {

          if (savedUpdateData && !this.savedDataSuccess) {
            this._toastr.success("Successfully updated");
            this.savedDataSuccess = savedUpdateData;
          }
        });
      } else {
        event.target.value = 0;
        this._toastr.error("Percentage should be in-between 0 and 100");
      }
    } else {
      this._toastr.error("Total of percentages is higher than 100");
    }

  }

  saveGrades(modal) {
    if (this.saveGradeForm.status === 'VALID') {
      let saveData = this.saveGradeForm.value;
      let comapanyId = saveData.company;
      let currentCompany = this.listCompany.filter(selectedComp => {
        if (selectedComp.code === comapanyId) {
          return selectedComp;
        }
      });

      let saveGrade: IcategoryGrades = {
        code: saveData.code,
        description: saveData.description,
        createdBy: this.currentUser.firstName,
        salaryFrom: saveData.salaryFrom,
        salaryTo: saveData.salaryTo,
        company: this.selectedCompany
      };

      this.categoryService.save(saveGrade).subscribe(dataSave => {
        this._toastr.success("Category/Salary Grade has been saved!");
        this.categoryService.getAllCategoryGradesByCompany(this.selectedCompany.code).subscribe(categoryGradeData => {
          this.listCategoryGrade = categoryGradeData;
          this.rows = categoryGradeData;
        });

        this.saveGradeForm.reset();
        modal.close();
      });
    }
  }

  saveAllNewPercentageData() {
    this.gradeRegModal.close();
  }

  checkValidationSum(newPercentageData, index) {
    if (newPercentageData.target.value !== '') {
      if (newPercentageData.target.value >= 0 && newPercentageData.target.value <= 100) {
        let allPercentages = this.percentageNewCalForm.controls.percentageNewInputList as FormArray;

        let totalPercentage = allPercentages.value.reduce((acc, cur) => {
          return acc + cur.percentage;
        }, 0);

        if (totalPercentage > (100 - this.totalPercentage)) {
          this._toastr.error("Total of percentages is higher than 100");
          //newPercentageData.target.value = 0;
        } else {
          if ((totalPercentage >= 0 && totalPercentage <= 100)) {
            let percentage = newPercentageData.target.value;
            let description = this.percentageNewDataList[index].description;
            let gradeCode = this.currentGradeData.code;
            let currentUser = this.currentUser.firstName;

            let createGradePercent = {categoryGrade: gradeCode, payPerc: percentage, createdBy: currentUser, salaryDefinition: {description: description, createdBy: currentUser}};

            this.categoryService.addGradePercentages(createGradePercent).subscribe(saveData => {
              this.disableCompletion[index] = 1;
              this._toastr.success("Successfully Added!");
            });
          } else {
            this._toastr.error("Percentage should be in-between 0 and 100");
            //newPercentageData.target.value = 0;
          }
        }
      } else {
        this._toastr.error("Percentage should be in-between 0 and 100");
      }
    }

  }

  openSalaryMaster(content2) {

    this.percentageNewDataList = [];
    this.disableCompletion = [];

    this.percentageNewCalForm = this.fb.group({
      percentageNewInputList: this.fb.array([])
    });

    let percentageDataList = this.percentageDataList.map(perDataLst => perDataLst.description);

    this.differencePercentageList = this.trxTypesList.filter(filData => {
      return !percentageDataList.includes(filData.description);
    });

    this.tempDifferencePercentageList = this.differencePercentageList;

    const percentageNewInput = this.percentageNewCalForm.controls.percentageNewInputList as FormArray;

    this.differencePercentageList.forEach((gradeWiseAllwArr, index) => {
      this.percentageNewDataList.push({description: gradeWiseAllwArr.description});
      percentageNewInput.push(
        this.fb.group({
          percentage: new FormControl(gradeWiseAllwArr.percentage)
        })
      );
    })

    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  filterPercentages(event) {
    this.tempDifferencePercentageList = this.percentageNewDataList.filter(filterPercent => {
      if (event.target.value !== '') {
        if (filterPercent.description.indexOf(event.target.value) !== -1) {
          return filterPercent;
        }
      } else {
        return filterPercent;
      }
    });

  }

  openAddSalaryMaster(content3) {
    this.modalService.open(content3, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  checkCodeLength(event) {
    if (event.target.value.length > 4) {
      this._toastr.error("Code length Should be lesser than 5 Digits!");
      event.target.value = '';
    }
  }

  firstModal(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngOnInit(): void {

    this.companyName = '';

    this.companyService.getAll().subscribe(companyData => {
      this.listCompany = companyData;
      this.companyName = companyData[0].name;
      this.selectedCompany = companyData[0];

      this.categoryService.getAllCategoryGradesByCompany(companyData[0].code).subscribe(categoryGradeData => {
        this.listCategoryGrade = categoryGradeData;
        this.rows = categoryGradeData;
      });
    });

    this.categoryService.getAllTRXTypes().subscribe(dataList => {
      this.trxTypesList = dataList;
    });

    this.currentUser = JSON.parse(localStorage.getItem('usersessiondata'));
  }

  listCompanyData(event) {
    let catCode = event.target.value;

    let companyDataSelected = this.listCompany.filter(selectedCom => {
      if (selectedCom.code === catCode) {
        return selectedCom;
      }
    });

    if (companyDataSelected.length === 1) {
      this.companyName = companyDataSelected[0].name;
      this.selectedCompany = companyDataSelected[0];
    }

    this.categoryService.getAllCategoryGradesByCompany(catCode).subscribe(categoryGradeData => {
      this.listCategoryGrade = categoryGradeData;
      this.rows = categoryGradeData;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
