import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-cashreceipt',
  templateUrl: './cashreceipt.component.html',
  styleUrls: ['./cashreceipt.component.css']
})
export class CashreceiptComponent implements OnInit {
  periodVoucher: FormGroup;
  cashreceipt: FormGroup;
  cashreceipt1: FormGroup;
  numbers = [];
  show: boolean = false;
  constructor(private fb: FormBuilder) {
    this.numbers = new Array(30).fill(0).map((x, i) => i);
  }
  public ngOnInit() {
    this.periodVoucher = this.fb.group({
      Period: [""],
      s_date: [""],
      e_date: [""],
      VoucherPeriod: [""],
    });
    this.cashreceipt = this.fb.group({
      Document1: [""],
      Document2: [""],
      CashGLCode: [""],
      SLCode: [""],
      Reference: [""],
      Being: [""],
      RecievedFrom: [""],
      Amount: [""],
      Date: [""],
      CostCenter1: [""],
      CostCenter2: [""],
      RefDate: [""],
      Currency: [""],
      ExchangeRate: [""],
    });
    this.cashreceipt1 = this.fb.group({
      GLDesc: [""],
      Branch: [""],
      CostDesc: [""],
      BOQDesc: [""],
      CategoryDesc: [""],
      AssetRunCost: [""],
      formControlName: [""],
      Area: [""],
      Costcentre: [""],
      ExpDesc: [""],
      BankGL: [""],
      BankSL: [""],
      BankDesc: [""],
    });
  }
  showJV() {
    this.show = !this.show;
  }
}