<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Project Assinging</h4>
                <h6 class="card-subtitle">To use add
                    <code>.r-separator</code> class in the form with form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal r-separator">
                <div class="card-body">
                    <h4 class="card-title">Personal Info</h4>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">First Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="First Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Last Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Last Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Contact No Here">
                        </div>
                    </div>
                </div>
                <div class="card-body bg-light">
                    <h4 class="card-title m-t-10 p-b-20">Requirements</h4>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Company</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Interested In</label>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Desiging</option>
                                <option>Development</option>
                                <option>Videography</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Budget</label>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Less then $5000</option>
                                <option>$5000 - $10000</option>
                                <option>$10000 - $20000</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Select File</label>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Upload</span>
                                </div>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">About Project</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="About Project Here">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Employee Profile</h4>
                <h6 class="card-subtitle">To use add
                    <code>.r-separator</code> class in the form with form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal r-separator">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">First Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="First Name Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Last Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Last Name Here">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row p-t-15">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Username</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" id="inputEmail3" placeholder="Username Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row p-t-15">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Nick Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Nick Name Here">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-light">
                    <h4 class="card-title m-t-10 p-b-20">Contact Info &amp; Bio</h4>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Email</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" id="inputEmail3" placeholder="Email Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Website</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="http://">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row p-t-15">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Contact No Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row p-t-15">
                                <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Bio</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Bio Here">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Employee Timing</h4>
                <h6 class="card-subtitle">To use add
                    <code>.r-separator</code> class in the form with form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal r-separator">
                <div class="card-body">
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Employee Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Employee Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Project Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Project Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Date</label>
                        <div class="col-sm-9">
                            <input type="date" class="form-control" id="inputEmail3" placeholder="Date Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Rate Per Hour</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Rate Per Hour Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Start Time</label>
                        <div class="col-sm-9">
                            <input type="time" class="form-control" id="inputEmail3" placeholder="Start Time Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">End Time</label>
                        <div class="col-sm-9">
                            <input type="time" class="form-control" id="inputEmail3" placeholder="End Time Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Notes</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Notes Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Event Registration</h4>
                <h6 class="card-subtitle">To use add
                    <code>.r-separator</code> class in the form with form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal r-separator">
                <div class="card-body">
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Full Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Full Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Title</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Title Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Company</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Contact No Here">
                        </div>
                    </div>
                    <div class="form-group row p-b-15">
                        <label for="inputEmail3" class="col-sm-3 text-right control-label col-form-label">Existing Customer</label>
                        <div class="col-sm-9">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="customControlValidation2" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation2">Yes</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="customControlValidation3" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation3">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-light">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->