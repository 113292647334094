<!-- row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">General Form</h4>
                <h6 class="card-subtitle"> All with bootstrap element classies </h6>
                <form class="m-t-30">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                    </div>
                    <div class="custom-control custom-checkbox mr-sm-2 m-b-15">
                        <input type="checkbox" class="custom-control-input" id="checkbox0" value="check">
                        <label class="custom-control-label" for="checkbox0">Check Me Out !</label>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- row -->
<!-- .row -->
<div class="row">
    <div class="col-sm-12">
        <div class="card card-body">
            <h4 class="card-title">Default Forms</h4>
            <h5 class="card-subtitle"> All bootstrap element classies </h5>
            <form class="form-horizontal m-t-30">
                <div class="form-group">
                    <label>Default Text
                        <span class="help"> e.g. "George deo"</span>
                    </label>
                    <input type="text" class="form-control" value="George deo...">
                </div>
                <div class="form-group">
                    <label for="example-email">Email
                        <span class="help"> e.g. "example@gmail.com"</span>
                    </label>
                    <input type="email" id="example-email" name="example-email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" class="form-control" value="password">
                </div>
                <div class="form-group">
                    <label>Placeholder</label>
                    <input type="text" class="form-control" placeholder="placeholder">
                </div>
                <div class="form-group">
                    <label>Text area</label>
                    <textarea class="form-control" rows="5"></textarea>
                </div>
                <div class="form-group">
                    <label>Read only input</label>
                    <input class="form-control" type="text" placeholder="Readonly input here…" readonly>
                </div>
                <div class="form-group">
                    <fieldset disabled>
                        <label for="disabledTextInput">Disabled input</label>
                        <input type="text" id="disabledTextInput" class="form-control" placeholder="Disabled input">
                    </fieldset>
                </div>
                <div class="form-group row p-t-20">
                    <div class="col-sm-4">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1">
                            <label class="custom-control-label" for="customCheck1">Check this custom checkbox</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck2">
                            <label class="custom-control-label" for="customCheck2">Check this custom checkbox</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck3">
                            <label class="custom-control-label" for="customCheck3">Check this custom checkbox</label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
                        </div>
                        <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio2">Toggle this custom radio</label>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Input Select</label>
                    <select class="custom-select col-12" id="inlineFormCustomSelect">
                        <option selected>Choose...</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Default file upload</label>
                    <input type="file" class="form-control">
                </div>
                <div class="form-group">
                    <label>Custom File upload</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Upload</span>
                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="inputGroupFile01">
                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Helping text</label>
                    <input type="text" class="form-control" placeholder="Helping text">
                    <span class="help-block">
                        <small>A block of help text that breaks onto a new line and may extend beyond one line.</small>
                    </span>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- /.row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="m-b-0 text-white">Other Sample form</h4>
            </div>
            <form action="#">
                <div class="card-body">
                    <h4 class="card-title">Person Info</h4>
                </div>
                <hr>
                <div class="form-body">
                    <div class="card-body">
                        <div class="row p-t-20">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">First Name</label>
                                    <input type="text" id="firstName" class="form-control" placeholder="John doe">
                                    <small class="form-control-feedback"> This is inline help </small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group has-danger">
                                    <label class="control-label">Last Name</label>
                                    <input type="text" id="lastName" class="form-control form-control-danger" placeholder="12n">
                                    <small class="form-control-feedback"> This field has error. </small>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group has-success">
                                    <label class="control-label">Gender</label>
                                    <select class="form-control custom-select">
                                        <option value="">Male</option>
                                        <option value="">Female</option>
                                    </select>
                                    <small class="form-control-feedback"> Select your gender </small>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Date of Birth</label>
                                    <input type="date" class="form-control">
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Category</label>
                                    <select class="form-control custom-select" data-placeholder="Choose a Category" tabindex="1">
                                        <option value="Category 1">Category 1</option>
                                        <option value="Category 2">Category 2</option>
                                        <option value="Category 3">Category 5</option>
                                        <option value="Category 4">Category 4</option>
                                    </select>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Membership</label>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio11" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio11">Free</label>
                                    </div>
                                    <div class="custom-control custom-radio">
                                        <input type="radio" id="customRadio22" name="customRadio" class="custom-control-input">
                                        <label class="custom-control-label" for="customRadio22">Paid</label>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <h4 class="card-title m-t-40">Address</h4>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 ">
                                <div class="form-group">
                                    <label>Street</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>City</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>State</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Post Code</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Country</label>
                                    <select class="form-control custom-select">
                                        <option>--Select your Country--</option>
                                        <option>India</option>
                                        <option>Sri Lanka</option>
                                        <option>USA</option>
                                    </select>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="card-body">
                            <button type="submit" class="btn btn-success">
                                <i class="fa fa-check"></i> Save</button>
                            <button type="button" class="btn btn-dark">Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Row -->
<!-- .row -->
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Default Basic Forms</h4>
                <h5 class="card-subtitle"> All bootstrap element classies </h5>
                <form class="form">
                    <div class="form-group m-t-40 row">
                        <label for="example-text-input" class="col-2 col-form-label">Text</label>
                        <div class="col-10">
                            <input class="form-control" type="text" value="Artisanal kale" id="example-text-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-search-input" class="col-2 col-form-label">Search</label>
                        <div class="col-10">
                            <input class="form-control" type="search" value="How do I shoot web" id="example-search-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-email-input" class="col-2 col-form-label">Email</label>
                        <div class="col-10">
                            <input class="form-control" type="email" value="bootstrap@example.com" id="example-email-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-url-input" class="col-2 col-form-label">URL</label>
                        <div class="col-10">
                            <input class="form-control" type="url" value="https://getbootstrap.com" id="example-url-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-tel-input" class="col-2 col-form-label">Telephone</label>
                        <div class="col-10">
                            <input class="form-control" type="tel" value="1-(555)-555-5555" id="example-tel-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-password-input" class="col-2 col-form-label">Password</label>
                        <div class="col-10">
                            <input class="form-control" type="password" value="hunter2" id="example-password-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-number-input" class="col-2 col-form-label">Number</label>
                        <div class="col-10">
                            <input class="form-control" type="number" value="42" id="example-number-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-datetime-local-input" class="col-2 col-form-label">Date and time</label>
                        <div class="col-10">
                            <input class="form-control" type="datetime-local" value="2011-08-19T13:45:00" id="example-datetime-local-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-date-input" class="col-2 col-form-label">Date</label>
                        <div class="col-10">
                            <input class="form-control" type="date" value="2011-08-19" id="example-date-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-month-input" class="col-2 col-form-label">Month</label>
                        <div class="col-10">
                            <input class="form-control" type="month" value="2011-08" id="example-month-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-week-input" class="col-2 col-form-label">Week</label>
                        <div class="col-10">
                            <input class="form-control" type="week" value="2011-W33" id="example-week-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-time-input" class="col-2 col-form-label">Time</label>
                        <div class="col-10">
                            <input class="form-control" type="time" value="13:45:00" id="example-time-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-month-input2" class="col-2 col-form-label">Select</label>
                        <div class="col-10">
                            <select class="custom-select col-12" id="example-month-input2">
                                <option selected="">Choose...</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-color-input" class="col-2 col-form-label">Color</label>
                        <div class="col-10">
                            <input class="form-control" type="color" value="#563d7c" id="example-color-input">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="example-color-input" class="col-2 col-form-label">Input Range</label>
                        <div class="col-10">
                            <input type="range" class="form-control" id="range" value="50">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /.row -->
<!-- .row -->
<div class="row">
    <div class="col-sm-12">
        <div class="card card-body">
            <h4 class="card-title">Input groups</h4>
            <h5 class="card-subtitle"> All bootstrap element classies </h5>
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    <form>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">@</span>
                            </div>
                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon2">@example.com</span>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon3">https://example.com/users/</span>
                            </div>
                            <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                            <div class="input-group-append">
                                <span class="input-group-text">.00</span>
                            </div>
                        </div>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <div class="input-group-prepend">
                                <span class="input-group-text">0.00</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                        </div>
                        <!-- form-group -->
                    </form>
                </div>
                <div class="col-sm-12 col-xs-12">
                    <form>
                        <label class="control-label m-t-20">Checkboxes and radio addons</label>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <div class="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" class="custom-control-input" id="checkbox3" value="check">
                                                <label class="custom-control-label" for="checkbox3"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Text input with checkbox">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio5" name="customRadio" class="custom-control-input">
                                                <label class="custom-control-label" for="customRadio5"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Text input with radio button">
                                </div>
                            </div>
                        </div>
                        <label class="control-label m-t-20">Multiple addons</label>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <div class="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" class="custom-control-input" id="checkbox00" value="check">
                                                <label class="custom-control-label" for="checkbox00"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">0.00</span>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Text input with checkbox">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">0.00</span>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-12 col-xs-12">
                    <form class="input-form">
                        <label class="control-label m-t-20">Button addons</label>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-info" type="button">Go!</button>
                                    </div>
                                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                                    <div class="input-group-append">
                                        <button class="btn btn-info" type="button">Go!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-danger" type="button">Hate It</button>
                                    </div>
                                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                                    <div class="input-group-append">
                                        <button class="btn btn-success" type="button">Love It</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- form-group -->
                    </form>
                </div>
                <div class="col-sm-12 col-xs-12">
                    <label class="control-label m-t-20">Dropdown addons</label>
                    <form class="input-form">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="javascript:void(0)">Action</a>
                                            <a class="dropdown-item" href="javascript:void(0)">Another action</a>
                                            <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
                                            <div role="separator" class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:void(0)">Separated link</a>
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" aria-label="Text input with dropdown button">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" aria-label="Text input with dropdown button">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dropdown</button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="javascript:void(0)">Action</a>
                                            <a class="dropdown-item" href="javascript:void(0)">Another action</a>
                                            <a class="dropdown-item" href="javascript:void(0)">Something else here</a>
                                            <div role="separator" class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript:void(0)">Separated link</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.row -->
<!-- .row -->
<div class="row">
    <div class="col-sm-12">
        <div class="card card-body">
            <h4 class="card-title">Input States</h4>
            <h5 class="card-subtitle"> Validation styles for error, warning, and success states on form controls.</h5>
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <form class="form-horizontal row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-control-label" for="inputSuccess1">Input with success</label>
                                <input type="text" class="form-control is-valid" id="inputSuccess1">
                                <div class="valid-feedback">
                                    Success! You've done it.
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-control-label" for="inputDanger1">Input with danger</label>
                                <input type="text" class="form-control is-invalid" id="inputDanger1">
                                <div class="invalid-feedback">
                                    Sorry, that username's taken. Try another?
                                </div>
                            </div>
                        </div>
                    </form>
                    <form class="form-horizontal">
                        <div class="form-group row">
                            <label for="inputHorizontalSuccess" class="col-sm-2 col-form-label">Email</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control is-valid" id="inputHorizontalSuccess" placeholder="name@example.com">
                                <div class="valid-feedback">Success! You've done it.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputHorizontalDnger" class="col-sm-2 col-form-label">Email</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control is-invalid" id="inputHorizontalDnger" placeholder="name@example.com">
                                <div class="invalid-feedback">Sorry, that username's taken. Try another?</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-5 offset-sm-1 col-xs-12">
                    <form class="form-horizontal row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="col-sm-3 form-control-label" for="example-input-small">Small</label>
                                <div class="col-sm-6">
                                    <input type="text" id="example-input-small" name="example-input-small" class="form-control form-control-sm" placeholder="form-control-sm">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 form-control-label" for="example-input-normal">Normal</label>
                                <div class="col-sm-6">
                                    <input type="text" id="example-input-normal" name="example-input-normal" class="form-control" placeholder="Normal">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 form-control-label" for="example-input-large">Large</label>
                                <div class="col-sm-6">
                                    <input type="text" id="example-input-large" name="example-input-large" class="form-control form-control-lg" placeholder="form-control-lg">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 form-control-label">Grid Sizes</label>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control" placeholder=".col-4">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-sm-5 col-sm-offset-3">
                                    <input type="text" class="form-control" placeholder=".col-5">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Disabled Form</h4>
                <form>
                    <fieldset disabled>
                        <div class="form-group">
                            <label for="disabledTextInput1">Disabled input</label>
                            <input type="text" id="disabledTextInput1" class="form-control" placeholder="Disabled input">
                        </div>
                        <div class="form-group">
                            <label for="disabledSelect">Disabled select menu</label>
                            <select id="disabledSelect" class="form-control">
                                <option>Disabled select</option>
                            </select>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="disabledFieldsetCheck" disabled>
                            <label class="form-check-label" for="disabledFieldsetCheck">
                                Can't check this
                            </label>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- /.row -->
<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <h4 class="card-title">Sample Basic Forms</h4>
            <h5 class="card-subtitle"> Bootstrap Elements </h5>
            <div class="row">
                <div class="col-sm-12 col-xs-12">
                    <form>
                        <div class="form-group">
                            <label for="exampleInputEmail111">User Name</label>
                            <input type="text" class="form-control" id="exampleInputEmail111" placeholder="Enter Username">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail12">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail12" placeholder="Enter email">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword11">Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword11" placeholder="Password">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword12">Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword12" placeholder="Confirm Password">
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="checkbox1" value="check">
                                <label class="custom-control-label" for="checkbox1">Remember Me</label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success m-r-10">Submit</button>
                        <button type="submit" class="btn btn-dark">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Sample Form with the Icons</h4>
                <h5 class="card-subtitle">made with bootstrap elements</h5>
                <form class="form p-t-20">
                    <div class="form-group">
                        <label>User Name</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon11">
                                    <i class="ti-user"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon11">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon22">
                                    <i class="ti-email"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon22">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon33">
                                    <i class="ti-lock"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon33">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon4">
                                    <i class="ti-lock"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Confirm Password" aria-label="Password" aria-describedby="basic-addon4">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" class="custom-control-input" id="checkbox10" value="check">
                            <label class="custom-control-label" for="checkbox10">Remember Me</label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-success m-r-10">Submit</button>
                    <button type="submit" class="btn btn-dark">Cancel</button>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Sample Form with the right Icons</h4>
                <h5 class="card-subtitle">made with bootstrap elements</h5>
                <form class="form p-t-20">
                    <div class="form-group">
                        <label>User Name</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Username" aria-label="Username">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="ti-user"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Email" aria-label="Email">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="ti-email"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Password" aria-label="Password">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="ti-lock"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Confirm Password" aria-label="Password">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    <i class="ti-lock"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox mr-sm-2">
                            <input type="checkbox" class="custom-control-input" id="checkbox12" value="check">
                            <label class="custom-control-label" for="checkbox12">Remember Me</label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-success m-r-10">Submit</button>
                    <button type="submit" class="btn btn-dark">Cancel</button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Row -->