import { Routes } from '@angular/router';
import {EnquiryviewComponent} from './enquiryview/enquiryview.component';
import {EmployeeEnquiryAuthGuardService} from './employeeenquiry-auth-guard.service';



export const EmployeeEnquiryRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'employee',
        component: EnquiryviewComponent,
        canActivate: [EmployeeEnquiryAuthGuardService]
      }
    ]
  }
];
