import {Routes} from '@angular/router';
import {InvoiceComponent} from './invoice.component';


export const InvoiceRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'targetsa',
        component: InvoiceComponent
      },
    ]
  }
];
