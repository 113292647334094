import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ChartsService} from '../../shared/service/charts.service';
import * as c3 from 'c3';

@Component({
  selector: 'app-nationality',
  templateUrl: './nationality.component.html',
  styleUrls: ['./nationality.component.css']
})
export class NationalityComponent implements OnInit, AfterViewInit {

  constructor(
    private chartsService: ChartsService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.chartsService.getNationalityChart().subscribe(nationalityChartData => {
      console.log(nationalityChartData);
      let columnNationalData = nationalityChartData.map(nationalityDataSet => {
        return [nationalityDataSet.nationality, nationalityDataSet.percentage];
      });
      console.log(columnNationalData);
      const chart2 = c3.generate({
        bindto: '#nationality',
        data: {
          columns: columnNationalData,

          type: 'donut'
        },
        donut: {
          label: {
            show: false
          },
          title: 'Nationalities',
          width: 35
        },
        legend: {
          hide: true
        },
        color: {
          pattern: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb']
        }
      });
    });
  }

}
