<!-- ============================================================== -->
<!-- Welcome back  -->
<!-- ============================================================== -->
<app-welcome></app-welcome>
<!-- ============================================================== -->
<!-- Sales Summery -->
<!-- ============================================================== -->
<app-product-info></app-product-info>
<!-- ============================================================== -->
<!-- place order / Exchange -->
<!-- ============================================================== -->
<app-mix-stats></app-mix-stats>
<!-- ============================================================== -->
<!-- project of the month -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-sm-12 col-lg-8">
        <app-project></app-project>
    </div>
    <div class="col-sm-12 col-lg-4">
        <app-weather></app-weather>
    </div>
</div>
<!-- ============================================================== -->
<!-- Task, Feeds -->
<!-- ============================================================== -->
<div class="row">
    <!-- column -->
    <div class="col-sm-12 col-lg-6">
        <app-tasklist></app-tasklist>
    </div>
    <!-- column -->
    <div class="col-sm-12 col-lg-6">
        <app-feeds></app-feeds>
    </div>
</div>
<div class="row">
    <div class="col-lg-8">
        <app-review></app-review>
    </div>
    <div class="col-lg-4">
        <app-earnings></app-earnings>
    </div>
</div>