<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Basic demo</h4>
                <h6 class="card-subtitle">This is basic demo for rating {{currentRate}}</h6>
                <ngb-rating [(rate)]="currentRate"></ngb-rating>
                
                <div class="m-t-10">Rate: <b>{{currentRate}}</b></div>
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Custom star template <span class="badge badge-success text-white">New</span></h4>
                <h6 class="card-subtitle">Custom rating template provided as child element</h6>
               <ngb-rating [(rate)]="currentRate">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
            
            <div class="m-t-10">Rate: <b>{{currentRate}}</b></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Events and readonly ratings</h4>
                <h6 class="card-subtitle">This is demo for readonly ratings </h6>
                <ngb-rating [(rate)]="selected" (hover)="hovered=$event" (leave)="hovered=0" [readonly]="readonly"></ngb-rating>
                <div class="m-t-10">Selected: <b>{{selected}}</b><br/>Hovered: <b>{{hovered}}</b>
                </div><button class="btn btn-sm m-t-10 btn-outline-{{readonly ? 'danger' : 'success'}}" (click)="readonly = !readonly">
                  {{ readonly ? "readonly" : "editable"}}
                </button>
  			</div>
        </div>
    </div>
    
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Custom decimal rating</h4>
                <h6 class="card-subtitle">Custom rating template provided via a variable. Shows fine-grained rating display</h6>
                <ng-template #t let-fill="fill">
                  <span *ngIf="fill === 100" class="heart full">&hearts;</span>
                  <span *ngIf="fill === 0" class="heart">&hearts;</span>
                  <span *ngIf="fill < 100 && fill > 0" class="heart">
                    <span class="half" [style.width.%]="fill">&hearts;</span>&hearts;
                  </span>
                </ng-template>

                <ngb-rating [(rate)]="currentRate2" [starTemplate]="t" [readonly]="true" max="5"></ngb-rating>
                <div>Rate: <b>{{currentRate2}}</b></div>
                <button class="btn btn-sm btn-outline-primary" (click)="currentRate2 = 1.50">1.50</button>
                <button class="btn btn-sm btn-outline-primary" (click)="currentRate2 = 4.72">4.72</button>
				
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Form integration</h4>
                <h6 class="card-subtitle">NgModel and reactive forms can be used without the 'rate' binding</h6>
                <div class="form-group" [class.has-success]="ctrl.valid" [class.has-danger]="ctrl.invalid">
                  <ngb-rating [formControl]="ctrl"></ngb-rating>
                  <div class="form-control-feedback">
                    <div *ngIf="ctrl.valid">Thanks!</div>
                    <div *ngIf="ctrl.errors">Please rate us</div>
                  </div>
                </div>

                <hr>
                <pre>Model: <b>{{ ctrl.value }}</b></pre>
                <button class="btn btn-sm btn-outline-{{ ctrl.disabled ? 'danger' : 'success'}}" (click)="toggle()">
                  {{ ctrl.disabled ? "control disabled" : " control enabled" }}
                </button>
                <button class="btn btn-sm btn-outline-primary" (click)="ctrl.setValue(null)">Clear</button>
            </div>
        </div>
    </div>
</div>