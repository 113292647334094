import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../shared/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentAuthGuardService implements CanActivate {

  constructor(public router: Router, private authService: AuthService) {
  }

  canActivate() {

    if (this.authService.isRecruitmentUser()) {
      return true;
    }
    return false;
  }
}
