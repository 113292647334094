import { Routes } from '@angular/router';
import {DatachartsComponent} from './datacharts.component';
import {EmployeeincrementchartpageComponent} from './employeeincrementchartpage/employeeincrementchartpage.component';
import {EmployeesalarytotalComponent} from './employeesalarytotal/employeesalarytotal.component';
import {EmployeesalaryincrementhistoryComponent} from './employeesalaryincrementhistory/employeesalaryincrementhistory.component';
import {DataChartsGuard} from './data-charts.guard';

export const DataChartRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'employeesalarybracket',
        canActivate: [DataChartsGuard],
        component: DatachartsComponent
      },
      {
        path: 'employeeincrement',
        canActivate: [DataChartsGuard],
        component: EmployeeincrementchartpageComponent
      },
      {
        path: 'employeesalarytotal',
        canActivate: [DataChartsGuard],
        component: EmployeesalarytotalComponent
      },
      {
        path: 'employeesalaryincrementhistory',
        canActivate: [DataChartsGuard],
        component: EmployeesalaryincrementhistoryComponent
      }
    ]
  }
];
