import { Routes } from '@angular/router';
import {InquiryComponent} from './inquiry/inquiry.component';
import {EmployeeDetailComponent} from './employee-detail/employee-detail.component';


export const ProvisionRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'employee-inquiry',
        component: InquiryComponent
      },
      {
        path: 'employee-detail/:empNo',
        component: EmployeeDetailComponent
      }
    ]
  }
];
