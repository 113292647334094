import { Component, OnInit } from '@angular/core';
import {EmployeesService} from '../../shared/service/employees.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IEmployee} from '../../shared/model/employee';
import {ChartsService} from '../../shared/service/charts.service';
import * as HighCharts from 'highcharts';

@Component({
  selector: 'app-employeesalaryincrementhistory',
  templateUrl: './employeesalaryincrementhistory.component.html',
  styleUrls: ['./employeesalaryincrementhistory.component.css']
})
export class EmployeesalaryincrementhistoryComponent implements OnInit {

  chart: any;
  employeeEnable: boolean = true;
  selectedOptionEmployee: any;

  totalProfessionPages: any;
  totalProfessionPagesArray: any[];
  arrayProfessionList: any[] = [];
  arrayFullProfessionalList: any[];
  arrayFullProfessionalSearchList: any[];
  activeProfessionFrom: any;
  selectedProfessionFrom: any;
  selectedProfessionCodeFrom: any;
  professionSearch: any;
  professionFromList = '';

  selectedEmployee: any;
  selectedEmployeeSet: any;

  arrayPositions: any[] = [];
  arrayFullPositions: any[] = [];
  arrayFullSearchPositions: any[] = [];
  selectedPositions: any;
  selectedPositionsCodes: any;
  activePositionFrom: any;
  totalPositionPages: any;
  positionSearch: any;
  totalPositionPagesArray: any[];

  employees: IEmployee[];
  allEmployees: IEmployee[];
  allMappedEmployees: any[];
  allMappedEmployeesTemp: any[];
  count: any = 10;
  pageSize: any = 8;
  page: any =1;
  config = {
    displayKey: "name",
    search: true,
    limitTo: 7,
  };

  constructor(
    private employeeService: EmployeesService,
    private modalService: NgbModal,
    private chartService: ChartsService
  ) { }

  ngOnInit(): void {
    this.employeeService.getPrefessions().subscribe(searchProfessionData => {
      console.log(searchProfessionData);
      this.totalProfessionPages = Math.ceil(searchProfessionData.length / 10);
      this.totalProfessionPagesArray = new Array(this.totalProfessionPages);
      this.activeProfessionFrom = 0;
      this.arrayFullProfessionalList = searchProfessionData;

      this.arrayFullProfessionalList.map((currElement, index) => {
        if (index <= 10) {
          this.arrayProfessionList.push(currElement);
        }
      });

      console.log(this.arrayProfessionList);

    });
    this.employeeService.getPositions().subscribe(allPositions => {
      console.log(allPositions);

      this.totalPositionPages = Math.ceil(allPositions.length / 10);
      this.totalPositionPagesArray = new Array(this.totalPositionPages);
      this.activePositionFrom = 0;
      this.arrayFullPositions = allPositions;

      this.arrayFullPositions.map((currElement, index) => {
        if (index <= 10) {
          this.arrayPositions.push(currElement);
        }
      });
      //this.arrayPositions = allPositions;
    });

    this.loadEmployees();
  }

  loadEmployees() {
    this.employeeService.getEmployeePage(this.page,this.pageSize).subscribe(data => {
      this.employees = data.content;
      this.allEmployees = data.content;
      this.count = data.totalPages;
      this.allMappedEmployees = this.employees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
      this.allMappedEmployeesTemp = this.allMappedEmployees;
    });
    this.employeeService.getAll().subscribe(data => {
      this.allEmployees = data;
      this.allMappedEmployees = this.allEmployees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
      this.allMappedEmployeesTemp = this.allMappedEmployees;
    });
  }

  searchChange($event) {
    /*console.log($event);
    let empNo = $event;
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      this.selectedEmployee = data;
      this.hrdocumentRequestForm = this.createHrdocumentRequestForm(data);
      this.getSelectedEmpDocRequests(empNo);
    })*/
    let empNameOrNumber = $event;

    if (empNameOrNumber !== '' && empNameOrNumber.replace(/\s/g, '').length) {
      console.log(empNameOrNumber);
      if (!isNaN(Number(empNameOrNumber))) {
        this.employeeService.getByEmpNoOrName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      } else {
        this.employeeService.getByName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      }
    } else {
      if (this.allEmployees) {
        this.allMappedEmployees = this.allEmployees.map(dataEmp => {
          return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
        });
      }
    }
  }

  onEmployeeChange(employee) {
    console.log(employee);
    console.log(employee.value.value.empNo);
    if (employee.value.value.empNo) {
      this.selectedEmployee = employee.value.value.empNo;
      this.selectedEmployeeSet = employee.value.value;
      //this.selectedOptionEmployee = [employee.value.name];
      console.log(this.selectedOptionEmployee);

      console.log(this.selectedEmployeeSet);
      this.employeeEnable = false;
    } else {
      this.employeeEnable = true;
    }
  }

  removeProfessionFilter(selectedProfession) {
    this.selectedProfessionFrom = null;
  }

  removeEmployeeFilter(removeEmployeeFilter) {
    console.log(removeEmployeeFilter);
    this.selectedEmployee = null;
    this.selectedEmployeeSet = null;
    this.employeeEnable = true;
    this.selectedOptionEmployee = null;
  }

  removePositionFilter(selectedPosition) {
    this.selectedPositions = null;
  }

  openStartProfession(modalStartProfession) {
    this.arrayProfessionList = [];
    this.professionSearch = '';
    let searchResult = this.arrayFullProfessionalList?.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.professionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalProfessionPages = Math.ceil(searchResult?.length / 10);
    this.activeProfessionFrom = 0;
    this.totalProfessionPagesArray = new Array(this.totalProfessionPages);

    this.arrayFullProfessionalSearchList = searchResult;

    this.arrayFullProfessionalSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayProfessionList.push(currElement);
      }
    });
    this.modalService.open(modalStartProfession, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  openPosition(modalPosition) {
    this.arrayPositions = [];
    this.positionSearch = '';
    let searchResult = this.arrayFullPositions.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.positionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalPositionPages = Math.ceil(searchResult.length / 10);
    this.activePositionFrom = 0;
    this.totalPositionPagesArray = new Array(this.totalPositionPages);

    this.arrayFullSearchPositions = searchResult;

    this.arrayFullSearchPositions.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayPositions.push(currElement);
      }
    });
    this.modalService.open(modalPosition, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  checkPosition(event, position) {
    console.log(position);
    if (event.target.checked === true) {
      this.selectedPositions = position;
      this.selectedPositionsCodes = position.id;
    }
    console.log(this.selectedPositions);

  }

  checkProfessionFrom(event, professionFrom) {
    if (event.target.checked === true) {
      this.selectedProfessionFrom = professionFrom;
      this.selectedProfessionCodeFrom = professionFrom.code;
    }

    console.log(this.selectedProfessionFrom);
  }

  searchProfessionList(event) {
    this.arrayProfessionList = [];
    this.professionSearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullProfessionalList.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.professionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalProfessionPages = Math.ceil(searchResult.length / 10);
    this.activeProfessionFrom = 0;
    this.totalProfessionPagesArray = new Array(this.totalProfessionPages);

    this.arrayFullProfessionalSearchList = searchResult;

    this.arrayFullProfessionalSearchList.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayProfessionList.push(currElement);
      }
    });
  }

  paginatedProfessionTo(paginationNum) {
    this.activeProfessionFrom = paginationNum;
    /*this.employeeService.getPrefessions(paginationNum, 10).subscribe(searchProfessionData => {
      console.log(searchProfessionData);
      this.arrayProfessionList = searchProfessionData.content;
    });*/

    this.arrayProfessionList = [];

    if (this.arrayFullProfessionalSearchList && this.arrayFullProfessionalSearchList.length > 0) {
      this.arrayFullProfessionalSearchList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayProfessionList.push(currElement);
        }
      });
    } else {
      this.arrayFullProfessionalList.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayProfessionList.push(currElement);
        }
      });
    }

  }

  paginatedPositionTo(paginationNum) {
    this.activePositionFrom = paginationNum;
    this.arrayPositions = [];

    if (this.arrayFullSearchPositions && this.arrayFullSearchPositions.length > 0) {
      this.arrayFullSearchPositions.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayPositions.push(currElement);
        }
      });
    } else {
      this.arrayFullPositions.map((currElement, index) => {
        index = index + 1;
        if (paginationNum === Math.floor(index / 10)) {
          this.arrayPositions.push(currElement);
        }
      });
    }
  }

  searchPositionList(event) {
    this.arrayPositions = [];
    this.positionSearch = event.target.value.toLowerCase();
    let searchResult = this.arrayFullPositions.filter(searchData => {
      if (searchData.description.toLowerCase().includes(this.positionSearch)) {
        return searchData;
      }
      return false;
    });

    this.totalPositionPages = Math.ceil(searchResult.length / 10);
    this.activePositionFrom = 0;
    this.totalPositionPagesArray = new Array(this.totalPositionPages);

    this.arrayFullSearchPositions = searchResult;

    this.arrayFullSearchPositions.map((currElement, index) => {
      index = index + 1;
      if (index <= 10) {
        this.arrayPositions.push(currElement);
      }
    });
  }

  generateChart() {
    if (this.selectedEmployee) {
      let searchCriteria = {empNo: this.selectedEmployee};
      console.log(searchCriteria);

      this.chartService.getBySalaryIncrementHistory(searchCriteria).subscribe(chartData => {
        console.log(chartData);

        let years = chartData['series'];

        let seriesDataArray = [];
        Object.keys(chartData['data']).map(function(key, index) {
          seriesDataArray.push(chartData['data'][key])
          return seriesDataArray;
        });

        seriesDataArray = seriesDataArray.map(seriesAltered => {
          return {type: undefined, name: seriesAltered.name, data: seriesAltered.data};
        });

        let yearsArr = [];
        years.map(yearsData => {
          yearsArr.push(yearsData.toString());
        });



        HighCharts.chart('salaryIncrementHistoryChart', {
          chart: {
            type: 'column'
          },

          title: {
            text: 'Employee Salary Increments'
          },

          xAxis: {
            categories: yearsArr
          },

          yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
              text: 'Number'
            }
          },

          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },

          series: seriesDataArray
        });

      });
    } else {


      if (this.selectedProfessionFrom || this.selectedPositions) {
        let searchCriteria = {profession: this.selectedProfessionFrom, position: this.selectedPositions};
        this.chartService.getBySalaryIncrementHistory(searchCriteria).subscribe(chartData => {
          console.log(chartData);

          let years = chartData['series'];

          let seriesDataArray = [];
          Object.keys(chartData['data']).map(function(key, index) {
            seriesDataArray.push(chartData['data'][key])
            return seriesDataArray;
          });

          seriesDataArray = seriesDataArray.map(seriesAltered => {
            return {type: undefined, name: seriesAltered.name, data: seriesAltered.data};
          });

          let yearsArr = [];
          years.map(yearsData => {
            yearsArr.push(yearsData.toString());
          });


          HighCharts.chart('salaryIncrementHistoryChart', {
            chart: {
              type: 'column'
            },

            title: {
              text: 'Employee Salary Increments'
            },

            xAxis: {
              categories: yearsArr
            },

            yAxis: {
              allowDecimals: false,
              min: 0,
              title: {
                text: 'Number'
              }
            },

            plotOptions: {
              column: {
                stacking: 'normal'
              }
            },

            series: seriesDataArray
          });

        });
      }
    }
  }

}
