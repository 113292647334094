import {Routes} from '@angular/router';

import {NotfoundComponent} from './404/not-found.component';
import {LockComponent} from './lock/lock.component';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {Signup2Component} from './signup2/signup2.component';
import {Login3Component} from './login3/login3.component';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login'
      },
      {
        path: '404',
        component: NotfoundComponent
      },
      {
        path: 'lock',
        component: LockComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      // {
      //   path: 'login2',
      //   component: Login2Component
      // },
      {
        path: 'login3',
        component: Login3Component
      },
      {
        path: 'signup',
        component: SignupComponent
      },
      {
        path: 'signup2',
        component: Signup2Component
      }
    ]
  }
];
