import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {UomRouting} from './uom.routing';
import { UommainComponent } from './uommain/uommain.component';
import { StorebinlocationsComponent } from './storebinlocations/storebinlocations.component';
import { StoregroupingComponent } from './storegrouping/storegrouping.component';
import { OtherchargesComponent } from './othercharges/othercharges.component';
import { StockmasterComponent } from './stockmaster/stockmaster.component';
import { SalesqueriesComponent } from './salesqueries/salesqueries.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(UomRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [
    UommainComponent,
    StorebinlocationsComponent,
    StoregroupingComponent,
    OtherchargesComponent,
    StockmasterComponent,
    SalesqueriesComponent
  ],
  providers: [
    DatePipe
  ]
})
export class UomModule {}
