import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {CompanyService} from '../../../shared/service/company.service';
import {EmployeesService} from '../../../shared/service/employees.service';
import {LocalstorageService} from '../../../shared/service/localstorage.service';
import {AuthService} from '../../../shared/service/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {EmployeeListComponent} from '../../../employee/employee-list/employee-list.component';
import {CandidateSelectionDto} from '../../../shared/dto/recruitment/selection/candidateSelectionDto';
import {RecruitmentCandidateSelectionService} from '../../../shared/service/recruitment-candidate-selection-service';
import {RecruitmentCandidateService} from '../../../shared/service/recruitment-candidate-service';
import {CandidateSelectionSearchDto} from '../../../shared/dto/recruitment/selection/candidateSelectionSearchDto';
import {CandidateSearchDto} from '../../../shared/dto/recruitment/candidate/candidateSearchDto';
import Swal from 'sweetalert2';
import {RecruitmentRequestService} from '../../../shared/service/recruitment-request-service';
import {RequestSearchDto} from '../../../shared/dto/recruitment/request/requestSearchDto';
import {CandidateDto} from '../../../shared/dto/recruitment/candidate/candidateDto';

export interface PopupTable {
  Code: string;
  EmpNo: string;
  Name: string;
  Selected: boolean;
  RowObject?: any;
}

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-selection-details.component.html',
  styleUrls: ['./candidate-selection-details.component.css']
})
export class CandidateSelectionDetailsComponent implements OnInit {

  candidateSelectionFormGroup: FormGroup;
  candidateSelectionDto: CandidateSelectionDto;
  idParam: number;
  companies = [];
  candidates: CandidateDto[];
  recruitmentRequests: [];
  popUpTableData: PopupTable[] = [];
  interviewee1: any;
  interviewee2: any;
  interviewee3: any;
  selectedCompanyCode: any;
  selectedCandidateId: any;
  selectedPositionCode: any;
  requestedEmployee = this.authService.getCurrentUserInformations()?.employee;
  currentUserAllowedToConfirmOrReject: boolean;
  confirmedBy: string[];
  status: string;
  docNo: string;
  selectedRecruitmentRequest: any;

  @ViewChild('company') company: ElementRef;
  @ViewChild('department') department: ElementRef;

  constructor(private candidateSelectionService: RecruitmentCandidateSelectionService, private candidateService: RecruitmentCandidateService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
              private companyService: CompanyService,
              private employeesService: EmployeesService, private authService: AuthService, private localstorage: LocalstorageService,
              private dialog: MatDialog, private recruitmentRequestService: RecruitmentRequestService) {
  }


  ngOnInit(): void {
    this.spinner.show();
    this.instantiateForm();
  }

  instantiateForm() {
    this.candidateSelectionFormGroup = this.formBuilder.group({
      id: [''],
      offeredDesignation: [''],
      offeredSalary: [''],
      positionAdvertisedDate: [''],
      newspaperSource: [''],
      onlineSource: [''],
      othersSource: [''],
      noCandidateInterviewed: [''],
      noShortlistedInterviewed: [''],
      interviewee1Remarks: [''],
      interviewee2Remarks: [''],
      interviewee3Remarks: ['']
    });

    if (this.activatedRoute.snapshot.queryParams['id']) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.idParam = params['id'];
        if (this.idParam) {
          this.loadCompanies(false);
          this.loadCandidates(false);
          this.searchCandidateSelectionById(this.idParam);
        }
      });
    } else {
      this.candidateSelectionDto = new CandidateSelectionDto();
      this.loadCompanies(true);
      this.loadCandidates(true);
    }
  }

  searchCandidateSelectionById(id) {
    const candidateSelectionSearchDto = new CandidateSelectionSearchDto();
    candidateSelectionSearchDto.id = id;
    this.candidateSelectionService.search(candidateSelectionSearchDto, 0, 10).subscribe(data => {
      if (data.numberOfElements === 1) {
        this.candidateSelectionDto = data.content[0];
        this.currentUserAllowedToConfirmOrReject = this.candidateSelectionDto.currentUserAllowedToConfirmOrReject;
        this.confirmedBy = this.candidateSelectionDto.confirmedBy;
        this.status = this.candidateSelectionDto.status;
        this.candidateSelectionFormGroup = this.formBuilder.group({
          id: [this.candidateSelectionDto.id],
          offeredDesignation: [this.candidateSelectionDto.offeredDesignation],
          offeredSalary: [this.candidateSelectionDto.offeredSalary],
          positionAdvertisedDate: [this.candidateSelectionDto.positionAdvertisedDate],
          newspaperSource: [this.candidateSelectionDto.newspaperSource],
          onlineSource: [this.candidateSelectionDto.onlineSource],
          othersSource: [this.candidateSelectionDto.othersSource],
          noCandidateInterviewed: [this.candidateSelectionDto.noCandidateInterviewed],
          noShortlistedInterviewed: [this.candidateSelectionDto.noShortlistedInterviewed],
          interviewee1Remarks: [this.candidateSelectionDto.interviewee1Remarks],
          interviewee2Remarks: [this.candidateSelectionDto.interviewee2Remarks],
          interviewee3Remarks: [this.candidateSelectionDto.interviewee3Remarks]
        });
        this.docNo = this.candidateSelectionDto?.id.toString();
        this.selectedCompanyCode = this.candidateSelectionDto.compCode;
        this.selectedCandidateId = this.candidateSelectionDto.candidateID;
        this.selectedRecruitmentRequest = this.candidateSelectionDto.recruitmentRequestId;
        const employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
        if (this.candidateSelectionDto.interviewee1Id != null) {
          this.interviewee1 = employeesData.filter(employee => employee.id === this.candidateSelectionDto.interviewee1Id &&
              employee.pmlId === this.candidateSelectionDto.interviewee1PmlId)[0];
        }
        if (this.candidateSelectionDto.interviewee2Id != null) {
          this.interviewee2 = employeesData.filter(employee => employee.id === this.candidateSelectionDto.interviewee2Id &&
              employee.pmlId === this.candidateSelectionDto.interviewee2PmlId)[0];
        }
        if (this.candidateSelectionDto.interviewee3Id != null) {
          this.interviewee3 = employeesData.filter(employee => employee.id === this.candidateSelectionDto.interviewee3Id &&
              employee.pmlId === this.candidateSelectionDto.interviewee3PmlId)[0];
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._toastr.error('Unable to search the candidate selection [' + id + ']');
        this.router.navigate(['/recruitment/candidate/selection/list']);
      }
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }


  save() {
    this.candidateSelectionDto = this.candidateSelectionFormGroup.value;
    this.setAdditionalInfo();
    this.validateForm();
    this.spinner.show();
    this.candidateSelectionService.save(this.candidateSelectionDto).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The candidate selection [' + data.id + '] was successfully saved !');
      this.router.navigate(['/recruitment/candidate/selection/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  update() {
    this.candidateSelectionDto = this.candidateSelectionFormGroup.value;
    this.setAdditionalInfo();
    this.validateForm();
    this.spinner.show();
    this.candidateSelectionService.update(this.candidateSelectionDto).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The candidate selection [' + data.id + '] was successfully updated !');
      this.router.navigate(['/recruitment/candidate/selection/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  validateForm() {
    console.log(this.candidateSelectionDto);
    if (this.candidateSelectionDto.compCode === null || this.candidateSelectionDto.compCode === '') {
      this.spinner.hide();
      this._toastr.error('The Company is Mandatory !');
      throw new Error(('The Company is Mandatory !'));
    } else if (this.candidateSelectionDto.offeredDesignation === null || this.candidateSelectionDto.offeredDesignation === '') {
      this.spinner.hide();
      this._toastr.error('The Offered Designation is Mandatory !');
      throw new Error(('The Offered Designation is Mandatory !'));
    } else if (this.candidateSelectionDto.offeredSalary === null || this.candidateSelectionDto.offeredSalary === '') {
      this.spinner.hide();
      this._toastr.error('The Offered Salary is Mandatory !');
      throw new Error(('The Offered Salary is Mandatory !'));
    } else if (this.candidateSelectionDto.positionAdvertisedDate === null ||
        this.candidateSelectionDto.positionAdvertisedDate.toString() === '') {
      this.spinner.hide();
      this._toastr.error('The Position Advertised Date is Mandatory !');
      throw new Error(('The Position Advertised Date is Mandatory !'));
    } else if (this.candidateSelectionDto.candidateID === null) {
      this.spinner.hide();
      this._toastr.error('The Candidate is Mandatory !');
      throw new Error(('The Candidate is Mandatory !'));
    } else if (this.candidateSelectionDto.recruitmentRequestId === null) {
      this.spinner.hide();
      this._toastr.error('The Recruitment Request is Mandatory !');
      throw new Error(('The Recruitment Request is Mandatory !'));
    }

  }

  reset() {
    if (confirm('Are you sure you want to reset the form ?')) {
      this.candidateSelectionFormGroup = this.formBuilder.group({
        id: [''],
        offeredDesignation: [''],
        offeredSalary: [''],
        positionAdvertisedDate: [''],
        newspaperSource: [''],
        onlineSource: [''],
        othersSource: [''],
        noCandidateInterviewed: [''],
        noShortlistedInterviewed: [''],
        interviewee1Remarks: [''],
        interviewee2Remarks: [''],
        interviewee3Remarks: ['']
      });
      this.candidateSelectionDto = new CandidateSelectionDto();
      this.candidateSelectionDto.id = null;
      this.interviewee1 = null;
      this.interviewee2 = null;
      this.interviewee3 = null;
      this.currentUserAllowedToConfirmOrReject = null;
      this.confirmedBy = null;
      this.docNo = null;
      this.generateDocNumber(this.selectedCompanyCode);
    }
    this.idParam = null;
  }

  loadCompanies(update) {
    this.spinner.show();
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
      const compCode = data[0]?.code;
      if (update) {
        this.selectedCompanyCode = compCode;
        this.generateDocNumber(compCode);
        this.loadRecruitmentRequest(true);
      } else {
        this.loadRecruitmentRequest(false);
      }
    });
  }

  generateDocNumber(compCode) {
    this.spinner.show();
    this.candidateSelectionService.generateDocNumber(compCode).subscribe(data => {
      this.spinner.hide();
      this.docNo = data;
      this.candidateSelectionDto.id = data;
    }, error => {
      this.docNo = null;
      this.candidateSelectionDto.id = null;
      this.spinner.hide();
      this._toastr.error('Voucher doesn\'t exist in company ' + compCode + '.\n' + 'Please contact Administrator');
    });
  }

  loadCandidates(update) {
    this.spinner.show();
    this.candidateService.searchCandidate(new CandidateSearchDto(), 0, 500).subscribe(data => {
      this.candidates = data.content;
      if (update) {
        this.selectedCandidateId = data.content[0]?.id;
      }
      this.spinner.hide();
    });
  }

  loadRecruitmentRequest(update) {
    this.spinner.show();
    const requestSearchDto = new RequestSearchDto();
    requestSearchDto.compCode = this.selectedCompanyCode;
    requestSearchDto.status = 'CONFIRMED';
    this.recruitmentRequestService.search(requestSearchDto, 0, 500).subscribe(data => {
      this.recruitmentRequests = data.content;
      if (update) {
        this.selectedRecruitmentRequest = data.content[0]?.referenceNumber;
      }
      this.spinner.hide();
    });
  }

  setCompany(event) {
    const company = event.target.value;
    this.selectedCompanyCode = company;
    this.generateDocNumber(company);
    this.loadRecruitmentRequest(true);
  }


  selectInterviewee1() {
    const dialogRef = this.dialog.open(EmployeeListComponent);
    dialogRef.afterClosed().subscribe(employee => {
      if (employee != null && employee?.id !== this.interviewee2?.id && employee?.id !== this.interviewee3?.id) {
        this.interviewee1 = employee;
      }
    });
  }


  selectInterviewee2() {
    const dialogRef = this.dialog.open(EmployeeListComponent);
    dialogRef.afterClosed().subscribe(employee => {
      if (employee != null && employee?.id !== this.interviewee1?.id && employee?.id !== this.interviewee3?.id) {
        this.interviewee2 = employee;
      }
    });
  }


  selectInterviewee3() {
    const dialogRef = this.dialog.open(EmployeeListComponent);
    dialogRef.afterClosed().subscribe(employee => {
      if (employee != null && employee?.id !== this.interviewee1?.id && employee?.id !== this.interviewee2?.id) {
        this.interviewee3 = employee;
      }
    });
  }

  setCandidate(event) {
    const candidate = event.target.value;
    if (candidate != null) {
      this.selectedCandidateId = candidate;
    } else {
      this.selectedCandidateId = null;
    }
  }

  setRecruitmentRequest(event) {
    const recruitmentRequest = event.target.value;
    if (recruitmentRequest != null) {
      this.selectedRecruitmentRequest = recruitmentRequest;
    } else {
      this.selectedRecruitmentRequest = null;
    }
  }

  isOwnerOfDocument(): boolean {
    const currentUserId = this.authService.getCurrentUserInformations().employee.id;
    if (!this.idParam) {
      return true;
    }
    return currentUserId === this.candidateSelectionDto?.createdById;
  }

  canConfirm() {
    return this.currentUserAllowedToConfirmOrReject && this.idParam &&
        (this.status === 'PASSED' || this.status === 'CONFIRMING');
  }

  confirm() {
    this.candidateSelectionDto = this.candidateSelectionFormGroup.value;
    const id = this.candidateSelectionDto.id;
    this.spinner.show();
    this.candidateSelectionService.confirm(id).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The Candidate Selection [' + id + '] was successfully confirmed !');
      this.router.navigate(['/recruitment/candidate/selection/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  canReject() {
    return this.currentUserAllowedToConfirmOrReject && this.idParam &&
        (this.status === 'PASSED' || this.status === 'CONFIRMING');
  }

  reject() {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {
        const remark = result.value;
        this.candidateSelectionDto = this.candidateSelectionFormGroup.value;
        this.candidateSelectionDto.rejectRemark = remark;
        this.spinner.show();
        this.candidateSelectionService.reject(this.candidateSelectionDto).subscribe(data => {
          this.spinner.hide();
          this._toastr.success('The Candidate Selection [' + this.candidateSelectionDto.id + '] was successfully rejected !');
          this.router.navigate(['/recruitment/candidate/selection/list']);
        }, error => {
          this._toastr.error(error);
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }

    });
  }

  canUpdate() {
    return this.docNo && this.idParam && (this.status === 'PENDING' || this.status === 'REJECTED');
  }

  canSave() {
    return this.docNo && !this.idParam && this.status == null;
  }

  canReset() {
    return !this.idParam && this.status == null;
  }

  pass() {
    this.candidateSelectionDto = this.candidateSelectionFormGroup.value;
    const requestNumber = this.candidateSelectionDto.id;
    this.spinner.show();
    this.candidateSelectionService.pass(requestNumber).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The Candidate Selection [' + requestNumber + '] was successfully passed !');
      this.router.navigate(['/recruitment/candidate/selection/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  canPass() {
    return this.docNo && (this.status === 'PENDING' || this.status === 'REJECTED');
  }

  private setAdditionalInfo() {
    this.candidateSelectionDto.id = parseInt(this.docNo, 10);
    this.candidateSelectionDto.compCode = this.selectedCompanyCode;
    if (this.selectedCandidateId != null) {
      this.candidateSelectionDto.candidateID = this.selectedCandidateId;
    } else if (this.candidates.length > 0) {
      this.candidateSelectionDto.candidateID = this.candidates[0].id;
    }
    this.candidateSelectionDto.recruitmentRequestId = this.selectedRecruitmentRequest;
    this.candidateSelectionDto.interviewee1Id = this.interviewee1?.id;
    this.candidateSelectionDto.interviewee1PmlId = this.interviewee1?.pmlId;
    this.candidateSelectionDto.interviewee2Id = this.interviewee2?.id;
    this.candidateSelectionDto.interviewee2PmlId = this.interviewee2?.pmlId;
    this.candidateSelectionDto.interviewee3Id = this.interviewee3?.id;
    this.candidateSelectionDto.interviewee3PmlId = this.interviewee3?.pmlId;
    this.candidateSelectionDto.createdById = this.requestedEmployee?.id;
    this.candidateSelectionDto.createdByPmlId = this.requestedEmployee?.pmlId;
  }
}
