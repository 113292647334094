<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Create Employees Account From A File</h4>
        <div class="row" style="padding-bottom: 10px;">
          <div class="col-md-8">
          <input class="form-control file-input" (change)="filesUploaded($event)" type="file" multiple>
          </div>
          <div class="col-md-4">
            <button class="btn btn-outline-success" (click)="uploadAccountFile()">Upload</button>
              </div>
          </div>
          <div class="row">
     
        </div>  
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Create account for specefic employees</h4>
        <div class="row" style="padding-bottom: 10px;">

        </div>

        <div *ngFor="let row of BodyRows" class="row mb-1">
          <div class="col-md-2 spaces">
            <input class="form-control" (click)="selectEmployee()" placeholder="Select Employee" required
              [value]="DataObject.Body[row.toString()]?.empName ? DataObject.Body[row.toString()]?.empName : ''">
          </div>
          <div class="col-md-3 spaces">
            <select class="form-select" id="floatingSelect" aria-label="Floating label select example" required [(ngModel)] ="DataObject.Body[row.toString()].oraUser"
              (change)="selectedOraUser($event.target.value)">
              <option [value]="choose" [selected]="true"> Choose an option </option>
             <option *ngFor="let oraUser of oraUsers" [value]="oraUser.name"> {{oraUser.name}} </option>
            </select>
          </div>
          <div class="col-md-2 spaces">
            <input  class="form-control" (click)="changeRoleButton(RoleModal, row)" placeholder="Select Roles" required>
          </div>
          <div class="col-md-1 spaces" *ngFor="let data of DataObject.Body[row.toString()].roles; last as isLast">
                {{ data==1? 'Self Service User' : 'Line Manager'}} {{isLast?'':','}}     
        </div>
          <div class="col-md-3 dlt">
            <button class="btn btn-outline-danger" (click)="deleteBodyRow(row)" [disabled]="BodyRows.length == 1">
              Delete 
            </button>
            &nbsp;
              <button class="btn btn-outline-success" style="cursor: pointer;" [disabled]="disableAddButton()" (click)="addRow()"
              >Add
            </button>
          </div>

        </div>
        <div class="row">
          <div class="col-md-8">
            <button class="btn btn-outline-success"  style="cursor: pointer;" [disabled]="isSaveDisabled"
              (click)="saveDetails()">Save</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<!--     *************************   Role Modal       -->
<ng-template #RoleModal let-modal>
  <div class="modal-header text-center">
    <h4 class="modal-title" id="modal-basic-title">Select Roles</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ng-container *ngIf="roles">
                    <div class="col-md-3 spaces" *ngFor="let role of roles">
                      <input type="checkbox" name="{{role.name}}"[checked]="isChecked(role)"  (change)="roleChange(role)">{{role.name}}
                    
                    </div>
                  </ng-container>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!--    ****************************   End Role Modal  -->


<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
  role="dialog" aria-labelledby="classInfo" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
      <div class="modal-header text-center">
        <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body container table-responsive">
        <div>
          <table class=" w-25 text-center  " id="GLTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="compact">
            <thead class="darkHeaders text-center ">
              <tr>
                <th> Employee Number </th>
                <th> Name </th>
              </tr>
            </thead>
            <tbody class=" text-center">
              <tr (click)="popUpTableValueSelected(row)" *ngFor="let row of PopUpTableData"
                [class]="row? row.Selected==true?'reedd':'':''">
                <td> {{ row.Code }} </td>
                <td> {{ row.Name }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>