<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Candidate Form</h4>
        <form [formGroup]="candidateFormGroup">
          <div class="form-body">
            <div class="row">

              <table class="table">
                <tbody>
                <tr>
                  <td>
                    <p>Id</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateDto?.id" class="form-control" disabled
                             formControlName="id"
                             id="id" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Name</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateDto?.name" class="form-control"
                             formControlName="name"
                             id="name"
                             placeholder="Name" type="text"/>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Date Of Birth</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="formatDate(candidateDto?.dateOfBirth)"
                             class="form-control"
                             formControlName="dateOfBirth" id="dateOfBirth"
                             placeholder="Date Of Birth"
                             type="date">
                    </div>
                  </td>

                  <td>
                    <p>Email</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateDto?.email" class="form-control"
                             formControlName="email"
                             id="email"
                             placeholder="Email" type="text"/>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Phone</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateDto?.phone" class="form-control"
                             formControlName="phone" id="phone"
                             placeholder="Phone" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Address</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateDto?.address" class="form-control"
                             formControlName="address"
                             id="address"
                             placeholder="Address" type="text"/>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Candidate Summary</p>
                  </td>
                  <td colspan="3">
                    <div class="form-group ">
                        <textarea [value]="candidateDto?.candidateSummary" class="form-control"
                                  formControlName="candidateSummary"
                                  id="candidateSummary"
                                  placeholder="Candidate Summary"
                                  rows="4"
                        ></textarea>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p *ngIf="cbAttachmentBlob == null">CB Attachment</p>
                    <p *ngIf="cbAttachmentBlob != null">
                      <button
                          (click)="downloadFile(cbAttachmentBlob,candidateDto.cbAttachmentName)">CB
                        Attachment
                      </button>
                    </p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (change)="cbAttachmentUpload($event)"
                             class="form-control file-input" id="cbAttachment"
                             multiple type="file">
                    </div>
                  </td>
                  <td>
                    <p *ngIf="otherFileAttachmentBlob == null">Other File Attachment</p>
                    <p *ngIf="otherFileAttachmentBlob != null">
                      <button
                          (click)="downloadFile(otherFileAttachmentBlob,candidateDto.otherFileAttachmentName)">
                        Other File Attachment
                      </button>
                    </p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (change)="otherFileAttachmentUpload($event)"
                             class="form-control file-input"
                             id="otherFileAttachment"
                             multiple type="file">
                    </div>
                  </td>
                </tr>


                <tr>
                  <td>
                    <button (click)="updateCandidate()" *ngIf="idParam"
                            class="btn btn-pass-section btn-success mb-2">Update
                    </button>
                    <button (click)="saveCandidate()" *ngIf="!idParam"
                            class="btn btn-pass-section btn-success mb-2">
                      Save
                    </button>
                    &nbsp;<button (click)="reset()" *ngIf="!idParam"
                                  class="btn btn-pass-section btn-warning mb-2">
                    Cancel
                  </button>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>


          </div>
        </form>


      </div>
    </div>

  </div>
</div>
