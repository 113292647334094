<div class="row">
  <div class="col-lg-12">
    <div class="form-group">
      <ng-container>
        <ngx-select-dropdown (change)="onEmployeeChange($event)" (searchChange)="searchChange($event)" [(ngModel)]="selectedOptionEmployee" tabindex="0" [multiple]="false" [config]="config" [options]="allMappedEmployees"></ngx-select-dropdown>
      </ng-container>
      <div *ngIf="selectedEmployeeSet" class="tagged-section">
        <label>
          <span>{{selectedEmployeeSet.name}}</span> <a (click)="removeEmployeeFilter(selectedEmployeeSet)"><i class="close"></i></a>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="row" >
  <div class="col-lg-6 col-md-12">
    <div class="form-group">
      <label>Profession:</label>
      <a (click)="openStartProfession(professionModel)">
        <input  [disabled]="!employeeEnable" type="text" [value]="selectedProfessionFrom?.description" class="form-control"/>
      </a>
      <div *ngIf="selectedProfessionFrom" class="tagged-section">
        <label>
          <span>{{selectedProfessionFrom.description}}</span> <a (click)="removeProfessionFilter(selectedProfessionFrom)"><i class="close"></i></a>
        </label>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12">
    <div class="form-group">
      <label>Position:</label>
      <a (click)="openPosition(PositionsModal)">
        <input [disabled]="!employeeEnable" [value]="selectedPositions?.description" type="text" class="form-control"/>
      </a>
      <div *ngIf="selectedPositions" class="tagged-section">
        <label>
          <span>{{selectedPositions.description}}</span> <a (click)="removePositionFilter(selectedPositions)"><i class="close"></i></a>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-6 col-md-12">
    <input type="button" class="btn btn-primary" value="Generate Chart" (click)="generateChart()"/>
  </div>
</div>

<div id="salaryIncrementHistoryChart" style="width:100%; height:600px;"></div>

<ng-template #professionModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Profession</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Profession" class="form-control" (keyup)="searchProfessionList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayProfession of arrayProfessionList">
                      <ng-contaner *ngIf="selectedProfessionCodeFrom === arrayProfession.code">
                        <label><input checked (change)="checkProfessionFrom($event, arrayProfession)" name="profession" type="radio"/>{{arrayProfession.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedProfessionCodeFrom !== arrayProfession.code">
                        <label><input (change)="checkProfessionFrom($event, arrayProfession)" name="profession" type="radio"/>{{arrayProfession.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalProfessionCount of totalProfessionPagesArray; let i=index">
                        <ng-container *ngIf="i === activeProfessionFrom">
                          <li class="active">
                            <a (click)="paginatedProfessionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activeProfessionFrom">
                          <li>
                            <a (click)="paginatedProfessionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<ng-template #PositionsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Position</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <input type="text" placeholder="Search Positions" class="form-control" (keyup)="searchPositionList($event)"/>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let arrayPositionArr of arrayPositions">
                      <ng-contaner *ngIf="selectedPositionsCodes === arrayPositionArr.id">
                        <label><input checked (change)="checkPosition($event, arrayPositionArr)" name="positionSelect" type="radio"/>{{arrayPositionArr.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedPositionsCodes !== arrayPositionArr.id">
                        <label><input (change)="checkPosition($event, arrayPositionArr)" name="positionSelect" type="radio"/>{{arrayPositionArr.description}}</label>
                      </ng-contaner>
                    </li>
                  </ul>
                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalPositionCount of totalPositionPagesArray; let i=index">
                        <ng-container *ngIf="i === activePositionFrom">
                          <li class="active">
                            <a (click)="paginatedPositionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activePositionFrom">
                          <li>
                            <a (click)="paginatedPositionTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
