<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="col-12 top">
          <h4 class="card-title">Leave Requests</h4>
          <button class="btn btn-success mt-2 mb-2" (click)="NewLeave()">New Leave Request</button>
        </div>
        <ngx-datatable
            #table
            class='material'
            [columns]="columns"
            [columnMode]="'force'"
            [scrollbarH]="scrollBarHorizontal"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [rows]="LeavesList">
          <ng-container *ngFor='let column of columns'>
            <ngx-datatable-column *ngIf='column.name === "Employee Name"' name="Name" prop="employeeName">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.employeeName}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "employeeWsDTO Profession"' name="Profession" prop="employeeWsDTO.profession">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.employeeWsDTO.profession}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Number"' name="Document Number" prop="docNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.docNo}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "From Date"' name="From Date" prop="fromDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{parseDate(row.fromDate)}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "To Date"' name="To Date" prop="toDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{parseDate(row.toDate)}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Status"' name="Status" prop="status">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{getStatus(row)}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Leave Balance"' name="Leave Balance" prop="leaveBalance">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.employeeWsDTO.levBalDays}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Action"' name="Action" [sortable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <button class="btn btn-primary" (click)="ListDetails(row.id)">Details</button>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>


        <div class="pagination-set">
          <mat-paginator (page)="searchLeavePaginated($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
