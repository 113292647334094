<div class="row">
  <div class="col-md-12">
    <div class="card bg-light-info no-card-border">
      <div class="card-body">
        <h5 class="card-title">Employee Salary Bracket</h5>
        <div class="d-flex no-block">
          <div id="salary" style="width:550px; height:540px;"></div>
        </div>
      </div>
    </div>
  </div>
</div>
