import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DataChartRouting} from './datacharts.routing';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { DatachartsComponent } from './datacharts.component';
import { NationalityComponent } from './nationality/nationality.component';
import {ChartsService} from '../shared/service/charts.service';
import {ChartsModule} from 'ng2-charts';
import {ChartistModule} from 'ng-chartist';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { EmployeesalarybracketComponent } from './employeesalarybracket/employeesalarybracket.component';
import { EmployeesalaryincrementComponent } from './employeesalaryincrement/employeesalaryincrement.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { EmployeeincrementchartpageComponent } from './employeeincrementchartpage/employeeincrementchartpage.component';
import { EmployeesalarytotalComponent } from './employeesalarytotal/employeesalarytotal.component';
import { EmployeesalaryincrementhistoryComponent } from './employeesalaryincrementhistory/employeesalaryincrementhistory.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(DataChartRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ChartsModule,
    ChartistModule,
    NgxChartsModule,
    HighchartsChartModule,
    SelectDropDownModule
  ],
  exports: [
    NationalityComponent,
    EmployeesalarybracketComponent
  ],
  declarations: [DatachartsComponent, NationalityComponent, EmployeesalarybracketComponent, EmployeesalaryincrementComponent, EmployeeincrementchartpageComponent, EmployeesalarytotalComponent, EmployeesalaryincrementhistoryComponent],
  providers: [ChartsService]
})
export class DatachartsModule {}
