import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CandidateDto} from '../../../shared/dto/recruitment/candidate/candidateDto';
import {RecruitmentCandidateService} from '../../../shared/service/recruitment-candidate-service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {CandidateSearchDto} from '../../../shared/dto/recruitment/candidate/candidateSearchDto';
import moment from 'moment';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.css']
})
export class CandidateDetailsComponent implements OnInit {

  candidateFormGroup: FormGroup;
  candidateDto: CandidateDto;
  idParam: number;
  cbAttachmentFile: File;
  otherFileAttachmentFile: File;
  cbAttachmentBlob: Blob;
  otherFileAttachmentBlob: Blob;


  constructor(private candidateService: RecruitmentCandidateService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.instantiateForm();
  }

  instantiateForm() {
    this.candidateFormGroup = this.formBuilder.group({
      id: [''],
      name: [''],
      dateOfBirth: [''],
      email: [''],
      phone: [''],
      address: [''],
      candidateSummary: ['']
    });

    if (this.activatedRoute.snapshot.queryParams['id']) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.idParam = params['id'];
        if (this.idParam) {
          this.searchCandidatesById(this.idParam);
        }
      });
    } else {
      this.spinner.hide();
    }
  }

  searchCandidatesById(id) {
    const candidateSearch = new CandidateSearchDto();
    candidateSearch.id = id;
    this.candidateService.searchCandidate(candidateSearch, 0, 10).subscribe(data => {
      if (data.numberOfElements === 1) {
        this.candidateDto = data.content[0];
        this.candidateFormGroup = this.formBuilder.group({
          id: [this.candidateDto?.id],
          name: [this.candidateDto?.name],
          dateOfBirth: [this.candidateDto?.dateOfBirth],
          email: [this.candidateDto?.email],
          phone: [this.candidateDto?.phone],
          address: [this.candidateDto?.address],
          candidateSummary: [this.candidateDto?.candidateSummary]
        });
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._toastr.error('Unable to search the candidate [' + id + ']');
        this.router.navigate(['/recruitment/candidate/list']);
      }
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
    this.spinner.show();
    this.candidateService.downloadCbAttachment(id).subscribe(cbAttachment => {
      this.cbAttachmentBlob = new Blob([cbAttachment]);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
    this.spinner.show();
    this.candidateService.downloadOtherFileAttachment(id).subscribe(otherFileAttachment => {
      this.otherFileAttachmentBlob = new Blob([otherFileAttachment]);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  downloadFile(blob, cbAttachmentName) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = cbAttachmentName;
    link.click();
  }

  saveCandidate() {
    this.candidateDto = this.candidateFormGroup.value;
    this.validateForm();
    this.spinner.show();
    this.candidateService.saveCandidate(this.candidateDto, this.cbAttachmentFile, this.otherFileAttachmentFile).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The candidate [' + data.id + '] was successfully saved !');
      this.router.navigate(['/recruitment/candidate/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  updateCandidate() {
    this.candidateDto = this.candidateFormGroup.value;
    this.validateForm();
    this.spinner.show();
    this.candidateService.updateCandidate(this.candidateDto, this.cbAttachmentFile, this.otherFileAttachmentFile).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The candidate [' + data.id + '] was successfully updated !');
      this.router.navigate(['/recruitment/candidate/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  validateForm() {
    if (this.candidateDto.name === '') {
      this.spinner.hide();
      this._toastr.error('The Name is Mandatory !');
      throw new Error(('The Name is Mandatory !'));
    } else if (this.candidateDto.dateOfBirth.toString() === '') {
      this.spinner.hide();
      this._toastr.error('The Date Of Birth is Mandatory !');
      throw new Error(('The Date Of Birth is Mandatory !'));
    } else if (this.candidateDto.phone === '') {
      this.spinner.hide();
      this._toastr.error('The Email is Mandatory !');
      throw new Error(('The Email is Mandatory !'));
    } else if (this.candidateDto.email === '') {
      this.spinner.hide();
      this._toastr.error('The Phone is Mandatory !');
      throw new Error(('The Phone is Mandatory !'));
    } else if (this.candidateDto.address === '') {
      this.spinner.hide();
      this._toastr.error('The Address is Mandatory !');
      throw new Error(('The Address is Mandatory !'));
    } else if (this.candidateDto.candidateSummary === '') {
      this.spinner.hide();
      this._toastr.error('The Candidate Summary is Mandatory !');
      throw new Error(('The Candidate Summary is Mandatory !'));
    }
  }

  cbAttachmentUpload(e) {
    this.cbAttachmentFile = e?.target?.files[0];
  }

  otherFileAttachmentUpload(e) {
    this.otherFileAttachmentFile = e?.target?.files[0];
  }

  reset() {
    if (confirm('Are you sure you want to reset the form ?')) {
      this.candidateFormGroup = this.formBuilder.group({
        id: [''],
        name: [''],
        dateOfBirth: [''],
        email: [''],
        phone: [''],
        address: [''],
        candidateSummary: ['']
      });
    }
    this.idParam = null;
    this.otherFileAttachmentFile = null;
    this.cbAttachmentFile = null;
    this.otherFileAttachmentBlob = null;
    this.cbAttachmentBlob = null;
  }
}
