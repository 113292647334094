import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {environment} from '../../../environments/environment';
import {IHrDocumentRequest} from '../model/ihr-document-request';

@Injectable({
  providedIn: 'root'
})
export class UomService {

  constructor(private http: HttpClient) { }

  getAllUom(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/unitOfMeasurement/all', httpOptions);
  }

  getAllStoreBinLocations(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/storeBinLocation/all', httpOptions);
  }

  getAllSalesPriceGroupMaster(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/salesPriceGroupMaster/all', httpOptions);
  }

  getAllStoresList(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/storeMaster/all', httpOptions);
  }

  getAllSalesManList(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(`${environment.host}/api/salesmanMaster/all`, httpOptions);
  }

  getAllCustomerList(paginationNum: number, size: number): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(`${environment.host}/api/customers/page?page=${paginationNum}&size=${size}`, httpOptions);
  }

  saveQuotation(quoteData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post<any>(environment.host + '/api/quotation/query/save', quoteData, httpOptions);
  }

  getAllOtherCharges(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/otherCharges/all', httpOptions);
  }

}
