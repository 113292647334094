import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  // {
  //   path: '',
  //   title: 'Dashboards',
  //   icon: 'icon-Film-Video',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/dashboard/dashboard1',
  //       title: 'Dashboard 1',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/dashboard/dashboard2',
  //       title: 'Dashboard 2',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/dashboard/dashboard3',
  //       title: 'Dashboard 3',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  /*
  {
    path: '',
    title: 'Apps',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/apps/email',
    title: 'Email',
    icon: 'icon-Mailbox-Empty',
    class: '',
    extralink: false,
    submenu: []
  },*/
  {
    path: '/dashboard-m/dashboardM',
    title: 'DashboardM',
    icon: 'icon-Home',
    class: 'disabled',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Leaves',
    icon: 'icon-Ticket',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      // {
      //   path: '/leaves/list',
      //   title: 'Leaves List',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      {
        path: '/leaves/list',
        title: 'Leave Request',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }, {
        path: '/leaves/leaveapprove',
        title: 'Leave Approvals',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }, {
        path: '/leaves/leaveplanning',
        title: 'Leave Planning',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }, {
        path: '/leaves/leavereturn',
        title: 'Leave Return',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'HR Documents',
    icon: 'icon-Folder-WithDocument',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/hrdocuments/list',
        title: 'HR Document Request',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/hrdocuments/documentapproval',
        title: 'HR Document Approval',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Recruitment',
    icon: 'icon-Add-User',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/recruitment/candidate/list',
        title: 'Candidates',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/recruitment/candidate/selection/list',
        title: 'Candidate Selection',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/recruitment/request/list',
        title: 'Recruitment Request',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Admin',
    icon: 'icon-Administrator',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/admin/mapping',
        title: 'Employee/Manager Mapping',
        icon: 'icon-Conference',
        class: '',
        extralink: false,
        submenu: []
      }, {
        path: '/admin/employeeAccount',
        title: 'Employee/Account Mapping',
        icon: 'icon-Conference',
        class: '',
        extralink: false,
        submenu: []
      }, {
        path: '/admin/approvalManagement',
        title: 'Approval Level Management',
        icon: 'icon-Conference',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Provision',
    icon: 'icon-Coins',
    class: 'disabled',
    extralink: false,
    submenu: [
      // {
      //   path: '/provision/employee-inquiry',
      //   title: 'Provision Inquiry',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  {
    path: '',
    title: 'Employee Enquiry',
    icon: 'icon-Search-People',
    class: 'disabled',
    extralink: false,
    submenu: [
      // {
      //   path: '/enquiry/employee',
      //   title: 'Employee Enquiry',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  // {
  //   path: '',
  //   title: 'Master Categories',
  //   icon: 'icon-Folder-Organizing',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/mastercat/list',
  //       title: 'Category List',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  {
    path: '',
    title: 'Data Charts',
    icon: 'icon-Statistic',
    class: 'disabled',
    extralink: false,
    submenu: [
      // {
      //   path: '/chartsdata/employeesalarybracket',
      //   title: 'Data Charts',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/chartsdata/employeesalarytotal',
      //   title: 'Employee Salary By Total',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // },
      // {
      //   path: '/chartsdata/employeesalaryincrementhistory',
      //   title: 'Salary Increment History',
      //   icon: '',
      //   class: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  },
  // {
  //   path: '',
  //   title: 'Unit of Measurement',
  //   icon: 'icon-Dashboard',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/uom/initiallist',
  //       title: 'UOM List',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/uom/storebinlocations',
  //       title: 'Store Bin Locations',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }, {
  //       path: '/uom/storegroupings',
  //       title: 'Store Groupings',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }, {
  //       path: '/uom/stockmaster',
  //       title: 'Stock Master',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }, {
  //       path: '/uom/othercharges',
  //       title: 'Other Charges',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }, {
  //       path: '/uom/salesqueries',
  //       title: 'Sales Queries',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   title: 'Document Types',
  //   icon: 'icon-Box-withFolders',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/documenttypes/managedocs',
  //       title: 'Manage Document Types',
  //       icon: '',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },

  // {
  //   path: '',
  //   title: 'ERP',
  //   icon: 'icon-Folder-Archive',
  //   class: 'has-arrow',
  //   extralink: false,
  //   submenu: [
  //     {
  //       path: '/erp/genral-ledger',
  //       title: 'Genral Ledger',
  //       icon: 'icon-Folder-Block',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/erp/inventorypricing',
  //       title: 'Inventory/ Purchasing',
  //       icon: 'icon-Folder-Block',
  //       class: '',
  //       extralink: false,
  //       submenu: []
  //     }
  //   ]
  // },
  /*
  {
    path: '/component/tabs',
    title: 'Tabs',
    icon: '',
    class: '',
    extralink: false,
    submenu: []
  },{
    path: '/component/tabs',
    title: 'Tabs',
    icon: '',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'UI Elements',
    icon: 'icon-Paint-Brush',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/component/accordion',
        title: 'Accordion',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/alert',
        title: 'Alert',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/carousel',
        title: 'Carousel',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/dropdown',
        title: 'Dropdown',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/modal',
        title: 'Modal',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/pagination',
        title: 'Pagination',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/poptool',
        title: 'Popover & Tooltip',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/progressbar',
        title: 'Progressbar',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/rating',
        title: 'Ratings',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/tabs',
        title: 'Tabs',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/timepicker',
        title: 'Timepicker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/buttons',
        title: 'Button',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/notifier',
        title: 'Notifier',
        icon: 'mdi mdi-bandcamp',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Cards',
    icon: 'icon-Folder-Add',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/cards/basiccards',
        title: 'Basic Cards',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/cards/customcards',
        title: 'Custom Cards',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/cards/weathercards',
        title: 'Weather Cards',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Extra Components',
    icon: 'icon-Idea-5',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/extra-component/toastr',
        title: 'Toastr',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/extra-component/editor',
        title: 'Editor',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/extra-component/dragndrop',
        title: 'Drag n Drop',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Widgets',
    icon: 'icon-Wrench',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/widgets/apps',
        title: 'Widget Apps',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/widgets/data',
        title: 'Widget Data',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Forms',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Form Elements',
    icon: 'icon-Receipt-4',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/forminputs',
        title: 'Form Inputs',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/inputgroups',
        title: 'Input Groups',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/inputgrid',
        title: 'Input Grid',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/checkboxandradio',
        title: 'Checkbox & Radio',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/multiselect',
        title: 'Multiselect',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Form Layouts',
    icon: 'icon-Receipt-2',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/formbasic',
        title: 'Basic Forms',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formhorizontal',
        title: 'Horizontal Forms',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formactions',
        title: 'Form Actions',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formrowseparator',
        title: 'Row Separator',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formstripedrows',
        title: 'Striped Rows',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/formdetail',
        title: 'Detail Forms',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Form Addons',
    icon: 'icon-Add',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/forms/formvalidation',
        title: 'Form Validation',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/typehead',
        title: 'Form Typehead',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/datepicker',
        title: 'Datepicker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/component/language-datepicker',
        title: 'Language Datepicker',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/forms/ngx',
        title: 'Form Wizard / Steps',
        icon: 'mdi mdi-attachment',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Tables',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Bootstrap Tables',
    icon: 'mdi mdi-border-none',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/tables/basictables',
        title: 'Basic Tables',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tables/darktables',
        title: 'Dark Basic Tables',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tables/colortables',
        title: 'Colored Tables',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tables/tablesizing',
        title: 'Table Sizing',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '/tables/datatable',
    title: 'Data Tables',
    icon: 'mdi mdi-border-vertical',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Charts',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Charts',
    icon: 'icon-Pie-Chart',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/charts/chartjs',
        title: 'Chart Js',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/charts/chartistjs',
        title: 'Chartist Js',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/charts/ngxchart',
        title: 'Ngx Charts',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Maps',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/maps/google',
    title: 'Google Maps',
    icon: 'icon-Location-2',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Pages',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Ecommerce Pages',
    icon: 'icon-Shopping-Bag',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/ecom/products',
        title: 'Products',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/cart',
        title: 'Cart',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/edit',
        title: 'Edit Products',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/details',
        title: 'Product Details',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/orders',
        title: 'Orders',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/ecom/checkout',
        title: 'Checkout',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Authentication',
    icon: 'icon-Administrator',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/authentication/login',
        title: 'Login',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/authentication/login2',
        title: 'Login 2',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/authentication/signup',
        title: 'Register',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/authentication/signup2',
        title: 'Register 2',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/authentication/404',
        title: '404',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/authentication/lock',
        title: 'Lockscreen',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Sample Pages',
    icon: 'icon-Files',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/sample-pages/profile',
        title: 'Profile',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/sample-pages/pricing',
        title: 'Pricing',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/sample-pages/invoice',
        title: 'Invoice',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/sample-pages/helperclasses',
        title: 'Helper Classes',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/starter',
        title: 'Starter Page',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Timeline',
    icon: 'mdi mdi-apple-safari',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/timeline/left',
        title: 'Left Timeline',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/timeline/right',
        title: 'Right Timeline',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/timeline/center',
        title: 'Center Timeline',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Icons',
    icon: 'icon-Eyeglasses-Smiley',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '/icons/fontawesome',
        title: 'Fontawesome',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/icons/simpleline',
        title: 'Simple Line Icons',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/icons/material',
        title: 'Material Icons',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/icons/iconmind',
        title: 'Iconmind Icons',
        icon: '',
        class: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Menu Levels',
    icon: 'mdi mdi-notification-clear-all',
    class: 'has-arrow',
    extralink: false,
    submenu: [
      {
        path: '',
        title: 'Second Level',
        icon: '',
        class: '',
        extralink: true,
        submenu: []
      },
      {
        path: '',
        title: 'Second Child',
        icon: '',
        class: 'has-arrow',
        extralink: false,
        submenu: [
          {
            path: '',
            title: 'Third 1.1',
            icon: '',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '',
            title: 'Third 1.2',
            icon: '',
            class: '',
            extralink: false,
            submenu: []
          }
        ]
      }
    ]
  }*/
];
