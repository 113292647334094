import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { GetCategories, GetCostCenters, GetCurrenciesObject, GetGLCodes, GetSLCodes } from 'src/app/shared/model/ERP Models/APIResponse.model';
import { Period, PopupTable, SessionObject, Voucher } from 'src/app/shared/model/ERP Models/Logics.model';
import { GenerateDocument, SaveTransactionPayloadBR } from 'src/app/shared/model/ERP Models/Payloads.model';
import { GenralLedgerService } from 'src/app/shared/service/ERP Services/genral-ledger.service';
import Swal from 'sweetalert2';
import DateUtils from '../../utils/DateUtils';
@Component({
  selector: 'app-bankreceipt',
  templateUrl: './bankreceipt.component.html',
  styleUrls: ['./bankreceipt.component.css']
})
export class BankreceiptComponent implements OnInit {
  
  Session: SessionObject = {
    BranchCode: "01",
    FinancialCode: "2019",
    CompanyCode: "01",
    ModuleCode: "GL",
    DocumentCode: "BR",
    UserName:""
  };
  PeriodList: Period[] = [];
  VourcherList: Voucher[] = [];
  CurrencyList: GetCurrenciesObject[] = [];
  GLCodeList: GetGLCodes[] = [];
  PopUpTableData: PopupTable[] = [];
  CategoriesList: GetCategories[] = [];
  SLCodeList: GetSLCodes[] = [];
  CostCodeList: any[] = [];
  CostCentersList: GetCostCenters[] = [];
  SumCredit:number;
  SumDebit:number;
  BankObject:any;

  SelectedPeriod: Period | any = {};
  SelectedVoucher: string;
  SelectedCurrency: GetCurrenciesObject | any = {};
  DataObjectID: string = "GL";
  DataRowID: string = "1";
  numbers = [];
  show: boolean = false;
  DocumentCode: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  BodyRows: number[] = [1];
  DataObject = {
    GL_H: null,
    SL_H: null,
    CCC_H: null,
    Headers:
            { 
              Amount:0,
              Being: null,
              ChqNo: null,
              RefDate: null,
              Reference: null
            },
    GL: {},
    SL: {},
    CAT: {},
    CCC: {},
    Debit: {},
    Credit: {},
    CCD: {},
  };
  CurrentDate: string = DateUtils.get();//moment().format('DD-MM-YYYY').toString();
  CurrentDatePopulate: string = DateUtils.get();//moment().format('YYYY-MM-DD').toString() //.replace('-','/').replace('-','/');

  @ViewChild("configModal") public ConfigModal: ModalDirective;

  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;

  constructor(
    private spinner: NgxSpinnerService,
    private apiservice: GenralLedgerService,
    private _toastr: ToastrService
  ) {

  }


  public ngOnInit() 
  {
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode?SessionObject?.glUserMastersDetailsDTO?.branchCode:'01';
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.UserName = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.Session.DocumentCode = "BR";
    this.Session.FinancialCode = SessionObject?.year.year;
    this.Session.ModuleCode = "GL";
    this.dtOptions = {
      pagingType: "full_numbers",
    };
    this.loadPeriods(this.Session);
    this.loadVoucherCode(this.Session);
    this.loadGLCodes();
    this.loadCategories();
    this.loadCostCenters();
  }

  saveTransaction()
  {
    if(this.SumCredit != this.SumDebit)
    {
      this._toastr.error("The sum of credit must be equal to sum of debit.");
      // Swal.fire({
      //   title: "The sum of credit must be equal to sum of debit.",
      //   type: 'error',
      //   timer: 4000,
      //   showConfirmButton: false
      // });
    }
    let Payload: SaveTransactionPayloadBR;
    Payload = 
              {
                "glTrxHeadersDTO":
                                  {
                                    "being": this.DataObject.Headers.Being,
                                    "brncCode":this.Session.BranchCode?this.Session.BranchCode: null,
                                    "compCode":this.Session.CompanyCode?this.Session.CompanyCode: null,
                                    "docNo":this.DocumentCode?this.DocumentCode: null,
                                    "docType":this.Session.DocumentCode?this.Session.DocumentCode: null,
                                    "exchangeRate":this.SelectedCurrency.purchaseRate?this.SelectedCurrency.purchaseRate: null,
                                    "fyerId":this.SelectedPeriod.FinancialYearID? this.SelectedPeriod.FinancialYearID: null,
                                    "glVrCode":this.SelectedVoucher? this.SelectedVoucher: null,
                                    "modsModule":"GL",
                                    "periodId":this.SelectedPeriod.Id? this.SelectedPeriod.Id: null,
                                    "reference":this.DataObject.Headers.Reference? this.DataObject.Headers.Reference: null,
                                    "bankCode":this.BankObject.vcode? this.BankObject.vcode: null,
                                    "chequeNo":this.DataObject.Headers.ChqNo? this.DataObject.Headers.ChqNo: null,
                                    "costCenterCode": this.DataObject.CCC_H.RowObject?.id? this.DataObject.CCC_H.RowObject?.id : null,
                                    //"duDate":this.DataObject.Headers.RefDate? moment(this.DataObject.Headers.RefDate).format('DD-MM-YYYY'): null,
                                    "duDate":this.DataObject.Headers.RefDate? DateUtils.get(this.DataObject.Headers.RefDate): null,
                                    "glMasterCode":this.DataObject.GL_H.Code? this.DataObject.GL_H.Code: null,
                                    "headerAmount":this.DataObject.Headers.Amount? this.DataObject.Headers.Amount: null,
                                    "slCode":this.DataObject.SL_H.RowObject?.id? this.DataObject.SL_H.RowObject?.id: null,
                                    "slType":this.DataObject.SL_H.RowObject?.slType? this.DataObject.SL_H.RowObject?.slType: null,
                                    //"refDate":this.DataObject.Headers.RefDate? moment(this.DataObject.Headers.RefDate).format('DD-MM-YYYY'): null
                                    "refDate":this.DataObject.Headers.RefDate? DateUtils.get(this.DataObject.Headers.RefDate): null
                                  },
                "glTrxDetails":
                                  [
                                    {
                                      "brncCode" : this.Session.BranchCode? this.Session.BranchCode: null,
                                      "amount": this.DataObject.Headers.Amount?this.DataObject.Headers.Amount: null,
                                      "brncCompCode": this.Session.CompanyCode? this.Session.CompanyCode: null,
                                      "costCenterCode": this.DataObject.CCC_H.Code? this.DataObject.CCC_H.Code: null,
                                      "crDr": "D",
                                      "expsCode": "",       // EXPLAINATION NEEDED
                                      "expsCompCode": "",   // EXPLAINATION NEEDED
                                      "fCurAmount": 0,      // EXPLAINATION NEEDED
                                      "glmsCode": this.DataObject.GL_H.Code? this.DataObject.GL_H.Code: null,
                                      "glmsCompCode": this.DataObject.GL_H.RowObject?.compCode? this.DataObject.GL_H.RowObject?.compCode: null,
                                      "slCode": this.DataObject.SL_H.RowObject?.id? this.DataObject.SL_H.RowObject?.id: null,
                                      "slType": this.DataObject.SL_H.RowObject?.slType? this.DataObject.SL_H.RowObject?.slType: null
                                    }
                                  ]
              }

              for(let i=1; i<=this.BodyRows.length-1; i++)
              {
                Payload.glTrxDetails.push(
                  {
                    "brncCode" : this.Session.BranchCode? this.Session.BranchCode: null,
                    "amount": this.DataObject.Credit[i.toString()]? this.DataObject.Credit[i.toString()]: this.DataObject.Debit[i.toString()]? this.DataObject.Debit[i.toString()]: null,
                    "brncCompCode": this.Session.CompanyCode? this.Session.CompanyCode: null,
                    "costCenterCode": this.DataObject.CCC[i.toString()]? this.DataObject.CCC[i.toString()].Code: null,
                    "crDr": this.DataObject.Credit[i.toString()]? "C" : this.DataObject.Debit[i.toString()]? "D" : null,
                    "expsCode": "",       // EXPLAINATION NEEDED
                    "expsCompCode": "",   // EXPLAINATION NEEDED
                    "fCurAmount": 0,      // EXPLAINATION NEEDED
                    "glmsCode": this.DataObject.GL[i.toString()].Code?this.DataObject.GL[i.toString()].Code: null,
                    "glmsCompCode": this.DataObject.GL[i.toString()].RowObject?this.DataObject.GL[i.toString()].RowObject.compCode: null,
                    "slCode": this.DataObject.SL[i.toString()].RowObject?.id? this.DataObject.SL[i.toString()].RowObject?.id: null,
                    "slType": this.DataObject.SL[i.toString()].RowObject.slType? this.DataObject.SL[i.toString()].RowObject.slType: null
                  }
                )
              }

    this.apiservice.saveTransation(Payload).subscribe(
      data=>
        {
          this._toastr.success("Passed")
        },
      error=>
        {
          this._toastr.error("Error")
        }
    )
  }
  

  currencyChanged(event) {
    let CurrencyCode = event.target.value;
    this.SelectedCurrency = this.CurrencyList.filter(
      (x) => x.code == CurrencyCode
    ).pop();
  }

  showJV() {
    this.show = !this.show;
  }

  loadPeriods(Session: SessionObject) {
    if (!Session.BranchCode || !Session.CompanyCode || !Session.FinancialCode)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiservice
      .getPeriodByCompanyAndBranch(
        Session.CompanyCode,
        Session.FinancialCode,
        Session.BranchCode
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          data.forEach((element) => {
            this.PeriodList.push({
              Id: element.id,
              Period: element.period,
              FromDate: element.fromDate,
              ToDate: element.toDate,
              FinancialYearID: element.fyerId,
            });
          });
          this.PeriodList.sort((a, b) => {
            return Number.parseInt(a.Period) - Number.parseInt(b.Period);
          }); // Sorting the Periods
          this.SelectedPeriod = this.PeriodList[0];
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  loadVoucherCode(Session: SessionObject) {
    this.spinner.show();
    this.apiservice
      .getVoucherCode(
        Session.CompanyCode,
        Session.DocumentCode,
        Session.FinancialCode
      )
      .subscribe(
        (data: any[]) => {
          this.spinner.hide();
          this.VourcherList = [];
          data.forEach((element) => {
            this.VourcherList.push({
              Code: element.code,
              Description: element.description,
            });
          });
          this.SelectedVoucher = this.VourcherList[0].Code;
        },
        (error) => {
          this.spinner.hide();
          this._toastr.error("Error in API","Error");
        }
      );
  }

  showBankReciepts()
  {
    if (this.SelectedPeriod && this.SelectedVoucher) {
      this.show = true;
      this.generateDocumentCode();
      this.getCurrencyList();
    }
  }

  generateDocumentCode() {
    let Payload: GenerateDocument = {
      vTypeDocType: this.Session.DocumentCode,
      vTypeModsModule: this.Session.ModuleCode,
      fyerId: this.SelectedPeriod.FinancialYearID.toString(),
      brncCode: this.Session.BranchCode,
      code: this.SelectedVoucher,
      brncCompCode: this.Session.CompanyCode,
      modsModule: this.Session.ModuleCode,
    };
    this.apiservice
      .generateDocumentCode(Payload, this.SelectedPeriod.Id)
      .subscribe(
        (data: any) => {
          this.DocumentCode = data;
        },
        (error) => {this._toastr.error("Error in API","Error");}
      );
  }

  getCurrencyList() {
    this.spinner.show();
    this.apiservice.getCurrencies().subscribe(
      (data: GetCurrenciesObject[]) => {
        this.spinner.hide();
        this.CurrencyList = data;
        this.SelectedCurrency = this.CurrencyList[0];
      },
      (error) => {
        this.spinner.hide();
        this._toastr.error("Error in API","Error");
      }
    );
  }

  setDateFromPeriod(event) {
    let ChangePeriod = event.target.value;
    this.SelectedPeriod = this.PeriodList.filter(
      (x) => x.Period == ChangePeriod
    )[0];
  }

  popUpTableValueSelected(event, row: PopupTable) {
    let CheckFlag = event.currentTarget.checked;
    if (CheckFlag) 
    {
      this.PopUpTableData.forEach((x) => (x.Selected = false));
      if( this.DataObjectID.slice(this.DataObjectID.length-2,this.DataObjectID.length) == "_H")
      {
        this.DataObject[this.DataObjectID] = row;
        row.Selected = true;

        if(this.DataObjectID == "GL_H")
        {
          this.getBankCodes(row.Code);
        }
        return;
      }

     
      row.Selected = true;
      this.DataObject[this.DataObjectID][this.DataRowID] = row;
      if (
        Object.keys(this.DataObject[this.DataObjectID]).length ==
        this.BodyRows.length
      ) {
        this.BodyRows.push(Object.keys(this.BodyRows).length + 1);
      }
    }
  }

  getBankCodes(GLCode)
  {
    this.apiservice.getBanks(this.Session.CompanyCode, GLCode).subscribe(
      data=>{
        this.BankObject = data;
      },
      error=>{
        this._toastr.error('error in API');
      }
    )
  }

  rerender(): void {
    if(!this.datatableElement.dtInstance)
    {
      this.dtTrigger.next();
    }
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  loadGLCodes() {
    this.apiservice.getGLCodesForBR().subscribe((data: any) => {
      this.GLCodeList = data.content ? data.content : {};
      this.GLCodeList.forEach((x) => {
        this.PopUpTableData.push({
          Code: x.code,
          Name: x.description,
          Selected: false,
          RowObject: x,
        });
      });
    
    });
  }

  openGLModal()
  {
    this.PopUpTableData = [];
    this.DataObjectID = "GL_H"
      if (this.GLCodeList.length != 0) {
        this.GLCodeList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.description,
            Code: x.code,
            Selected: false,
            RowObject: x
          });
        });
        this.ConfigModal.show();
        this.rerender();
        
      }
    

  }

  openCCModal()
  {
    this.PopUpTableData = [];
    this.DataObjectID = "CCC_H";
    if(this.CostCentersList.length !=0)
    {
      this.CostCentersList.forEach(x=>{
        this.PopUpTableData.push(
          {
            Name: x.description,
            Code: x.code,
            Selected: false,
            RowObject: x,
          }
        )
      });
      this.rerender();
      this.ConfigModal.show();
    }
  }

  openSLModal()
  {
    this.PopUpTableData = [];
    this.rerender();
   
    if(!this.Session.CompanyCode || !this.DataObject.GL_H?.Code)
      {
        this.spinner.hide();
        return;
      };
    this.DataObjectID = "SL_H"
    this.apiservice.getSLCodes(this.DataObject.GL_H.Code, this.Session.CompanyCode).subscribe(
      (data: GetSLCodes[])=>
      {
        data.forEach(x=>{
           this.PopUpTableData.push(
          {
           "Code": x.slCode,
           "Name": x.slName,
          "Selected": false,
          "RowObject": x
          }
        )
        })
        this.ConfigModal.show();
        this.rerender();
        this.spinner.hide();
      })

    }

  loadCategories() {
      this.spinner.show();
      this.apiservice.getCategories().subscribe(
        (data: GetCategories[]) => {
          this.spinner.hide();
          this.CategoriesList = data;
          this.CategoriesList.forEach((x) => {
            this.PopUpTableData.push({
              Code: x.code,
              Name: x.description,
              Selected: false,
              RowObject: x,
            });
          });
        },
        (error) => {
          this.spinner.hide();
          this._toastr.error("Error in API","Error");
        }
      );
    } 

  setVoucherCode(event) {
    this.SelectedVoucher = event.target.value;
  }

  loadSLCodes() {
      if (!this.DataObject["GL"][this.DataRowID] || !this.Session.CompanyCode)
        {
          return;
        }
      else {
        this.rerender();
        this.spinner.show();
        this.apiservice
          .getSLCodes(
            this.DataObject["GL"][this.DataRowID].Code,
            this.Session.CompanyCode
          )
          .subscribe(
            (data: GetSLCodes[]) => {
              data.forEach((x) => {
                this.PopUpTableData.push({
                  Name: x.slName,
                  Code: x.slCode,
                  Selected: false,
                  RowObject: x,
                });
              });
              if(this.PopUpTableData.length > 0 )
                this.rerender();
              this.ConfigModal.show();
              this.spinner.hide();
            },
            (error) => {
              this.spinner.hide();
            }
          );
      }
    }

  loadCostCode(SLId: string, SLType: string) {
      if(!SLType || !SLId)
        return; // throw error later
      this.spinner.show();
      this.apiservice.getCostCode(SLId, SLType).subscribe(
        (data:any)=>
        {
          this.CostCodeList = data;
          this.PopUpTableData = [];
          this.rerender();
          this.CostCodeList.forEach(x=>
            {
              this.PopUpTableData.push({"Code":x.id, "Name":x.longDescription, "Selected": false, "RowObject":x})
            })
          this.spinner.hide();
        },
        error=>
        {
          this.spinner.hide();
          this._toastr.error("Error in API","Error");
        }
      )
    }

  loadCostCenters() {
    this.apiservice.getCostCenters().subscribe((data: GetCostCenters[]) => {
      this.CostCentersList = data;
    });
  }

  fetchTotal(Type: string) {
    let Sum = 0;
    if (this.DataObject[Type]) {
      Object.keys(this.DataObject[Type]).forEach((x) => {
        if(isNaN(Number.parseFloat(this.DataObject[Type][x])))
          return;
        Sum += Number.parseFloat(this.DataObject[Type][x]);
      });
    }
    if(Type == "Credit")
      this.SumCredit = Sum;
    if(Type == "Debit")
    {
      Sum+=this.DataObject.Headers.Amount;
      this.SumDebit = Sum;
    }
    return Sum.toString();
  }

  OpenSelectModal(pDataObjectID: string, pDataRowID: string) {
      this.PopUpTableData = [];
      this.DataObjectID = pDataObjectID;
      this.DataRowID = pDataRowID;
  
      if (pDataObjectID == "GL") {
        if (this.GLCodeList.length != 0) {
          this.GLCodeList.forEach((x) => {
            this.PopUpTableData.push({
              Name: x.description,
              Code: x.code,
              Selected: false,
              RowObject: x
            });
          });
          
          this.rerender();
          this.ConfigModal.show();
        }
      }
  
      if (pDataObjectID == "SL") {
        if (this.SLCodeList.length != 0) {
          this.SLCodeList.forEach((x) => {
            this.PopUpTableData.push({
              Name: x.slName,
              Code: x.slCode,
              Selected: false,
              RowObject: x
            });
          });
  
          this.rerender();
          this.ConfigModal.show();
        } else {
          this.loadSLCodes();
        }
      }
  
      if (pDataObjectID == "CAT") {
        if (this.CategoriesList.length != 0) {
          this.CategoriesList.forEach((x) => {
            this.PopUpTableData.push({
              Name: x.description,
              Code: x.code,
              Selected: false,
              RowObject: x
            });
          });
          this.rerender();
          this.ConfigModal.show();
        } else {
          this.loadCategories();
          this.rerender();
          this.ConfigModal.show();
        }
      }
      // Cost Codes
      if (pDataObjectID == "CCD") {
        if(Object.keys(this.DataObject.SL).length == 0)
          return
        if (this.CostCodeList.length != 0) {
          this.CostCodeList.forEach((x) => {
            this.PopUpTableData.push({
              Name: x.longDescription,
              Code: x.id,
              Selected: false,
              RowObject: x
            });
          });
          this.rerender();
          this.ConfigModal.show();
        } else {
          if(this.DataObject.SL[this.DataRowID])
            this.loadCostCode(this.DataObject.SL[this.DataRowID].RowObject.id,this.DataObject.SL[this.DataRowID].RowObject.slType );
          this.rerender();
          this.ConfigModal.show();
        }
      }
      // Cost Centers
      if (pDataObjectID == "CCC") {
        if (this.CostCentersList.length != 0) {
          this.CostCentersList.forEach((x) => {
            this.PopUpTableData.push({
              Name: x.description,
              Code: x.code,
              Selected: false,
              RowObject: x,
            });
          });
          this.rerender();
          this.ConfigModal.show();
        } else {
          this.loadCostCenters();
          this.rerender();
          this.ConfigModal.show();
        }
      }
    }
   
}
