import { Routes } from '@angular/router';
import {CategorylistComponent} from './categorylist/categorylist.component';


export const MasterCatRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: CategorylistComponent
      }
    ]
  }
];
