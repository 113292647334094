import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeeincrementchartpage',
  templateUrl: './employeeincrementchartpage.component.html',
  styleUrls: ['./employeeincrementchartpage.component.css']
})
export class EmployeeincrementchartpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
