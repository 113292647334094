<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Candidate Selection Form</h2>
        <form [formGroup]="candidateSelectionFormGroup">
          <div class="form-body">
            <div class="row">

              <table class="table">
                <tbody>
                <tr>
                  <td>
                    <p>Id</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.id" class="form-control"
                             disabled
                             formControlName="id"
                             id="id" type="text"/>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <p>Recruitment Request</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setRecruitmentRequest($event)" [disabled]="true"
                              class="form-control leave-type-section">
                        <option *ngFor="let recruitmentRequest of recruitmentRequests"
                                [selected]="recruitmentRequest.referenceNumber == selectedRecruitmentRequest"
                                [value]="recruitmentRequest.referenceNumber">{{recruitmentRequest.referenceNumber}}</option>
                      </select>
                    </div>
                  </td>

                  <td>
                    <p>Candidate</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setCandidate($event)"
                              class="form-control leave-type-section">
                        <option *ngFor="let candidate of candidates"
                                [selected]="candidate.id == selectedCandidateId"
                                [value]="candidate.id">{{candidate.id}} {{candidate.name}}</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Vacancy Job Title</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [disabled]="true"
                             [value]="candidateSelectionDto?.recruitmentRequestTitle"
                             class="form-control"
                             id="vacancyJobTitle"
                             placeholder="Vacancy Job Title" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Approved Salary</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [disabled]="true"
                             [value]="candidateSelectionDto?.recruitmentRequestSalaryGrossPackage"
                             class="form-control"
                             id="salaryGrossPackage"
                             placeholder="Approved Salary" type="text"/>
                    </div>
                  </td>


                </tr>

                <tr>
                  <td>
                    <p>Offered Designation</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.offeredDesignation"
                             class="form-control"
                             formControlName="offeredDesignation"
                             id="offeredDesignation"
                             placeholder="Offered Designation" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Offered Salary</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.offeredSalary" class="form-control"
                             formControlName="offeredSalary"
                             id="offeredSalary"
                             placeholder="Offered Salary" type="text"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Company</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setCompany($event)"
                              class="form-control leave-type-section" id="company">
                        <option *ngFor="let company of companies"
                                [selected]="company.code == selectedCompanyCode"
                                [value]="company.code">{{company.code}} {{company.name}}</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <p>Position Advertised Date</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="formatDate(candidateSelectionDto?.positionAdvertisedDate)"
                             class="form-control"
                             formControlName="positionAdvertisedDate" id="positionAdvertisedDate"
                             placeholder="Position Advertised Date"
                             type="date">
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>News Paper Source</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.newspaperSource"
                             class="form-control"
                             formControlName="newspaperSource"
                             id="newspaperSource"
                             placeholder="News Paper Source" type="text"/>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>

                  <td>
                    <p>Online Source</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.onlineSource"
                             class="form-control"
                             formControlName="onlineSource"
                             id="onlineSource"
                             placeholder="Online Source" type="text"/>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <p>Others Source</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.othersSource"
                             class="form-control"
                             formControlName="othersSource"
                             id="othersSource"
                             placeholder="Others Source" type="text"/>
                    </div>
                  </td>
                  <td></td>

                </tr>

                <tr>
                  <td>
                    <p>No Candidate Interviewed</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.noCandidateInterviewed"
                             class="form-control"
                             formControlName="noCandidateInterviewed" id="noCandidateInterviewed"
                             placeholder="No Candidate Interviewed"
                             type="text">
                    </div>
                  </td>

                  <td>
                    <p>No Shortlisted Interviewed</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="candidateSelectionDto?.noShortlistedInterviewed"
                             class="form-control"
                             formControlName="noShortlistedInterviewed"
                             id="noShortlistedInterviewed"
                             placeholder="No Shortlisted Interviewed"
                             type="text">
                    </div>
                  </td>


                </tr>

                <tr>
                  <h4 class="card-title">Interview 1</h4>
                </tr>

                <tr>
                  <td>
                    <p>Number</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (click)="selectInterviewee1()"
                             [value]="interviewee1?interviewee1.empNo:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                  <td>
                    <p>Name</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-15">
                      <input (click)="selectInterviewee1()"
                             [value]="interviewee1?interviewee1.name:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Remarks</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-15">
                      <input [value]="candidateSelectionDto?.interviewee1Remarks"
                             class="form-control"
                             formControlName="interviewee1Remarks" id="interviewee1Remarks"
                             placeholder="Interviewee 1 Remarks"
                             type="text">
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <h4 class="card-title">Interview 2</h4>
                </tr>
                <tr>
                  <td>
                    <p>Number</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (click)="selectInterviewee2()"
                             [value]="interviewee2?interviewee2.empNo:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                  <td>
                    <p>Name</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-15">
                      <input (click)="selectInterviewee2()"
                             [value]="interviewee2?interviewee2.name:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Remarks</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-15">
                      <input [value]="candidateSelectionDto?.interviewee2Remarks"
                             class="form-control"
                             formControlName="interviewee2Remarks" id="interviewee2Remarks"
                             placeholder="Interviewee 2 Remarks"
                             type="text">
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <h4 class="card-title">Interview 3</h4>
                </tr>
                <tr>
                  <td>
                    <p>Number</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (click)="selectInterviewee3()"
                             [value]="interviewee3?interviewee3.empNo:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                  <td>
                    <p>Name</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-15">
                      <input (click)="selectInterviewee3()"
                             [value]="interviewee3?interviewee3.name:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Remarks</p>
                  </td>
                  <td colspan="2">
                    <div class="form-group col-sm-15">
                      <input [value]="candidateSelectionDto?.interviewee3Remarks"
                             class="form-control"
                             formControlName="interviewee3Remarks" id="interviewee3Remarks"
                             placeholder="Interviewee 3 Remarks"
                             type="text">
                    </div>
                  </td>
                  <td></td>
                </tr>


                <tr>
                  <td>
                    <span *ngIf="canConfirm()">
                      <button (click)="confirm()"

                              class="btn btn-pass-section btn-primary mb-2">Confirm
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canReject()">
                      <button (click)="reject()"

                              class="btn btn-pass-section btn-warning mb-2"
                              style="background-color: red">Reject
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canUpdate()">
                      <button (click)="update()"

                              class="btn btn-pass-section btn-success mb-2">Update
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canSave()">
                      <button (click)="save()"

                              class="btn btn-pass-section btn-success mb-2">
                        Save
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canPass()">
                      <button (click)="pass()"

                              class="btn btn-pass-section btn-success mb-2">
                        Pass
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canReset()">
                      <button (click)="reset()"

                              class="btn btn-pass-section btn-warning mb-2">
                        Cancel
                      </button>
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                </tbody>
              </table>
            </div>


          </div>
        </form>


      </div>
    </div>

  </div>
</div>
