<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Leave Request Approval</h4>
        <div class="row">
          <div class="col-lg-12">
            <select (change)="filterLeaveRequests($event)" class="form-control leave-type-section">
              <option value="6" selected>All</option>
              <option value="5">Pending</option>
              <option value="4">Passed</option>
              <option value="3">Manager Approved</option>
              <option value="2">Rejected</option>
              <option value="1">Approved</option>
            </select>
            <select (change)="filterLeaveRequestsByCompany($event)" class="form-control leave-type-section">
             <option selected>Company</option>
             <option *ngFor="let company of companies" [value]="company.code">{{company.name}}</option>
            </select>

            <select (change)="filterLeaveRequestsByDepartment($event)" class="form-control leave-type-section">
              <option selected>Department</option>
              <option *ngFor="let department of departments" [value]="department.code">{{department.description}}</option>
             </select>
          </div>
        </div>

        <!--<ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [rows]='rows'></ngx-datatable>-->
        <ngx-datatable #table
                       class='material'
                       [columns]="columns"
                       [columnMode]="'force'"
                       [scrollbarH]="scrollBarHorizontal"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [rows]="rows">
          <ng-container *ngFor='let column of columns' >
            <ngx-datatable-column *ngIf='column.name === "Employee Number"' name="Employee Name" prop="empNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.employeeName}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee Name"' name="Document Number" prop="name">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                      {{row?.voucherCode}} {{row?.docNo}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "From Date"' name="From Date" prop="fromDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{formatDate(row?.fromDate)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "To Date"' name="To Date" prop="fromDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{formatDate(row?.toDate)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "Remarks"' name="Remarks" prop="remarks">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.remarks}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Leave Balance"' name="Leave Balance" prop="leaveBalance">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.employeeWsDTO.levBalDays}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Handover To (Emp No)"' name="Handover To (Emp No)"
              prop="handoverToEmpNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.subsituteemployeeWsDTO?.empNo}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Handover To (Emp Name)"' name="Handover To (Emp Name)" prop="handoverToEmpName">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.subsituteemployeeWsDTO?.name}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "status"' name="status" prop="status">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{
                          getStatus(row)
                        }}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Actions"' name="Actions" prop="remarks">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div class="confirmation-section"
                     *ngIf="row.passed == 'Y' && row.managerApproved == true && row.confirmed ==  'N' && row.rejected ==  'N'">
                  <button *ngIf="row.currentUserAllowedToApproveCurrentLevel"
                          class="btn btn-primary m-1 " (click)="remarksForApprove(row?.id)"><i
                      class="fas fa-check"></i></button>
                  <button
                      *ngIf="row.currentUserAllowedToApproveCurrentLevel"
                      class="btn btn-warning m-1 " (click)="remarksForReject(row?.id)"><i
                      class="fas fa-times"></i></button>
                  <button class="btn btn-info m-1 " (click)="openDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>
                <div class="approval-section"
                     *ngIf="row.passed == 'Y' && row.managerApproved == false && row.confirmed ==  'N' && row.rejected ==  'N'">
                  <button *ngIf="row.currentUserAllowedToApprove" class="btn btn-success "
                          (click)="managerApprove(row.id)"><i class="fas fa-check"></i></button>
                  <button
                      *ngIf="row.currentUserAllowedToApprove"
                      class="btn btn-warning m-1 " (click)="remarksForReject(row?.id)"><i
                      class="fas fa-times"></i></button>
                  <button class="btn btn-info m-1 " (click)="openDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>
                <div class="approval-section"
                     *ngIf="row.passed == 'N' || row.confirmed == 'Y' || row.rejected == 'Y'">
                  <button class="btn btn-info m-1 " (click)="openDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>

        <div class="pagination-set">
          <mat-paginator (page)="searchPaginated($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
