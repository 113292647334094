import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataChartsGuard implements CanActivate {

  
  constructor(public router: Router , private authService: AuthService) { }

  canActivate(){
    if (this.authService.isMgtTestUser()) {
      return true;
    }
    return false;
  }
  
}
