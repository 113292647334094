import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LEAVE_REQ_APPROVED, LEAVE_REQ_PENDING, LEAVE_REQ_REJECTED } from '../../app.constants';
import { HrpHeadersService } from '../service/hrp-headers.service'
import { Observable } from 'rxjs';
import {ILeaveRequest, ILeaveRequestHeader} from '../model/ileave-request';
import {environment} from '../../../environments/environment';
import {ILeaveType} from '../model/ileave-type';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private headers: HrpHeadersService) { }

  getPeriodByCompanyAndBranch(CompnayCode, FinancialCode, BranchCode): Observable<any>
  {
    const EndPoint = `${environment.host}/api/glFinPeriod/get-by-comp-and-branch?compCode=${CompnayCode}&financialYear=${FinancialCode}&branchCode=${BranchCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getLeaveRequestVouchers(CompCode, FyId): Observable<any>
  {
    const EndPoint = `${environment.host}/api/vouchers/leave-request?compCode=${CompCode}&financialYear=${FyId}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  saveLeaveRequestHeaders(Payload): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/add-with-details`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }
  
}
