<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title">Candidate List</h4>
          <button (click)="listDetails(null)" class="new btn btn-success mt-2 mb-2">New Candidate
          </button>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <form (ngSubmit)="(searchCandidates())" [formGroup]="candidateFormGroup"
                  name="employeeForm">
              <table class="table">
                <tbody>
                <tr>
                  <td>
                    <p>Id</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="id" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Name</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="name" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Email</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="name" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Phone</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="phone" type="text"/>
                    </div>
                  </td>

                </tr>

                <tr>
                  <td>
                    <button class="btn btn-primary btn-euquiry">Search</button>
                  </td>
                </tr>

                </tbody>
              </table>
            </form>
          </div>
        </div>

        <ngx-datatable #table
                       [columnMode]="'force'"
                       [columns]="columns"
                       [footerHeight]="50"
                       [headerHeight]="50"
                       [rowHeight]="'auto'"
                       [rows]="rows"
                       [scrollbarH]="scrollBarHorizontal"
                       class='material'>
          <ng-container *ngFor='let column of columns'>
            <ngx-datatable-column *ngIf='column.name === "Id"' name="Id" prop="id">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.id}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Name"' name="Name"
                                  prop="name">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.name}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Email"' name="Email"
                                  prop="email">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.email}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Phone"' name="Phone"
                                  prop="phone">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.phone}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Address"' name="Address"
                                  prop="address">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.address}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Action"' [sortable]="false" name="Action">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button (click)="listDetails(row.id)" class="btn btn-info m-1 "><i
                    class="fas fa-info-circle"></i></button>
                <!--                <button (click)="delete(row?.id)" class="btn btn-warning m-1 "-->
                <!--                        style="background-color: red"><i-->
                <!--                    class="fas fa-times"></i></button>-->
              </ng-template>
            </ngx-datatable-column>


          </ng-container>
        </ngx-datatable>

        <div class="pagination-set">
          <mat-paginator (page)="searchByPageCandidates($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>

      </div>
    </div>
  </div>
</div>
