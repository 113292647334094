import { IEmployee } from "./employee";
import { IVoucher } from "./ivoucher";

export class ILeaveRequestHeader {

    employee: IEmployee;
    applyDate: Date;
    remarks: string;
    voucher: IVoucher;
    fromDate: Date;
    toDate: Date;
    rejectRemarks: string;
    telephone : string;
    levBalDays : string;
    address : string; //Address during leave
    travelDate : Date;
    carrier : string;
    handover_empNo : Number; 
    handover_name : string; 
    reason : string;
    
    
}

export class ILeaveRequestDetails {

    leaveRequestHeader : ILeaveRequestHeader;
    id: number;
    fromDate: Date;
    toDate: Date;
    numberOfDays: number;
    leaveTypeId : number;
}

export class ILeaveRequest {

    header : ILeaveRequestHeader;
    details : ILeaveRequestDetails[];

}
