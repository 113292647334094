<div class="card">
    <div class="card-body">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <h4 class="card-title">Visitors By Countries</h4>
                <div class="visitors">
                    <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [schemeType]="schemeType" [results]="multi" [gradient]="gradient" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                        [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines"
                        [barPadding]="barPadding" [groupPadding]="20" [roundDomains]="roundDomains">
                    </ngx-charts-bar-vertical-2d>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <!-- row -->
                <div class="row m-b-15">
                    <div class="col-9">Germany</div>
                    <div class="col-3 text-right">28%</div>
                    <div class="col-12">
                        <div class="progress m-t-5">
                            <div class="progress-bar bg-info" role="progressbar" style="width: 48%" aria-valuenow="48" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>

                </div>
                <!-- row -->
                <div class="row m-b-15">

                    <div class="col-9"> USA</div>
                    <div class="col-3 text-right">21%</div>
                    <div class="col-12">
                        <div class="progress m-t-5">
                            <div class="progress-bar bg-cyan" role="progressbar" style="width: 33%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <!-- row -->
                <div class="row m-b-15">
                    <div class="col-9"> India</div>
                    <div class="col-3 text-right">18%</div>
                    <div class="col-12">
                        <div class="progress m-t-5">
                            <div class="progress-bar bg-purple" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <!-- row -->
                <div class="row">
                    <div class="col-9">Spain</div>
                    <div class="col-3 text-right">12%</div>
                    <div class="col-12">
                        <div class="progress m-t-5">
                            <div class="progress-bar bg-danger" role="progressbar" style="width: 15%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>