<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Project Assinging</h4>
                <h6 class="card-subtitle">Add
                    <code>.striped-rows</code> class in the form with bordered form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal striped-rows b-form">
                <div class="card-body">
                    <h4 class="card-title">Personal Info</h4>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">First Name</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="First Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Last Name</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Last Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Email</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Contact No</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Contact No Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <h4 class="card-title">Requirements</h4>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Company</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Interested In</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Desiging</option>
                                <option>Development</option>
                                <option>Videography</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Budget</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Less then $5000</option>
                                <option>$5000 - $10000</option>
                                <option>$10000 - $20000</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Select File</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Upload</span>
                                </div>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">About Project</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="About Project Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Employee Timing</h4>
                <h6 class="card-subtitle">Add
                    <code>.striped-rows</code> class in the form with bordered form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal striped-rows b-form">
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Employee Name</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="far fa-user"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Project Name</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="fas fa-briefcase"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Date</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="fas fa-comments"></i>
                                </span>
                            </div>
                            <input type="date" class="form-control" placeholder="Date here" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Rate Per Hour</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                            <div class="input-group-append">
                                <span class="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Start Time</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="far fa-clock"></i>
                                </span>
                            </div>
                            <input type="time" class="form-control" placeholder="Date here" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">End Time</label>
                            </div>
                        </div>
                        <div class="input-group col-sm-9">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">
                                    <i class="far fa-clock"></i>
                                </span>
                            </div>
                            <input type="time" class="form-control" placeholder="Date here" aria-label="Username" aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Notes</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <textarea class="form-control" id="exampleTextarea" rows="3"></textarea>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Event Registration</h4>
                <h6 class="card-subtitle">Add
                    <code>.striped-rows</code> class in the form with bordered form styling.</h6>
            </div>
            <hr class="m-t-0">
            <form class="form-horizontal striped-rows b-form">
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Full Name</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Full Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Title</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Title Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Company</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Email</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Contact No</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Contact No Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-3">
                            <div class="b-label">
                                <label for="inputEmail3" class="control-label col-form-label">Existing Customer</label>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="custom-control custom-radio custom-control-inline m-t-5">
                                <input type="radio" class="custom-control-input" id="customControlValidation2" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation2">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" class="custom-control-input" id="customControlValidation3" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation3">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->