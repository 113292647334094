<div class="card-group">
    <!-- Column -->
    <div class="card">
        <div class="card-body text-center">
            <h4 class="text-center text-info">Unique Visit</h4>
            <h2>1200</h2>
            <div class="row p-t-10 p-b-10">
                <!-- Column -->
                <div class="col text-center align-self-center">
                    <div data-label="20%" class="css-bar m-b-0 css-bar-primary css-bar-20">
                        <i class="display-6 mdi mdi-account-circle"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-up text-success"></i>
                        <br> 12056</h4>
                </div>
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-down text-danger"></i>
                        <br> 145</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="card">
        <div class="card-body text-center">
            <h4 class="text-center text-danger">Total Visit</h4>
            <h2>1200</h2>
            <div class="row p-t-10 p-b-10">
                <!-- Column -->
                <div class="col text-center align-self-center">
                    <div data-label="20%" class="css-bar m-b-0 css-bar-danger css-bar-20">
                        <i class="display-6 mdi mdi-star-circle"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-up text-success"></i>
                        <br> 21456</h4>
                </div>
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-down text-danger"></i>
                        <br> 145</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="card">
        <div class="card-body text-center">
            <h4 class="text-center text-cyan">Bounce rate</h4>
            <h2>1200</h2>
            <div class="row p-t-10 p-b-10">
                <!-- Column -->
                <div class="col text-center align-self-center">
                    <div data-label="20%" class="css-bar m-b-0 css-bar-success css-bar-20">
                        <i class="display-6 mdi mdi-briefcase-check"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-up text-success"></i>
                        <br> 12465</h4>
                </div>
                <div class="col-md-6 col-sm-12">
                    <h4 class="font-medium m-b-0">
                        <i class="ti-angle-down text-danger"></i>
                        <br> 145</h4>
                </div>
            </div>
        </div>
    </div>
    <!-- Column -->
</div>