export class RecruitmentRequestDto {

  referenceNumber: number;

  compCode: string;

  dptCode: string;

  dptCompCode: string;

  vacancyJobTitle: string;

  requiredByDate: Date;

  additionalNosRequired: string;

  salaryGrossPackage: string;

  presentStrength: string;

  position: number;

  gradeCode: string;

  gradeCompCode: string;

  workLocation: string;

  recruitmentType: string;

  repEmpId: number;

  repEmpPmlId: number;

  reasonForReplacement: string;

  skill1: string;

  skill2: string;

  skill3: string;

  skill4: string;

  approvedBy: string;

  endorsedBy: string;

  confirmedBy: string[];

  createOn: Date;

  updateOn: Date;

  reqEmpId: number;

  reqEmpPmlId: number;

  languagesAndNationalities: string;

  currentUserAllowedToConfirmOrReject: boolean;

  status: string;

  approvalLevel: string;

  rejectedBy: string;

  rejectRemark: string;

  jobDescFileName: String;


  constructor() {
  }

}
