import {Routes} from '@angular/router';
import {AuthGuardService as AuthGuard} from './shared/service/auth-guard.service';
import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {path: '', redirectTo: '/authentication/login', pathMatch: 'full'},
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)/*,
        canActivate: [AuthGuard]*/
      },
      {
        path: 'chartsdata',
        loadChildren: () => import('./datacharts/datacharts.module').then(m => m.DatachartsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'starter',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      { path: 'cards', loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule) },
      {
        path: 'leaves',
        loadChildren: () => import('./leavesection/leave.module').then(m => m.LeaveModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'erp',
        loadChildren: () => import('./erp/erp.module').then(m => m.ErpModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'hrdocuments',
        loadChildren: () => import('./hrdocument/hrdocument.module').then(m => m.HrdocumentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'provision',
        loadChildren: () => import('./provisionsection/provision.module').then(m => m.ProvisionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'mastercat',
        loadChildren: () => import('./mastercat/mastercat.module').then(m => m.MastercatModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'uom',
        loadChildren: () => import('./uom/uom.module').then(m => m.UomModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'documenttypes',
        loadChildren: () => import('./document-types/document-types.module').then(m => m.DocumentTypesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'enquiry',
        loadChildren: () => import('./employeeenquiry/employeeenquiry.module').then(m => m.EmployeeenquiryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard-m',
        loadChildren: () => import('./dashboard-m/dashboard-m.module').then(m => m.DashboardMModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'recruitment',
        loadChildren: () => import('./recruitment/recruitment.module').then(m => m.RecruitmentModule),
        canActivate: [AuthGuard]
      },
      {path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule)},
      {path: 'forms', loadChildren: () => import('./form/forms.module').then(m => m.FormModule)},
      {path: 'tables', loadChildren: () => import('./table/tables.module').then(m => m.TablesModule)},
      {path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartModule)},
      {
        path: 'widgets',
        loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
      },
      {path: 'ecom', loadChildren: () => import('./ecommerce/ecom.module').then(m => m.EcomModule)},
      {
        path: 'timeline',
        loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
      },
      {
        path: 'extra-component',
        loadChildren:
          () => import('./extra-component/extra-component.module').then(m => m.ExtraComponentModule)
      },
      { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
      {
        path: 'apps/email',
        loadChildren: () => import('./apps/email/mail.module').then(m => m.MailModule)
      },
      { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
      {
        path: 'sample-pages',
        loadChildren: () => import('./sample-pages/sample-pages.module').then(m => m.SamplePagesModule)
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
            () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'invoice',
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
];
