import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import {environment} from '../../../../environments/environment';
import { HeadersService } from '../../service/headers.service'
import { MaterialPayload } from '../../model/ERP Models/Payloads.model'

@Injectable({
  providedIn: 'root'
})
export class InventoryPricingService {

  constructor(
    private http: HttpClient,
    private headers: HeadersService
  ) { }

  getPeriodByCompanyAndBranch(CompanyCode, FinancialCode, BranchCode): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/glFinPeriod/get-by-comp-and-branch?compCode=${CompanyCode}&financialYear=${FinancialCode}&branchCode=${BranchCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getJobs(page, size): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/jc-jobs/all?page=${page}&size=${size}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getVoucherCode(compCode, vTypeDocType, financialYear, moduleCode, storeCode): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/sc-vouchers/get-by-doc-type-and-module?compCode=${compCode}&vTypeDocType=${vTypeDocType}&financialYear=${financialYear}&module=${moduleCode}&storeCode=${storeCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getCostCenters(): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/costcentres`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }
  
  getStocks(): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/stockMasters/page`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }
  
  getStockMasters(): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/unitOfMeasurement/all`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getAllUnitOfMeasurement(): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/unitOfMeasurement/all`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getUnitOfMeasurementByCode(code): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/unitOfMeasurement/${code}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getStores(compCode): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/store-master/find-by-company?compCode=${compCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getPOReqHeadersById(id): Observable<any>{
    const EndPoint = `${environment.hostErp}/api/poReqHdr/${id}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  generateDocumentCode(Payload, PeriodID)
  {
    const EndPoint = `${environment.hostErp}/api/poReqHdr/generate-doc-no/${PeriodID}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  saveMaterialRequest(Payload: MaterialPayload| any)
  {
    const EndPoint = `${environment.hostErp}/api/poReqHdr/poRequest/save`;
    return this.http.post(EndPoint, Payload ,this.headers.getHttpOptions('POST', true));
  }

  saveMultiFileUpload(Payload)
  {
    const EndPoint = `${environment.hostErp}/api/poReqHdr/multipleUploadfile`;
    return this.http.post(EndPoint, Payload ,this.headers.getHttpOptions('POST'));
  }

  passMaterialRequestTransaction(savedHeader: number , userCode : any) : Observable<any> {
    const EndPoint = `${environment.hostErp}/api/poReqHdr/pass/${savedHeader}/user/${userCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

}
