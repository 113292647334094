import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdminService } from '../../shared/service/admin.service'
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgForm } from "@angular/forms";
import { UserService } from 'src/app/shared/service/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



interface Manager {
  id: number;
  empNo: number;
  pmlId: number;
  name: string;
  doj: string;
  dob: string;
  compCode: string;
  companyName: string;
  departement: Departement;
  profession: string;
  nationality: string;
  matnPmlId?: any;
  matnMatId?: any;
  ppEmpTypeId?: any;
  levBalDays: number;
  levBalprov: number;
  gratuityProv: number;
  gratuityDays: number;
  grade?: any;
  gender: string;
  bankAcno?: any;
  bankName: string;
  position?: any;
  costCenter?: any;
  accumAbsentDays: number;
}

interface Departement {
  code: string;
  company?: any;
  description: string;
}


interface SavePayload {
  employeeAccount: employeeAccountDetails[];
}

interface employeeAccountDetails {
  empNo: number,
  oraUser: string,
  roles: any[],
}


@Component({
  selector: 'app-employee-account',
  templateUrl: './employee-account.component.html',
  styleUrls: ['./employee-account.component.css']
})
export class EmployeeAccountComponent implements OnInit {

  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  @ViewChild("configModal") public ConfigModal: ModalDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  SelectedManager: Manager;
  ManagersList: Manager[] = [];
  oraUsers: [];
  employeeAccountList: any[] = [];
  BodyRows: number[] = [1]
  CurrentRow: number = 1;
  DataObject = {
    "Body": {
      1: {
        "empName": '',
        "empNo": '',
        "oraUser": "",
        "roles" :[],
      }
    },

  };
  roles: any[] = [];
  isSaveDisabled: boolean = false;
  EmployeeList: String[] = [];
  PopUpTableData: any[] = [];
  flag = false;
  employeeName: String;
  oraUserName: String;
  isFormSubmitted = false;
  isFormValid :boolean = true;
  selectedFiles: FileList;
  currentFile: File;
  constructor(public AdminService: AdminService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
    ) {
    this.AdminService.getAllManagerEmployees().subscribe(data => {
      // this.dataSource.data = data;
    });
  }

  ngOnInit(): void {
  
    this.getAllOraUsers();
    this.AdminService.getManagers().subscribe(
      (data: Manager[]) => {
        this.ManagersList = data;
        this.SelectedManager = data[0];
        this.selectManager(this.SelectedManager.empNo);
        this.spinner.hide();
      },
      error => {
        console.log('Error');
        this.spinner.hide();
      }
    );

    this.userService.getRoles().subscribe(data => {
      if (data) {
        this.roles = data;
      //  this.selectedRoles.push(this.roles[0].id);
      }
    }, err => {
      this.toastr.error("Error Occurred while getting roles");
    })
  }

  popUpTableValueSelected(row) {
    this.DataObject.Body[this.CurrentRow.toString()]['empName'] = row?.Name;
    this.DataObject.Body[this.CurrentRow.toString()]['empNo'] = row?.Code;
    row.Selected = true;
    this.EmployeeList.push(row.Name);
    this.ConfigModal.hide();
  }

  selectManager(empNo) {
    this.SelectedManager = this.ManagersList.filter(x => x.empNo == empNo)[0];
    this.spinner.show();
    this.AdminService.getManagerEmployees(empNo).subscribe(
      data => {
        this.EmployeeList = data.employees;
        this.spinner.hide();
      }
    )
    error => {
      console.log('Error');
      this.spinner.hide();
    }

  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  selectEmployee() {
    this.PopUpTableData = [];
    let Data = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null
    if (Data) {
      Data.forEach((element: any) => {
        this.PopUpTableData.push({
          Code: element.empNo,
          Name: element.name,
          Selected: this.EmployeeList.includes(element.name) ? true : false,
          RowObject: element
        });
      })
    };

    if (!this.flag)
      this.dtTrigger.next();
    else
      this.rerender();
    this.flag = true
    this.ConfigModal.show();

  }

  getAllOraUsers() {
    this.AdminService.getAllOraUsers().subscribe(data => {
      this.oraUsers = data;
    })
  }

  selectedOraUser(data) {
    this.oraUserName = data;
    this.DataObject.Body[this.CurrentRow.toString()]['oraUser'] = data;

  }

  addRow() {
    this.BodyRows.push(this.BodyRows[this.BodyRows.length - 1] + 1);
    this.CurrentRow = this.CurrentRow + 1
    this.DataObject.Body[this.CurrentRow] = {};
  }

  deleteBodyRow(row) {
    delete this.DataObject.Body[row.toString()];
    this.BodyRows = this.BodyRows.filter(x => x != row);
    this.CurrentRow = Number.parseInt(Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length - 1]);
  }

  saveDetails() {
    this.isFormValid = true;
    this.isSaveDisabled = true;
    let Payload: SavePayload = {
      employeeAccount: []
    }

    Object.keys(this.DataObject.Body).forEach(BodyObject => {
      Payload.employeeAccount.push({
        "empNo": this.DataObject.Body[BodyObject]?.empNo ? this.DataObject.Body[BodyObject]?.empNo : null,
        "oraUser": this.DataObject.Body[BodyObject]?.oraUser ? this.DataObject.Body[BodyObject]?.oraUser : null,
        "roles": this.DataObject.Body[BodyObject]?.roles ? this.DataObject.Body[BodyObject]?.roles : null,
      })
    }),

Payload.employeeAccount.forEach(data =>{
    if(this.isFormValid == true){
      if(data?.empNo==null || data?.oraUser==null || data?.roles==null){
        return this.isFormValid = false;
      }
    }
})
    if(this.isFormValid == true){
      this.AdminService.saveAccountForEmployee(Payload).subscribe(data => {
              this.isSaveDisabled = false;
              this.toastr.success("Account created for employee successfully");
              Payload.employeeAccount=[];  
              this.DataObject = {
                "Body": {
                  1: {
                    "empName": '',
                    "empNo": '',
                    "oraUser": '',
                    "roles" :[],
                  }
                },
            
              };
              
            }, err => {
              this.isSaveDisabled = false;
              this.toastr.error("Error Occurred while saving the details");
            })
          } else{
            this.isSaveDisabled = false;
            this.toastr.error("Please fill the details");
          }
    }
  

  disableAddButton() {
    let LastDataObject: any = this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length - 1]]
    if (!LastDataObject?.empName || !LastDataObject?.oraUser || !LastDataObject?.empNo || LastDataObject?.roles?.length <=0 || !LastDataObject.roles){
        return true;
    }else{
      return false;
    }
  }
  isChecked(role) {
    if (this.DataObject.Body[this.CurrentRow.toString()]['roles'].indexOf(role.id) != -1) {
      return true;
    }else{
      return false;
    }
    
  }

  roleChange(role) {
    if(this.DataObject.Body[this.CurrentRow.toString()]['roles']==null){
      this.DataObject.Body[this.CurrentRow.toString()]['roles'] =[];
    }

  var index= this.DataObject.Body[this.CurrentRow.toString()]['roles'].indexOf(role.id);
   if(index==-1){
    this.DataObject.Body[this.CurrentRow.toString()]['roles'].push(role.id);
    } else{
      this.DataObject.Body[this.CurrentRow.toString()]['roles'].splice(index,1);
    }


  }

  changeRoleButton(roleModal, row) {
    this.CurrentRow = row;
    this.modalService.open(roleModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }
  
  filesUploaded(e) {
   this.selectedFiles = e.target.files;
  }

  uploadAccountFile(){
    this.currentFile = this.selectedFiles.item(0);
    this.AdminService.uploadAccountCreationFile(this.currentFile).subscribe(data => {
      this.toastr.success("Employee Accounts Created successfully");
    },err=>{
      this.toastr.error("Error Occurred");
    });
  }

  getApprovalLevelLeaves() :Observable<any>{
    return null;
  }

}
