import { Routes } from '@angular/router';
import { GenralledgerComponent } from './genralledger/genralledger.component';
import { InventoryPricingComponent } from './inventorypricing/inventorypricing.component';
import { JournalvoucherComponent } from './journalvoucher/journalvoucher.component'
import { MaterialComponent } from './material/material.component'
import { BankpaymentComponent } from './bankpayment/bankpayment.component'
import { CashpaymentComponent } from './cashpayment/cashpayment.component'
import { BankreceiptComponent } from './bankreceipt/bankreceipt.component'
import { CashapprovalComponent } from './cashapproval/cashapproval.component'
import { CashreceiptComponent } from './cashreceipt/cashreceipt.component'
import { CashpaymentapprovalComponent } from './cashpaymentapproval/cashpaymentapproval.component'

export const EmployeeEnquiryRouting: Routes = [
  {
    path: 'genral-ledger',
    component: GenralledgerComponent,
    children: 
        [
            {
                path: 'journal-voucher',
                component:JournalvoucherComponent
            },
            {
                path: 'bank-payment',
                component:BankpaymentComponent
            },
            {
                path: 'cash-payment',
                component: CashpaymentComponent
            },
            {
                path: 'bank-receipt',
                component: BankreceiptComponent
            },
            {
                path: 'cash-approval',
                component: CashapprovalComponent
            },
            {
                path: 'cash-receipt',
                component: CashreceiptComponent
            },
            {
                path: 'cash-payment-approval',
                component: CashpaymentapprovalComponent
            }
        ]
  },
  {
    path: 'inventorypricing',
    component: InventoryPricingComponent,
    children: 
        [
            {
                path: 'material',
                component:MaterialComponent
            }
        ]
  } 
];
