import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApprovalManagementComponent } from './approval-management/approval-management.component';
import { EmployeeAccountComponent } from './employee-account/employee-account.component';
import {MappingComponent} from './mapping/mapping.component'

const routes: Routes = [
  {
    path: '',
    children: [
      { 
        path: 'mapping',
        component: MappingComponent
      }
    ]
  },
  {
    path: '',
    children: [
      { 
        path: 'employeeAccount',
        component: EmployeeAccountComponent
      }
    ]
  },
  {
    path: '',
    children: [
      { 
        path: 'approvalManagement',
        component: ApprovalManagementComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
