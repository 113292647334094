<div *ngIf="!show" id="JVSelector" class="col mx-auto w-75 p-3 form-card shadowdiv">
    <br>
    <div class="row">
      <div class="col-sm">
        <h4> Select Period and Voucher </h4>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-3"> <label> Period </label> </div>
      <div class="col-sm">
        <select class=" form-control " (change)="setDateFromPeriod($event)">
          <option *ngFor="let period of PeriodList" [value]="period.Period"> {{ period.Period }}</option>
        </select>
      </div>
      <div class="col-sm date">
        <input [value]="SelectedPeriod.FromDate? SelectedPeriod.FromDate: '' " class="form-control" disabled>
      </div>
      <div class="col-sm date">
        <input [value]="SelectedPeriod.ToDate? SelectedPeriod.ToDate: '' " class="form-control" disabled>
      </div>
    </div>
    <br>

    <div  class="row mb-3">
      <div class="col-3"> <label> Company </label> </div>

      <div class="col">
        <select disabled='!!setCompanyDropDown' class="form-control" (change)="setCompany($event)">
          <option *ngFor="let company of CompaniesList;let i = index" [selected]="setCompanyDropDown? i==setCompanyDropDown: i==0" [value]="company?.code">
            {{company?.name}}
          </option>
        </select>
      </div>
    </div>

    <div  class="row">
      <div class="col-3"> <label> Voucher Period </label> </div>

      <div class="col">
        <select [disabled]="disableVoucherPeriod" class="form-control" (change)="setVoucherCode($event)">
          <option *ngFor="let voucher of VourcherList;let i = index" [selected]="i==0" [value]="voucher.docType+'|'+voucher.code+'|'+voucher.description">
            {{ voucher.code +' - '+ voucher.description}}
          </option>
        </select>
      </div>
    </div>
    <div class="btns">
      <button class="btn btn-primary mr-3" (click)="showLeavesRequests()"
              [disabled]="SelectedVoucher? !SelectedVoucher.code: true"> Start
      </button>
      <button class="btn btn-primary"> Cancel</button>
    </div>
  </div>
<div *ngIf="show && isReviewMode">
  <button class="btn btn-danger mb-2" (click)="back()">Back</button>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Leave Request Section #1</h4><br>
          <form [formGroup]="leaveRequestHeaderForm" (ngSubmit)="onSubmit()">
            <div class="form-body">
              <div class="row">
                <div class="col-sm-2 spaces">
                  <label for="">Voucher Code</label>
                  <input class="form-control" [value]="SelectedVoucher.code" disabled>
                </div>

                <div class="col-sm-2 spaces">
                  <label for="">Document Number</label>
                  <input class="form-control" [value]="DocumentNumber? DocumentNumber : ''"
                         disabled>
                </div>

                <div class="col-sm spaces">
                  <label for="">Apply Date</label>
                  <input class="form-control" type="text" [value]="formatDate(leaveRequestApplyDate)" disabled>
                </div>

                <div class="col-sm check">
                  <input type="checkbox" class="mr-1" disabled [checked]="RejectedFlag" >
                  <label>Rejected</label>
                </div>

                <div class="col-sm check">
                  <input type="checkbox" class="mr-1" disabled [checked]="PassedFlag">
                  <label>Passed</label>
                </div>

                <div class="col-sm check">
                  <input type="checkbox" class="mr-1" disabled [checked]="ConfirmedCheckbox">
                  <label>Confirmed</label>
                </div>
              </div>


              <div class="row mt-2"
                   *ngIf='isCurrentUserAllowedToApprove == true || (currentUserAllowedToApproveCurrentLevel == true && !isOwnerOfRequest())'>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Emp. Number</label>
                    <input type="text" class="form-control" placeholder="Emp. Number" id="empNumber" readonly
                           formControlName="emp_number" [value]="fetchedempNo" (keyup.enter)="loadEmployeeInfos()">
                    <small class="text-danger" *ngIf="empNumber.hasError('required') && !empNumber.pristine">
                      This field is required.
                    </small>
                  </div>
                </div>

                  <div class="col-md-4 form-group">
                    <label>Employee: </label>
                    <input class="form-control" (click)="selectEmployee(1)" *ngIf="!Flag_Details_Add" value="Select Employee" readonly [value]="selectedEmployeeName" >
                    <input class="form-control"  value="Select Employee" *ngIf="Flag_Details_Add" readonly [value]="selectedEmployeeName" >
                    <!-- <ng-container>
                      <ngx-select-dropdown (change)="onEmployeeChange($event)" (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [config]="config" [options]="allMappedEmployees"></ngx-select-dropdown>
                    </ng-container> -->
                  </div>

                <div class="col-md-2 form-group btn-pass-section save">
                    <button class="btn btn-success"  [disabled]="isSaveDisabled()" (click)="saveTransaction(false)" >SAVE</button>
                  </div>
                  <div class="col-md-2 form-group btn-pass-section">
                    <button class="btn btn-success hello"  [disabled]="isPassDisabled()" (click)="passTransaction()">PASS</button>
                  </div>

                <div class="col-sm-2 form-group btn-pass-section" *ngIf='canConfirm()'>
                  <button  class="btn btn-success" (click)="remarksForApprove()" >Confirm</button>
                </div>
                <div class="col-sm-2 form-group btn-pass-section" *ngIf='canApprove()'>
                  <button  class="btn btn-success"  (click)="managerApprove()" >Approve</button>
                </div>
              </div>


              <div class="row employee" *ngIf='(isCurrentUserAllowedToApprove == false && currentUserAllowedToApproveCurrentLevel == false) || isOwnerOfRequest() ' >
              <div class="col-md-2">
                <div class="form-group">
                  <label for="">Emp. Number</label>
                  <input type="text" class="form-control" placeholder="Emp. Number" id="empNumber" readonly
                         formControlName="emp_number" [value]="fetchedempNo" (keyup.enter)="loadEmployeeInfos()">
                  <small class="text-danger" *ngIf="empNumber.hasError('required') && !empNumber.pristine">
                    This field is required.
                  </small>
                </div>
              </div>

                <div class="col-md-4 form-group">
                  <label>Employee: </label>
                  <label>Employee: </label>
                  <input class="form-control" (click)="selectEmployee(1)" *ngIf="!Flag_Details_Add" value="Select Employee" readonly [value]="selectedEmployeeName" >
                  <input class="form-control"  value="Select Employee" *ngIf="Flag_Details_Add" readonly [value]="selectedEmployeeName" >
                  <!-- <ng-container>
                    <ngx-select-dropdown (change)="onEmployeeChange($event)" (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [config]="config" [options]="allMappedEmployees"></ngx-select-dropdown>
                  </ng-container> -->
                </div>

                <div class="col-md-3 form-group btn-pass-section save">
                  <button class="btn btn-success"  [disabled]="isSaveDisabled()" (click)="saveTransaction(false)">SAVE</button>
                </div>
                <div class="col-md-3 form-group btn-pass-section">
                  <button class="btn btn-success"  [disabled]="isPassDisabled()" (click)="passTransaction()">PASS</button>
                </div>

              </div>



            <div class="row mt-2">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Job Title</label>
                  <input type="text" class="form-control" placeholder="Job Title" id="job_title"  disabled
                         formControlName="job_title">
                  <small class="text-danger" *ngIf="jobTitle.hasError('required') && !jobTitle.pristine">
                    This field is required.
                  </small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Department</label>
                  <input type="text" class="form-control" placeholder="Department" id="department" disabled
                         formControlName="department">
                  <small class="text-danger" *ngIf="department.hasError('required') && !department.pristine">
                    This field is required.
                  </small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Site</label>
                  <input type="text" class="form-control" placeholder="Site" id="site" disabled
                         formControlName="site">
                  <small class="text-danger" *ngIf="site.hasError('required') && !site.pristine">
                    This field is required.
                  </small>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="">Date of Join</label>
                  <input class="form-control" placeholder="Date of Join" name="dp" id="doj" disabled
                         formControlName="doj">
                  <small class="text-danger" *ngIf="getDoj.dirty && getDoj.invalid">
                    <span *ngIf="getDoj.invalid">This field is required.</span>
                  </small>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <div class="form-group decrease">
                  <label for="">Grade</label>
                  <input type="text" class="form-control" placeholder="Grade" id="grade" disabled
                         formControlName="grade">
                  <small class="text-danger" *ngIf="grade.hasError('required') && !grade.pristine">
                    This field is required.
                  </small>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label for="">Return Date</label>
                  <input class="form-control" placeholder="Return Date" name="dp" ngbDatepicker
                         #returnDate="ngbDatepicker" (click)="returnDate.toggle()" id="returnDate" disabled
                         formControlName="return_date">
                  <small class="text-danger" *ngIf="getReturnDate.dirty && getReturnDate.invalid">
                    <span *ngIf="getReturnDate.invalid">This field is required.</span>
                  </small>
                </div>
              </div>

              <div class="col-sm-3">
                <label>Accum. Absent Days</label>
                <input [readonly]="ConfirmedFlag" class="form-control" id="accumAbsentDays"   formControlName="accumAbsentDays" disabled>
              </div>
              <div class="col-md-3">
                <label>Leave Balance Days</label>
                <input class="form-control"  [value]="employee?.levBalDays? ((employee?.levBalDays)-SumNumOfDays): ''" disabled>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="form-group spaces">
                  <label>Present Address</label>
                  <input  placeholder="House Number" class="form-control" [disabled]="ConfirmedFlag" [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.HouseNumber">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input placeholder="Street" class="form-control" [disabled]="ConfirmedFlag" [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.Street">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input placeholder="City" class="form-control" [disabled]="ConfirmedFlag" [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.City">
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input placeholder="State" class="form-control" [disabled]="ConfirmedFlag" [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.State">
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <div class="form-group">
                      <input placeholder="Region" class="form-control" [disabled]="ConfirmedFlag" [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.Region">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row mb-3" [formGroup]="empFormData">

          <div class="col-sm-2 text-left">
            <label>Handover To:<span style="color: red;" > *</span></label>
          </div>

          <div class="col-sm-1 text-right spaces">
            <label>Emp No</label>
          </div>

          <div class="col-sm-2">
            <input (click)="selectEmployee(2)" [(ngModel)]="NewFieldsObject.SubEmployeeNo"
                   [disabled]="ConfirmedFlag"
                   [ngClass]="{ 'is-invalid': isSubmitted && empFormData?.controls?.empId.errors }"
                   class="form-control" formControlName="empId" readonly>
            <small class="text-danger" *ngIf="isSubmitted && empFormData?.controls?.empId.errors" style="color: red;">Please select Employee.</small>
          </div>

          <div class="col-sm-1 text-right spaces">
            <label>Emp Name</label>
          </div>

          <div class="col-sm-6">
            <input formControlName="empName" class="form-control"
                   [ngClass]="{ 'is-invalid': isSubmitted && empFormData?.controls?.empName.errors }"
                   (click)="selectEmployee(2)" disabled
                   [(ngModel)]="NewFieldsObject.SubEmployeeName">
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>Travel Date</label>
            <input class="form-control" [disabled]="ConfirmedFlag" type="date" [(ngModel)]="NewFieldsObject.TravelDate">
          </div>

          <div class="col-sm-4 spaces">
            <label>Carrier</label>
            <input class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.Carrier">
          </div>

          <div class="col-sm-4 spaces">
            <label>Telephone No</label>
              <input class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.TelephoneNumber">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Reason</label>
            <textarea class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.Reason">  </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Leave Request Section #2</h4>

        <div *ngFor="let row of BodyRows" class="row mb-1">
          <div class="col-sm-2 spaces">
          <input [disabled]="ConfirmedFlag" [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['LeaveType'] }" (click)="changeLeaveTypeButton(LeaveTypeModal, row)" placeholder="Select Leave Type" class="form-control" type="text" [value]="DataObject.Body[row.toString()]?.LeaveType?.description? DataObject.Body[row.toString()]?.LeaveType?.description : ''"  required="required"/>
          <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['LeaveType']" style="color: red;">Please Select Leave Type</small>
        </div>
        <div class="col-sm-3 spaces">
          <input [disabled]="ConfirmedFlag" [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['StartDate'] }" [(ngModel)]="DataObject.Body[row.toString()]['StartDate']" (ngModelChange)="checkDateValidations($event)" class="form-control" type="date" placeholder="Start Date">
          <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['StartDate']" style="color: red;">Please Select Start Date</small>
        </div>
        <div class="col-sm-2 spaces">
          <input (change)="evaluateLeaveBalance($event,row)"
                 [(ngModel)]="DataObject.Body[row.toString()]['NoOfDays']"
                 [disabled]="ConfirmedFlag"
                 [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['NoOfDays'] }"
                 class="form-control" min="0" placeholder="Number of Days" type="number">
          <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['NoOfDays']" style="color: red;">Please Enter Number of days</small>
        </div>
        <div class="col-sm-3 spaces">
          <input [value]="fetchEndDate(row)" class="form-control" type="date" placeholder="End Date" disabled>
        </div>
        <div class="col-sm-1 ">
          <button class="btn btn-outline-danger" (click)="deleteBodyRow(row)" [disabled]="BodyRows.length == 1"> Delete </button>
        </div>
       </div>
        <div class="row">
          <div class="col-sm-1">
            <button class="btn btn-outline-success mr-1" (click)="addBodyRow()"
                    [disabled]="canAddLeaveSectionRowFlag || ConfirmedFlag"> Add
            </button>
          </div>

          <div class="col-sm-2">
            <h4><span class="badge badge-primary p-1 bold text-center" [hidden]="levBalDays==null"> Remaining Leaves: <b> {{levBalDays}} </b> </span>
            </h4>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>


<!-- Section #3 -->
  <div class="row file">
    <input [disabled]="ConfirmedFlag" [class.file-input-disabled]="ConfirmedFlag"
           class="form-control file-input" (change)="filesUploaded($event)" type="file" multiple>

    <table class="table">
      <tr>
        <th>File Name</th>
        <th>Description</th>
        <th>Created By</th>
        <th>Created On</th>
      </tr>

      <!--   Creation form file list -->
    <tr *ngFor="let file of FilesList; let i=index">
      <td> {{ file.name}} </td>
      <td> <input [disabled]="ConfirmedFlag" class="from-control" [(ngModel)]="FilesList[i].Description" type="text"> </td>
      <td> {{ Session.UserName }} </td>
      <td> {{ formatDate(file.lastModifiedDate) }}
        <button (click)="deleteFile(file.name, file.lastModified)"
                class="btn btn-outline-danger ml-5"> Delete
        </button>
      </td>
    </tr>

        <!--   Details page  file list -->

      <tr *ngFor="let file of (currentLeaveRequest?.files || []); let i=index">
        <td> {{ file.fileName}} </td>
        <td> {{file.description}} </td>
        <td>{{file.createdBy}}</td>
        <td>{{formatDate(file.createdOn)}}</td>
        <td>
          <button (click)="downloadLeaverequestFile(file.id,file.fileName)"
                  class="btn btn-outline-success ml-5"> Download
          </button>
        </td>
      </tr>

    </table>
  </div>


<div class="row" *ngIf="isSection3Visible == true" >
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Leave Request Section #3</h4>

        <div class="table-responsive">
          <h5 class="mt-2">Pending Leave Request in same Department</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Company</th>
                <th>Document Number</th>
              </tr>
            </thead>
            <tbody *ngIf="Flag_Details_Add">
              <tr *ngFor="let row of LastLeaveReqList">
                <td>{{row.employeeName}}</td>
                <td>{{row.company}}</td>
                <td>{{row.voucherCode}} {{row.docNo}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive">
          <h5 class="mt-2">Employees On Leave</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Profession</th>
                <th>Leave Balance Days</th>
              </tr>
            </thead>
            <tbody *ngIf="Flag_Details_Add">
              <tr *ngFor="let row of OnLeaveList">
                <td>{{row.name}}</td>
                <td>{{row.profession}}</td>
                <td>{{row.levBalDays}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive">
          <h5 class="mt-2">Last Leave Taken</h5>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Number of Days</th>
              </tr>
            </thead>
            <tbody *ngIf="Flag_Details_Add">
              <tr *ngFor="let row of LastLeaveTakenList">
                <td>{{row.fromDate}}</td>
                <td>{{row.toDate}}</td>
                <td>{{row.numberOfDays}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

</div>

<ng-template #LeaveTypeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Leave Type</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ng-container >
                    <div class="row">
                      <div *ngFor="let leaveType of leavetypes;let i = index" class="col-sm-3">
                        <button
                            class="btn btn-primary m-2"
                            [disabled]="disableLeaveTypeButton(leaveType.id,i)"
                            (click)="selectLeaveTypeOption(leaveType, modal )">
                          {{leaveType.description}}
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="show && !isReviewMode">
  <div class='stepper__header'>
    <div *ngFor='let step of stepsData; let index = index;' (click)='setStep(index)'>
      <div [ngClass]="{'stepper__header_index': true, 'stepper__header_index--active': index === currentStep}">{{index +
        1}}
      </div>
      <p class='stepper__header_label'>{{step.name}}</p>
    </div>
  </div>
  <div class='stepper__body'>
    <div *ngIf='currentStep === 0'>
      <!-- Step 1 content -->
      <div class='stepper__body_content'>
        <h4 class='font-weight-bold'>{{stepsData[currentStep].name}}</h4>
        <div *ngFor="let row of BodyRows" class="row mb-1">
          <div class="col-sm-2 spaces">
            <input [disabled]="ConfirmedFlag"
              [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['LeaveType'] }"
              (click)="changeLeaveTypeButton(LeaveTypeModal, row)" placeholder="Select Leave Type" class="form-control"
              type="text"
              [value]="DataObject.Body[row.toString()]?.LeaveType?.description? DataObject.Body[row.toString()]?.LeaveType?.description : ''"
              required="required" />
            <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['LeaveType']"
              style="color: red;">Please Select Leave Type</small>
          </div>
          <div class="col-sm-3 spaces">
            <input [disabled]="ConfirmedFlag"
              [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['StartDate'] }"
              [(ngModel)]="DataObject.Body[row.toString()]['StartDate']" (ngModelChange)="checkDateValidations($event)"
              class="form-control" type="date" placeholder="Start Date">
            <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['StartDate']"
              style="color: red;">Please Select Start Date</small>
          </div>
          <div class="col-sm-2 spaces">
            <input [disabled]="ConfirmedFlag"
                   [ngClass]="{ 'is-invalid': !isCorrectLeave && !DataObject.Body[row.toString()]['NoOfDays'] }"
                   [(ngModel)]="DataObject.Body[row.toString()]['NoOfDays']" min="0"
                   (change)="evaluateLeaveBalance($event,row)" class="form-control" type="number"
                   placeholder="Number of Days">
            <small class="text-danger" *ngIf="!isCorrectLeave && !DataObject.Body[row.toString()]['NoOfDays']"
              style="color: red;">Please Enter Number of days</small>
          </div>
          <div class="col-sm-3 spaces">
            <input [value]="fetchEndDate(row)" class="form-control" type="date" placeholder="End Date" disabled>
          </div>
          <div class="col-sm-1 ">
            <button class="btn btn-outline-danger" (click)="deleteBodyRow(row)" [disabled]="BodyRows.length == 1">
              Delete </button>
          </div>
          <div class='col-sm-1'>
            <button class="btn btn-outline-success mr-1" (click)="addBodyRow()"
              [disabled]="canAddLeaveSectionRowFlag || ConfirmedFlag"> Add </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4><span class="badge badge-primary p-1 bold text-center" [hidden]="levBalDays==null"> Remaining
                Leaves: <b> {{levBalDays}} </b> </span></h4>
          </div>
        </div>
      </div>
      <div class='stepper__body_actions'>
        <button class='btn btn-primary' (click)="nextStep()" [disabled]='canGoNextStep()'>Next</button>
      </div>
    </div>
    <div *ngIf='currentStep === 1'>
      <div class='stepper__body_content'>
        <h4 class='font-weight-bold'>{{stepsData[currentStep].name}}</h4>
        <div class="row mb-3" [formGroup]="empFormData">

          <div class="col-sm-2 text-left">
            <label>Handover To:<span style="color: red;"> *</span></label>
          </div>

          <div class="col-sm-1 text-right spaces">
            <label>Emp No</label>
          </div>

          <div class="col-sm-2">
            <input formControlName="empId" class="form-control"
                   [ngClass]="{ 'is-invalid': isSubmitted && empFormData?.controls?.empId.errors }"
                   (click)="selectEmployee(2)" [disabled]="ConfirmedFlag" readonly
                   [(ngModel)]="NewFieldsObject.SubEmployeeNo">
            <small class="text-danger" *ngIf="isSubmitted && empFormData?.controls?.empId.errors"
              style="color: red;">Please select Employee.</small>
          </div>

          <div class="col-sm-1 text-right spaces">
            <label>Emp Name</label>
          </div>

          <div class="col-sm-6">
            <input formControlName="empName" class="form-control"
              [ngClass]="{ 'is-invalid': isSubmitted && empFormData?.controls?.empName.errors }"
              (click)="selectEmployee(2)" disabled [(ngModel)]="NewFieldsObject.SubEmployeeName">
          </div>

        </div>

        <div class="row mb-3">
          <div class="col-sm-4">
            <label>Travel Date</label>
            <input class="form-control" [disabled]="ConfirmedFlag" type="date" [(ngModel)]="NewFieldsObject.TravelDate">
          </div>

          <div class="col-sm-4 spaces">
            <label>Carrier</label>
            <input class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.Carrier">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Reason</label>
            <textarea class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.Reason">  </textarea>
          </div>
        </div>
      </div>
      <div class='stepper__body_actions'>
        <button class='btn btn-outline-primary mr-3' (click)="previousStep()">Back</button>
        <button class='btn btn-primary' (click)="nextStep()" [disabled]="canGoNextStep()">Next</button>
      </div>
    </div>
    <div *ngIf='currentStep === 2'>
      <div class='stepper__body_content'>
        <h4 class='font-weight-bold'>{{stepsData[currentStep].name}}</h4>
        <form [formGroup]="leaveRequestHeaderForm" (ngSubmit)="onSubmit()">
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="form-group spaces">
                <label>Present Address</label>
                <input placeholder="House Number" class="form-control" [disabled]="ConfirmedFlag"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.HouseNumber">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input placeholder="Street" class="form-control" [disabled]="ConfirmedFlag"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.Street">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input placeholder="City" class="form-control" [disabled]="ConfirmedFlag"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.City">
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <input placeholder="State" class="form-control" [disabled]="ConfirmedFlag"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.State">
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="form-group">
                    <input placeholder="Region" class="form-control" [disabled]="ConfirmedFlag"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="NewFieldsObject.Region">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group decrease">
                <label for="">Grade</label>
                <input type="text" class="form-control" placeholder="Grade" id="grade" disabled formControlName="grade">
                <small class="text-danger" *ngIf="grade.hasError('required') && !grade.pristine">
                  This field is required.
                </small>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-sm-4 spaces">
            <label>Telephone No</label>
            <input class="form-control" [disabled]="ConfirmedFlag" [(ngModel)]="NewFieldsObject.TelephoneNumber">
          </div>
        </div>
      </div>
      <div class='stepper__body_actions'>
        <button class='btn btn-outline-primary mr-3' (click)="previousStep()">Back</button>
        <button class='btn btn-primary' (click)="nextStep()">Next</button>
      </div>
    </div>
    <div *ngIf='currentStep === 3'>
      <div class='stepper__body_content'>
        <h4 class='font-weight-bold'>{{stepsData[currentStep].name}}</h4>
        <div class="row file">
          <input [disabled]="ConfirmedFlag" [class.file-input-disabled]="ConfirmedFlag" class="form-control file-input"
            (change)="filesUploaded($event)" type="file" multiple>

          <table class="table">
            <tr>
              <th>File Name</th>
              <th>Description</th>
              <th>Created By</th>
              <th>Created On</th>
            </tr>

            <!--   Creation form file list -->
            <tr *ngFor="let file of FilesList; let i=index">
              <td> {{ file.name}} </td>
              <td> <input [disabled]="ConfirmedFlag" class="from-control" [(ngModel)]="FilesList[i].Description"
                  type="text"> </td>
              <td> {{ Session.UserName }} </td>
              <td> {{ formatDate(file.lastModifiedDate) }}
                <button class="btn btn-outline-danger ml-5" (click)="deleteFile(file.name, file.lastModified)"> Delete
                </button>
              </td>
            </tr>

            <!--   Details page  file list -->

            <tr *ngFor="let file of (currentLeaveRequest?.files || []); let i=index">
              <td> {{ file.fileName}} </td>
              <td> {{file.description}} </td>
              <td>{{file.createdBy}}</td>
              <td>{{formatDate(file.createdOn)}}</td>
              <td>
                <button class="btn btn-outline-success ml-5" (click)="downloadLeaverequestFile(file.id,file.fileName)">
                  Download </button>
              </td>
            </tr>

          </table>
        </div>
      </div>
      <div class='stepper__body_actions'>
        <button class='btn btn-outline-primary mr-3' (click)="previousStep()">Back</button>
        <button (click)="saveTransaction(true)" *ngIf='isSubmitEnabled()' class='btn btn-primary'>
          Submit
        </button>
      </div>
    </div>
  </div>
</div>



<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
        <div class="modal-header text-center">
          <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body container table-responsive">
          <div>
            <table class=" w-25 text-center  " id="GLTable" datatable [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" class="compact">
              <thead class="darkHeaders text-center ">
                <tr>
                  <th> Emmployee Number </th>
                  <th> Name </th>
                </tr>
              </thead>
              <tbody class=" text-center">
              <tr (click)="popUpTableValueSelected(row)" *ngFor="let row of PopUpTableData"
                  [class]="row? row.Selected==true?'reedd':'':''">
                <td> {{ row.EmpNo }} </td>
                <td> {{ row.Name }} </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
