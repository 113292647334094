import {Component, OnInit} from '@angular/core';
import {DOC_REQ_ALL} from '../../app.constants';
import {IHrDocumentRequest} from '../../shared/model/ihr-document-request';
import {HrDocumentRequestService} from '../../shared/service/hr-document-request.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/shared/service/auth.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-hrdocapproval',
  templateUrl: './hrdocapproval.component.html',
  styleUrls: ['./hrdocapproval.component.css']
})
export class HrdocapprovalComponent implements OnInit {

  currentSelectedPage = 0;
  totalPages = 0;
  pageIndexes: Array<number> = [];
  approvalStatus: any = DOC_REQ_ALL;
  rows = [];
  approvalStatusOption: any;
  hrDocumentRequestsApprovals: IHrDocumentRequest[] = [];
  status: string;
  loginCompany: string;
  selected = [];
  isApproveDisabled = false;
  isRejectDisabled = false;
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;

  columns = [{prop: 'docNo', name: 'Document Number'}, {prop: 'documentType', name: 'Document Type'}, {
    prop: 'status',
    name: 'Document Status'
  }, {prop: 'empNo', name: 'Employee'}, {prop: 'docDate', name: 'Document Date'}, {name: 'Actions'}];

  constructor(
      private hrDocumentRequestService: HrDocumentRequestService,
      private router: Router,
      private spinner: NgxSpinnerService,
      private authService: AuthService,
      private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.status = 'Pending';
    this.loginCompany = this.authService.getCurrentUserInformations()?.compCode;
    this.loadDocumentRequests(0, this.pageSize);
  }

  showDetails(id) {
    this.router.navigate(['/hrdocuments/documentrequest'], {queryParams: {id: id}});
  }

  filterHrDocRequests(event) {
    this.spinner.show();
    this.approvalStatusOption = event.target.value;
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatusOption, this.loginCompany, 0, this.pageSize).subscribe(data => {

      this.refreshStatus();
      this.rows = data.content;
      this.hrDocumentRequestsApprovals = data.content;
      this.totalPages = data.totalPages;
      this.pageIndexes = Array(this.totalPages).fill(0).map((x, i) => i);
      this.currentSelectedPage = data.number;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
    /*this.leaveRequestService.getPageByApprovalStatus(event.target.value).subscribe(data => {
      console.log(data);
      this.leaveRequests = data;
      this.rows = data;
    });*/
  }

  searchPaginated(event: any) {
    this.spinner.show();
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatusOption, this.loginCompany, event.pageIndex, event.pageSize).subscribe((data: any) => {
      this.rows = data.content;
      this.hrDocumentRequestsApprovals = data.content;
      this.totalPages = data.totalPages;
      this.pageIndexes = Array(this.totalPages).fill(0).map((x, i) => i);
      this.currentSelectedPage = data.number;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  private refreshStatus() {
    if (this.approvalStatusOption === '6') {
      this.status = 'All';
    } else if (this.approvalStatusOption === '5') {
      this.status = 'Pending';
    } else if (this.approvalStatusOption === '4') {
      this.status = 'Passed';
    } else if (this.approvalStatusOption === '3') {
      this.status = 'Manager Approved';
    } else if (this.approvalStatusOption === '2') {
      this.status = 'Rejected';
    } else if (this.approvalStatusOption === '1') {
      this.status = 'Approved';
    }
  }

  approveSelected() {
    this.isApproveDisabled = true;
    let approvalIds = Array();
    this.selected.filter(data => {
      approvalIds.push(data.id);
    });
    this.hrDocumentRequestService.approve(approvalIds).subscribe(data => {
      this.loadDocumentRequests(0, this.pageSize);
      this.isApproveDisabled = false;
      this.toastr.success('Document Request Approved');
    }, err => {
      this.isApproveDisabled = false;
      this.toastr.error('Error Occurred');
    });
  }

  approve(id: number) {
    this.isApproveDisabled = true;
    let approvalIds = Array();
    if (id != null) {
      approvalIds.push(id);
    }

    this.hrDocumentRequestService.approve(approvalIds).subscribe(data => {
      this.loadDocumentRequests(0, this.pageSize);
      this.isApproveDisabled = false;
      this.toastr.success('Document Request Approved');
    }, err => {
      this.isApproveDisabled = false;
      this.toastr.error('Error Occurred');
    });
  }

  managerApprove(id: number) {
    this.hrDocumentRequestService.managerApprove(id).subscribe(data => {
      this.toastr.success('Document request has been approved');
      window.location.reload();
    }, err => {
      this.isApproveDisabled = false;
      this.toastr.error('Error Occurred');
    });
  }

  rejectSelected() {
    this.isRejectDisabled = true;
    let rejectedIds = Array();
    this.selected.forEach(data => {
      rejectedIds.push(data.id);
    });
    this.hrDocumentRequestService.reject(rejectedIds).subscribe(data => {
      this.loadDocumentRequests(0, this.pageSize);
      this.isRejectDisabled = false;
      this.toastr.success('Document Request Rejected');
    }, err => {
      this.isRejectDisabled = false;
      this.toastr.error('Error Occurred');
    });
  }

  reject(id: number) {
    let rejectedIds = Array();
    rejectedIds.push(id);
    this.isRejectDisabled = true;
    this.hrDocumentRequestService.reject(rejectedIds).subscribe(data => {
      this.loadDocumentRequests(0, this.pageSize);
      this.toastr.success('Document Request Rejected');
      this.isRejectDisabled = false;
    }, err => {
      this.isRejectDisabled = false;
      this.toastr.error('Error Occurred');
    });
  }


  getPaginationWithIndex(index: number) {
    this.loadDocumentRequests(index, this.pageSize);
  }

  active(index: number) {
    console.log('current ' + this.currentSelectedPage + ' ****  index' + index);
    if (this.currentSelectedPage === index) {
      return {
        active: true
      };
    }
  }

  nextClick() {
    if (this.currentSelectedPage < this.totalPages - 1) {
      this.loadDocumentRequests(++this.currentSelectedPage, this.pageSize);
    }
  }

  previousClick() {
    if (this.currentSelectedPage > 0) {
      this.loadDocumentRequests(--this.currentSelectedPage, this.pageSize);
    }
  }

  onDocumentRequestStatusChange(approvalStatus) {
    this.approvalStatus = approvalStatus;
    this.loadDocumentRequests(0, this.pageSize);
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  getStatus(documentRequest: any) {
    if (documentRequest.rejected === true) {
      return 'Rejected';
    } else if (documentRequest.approved === true) {
      return 'Approved';
    } else if (documentRequest.managerApproved === true) {
      return 'Manager Approved';
    } else if (documentRequest.passed === 'Y') {
      return 'Passed';
    }
    return 'Pending';
  }

  onActivate(event) {
  }

  private loadDocumentRequests(index, pageSize) {
    this.spinner.show();
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatus, this.loginCompany, index, pageSize).subscribe(data => {
      this.refreshStatus();
      this.rows = data.content;
      this.hrDocumentRequestsApprovals = data.content;
      this.totalPages = data.totalPages;
      this.pageIndexes = Array(this.totalPages).fill(0).map((x, i) => i);
      this.currentSelectedPage = data.number;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

}
