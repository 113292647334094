import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {RecruitmentRequestDto} from '../../../shared/dto/recruitment/request/recruitmentRequestDto';
import {RecruitmentRequestService} from '../../../shared/service/recruitment-request-service';
import {CompanyService} from '../../../shared/service/company.service';
import {MastercategoryService} from '../../../shared/service/mastercategory.service';
import {EmployeesService} from '../../../shared/service/employees.service';
import {IcategoryGrades} from '../../../shared/model/icategory-grades';
import {LocalstorageService} from '../../../shared/service/localstorage.service';
import {AuthService} from '../../../shared/service/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {EmployeeListComponent} from '../../../employee/employee-list/employee-list.component';
import {RequestSearchDto} from '../../../shared/dto/recruitment/request/requestSearchDto';
import Swal from 'sweetalert2';

export interface PopupTable {
  Code: string;
  EmpNo: string;
  Name: string;
  Selected: boolean;
  RowObject?: any;
}

@Component({
  selector: 'app-candidate-details',
  templateUrl: './recruitment-request-details.component.html',
  styleUrls: ['./recruitment-request-details.component.css']
})
export class RecruitmentRequestDetailsComponent implements OnInit {

  requestFormGroup: FormGroup;
  recruitmentRequestDto: RecruitmentRequestDto;
  idParam: number;
  companies = [];
  departments: [];
  positions: [];
  grades: IcategoryGrades[];
  isReplacement = false;
  popUpTableData: PopupTable[] = [];
  replacementEmployee: any;
  selectedCompanyCode: any;
  selectedDepartmentCode: any;
  selectedPositionCode: any;
  selectedGradeCode: any;
  requestedEmployee = this.authService.getCurrentUserInformations()?.employee;
  currentUserAllowedToConfirmOrReject: boolean;
  confirmedBy: string[];
  status: string;
  docNo: string;
  jobDescFile: File;
  jobDescBlob: Blob;


  @ViewChild('company') company: ElementRef;
  @ViewChild('department') department: ElementRef;

  constructor(private recruitmentRequestService: RecruitmentRequestService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute,
              private companyService: CompanyService, private mastercategoryService: MastercategoryService,
              private employeesService: EmployeesService, private authService: AuthService, private localstorage: LocalstorageService,
              private dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.spinner.show();
    this.instantiateForm();
  }

  instantiateForm() {
    this.requestFormGroup = this.formBuilder.group({
      referenceNumber: [''],
      vacancyJobTitle: [''],
      requiredByDate: [''],
      additionalNosRequired: [''],
      salaryGrossPackage: [''],
      presentStrength: [''],
      workLocation: [''],
      recruitmentType: [''],
      reasonForReplacement: [''],
      languagesAndNationalities: [''],
      skill1: [''],
      skill2: [''],
      skill3: [''],
      skill4: ['']
    });

    if (this.activatedRoute.snapshot.queryParams['id']) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.idParam = params['id'];
        if (this.idParam) {
          this.loadCompanies(false);
          this.loadPositions(false);
          this.searchCandidatesById(this.idParam);
        }
      });
    } else {
      this.recruitmentRequestDto = new RecruitmentRequestDto();
      this.loadCompanies(true);
      this.loadPositions(true);
    }
  }

  searchCandidatesById(referenceNumber) {
    const requestSearchDto = new RequestSearchDto();
    requestSearchDto.referenceNumber = referenceNumber;
    this.recruitmentRequestService.search(requestSearchDto, 0, 10).subscribe(data => {
      if (data.numberOfElements === 1) {
        this.recruitmentRequestDto = data.content[0];
        this.currentUserAllowedToConfirmOrReject = this.recruitmentRequestDto.currentUserAllowedToConfirmOrReject;
        this.confirmedBy = this.recruitmentRequestDto.confirmedBy;
        this.status = this.recruitmentRequestDto.status;
        this.requestFormGroup = this.formBuilder.group({
          referenceNumber: [this.recruitmentRequestDto?.referenceNumber],
          vacancyJobTitle: [this.recruitmentRequestDto?.vacancyJobTitle],
          requiredByDate: [this.recruitmentRequestDto?.requiredByDate],
          additionalNosRequired: [this.recruitmentRequestDto?.additionalNosRequired],
          salaryGrossPackage: [this.recruitmentRequestDto?.salaryGrossPackage],
          presentStrength: [this.recruitmentRequestDto?.presentStrength],
          workLocation: [this.recruitmentRequestDto?.workLocation],
          recruitmentType: [this.recruitmentRequestDto?.recruitmentType],
          reasonForReplacement: [this.recruitmentRequestDto?.reasonForReplacement],
          languagesAndNationalities: [this.recruitmentRequestDto?.languagesAndNationalities],
          skill1: [this.recruitmentRequestDto?.skill1],
          skill2: [this.recruitmentRequestDto?.skill2],
          skill3: [this.recruitmentRequestDto?.skill3],
          skill4: [this.recruitmentRequestDto?.skill4]
        });
        this.docNo = this.recruitmentRequestDto?.referenceNumber.toString();
        this.filterByCompanyCode(this.recruitmentRequestDto.compCode);
        this.selectedCompanyCode = this.recruitmentRequestDto.compCode;
        this.selectedDepartmentCode = this.recruitmentRequestDto.dptCode;
        this.selectedPositionCode = this.recruitmentRequestDto.position;
        this.selectedGradeCode = this.recruitmentRequestDto.gradeCode;
        if (this.recruitmentRequestDto.recruitmentType === 'Replacement') {
          const employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
          this.replacementEmployee = employeesData.filter(employee => employee.id === this.recruitmentRequestDto.repEmpId &&
              employee.pmlId === this.recruitmentRequestDto.repEmpPmlId)[0];
          this.isReplacement = true;
        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this._toastr.error('Unable to search the recruitment request [' + referenceNumber + ']');
        this.router.navigate(['/recruitment/request/list']);
      }
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
    this.spinner.show();
    this.recruitmentRequestService.downloadJobDescFile(referenceNumber).subscribe(jobDescFile => {
      this.jobDescBlob = new Blob([jobDescFile]);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }


  save() {
    this.recruitmentRequestDto = this.requestFormGroup.value;
    this.setAdditionalInfo();
    this.validateForm();
    this.spinner.show();
    this.recruitmentRequestService.save(this.recruitmentRequestDto, this.jobDescFile).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The recruitment request [' + data.referenceNumber + '] was successfully saved !');
      this.router.navigate(['/recruitment/request/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  generateDocNumber(compCode) {
    this.spinner.show();
    this.recruitmentRequestService.generateDocNumber(compCode).subscribe(data => {
      this.spinner.hide();
      this.docNo = data;
      this.recruitmentRequestDto.referenceNumber = data;
    }, error => {
      this.docNo = null;
      this.recruitmentRequestDto.referenceNumber = null;
      this.spinner.hide();
      this._toastr.error('Voucher doesn\'t exist in company ' + compCode + '.\n' + 'Please contact Administrator');
    });
  }

  update() {
    this.recruitmentRequestDto = this.requestFormGroup.value;
    this.setAdditionalInfo();
    this.validateForm();
    this.spinner.show();
    this.recruitmentRequestService.update(this.recruitmentRequestDto, this.jobDescFile).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The recruitment request [' + data.referenceNumber + '] was successfully updated !');
      this.router.navigate(['/recruitment/request/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  validateForm() {
    if (this.recruitmentRequestDto.compCode === '') {
      this.spinner.hide();
      this._toastr.error('The Company is Mandatory !');
      throw new Error(('The Company is Mandatory !'));
    } else if (this.recruitmentRequestDto.dptCode === '') {
      this.spinner.hide();
      this._toastr.error('The Department is Mandatory !');
      throw new Error(('The Department is Mandatory !'));
    } else if (this.recruitmentRequestDto.vacancyJobTitle === '') {
      this.spinner.hide();
      this._toastr.error('The Vacancy Job Title is Mandatory !');
      throw new Error(('The Vacancy Job Title is Mandatory !'));
    } else if (this.recruitmentRequestDto.requiredByDate.toString() === '') {
      this.spinner.hide();
      this._toastr.error('The Required By Date is Mandatory !');
      throw new Error(('The Required By Date is Mandatory !'));
    } else if (this.recruitmentRequestDto.additionalNosRequired === '') {
      this.spinner.hide();
      this._toastr.error('The Additional Nos Required is Mandatory !');
      throw new Error(('The Additional Nos Required is Mandatory !'));
    } else if (this.recruitmentRequestDto.presentStrength === '') {
      this.spinner.hide();
      this._toastr.error('The Present Strength is Mandatory !');
      throw new Error(('The Present Strength is Mandatory !'));
    } else if (this.recruitmentRequestDto.salaryGrossPackage === '') {
      this.spinner.hide();
      this._toastr.error('The Salary Gross Package is Mandatory !');
      throw new Error(('The Salary Gross Package is Mandatory !'));
    } else if (this.recruitmentRequestDto.position === null) {
      this.spinner.hide();
      this._toastr.error('The Position is Mandatory !');
      throw new Error(('The Position is Mandatory !'));
    } else if (this.recruitmentRequestDto.gradeCode === '') {
      this.spinner.hide();
      this._toastr.error('The Grade is Mandatory !');
      throw new Error(('The Grade is Mandatory !'));
    } else if (this.recruitmentRequestDto.workLocation === '') {
      this.spinner.hide();
      this._toastr.error('The Work Location is Mandatory !');
      throw new Error(('The Work Location is Mandatory !'));
    } else if (this.recruitmentRequestDto.recruitmentType === '') {
      this.spinner.hide();
      this._toastr.error('The Recruitment Type is Mandatory !');
      throw new Error(('The Recruitment Type is Mandatory !'));
    } else if (this.recruitmentRequestDto.languagesAndNationalities === '') {
      this.spinner.hide();
      this._toastr.error('The Languages And Nationalities is Mandatory !');
      throw new Error(('The Languages And Nationalities is Mandatory !'));
    }
    if (this.isReplacement) {
      if (this.recruitmentRequestDto.reasonForReplacement === '') {
        this.spinner.hide();
        this._toastr.error('The Reason For Replacement is Mandatory !');
        throw new Error(('The Reason For Replacement is Mandatory !'));
      } else if (this.recruitmentRequestDto.repEmpId === null) {
        this.spinner.hide();
        this._toastr.error('The Replacement Employee is Mandatory !');
        throw new Error(('The Replacement Employee is Mandatory !'));
      }
    }
  }

  reset() {
    if (confirm('Are you sure you want to reset the form ?')) {
      this.requestFormGroup = this.formBuilder.group({
        referenceNumber: [''],
        vacancyJobTitle: [''],
        requiredByDate: [''],
        additionalNosRequired: [''],
        salaryGrossPackage: [''],
        presentStrength: [''],
        workLocation: [''],
        recruitmentType: [''],
        reasonForReplacement: [''],
        languagesAndNationalities: [''],
        skill1: [''],
        skill2: [''],
        skill3: [''],
        skill4: ['']
      });
      this.recruitmentRequestDto = new RecruitmentRequestDto();
      this.recruitmentRequestDto.referenceNumber = null;
      this.replacementEmployee = null;
      this.isReplacement = false;
      this.recruitmentRequestDto.recruitmentType = 'New';
      this.idParam = null;
      this.currentUserAllowedToConfirmOrReject = null;
      this.confirmedBy = null;
      this.docNo = null;
      this.jobDescFile = null;
      this.jobDescBlob = null;
      this.generateDocNumber(this.selectedCompanyCode);
    }
  }

  loadCompanies(update) {
    this.spinner.show();
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
      if (update) {
        const compCode = data[0]?.code;
        this.selectedCompanyCode = compCode;
        this.generateDocNumber(compCode);
      }
      this.loadDepartment(this.selectedCompanyCode, update);
      this.loadGrades(this.selectedCompanyCode, update);
    });
  }

  loadDepartment(company, update) {
    this.spinner.show();
    this.employeesService.getDepartmentsByCompany(company).subscribe(data => {
      this.departments = data;
      if (update) {
        this.selectedDepartmentCode = data[0]?.code;
      }
      this.spinner.hide();
    });
  }

  loadPositions(update) {
    this.spinner.show();
    this.employeesService.getPositions().subscribe(data => {
      this.positions = data;
      if (update) {
        this.selectedPositionCode = data[0]?.code;
      }
      this.spinner.hide();
    });
  }

  loadGrades(company, update) {
    this.spinner.show();
    this.mastercategoryService.getAllCategoryGradesByCompany(company).subscribe(data => {
      this.grades = data;
      if (update) {
        this.selectedGradeCode = data[0]?.code;
      }
      this.spinner.hide();
    });
  }

  filterByCompany(event) {
    const company = event.target.value;
    this.selectedCompanyCode = company;
    this.generateDocNumber(company);
    this.loadDepartment(company, true);
    this.loadGrades(company, true);
  }

  filterByCompanyCode(company) {
    this.selectedCompanyCode = company;
    this.loadDepartment(company, false);
    this.loadGrades(company, false);
  }

  changeType(event) {
    if (event.target.value === 'Replacement') {
      this.isReplacement = true;
    } else {
      this.isReplacement = false;
    }
  }

  selectEmployee() {
    const dialogRef = this.dialog.open(EmployeeListComponent);
    dialogRef.afterClosed().subscribe(employee => {
      if (employee != null) {
        this.replacementEmployee = employee;
      }
    });

  }

  setDepartment(event) {
    const department = event.target.value;
    if (department != null) {
      this.selectedDepartmentCode = department;
    } else {
      this.selectedDepartmentCode = null;
    }
  }

  setPosition(event) {
    const position = event.target.value;
    if (position != null) {
      this.selectedPositionCode = position;
    } else {
      this.selectedPositionCode = null;
    }
  }

  setGrade(event) {
    const grade = event.target.value;
    if (grade != null) {
      this.selectedGradeCode = grade;
    } else {
      this.selectedGradeCode = null;
    }
  }

  private setAdditionalInfo() {
    this.recruitmentRequestDto.referenceNumber = parseInt(this.docNo, 10);
    this.recruitmentRequestDto.compCode = this.selectedCompanyCode;
    this.recruitmentRequestDto.dptCode = this.selectedDepartmentCode;
    this.recruitmentRequestDto.dptCompCode = this.selectedCompanyCode;
    this.recruitmentRequestDto.position = this.selectedPositionCode;
    this.recruitmentRequestDto.gradeCode = this.selectedGradeCode;
    this.recruitmentRequestDto.gradeCompCode = this.selectedCompanyCode;
    this.recruitmentRequestDto.reqEmpId = this.requestedEmployee?.id;
    this.recruitmentRequestDto.reqEmpPmlId = this.requestedEmployee?.pmlId;
    if (this.isReplacement) {
      this.recruitmentRequestDto.repEmpId = this.replacementEmployee?.id;
      this.recruitmentRequestDto.repEmpPmlId = this.replacementEmployee?.pmlId;
      this.recruitmentRequestDto.recruitmentType = 'Replacement';
    } else {
      this.recruitmentRequestDto.repEmpId = null;
      this.recruitmentRequestDto.repEmpPmlId = null;
      this.recruitmentRequestDto.recruitmentType = 'New';
      this.recruitmentRequestDto.reasonForReplacement = null;
    }
  }

  isOwnerOfDocument(): boolean {
    const currentUserId = this.authService.getCurrentUserInformations().employee.id;
    if (!this.idParam) {
      return true;
    }
    return currentUserId === this.recruitmentRequestDto?.reqEmpId;
  }

  canConfirm() {
    return this.currentUserAllowedToConfirmOrReject && this.idParam &&
        (this.status === 'PASSED' || this.status === 'CONFIRMING');
  }


  confirm() {
    this.recruitmentRequestDto = this.requestFormGroup.value;
    const requestNumber = this.recruitmentRequestDto.referenceNumber;
    this.spinner.show();
    this.recruitmentRequestService.confirm(requestNumber).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The recruitment request [' + requestNumber + '] was successfully confirmed !');
      this.router.navigate(['/recruitment/request/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  canReject() {
    return this.currentUserAllowedToConfirmOrReject && this.idParam &&
        (this.status === 'PASSED' || this.status === 'CONFIRMING');
  }

  reject() {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {
        const remark = result.value;
        this.recruitmentRequestDto = this.requestFormGroup.value;
        this.recruitmentRequestDto.rejectRemark = remark;
        this.spinner.show();
        this.recruitmentRequestService.reject(this.recruitmentRequestDto).subscribe(data => {
          this.spinner.hide();
          this._toastr.success('The recruitment request [' + this.recruitmentRequestDto.referenceNumber + '] was successfully rejected !');
          this.router.navigate(['/recruitment/request/list']);
        }, error => {
          this._toastr.error(error);
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }

    });
  }

  canUpdate() {
    return this.docNo && this.idParam && (this.status === 'PENDING' || this.status === 'REJECTED') && this.isOwnerOfDocument();
  }

  canSave() {
    return this.docNo && !this.idParam && this.status == null;
  }

  canReset() {
    return !this.idParam && this.status == null;
  }

  pass() {
    this.recruitmentRequestDto = this.requestFormGroup.value;
    const requestNumber = this.recruitmentRequestDto.referenceNumber;
    this.spinner.show();
    this.recruitmentRequestService.pass(requestNumber).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('The recruitment request [' + requestNumber + '] was successfully passed !');
      this.router.navigate(['/recruitment/request/list']);
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  canPass() {
    return this.docNo && (this.status === 'PENDING' || this.status === 'REJECTED') && this.isOwnerOfDocument();
  }

  jobDescFileUpload(e) {
    this.jobDescFile = e?.target?.files[0];
  }

  downloadFile(blob, jobDescFileName) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = jobDescFileName;
    link.click();
  }

}
