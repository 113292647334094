<div class="row">
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Toastr Success</h4>
                <h6 class="card-subtitle">This is the simpel toastr with success message</h6>
                <button class="btn btn-success" (click)="showSuccess()">Toastr Success</button>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Toastr Danger</h4>
                <h6 class="card-subtitle">This is the simpel toastr with Danger message</h6>
                <button class="btn btn-danger" (click)="showError()">Toastr Danger Error</button>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Toastr Warning</h4>
                <h6 class="card-subtitle">This is the simpel toastr with Warning message</h6>
                <button class="btn btn-warning" (click)="showWarning()">Toastr Warning</button>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Toastr Info</h4>
                <h6 class="card-subtitle">This is the simpel toastr with Info message</h6>
                <button class="btn btn-info" (click)="showInfo()">Toastr Info</button>
            </div>
        </div>
    </div>

</div>