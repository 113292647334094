<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Bar Sales Chart</h4>
                <h6 class="card-subtitle">This is the simple example of bar chart</h6>
                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend"
                    [chartType]="barChartType" (chartHover)="chartHovered($event)" [colors]="barChartColors" (chartClick)="chartClicked($event)">
                </canvas>
            </div>
            <div class="card-footer">
                <button class="btn btn-info btn-sm" (click)="randomize()">Update</button>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Doughnut Chart</h4>
                <h6 class="card-subtitle">This is the simple example of Doughnut chart</h6>
                <canvas baseChart height="165px" [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)" [colors]="[{backgroundColor: ['#a1aab2', '#36bea6', '#2962FF']}]">
                </canvas>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Radar Chart</h4>
                <h6 class="card-subtitle">This is the simple example of Radar chart</h6>
                <canvas baseChart height="150px" [datasets]="radarChartData" [labels]="radarChartLabels" [chartType]="radarChartType" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)" [colors]="radarChartColors">
                </canvas>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pie Chart</h4>
                <h6 class="card-subtitle">This is the simple example of Pie chart</h6>
                <canvas baseChart height="150px" [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)" [colors]="[{backgroundColor: ['#a1aab2', '#36bea6', '#2962FF']}]">
                </canvas>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-lg-6">
        <div class="card">

            <div class="card-body">
                <h4 class="card-title">Polar Area Chart</h4>
                <h6 class="card-subtitle">This is the simple example of Polar Area Chart</h6>
                <canvas baseChart height="130px" [data]="polarAreaChartData" [labels]="polarAreaChartLabels" [legend]="polarAreaLegend" [chartType]="polarAreaChartType"
                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)" [colors]="[{backgroundColor: ['#a1aab2', '#36bea6', '#2962FF', '#7460ee', '#f62d51']}]">
                </canvas>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Line Chart</h4>
                <h6 class="card-subtitle">This is the simple example of Polar Line Chart</h6>
                <canvas baseChart height="130" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
                    [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                </canvas>
            </div>
        </div>
    </div>
</div>