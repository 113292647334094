<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Manager / Employees  Tree</h4>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node  *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled (click)="test()"></button>
            {{node.name}}
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.name}}
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>


    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Assign Employees to a manager</h4>

        <div class="row">

          <div class="col-sm-5">
            <div class="form-floating">
              <select (change)="selectManager($event.target.value)" class="form-select" id="floatingSelect" aria-label="Floating label select example">
                <option *ngFor="let manager of ManagersList" [value]="manager.empNo"> {{manager.name}} </option>
              </select>
              <label for="floatingSelect">Manager</label>
            </div>
          </div>

          <div class="col-sm-2">
            <button class="btn btn-primary" (click)="selectEmployee()">Assign Employees{{selectEmployee.name}}</button>
          </div>

        </div>
        
        <div class="row mt-2">
          <table class="table">
            <tr>  
              <th>No.</th>            
              <th>Name</th>
            </tr>
            <tr *ngFor="let employee of EmployeeList; let i = index">
              <td>{{i+1}} </td>
              <td> {{employee}} </td>
            </tr>
          </table>
        </div>
        
      </div>
      </div>
    </div>
  </div>



  <div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
        <div class="modal-header text-center">
          <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body container table-responsive">
          <div>
            <table class=" w-25 text-center  " id="GLTable" datatable [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" class="compact">
              <thead class="darkHeaders text-center ">
                <tr>
                  <th> Employee Number </th>
                  <th> Name </th>
                </tr>
              </thead>
              <tbody class=" text-center">
                <tr (click)="popUpTableValueSelected(row)" *ngFor="let row of PopUpTableData" [class]="row? row.Selected==true?'reedd':'':''">        
                  <td> {{ row.Code }} </td>
                  <td> {{ row.Name }} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>