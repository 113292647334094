import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../../shared/service/user.service';
import {CompanyService} from '../../shared/service/company.service';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  focus;
  focus1;
  focus2;
  glUserMastersDetailsDTO: any;
  FinancialYears = [];
  allLoginData: any;
  loginStep: number;

  test: Date = new Date();
  private toggleButton;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  returnUrl: string;

  loginForm = this.fb.group({
    username: [''],
    password: [''],
    rememberMe: [true],
    otp: ['']
  });

  yearForm = this.fb.group({
    yearset: ['']
  });

  constructor(
    private element: ElementRef,
    private loginService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private LeaveService: LeaveRequestService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,

  ) {
    this.nativeElement = element.nativeElement;
  }

  loginform = true;
  recoverform = false;

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'leaves/list';
    this.loginStep = 1;
  }

  generateOTP(chanel: string) {
    this.spinner.show();
    const data = this.loginForm.value;
    this.loginService.generateOTP(chanel, data.username.toUpperCase()).subscribe(res => {
      this.loginStep = 3;
      if (chanel === 'sms') {
        this.spinner.hide();
        this.toastr.success('A verification code has been sent to your phone number');
      } else {
        this.spinner.hide();
        this.toastr.success('A verification code has been sent to your email');
      }
    }, err => {
      this.toastr.error('Error Occurred while generating OTP');
    });
  }


  tryGenerateOTP() {
    this.spinner.show();
    if (this.loginForm.status === 'VALID') {
      const data = this.loginForm.value;
      data.username = data.username.toUpperCase();
      this.loginService.tryGenerateOTP(data)
      .toPromise()
      .then((canAuthenticate) => {
        this.spinner.hide();
        if (canAuthenticate) {
          this.loginStep = 3;
        } else {
          this.toastr.error('Bad Credentials');
        }

      })
      .catch(() => {
        this.spinner.hide();
        this.toastr.error('Error Occurred');
      });
    } else {
      this.spinner.hide();
      this.toastr.error('Enter All Details');
    }

  }

  login() {
    this.spinner.show();
    const data = this.loginForm.value;
    data.username = data.username.toUpperCase();
    this.loginService.login(data)
    .toPromise()
    .then((loginData) => {
      if (loginData) {
        if (loginData) {
          if (loginData?.id_token) {
            this.companyService.getAll().subscribe(allcompanies => {
              localStorage.setItem('Companies', JSON.stringify(allcompanies));
            });
            this.allLoginData = loginData;
            this.glUserMastersDetailsDTO = loginData?.glUserMastersDetailsDTO;
            Object.keys(this.glUserMastersDetailsDTO.fyerIds).forEach(
                fyerid => {
                  this.FinancialYears.push({'fyerid': fyerid, 'year': this.glUserMastersDetailsDTO.fyerIds[fyerid]});
                }
            );
            this.allLoginData.year = this.FinancialYears.filter(x => x.fyerid == this.yearForm?.value?.yearset)[0];
            localStorage.setItem('loginsessiondata', JSON.stringify(this.allLoginData));
            this.glUserMastersDetailsDTO.fyerIds = this.FinancialYears;
            this.spinner.hide();
            this.toastr.success('OTP verification successfull');
            this.router.navigateByUrl(this.returnUrl);
          }
        }


      } else {
        this.spinner.hide();
        this.toastr.error('Error Occurred');
      }

    })
    .catch(() => {
      this.spinner.hide();
      this.toastr.error('Error Occurred');
    });
  }

}
