<div class="row">
    <div class="col-lg-8">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Ticket</h4> Hi There, i was wondering, do you provide a service to build custom pages. I need some pages
                for a ticket system similar to the one you have. Please advise if you provide this service and i will send
                through my requirements. Regards.
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Ticket Replies</h4>
                <ul class="list-unstyled m-t-40">
                    <li class="media">
                        <img class="m-r-15" src="assets/images/users/2.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">User Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                    <hr>
                    <li class="media my-4">
                        <img class="m-r-15" src="assets/images/users/1.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">Agent Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                    <hr>
                    <li class="media">
                        <img class="m-r-15" src="assets/images/users/2.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">User Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                    <hr>
                    <li class="media">
                        <img class="m-r-15" src="assets/images/users/2.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">User Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                    <hr>
                    <li class="media my-4">
                        <img class="m-r-15" src="assets/images/users/1.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">Agent Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                    <hr>
                    <li class="media">
                        <img class="m-r-15" src="assets/images/users/2.jpg" width="60" alt="Generic placeholder image">
                        <div class="media-body">
                            <h5 class="mt-0 mb-1">User Name</h5> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante
                            sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum
                            nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="m-b-20">Write a reply</h4>
                <form method="post">
                    <quill-editor [style]="{height: '200px'}"></quill-editor>
                    <button type="button" class="m-t-20 m-r-5 btn waves-effect waves-light btn-success">Reply</button>
                    <button type="button" class="m-t-20 btn waves-effect waves-light btn-info">Reply & close</button>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Ticket Info</h4>
            </div>
            <div class="card-body bg-light">
                <div class="row text-center">
                    <div class="col-6 m-t-10 m-b-10">
                        <span class="label label-warning">In Progress</span>
                    </div>
                    <div class="col-6 m-t-10 m-b-10">
                        May 2, 2018 9:49
                    </div>
                </div>
            </div>
            <div class="card-body">
                <h5 class="p-t-20">Ticket Creator</h5>
                <span>User Name</span>
                <h5 class="m-t-30">Support Staff</h5>
                <span>Agent Name</span>
                <br/>
                <button type="button" class="m-t-20 btn waves-effect waves-light btn-success">Update</button>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <h4 class="card-title">User Info</h4>
                <div class="profile-pic m-b-20 m-t-20">
                    <img src="assets/images/users/5.jpg" width="150" class="rounded-circle" alt="user">
                    <h4 class="m-t-20 m-b-0">Daniel Kristeen</h4>
                    <a href="mailto:danielkristeen@gmail.com">danielkristeen@gmail.com</a>
                </div>
                <div class="row text-center m-t-40">
                    <div class="col-4">
                        <h3 class="font-bold">4</h3>
                        <h6>Total</h6>
                    </div>
                    <div class="col-4">
                        <h3 class="font-bold">2</h3>
                        <h6>Open</h6>
                    </div>
                    <div class="col-4">
                        <h3 class="font-bold">3</h3>
                        <h6>Closed</h6>
                    </div>
                </div>
            </div>
            <div class="p-25 border-top m-t-15">
                <div class="row text-center">
                    <div class="col-6 border-right">
                        <a href="#" class="link d-flex align-items-center justify-content-center font-medium">
                            <i class="mdi mdi-message font-20 m-r-5"></i>Message</a>
                    </div>
                    <div class="col-6">
                        <a href="#" class="link d-flex align-items-center justify-content-center font-medium">
                            <i class="mdi mdi-developer-board font-20 m-r-5"></i>Portfolio</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Ticket Statestics</h4>
                <div id="visitor" style="height:290px; width:100%;" class="m-t-20"></div>
            </div>
        </div>
    </div>
</div>