<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Period, Store and Voucher</h4>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <ng-container *ngIf="!firstFormValidity">
                      <form [formGroup]="salesPeriodForm">
                        <div class="row">
                          <div class="col-lg-2 col-md-6 col-sm-12">
                            <label>
                              Period
                            </label>
                          </div>
                          <div class="col-lg-2 col-md-6 col-sm-12">
                            <div class="form-group">
                              <select class="form-control" formControlName="period">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                              <input class="form-control" placeholder="From Date" name="dp" formControlName="fromDate" ngbDatepicker #fromDate1="ngbDatepicker" (click)="fromDate1.toggle()" id="sfromDate" />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                              <input class="form-control" placeholder="From Date" name="dp" formControlName="toDate" ngbDatepicker #fromDate2="ngbDatepicker" (click)="fromDate2.toggle()" id="fromDate2" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-2 col-md-6 col-sm-12">
                            <label>
                              Stores
                            </label>
                          </div>
                          <div class="col-lg-10 col-md-6 col-sm-12">
                            <div class="form-group">
                              <select class="form-control" formControlName="storeFirst">
                                <ng-container *ngFor="let store of storeList">
                                  <option value="{{store?.storeMasterIdentity?.code}}">{{store?.description}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-2 col-md-6 col-sm-12">
                            <label>
                              Voucher Code
                            </label>
                          </div>
                          <div class="col-lg-10 col-md-6 col-sm-12">
                            <div class="form-group">
                              <select class="form-control" formControlName="voucherFirst">
                                <option>Customer Enquiries</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-2 col-md-6 col-sm-12"></div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <input type="button" class="btn btn-primary" (click)="saveAndGoNext()" value="Save"/>
                          </div>
                        </div>
                      </form>
                    </ng-container>
                    <!--<ng-container *ngIf="firstFormValidity">-->
                    <ng-container *ngIf="firstFormValidity">
                      <form [formGroup]="secondSalesForm">
                        <div class="row">
                          <div class="col-lg-8 col-md-6 col-sm-12">
                            <div class="row">
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <label>Document Type</label>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <div class="form-group">
                                  <select (change)="changeDocType($event)" class="form-control">
                                    <ng-container *ngFor="let docType of documentTypes">
                                      <option value="{{docType.docType}}">{{docType.description}}</option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <label>Document No</label>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <div class="form-group">
                                  <!--<input type="text" value="{{docCode}}" readonly class="form-control"/>-->
                                  <input type="text" formControlName="docNumber" class="form-control"/>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <label>Doc Date</label>
                              </div>
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <div class="form-group">
                                  <input type="text" readonly value="{{currentDate}}" class="form-control"/>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-4 col-sm-12">
                                <label>Customer</label>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-12">
                                <div class="form-group">
                                  <a (click)="openCustomer(customerModel)">
                                    <input type="text" formControlName="customerData" value="{{selectedCustomer?.id}}" class="form-control"/>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-5 col-md-4 col-sm-12">
                                <div class="form-group">
                                  <input type="text" value="{{selectedCustomer?.name}}" disabled class="form-control"/>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <label>Reference</label>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <input type="text" formControlName="reference" class="form-control"/>
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <label>
                                  Currency
                                </label>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <input type="text" formControlName="currency" class="form-control"/>
                                </div>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <input type="text" disabled class="form-control"/>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <label>
                                  Enquiry Format
                                </label>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <select formControlName="enquiryformat" class="form-control">
                                    <option>Fax</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <label>Ref. Date</label>
                              </div>
                              <div class="col-lg-3 col-sm-12 col-md-6">
                                <div class="form-group">
                                  <input class="form-control" formControlName="refDate" placeholder="Reference Date" name="dp" ngbDatepicker #refDate="ngbDatepicker" (click)="refDate.toggle()" id="refDate" />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <label>Remarks</label>
                              </div>
                              <div class="col-lg-9 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <select formControlName="remarks" class="form-control">
                                    <option>Remark 01</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <label>
                                  Sales man
                                </label>
                              </div>
                              <div class="col-lg-4 col-sm-12 col-md-6">
                                <div class="form-group">
                                  <select formControlName="salesman" (change)="selectSalesMan($event)" class="form-control">
                                    <option></option>
                                    <ng-container *ngFor="let salesman of salesManList">
                                      <option value="{{salesman.id}}">{{salesman.name}}</option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                              <div class="col-lg-5 col-sm-12 col-md-6">
                                <div class="form-group">
                                  <input type="text" value="{{selectedSalesMan?.id}}" class="form-control" disabled />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-3 col-sm-12 col-md-6">
                                <label>Store</label>
                              </div>
                              <div class="col-lg-4 col-sm-12 col-md-6">
                                <div class="form-group">
                                  <input type="text" [value]="selectedStore?.storeMasterIdentity?.code" readonly class="form-control"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12"></div>
                          <div class="col-lg-12">
                            <hr/>
                          </div>
                          <div class="col-lg-12" formArrayName="itemInputList">
                            <div class="row">
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <label>Item Code</label>
                              </div>
                              <div class="col-lg-2 col-md-6 col-sm-12">
                                <label>Part Number</label>
                              </div>
                              <div class="col-lg-4 col-md-6 col-sm-12">
                                <label>Description</label>
                              </div>
                              <div class="col-lg-1 col-md-6 col-sm-12">
                                <label>UOM</label>
                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <label>Quantity</label>
                              </div>
                            </div>
                            <ng-container *ngFor="let item of secondSalesForm?.controls['itemInputList']?.controls; let i = index;">
                              <ng-container [formGroupName]="i">
                                <div class="row row-item">
                                  <div class="col-lg-2 col-md-6 col-sm-12">
                                    <div class="form-group">
                                      <input type="text" formControlName="iuomCode" class="form-control"/>
                                      <input type="hidden" value="322286" formControlName="iuomStockId" class="form-control"/>
                                      <input type="hidden" value="322286" formControlName="stockId" class="form-control"/>
                                    </div>
                                  </div>
                                  <div class="col-lg-2 col-md-6 col-sm-12">
                                    <div class="form-group">
                                      <input type="text" disabled class="form-control"/>
                                    </div>
                                  </div>
                                  <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="form-group">
                                      <input type="text" disabled class="form-control"/>
                                    </div>
                                  </div>
                                  <div class="col-lg-1 col-md-6 col-sm-12">
                                    <div class="form-group">
                                      <!--<input type="text" formControlName="uom" class="form-control"/>-->
                                    </div>
                                  </div>
                                  <div class="col-lg-3 col-md-6 col-sm-12">
                                    <div class="form-group">
                                      <input type="number" formControlName="qty" class="form-control"/>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                            <div class="row">
                              <div class="col-lg-9 col-md-6 col-sm-12">

                              </div>
                              <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="form-group">
                                  <input type="button" (click)="addRowItem()" class="btn btn-info" value="Add Row"/>
                                  <input type="button" (click)="removeRowItem()" class="btn btn-danger" value="Remove Row"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <hr/>
                          </div>
                          <div class="col-lg-12">
                            <div class="form-group">
                              <input type="button" value="Save" class="btn btn-primary" (click)="saveDocData()"/>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #customerModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Customer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ul class="select-list-all">
                    <li *ngFor="let customerData of currentCustomerList">
                      <!--<ng-contaner *ngIf="selectedProfessionCodeFrom.indexOf(arrayProfession.code) > -1">
                        <label><input checked (change)="checkProfessionFrom($event, arrayProfession)" type="checkbox"/>{{arrayProfession.description}}</label>
                      </ng-contaner>
                      <ng-contaner *ngIf="selectedProfessionCodeFrom.indexOf(arrayProfession.code) <= -1">
                        <label><input (change)="checkProfessionFrom($event, arrayProfession)" type="checkbox"/>{{arrayProfession.description}}</label>
                      </ng-contaner>-->
                      <label><input name="customerSelect" (change)="selectCustomer($event, customerData)" type="radio"/>{{customerData.name}}</label>
                    </li>
                  </ul>

                  <div class="pagination-list-section">
                    <ul>
                      <ng-container *ngFor="let totalCustomerPage of totalCustomerPagesArray; let i=index">
                        <ng-container *ngIf="i === activePageNumber">
                          <li class="active">
                            <a (click)="paginatedCustomerTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="i !== activePageNumber">
                          <li>
                            <a (click)="paginatedCustomerTo(i)">
                              {{i + 1}}
                            </a>
                          </li>
                        </ng-container>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
