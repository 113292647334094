import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {DashboardMComponent} from './dashboard-mView/dashboard-m.component';
import {RouterModule} from '@angular/router';
import {DashboardMRouting} from './dashboard-m.routing';


@NgModule({
  declarations: [DashboardMComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(DashboardMRouting),
  ]
})
export class DashboardMModule { }
