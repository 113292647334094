<div class="row">
    <div class="col-lg-12 col-xlg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">General Buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-success</code> to quickly create a General btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-primary">Primary</button>
                    <button type="button" class="btn btn-secondary">Secondary</button>
                    <button type="button" class="btn btn-success">Success</button>
                    <button type="button" class="btn btn-info">Info</button>
                    <button type="button" class="btn btn-warning">Warning</button>
                    <button type="button" class="btn btn-danger">Danger</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xlg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button with outline</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-outline-success</code> to quickly create a General btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-outline-primary">Primary</button>
                    <button type="button" class="btn btn-outline-secondary">Secondary</button>
                    <button type="button" class="btn btn-outline-success">Success</button>
                    <button type="button" class="btn btn-outline-info">Info</button>
                    <button type="button" class="btn btn-outline-warning">Warning</button>
                    <button type="button" class="btn btn-outline-danger">Danger</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xlg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Rounded Buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-rounded btn-success</code> to quickly create a General btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-rounded btn-primary">Primary</button>
                    <button type="button" class="btn btn-rounded btn-secondary">Secondary</button>
                    <button type="button" class="btn btn-rounded btn-success">Success</button>
                    <button type="button" class="btn btn-rounded btn-info">Info</button>
                    <button type="button" class="btn btn-rounded btn-warning">Warning</button>
                    <button type="button" class="btn btn-rounded btn-danger">Danger</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-12 col-xlg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Rounded outlined Buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-rounded btn-outline-success</code> to quickly create a General btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-rounded btn-outline-primary">Primary</button>
                    <button type="button" class="btn btn-rounded btn-outline-secondary">Secondary</button>
                    <button type="button" class="btn btn-rounded btn-outline-success">Success</button>
                    <button type="button" class="btn btn-rounded btn-outline-info">Info</button>
                    <button type="button" class="btn btn-rounded btn-outline-warning">Warning</button>
                    <button type="button" class="btn btn-rounded btn-outline-danger">Danger</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button Sizes</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-lg btn-success</code> to quickly create a General btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-lg btn-primary">Large .btn-lg </button>
                    <button type="button" class="btn btn-secondary">Normal .btn</button>
                    <button type="button" class="btn btn-sm btn-success">Small .btn-sm</button>
                    <button type="button" class="btn btn-xs btn-info">Tiny .btn-xs</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button Sizes with rounded</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-lg btn-rounded btn-success</code> to create a btn.</h6>
                <div class="button-group">
                    <button type="button" class="btn btn-lg btn-rounded btn-primary">Large .btn-lg </button>
                    <button type="button" class="btn btn-rounded btn-secondary">Normal .btn</button>
                    <button type="button" class="btn btn-rounded btn-sm btn-success">Small .btn-sm</button>
                    <button type="button" class="btn btn-rounded btn-xs btn-info">Tiny .btn-xs</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Block buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-block btn-success</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-info">Info</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-success">Success</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-primary">Primary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-danger">Danger</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-secondary">Secondary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-warning">Warning</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <h4 class="card-title">Rounded Block buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-block btn-success</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-info">Info</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-success">Success</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-primary">Primary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-danger">Danger</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-secondary">Secondary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-warning">Warning</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <h4 class="card-title">Block outline buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-block btn-outline-success</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-info">Info</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-success">Success</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-primary">Primary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-danger">Danger</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-secondary">Secondary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-outline-warning">Warning</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <h4 class="card-title">Rounded outline Block buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-block btn-outline-success</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-info">Info</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-success">Success</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-primary">Primary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-danger">Danger</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-secondary">Secondary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-rounded btn-block btn-outline-warning">Warning</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <h4 class="card-title">Large Block buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-block btn-lg btn-success</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-info">Info</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-success">Success</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-primary">Primary</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-danger">Danger</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-secondary">Second</button>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <button type="button" class="btn btn-block btn-lg btn-warning">Warning</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button with icon</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-success</code> & <code>i class ="fa fa-heart"</code> to quickly create a General btn.</h6>
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button type="button" class="btn btn-primary"><i class="fa fa-check"></i> Primary</button>
                        <button type="button" class="btn btn-secondary">Secondary <i class="fa fa-heart"></i></button>
                        <button type="button" class="btn btn-success"><i class="fa fa-check"></i> Success</button>
                        <button type="button" class="btn btn-info"><i class="fa fa-heart"></i> Info</button>
                        <button type="button" class="btn btn-warning"><i class="fa fa-heart"></i> Warning</button>
                        <button type="button" class="btn btn-danger"><i class="fa fa-heart"></i> Danger</button>
                    </div>
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button class="btn btn-primary waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fas fa-envelope"></i></span>Mail</button>
                        <button class="btn btn-secondary waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-check"></i></span>Check</button>
                        <button class="btn btn-info waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-heart"></i></span>Heart</button>
                        <button class="btn btn-warning waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fas fa-envelope"></i></span>Mail</button>
                        <button class="btn btn-danger waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-heart"></i></span>Heart</button>
                    </div>
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button type="button" class="btn btn-primary btn-rounded"><i class="fa fa-check"></i> Primary</button>
                        <button type="button" class="btn btn-secondary btn-rounded">Secondary <i class="fa fa-heart"></i></button>
                        <button type="button" class="btn btn-success btn-rounded"><i class="fa fa-check"></i> Success</button>
                        <button type="button" class="btn btn-info btn-rounded"><i class="fa fa-heart"></i> Info</button>
                        <button type="button" class="btn btn-warning btn-rounded"><i class="fa fa-heart"></i> Warning</button>
                        <button type="button" class="btn btn-danger btn-rounded"><i class="fa fa-heart"></i> Danger</button>
                    </div>
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button type="button" class="btn btn-outline-primary"><i class="fa fa-check"></i> Primary</button>
                        <button type="button" class="btn btn-outline-secondary">Secondary <i class="fa fa-heart"></i></button>
                        <button type="button" class="btn btn-outline-success"><i class="fa fa-check"></i> Success</button>
                        <button type="button" class="btn btn-outline-info"><i class="fa fa-heart"></i> Info</button>
                        <button type="button" class="btn btn-outline-warning"><i class="fa fa-heart"></i> Warning</button>
                        <button type="button" class="btn btn-outline-danger"><i class="fa fa-heart"></i> Danger</button>
                    </div>
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button class="btn btn-outline-primary waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fas fa-envelope"></i></span>Mail</button>
                        <button class="btn btn-outline-secondary waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-check"></i></span>Check</button>
                        <button class="btn btn-outline-info waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-heart"></i></span>Heart</button>
                        <button class="btn btn-outline-warning waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fas fa-envelope"></i></span>Mail</button>
                        <button class="btn btn-outline-danger waves-effect waves-light" type="button"><span class="btn-label m-r-5"><i class="fa fa-heart"></i></span>Heart</button>
                    </div>
                    <div class="col-lg-12 col-xlg-6 m-b-30">
                        <button type="button" class="btn btn-outline-primary btn-rounded"><i class="fa fa-check"></i> Primary</button>
                        <button type="button" class="btn btn-outline-secondary btn-rounded">Secondary <i class="fa fa-heart"></i></button>
                        <button type="button" class="btn btn-outline-success btn-rounded"><i class="fa fa-check"></i> Success</button>
                        <button type="button" class="btn btn-outline-info btn-rounded"><i class="fa fa-heart"></i> Info</button>
                        <button type="button" class="btn btn-outline-warning btn-rounded"><i class="fa fa-heart"></i> Warning</button>
                        <button type="button" class="btn btn-outline-danger btn-rounded"><i class="fa fa-heart"></i> Danger</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-6 button-group">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Circle buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-circle btn-success</code> to quickly create a General btn.</h6>
                <button type="button" class="btn btn-secondary btn-circle"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-primary btn-circle"><i class="fa fa-list"></i> </button>
                <button type="button" class="btn btn-success btn-circle"><i class="fa fa-link"></i> </button>
                <button type="button" class="btn btn-info btn-circle"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-warning btn-circle"><i class="fa fa-times"></i> </button>
                <button type="button" class="btn btn-danger btn-circle"><i class="fa fa-heart"></i> </button>
                <br>
                <br>
                <h4 class="card-title">Circle Large buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-circle btn-lg btn-success</code> to quickly create a General btn.</h6>
                <button type="button" class="btn btn-secondary btn-circle btn-lg"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-primary btn-circle btn-lg"><i class="fa fa-list"></i> </button>
                <button type="button" class="btn btn-success btn-circle btn-lg"><i class="fa fa-link"></i> </button>
                <button type="button" class="btn btn-info btn-circle btn-lg"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-warning btn-circle btn-lg"><i class="fa fa-times"></i> </button>
                <button type="button" class="btn btn-danger btn-circle btn-lg"><i class="fa fa-heart"></i> </button>
                <br>
                <br>
                <h4 class="card-title">Circle xtra Large buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-circle btn-xl btn-success</code> to quickly create a General btn.</h6>
                <button type="button" class="btn btn-secondary btn-circle btn-xl"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-primary btn-circle btn-xl"><i class="fa fa-list"></i> </button>
                <button type="button" class="btn btn-success btn-circle btn-xl"><i class="fa fa-link"></i> </button>
                <button type="button" class="btn btn-info btn-circle btn-xl"><i class="fa fa-check"></i> </button>
                <button type="button" class="btn btn-warning btn-circle btn-xl"><i class="fa fa-times"></i> </button>
            </div>
        </div>
    </div>
    <div class="col-lg-6 button-group">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Social buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-facebook</code> to quickly create a General btn.</h6>
                <button class="btn btn-facebook waves-effect waves-light" type="button"> <i class="fab fa-facebook-f"></i> </button>
                <button class="btn btn-twitter waves-effect waves-light" type="button"> <i class="fab fa-twitter"></i> </button>
                <button class="btn btn-googleplus waves-effect waves-light" type="button"> <i class="fab fa-google-plus"></i> </button>
                <button class="btn btn-linkedin waves-effect waves-light" type="button"> <i class="fab fa-linkedin"></i> </button>
                <button class="btn btn-instagram waves-effect waves-light" type="button"> <i class="fab fa-instagram"></i> </button>
                <button class="btn btn-pinterest waves-effect waves-light" type="button"> <i class="fab fa-pinterest"></i> </button>
                <button class="btn btn-dribbble waves-effect waves-light" type="button"> <i class="fab fa-dribbble"></i> </button>
                <button class="btn btn-youtube waves-effect waves-light" type="button"> <i class="fab fa-youtube"></i> </button>
                <br>
                <br>
                <h4 class="card-title">With Circle buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-circle btn-success</code> to quickly create a General btn.</h6>
                <button class="btn btn-facebook waves-effect btn-circle waves-light" type="button"> <i class="fab fa-facebook-f"></i> </button>
                <button class="btn btn-twitter waves-effect btn-circle waves-light" type="button"> <i class="fab fa-twitter"></i> </button>
                <button class="btn btn-googleplus waves-effect btn-circle waves-light" type="button"> <i class="fab fa-google-plus"></i> </button>
                <button class="btn btn-linkedin waves-effect btn-circle waves-light" type="button"> <i class="fab fa-linkedin"></i> </button>
                <button class="btn btn-instagram waves-effect btn-circle waves-light" type="button"> <i class="fab fa-instagram"></i> </button>
                <button class="btn btn-pinterest waves-effect btn-circle waves-light" type="button"> <i class="fab fa-pinterest"></i> </button>
                <button class="btn btn-dribbble waves-effect btn-circle waves-light" type="button"> <i class="fab fa-dribbble"></i> </button>
                <button class="btn btn-youtube waves-effect btn-circle waves-light" type="button"> <i class="fab fa-youtube"></i> </button>
                <br>
                <br>
                <h4 class="card-title">with rounded buttons</h4>
                <h6 class="card-subtitle">Use a classes <code>btn btn-rounded btn-success</code> to quickly create a General btn.</h6>
                <button class="btn btn-facebook waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-facebook-f"></i> </button>
                <button class="btn btn-twitter waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-twitter"></i> </button>
                <button class="btn btn-googleplus waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-google-plus"></i> </button>
                <button class="btn btn-linkedin waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-linkedin"></i> </button>
                <button class="btn btn-instagram waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-instagram"></i> </button>
                <button class="btn btn-pinterest waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-pinterest"></i> </button>
                <button class="btn btn-dribbble waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-dribbble"></i> </button>
                <button class="btn btn-youtube waves-effect btn-rounded waves-light" type="button"> <i class="fab fa-youtube"></i> </button>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="row">
                <!-- column -->
                <div class="col-xlg-6 col-lg-12 m-b-30">
                    <div class="card-body">
                        <h4 class="card-title">Group buttons</h4>
                        <h6 class="card-subtitle">Wrap a series of buttons with <code class="highlighter-rouge">.btn</code> in <code class="highlighter-rouge">.btn-group</code></h6>
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary">Left</button>
                            <button type="button" class="btn btn-secondary">Middle</button>
                            <button type="button" class="btn btn-secondary">Right</button>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-info"><i class="fa fa-align-left"></i></button>
                            <button type="button" class="btn btn-info"><i class="fa fa-align-justify"></i></button>
                            <button type="button" class="btn btn-info"><i class="fa fa-align-right"></i></button>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-success"><i class="fa fa-fast-backward"></i></button>
                            <button type="button" class="btn btn-success"><i class="fa fa-play"></i></button>
                            <button type="button" class="btn btn-success"><i class="fa fa-fast-forward"></i></button>
                        </div>
                    </div>
                </div>
                <!-- column -->
                <div class="col-xlg-6 col-lg-12 m-b-30">
                    <div class="card-body">
                        <h4 class="card-title">Button toolbar pagination</h4>
                        <h6 class="card-subtitle">Wrap a series of buttons with <code class="highlighter-rouge">.btn</code> in <code class="highlighter-rouge">.btn-group</code></h6>
                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-2" role="group" aria-label="First group">
                                <button type="button" class="btn btn-secondary disabled">1</button>
                                <button type="button" class="btn btn-info">2</button>
                                <button type="button" class="btn btn-secondary">3</button>
                                <button type="button" class="btn btn-secondary">4</button>
                            </div>
                            <div class="btn-group mr-2" role="group" aria-label="Second group">
                                <button type="button" class="btn btn-secondary">5</button>
                                <button type="button" class="btn btn-secondary">6</button>
                                <button type="button" class="btn btn-secondary">7</button>
                            </div>
                            <div class="btn-group" role="group" aria-label="Third group">
                                <button type="button" class="btn btn-secondary">8</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- column -->
                <!-- column -->
                <div class="col-xlg-6 col-lg-12 m-b-30">
                    <div class="card-body">
                        <h4 class="card-title">Group buttons sizing</h4>
                        <h6 class="card-subtitle">Wrap a series of buttons with <code class="highlighter-rouge">.btn</code> in <code class="highlighter-rouge">.btn-group</code></h6>
                        <div class="btn-group btn-group-lg" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-secondary">Left</button>
                            <button type="button" class="btn btn-secondary">Middle</button>
                            <button type="button" class="btn btn-secondary">Right</button>
                        </div>
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-info"><i class="fa fa-align-left"></i></button>
                            <button type="button" class="btn btn-info"><i class="fa fa-align-justify"></i></button>
                            <button type="button" class="btn btn-info"><i class="fa fa-align-right"></i></button>
                        </div>
                        <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-success"><i class="fa fa-fast-backward"></i></button>
                            <button type="button" class="btn btn-success"><i class="fa fa-play"></i></button>
                            <button type="button" class="btn btn-success"><i class="fa fa-fast-forward"></i></button>
                        </div>
                    </div>
                </div>
                <!-- column -->
                <!-- column -->
                <div class="col-xlg-6 col-lg-12 m-b-30">
                    <div class="card-body">
                        <h4 class="card-title">Vertical buttons</h4>
                        <h6 class="card-subtitle">creat vertical button with class of <code>.btn-group-vertical</code> </h6>
                        <div class="btn-group-vertical" role="group" aria-label="Vertical button group">
                            <button type="button" class="btn btn-secondary">Button</button>
                            <button type="button" class="btn btn-secondary">Button</button>
                            <button type="button" class="btn btn-secondary">Button</button>
                            <button type="button" class="btn btn-secondary">Button</button>
                            <button type="button" class="btn btn-secondary">Button</button>
                            <button type="button" class="btn btn-secondary">Button</button>
                        </div>
                    </div>
                </div>
                <!-- column -->
            </div>
        </div>
    </div>
</div>
<!-- End Row -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Checkbox buttons</h4>
                <div class="btn-group btn-group-toggle">
                  <label class="btn-primary" ngbButtonLabel>
                    <input type="checkbox" ngbButton [(ngModel)]="model.left"> Left (pre-checked)
                  </label>
                  <label class="btn-primary" ngbButtonLabel>
                    <input type="checkbox" ngbButton [(ngModel)]="model.middle"> Middle
                  </label>
                  <label class="btn-primary" ngbButtonLabel>
                    <input type="checkbox" ngbButton [(ngModel)]="model.right"> Right
                  </label>
                </div>
                <hr>
                <pre>{{model | json}}</pre>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Checkbox buttons(Reactive Forms)</h4>
                <form [formGroup]="checkboxGroupForm">
                  <div class="btn-group btn-group-toggle">
                    <label class="btn-primary" ngbButtonLabel>
                      <input type="checkbox" formControlName="left" ngbButton> Left (pre-checked)
                    </label>
                    <label class="btn-primary" ngbButtonLabel>
                      <input type="checkbox" formControlName="middle" ngbButton> Middle
                    </label>
                    <label class="btn-primary" ngbButtonLabel>
                      <input type="checkbox" formControlName="right" ngbButton> Right
                    </label>
                  </div>
                </form>
                <hr>
                <pre>{{checkboxGroupForm.value | json}}</pre>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Radio Buttons</h4>
                <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="model1">
                  <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="1"> Left (pre-checked)
                  </label>
                  <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" value="middle"> Middle
                  </label>
                  <label ngbButtonLabel class="btn-primary">
                    <input ngbButton type="radio" [value]="false"> Right
                  </label>
                </div>
                <hr>
                <pre>{{model1}}</pre>
            </div>
        </div>
    </div>

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Radio Buttons(Reactive Forms)</h4>
                <form [formGroup]="radioGroupForm">
                  <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="model">
                    <label ngbButtonLabel class="btn-primary">
                      <input ngbButton type="radio" [value]="1"> Left (pre-checked)
                    </label>
                    <label ngbButtonLabel class="btn-primary">
                      <input ngbButton type="radio" value="middle"> Middle
                    </label>
                    <label ngbButtonLabel class="btn-primary">
                      <input ngbButton type="radio" [value]="false"> Right
                    </label>
                  </div>
                </form>
                <hr>
                <pre>{{radioGroupForm.value['model']}}</pre>
            </div>
        </div>
    </div>
</div>