<li class="nav-item" ngbDropdown>
  <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic"
     href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
     aria-expanded="false" id="notificationToggle">

    <div *ngIf="unreadNotifications >0">
      <i class="fa fa-bell font-20"></i>
      <span class="badge rounded-pill badge-notification bg-danger">{{unreadNotifications}}</span>
    </div>
    <div *ngIf="unreadNotifications ===0">
      <i class="ti-bell font-20"></i>
    </div>


  </a>
  <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
    <button *ngIf="unreadNotifications > 0" style="float: right" mat-icon-button color="info"
            (click)="readAllNotifications()" >
      <mat-icon><i ngbTooltip="Read All Notifications" placement="top-right" class="far fa-envelope-open"></i></mat-icon>
    </button>
    <ul class="mailbox list-style-none m-t-20">
      <li>
        <div class="message-center chat-scroll" [perfectScrollbar]="config">

          <div *ngFor="let notification of notificationList" id="notification"
               style="display: flex">
            <button class="only-show-on-hover" mat-icon-button color="warn"
                    (click)="deleteNotification(notification)">
              <mat-icon>delete</mat-icon>
            </button>
            <a href="#/{{notification.url}}"
               class="message-item" id='{{notification.id}}'
               (mouseenter)="readNotification(notification)">


              <span class="notification-contnet" *ngIf="notification.read">

                                            <span
                                                class="readNotification-desc">{{notification.content}}</span>
                                            <span
                                                class="readTime">{{notification.createdTime}}</span>
                                        </span>
              <span class="notification-contnet" *ngIf="!notification.read">
                                            <span
                                                class="unreadNotification-desc">{{notification.content}}</span>
                                            <span
                                                class="unreadTime">{{notification.createdTime}}</span>
                                        </span>


            </a>

          </div>

          <div class="panel-footer row">
            <div *ngIf="this.notificationPageSize < this.undeletedNotificationsCount"
                 class="col-6 text-left">
              <a (click)="showMore()" href="javascript:void(0)">
                <h5 placement="top-left" ngbTooltip="Show more notifications">Show More</h5>
              </a>
            </div>
            <div *ngIf="this.notificationPageSize > this.maxNotificationSize"
                 class="col-6 text-right">
              <a (click)="showLess()" href="javascript:void(0)">
                <h5 placement="top-right" ngbTooltip="Show less notifications">Show Less</h5>
              </a>
            </div>
          </div>

        </div>
      </li>
    </ul>
  </div>
</li>
