<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Multiple Select</h4>
                <ng-multiselect-dropdown
                  [placeholder]="'custom placeholder'"
                  [data]="dropdownList"
                  [(ngModel)]="selectedItems"
                  [settings]="dropdownSettings"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                >
                </ng-multiselect-dropdown>
                <h4 class="card-title mt-4">Single Select</h4>
                <ng-multiselect-dropdown
                   name="city"
                   [data]="cities"
                   [(ngModel)]="singleselectedItems"
                   [settings]="singledropdownSettings"
                   (onSelect)="onItemSelect($event)">
               </ng-multiselect-dropdown>
            </div>
        </div>
    </div>
</div>