<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Employees</h4>
        <ngx-datatable #table
                       class='material'
                       [columns]="columns"
                       [columnMode]="'force'"
                       [scrollbarH]="scrollBarHorizontal"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [rows]="rows">
          <ng-container *ngFor='let column of columns' >
            <ngx-datatable-column *ngIf='column.name === "Employee Number"' name="Employee Number" prop="empNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.empNo}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee Name"' name="Employee Name" prop="name">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.name}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Nationality"' name="Nationality" prop="nationnality">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.nationality}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Date of join"' name="Date of join" prop="doj">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.doj | date}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee Code"' name="Employee Code" prop="empCode">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.employeeType?.code}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Actions"' name="Actions" prop="remarks">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <a routerLink="/provision/employee-detail/{{row?.empNo}}" class="btn btn-primary">Check Details</a>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
        <div class="center">
          <nav aria-label="...">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" (click)="previousClick()">Previous</a>
              </li>
              <li *ngFor='let index of pageIndexes' class="page-item" [ngClass]="active(index)">
                <a (click)="getPaginationWithIndex(index)" class="page-link">
                  {{index + 1}}
                </a>
              </li>
              <li class="page-item" *ngIf="maxPageExceeded">
                <a class="page-link" >...</a>
              </li>
              <li class="page-item">
                <a class="page-link" (click)="nextClick()">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
