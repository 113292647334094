import { Component, AfterViewInit } from '@angular/core';

@Component({
  templateUrl: './dashboard3.component.html',
  styleUrls: ['./dashboard3.component.css']
})
export class Dashboard3Component implements AfterViewInit {
  constructor() {}

  ngAfterViewInit() {}
}
