import { Routes } from '@angular/router';
import {UommainComponent} from './uommain/uommain.component';
import {StorebinlocationsComponent} from './storebinlocations/storebinlocations.component';
import {StoregroupingComponent} from './storegrouping/storegrouping.component';
import {OtherchargesComponent} from './othercharges/othercharges.component';
import {StockmasterComponent} from './stockmaster/stockmaster.component';
import {SalesqueriesComponent} from './salesqueries/salesqueries.component';


export const UomRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'initiallist',
        component: UommainComponent
      }, {
        path: 'storebinlocations',
        component: StorebinlocationsComponent
      }, {
        path: 'storegroupings',
        component: StoregroupingComponent
      }, {
        path: 'othercharges',
        component: OtherchargesComponent
      }, {
        path: 'stockmaster',
        component: StockmasterComponent
      }, {
        path: 'salesqueries',
        component: SalesqueriesComponent
      }
    ]
  }
];
