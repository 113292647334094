// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // host: 'https://4cad34f4ca87.ngrok.io',
 //host: 'http://localhost:8080',
 //host: 'https://34.240.225.97',
 host: 'https://15.185.204.4',
  
  //hostErp: 'https://4cad34f4ca87.ngrok.io'
  //hostErp: 'https://34.240.225.97'
  hostErp: 'https://15.185.204.4'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//"id_token": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJBQkJBUyIsImF1dGgiOiIiLCJyb2xlcyI6IkxJTkVfTUFOQUdFUixNR1RfVEVTVCxIUl9DT1JESU5BVE9SLEFETUlOLFNFTEZfU0VSVklDRV9VU0VSLE1BTkFHRU1FTlQiLCJlbXBsb3llZUlkIjo1MzM3LCJlbXBsb3llZVBtSWQiOjIwLCJjb21wYW55X2tleSI6IjAxIiwiZXhwIjoxNjMxODU2OTExfQ.jyT8fEevFQ0R1tcoRaonj0C1cA4r2NFzc82UA928AD5Wrllu7gley9Mifa4CtpqNqJIgaYYYnAsk9QdDC2L5rw";
