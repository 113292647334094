import { Component, OnInit } from '@angular/core';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stockmaster',
  templateUrl: './stockmaster.component.html',
  styleUrls: ['./stockmaster.component.css']
})
export class StockmasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentJustify = 'start';

  currentOrientation = 'horizontal';
  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'tab-preventchange2') {
      $event.preventDefault();
    }
  }

}
