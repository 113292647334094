import {Component, OnInit} from '@angular/core';
import {ROUTES} from './menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../service/auth.service';
import {LocalstorageService} from '../service/localstorage.service';
import {SessionObject} from '../model/ERP Models/Logics.model';
import {UserService} from '../service/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close

  Session: SessionObject = {
    BranchCode: "",
    FinancialCode: "",
    CompanyCode: "",
    ModuleCode: "",
    DocumentCode: "",
    UserName:"",

  };
  public companies: any[];
  public selectedCompany: any;
  private employeeNo: number;

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private localstorage: LocalstorageService,
    private loginService: UserService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  BlackList: string[] = []
  // End open close
  ngOnInit() {
    const local = this.localstorage.getLocalStorageData();
    if (!this.authService.isLinemanagerUser() && !this.authService.isErp_ConfirmationUser()) {
      this.BlackList.push('Leave Approvals', 'HR Document Approval');
    }
    if (!this.authService.isAdminUser()) {
      this.BlackList.push('Employee/Manager Mapping', 'Employee/Account Mapping', 'Approval Level Management', 'Admin', 'Leave Return');
    }
    if (!this.authService.isManagementUser()) {
      this.BlackList.push('DashboardM', 'Employee Enquiry');
    }

    if (!this.authService.isRecruitmentUser()) {
      this.BlackList.push('Candidates', 'Candidate Selection', 'Recruitment Request');
    }

    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    let SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.Session.UserName = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.employeeNo = SessionObject?.glUserMastersDetailsDTO?.employee?.empNo;
    this.loginService.getCompanies(this.Session.UserName.toUpperCase()).subscribe(
        data => {
          this.companies = data;
          this.selectedCompany = data.filter(company => company.code === SessionObject?.glUserMastersDetailsDTO?.compCode)[0];
        });
  }


  logoutUser() {
    this.authService.logout();
  }

  changeCompany(compCode: string) {
    if (this.selectedCompany.code !== compCode) {
      this.spinner.show();
      this.loginService.loginByEmpNoAndCompCode(this.employeeNo, compCode).subscribe(data => {
        if (data) {
          const allLoginData = data;
          localStorage.setItem('loginsessiondata', JSON.stringify(allLoginData));
          this.authService.onAuthenticationSuccess(data, true, true);
          this.toastrService.success('Successfully changed login company to ' + compCode);
        } else {
          this.spinner.hide();
          this.toastrService.error('An error was occurred');
        }
      }, err => {
        this.spinner.hide();
        this.toastrService.error(err?.error?.detail);
      });
    }
  }
}
