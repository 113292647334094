import { Component, OnInit } from '@angular/core';
import {ChartsService} from '../../shared/service/charts.service';
import * as c3 from 'c3';

@Component({
  selector: 'app-employeesalarybracket',
  templateUrl: './employeesalarybracket.component.html',
  styleUrls: ['./employeesalarybracket.component.css']
})
export class EmployeesalarybracketComponent implements OnInit {

  constructor(
    private chartsService: ChartsService
  ) { }

  ngOnInit(): void {
    this.chartsService.getEmployeeBySalaryBracketChart().subscribe(chartData => {
      console.log(chartData);
      if (chartData) {
        const chart2 = c3.generate({
          bindto: '#salary',
          data: {
            columns: chartData,

            type: 'donut'
          },
          donut: {
            label: {
              show: false
            },
            title: 'Employee Salary Bracket',
            width: 35
          },
          legend: {
            hide: true
          },
          color: {
            pattern: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb']
          }
        });
      }

    });
  }

}
