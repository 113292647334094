import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HeadersService {
  constructor() {}

  token: string = `Bearer ${localStorage.getItem('token')}`

  getHttpOptions(Method: string, DisableContentType: boolean = false) {
    let headerObject = new HttpHeaders();
    
    if(!DisableContentType)
    headerObject = headerObject.append(
      "Content-Type",
      "application/json"
    );

    headerObject = headerObject.append(
      "Authorization",
      this.token
    );
    headerObject = headerObject.append("Authorization-Type", "Preemptive");
    headerObject = headerObject.append(
      "Access-Control-Allow-Headers",
      "Content-Type"
    );
    headerObject = headerObject.append("Access-Control-Allow-Methods", Method);
    headerObject = headerObject.append("Access-Control-Allow-Origin", "*");
    const httpOptions = {
      headers: headerObject,
    };
    return httpOptions;
  }
}
