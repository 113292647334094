<div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-sm-2">
              <button class="btn btn-primary" (click)="sendPendingLeaveRequestsReport()">Send Pending Leave Requests Report</button>
            </div>
            <div class="col-sm-2">
              <button class="btn btn-primary" (click)="sendPendingHrDocumentRequestsReport()">Send Pending Hr Document Requests Report</button>
            </div>
          </div>
            <div class="row" style="padding-bottom: 10px;">
                <div class="col-md-4">
                    <label style="padding-left: 10px;"   for="financialYear"> Financial Year</label>
                    <select (change)="selectedFinancialYear($event.target.value)"  class="form-select" name="fyers" id="fyers">
                        <option  value="choose" disabled selected="true">Choose an option</option>
                        <option  *ngFor ="let financialyear of financialYearsList"[value]="financialyear.id">{{financialyear.year}}</option>
                    </select>
                </div>
                <!-- <div class="col-md-4">
                    <label   for="voucherType">Voucher Type</label>
                    <select  (change) ="selectedVoucher($event.target.value)" class="form-select" name="voucherType" id="voucherType">
                        <option  value="choose" disabled selected="true">Choose an option</option>
                        <option  [ngValue]="voucher" *ngFor="let voucher of vouchersList">{{voucher.docType}} - {{voucher.code}}</option>
                    </select>
                </div> -->
                <div class="col-md-4">
                    <label   for="docType">Document Type</label>
                    <select  (change) ="selectedDocumentType($event.target.value)" class="form-select" name="voucherType" id="voucherType">
                        <option  value="choose" disabled selected="true">Choose an option</option>
                        <option  [ngValue]="voucher" *ngFor="let voucher of vouchersList">{{voucher.docType}}</option>
                    </select>
                </div>

               <div class="col-md-4">
                    <label   for="docCode">Document Code</label>
                    <select  (change) ="selectedDocCode($event.target.value)" class="form-select" name="docCode" id="docCode">
                        <option  value="choose" disabled selected="true">Choose an option</option>
                        <option  [ngValue]="docCode" *ngFor="let docCode of docCodesList ">{{docCode.code}}</option>
                    </select>
                </div> 
            </div>
          <h4 class="card-title">Applied Approval Levels</h4>
            <div class="table-responsive">
                <table class="table table-bordered">
                <thead>
                    <tr>  
                    <th>User name</th>
                    <th>Document Type</th> 
                    <th>Approval Levels Applied</th>
                   
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of appliedApprovalsLevelList">
                    <td>{{row.userCode}}</td>
                    <td>{{row.usVTypeDocType}}</td>
                    <td>
                        <div class="row">
                            <div class="col-md-4">
                                <input   class="form-control" type="number" id="approvalLevel" [(ngModel)]="row.apprLevelsApplied" > 
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-outline-success"  style="cursor: pointer;" [disabled]="isSaveDisabled" (click) = saveApprLevelsApplied(row)>Save</button>
                            </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
         
        <div class="pagination-set">
            <ng-container *ngIf="searchResultPagesArray && searchResultPagesArray.length > 0">
              <div class="row">
                <div class="col-lg-10">
                  <ul>
                    <li>
                      <a (click)="searchPaginated(0)" class="btn btn-pagination"  style="cursor: pointer;">First</a>
                    </li>
                    <ng-container *ngFor="let searchResultPagesArr of searchResultPagesArray; let i = index">
                      <ng-container *ngIf="(i - 3) < activePageRequest && (i + 3) > activePageRequest && activePageRequest!=0 && activePageRequest!=1 && activePageRequest!=(searchResultPagesArray.length - 1) && activePageRequest!=(searchResultPagesArray.length - 2)">
                        <li>
                          <ng-container *ngIf="i == activePageRequest">
                            <a class="btn btn-pagination active" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activePageRequest ">
                            <a class="btn btn-pagination" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="activePageRequest==0 && (i - 5) < activePageRequest && (i + 5) > activePageRequest">
                        <li>
                          <ng-container *ngIf="i == activePageRequest">
                            <a class="btn btn-pagination active" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activePageRequest ">
                            <a class="btn btn-pagination" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="activePageRequest==1 && (i - 4) < activePageRequest && (i + 4) > activePageRequest">
                        <li>
                          <ng-container *ngIf="i == activePageRequest">
                            <a class="btn btn-pagination active" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activePageRequest ">
                            <a class="btn btn-pagination" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="(i - 5) < activePageRequest && (i + 5) > activePageRequest && activePageRequest==(searchResultPagesArray.length - 1) && searchResultPagesArray.length > 2">
                        <li>
                          <ng-container *ngIf="i == activePageRequest">
                            <a class="btn btn-pagination active" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activePageRequest ">
                            <a class="btn btn-pagination" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="(i - 4) < activePageRequest && (i + 4) > activePageRequest && activePageRequest==(searchResultPagesArray.length - 2) && searchResultPagesArray.length > 3">
                        <li>
                          <ng-container *ngIf="i == activePageRequest">
                            <a class="btn btn-pagination active" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                          <ng-container *ngIf="i != activePageRequest ">
                            <a class="btn btn-pagination" (click)="searchPaginated(i)">{{i + 1}}</a>
                          </ng-container>
                        </li>
                      </ng-container>
                    </ng-container>
                    <li *ngIf="activePageRequest !== (searchResultPagesArray.length - 1)">
                      <a (click)="searchPaginated(activePageRequest + 1)" class="btn btn-pagination">Next </a>
                    </li>
                    <li>
                      <a (click)="searchPaginated(searchResultPagesArray.length - 1)" class="btn btn-pagination"  style="cursor: pointer;">Last</a>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>
    </div>
      </div>
    </div>
  </div>  
  
