import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
interface SessionObject{
  CompanyCode?: string;
  BranchCode?: string;
  Token?: string;
  FinancialCode?: string;
  FYearID?:string;
  DocumentCode?: string;
  ModuleCode?: string;
  UserName?: string
}
@Component({
  templateUrl: 'profile.component.html'
})

export class ProfileComponent implements OnInit {
  companyName: string;
  profession: string;
  location: string;
  empNo;
  email: string;
  phoneNo: string;
  Session: SessionObject = {
    BranchCode: "",
    FinancialCode: "",
    CompanyCode: "",
    ModuleCode: "",
    DocumentCode: "",
    UserName:""
  };
  FormData: FormGroup;
  constructor(private builder: FormBuilder) {}
  ngOnInit(): void{
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.UserName = SessionObject?.glUserMastersDetailsDTO?.employee.name;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.companyName = SessionObject.glUserMastersDetailsDTO.employee.companyName;
    this.profession = SessionObject.glUserMastersDetailsDTO.employee.profession;
    this.location = SessionObject.glUserMastersDetailsDTO.employee.nationality;
    this.empNo = SessionObject.glUserMastersDetailsDTO.employee.empNo;
    this.email = localStorage.getItem('Email');
    this.phoneNo = localStorage.getItem('PhoneNo');
    this.FormData = this.builder.group({
      Fullname: new FormControl({value: this.Session.UserName, disabled: true}, [Validators.required]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Password: new FormControl({value:"", disabled: true}, [Validators.required]),
      PhoneNo: new FormControl('', [Validators.required]),
      Message: new FormControl({value:"", disabled: true}, [Validators.required]),
      Country: new FormControl(
        {value: this.location.substring(0, this.location.indexOf('/')), disabled: true}, 
        [Validators.required]),
    })
  }
  onSubmit(FormData) {
    //console.log(FormData)
    const { Email, PhoneNo } = FormData;
    localStorage.setItem('Email', Email)
    localStorage.setItem('PhoneNo', PhoneNo)
    window.location.reload()
  }
}
