<!-- ============================================================== -->
<!-- Info card -->
<!-- ============================================================== -->
<app-info-card></app-info-card>
<!-- ============================================================== -->
<!-- Info card -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Email campaign chart -->
<!-- ============================================================== -->
<app-sales></app-sales>
<!-- ============================================================== -->
<!-- Email campaign chart -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Top Selliing Products -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-sm-12">
        <app-topsell></app-topsell>
    </div>
</div>
<!-- ============================================================== -->
<!-- Top Selliing Products -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Table -->
<!-- ============================================================== -->
<app-sales-income></app-sales-income>
<!-- ============================================================== -->
<!-- Table -->
<!-- ============================================================== -->
<!-- ============================================================== -->
<!-- Recent comment and chats -->
<!-- ============================================================== -->
<div class="row">
    <!-- column -->
    <div class="col-lg-6">
        <app-comment></app-comment>
    </div>
    <!-- column -->
    <div class="col-lg-6">
        <app-chat></app-chat>
    </div>
</div>
<!-- ============================================================== -->
<!-- Recent comment and chats -->
<!-- ============================================================== -->
