import { IEmployee } from "./employee";
import { IHrDocumentType } from "./hrDocumentType";

export class IHrDocumentRequest {
    id: number;
    empNo: Number;
    docDate: Date;
    docNo: Number;
    emplPmlId: Number;
    emplId: Number;
    employee: IEmployee;
    documentType: IHrDocumentType;
    documentTypeDescription : String;

}
