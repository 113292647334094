<div class="modal-body container table-responsive">
  <div>
    <table class=" w-25 text-center  " class="compact" datatable id="GLTable">
      <thead class="darkHeaders text-center ">
      <tr>
        <th> Employee Number</th>
        <th> Name</th>
      </tr>
      </thead>
      <tbody class=" text-center">
      <tr (click)="popUpTableValueSelected(row)" *ngFor="let row of PopUpTableData"
          [class]="row? row.Selected==true?'reedd':'':''">
        <td> {{ row.EmpNo }} </td>
        <td> {{ row.Name }} </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
