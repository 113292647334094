<br>
    <div>
        <div *ngIf="!show" id="JVSelector" class="col mx-auto w-75 p-3 shadowdiv">
            <br>
            <div class="row">
    
                <div class="col-sm">
                    <h4> Select Period and Voucher </h4>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-3"> <label> Period </label> </div>
    
                <div class="col-sm">
                    <select class=" form-control " (change)="setDateFromPeriod($event)">
                        <option *ngFor="let period of PeriodList" [value]="period.Period"> {{ period.Period }}</option>
                    </select>
                </div>
    
                <div class="col-sm">
                    <input [value]="SelectedPeriod.FromDate? SelectedPeriod.FromDate: '' " class="form-control" disabled>
                </div>
    
                <div class="col-sm">
                    <input [value]="SelectedPeriod.ToDate? SelectedPeriod.ToDate: '' " class="form-control" disabled>
                </div>
            </div>
            <br>
    
            <div class="row">
                <div class="col-3"> <label> Voucher Period </label> </div>
    
                <div class="col">
                    <select class="form-control" (change)="setVoucherCode($event)">
                        <option *ngFor="let voucher of VourcherList;let i = index" [selected]="i==0" [value]="voucher.Code">
                            {{ voucher.Code +' - '+ voucher.Description}}
                        </option>
                    </select>
                </div>
    
            </div>
    
            <br>
    
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary mr-3" (click)="showBankReciepts()"> OK </button>
                    <button class="btn btn-primary"> SAVE </button>
                </div>
            </div>
    
        </div>
        
        <div *ngIf="show" class="col-md-12 p-3 shadowdiv">    
                            <div>
                                <h4>Bank Receipt Voucher</h4>
                                <hr />
                            </div>
                            <div class="card-body">
                              
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                   <label> Document Number </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-3 mt-2">
                                                            <input type="text" [value]="SelectedVoucher"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-3 mt-2">
                                                            <input type="text" [value]="DocumentCode?DocumentCode:''"
                                                               class="form-control">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary">
                                                              Switch To PDR 
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Bank GL Code </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <input (click)="openGLModal()" readonly
                                                                type="text" class="form-control"
                                                                [value]="DataObject.GL_H? DataObject.GL_H.Code: ''">
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" disabled class="form-control" [value]="DataObject.GL_H? DataObject.GL_H.Name: ''">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> SL Code </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <input type="text" (click)="openSLModal()" readonly [value]="DataObject.SL_H? DataObject.SL_H.Code: ''"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" disabled class="form-control" [value]="DataObject.SL_H? DataObject.SL_H.Name: ''">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Bank </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <input type="text" disabled [value]="BankObject?.vcode? BankObject?.vcode : ''"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" disabled [value]="BankObject?.description? BankObject?.description : ''" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label>Reference </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control" [(ngModel)]="DataObject.Headers.Reference">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label>Being </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control" [(ngModel)]="DataObject.Headers.Being">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Retd. From</label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Currency </label> 
                                                </div>
                                                <div class=" col-sm">
                                                    <select class="form-control" (change)="currencyChanged($event)">
                                                        <option *ngFor="let currency of CurrencyList">
                                                            {{ currency.code }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-3">
                                                    <label> Exchange Rate </label>
                                                </div>
                                                <div class=" col-sm">
                                                    <input [value]="SelectedCurrency.purchaseRate? SelectedCurrency.purchaseRate: '' " class="form-control"
                                                        disabled>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <button class="ml-auto mr-2 btn btn-primary">
                                                    ENBD-CHQ
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="row mt-3">
                                                <div class="col-sm-4">
                                                    <div class="row">
                                                        <div class="col-sm-1">
                                                            <input type="checkbox" disabled
                                                                class="form-check-input">
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <label>Rejected </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="row">
                                                        <div class="col-sm-1">
                                                            <input type="checkbox" disabled
                                                                class="form-check-input">
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <label> Passed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="row">
                                                        <div class="col-sm-1">
                                                            <input type="checkbox" disabled
                                                                class="form-check-input">
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <label>Confirmed </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Date</label>
                                                </div>
                                                <div class="col-sm-7">
                                                    <input type="text" type="date" [(ngModel)]="CurrentDatePopulate" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Costcenter </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <input type="text" (click)="openCCModal()" [value]="DataObject.CCC_H? DataObject.CCC_H.Code: ''" readonly
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-8" >
                                                            <input type="text" class="form-control" [value]="DataObject.CCC_H? DataObject.CCC_H.Name: ''" disabled>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Ref Date </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="text" type="date"  [(ngModel)]="DataObject.Headers.RefDate"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary" disabled>
                                                                Chq. Attachment
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Cheque No </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="text" [(ngModel)]="DataObject.Headers.ChqNo"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary">
                                                                Settlement
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label>Cheque Date </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="date"
                                                                 class="form-control"  [(ngModel)]="DataObject.HeadersChqDate">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary" disabled>
                                                                Employee Loan
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Amount </label> 
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="number" [(ngModel)]="DataObject.Headers.Amount"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary">
                                                                A/C Balance
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Create By </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="text" [value]="Session.UserName" disabled
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <button class="btn btn-primary">
                                                                A/C Statement
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3">
                                                    <label> Create On </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <input type="text" [value]="CurrentDate" disabled
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <button (click)="saveTransaction()" style="margin-top: 187px;font-size: 22px;"
                                                class="btn btn-success mt-6">
                                                Pass
                                            </button>
                                        </div>
                                    </div>
                                

                                <br />
                                <br />
                                <hr />
                                <div class="row">
                                    <div class="col" style="overflow-x: scroll;">
                                        <table class="table table-bordered table-hover">
                                            <thead >
                                                <th>SNo.</th>
                                                <th>GL Code</th>
                                                <th>SL Code</th>
                                                <th>SL Desc</th>
                                                <th>Debit Amount</th>
                                                <th>Credit Amount</th>
                                                <th>Costcenter</th>
                                                <th>Description</th>
                                                <th>Invoice Date </th>
                                                <th>Description </th>
                                                <th>Ship.No. </th>
                                                <th>Ship.Exp.Code </th>
                                                <th>Asset Exp</th>
                                                <th>Due Date</th>
                                                <th>LPO No. </th>
                                            </thead >
                                            <tbody>
                                                <tr *ngFor="let row of BodyRows">
                                                    <td>{{DataObject['GL'][row]?row:''}}</td>
                                                    <td (click)="OpenSelectModal('GL',row)"
                                                        [innerHTML]="DataObject['GL'][row]? DataObject['GL'][row].Code: ''"> </td>
                        
                                                    <td (click)="OpenSelectModal('SL',row)"
                                                        [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Code: ''"> </td>
                                                    <td [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Name: ''"> </td>
                                                    <!-- <td (click)="OpenSelectModal('CCD',row)"
                                                        [innerHTML]="DataObject['CCD'][row]? DataObject['CCD'][row].id: ''"> </td>
                                                    <td (click)="OpenSelectModal('CAT',row)"
                                                        [innerHTML]="DataObject['CAT'][row]? DataObject['CAT'][row].Name: ''"> </td> -->
                                                    <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Debit'][row]"
                                                            [disabled]="!!DataObject['Credit'][row]"> </td>
                                                    <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Credit'][row]"
                                                            [disabled]="!!DataObject['Debit'][row]"> </td>
                                                    <td (click)="OpenSelectModal('CCC',row)"
                                                        [innerHTML]="DataObject['CCC'][row]? DataObject['CCC'][row].Name: ''">
                                                    </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                    <td> <input type="date" class="form-control"  > </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                    <td> <input type="date" class="form-control"  > </td>
                                                    <td> <input type="text" class="form-control"  > </td>
                                                </tr>
                        
                                                <tr>
                                                    <td colspan="3"></td>
                                                    <th class="text-right"> Total</th>
                                                    <td> <b>{{DataObject.Debit? fetchTotal('Debit'):''}} </b> </td>
                                                    <td> <b>{{DataObject.Credit? fetchTotal('Credit'):''}} </b> </td>
                                                    <th colspan="9" class="text-left"> Net Amt.: <b>{{SumCredit-SumDebit}}</b></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <hr />
    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="row mt-2">
                                                <div class="col-sm-4 mt-2">
                                                    <label> GL Desc </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-4 mt-2">
                                                    <label>Branch </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <input type="text"
                                                                class="form-control">
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" disabled class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-4 mt-2">
                                                    <label>Cost Desc </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-4 mt-2">
                                                    <label>BOQ Desc </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-4 mt-2">
                                                    <label>Item Desc </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label> SL Bank Desc </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label> Costcentre </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label> Area Desc. </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label>Asset Run Cost </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label> Section Desc</label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-sm-3 mt-2">
                                                    <label>Category Desc </label>
                                                </div>
                                                <div class="col-sm-9">
                                                    <input type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                              
                           
                      
       
        

               






<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
            <div class="modal-header text-center">
                <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container table-responsive">
                <div>
                    <!-- <button (click)="dtTrigger.next()">next</button>
                    <button (click)="rerender()">rerender</button> -->
                    <table class=" w-50 text-center  " id="GLTable" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="compact">
                        <thead class="darkHeaders text-center ">
                            <tr>
                                <th> </th>
                                <th> Code </th>
                                <th> Name </th>
                            </tr>
                        </thead>
                        <tbody class=" text-center">
                            <tr *ngFor="let row of PopUpTableData" [class]="row? row.Selected==true?'reedd':'':''">
                                <td> <input type="radio" (change)="popUpTableValueSelected($event, row)"
                                        [checked]="row.Selected"> </td>
                                <td> {{ row.Code }} </td>
                                <td> {{ row.Name }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>