import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bankpayment',
  templateUrl: './bankpayment.component.html',
  styleUrls: ['./bankpayment.component.css']
})
export class BankpaymentComponent implements OnInit {

  numbers = [];
  show: boolean = false;
  constructor() {
    this.numbers = new Array(30).fill(0).map((x, i) => i);
  }
  public ngOnInit() {}
  showJV() {
    this.show = !this.show;
  }
}
