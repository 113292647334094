<div class="row p-2" style="background-color: rgb(246, 246, 246); ">

    <div *ngFor="let Menu of MenuObject.length>0? MenuObject: []" class="col-sm-auto">

        <div ngbDropdown class="d-inline-block">
            <button *ngIf="Menu.Link !=''" class="btn btn-outline-secondary"
                (click)="Menu.Link!=''? reDirect(Menu.Link): doNothing() ">{{ Menu.MenuName? Menu.MenuName: '' }}</button>
            <button *ngIf="Menu.Link ==''" class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                {{ Menu.MenuName? Menu.MenuName: '' }} </button>

            <div class="customDD ngbDropdownMenu" ngbDropdownMenu aria-labelledby="dropdownBasic1">

                <span *ngFor="let Submenu of Menu.SubMenues.length>0? Menu.SubMenues: []">

                    <button *ngIf="Submenu.MenuName != '$hr$' && Submenu.SubMenues.length == 0"
                        (click)="reDirect(Submenu.Link)" class="selectyy" ngbDropdownItem>
                        {{ Submenu.MenuName? Submenu.MenuName:'' }} </button>

                    <hr *ngIf="Submenu.MenuName == '$hr$'">

                    <div ngbDropdown *ngIf="Submenu.SubMenues.length != 0">
                        <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
                            {{ Submenu.MenuName? Submenu.MenuName:'' }}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            class="bg-secondary dropdown-menu dropdown-menu-right">
                            <div class=" text-left ">
                                <table>
                                    <tr *ngFor="let subSubmenu of Submenu.SubMenues" class="submenu">
                                        <td> <button (click)="reDirect(subSubmenu.Link)" class="submenu"
                                                ngbDropdownItem> {{subSubmenu.MenuName}} </button> </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </span>

            </div>

        </div>

    </div>

</div>


<router-outlet></router-outlet>