import { Component, OnInit } from '@angular/core';
import {UomService} from '../../shared/service/uom.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {HrDocumentTypeService} from '../../shared/service/hr-document-type.service';

@Component({
  selector: 'app-salesqueries',
  templateUrl: './salesqueries.component.html',
  styleUrls: ['./salesqueries.component.css']
})
export class SalesqueriesComponent implements OnInit {

  documentTypes: any[];
  docCode: any = '';

  storeList: any[];
  firstFormValidity: boolean;
  currentDate: any;

  customerPageSize: any;
  currentCustomerList: any[];
  activePageNumber: any;
  totalCustomerPagesArray: any[];
  selectedCustomer: any;
  selectedSalesMan: any;
  itemInputs: any;

  numItems: number;
  numItemsArray: any[];

  selectedStore: any;
  selectedDoc: any;

  selectedDocType: any;


  salesManList: any[];

  salesPeriodForm: FormGroup;
  secondSalesForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private uomService: UomService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private hrDocumentTypeService: HrDocumentTypeService
  ) { }

  ngOnInit(): void {

    this.currentDate = new Date();
    this.numItems = 1;
    this.numItemsArray = new Array(this.numItems);

    this.currentDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');

    this.firstFormValidity = false;

    this.hrDocumentTypeService.getAllDocumentTypes().subscribe(doctypeData => {
      this.documentTypes = doctypeData;
    }, error => {
      console.log(error);
    });

    this.salesPeriodForm = this.formBuilder.group({
      period: [null],
      fromDate: [null],
      toDate: [null],
      storeFirst: [null],
      voucherFirst: [null]
    });

    this.secondSalesForm = this.formBuilder.group({
      docNumber: [null],
      docDate: [null],
      customerData: [null],
      reference: [null],
      currency: [null],
      enquiryformat: [null],
      refDate: [null],
      remarks: [null],
      salesman: [null],
      storeSelected: [null],
      itemInputList: this.formBuilder.array([])
    });

    this.itemInputs = this.secondSalesForm.controls.itemInputList as FormArray;

    console.log(this.numItemsArray);

    let num = 0;
    while (num < this.numItems) {
      this.itemInputs.push(
        this.formBuilder.group({
          iuomCode: new FormControl(),
          iuomStockId: new FormControl(322286),
          stockId: new FormControl(322286),/*
          uom: new FormControl(),*/
          qty: new FormControl()
        })
      );
      num++;
    }

    this.uomService.getAllStoresList().subscribe(storeList => {
      console.log(storeList);
      this.storeList = storeList;
    });

    this.uomService.getAllSalesManList().subscribe(salesManList => {
      this.salesManList = salesManList;
      console.log(this.salesManList);
    });

  }

  saveAndGoNext() {
    if (
      this.salesPeriodForm.value.period &&
      this.salesPeriodForm.value.fromDate &&
      this.salesPeriodForm.value.toDate &&
      this.salesPeriodForm.value.storeFirst &&
      this.salesPeriodForm.value.voucherFirst
    ) {
      this.firstFormValidity = true;
      this.selectedStore = this.storeList.filter(storeData => storeData?.storeMasterIdentity?.code === this.salesPeriodForm.value.storeFirst);
      this.selectedStore = this.selectedStore[0];
      console.log(this.selectedStore);

    } else {
      this.firstFormValidity = false;
    }

    console.log(this.firstFormValidity);
  }

  changeDocType(event) {
    if (event.target && event.target.value) {
      this.selectedDocType = event.target.value;
      this.hrDocumentTypeService.generateCodeDocument(event.target.value).subscribe(generatedCode => {
        this.docCode = generatedCode;
        this.selectedDoc = this.documentTypes.filter(docType => {
          if (docType.docType === event.target.value) {
            return docType;
          }
        });
      }, error => {
        if (error?.error.text) {
          this.docCode = error?.error.text;
          this.selectedDoc = this.documentTypes.filter(docType => {
            if (docType.docType === event.target.value) {
              return docType;
            }
          });
        }
      });
    }
  }

  selectSalesMan(event) {
    if (event?.target?.value !== '') {
      this.selectedSalesMan = this.salesManList.filter(salesman => {
        console.log(salesman.id, parseInt(event?.target?.value, 10));
        if (salesman.id === parseInt(event?.target?.value, 10)) {
          return salesman;
        }
      });
      this.selectedSalesMan = this.selectedSalesMan[0];
    } else {
      this.selectedSalesMan = null;
    }
  }

  openCustomer(customerModel) {

    this.activePageNumber = 0;

    this.uomService.getAllCustomerList(this.activePageNumber, 10).subscribe(customerList => {
      console.log(customerList);
      this.customerPageSize = customerList['totalPages'];
      this.currentCustomerList = customerList['content'];
      this.totalCustomerPagesArray = new Array(this.customerPageSize);
      console.log(this.currentCustomerList);
    });

    this.modalService.open(customerModel, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  paginatedCustomerTo(i) {
    this.activePageNumber = i;
    this.uomService.getAllCustomerList(this.activePageNumber, 10).subscribe(customerList => {
      console.log(customerList);
      this.customerPageSize = customerList['totalPages'];
      this.currentCustomerList = customerList['content'];
      this.totalCustomerPagesArray = new Array(this.customerPageSize);
    });
  }

  selectCustomer(event: any, selectCustomer: any) {
    this.selectedCustomer = selectCustomer;
    console.log(this.selectedCustomer);
  }

  addRowItem() {
    this.numItems = this.numItems + 1;
    this.numItemsArray = new Array(this.numItems);
    let num = 0;
    this.itemInputs.push(
      this.formBuilder.group({
        iuomCode: new FormControl(),
        iuomStockId: new FormControl(322286),
        stockId: new FormControl(322286),/*
        uom: new FormControl(),*/
        qty: new FormControl()
      })
    );

  }

  removeRowItem() {
    if (this.numItems > 0) {

      this.itemInputs.removeAt(this.numItems);
      this.numItems = this.numItems - 1;
      this.numItemsArray = new Array(this.numItems);

    }
  }

  saveDocData() {
    if (
      this.selectedCustomer &&
      this.selectedStore &&
      this.selectedSalesMan
    ) {
      let docCode = this.secondSalesForm.value.docNumber;
      let customerId = this.secondSalesForm.value.customerData;
      let storeCode = this.selectedStore?.storeMasterIdentity?.code;
      let salesMan = this.selectedSalesMan?.id;
      let sendData = {
       // documentCode: this.docCode,
        documentCode: docCode,
        customerId: this.selectedCustomer.id,
        storeCode: storeCode,
        salesMan: salesMan,
        code: 'T15',
        docType: this.selectedDocType,
        finPrdId: 4971,
        details: this.itemInputs.value
      };

      console.log(JSON.stringify(sendData));

      this.uomService.saveQuotation(sendData).subscribe(savedData => {
        console.log(savedData);
      }, error => {
        console.log(error);
      });
    }
  }

  searchCustomerList(event: any) {
  }

}
