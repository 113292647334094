<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Leave Return</h4>
        <div class="row">
          <div class="col-lg-12">
            <select (change)="filterLeaveRequestsByCompany($event)"
                    class="form-control leave-type-section">
              <option selected [value]="'all'" >Company</option>
              <option *ngFor="let company of companies"
                      [value]="company.code">{{company.code}} {{company.name}}</option>
            </select>
          </div>
        </div>

        <ngx-datatable #table
                       class='material'
                       [columns]="columns"
                       [columnMode]="'force'"
                       [scrollbarH]="scrollBarHorizontal"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [rows]="rows">
          <ng-container *ngFor='let column of columns'>
            <ngx-datatable-column *ngIf='column.name === "Company"' name="Company" prop="company">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{getCompanyByCode(row?.branchCompCode)?.name}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee Number"' name="Employee Number"
                                  prop="empNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.empNo}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee Name"' name="Employee Name"
                                  prop="name">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.employeeName}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "From Date"' name="From Date"
                                  prop="fromDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{formatDate(row?.fromDate)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "To Date"' name="To Date" prop="fromDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{formatDate(row?.toDate)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>


          </ng-container>
        </ngx-datatable>

        <div class="pagination-set">
          <mat-paginator (page)="searchPaginated($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>

      </div>
    </div>
  </div>
</div>
