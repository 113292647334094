import { Routes } from '@angular/router';

import { DatatableComponent } from './data-table/data-table.component';
import { BasictableComponent } from './basic/basic.component';
import { DarktableComponent } from './dark-basic/dark.component';
import { ColortableComponent } from './color-table/color.component';
import { TablesizeComponent } from './sizing/size.component';

export const TablesRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'datatable',
                component: DatatableComponent,
                data: {
                    title: 'Data Table',
                    urls: [
                        { title: 'Dashboard', url: '/dashboard' },
                        { title: 'Data Table' }
                    ]
                }
            },
            {
                path: 'basictables',
                component: BasictableComponent,
                data: {
                    title: 'Basic Tables',
                    urls: [
                        { title: 'Dashboard', url: '/dashboard' },
                        { title: 'Basic Tables' }
                    ]
                }
            },
            {
                path: 'darktables',
                component: DarktableComponent,
                data: {
                    title: 'Dark Tables',
                    urls: [
                        { title: 'Dashboard', url: '/dashboard' },
                        { title: 'Dark Tables' }
                    ]
                }
            },
            {
                path: 'colortables',
                component: ColortableComponent,
                data: {
                    title: 'Color Tables',
                    urls: [
                        { title: 'Dashboard', url: '/dashboard' },
                        { title: 'Color Tables' }
                    ]
                }
            },
            {
                path: 'tablesizing',
                component: TablesizeComponent,
                data: {
                    title: 'Table Sizing',
                    urls: [
                        { title: 'Dashboard', url: '/dashboard' },
                        { title: 'Table Sizing' }
                    ]
                }
            }
        ]
    }
];
