import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router , ActivatedRoute} from "@angular/router";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import MenuItems from "../../../assets/config/inventorymenuconfig.json";
interface MenuObject {
  MainMenues: MainMenue[];
}


interface MainMenue {
  MenuName: string;
  SubMenues: SubMenue2[];
}

interface SubMenue2 {
  MenuName: string;
  Link: string;
  SubMenues: SubMenue[][];
}

interface SubMenue {
  MenuName: string;
  Link: string;
  SubMenues: any[];
}
@Component({
  selector: "app-inventorypricing",
  templateUrl: "./inventorypricing.component.html",
  styleUrls: ["./inventorypricing.component.css"],
})
export class InventoryPricingComponent implements OnInit {
  MenuObject: any[]=[];


  
  constructor(public router: Router, public activatedRoute:ActivatedRoute) {}
  public isCollapsed = false;
  ngOnInit(): void {
    this.MenuObject = MenuItems.MainMenues;
  }

  reDirect(Link: string)
  {
    this.router.navigate([Link],{relativeTo: this.activatedRoute})
  }

  doNothing(){}
  
}
