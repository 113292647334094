import {Component, OnInit} from '@angular/core';
import {RecruitmentCandidateService} from '../../../shared/service/recruitment-candidate-service';
import {NgxSpinnerService} from 'ngx-spinner';
import {CandidateSearchDto} from '../../../shared/dto/recruitment/candidate/candidateSearchDto';
import {CandidateDto} from '../../../shared/dto/recruitment/candidate/candidateDto';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list',
  templateUrl: './candidateList.component.html',
  styleUrls: ['./candidateList.component.css']
})
export class CandidateListComponent implements OnInit {

  rows: CandidateDto[];
  sessionObject = {
    user: '',
    token: '',
    company: ''
  };
  candidateFormGroup: FormGroup;
  candidateSearch: CandidateSearchDto;
  columns = [
    {prop: 'id', name: 'Id'},
    {prop: 'name', name: 'Name'},
    {prop: 'email', name: 'Email'},
    {prop: 'phone', name: 'Phone'},
    {prop: 'address', name: 'Address'},
    {prop: 'action', name: 'Action', width: 50}
  ];
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;
  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(private candidateService: RecruitmentCandidateService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.instantiateForm();
    this.loadCandidates();
  }

  instantiateForm() {
    this.candidateFormGroup = this.formBuilder.group({
      id: [''],
      name: [''],
      email: [''],
      phone: ['']
    });
  }

  searchByPageCandidates(event) {
    this.candidateSearch = this.candidateFormGroup.value;
    this.spinner.show();
    this.candidateService.searchCandidate(this.candidateSearch, event.pageIndex, event.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  searchCandidates() {
    this.candidateSearch = this.candidateFormGroup.value;
    this.spinner.show();
    this.candidateService.searchCandidate(this.candidateSearch, 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  loadCandidates() {
    this.spinner.show();
    this.candidateService.searchCandidate(new CandidateSearchDto(), 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  initializeSessionObject() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.sessionObject.token = SessionObject.id_token;
    this.sessionObject.user = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.sessionObject.company = SessionObject?.glUserMastersDetailsDTO?.compCode;
  }

  listDetails(id) {
    this.router.navigate(['/recruitment/candidate/details'], {queryParams: {id: id}});
  }

  delete(id) {
    if (confirm('Are you sure you want to delete the candidate [' + id + '] ?')) {
      this.spinner.show();
      this.candidateService.delete(id).subscribe(data => {
        this.spinner.hide();
        this._toastr.success('The candidate [' + id + '] was successfully deleted !');
        this.loadCandidates();
      }, error => {
        this._toastr.error(error);
        this.spinner.hide();
      });
    }
  }
}
