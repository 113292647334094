import {Component, OnInit, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {UomService} from '../../shared/service/uom.service';

@Component({
  selector: 'app-storegrouping',
  templateUrl: './storegrouping.component.html',
  styleUrls: ['./storegrouping.component.css']
})
export class StoregroupingComponent implements OnInit {

  rows = [];
  columns = [{ prop: 'code', name: 'Code' }, { prop: 'description', name: 'Description' }, { prop: 'picture', name: 'Format' }, { prop: 'updatedBy', name: 'Updated By' }, {name: 'Actions', width: 50}];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(
    private uomService: UomService
  ) { }

  ngOnInit(): void {
    this.uomService.getAllSalesPriceGroupMaster().subscribe(uomList => {
      console.log(uomList);
      this.rows = [];
    });
  }

}
