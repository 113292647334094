export class CandidateSelectionSearchDto {

  id: number;

  compCode: string;

  candidateId: number;

  constructor() {
  }

}
