<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Unit of Measurement</h4>

        <!--<ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [rows]='rows'></ngx-datatable>-->
        <ngx-datatable #table
                       class='material'
                       [columns]="columns"
                       [columnMode]="'force'"
                       [scrollbarH]="scrollBarHorizontal"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [limit]="10"
                       [rows]="rows">
          <ng-container *ngFor='let column of columns' >
            <ngx-datatable-column *ngIf='column.name === "Code"' name="Code" prop="code">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.code}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Description"' name="Description" prop="description">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.description}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Format"' name="Format" prop="picture">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.picture}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Updated By"' name="Updated By" prop="updatedBy">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.updatedBy}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Actions"' name="Actions" prop="actions">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div class="button-flex">
                  <button class="btn btn-primary">View Details</button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
