import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {HrpHeadersService} from '../service/hrp-headers.service'; 
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';
import { fil } from 'date-fns/locale';

interface MappingPayload {
  managerEmpNo: number;
  empNos: number[];
}

interface SavePayload {
  employeeAccount: employeeAccountDetails[];
}

interface employeeAccountDetails {
 empNo : number,
 oraUser: string
}


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient,private headers: HrpHeadersService) { }

  getManagers(): Observable<any>
  {
    const EndPoint = `${environment.host}/api/employees/line-managers`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getManagerEmployees(EmpNo): Observable<any>
  {
    const EndPoint = `${environment.host}/api/employees/manager-employees-mapping?empNo=${EmpNo}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getAllManagerEmployees(): Observable<any>
  {
    const EndPoint = `${environment.host}/api/employees/all-manager-employees-mapping`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  assignEmployeesToLineManager(Payload: MappingPayload)
  {
    const EndPoint = `${environment.host}/api/employees/assign-employees-to-line-manager`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  getAllOraUsers(): Observable<any>{
    const EndPoint = `${environment.host}/api/ora-users/all`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  saveAccountForEmployee(Payload : SavePayload): Observable<any>{
    const EndPoint = `${environment.host}/api/employees/create-account-for-employee`;
    return this.http.post(EndPoint, Payload?.employeeAccount, this.headers.getHttpOptions('POST'));

  } 

  uploadAccountCreationFile(file: File): Observable<any>
  {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const EndPoint = `${environment.host}/api/employees/create-account-from-file`;
    return this.http.post(EndPoint, formData, this.headers.getHttpOptions('POST', true));
  }

  getUserPpDocsforAdminManagement(userCompCode : string, usFyerId : string ,docType:string,compCode: string, page : number , size : number ): Observable<any>{
    let params = new HttpParams();
    params = params.append('userCompCode', userCompCode);
    params = params.append('usFyerId', usFyerId);
    params = params.append('docType', docType);
   
    params = params.append('userCode', compCode);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };

     return this.http.get(environment.host + '/api/user-ppdocs/find-by-voucher-and-fyer?page=' + page +'&size=' + size, httpOptions);
    
  }

  getAllFinancialYears(compCode : string):Observable<any>{
  
    let params = new HttpParams();
    params = params.append('compCode' , compCode)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params :params
    };
    
    return this.http.get(environment.host +'/api/financial-year/all',httpOptions);
  }

  getAllVouchers(compCode : string,fyerId:number):Observable<any>{

    let params = new HttpParams();
    params = params.append('compCode' , compCode)
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params :params
    };
    
    return this.http.get(environment.host +'/api/vouchers/all?fyerId='+ fyerId,httpOptions);
  }

  saveApprovalLevelDetils(userPPDocs) :Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post<any[]>(environment.host + `/api/save-approvalLvlDetails`, userPPDocs, httpOptions);
  }

  getAllDocumentTypes() :Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(environment.host +'/api/get/allDocTypes',httpOptions);

  }

  getDocCodesBasedOnCompCodeDocTypeAndFinYear(compCode :string,FYearID:number, docType:string) : Observable<any>{
       
    let params = new HttpParams();
    params = params.append('compCode' , compCode);
    params = params.append('docType',docType);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params:params
    };
    return this.http.get(environment.host +'/api/all/docCodes-with-docType?fyerId='+FYearID,httpOptions);

  }
}

