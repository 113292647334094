<br>
<div class="row">
    <div class="col-md-12">
        <div class="p-5 shadowdiv">
            <div id="JVSelector" *ngIf="!show">
                <br>
                <div class="row">
        
                    <div class="col-3">
                        <h4> Select Period and Voucher </h4>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3"> <label> Period </label> </div>
        
                    <div class="col">
                        <select class=" form-control ">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
        
                    <div class="col">
                        <input class=" form-control ">
                    </div>
        
                    <div class="col">
                        <input class=" form-control ">
                    </div>
                </div>
                <br>
        
                <div class="row">
                    <div class="col-3"> <label> Voucher Period </label> </div>
        
                    <div class="col">
                        <select class=" form-control ">
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
        
                </div>
        
                <br>
        
                <div class="row">
                    <div class="col">
                        <button class="btn btn-primary mr-3" (click)="show=!show"> OK </button>
                        <button class="btn btn-primary"> SAVE </button>
                    </div>
        
        
                </div>
        
            </div>
            <div *ngIf="show">
                <div>
                    <h4>Bank Payment Voucher</h4>
                    <hr />
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Document Number
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-3 mt-2">
                                            <input type="text" value="C01" class="form-control">
                                        </div>
                                        <div class="col-sm-3 mt-2">
                                            <input type="text" value="20110001" class="form-control">
                                        </div>

                                        <div class="col-sm-6">
                                            <button class="btn btn-primary">
                                                Switch To PDR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Bank GL Code
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input value="0101020101" type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    SL Code
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Bank
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Reference
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Being
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Paid to
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Currency
                                </div>
                                <div class="col-sm-2">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-3">
                                    Exchange Rate
                                </div>
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <button class="ml-auto mr-2 btn btn-primary">
                                    ENBD-CHQ
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="row mt-3">
                                <div class="col-sm-4">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input type="checkbox" disabled 
                                                class="form-check-input">
                                        </div>
                                        <div class="col-sm-9">
                                            Rejected
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input type="checkbox" disabled 
                                                class="form-check-input">
                                        </div>
                                        <div class="col-sm-9">
                                            Passed
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <input type="checkbox" disabled 
                                                class="form-check-input">
                                        </div>
                                        <div class="col-sm-9">
                                            Confirmed
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Date
                                </div>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Costcenter
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Ref Date
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-7">
                                            <button class="btn btn-primary" disabled>
                                                Chq. Attachment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Cheque No
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-7">
                                            <button class="btn btn-primary">
                                                Settlement
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Cheque Date
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" value="26-11-2020" class="form-control">
                                        </div>
                                        <div class="col-sm-7">
                                            <button class="btn btn-primary" disabled>
                                                Employee Loan
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Amount
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-7">
                                            <button class="btn btn-primary">
                                                A/C Balance
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Create By
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-7">
                                            <button class="btn btn-primary">
                                                A/C Statement
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3">
                                    Create On
                                </div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <button style="margin-top: 187px;font-size: 22px;" class="btn btn-success mt-6">
                                Pass
                            </button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="row">
                                <div class="col-sm-4">
                                    SN
                                </div>
                                <div class="col-sm-8">
                                    GL Code
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div style="overflow: auto;">
                                <table class="table table-bordered">
                                    <thead>
                                        <th>Dr. Amount</th>
                                        <th>Cr. Amount</th>
                                        <th>Costcenter</th>
                                        <th>GL Code</th>
                                        <th>SL Code</th>
                                        <th>Bank Code</th>
                                        <th>Invoice No.</th>
                                        <th>Invoice Date</th>
                                        <th>Description</th>
                                        <th>Ship No.</th>
                                        <th>Ship Exp Code</th>
                                        <th>Asset Exp</th>
                                        <th>Due Date</th>
                                        <th>LPO No</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <hr />
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="row mt-2">
                                <div class="col-sm-4 mt-2">
                                    GL Desc
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 mt-2">
                                    Branch
                                </div>
                                <div class="col-sm-8">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control">
                                        </div>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 mt-2">
                                    Cost Desc
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 mt-2">
                                    BOQ Desc
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4 mt-2">
                                    Item Desc
                                </div>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    SL Bank Desc
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    Costcentre
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    Area Desc.
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    Asset Run Cost
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    Section Desc
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-3 mt-2">
                                    Category Desc
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>