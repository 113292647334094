import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LEAVE_REQ_APPROVED, LEAVE_REQ_PENDING, LEAVE_REQ_REJECTED } from '../../app.constants';

import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import {ICompany} from '../model/i-company';
import {IcategoryGrades} from '../model/icategory-grades';
import {IHrDocumentRequest} from '../model/ihr-document-request';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MastercategoryService {
  constructor(private http: HttpClient) { }

  getAllCategoryGradesByCompany(companyId): Observable<IcategoryGrades[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IcategoryGrades[]>(environment.host + `/api/category-grades/company/${companyId}`, httpOptions);
  }

  save(categoryGrade: IcategoryGrades): Observable<IcategoryGrades> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post<IcategoryGrades>(environment.host + '/api/category-grades/save', categoryGrade, httpOptions);
  }

  getAllTRXTypes(): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + `/api/trx-types/all`, httpOptions);
  }

  getAllCompanyPrecentageTypes(catGrade: any): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + `/api/salary-definition/category-grade/${catGrade}`, httpOptions);
  }

  updateGradePercentages(gradePercentages): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.put<any[]>(environment.host + `/api/salary-definition/update`, gradePercentages, httpOptions);
  }

  addGradePercentages(gradePercentages): Observable<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post<any[]>(environment.host + `/api/salary-definition/create`, gradePercentages, httpOptions);
  }
}
