<div class="row">
    <div class="col-12">
        <h4 class="m-b-15">Card with Image</h4>
        <!-- Row -->
        <div class="row">
            <!-- column -->
            <div class="col-lg-3 col-md-6">
                <!-- Card -->
                <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/big/img1.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
                <!-- Card -->
            </div>
            <!-- column -->
            <!-- column -->
            <div class="col-lg-3 col-md-6">
                <!-- Card -->
                <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/big/img2.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
                <!-- Card -->
            </div>
            <!-- column -->
            <!-- column -->
            <div class="col-lg-3 col-md-6">
                <!-- Card -->
                <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/big/img3.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
                <!-- Card -->
            </div>
            <!-- column -->
            <!-- column -->
            <div class="col-lg-3 col-md-6 img-responsive">
                <!-- Card -->
                <div class="card">
                    <img class="card-img-top img-responsive" src="assets/images/big/img4.jpg" alt="Card image cap">
                    <div class="card-body">
                        <h4 class="card-title">Card title</h4>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <a href="#" class="btn btn-primary">Go somewhere</a>
                    </div>
                </div>
                <!-- Card -->
            </div>
            <!-- column -->
        </div>
        <!-- Row -->
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Content types</h4>
        <p class="text-muted m-t-0">The building block of a card is the
            <code>.card</code>
            <code>.card-body</code> Use it whenever you need a padded section within a card.</p>
        <div class="card">
            <div class="card-body collapse show">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Titles, text, and links</h4>
        <p class="text-muted m-t-0">Card titles are used by adding
            <code>.card-title</code> &amp;
            <code>.card-subtitle</code> for subtitle of card.</p>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Card title</h4>
                <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="card-link">Card link</a>
                <a href="#" class="card-link">Another link</a>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Header and footer</h4>
        <p class="text-muted m-t-0 font-12">Add an optional header and/or footer within a card.</p>
        <!-- Card -->
        <div class="card">
            <div class="card-header">
                Featured
            </div>
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div>
        <!-- Card -->
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Header and footer</h4>
        <p class="text-muted m-t-0 font-12">Add an optional header and/or footer within a card. </p>
        <!-- Card -->
        <div class="card text-center">
            <div class="card-header">
                Featured
            </div>
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-info">Go somewhere</a>
            </div>
            <div class="card-footer text-muted">
                2 days ago
            </div>
        </div>
        <!-- Card -->
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Size Using grid markup</h4>
        <p class="text-muted m-t-0 font-12">Using the grid, wrap cards in columns and rows as needed.
            <code>.col-1 to .col-12</code>
        </p>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Special title treatment</h3>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-success">Go somewhere</a>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Special title treatment</h3>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Text alignment</h4>
        <p class="text-muted m-t-0 font-12">You can quickly change the text alignment
            <code>.text-center .text-right</code>.</p>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-info">Go somewhere</a>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card text-center">
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card text-right">
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-inverse">Go somewhere</a>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Using utilities</h4>
        <p class="text-muted m-t-0 font-12">Using the utility, you can give direct width class to card like
            <code>.w-25, w-50, w-75, w-100</code>
        </p>
        <div class="card w-75">
            <div class="card-body">
                <h3 class="card-title">This card has width of 75%</h3>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-danger">Go somewhere</a>
            </div>
        </div>
        <div class="card w-50">
            <div class="card-body">
                <h3 class="card-title">This card has width of 50%</h3>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Size Using grid markup</h4>
        <p class="text-muted m-t-0 font-12">Using the grid, wrap cards in columns and rows as needed.
            <code>.col-1 to .col-12</code>
        </p>
    </div>
    <div class="col-md-6">
        <div class="card text-center">
            <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" href="javascript:void(0)">Active</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Link</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled" href="#">Disabled</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-inverse">Go somewhere</a>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card text-center">
            <div class="card-header">
                <ul class="nav nav-pills card-header-pills">
                    <li class="nav-item">
                        <a class="nav-link active" href="javascript:void(0)">Active</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Link</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled" href="javascript:void(0)">Disabled</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <h4 class="card-title">Special title treatment</h4>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                <a href="#" class="btn btn-info">Go somewhere</a>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Card Groups</h4>
        <p class="text-muted m-t-0 font-12">Use card groups to render cards as a single, attached element with equal width and height columns.</p>
        <div class="card-group">
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img1.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content
                        is a little bit longer.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img2.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img3.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card
                        has even longer content than the first to show that equal height action.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Card with Deck</h4>
        <p class="text-muted m-t-0 font-12">Use card groups to render cards as a single, attached element with equal width and height columns.</p>
        <div class="card-deck">
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img1.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content
                        is a little bit longer.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img2.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This card has supporting text below as a natural.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img-top img-responsive" src="assets/images/big/img3.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card
                        has even longer content than the first to show that equal height action. supporting text below as
                        a natural lead-in to additional content</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12 m-t-30">
        <h4 class="m-b-0">Card columns</h4>
        <p class="text-muted m-t-0 font-12">Cards can be organized into Masonry-like columns with just CSS by wrapping them in
            <code>card-columns</code>
        </p>
        <div class="card-columns">
            <div class="card">
                <img class="card-img-top img-fluid" src="assets/images/big/img6.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title that wraps to a new line</h4>
                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content
                        is a little bit longer.</p>
                </div>
            </div>
            <div class="card p-3">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                <footer>
                    <small class="text-muted">
                        Someone famous in
                        <cite title="Source Title">Source Title</cite>
                    </small>
                </footer>
            </div>
            <div class="card">
                <img class="card-img-top img-fluid" src="assets/images/big/img5.jpg" alt="Card image cap">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card card-inverse card-primary p-3 text-center">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat.</p>
                <footer>
                    <small>
                        Someone famous in
                        <cite title="Source Title">Source Title</cite>
                    </small>
                </footer>
            </div>
            <div class="card text-center">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
            <div class="card">
                <img class="card-img img-fluid" src="assets/images/big/img4.jpg" alt="Card image">
            </div>
            <div class="card p-3 text-right">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                <footer>
                    <small class="text-muted">
                        Someone famous in
                        <cite title="Source Title">Source Title</cite>
                    </small>
                </footer>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Card title</h4>
                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card
                        has even longer content than the first to show that equal height action.</p>
                    <p class="card-text">
                        <small class="text-muted">Last updated 3 mins ago</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->