<div *ngIf="!show" id="JVSelector" class="col mx-auto w-75 p-3 shadowdiv form-card">
  <br>
  <div class="row">

      <div class="col-sm">
          <h4> Select Period and Voucher </h4>
      </div>
  </div>
  <br>
  <div class="row">
      <div class="col-3"> <label> Period </label> </div>

      <div class="col-sm">
          <select class=" form-control " (change)="setDateFromPeriod($event)">
              <option *ngFor="let period of PeriodList" [value]="period.Period"> {{ period.Period }}</option>
          </select>
      </div>

      <div class="col-sm date">
          <input [value]="SelectedPeriod.FromDate? SelectedPeriod.FromDate: '' " class="form-control" disabled>
      </div>

      <div class="col-sm date">
          <input [value]="SelectedPeriod.ToDate? SelectedPeriod.ToDate: '' " class="form-control" disabled>
      </div>
  </div>
  <br>

  <div  class="row">
      <div class="col-3"> <label> Voucher Period </label> </div>

      <div class="col">
          <select disabled class="form-control" (change)="setVoucherCode($event)">
              <option *ngFor="let voucher of VourcherList;let i = index" [selected]="i==0" [value]="voucher.code">
                {{ voucher.code +' - '+ voucher.description}}
              </option>
          </select>
      </div>

  </div>

  <br>

  <div class="row">
      <div class="col">
          <button class="btn btn-primary mr-3" (click)="showDocRequests()"> Start </button>
          <button class="btn btn-primary"> Cancel </button>
      </div>
  </div>

</div>
<div *ngIf="show" class="row">
  <!-- <button class="btn btn-danger mb-2" style="margin-left: 10px;" (click)="backHrDoc()">Back</button> -->
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">HR Document Request</h4>
        <form [formGroup]="hrdocumentRequestForm" (ngSubmit)="onSubmit()">
          <div class="form-body">
            <div class="row">
              <div class="col-sm-1">
                <div class="form-group">
                  <input type="text" class="form-control" id="voucherCode" disabled [value]="this.SelectedVoucher?.code? this.SelectedVoucher?.code: '' " />
                </div>
              </div>

              <div class="col-sm-5">
                <div class="form-group">
                  <input type="text" class="form-control" id="docNo" disabled formControlName="docNo" placeholder="" [value]="DetailsPopulateObject.DocNo"   />
                </div>
              </div>
             
              <div class="col-sm-6">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="" [value]="docDate | date: 'dd/MM/yyyy'" id="docDate" disabled formControlName="docDate" placeholder="">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <div class="form-group">          
                    <input class='form-control' (click)="selectEmployee()" readonly [disabled]="isEmployeePopupDisabled" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.EmpNo" >
                </div>
              </div>
              <div class="col-sm-9">
                <input class='form-control' disabled [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.Name" >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group spaces">
                  <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.EmployeeType" class="form-control" id="empType" disabled formControlName="empType" placeholder="Employee Type">
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <input type="text" class="form-control" id="doj" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.DOJ" disabled formControlName="doj" placeholder="Date of join"/>
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.Location" class="form-control" id="location" disabled formControlName="location" placeholder="Location">
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.Profession" id="profession" disabled formControlName="profession" placeholder="Profession">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.Department" id="departement" disabled formControlName="departement" placeholder="Department">
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <input type="text" class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="FromObject.Position" id="position" disabled formControlName="position" placeholder="Position">
                </div>
              </div>
            </div>

            <div class="row" *ngIf='currentUserAllowedToManagerApprove == true || (currentUserAllowedToApproveCurrentLevel == true && !isOwnerOfDocument())'>

              <div class="col-sm-6">
                <select [disabled]="isDetailsFlag || passedFlag" (click)="initialFlagTrue()" class="custom-select" (change)="onChange($event)">
                  <option [disabled]='initialFlag' >Select Document</option>
                  <option [selected]='DocType.selected' *ngFor="let DocType of DocumentTypes" [value]="DocType.code" >
                    {{ DocType.description }}
                  </option>
                </select>
              </div>

              <div class="col-sm-2 spaces">
                <button  class="btn btn-pass-section btn-success mb-2" [disabled]="isSaveDisabled()" (click)="saveHrDocumentRequest()">SAVE</button>
              </div>   
              
              <div class="col-sm-2 spaces">
                <button  class="btn btn-pass-section btn-success" [disabled]="isPassDisabled()" (click)="passDocumentRequest()">PASS</button>
              </div>

              <div class="col-sm-2 spaces" *ngIf='canConfirm()'>
                <button  class="btn btn-pass-section btn-success" (click)="confirm()" >Confirm</button>
              </div>
              <div class="col-sm-2 spaces" *ngIf='canApprove()'>
                <button  class="btn btn-pass-section btn-success"  (click)="managerApprove()" >Approve</button>
              </div>
            </div>

            <div class="row" *ngIf='(currentUserAllowedToManagerApprove == false && currentUserAllowedToApproveCurrentLevel == false) || isOwnerOfDocument()'>

              <div class="col-sm-6">
                <select [disabled]="isDetailsFlag || passedFlag" (click)="initialFlagTrue()" class="custom-select" (change)="onChange($event)">
                  <option [disabled]='initialFlag' >Select Document</option>
                  <option [selected]='DocType.selected' *ngFor="let DocType of DocumentTypes" [value]="DocType.code" >
                    {{ DocType.description }}
                  </option>
                </select>
              </div>

              <div class="col-sm-3 spaces">
                <button  class="btn btn-pass-section btn-success mb-2" [disabled]="isSaveDisabled()" (click)="saveHrDocumentRequest()">SAVE</button>
              </div>

              <div class="col-sm-3 spaces">
                <button  class="btn btn-pass-section btn-success" [disabled]="isPassDisabled()" (click)="passDocumentRequest()">PASS</button>
              </div>
            </div>

          </div>
          <div class="form-actions">
            <div class="text-right">

               
              <!--<button type="submit" class="btn btn-info mr-2">Submit</button>
              <button type="reset" class="btn btn-dark">Reset</button>-->
            </div>
          </div>
        </form>

    
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Approved Documents</h4>
        <ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [rows]="rows">
          <ng-container *ngFor='let column of columns' >
            <ngx-datatable-column *ngIf='column.name === "Document Type"' name="Document Type" prop="documentType">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.documentTypeDescription}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document No"' name="Document No" prop="docNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row.docNo}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Date"' name="Document Date" prop="docDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row.docDate }}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='isDownloadButtonVisible && column.name === "Actions"' name="Actions" prop="actions">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <button class="button btn btn-primary" (click)="downloadDocument(row)"> Download </button>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>




<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
            <div class="modal-header text-center">
                <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container table-responsive">
                <div>
                    <table class=" w-25 text-center " id="GLTable" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="compact">
                        <thead class="darkHeaders text-center ">
                            <tr>
                               
                                <th> Emmployee Number </th>
                                <th> Name </th>
                            </tr>
                        </thead>
                        <tbody class=" text-center">
                            <tr (click)="popUpTableValueSelected(row)" *ngFor="let row of PopUpTableData" [class]="row? row.Selected==true?'reedd':'':''">        
                                <td> {{ row.Code }} </td>
                                <td> {{ row.Name }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
