<div class="card earning-widget">
    <div class="card-body">
        <h4 class="m-b-0">Total Earning</h4>
    </div>
    <div class="border-top scrollable" style="height:365px;" [perfectScrollbar]="config">
        <table class="table v-middle no-border">
            <tbody>
                <tr>
                    <td style="width:40px">
                        <img src="assets/images/users/1.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Andrew Simon</td>
                    <td align="right">
                        <span class="label label-info">$2300</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/users/2.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Daniel Kristeen</td>
                    <td align="right">
                        <span class="label label-success">$3300</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/users/3.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Dany John</td>
                    <td align="right">
                        <span class="label label-primary">$4300</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/users/4.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Chris gyle</td>
                    <td align="right">
                        <span class="label label-warning">$5300</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/users/5.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Opera mini</td>
                    <td align="right">
                        <span class="label label-danger">$4567</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/users/6.jpg" width="50" class="rounded-circle" alt="logo">
                    </td>
                    <td>Microsoft edge</td>
                    <td align="right">
                        <span class="label label-megna">$7889</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>