export class RequestSearchDto {

  referenceNumber: number;

  compCode: string;

  status: string;

  constructor() {
  }

}
