<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Icons</h4>
                <h6 class="card-subtitle">use class
                    <code>sl-icon-</code> icon name in i tag</h6>
                <div class="icon-list-demo row">
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-user"></i>sl-icon-user </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-people"></i>sl-icon-people </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-user-female"></i> sl-icon-user-female </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-user-follow"></i> sl-icon-user-follow </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-user-following"></i> sl-icon-user-following </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-user-unfollow"></i> sl-icon-user-unfollow </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-login"></i> sl-icon-login </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-logout"></i> sl-icon-logout </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-emotsmile"></i> sl-icon-emotsmile </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-phone"></i> sl-icon-phone </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-call-end"></i> sl-icon-call-end </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-call-in"></i> sl-icon-call-in </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-call-out"></i> sl-icon-call-out </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-map"></i> sl-icon-map </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-location-pin"></i> sl-icon-location-pin </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-direction"></i> sl-icon-direction </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-directions"></i> sl-icon-directions </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-compass"></i> sl-icon-compass </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-layers"></i> sl-icon-layers </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-menu"></i> sl-icon-menu </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-list"></i> sl-icon-list </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-options-vertical"></i> sl-icon-options-vertical </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-options"></i> sl-icon-options </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-down"></i> sl-icon-arrow-down </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-left"></i> sl-icon-arrow-left </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-right"></i> sl-icon-arrow-right </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-up"></i> sl-icon-arrow-up </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-up-circle"></i> sl-icon-arrow-up-circle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-left-circle"></i> sl-icon-arrow-left-circle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-right-circle"></i> sl-icon-arrow-right-circle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-arrow-down-circle"></i> sl-icon-arrow-down-circle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-check"></i> sl-icon-check </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-clock"></i> sl-icon-clock </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-plus"></i> sl-icon-plus </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-close"></i> sl-icon-close </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-trophy"></i> sl-icon-trophy </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-screen-smartphone"></i> sl-icon-screen-smartphone </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-screen-desktop"></i> sl-icon-screen-desktop </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-plane"></i> sl-icon-plane </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-notebook"></i> sl-icon-notebook </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-mustache"></i> sl-icon-mustache </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-mouse"></i> sl-icon-mouse </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-magnet"></i> sl-icon-magnet </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-energy"></i> sl-icon-energy </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-disc"></i> sl-icon-disc </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-cursor"></i> sl-icon-cursor </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-cursor-move"></i> sl-icon-cursor-move </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-crop"></i> sl-icon-crop </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-chemistry"></i> sl-icon-chemistry </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-speedometer"></i> sl-icon-speedometer </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-shield"></i> sl-icon-shield </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-screen-tablet"></i> sl-icon-screen-tablet </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-magic-wand"></i> sl-icon-magic-wand </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-hourglass"></i> sl-icon-hourglass </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-graduation"></i> sl-icon-graduation </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-ghost"></i> sl-icon-ghost </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-game-controller"></i> sl-icon-game-controller </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-fire"></i> sl-icon-fire </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-eyeglass"></i> sl-icon-eyeglass </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-envelope-open"></i> sl-icon-envelope-open </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-envolope-letter"></i> sl-icon-envolope-letter </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-bell"></i> sl-icon-bell </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-badge"></i> sl-icon-badge </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-anchor"></i> sl-icon-anchor </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-wallet"></i> sl-icon-wallet </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-vector"></i> sl-icon-vector </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-speech"></i> sl-icon-speech </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-puzzle"></i> sl-icon-puzzle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-printer"></i> sl-icon-printer </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-present"></i> sl-icon-present </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-playlist"></i> sl-icon-playlist </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-pin"></i> sl-icon-pin </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-picture"></i> sl-icon-picture </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-handbag"></i> sl-icon-handbag </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-globe-alt"></i> sl-icon-globe-alt </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-globe"></i> sl-icon-globe </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-folder-alt"></i> sl-icon-folder-alt </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-folder"></i> sl-icon-folder </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-film"></i> sl-icon-film </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-feed"></i> sl-icon-feed </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-drop"></i> sl-icon-drop </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-drawar"></i> sl-icon-drawar </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-docs"></i> sl-icon-docs </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-doc"></i> sl-icon-doc </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-diamond"></i> sl-icon-diamond </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-cup"></i> sl-icon-cup </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-calculator"></i> sl-icon-calculator </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-bubbles"></i> sl-icon-bubbles </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-briefcase"></i> sl-icon-briefcase </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-book-open"></i> sl-icon-book-open </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-basket-loaded"></i> sl-icon-basket-loaded </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-basket"></i> sl-icon-basket </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-bag"></i> sl-icon-bag </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-action-undo"></i> sl-icon-action-undo </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-action-redo"></i> sl-icon-action-redo </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-wrench"></i> sl-icon-wrench </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-umbrella"></i> sl-icon-umbrella </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-trash"></i> sl-icon-trash </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-tag"></i> sl-icon-tag </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-support"></i> .sl-icon-support </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-frame"></i> sl-icon-frame </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-size-fullscreen"></i> sl-icon-size-fullscreen </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-size-actual"></i> sl-icon-size-actual </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-shuffle"></i> sl-icon-shuffle </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-share-alt"></i> sl-icon-share-alt </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-share"></i> sl-icon-share </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-rocket"></i> sl-icon-rocket </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-question"></i> sl-icon-question </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-pie-chart"></i> sl-icon-pie-chart </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-pencil"></i> sl-icon-pencil </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-note"></i> sl-icon-note </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-loop"></i> sl-icon-loop </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-home"></i> sl-icon-home </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-grid"></i> sl-icon-grid </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-graph"></i> sl-icon-graph </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-microphone"></i> sl-icon-microphone </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-music-tone-alt"></i> sl-icon-music-tone-alt </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-music-tone"></i> sl-icon-music-tone </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-earphones-alt"></i> sl-icon-earphones-alt </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-earphones"></i> sl-icon-earphones </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-equalizer"></i> sl-icon-equalizer </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-like"></i> sl-icon-like </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-dislike"></i> sl-icon-dislike </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-start"></i> sl-icon-control-start </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-rewind"></i> sl-icon-control-rewind </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-play"></i> sl-icon-control-play </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-pause"></i> sl-icon-control-pause </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-forward"></i> sl-icon-control-forward </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-control-end"></i> sl-icon-control-end </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-volume-1"></i> sl-icon-volume-1 </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-volume-2"></i> sl-icon-volume-2 </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-volume-off"></i> sl-icon-volume-off </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-calender"></i> sl-icon-calender </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-bulb"></i> sl-icon-bulb </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-chart"></i> sl-icon-chart </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-ban"></i> sl-icon-ban </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-bubble"></i> sl-icon-bubble </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-camrecorder"></i> sl-icon-camrecorder </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-camera"></i> sl-icon-camera </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-cloud-download"></i> sl-icon-cloud-download </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-cloud-upload"></i> sl-icon-cloud-upload </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-eye"></i> sl-icon-eye </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-flag"></i> sl-icon-flag </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-heart"></i> sl-icon-heart </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-info"></i> sl-icon-info </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-key"></i> sl-icon-key </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-link"></i> sl-icon-link </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-lock"></i> sl-icon-lock </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-lock-open"></i> sl-icon-lock-open </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-magnifier"></i> sl-icon-magnifier </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-magnifier-add"></i> sl-icon-magnifier-add </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-magnifier-remove"></i> sl-icon-magnifier-remove </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-paper-clip"></i> sl-icon-paper-clip </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-paper-plane"></i> sl-icon-paper-plane </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-power"></i> sl-icon-power </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-refresh"></i> sl-icon-refresh </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-reload"></i> sl-icon-reload </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-settings"></i> sl-icon-settings </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-star"></i> sl-icon-star </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-symble-female"></i> sl-icon-symble-female </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-symbol-male"></i> sl-icon-symbol-male </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-target"></i> sl-icon-target </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-credit-card"></i> sl-icon-credit-card </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-paypal"></i> sl-icon-paypal </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-tumblr"></i> sl-icon-social-tumblr </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-twitter"></i> sl-icon-social-twitter </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-facebook"></i> sl-icon-social-facebook </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-instagram"></i> sl-icon-social-instagram </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-linkedin"></i> sl-icon-social-linkedin </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-pintarest"></i> sl-icon-social-pintarest </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-github"></i> sl-icon-social-github </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-gplus"></i> sl-icon-social-gplus </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-reddit"></i> sl-icon-social-reddit </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-skype"></i> sl-icon-social-skype </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-dribbble"></i> sl-icon-social-dribbble </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-behance"></i> sl-icon-social-behance </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-foursqare"></i> sl-icon-social-foursqare </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-soundcloud"></i> sl-icon-social-soundcloud </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-spotify"></i> sl-icon-social-spotify </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-stumbleupon"></i> sl-icon-social-stumbleupon </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-youtube"></i> sl-icon-social-youtube </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="preview">
                            <i class="sl-icon-social-dropbox"></i> sl-icon-social-dropbox </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>