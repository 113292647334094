<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Material Icons</h4>
                <h6 class="card-subtitle">you can use any icon with class of
                    <code>mid mid-</code>name of icon</h6>
                <div class="material-icon-list-demo">
                    <div class="icons" id="icons">
                        <div>
                            <i class="mdi mdi-access-point"></i>
                            <code>f002</code>
                            <span>mdi-access-point</span>
                        </div>
                        <div>
                            <i class="mdi mdi-access-point-network"></i>
                            <code>f003</code>
                            <span>mdi-access-point-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account"></i>
                            <code>f004</code>
                            <span>mdi-account</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-alert"></i>
                            <code>f005</code>
                            <span>mdi-account-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-box"></i>
                            <code>f006</code>
                            <span>mdi-account-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-box-outline"></i>
                            <code>f007</code>
                            <span>mdi-account-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-card-details"></i>
                            <code>f5d2</code>
                            <span>mdi-account-card-details</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-check"></i>
                            <code>f008</code>
                            <span>mdi-account-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-circle"></i>
                            <code>f009</code>
                            <span>mdi-account-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-convert"></i>
                            <code>f00a</code>
                            <span>mdi-account-convert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-edit"></i>
                            <code>f6bb</code>
                            <span>mdi-account-edit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-key"></i>
                            <code>f00b</code>
                            <span>mdi-account-key</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-location"></i>
                            <code>f00c</code>
                            <span>mdi-account-location</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-minus"></i>
                            <code>f00d</code>
                            <span>mdi-account-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-multiple"></i>
                            <code>f00e</code>
                            <span>mdi-account-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-multiple-minus"></i>
                            <code>f5d3</code>
                            <span>mdi-account-multiple-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-multiple-outline"></i>
                            <code>f00f</code>
                            <span>mdi-account-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-multiple-plus"></i>
                            <code>f010</code>
                            <span>mdi-account-multiple-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-network"></i>
                            <code>f011</code>
                            <span>mdi-account-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-off"></i>
                            <code>f012</code>
                            <span>mdi-account-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-outline"></i>
                            <code>f013</code>
                            <span>mdi-account-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-plus"></i>
                            <code>f014</code>
                            <span>mdi-account-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-remove"></i>
                            <code>f015</code>
                            <span>mdi-account-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-search"></i>
                            <code>f016</code>
                            <span>mdi-account-search</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-settings"></i>
                            <code>f630</code>
                            <span>mdi-account-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-settings-variant"></i>
                            <code>f631</code>
                            <span>mdi-account-settings-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-star"></i>
                            <code>f017</code>
                            <span>mdi-account-star</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-star-variant"></i>
                            <code>f018</code>
                            <span>mdi-account-star-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-account-switch"></i>
                            <code>f019</code>
                            <span>mdi-account-switch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-adjust"></i>
                            <code>f01a</code>
                            <span>mdi-adjust</span>
                        </div>
                        <div>
                            <i class="mdi mdi-air-conditioner"></i>
                            <code>f01b</code>
                            <span>mdi-air-conditioner</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airballoon"></i>
                            <code>f01c</code>
                            <span>mdi-airballoon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airplane"></i>
                            <code>f01d</code>
                            <span>mdi-airplane</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airplane-landing"></i>
                            <code>f5d4</code>
                            <span>mdi-airplane-landing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airplane-off"></i>
                            <code>f01e</code>
                            <span>mdi-airplane-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airplane-takeoff"></i>
                            <code>f5d5</code>
                            <span>mdi-airplane-takeoff</span>
                        </div>
                        <div>
                            <i class="mdi mdi-airplay"></i>
                            <code>f01f</code>
                            <span>mdi-airplay</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm"></i>
                            <code>f020</code>
                            <span>mdi-alarm</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm-check"></i>
                            <code>f021</code>
                            <span>mdi-alarm-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm-multiple"></i>
                            <code>f022</code>
                            <span>mdi-alarm-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm-off"></i>
                            <code>f023</code>
                            <span>mdi-alarm-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm-plus"></i>
                            <code>f024</code>
                            <span>mdi-alarm-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alarm-snooze"></i>
                            <code>f68d</code>
                            <span>mdi-alarm-snooze</span>
                        </div>
                        <div>
                            <i class="mdi mdi-album"></i>
                            <code>f025</code>
                            <span>mdi-album</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert"></i>
                            <code>f026</code>
                            <span>mdi-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-box"></i>
                            <code>f027</code>
                            <span>mdi-alert-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-circle"></i>
                            <code>f028</code>
                            <span>mdi-alert-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-circle-outline"></i>
                            <code>f5d6</code>
                            <span>mdi-alert-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-octagon"></i>
                            <code>f029</code>
                            <span>mdi-alert-octagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-octagram"></i>
                            <code>f6bc</code>
                            <span>mdi-alert-octagram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alert-outline"></i>
                            <code>f02a</code>
                            <span>mdi-alert-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-all-inclusive"></i>
                            <code>f6bd</code>
                            <span>mdi-all-inclusive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alpha"></i>
                            <code>f02b</code>
                            <span>mdi-alpha</span>
                        </div>
                        <div>
                            <i class="mdi mdi-alphabetical"></i>
                            <code>f02c</code>
                            <span>mdi-alphabetical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-altimeter"></i>
                            <code>f5d7</code>
                            <span>mdi-altimeter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-amazon"></i>
                            <code>f02d</code>
                            <span>mdi-amazon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-amazon-clouddrive"></i>
                            <code>f02e</code>
                            <span>mdi-amazon-clouddrive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ambulance"></i>
                            <code>f02f</code>
                            <span>mdi-ambulance</span>
                        </div>
                        <div>
                            <i class="mdi mdi-amplifier"></i>
                            <code>f030</code>
                            <span>mdi-amplifier</span>
                        </div>
                        <div>
                            <i class="mdi mdi-anchor"></i>
                            <code>f031</code>
                            <span>mdi-anchor</span>
                        </div>
                        <div>
                            <i class="mdi mdi-android"></i>
                            <code>f032</code>
                            <span>mdi-android</span>
                        </div>
                        <div>
                            <i class="mdi mdi-android-debug-bridge"></i>
                            <code>f033</code>
                            <span>mdi-android-debug-bridge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-android-studio"></i>
                            <code>f034</code>
                            <span>mdi-android-studio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-angular"></i>
                            <code>f6b1</code>
                            <span>mdi-angular</span>
                        </div>
                        <div>
                            <i class="mdi mdi-angularjs"></i>
                            <code>f6be</code>
                            <span>mdi-angularjs</span>
                        </div>
                        <div>
                            <i class="mdi mdi-animation"></i>
                            <code>f5d8</code>
                            <span>mdi-animation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple"></i>
                            <code>f035</code>
                            <span>mdi-apple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-finder"></i>
                            <code>f036</code>
                            <span>mdi-apple-finder</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-ios"></i>
                            <code>f037</code>
                            <span>mdi-apple-ios</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-keyboard-caps"></i>
                            <code>f632</code>
                            <span>mdi-apple-keyboard-caps</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-keyboard-command"></i>
                            <code>f633</code>
                            <span>mdi-apple-keyboard-command</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-keyboard-control"></i>
                            <code>f634</code>
                            <span>mdi-apple-keyboard-control</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-keyboard-option"></i>
                            <code>f635</code>
                            <span>mdi-apple-keyboard-option</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-keyboard-shift"></i>
                            <code>f636</code>
                            <span>mdi-apple-keyboard-shift</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-mobileme"></i>
                            <code>f038</code>
                            <span>mdi-apple-mobileme</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apple-safari"></i>
                            <code>f039</code>
                            <span>mdi-apple-safari</span>
                        </div>
                        <div>
                            <i class="mdi mdi-application"></i>
                            <code>f614</code>
                            <span>mdi-application</span>
                        </div>
                        <div>
                            <i class="mdi mdi-apps"></i>
                            <code>f03b</code>
                            <span>mdi-apps</span>
                        </div>
                        <div>
                            <i class="mdi mdi-archive"></i>
                            <code>f03c</code>
                            <span>mdi-archive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrange-bring-forward"></i>
                            <code>f03d</code>
                            <span>mdi-arrange-bring-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrange-bring-to-front"></i>
                            <code>f03e</code>
                            <span>mdi-arrange-bring-to-front</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrange-send-backward"></i>
                            <code>f03f</code>
                            <span>mdi-arrange-send-backward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrange-send-to-back"></i>
                            <code>f040</code>
                            <span>mdi-arrange-send-to-back</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-all"></i>
                            <code>f041</code>
                            <span>mdi-arrow-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-bottom-left"></i>
                            <code>f042</code>
                            <span>mdi-arrow-bottom-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-bottom-right"></i>
                            <code>f043</code>
                            <span>mdi-arrow-bottom-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-compress"></i>
                            <code>f615</code>
                            <span>mdi-arrow-compress</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-compress-all"></i>
                            <code>f044</code>
                            <span>mdi-arrow-compress-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down"></i>
                            <code>f045</code>
                            <span>mdi-arrow-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-bold"></i>
                            <code>f046</code>
                            <span>mdi-arrow-down-bold</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-bold-circle"></i>
                            <code>f047</code>
                            <span>mdi-arrow-down-bold-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-bold-circle-outline"></i>
                            <code>f048</code>
                            <span>mdi-arrow-down-bold-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-bold-hexagon-outline"></i>
                            <code>f049</code>
                            <span>mdi-arrow-down-bold-hexagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-box"></i>
                            <code>f6bf</code>
                            <span>mdi-arrow-down-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-drop-circle"></i>
                            <code>f04a</code>
                            <span>mdi-arrow-down-drop-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-down-drop-circle-outline"></i>
                            <code>f04b</code>
                            <span>mdi-arrow-down-drop-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-expand"></i>
                            <code>f616</code>
                            <span>mdi-arrow-expand</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-expand-all"></i>
                            <code>f04c</code>
                            <span>mdi-arrow-expand-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left"></i>
                            <code>f04d</code>
                            <span>mdi-arrow-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-bold"></i>
                            <code>f04e</code>
                            <span>mdi-arrow-left-bold</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-bold-circle"></i>
                            <code>f04f</code>
                            <span>mdi-arrow-left-bold-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-bold-circle-outline"></i>
                            <code>f050</code>
                            <span>mdi-arrow-left-bold-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-bold-hexagon-outline"></i>
                            <code>f051</code>
                            <span>mdi-arrow-left-bold-hexagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-box"></i>
                            <code>f6c0</code>
                            <span>mdi-arrow-left-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-drop-circle"></i>
                            <code>f052</code>
                            <span>mdi-arrow-left-drop-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-left-drop-circle-outline"></i>
                            <code>f053</code>
                            <span>mdi-arrow-left-drop-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right"></i>
                            <code>f054</code>
                            <span>mdi-arrow-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-bold"></i>
                            <code>f055</code>
                            <span>mdi-arrow-right-bold</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-bold-circle"></i>
                            <code>f056</code>
                            <span>mdi-arrow-right-bold-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-bold-circle-outline"></i>
                            <code>f057</code>
                            <span>mdi-arrow-right-bold-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-bold-hexagon-outline"></i>
                            <code>f058</code>
                            <span>mdi-arrow-right-bold-hexagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-box"></i>
                            <code>f6c1</code>
                            <span>mdi-arrow-right-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-drop-circle"></i>
                            <code>f059</code>
                            <span>mdi-arrow-right-drop-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-right-drop-circle-outline"></i>
                            <code>f05a</code>
                            <span>mdi-arrow-right-drop-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-top-left"></i>
                            <code>f05b</code>
                            <span>mdi-arrow-top-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-top-right"></i>
                            <code>f05c</code>
                            <span>mdi-arrow-top-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up"></i>
                            <code>f05d</code>
                            <span>mdi-arrow-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-bold"></i>
                            <code>f05e</code>
                            <span>mdi-arrow-up-bold</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-bold-circle"></i>
                            <code>f05f</code>
                            <span>mdi-arrow-up-bold-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-bold-circle-outline"></i>
                            <code>f060</code>
                            <span>mdi-arrow-up-bold-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i>
                            <code>f061</code>
                            <span>mdi-arrow-up-bold-hexagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-box"></i>
                            <code>f6c2</code>
                            <span>mdi-arrow-up-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-drop-circle"></i>
                            <code>f062</code>
                            <span>mdi-arrow-up-drop-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-arrow-up-drop-circle-outline"></i>
                            <code>f063</code>
                            <span>mdi-arrow-up-drop-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-assistant"></i>
                            <code>f064</code>
                            <span>mdi-assistant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-asterisk"></i>
                            <code>f6c3</code>
                            <span>mdi-asterisk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-at"></i>
                            <code>f065</code>
                            <span>mdi-at</span>
                        </div>
                        <div>
                            <i class="mdi mdi-attachment"></i>
                            <code>f066</code>
                            <span>mdi-attachment</span>
                        </div>
                        <div>
                            <i class="mdi mdi-audiobook"></i>
                            <code>f067</code>
                            <span>mdi-audiobook</span>
                        </div>
                        <div>
                            <i class="mdi mdi-auto-fix"></i>
                            <code>f068</code>
                            <span>mdi-auto-fix</span>
                        </div>
                        <div>
                            <i class="mdi mdi-auto-upload"></i>
                            <code>f069</code>
                            <span>mdi-auto-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-autorenew"></i>
                            <code>f06a</code>
                            <span>mdi-autorenew</span>
                        </div>
                        <div>
                            <i class="mdi mdi-av-timer"></i>
                            <code>f06b</code>
                            <span>mdi-av-timer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-baby"></i>
                            <code>f06c</code>
                            <span>mdi-baby</span>
                        </div>
                        <div>
                            <i class="mdi mdi-baby-buggy"></i>
                            <code>f68e</code>
                            <span>mdi-baby-buggy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-backburger"></i>
                            <code>f06d</code>
                            <span>mdi-backburger</span>
                        </div>
                        <div>
                            <i class="mdi mdi-backspace"></i>
                            <code>f06e</code>
                            <span>mdi-backspace</span>
                        </div>
                        <div>
                            <i class="mdi mdi-backup-restore"></i>
                            <code>f06f</code>
                            <span>mdi-backup-restore</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bandcamp"></i>
                            <code>f674</code>
                            <span>mdi-bandcamp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bank"></i>
                            <code>f070</code>
                            <span>mdi-bank</span>
                        </div>
                        <div>
                            <i class="mdi mdi-barcode"></i>
                            <code>f071</code>
                            <span>mdi-barcode</span>
                        </div>
                        <div>
                            <i class="mdi mdi-barcode-scan"></i>
                            <code>f072</code>
                            <span>mdi-barcode-scan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-barley"></i>
                            <code>f073</code>
                            <span>mdi-barley</span>
                        </div>
                        <div>
                            <i class="mdi mdi-barrel"></i>
                            <code>f074</code>
                            <span>mdi-barrel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-basecamp"></i>
                            <code>f075</code>
                            <span>mdi-basecamp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-basket"></i>
                            <code>f076</code>
                            <span>mdi-basket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-basket-fill"></i>
                            <code>f077</code>
                            <span>mdi-basket-fill</span>
                        </div>
                        <div>
                            <i class="mdi mdi-basket-unfill"></i>
                            <code>f078</code>
                            <span>mdi-basket-unfill</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery"></i>
                            <code>f079</code>
                            <span>mdi-battery</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-10"></i>
                            <code>f07a</code>
                            <span>mdi-battery-10</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-20"></i>
                            <code>f07b</code>
                            <span>mdi-battery-20</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-30"></i>
                            <code>f07c</code>
                            <span>mdi-battery-30</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-40"></i>
                            <code>f07d</code>
                            <span>mdi-battery-40</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-50"></i>
                            <code>f07e</code>
                            <span>mdi-battery-50</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-60"></i>
                            <code>f07f</code>
                            <span>mdi-battery-60</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-70"></i>
                            <code>f080</code>
                            <span>mdi-battery-70</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-80"></i>
                            <code>f081</code>
                            <span>mdi-battery-80</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-90"></i>
                            <code>f082</code>
                            <span>mdi-battery-90</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-alert"></i>
                            <code>f083</code>
                            <span>mdi-battery-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging"></i>
                            <code>f084</code>
                            <span>mdi-battery-charging</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-100"></i>
                            <code>f085</code>
                            <span>mdi-battery-charging-100</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-20"></i>
                            <code>f086</code>
                            <span>mdi-battery-charging-20</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-30"></i>
                            <code>f087</code>
                            <span>mdi-battery-charging-30</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-40"></i>
                            <code>f088</code>
                            <span>mdi-battery-charging-40</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-60"></i>
                            <code>f089</code>
                            <span>mdi-battery-charging-60</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-80"></i>
                            <code>f08a</code>
                            <span>mdi-battery-charging-80</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-charging-90"></i>
                            <code>f08b</code>
                            <span>mdi-battery-charging-90</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-minus"></i>
                            <code>f08c</code>
                            <span>mdi-battery-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-negative"></i>
                            <code>f08d</code>
                            <span>mdi-battery-negative</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-outline"></i>
                            <code>f08e</code>
                            <span>mdi-battery-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-plus"></i>
                            <code>f08f</code>
                            <span>mdi-battery-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-positive"></i>
                            <code>f090</code>
                            <span>mdi-battery-positive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-battery-unknown"></i>
                            <code>f091</code>
                            <span>mdi-battery-unknown</span>
                        </div>
                        <div>
                            <i class="mdi mdi-beach"></i>
                            <code>f092</code>
                            <span>mdi-beach</span>
                        </div>
                        <div>
                            <i class="mdi mdi-beaker"></i>
                            <code>f68f</code>
                            <span>mdi-beaker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-beats"></i>
                            <code>f097</code>
                            <span>mdi-beats</span>
                        </div>
                        <div>
                            <i class="mdi mdi-beer"></i>
                            <code>f098</code>
                            <span>mdi-beer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-behance"></i>
                            <code>f099</code>
                            <span>mdi-behance</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell"></i>
                            <code>f09a</code>
                            <span>mdi-bell</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-off"></i>
                            <code>f09b</code>
                            <span>mdi-bell-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-outline"></i>
                            <code>f09c</code>
                            <span>mdi-bell-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-plus"></i>
                            <code>f09d</code>
                            <span>mdi-bell-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-ring"></i>
                            <code>f09e</code>
                            <span>mdi-bell-ring</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-ring-outline"></i>
                            <code>f09f</code>
                            <span>mdi-bell-ring-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bell-sleep"></i>
                            <code>f0a0</code>
                            <span>mdi-bell-sleep</span>
                        </div>
                        <div>
                            <i class="mdi mdi-beta"></i>
                            <code>f0a1</code>
                            <span>mdi-beta</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bible"></i>
                            <code>f0a2</code>
                            <span>mdi-bible</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bike"></i>
                            <code>f0a3</code>
                            <span>mdi-bike</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bing"></i>
                            <code>f0a4</code>
                            <span>mdi-bing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-binoculars"></i>
                            <code>f0a5</code>
                            <span>mdi-binoculars</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bio"></i>
                            <code>f0a6</code>
                            <span>mdi-bio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-biohazard"></i>
                            <code>f0a7</code>
                            <span>mdi-biohazard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bitbucket"></i>
                            <code>f0a8</code>
                            <span>mdi-bitbucket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-black-mesa"></i>
                            <code>f0a9</code>
                            <span>mdi-black-mesa</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blackberry"></i>
                            <code>f0aa</code>
                            <span>mdi-blackberry</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blender"></i>
                            <code>f0ab</code>
                            <span>mdi-blender</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blinds"></i>
                            <code>f0ac</code>
                            <span>mdi-blinds</span>
                        </div>
                        <div>
                            <i class="mdi mdi-block-helper"></i>
                            <code>f0ad</code>
                            <span>mdi-block-helper</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blogger"></i>
                            <code>f0ae</code>
                            <span>mdi-blogger</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth"></i>
                            <code>f0af</code>
                            <span>mdi-bluetooth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth-audio"></i>
                            <code>f0b0</code>
                            <span>mdi-bluetooth-audio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth-connect"></i>
                            <code>f0b1</code>
                            <span>mdi-bluetooth-connect</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth-off"></i>
                            <code>f0b2</code>
                            <span>mdi-bluetooth-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth-settings"></i>
                            <code>f0b3</code>
                            <span>mdi-bluetooth-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bluetooth-transfer"></i>
                            <code>f0b4</code>
                            <span>mdi-bluetooth-transfer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blur"></i>
                            <code>f0b5</code>
                            <span>mdi-blur</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blur-linear"></i>
                            <code>f0b6</code>
                            <span>mdi-blur-linear</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blur-off"></i>
                            <code>f0b7</code>
                            <span>mdi-blur-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-blur-radial"></i>
                            <code>f0b8</code>
                            <span>mdi-blur-radial</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bomb"></i>
                            <code>f690</code>
                            <span>mdi-bomb</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bomb-off"></i>
                            <code>f6c4</code>
                            <span>mdi-bomb-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bone"></i>
                            <code>f0b9</code>
                            <span>mdi-bone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book"></i>
                            <code>f0ba</code>
                            <span>mdi-book</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-minus"></i>
                            <code>f5d9</code>
                            <span>mdi-book-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-multiple"></i>
                            <code>f0bb</code>
                            <span>mdi-book-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-multiple-variant"></i>
                            <code>f0bc</code>
                            <span>mdi-book-multiple-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-open"></i>
                            <code>f0bd</code>
                            <span>mdi-book-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-open-page-variant"></i>
                            <code>f5da</code>
                            <span>mdi-book-open-page-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-open-variant"></i>
                            <code>f0be</code>
                            <span>mdi-book-open-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-plus"></i>
                            <code>f5db</code>
                            <span>mdi-book-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-book-variant"></i>
                            <code>f0bf</code>
                            <span>mdi-book-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark"></i>
                            <code>f0c0</code>
                            <span>mdi-bookmark</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-check"></i>
                            <code>f0c1</code>
                            <span>mdi-bookmark-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-music"></i>
                            <code>f0c2</code>
                            <span>mdi-bookmark-music</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-outline"></i>
                            <code>f0c3</code>
                            <span>mdi-bookmark-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-plus"></i>
                            <code>f0c5</code>
                            <span>mdi-bookmark-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-plus-outline"></i>
                            <code>f0c4</code>
                            <span>mdi-bookmark-plus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bookmark-remove"></i>
                            <code>f0c6</code>
                            <span>mdi-bookmark-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-boombox"></i>
                            <code>f5dc</code>
                            <span>mdi-boombox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bootstrap"></i>
                            <code>f6c5</code>
                            <span>mdi-bootstrap</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-all"></i>
                            <code>f0c7</code>
                            <span>mdi-border-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-bottom"></i>
                            <code>f0c8</code>
                            <span>mdi-border-bottom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-color"></i>
                            <code>f0c9</code>
                            <span>mdi-border-color</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-horizontal"></i>
                            <code>f0ca</code>
                            <span>mdi-border-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-inside"></i>
                            <code>f0cb</code>
                            <span>mdi-border-inside</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-left"></i>
                            <code>f0cc</code>
                            <span>mdi-border-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-none"></i>
                            <code>f0cd</code>
                            <span>mdi-border-none</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-outside"></i>
                            <code>f0ce</code>
                            <span>mdi-border-outside</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-right"></i>
                            <code>f0cf</code>
                            <span>mdi-border-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-style"></i>
                            <code>f0d0</code>
                            <span>mdi-border-style</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-top"></i>
                            <code>f0d1</code>
                            <span>mdi-border-top</span>
                        </div>
                        <div>
                            <i class="mdi mdi-border-vertical"></i>
                            <code>f0d2</code>
                            <span>mdi-border-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bow-tie"></i>
                            <code>f677</code>
                            <span>mdi-bow-tie</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bowl"></i>
                            <code>f617</code>
                            <span>mdi-bowl</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bowling"></i>
                            <code>f0d3</code>
                            <span>mdi-bowling</span>
                        </div>
                        <div>
                            <i class="mdi mdi-box"></i>
                            <code>f0d4</code>
                            <span>mdi-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-box-cutter"></i>
                            <code>f0d5</code>
                            <span>mdi-box-cutter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-box-shadow"></i>
                            <code>f637</code>
                            <span>mdi-box-shadow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bridge"></i>
                            <code>f618</code>
                            <span>mdi-bridge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-briefcase"></i>
                            <code>f0d6</code>
                            <span>mdi-briefcase</span>
                        </div>
                        <div>
                            <i class="mdi mdi-briefcase-check"></i>
                            <code>f0d7</code>
                            <span>mdi-briefcase-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-briefcase-download"></i>
                            <code>f0d8</code>
                            <span>mdi-briefcase-download</span>
                        </div>
                        <div>
                            <i class="mdi mdi-briefcase-upload"></i>
                            <code>f0d9</code>
                            <span>mdi-briefcase-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-1"></i>
                            <code>f0da</code>
                            <span>mdi-brightness-1</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-2"></i>
                            <code>f0db</code>
                            <span>mdi-brightness-2</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-3"></i>
                            <code>f0dc</code>
                            <span>mdi-brightness-3</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-4"></i>
                            <code>f0dd</code>
                            <span>mdi-brightness-4</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-5"></i>
                            <code>f0de</code>
                            <span>mdi-brightness-5</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-6"></i>
                            <code>f0df</code>
                            <span>mdi-brightness-6</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-7"></i>
                            <code>f0e0</code>
                            <span>mdi-brightness-7</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brightness-auto"></i>
                            <code>f0e1</code>
                            <span>mdi-brightness-auto</span>
                        </div>
                        <div>
                            <i class="mdi mdi-broom"></i>
                            <code>f0e2</code>
                            <span>mdi-broom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-brush"></i>
                            <code>f0e3</code>
                            <span>mdi-brush</span>
                        </div>
                        <div>
                            <i class="mdi mdi-buffer"></i>
                            <code>f619</code>
                            <span>mdi-buffer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bug"></i>
                            <code>f0e4</code>
                            <span>mdi-bug</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bulletin-board"></i>
                            <code>f0e5</code>
                            <span>mdi-bulletin-board</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bullhorn"></i>
                            <code>f0e6</code>
                            <span>mdi-bullhorn</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bullseye"></i>
                            <code>f5dd</code>
                            <span>mdi-bullseye</span>
                        </div>
                        <div>
                            <i class="mdi mdi-burst-mode"></i>
                            <code>f5de</code>
                            <span>mdi-burst-mode</span>
                        </div>
                        <div>
                            <i class="mdi mdi-bus"></i>
                            <code>f0e7</code>
                            <span>mdi-bus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cached"></i>
                            <code>f0e8</code>
                            <span>mdi-cached</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cake"></i>
                            <code>f0e9</code>
                            <span>mdi-cake</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cake-layered"></i>
                            <code>f0ea</code>
                            <span>mdi-cake-layered</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cake-variant"></i>
                            <code>f0eb</code>
                            <span>mdi-cake-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calculator"></i>
                            <code>f0ec</code>
                            <span>mdi-calculator</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar"></i>
                            <code>f0ed</code>
                            <span>mdi-calendar</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-blank"></i>
                            <code>f0ee</code>
                            <span>mdi-calendar-blank</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-check"></i>
                            <code>f0ef</code>
                            <span>mdi-calendar-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-clock"></i>
                            <code>f0f0</code>
                            <span>mdi-calendar-clock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-multiple"></i>
                            <code>f0f1</code>
                            <span>mdi-calendar-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-multiple-check"></i>
                            <code>f0f2</code>
                            <span>mdi-calendar-multiple-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-plus"></i>
                            <code>f0f3</code>
                            <span>mdi-calendar-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-question"></i>
                            <code>f691</code>
                            <span>mdi-calendar-question</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-range"></i>
                            <code>f678</code>
                            <span>mdi-calendar-range</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-remove"></i>
                            <code>f0f4</code>
                            <span>mdi-calendar-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-text"></i>
                            <code>f0f5</code>
                            <span>mdi-calendar-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-calendar-today"></i>
                            <code>f0f6</code>
                            <span>mdi-calendar-today</span>
                        </div>
                        <div>
                            <i class="mdi mdi-call-made"></i>
                            <code>f0f7</code>
                            <span>mdi-call-made</span>
                        </div>
                        <div>
                            <i class="mdi mdi-call-merge"></i>
                            <code>f0f8</code>
                            <span>mdi-call-merge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-call-missed"></i>
                            <code>f0f9</code>
                            <span>mdi-call-missed</span>
                        </div>
                        <div>
                            <i class="mdi mdi-call-received"></i>
                            <code>f0fa</code>
                            <span>mdi-call-received</span>
                        </div>
                        <div>
                            <i class="mdi mdi-call-split"></i>
                            <code>f0fb</code>
                            <span>mdi-call-split</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camcorder"></i>
                            <code>f0fc</code>
                            <span>mdi-camcorder</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camcorder-box"></i>
                            <code>f0fd</code>
                            <span>mdi-camcorder-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camcorder-box-off"></i>
                            <code>f0fe</code>
                            <span>mdi-camcorder-box-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camcorder-off"></i>
                            <code>f0ff</code>
                            <span>mdi-camcorder-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera"></i>
                            <code>f100</code>
                            <span>mdi-camera</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-burst"></i>
                            <code>f692</code>
                            <span>mdi-camera-burst</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-enhance"></i>
                            <code>f101</code>
                            <span>mdi-camera-enhance</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-front"></i>
                            <code>f102</code>
                            <span>mdi-camera-front</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-front-variant"></i>
                            <code>f103</code>
                            <span>mdi-camera-front-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-iris"></i>
                            <code>f104</code>
                            <span>mdi-camera-iris</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-off"></i>
                            <code>f5df</code>
                            <span>mdi-camera-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-party-mode"></i>
                            <code>f105</code>
                            <span>mdi-camera-party-mode</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-rear"></i>
                            <code>f106</code>
                            <span>mdi-camera-rear</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-rear-variant"></i>
                            <code>f107</code>
                            <span>mdi-camera-rear-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-switch"></i>
                            <code>f108</code>
                            <span>mdi-camera-switch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-camera-timer"></i>
                            <code>f109</code>
                            <span>mdi-camera-timer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-candle"></i>
                            <code>f5e2</code>
                            <span>mdi-candle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-candycane"></i>
                            <code>f10a</code>
                            <span>mdi-candycane</span>
                        </div>
                        <div>
                            <i class="mdi mdi-car"></i>
                            <code>f10b</code>
                            <span>mdi-car</span>
                        </div>
                        <div>
                            <i class="mdi mdi-car-battery"></i>
                            <code>f10c</code>
                            <span>mdi-car-battery</span>
                        </div>
                        <div>
                            <i class="mdi mdi-car-connected"></i>
                            <code>f10d</code>
                            <span>mdi-car-connected</span>
                        </div>
                        <div>
                            <i class="mdi mdi-car-wash"></i>
                            <code>f10e</code>
                            <span>mdi-car-wash</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cards"></i>
                            <code>f638</code>
                            <span>mdi-cards</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cards-outline"></i>
                            <code>f639</code>
                            <span>mdi-cards-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cards-playing-outline"></i>
                            <code>f63a</code>
                            <span>mdi-cards-playing-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cards-variant"></i>
                            <code>f6c6</code>
                            <span>mdi-cards-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-carrot"></i>
                            <code>f10f</code>
                            <span>mdi-carrot</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cart"></i>
                            <code>f110</code>
                            <span>mdi-cart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cart-off"></i>
                            <code>f66b</code>
                            <span>mdi-cart-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cart-outline"></i>
                            <code>f111</code>
                            <span>mdi-cart-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cart-plus"></i>
                            <code>f112</code>
                            <span>mdi-cart-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-case-sensitive-alt"></i>
                            <code>f113</code>
                            <span>mdi-case-sensitive-alt</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cash"></i>
                            <code>f114</code>
                            <span>mdi-cash</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cash-100"></i>
                            <code>f115</code>
                            <span>mdi-cash-100</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cash-multiple"></i>
                            <code>f116</code>
                            <span>mdi-cash-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cash-usd"></i>
                            <code>f117</code>
                            <span>mdi-cash-usd</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cast"></i>
                            <code>f118</code>
                            <span>mdi-cast</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cast-connected"></i>
                            <code>f119</code>
                            <span>mdi-cast-connected</span>
                        </div>
                        <div>
                            <i class="mdi mdi-castle"></i>
                            <code>f11a</code>
                            <span>mdi-castle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cat"></i>
                            <code>f11b</code>
                            <span>mdi-cat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone"></i>
                            <code>f11c</code>
                            <span>mdi-cellphone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-android"></i>
                            <code>f11d</code>
                            <span>mdi-cellphone-android</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-basic"></i>
                            <code>f11e</code>
                            <span>mdi-cellphone-basic</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-dock"></i>
                            <code>f11f</code>
                            <span>mdi-cellphone-dock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-iphone"></i>
                            <code>f120</code>
                            <span>mdi-cellphone-iphone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-link"></i>
                            <code>f121</code>
                            <span>mdi-cellphone-link</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-link-off"></i>
                            <code>f122</code>
                            <span>mdi-cellphone-link-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cellphone-settings"></i>
                            <code>f123</code>
                            <span>mdi-cellphone-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-certificate"></i>
                            <code>f124</code>
                            <span>mdi-certificate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chair-school"></i>
                            <code>f125</code>
                            <span>mdi-chair-school</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-arc"></i>
                            <code>f126</code>
                            <span>mdi-chart-arc</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-areaspline"></i>
                            <code>f127</code>
                            <span>mdi-chart-areaspline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-bar"></i>
                            <code>f128</code>
                            <span>mdi-chart-bar</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-bubble"></i>
                            <code>f5e3</code>
                            <span>mdi-chart-bubble</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-gantt"></i>
                            <code>f66c</code>
                            <span>mdi-chart-gantt</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-histogram"></i>
                            <code>f129</code>
                            <span>mdi-chart-histogram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-line"></i>
                            <code>f12a</code>
                            <span>mdi-chart-line</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-pie"></i>
                            <code>f12b</code>
                            <span>mdi-chart-pie</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-scatterplot-hexbin"></i>
                            <code>f66d</code>
                            <span>mdi-chart-scatterplot-hexbin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chart-timeline"></i>
                            <code>f66e</code>
                            <span>mdi-chart-timeline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-check"></i>
                            <code>f12c</code>
                            <span>mdi-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-check-all"></i>
                            <code>f12d</code>
                            <span>mdi-check-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-check-circle"></i>
                            <code>f5e0</code>
                            <span>mdi-check-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-check-circle-outline"></i>
                            <code>f5e1</code>
                            <span>mdi-check-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-blank"></i>
                            <code>f12e</code>
                            <span>mdi-checkbox-blank</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-blank-circle"></i>
                            <code>f12f</code>
                            <span>mdi-checkbox-blank-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-blank-circle-outline"></i>
                            <code>f130</code>
                            <span>mdi-checkbox-blank-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-blank-outline"></i>
                            <code>f131</code>
                            <span>mdi-checkbox-blank-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-marked"></i>
                            <code>f132</code>
                            <span>mdi-checkbox-marked</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-marked-circle"></i>
                            <code>f133</code>
                            <span>mdi-checkbox-marked-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                            <code>f134</code>
                            <span>mdi-checkbox-marked-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-marked-outline"></i>
                            <code>f135</code>
                            <span>mdi-checkbox-marked-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-blank"></i>
                            <code>f136</code>
                            <span>mdi-checkbox-multiple-blank</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-blank-circle"></i>
                            <code>f63b</code>
                            <span>mdi-checkbox-multiple-blank-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-blank-circle-outline"></i>
                            <code>f63c</code>
                            <span>mdi-checkbox-multiple-blank-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-blank-outline"></i>
                            <code>f137</code>
                            <span>mdi-checkbox-multiple-blank-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-marked"></i>
                            <code>f138</code>
                            <span>mdi-checkbox-multiple-marked</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-marked-circle"></i>
                            <code>f63d</code>
                            <span>mdi-checkbox-multiple-marked-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-marked-circle-outline"></i>
                            <code>f63e</code>
                            <span>mdi-checkbox-multiple-marked-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkbox-multiple-marked-outline"></i>
                            <code>f139</code>
                            <span>mdi-checkbox-multiple-marked-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-checkerboard"></i>
                            <code>f13a</code>
                            <span>mdi-checkerboard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chemical-weapon"></i>
                            <code>f13b</code>
                            <span>mdi-chemical-weapon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-double-down"></i>
                            <code>f13c</code>
                            <span>mdi-chevron-double-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-double-left"></i>
                            <code>f13d</code>
                            <span>mdi-chevron-double-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-double-right"></i>
                            <code>f13e</code>
                            <span>mdi-chevron-double-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-double-up"></i>
                            <code>f13f</code>
                            <span>mdi-chevron-double-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-down"></i>
                            <code>f140</code>
                            <span>mdi-chevron-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-left"></i>
                            <code>f141</code>
                            <span>mdi-chevron-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-right"></i>
                            <code>f142</code>
                            <span>mdi-chevron-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chevron-up"></i>
                            <code>f143</code>
                            <span>mdi-chevron-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-chip"></i>
                            <code>f61a</code>
                            <span>mdi-chip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-church"></i>
                            <code>f144</code>
                            <span>mdi-church</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cisco-webex"></i>
                            <code>f145</code>
                            <span>mdi-cisco-webex</span>
                        </div>
                        <div>
                            <i class="mdi mdi-city"></i>
                            <code>f146</code>
                            <span>mdi-city</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard"></i>
                            <code>f147</code>
                            <span>mdi-clipboard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-account"></i>
                            <code>f148</code>
                            <span>mdi-clipboard-account</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-alert"></i>
                            <code>f149</code>
                            <span>mdi-clipboard-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-arrow-down"></i>
                            <code>f14a</code>
                            <span>mdi-clipboard-arrow-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-arrow-left"></i>
                            <code>f14b</code>
                            <span>mdi-clipboard-arrow-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-check"></i>
                            <code>f14c</code>
                            <span>mdi-clipboard-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-flow"></i>
                            <code>f6c7</code>
                            <span>mdi-clipboard-flow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-outline"></i>
                            <code>f14d</code>
                            <span>mdi-clipboard-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clipboard-text"></i>
                            <code>f14e</code>
                            <span>mdi-clipboard-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clippy"></i>
                            <code>f14f</code>
                            <span>mdi-clippy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock"></i>
                            <code>f150</code>
                            <span>mdi-clock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-alert"></i>
                            <code>f5ce</code>
                            <span>mdi-clock-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-end"></i>
                            <code>f151</code>
                            <span>mdi-clock-end</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-fast"></i>
                            <code>f152</code>
                            <span>mdi-clock-fast</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-in"></i>
                            <code>f153</code>
                            <span>mdi-clock-in</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-out"></i>
                            <code>f154</code>
                            <span>mdi-clock-out</span>
                        </div>
                        <div>
                            <i class="mdi mdi-clock-start"></i>
                            <code>f155</code>
                            <span>mdi-clock-start</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close"></i>
                            <code>f156</code>
                            <span>mdi-close</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-box"></i>
                            <code>f157</code>
                            <span>mdi-close-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-box-outline"></i>
                            <code>f158</code>
                            <span>mdi-close-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-circle"></i>
                            <code>f159</code>
                            <span>mdi-close-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-circle-outline"></i>
                            <code>f15a</code>
                            <span>mdi-close-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-network"></i>
                            <code>f15b</code>
                            <span>mdi-close-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-octagon"></i>
                            <code>f15c</code>
                            <span>mdi-close-octagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-octagon-outline"></i>
                            <code>f15d</code>
                            <span>mdi-close-octagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-close-outline"></i>
                            <code>f6c8</code>
                            <span>mdi-close-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-closed-caption"></i>
                            <code>f15e</code>
                            <span>mdi-closed-caption</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud"></i>
                            <code>f15f</code>
                            <span>mdi-cloud</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-check"></i>
                            <code>f160</code>
                            <span>mdi-cloud-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-circle"></i>
                            <code>f161</code>
                            <span>mdi-cloud-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-download"></i>
                            <code>f162</code>
                            <span>mdi-cloud-download</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-outline"></i>
                            <code>f163</code>
                            <span>mdi-cloud-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-outline-off"></i>
                            <code>f164</code>
                            <span>mdi-cloud-outline-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-print"></i>
                            <code>f165</code>
                            <span>mdi-cloud-print</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-print-outline"></i>
                            <code>f166</code>
                            <span>mdi-cloud-print-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-sync"></i>
                            <code>f63f</code>
                            <span>mdi-cloud-sync</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cloud-upload"></i>
                            <code>f167</code>
                            <span>mdi-cloud-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-array"></i>
                            <code>f168</code>
                            <span>mdi-code-array</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-braces"></i>
                            <code>f169</code>
                            <span>mdi-code-braces</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-brackets"></i>
                            <code>f16a</code>
                            <span>mdi-code-brackets</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-equal"></i>
                            <code>f16b</code>
                            <span>mdi-code-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-greater-than"></i>
                            <code>f16c</code>
                            <span>mdi-code-greater-than</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-greater-than-or-equal"></i>
                            <code>f16d</code>
                            <span>mdi-code-greater-than-or-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-less-than"></i>
                            <code>f16e</code>
                            <span>mdi-code-less-than</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-less-than-or-equal"></i>
                            <code>f16f</code>
                            <span>mdi-code-less-than-or-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-not-equal"></i>
                            <code>f170</code>
                            <span>mdi-code-not-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-not-equal-variant"></i>
                            <code>f171</code>
                            <span>mdi-code-not-equal-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-parentheses"></i>
                            <code>f172</code>
                            <span>mdi-code-parentheses</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-string"></i>
                            <code>f173</code>
                            <span>mdi-code-string</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-tags"></i>
                            <code>f174</code>
                            <span>mdi-code-tags</span>
                        </div>
                        <div>
                            <i class="mdi mdi-code-tags-check"></i>
                            <code>f693</code>
                            <span>mdi-code-tags-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-codepen"></i>
                            <code>f175</code>
                            <span>mdi-codepen</span>
                        </div>
                        <div>
                            <i class="mdi mdi-coffee"></i>
                            <code>f176</code>
                            <span>mdi-coffee</span>
                        </div>
                        <div>
                            <i class="mdi mdi-coffee-outline"></i>
                            <code>f6c9</code>
                            <span>mdi-coffee-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-coffee-to-go"></i>
                            <code>f177</code>
                            <span>mdi-coffee-to-go</span>
                        </div>
                        <div>
                            <i class="mdi mdi-coin"></i>
                            <code>f178</code>
                            <span>mdi-coin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-coins"></i>
                            <code>f694</code>
                            <span>mdi-coins</span>
                        </div>
                        <div>
                            <i class="mdi mdi-collage"></i>
                            <code>f640</code>
                            <span>mdi-collage</span>
                        </div>
                        <div>
                            <i class="mdi mdi-color-helper"></i>
                            <code>f179</code>
                            <span>mdi-color-helper</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment"></i>
                            <code>f17a</code>
                            <span>mdi-comment</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-account"></i>
                            <code>f17b</code>
                            <span>mdi-comment-account</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-account-outline"></i>
                            <code>f17c</code>
                            <span>mdi-comment-account-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-alert"></i>
                            <code>f17d</code>
                            <span>mdi-comment-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-alert-outline"></i>
                            <code>f17e</code>
                            <span>mdi-comment-alert-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-check"></i>
                            <code>f17f</code>
                            <span>mdi-comment-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-check-outline"></i>
                            <code>f180</code>
                            <span>mdi-comment-check-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-multiple-outline"></i>
                            <code>f181</code>
                            <span>mdi-comment-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-outline"></i>
                            <code>f182</code>
                            <span>mdi-comment-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-plus-outline"></i>
                            <code>f183</code>
                            <span>mdi-comment-plus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-processing"></i>
                            <code>f184</code>
                            <span>mdi-comment-processing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-processing-outline"></i>
                            <code>f185</code>
                            <span>mdi-comment-processing-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-question-outline"></i>
                            <code>f186</code>
                            <span>mdi-comment-question-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-remove-outline"></i>
                            <code>f187</code>
                            <span>mdi-comment-remove-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-text"></i>
                            <code>f188</code>
                            <span>mdi-comment-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-comment-text-outline"></i>
                            <code>f189</code>
                            <span>mdi-comment-text-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-compare"></i>
                            <code>f18a</code>
                            <span>mdi-compare</span>
                        </div>
                        <div>
                            <i class="mdi mdi-compass"></i>
                            <code>f18b</code>
                            <span>mdi-compass</span>
                        </div>
                        <div>
                            <i class="mdi mdi-compass-outline"></i>
                            <code>f18c</code>
                            <span>mdi-compass-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-console"></i>
                            <code>f18d</code>
                            <span>mdi-console</span>
                        </div>
                        <div>
                            <i class="mdi mdi-contact-mail"></i>
                            <code>f18e</code>
                            <span>mdi-contact-mail</span>
                        </div>
                        <div>
                            <i class="mdi mdi-contacts"></i>
                            <code>f6ca</code>
                            <span>mdi-contacts</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-copy"></i>
                            <code>f18f</code>
                            <span>mdi-content-copy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-cut"></i>
                            <code>f190</code>
                            <span>mdi-content-cut</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-duplicate"></i>
                            <code>f191</code>
                            <span>mdi-content-duplicate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-paste"></i>
                            <code>f192</code>
                            <span>mdi-content-paste</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-save"></i>
                            <code>f193</code>
                            <span>mdi-content-save</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-save-all"></i>
                            <code>f194</code>
                            <span>mdi-content-save-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-content-save-settings"></i>
                            <code>f61b</code>
                            <span>mdi-content-save-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-contrast"></i>
                            <code>f195</code>
                            <span>mdi-contrast</span>
                        </div>
                        <div>
                            <i class="mdi mdi-contrast-box"></i>
                            <code>f196</code>
                            <span>mdi-contrast-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-contrast-circle"></i>
                            <code>f197</code>
                            <span>mdi-contrast-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cookie"></i>
                            <code>f198</code>
                            <span>mdi-cookie</span>
                        </div>
                        <div>
                            <i class="mdi mdi-copyright"></i>
                            <code>f5e6</code>
                            <span>mdi-copyright</span>
                        </div>
                        <div>
                            <i class="mdi mdi-counter"></i>
                            <code>f199</code>
                            <span>mdi-counter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cow"></i>
                            <code>f19a</code>
                            <span>mdi-cow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-creation"></i>
                            <code>f1c9</code>
                            <span>mdi-creation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-credit-card"></i>
                            <code>f19b</code>
                            <span>mdi-credit-card</span>
                        </div>
                        <div>
                            <i class="mdi mdi-credit-card-multiple"></i>
                            <code>f19c</code>
                            <span>mdi-credit-card-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-credit-card-off"></i>
                            <code>f5e4</code>
                            <span>mdi-credit-card-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-credit-card-plus"></i>
                            <code>f675</code>
                            <span>mdi-credit-card-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-credit-card-scan"></i>
                            <code>f19d</code>
                            <span>mdi-credit-card-scan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop"></i>
                            <code>f19e</code>
                            <span>mdi-crop</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop-free"></i>
                            <code>f19f</code>
                            <span>mdi-crop-free</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop-landscape"></i>
                            <code>f1a0</code>
                            <span>mdi-crop-landscape</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop-portrait"></i>
                            <code>f1a1</code>
                            <span>mdi-crop-portrait</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop-rotate"></i>
                            <code>f695</code>
                            <span>mdi-crop-rotate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crop-square"></i>
                            <code>f1a2</code>
                            <span>mdi-crop-square</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crosshairs"></i>
                            <code>f1a3</code>
                            <span>mdi-crosshairs</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crosshairs-gps"></i>
                            <code>f1a4</code>
                            <span>mdi-crosshairs-gps</span>
                        </div>
                        <div>
                            <i class="mdi mdi-crown"></i>
                            <code>f1a5</code>
                            <span>mdi-crown</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cube"></i>
                            <code>f1a6</code>
                            <span>mdi-cube</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cube-outline"></i>
                            <code>f1a7</code>
                            <span>mdi-cube-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cube-send"></i>
                            <code>f1a8</code>
                            <span>mdi-cube-send</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cube-unfolded"></i>
                            <code>f1a9</code>
                            <span>mdi-cube-unfolded</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cup"></i>
                            <code>f1aa</code>
                            <span>mdi-cup</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cup-off"></i>
                            <code>f5e5</code>
                            <span>mdi-cup-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cup-water"></i>
                            <code>f1ab</code>
                            <span>mdi-cup-water</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-btc"></i>
                            <code>f1ac</code>
                            <span>mdi-currency-btc</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-eur"></i>
                            <code>f1ad</code>
                            <span>mdi-currency-eur</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-gbp"></i>
                            <code>f1ae</code>
                            <span>mdi-currency-gbp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-inr"></i>
                            <code>f1af</code>
                            <span>mdi-currency-inr</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-ngn"></i>
                            <code>f1b0</code>
                            <span>mdi-currency-ngn</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-rub"></i>
                            <code>f1b1</code>
                            <span>mdi-currency-rub</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-try"></i>
                            <code>f1b2</code>
                            <span>mdi-currency-try</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-usd"></i>
                            <code>f1b3</code>
                            <span>mdi-currency-usd</span>
                        </div>
                        <div>
                            <i class="mdi mdi-currency-usd-off"></i>
                            <code>f679</code>
                            <span>mdi-currency-usd-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cursor-default"></i>
                            <code>f1b4</code>
                            <span>mdi-cursor-default</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cursor-default-outline"></i>
                            <code>f1b5</code>
                            <span>mdi-cursor-default-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cursor-move"></i>
                            <code>f1b6</code>
                            <span>mdi-cursor-move</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cursor-pointer"></i>
                            <code>f1b7</code>
                            <span>mdi-cursor-pointer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-cursor-text"></i>
                            <code>f5e7</code>
                            <span>mdi-cursor-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-database"></i>
                            <code>f1b8</code>
                            <span>mdi-database</span>
                        </div>
                        <div>
                            <i class="mdi mdi-database-minus"></i>
                            <code>f1b9</code>
                            <span>mdi-database-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-database-plus"></i>
                            <code>f1ba</code>
                            <span>mdi-database-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-debug-step-into"></i>
                            <code>f1bb</code>
                            <span>mdi-debug-step-into</span>
                        </div>
                        <div>
                            <i class="mdi mdi-debug-step-out"></i>
                            <code>f1bc</code>
                            <span>mdi-debug-step-out</span>
                        </div>
                        <div>
                            <i class="mdi mdi-debug-step-over"></i>
                            <code>f1bd</code>
                            <span>mdi-debug-step-over</span>
                        </div>
                        <div>
                            <i class="mdi mdi-decimal-decrease"></i>
                            <code>f1be</code>
                            <span>mdi-decimal-decrease</span>
                        </div>
                        <div>
                            <i class="mdi mdi-decimal-increase"></i>
                            <code>f1bf</code>
                            <span>mdi-decimal-increase</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete"></i>
                            <code>f1c0</code>
                            <span>mdi-delete</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete-circle"></i>
                            <code>f682</code>
                            <span>mdi-delete-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete-empty"></i>
                            <code>f6cb</code>
                            <span>mdi-delete-empty</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete-forever"></i>
                            <code>f5e8</code>
                            <span>mdi-delete-forever</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete-sweep"></i>
                            <code>f5e9</code>
                            <span>mdi-delete-sweep</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delete-variant"></i>
                            <code>f1c1</code>
                            <span>mdi-delete-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-delta"></i>
                            <code>f1c2</code>
                            <span>mdi-delta</span>
                        </div>
                        <div>
                            <i class="mdi mdi-deskphone"></i>
                            <code>f1c3</code>
                            <span>mdi-deskphone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-desktop-mac"></i>
                            <code>f1c4</code>
                            <span>mdi-desktop-mac</span>
                        </div>
                        <div>
                            <i class="mdi mdi-desktop-tower"></i>
                            <code>f1c5</code>
                            <span>mdi-desktop-tower</span>
                        </div>
                        <div>
                            <i class="mdi mdi-details"></i>
                            <code>f1c6</code>
                            <span>mdi-details</span>
                        </div>
                        <div>
                            <i class="mdi mdi-developer-board"></i>
                            <code>f696</code>
                            <span>mdi-developer-board</span>
                        </div>
                        <div>
                            <i class="mdi mdi-deviantart"></i>
                            <code>f1c7</code>
                            <span>mdi-deviantart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dialpad"></i>
                            <code>f61c</code>
                            <span>mdi-dialpad</span>
                        </div>
                        <div>
                            <i class="mdi mdi-diamond"></i>
                            <code>f1c8</code>
                            <span>mdi-diamond</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-1"></i>
                            <code>f1ca</code>
                            <span>mdi-dice-1</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-2"></i>
                            <code>f1cb</code>
                            <span>mdi-dice-2</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-3"></i>
                            <code>f1cc</code>
                            <span>mdi-dice-3</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-4"></i>
                            <code>f1cd</code>
                            <span>mdi-dice-4</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-5"></i>
                            <code>f1ce</code>
                            <span>mdi-dice-5</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-6"></i>
                            <code>f1cf</code>
                            <span>mdi-dice-6</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-d20"></i>
                            <code>f5ea</code>
                            <span>mdi-dice-d20</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-d4"></i>
                            <code>f5eb</code>
                            <span>mdi-dice-d4</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-d6"></i>
                            <code>f5ec</code>
                            <span>mdi-dice-d6</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dice-d8"></i>
                            <code>f5ed</code>
                            <span>mdi-dice-d8</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dictionary"></i>
                            <code>f61d</code>
                            <span>mdi-dictionary</span>
                        </div>
                        <div>
                            <i class="mdi mdi-directions"></i>
                            <code>f1d0</code>
                            <span>mdi-directions</span>
                        </div>
                        <div>
                            <i class="mdi mdi-directions-fork"></i>
                            <code>f641</code>
                            <span>mdi-directions-fork</span>
                        </div>
                        <div>
                            <i class="mdi mdi-discord"></i>
                            <code>f66f</code>
                            <span>mdi-discord</span>
                        </div>
                        <div>
                            <i class="mdi mdi-disk"></i>
                            <code>f5ee</code>
                            <span>mdi-disk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-disk-alert"></i>
                            <code>f1d1</code>
                            <span>mdi-disk-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-disqus"></i>
                            <code>f1d2</code>
                            <span>mdi-disqus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-disqus-outline"></i>
                            <code>f1d3</code>
                            <span>mdi-disqus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-division"></i>
                            <code>f1d4</code>
                            <span>mdi-division</span>
                        </div>
                        <div>
                            <i class="mdi mdi-division-box"></i>
                            <code>f1d5</code>
                            <span>mdi-division-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dna"></i>
                            <code>f683</code>
                            <span>mdi-dna</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dns"></i>
                            <code>f1d6</code>
                            <span>mdi-dns</span>
                        </div>
                        <div>
                            <i class="mdi mdi-do-not-disturb"></i>
                            <code>f697</code>
                            <span>mdi-do-not-disturb</span>
                        </div>
                        <div>
                            <i class="mdi mdi-do-not-disturb-off"></i>
                            <code>f698</code>
                            <span>mdi-do-not-disturb-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dolby"></i>
                            <code>f6b2</code>
                            <span>mdi-dolby</span>
                        </div>
                        <div>
                            <i class="mdi mdi-domain"></i>
                            <code>f1d7</code>
                            <span>mdi-domain</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dots-horizontal"></i>
                            <code>f1d8</code>
                            <span>mdi-dots-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dots-vertical"></i>
                            <code>f1d9</code>
                            <span>mdi-dots-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-douban"></i>
                            <code>f699</code>
                            <span>mdi-douban</span>
                        </div>
                        <div>
                            <i class="mdi mdi-download"></i>
                            <code>f1da</code>
                            <span>mdi-download</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drag"></i>
                            <code>f1db</code>
                            <span>mdi-drag</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drag-horizontal"></i>
                            <code>f1dc</code>
                            <span>mdi-drag-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drag-vertical"></i>
                            <code>f1dd</code>
                            <span>mdi-drag-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drawing"></i>
                            <code>f1de</code>
                            <span>mdi-drawing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drawing-box"></i>
                            <code>f1df</code>
                            <span>mdi-drawing-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dribbble"></i>
                            <code>f1e0</code>
                            <span>mdi-dribbble</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dribbble-box"></i>
                            <code>f1e1</code>
                            <span>mdi-dribbble-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drone"></i>
                            <code>f1e2</code>
                            <span>mdi-drone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dropbox"></i>
                            <code>f1e3</code>
                            <span>mdi-dropbox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-drupal"></i>
                            <code>f1e4</code>
                            <span>mdi-drupal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-duck"></i>
                            <code>f1e5</code>
                            <span>mdi-duck</span>
                        </div>
                        <div>
                            <i class="mdi mdi-dumbbell"></i>
                            <code>f1e6</code>
                            <span>mdi-dumbbell</span>
                        </div>
                        <div>
                            <i class="mdi mdi-earth"></i>
                            <code>f1e7</code>
                            <span>mdi-earth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-earth-box"></i>
                            <code>f6cc</code>
                            <span>mdi-earth-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-earth-box-off"></i>
                            <code>f6cd</code>
                            <span>mdi-earth-box-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-earth-off"></i>
                            <code>f1e8</code>
                            <span>mdi-earth-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-edge"></i>
                            <code>f1e9</code>
                            <span>mdi-edge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eject"></i>
                            <code>f1ea</code>
                            <span>mdi-eject</span>
                        </div>
                        <div>
                            <i class="mdi mdi-elevation-decline"></i>
                            <code>f1eb</code>
                            <span>mdi-elevation-decline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-elevation-rise"></i>
                            <code>f1ec</code>
                            <span>mdi-elevation-rise</span>
                        </div>
                        <div>
                            <i class="mdi mdi-elevator"></i>
                            <code>f1ed</code>
                            <span>mdi-elevator</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email"></i>
                            <code>f1ee</code>
                            <span>mdi-email</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-alert"></i>
                            <code>f6ce</code>
                            <span>mdi-email-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-open"></i>
                            <code>f1ef</code>
                            <span>mdi-email-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-open-outline"></i>
                            <code>f5ef</code>
                            <span>mdi-email-open-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-outline"></i>
                            <code>f1f0</code>
                            <span>mdi-email-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-secure"></i>
                            <code>f1f1</code>
                            <span>mdi-email-secure</span>
                        </div>
                        <div>
                            <i class="mdi mdi-email-variant"></i>
                            <code>f5f0</code>
                            <span>mdi-email-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emby"></i>
                            <code>f6b3</code>
                            <span>mdi-emby</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon"></i>
                            <code>f1f2</code>
                            <span>mdi-emoticon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-cool"></i>
                            <code>f1f3</code>
                            <span>mdi-emoticon-cool</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-dead"></i>
                            <code>f69a</code>
                            <span>mdi-emoticon-dead</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-devil"></i>
                            <code>f1f4</code>
                            <span>mdi-emoticon-devil</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-excited"></i>
                            <code>f69b</code>
                            <span>mdi-emoticon-excited</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-happy"></i>
                            <code>f1f5</code>
                            <span>mdi-emoticon-happy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-neutral"></i>
                            <code>f1f6</code>
                            <span>mdi-emoticon-neutral</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-poop"></i>
                            <code>f1f7</code>
                            <span>mdi-emoticon-poop</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-sad"></i>
                            <code>f1f8</code>
                            <span>mdi-emoticon-sad</span>
                        </div>
                        <div>
                            <i class="mdi mdi-emoticon-tongue"></i>
                            <code>f1f9</code>
                            <span>mdi-emoticon-tongue</span>
                        </div>
                        <div>
                            <i class="mdi mdi-engine"></i>
                            <code>f1fa</code>
                            <span>mdi-engine</span>
                        </div>
                        <div>
                            <i class="mdi mdi-engine-outline"></i>
                            <code>f1fb</code>
                            <span>mdi-engine-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-equal"></i>
                            <code>f1fc</code>
                            <span>mdi-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-equal-box"></i>
                            <code>f1fd</code>
                            <span>mdi-equal-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eraser"></i>
                            <code>f1fe</code>
                            <span>mdi-eraser</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eraser-variant"></i>
                            <code>f642</code>
                            <span>mdi-eraser-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-escalator"></i>
                            <code>f1ff</code>
                            <span>mdi-escalator</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ethernet"></i>
                            <code>f200</code>
                            <span>mdi-ethernet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ethernet-cable"></i>
                            <code>f201</code>
                            <span>mdi-ethernet-cable</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ethernet-cable-off"></i>
                            <code>f202</code>
                            <span>mdi-ethernet-cable-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-etsy"></i>
                            <code>f203</code>
                            <span>mdi-etsy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ev-station"></i>
                            <code>f5f1</code>
                            <span>mdi-ev-station</span>
                        </div>
                        <div>
                            <i class="mdi mdi-evernote"></i>
                            <code>f204</code>
                            <span>mdi-evernote</span>
                        </div>
                        <div>
                            <i class="mdi mdi-exclamation"></i>
                            <code>f205</code>
                            <span>mdi-exclamation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-exit-to-app"></i>
                            <code>f206</code>
                            <span>mdi-exit-to-app</span>
                        </div>
                        <div>
                            <i class="mdi mdi-export"></i>
                            <code>f207</code>
                            <span>mdi-export</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eye"></i>
                            <code>f208</code>
                            <span>mdi-eye</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eye-off"></i>
                            <code>f209</code>
                            <span>mdi-eye-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eye-outline"></i>
                            <code>f6cf</code>
                            <span>mdi-eye-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eye-outline-off"></i>
                            <code>f6d0</code>
                            <span>mdi-eye-outline-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eyedropper"></i>
                            <code>f20a</code>
                            <span>mdi-eyedropper</span>
                        </div>
                        <div>
                            <i class="mdi mdi-eyedropper-variant"></i>
                            <code>f20b</code>
                            <span>mdi-eyedropper-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-face"></i>
                            <code>f643</code>
                            <span>mdi-face</span>
                        </div>
                        <div>
                            <i class="mdi mdi-face-profile"></i>
                            <code>f644</code>
                            <span>mdi-face-profile</span>
                        </div>
                        <div>
                            <i class="mdi mdi-facebook"></i>
                            <code>f20c</code>
                            <span>mdi-facebook</span>
                        </div>
                        <div>
                            <i class="mdi mdi-facebook-box"></i>
                            <code>f20d</code>
                            <span>mdi-facebook-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-facebook-messenger"></i>
                            <code>f20e</code>
                            <span>mdi-facebook-messenger</span>
                        </div>
                        <div>
                            <i class="mdi mdi-factory"></i>
                            <code>f20f</code>
                            <span>mdi-factory</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fan"></i>
                            <code>f210</code>
                            <span>mdi-fan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fast-forward"></i>
                            <code>f211</code>
                            <span>mdi-fast-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fast-forward-outline"></i>
                            <code>f6d1</code>
                            <span>mdi-fast-forward-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fax"></i>
                            <code>f212</code>
                            <span>mdi-fax</span>
                        </div>
                        <div>
                            <i class="mdi mdi-feather"></i>
                            <code>f6d2</code>
                            <span>mdi-feather</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ferry"></i>
                            <code>f213</code>
                            <span>mdi-ferry</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file"></i>
                            <code>f214</code>
                            <span>mdi-file</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-chart"></i>
                            <code>f215</code>
                            <span>mdi-file-chart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-check"></i>
                            <code>f216</code>
                            <span>mdi-file-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-cloud"></i>
                            <code>f217</code>
                            <span>mdi-file-cloud</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-delimited"></i>
                            <code>f218</code>
                            <span>mdi-file-delimited</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-document"></i>
                            <code>f219</code>
                            <span>mdi-file-document</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-document-box"></i>
                            <code>f21a</code>
                            <span>mdi-file-document-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-excel"></i>
                            <code>f21b</code>
                            <span>mdi-file-excel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-excel-box"></i>
                            <code>f21c</code>
                            <span>mdi-file-excel-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-export"></i>
                            <code>f21d</code>
                            <span>mdi-file-export</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-find"></i>
                            <code>f21e</code>
                            <span>mdi-file-find</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-hidden"></i>
                            <code>f613</code>
                            <span>mdi-file-hidden</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-image"></i>
                            <code>f21f</code>
                            <span>mdi-file-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-import"></i>
                            <code>f220</code>
                            <span>mdi-file-import</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-lock"></i>
                            <code>f221</code>
                            <span>mdi-file-lock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-multiple"></i>
                            <code>f222</code>
                            <span>mdi-file-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-music"></i>
                            <code>f223</code>
                            <span>mdi-file-music</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-outline"></i>
                            <code>f224</code>
                            <span>mdi-file-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-pdf"></i>
                            <code>f225</code>
                            <span>mdi-file-pdf</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-pdf-box"></i>
                            <code>f226</code>
                            <span>mdi-file-pdf-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-powerpoint"></i>
                            <code>f227</code>
                            <span>mdi-file-powerpoint</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-powerpoint-box"></i>
                            <code>f228</code>
                            <span>mdi-file-powerpoint-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-presentation-box"></i>
                            <code>f229</code>
                            <span>mdi-file-presentation-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-restore"></i>
                            <code>f670</code>
                            <span>mdi-file-restore</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-send"></i>
                            <code>f22a</code>
                            <span>mdi-file-send</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-tree"></i>
                            <code>f645</code>
                            <span>mdi-file-tree</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-video"></i>
                            <code>f22b</code>
                            <span>mdi-file-video</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-word"></i>
                            <code>f22c</code>
                            <span>mdi-file-word</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-word-box"></i>
                            <code>f22d</code>
                            <span>mdi-file-word-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-file-xml"></i>
                            <code>f22e</code>
                            <span>mdi-file-xml</span>
                        </div>
                        <div>
                            <i class="mdi mdi-film"></i>
                            <code>f22f</code>
                            <span>mdi-film</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filmstrip"></i>
                            <code>f230</code>
                            <span>mdi-filmstrip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filmstrip-off"></i>
                            <code>f231</code>
                            <span>mdi-filmstrip-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filter"></i>
                            <code>f232</code>
                            <span>mdi-filter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filter-outline"></i>
                            <code>f233</code>
                            <span>mdi-filter-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filter-remove"></i>
                            <code>f234</code>
                            <span>mdi-filter-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filter-remove-outline"></i>
                            <code>f235</code>
                            <span>mdi-filter-remove-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-filter-variant"></i>
                            <code>f236</code>
                            <span>mdi-filter-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-find-replace"></i>
                            <code>f6d3</code>
                            <span>mdi-find-replace</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fingerprint"></i>
                            <code>f237</code>
                            <span>mdi-fingerprint</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fire"></i>
                            <code>f238</code>
                            <span>mdi-fire</span>
                        </div>
                        <div>
                            <i class="mdi mdi-firefox"></i>
                            <code>f239</code>
                            <span>mdi-firefox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fish"></i>
                            <code>f23a</code>
                            <span>mdi-fish</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag"></i>
                            <code>f23b</code>
                            <span>mdi-flag</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag-checkered"></i>
                            <code>f23c</code>
                            <span>mdi-flag-checkered</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag-outline"></i>
                            <code>f23d</code>
                            <span>mdi-flag-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag-outline-variant"></i>
                            <code>f23e</code>
                            <span>mdi-flag-outline-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag-triangle"></i>
                            <code>f23f</code>
                            <span>mdi-flag-triangle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flag-variant"></i>
                            <code>f240</code>
                            <span>mdi-flag-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flash"></i>
                            <code>f241</code>
                            <span>mdi-flash</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flash-auto"></i>
                            <code>f242</code>
                            <span>mdi-flash-auto</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flash-off"></i>
                            <code>f243</code>
                            <span>mdi-flash-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flash-outline"></i>
                            <code>f6d4</code>
                            <span>mdi-flash-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flash-red-eye"></i>
                            <code>f67a</code>
                            <span>mdi-flash-red-eye</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flashlight"></i>
                            <code>f244</code>
                            <span>mdi-flashlight</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flashlight-off"></i>
                            <code>f245</code>
                            <span>mdi-flashlight-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flask"></i>
                            <code>f093</code>
                            <span>mdi-flask</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flask-empty"></i>
                            <code>f094</code>
                            <span>mdi-flask-empty</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flask-empty-outline"></i>
                            <code>f095</code>
                            <span>mdi-flask-empty-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flask-outline"></i>
                            <code>f096</code>
                            <span>mdi-flask-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flattr"></i>
                            <code>f246</code>
                            <span>mdi-flattr</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flip-to-back"></i>
                            <code>f247</code>
                            <span>mdi-flip-to-back</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flip-to-front"></i>
                            <code>f248</code>
                            <span>mdi-flip-to-front</span>
                        </div>
                        <div>
                            <i class="mdi mdi-floppy"></i>
                            <code>f249</code>
                            <span>mdi-floppy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-flower"></i>
                            <code>f24a</code>
                            <span>mdi-flower</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder"></i>
                            <code>f24b</code>
                            <span>mdi-folder</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-account"></i>
                            <code>f24c</code>
                            <span>mdi-folder-account</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-download"></i>
                            <code>f24d</code>
                            <span>mdi-folder-download</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-google-drive"></i>
                            <code>f24e</code>
                            <span>mdi-folder-google-drive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-image"></i>
                            <code>f24f</code>
                            <span>mdi-folder-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-lock"></i>
                            <code>f250</code>
                            <span>mdi-folder-lock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-lock-open"></i>
                            <code>f251</code>
                            <span>mdi-folder-lock-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-move"></i>
                            <code>f252</code>
                            <span>mdi-folder-move</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-multiple"></i>
                            <code>f253</code>
                            <span>mdi-folder-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-multiple-image"></i>
                            <code>f254</code>
                            <span>mdi-folder-multiple-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-multiple-outline"></i>
                            <code>f255</code>
                            <span>mdi-folder-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-outline"></i>
                            <code>f256</code>
                            <span>mdi-folder-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-plus"></i>
                            <code>f257</code>
                            <span>mdi-folder-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-remove"></i>
                            <code>f258</code>
                            <span>mdi-folder-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-star"></i>
                            <code>f69c</code>
                            <span>mdi-folder-star</span>
                        </div>
                        <div>
                            <i class="mdi mdi-folder-upload"></i>
                            <code>f259</code>
                            <span>mdi-folder-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-font-awesome"></i>
                            <code>f03a</code>
                            <span>mdi-font-awesome</span>
                        </div>
                        <div>
                            <i class="mdi mdi-food"></i>
                            <code>f25a</code>
                            <span>mdi-food</span>
                        </div>
                        <div>
                            <i class="mdi mdi-food-apple"></i>
                            <code>f25b</code>
                            <span>mdi-food-apple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-food-fork-drink"></i>
                            <code>f5f2</code>
                            <span>mdi-food-fork-drink</span>
                        </div>
                        <div>
                            <i class="mdi mdi-food-off"></i>
                            <code>f5f3</code>
                            <span>mdi-food-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-food-variant"></i>
                            <code>f25c</code>
                            <span>mdi-food-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-football"></i>
                            <code>f25d</code>
                            <span>mdi-football</span>
                        </div>
                        <div>
                            <i class="mdi mdi-football-australian"></i>
                            <code>f25e</code>
                            <span>mdi-football-australian</span>
                        </div>
                        <div>
                            <i class="mdi mdi-football-helmet"></i>
                            <code>f25f</code>
                            <span>mdi-football-helmet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-align-center"></i>
                            <code>f260</code>
                            <span>mdi-format-align-center</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-align-justify"></i>
                            <code>f261</code>
                            <span>mdi-format-align-justify</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-align-left"></i>
                            <code>f262</code>
                            <span>mdi-format-align-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-align-right"></i>
                            <code>f263</code>
                            <span>mdi-format-align-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-annotation-plus"></i>
                            <code>f646</code>
                            <span>mdi-format-annotation-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-bold"></i>
                            <code>f264</code>
                            <span>mdi-format-bold</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-clear"></i>
                            <code>f265</code>
                            <span>mdi-format-clear</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-color-fill"></i>
                            <code>f266</code>
                            <span>mdi-format-color-fill</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-color-text"></i>
                            <code>f69d</code>
                            <span>mdi-format-color-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-float-center"></i>
                            <code>f267</code>
                            <span>mdi-format-float-center</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-float-left"></i>
                            <code>f268</code>
                            <span>mdi-format-float-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-float-none"></i>
                            <code>f269</code>
                            <span>mdi-format-float-none</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-float-right"></i>
                            <code>f26a</code>
                            <span>mdi-format-float-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-font"></i>
                            <code>f6d5</code>
                            <span>mdi-format-font</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-1"></i>
                            <code>f26b</code>
                            <span>mdi-format-header-1</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-2"></i>
                            <code>f26c</code>
                            <span>mdi-format-header-2</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-3"></i>
                            <code>f26d</code>
                            <span>mdi-format-header-3</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-4"></i>
                            <code>f26e</code>
                            <span>mdi-format-header-4</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-5"></i>
                            <code>f26f</code>
                            <span>mdi-format-header-5</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-6"></i>
                            <code>f270</code>
                            <span>mdi-format-header-6</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-decrease"></i>
                            <code>f271</code>
                            <span>mdi-format-header-decrease</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-equal"></i>
                            <code>f272</code>
                            <span>mdi-format-header-equal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-increase"></i>
                            <code>f273</code>
                            <span>mdi-format-header-increase</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-header-pound"></i>
                            <code>f274</code>
                            <span>mdi-format-header-pound</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-horizontal-align-center"></i>
                            <code>f61e</code>
                            <span>mdi-format-horizontal-align-center</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-horizontal-align-left"></i>
                            <code>f61f</code>
                            <span>mdi-format-horizontal-align-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-horizontal-align-right"></i>
                            <code>f620</code>
                            <span>mdi-format-horizontal-align-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-indent-decrease"></i>
                            <code>f275</code>
                            <span>mdi-format-indent-decrease</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-indent-increase"></i>
                            <code>f276</code>
                            <span>mdi-format-indent-increase</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-italic"></i>
                            <code>f277</code>
                            <span>mdi-format-italic</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-line-spacing"></i>
                            <code>f278</code>
                            <span>mdi-format-line-spacing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-line-style"></i>
                            <code>f5c8</code>
                            <span>mdi-format-line-style</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-line-weight"></i>
                            <code>f5c9</code>
                            <span>mdi-format-line-weight</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-list-bulleted"></i>
                            <code>f279</code>
                            <span>mdi-format-list-bulleted</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-list-bulleted-type"></i>
                            <code>f27a</code>
                            <span>mdi-format-list-bulleted-type</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-list-numbers"></i>
                            <code>f27b</code>
                            <span>mdi-format-list-numbers</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-page-break"></i>
                            <code>f6d6</code>
                            <span>mdi-format-page-break</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-paint"></i>
                            <code>f27c</code>
                            <span>mdi-format-paint</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-paragraph"></i>
                            <code>f27d</code>
                            <span>mdi-format-paragraph</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-pilcrow"></i>
                            <code>f6d7</code>
                            <span>mdi-format-pilcrow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-quote"></i>
                            <code>f27e</code>
                            <span>mdi-format-quote</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-rotate-90"></i>
                            <code>f6a9</code>
                            <span>mdi-format-rotate-90</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-section"></i>
                            <code>f69e</code>
                            <span>mdi-format-section</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-size"></i>
                            <code>f27f</code>
                            <span>mdi-format-size</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-strikethrough"></i>
                            <code>f280</code>
                            <span>mdi-format-strikethrough</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-strikethrough-variant"></i>
                            <code>f281</code>
                            <span>mdi-format-strikethrough-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-subscript"></i>
                            <code>f282</code>
                            <span>mdi-format-subscript</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-superscript"></i>
                            <code>f283</code>
                            <span>mdi-format-superscript</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-text"></i>
                            <code>f284</code>
                            <span>mdi-format-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-textdirection-l-to-r"></i>
                            <code>f285</code>
                            <span>mdi-format-textdirection-l-to-r</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-textdirection-r-to-l"></i>
                            <code>f286</code>
                            <span>mdi-format-textdirection-r-to-l</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-title"></i>
                            <code>f5f4</code>
                            <span>mdi-format-title</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-underline"></i>
                            <code>f287</code>
                            <span>mdi-format-underline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-vertical-align-bottom"></i>
                            <code>f621</code>
                            <span>mdi-format-vertical-align-bottom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-vertical-align-center"></i>
                            <code>f622</code>
                            <span>mdi-format-vertical-align-center</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-vertical-align-top"></i>
                            <code>f623</code>
                            <span>mdi-format-vertical-align-top</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-wrap-inline"></i>
                            <code>f288</code>
                            <span>mdi-format-wrap-inline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-wrap-square"></i>
                            <code>f289</code>
                            <span>mdi-format-wrap-square</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-wrap-tight"></i>
                            <code>f28a</code>
                            <span>mdi-format-wrap-tight</span>
                        </div>
                        <div>
                            <i class="mdi mdi-format-wrap-top-bottom"></i>
                            <code>f28b</code>
                            <span>mdi-format-wrap-top-bottom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-forum"></i>
                            <code>f28c</code>
                            <span>mdi-forum</span>
                        </div>
                        <div>
                            <i class="mdi mdi-forward"></i>
                            <code>f28d</code>
                            <span>mdi-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-foursquare"></i>
                            <code>f28e</code>
                            <span>mdi-foursquare</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fridge"></i>
                            <code>f28f</code>
                            <span>mdi-fridge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fridge-filled"></i>
                            <code>f290</code>
                            <span>mdi-fridge-filled</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fridge-filled-bottom"></i>
                            <code>f291</code>
                            <span>mdi-fridge-filled-bottom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fridge-filled-top"></i>
                            <code>f292</code>
                            <span>mdi-fridge-filled-top</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fullscreen"></i>
                            <code>f293</code>
                            <span>mdi-fullscreen</span>
                        </div>
                        <div>
                            <i class="mdi mdi-fullscreen-exit"></i>
                            <code>f294</code>
                            <span>mdi-fullscreen-exit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-function"></i>
                            <code>f295</code>
                            <span>mdi-function</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gamepad"></i>
                            <code>f296</code>
                            <span>mdi-gamepad</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gamepad-variant"></i>
                            <code>f297</code>
                            <span>mdi-gamepad-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-garage"></i>
                            <code>f6d8</code>
                            <span>mdi-garage</span>
                        </div>
                        <div>
                            <i class="mdi mdi-garage-open"></i>
                            <code>f6d9</code>
                            <span>mdi-garage-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gas-cylinder"></i>
                            <code>f647</code>
                            <span>mdi-gas-cylinder</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gas-station"></i>
                            <code>f298</code>
                            <span>mdi-gas-station</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gate"></i>
                            <code>f299</code>
                            <span>mdi-gate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gauge"></i>
                            <code>f29a</code>
                            <span>mdi-gauge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gavel"></i>
                            <code>f29b</code>
                            <span>mdi-gavel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gender-female"></i>
                            <code>f29c</code>
                            <span>mdi-gender-female</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gender-male"></i>
                            <code>f29d</code>
                            <span>mdi-gender-male</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gender-male-female"></i>
                            <code>f29e</code>
                            <span>mdi-gender-male-female</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gender-transgender"></i>
                            <code>f29f</code>
                            <span>mdi-gender-transgender</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ghost"></i>
                            <code>f2a0</code>
                            <span>mdi-ghost</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gift"></i>
                            <code>f2a1</code>
                            <span>mdi-gift</span>
                        </div>
                        <div>
                            <i class="mdi mdi-git"></i>
                            <code>f2a2</code>
                            <span>mdi-git</span>
                        </div>
                        <div>
                            <i class="mdi mdi-github-box"></i>
                            <code>f2a3</code>
                            <span>mdi-github-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-github-circle"></i>
                            <code>f2a4</code>
                            <span>mdi-github-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-github-face"></i>
                            <code>f6da</code>
                            <span>mdi-github-face</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glass-flute"></i>
                            <code>f2a5</code>
                            <span>mdi-glass-flute</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glass-mug"></i>
                            <code>f2a6</code>
                            <span>mdi-glass-mug</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glass-stange"></i>
                            <code>f2a7</code>
                            <span>mdi-glass-stange</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glass-tulip"></i>
                            <code>f2a8</code>
                            <span>mdi-glass-tulip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glassdoor"></i>
                            <code>f2a9</code>
                            <span>mdi-glassdoor</span>
                        </div>
                        <div>
                            <i class="mdi mdi-glasses"></i>
                            <code>f2aa</code>
                            <span>mdi-glasses</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gmail"></i>
                            <code>f2ab</code>
                            <span>mdi-gmail</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gnome"></i>
                            <code>f2ac</code>
                            <span>mdi-gnome</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gondola"></i>
                            <code>f685</code>
                            <span>mdi-gondola</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google"></i>
                            <code>f2ad</code>
                            <span>mdi-google</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-cardboard"></i>
                            <code>f2ae</code>
                            <span>mdi-google-cardboard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-chrome"></i>
                            <code>f2af</code>
                            <span>mdi-google-chrome</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-circles"></i>
                            <code>f2b0</code>
                            <span>mdi-google-circles</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-circles-communities"></i>
                            <code>f2b1</code>
                            <span>mdi-google-circles-communities</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-circles-extended"></i>
                            <code>f2b2</code>
                            <span>mdi-google-circles-extended</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-circles-group"></i>
                            <code>f2b3</code>
                            <span>mdi-google-circles-group</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-controller"></i>
                            <code>f2b4</code>
                            <span>mdi-google-controller</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-controller-off"></i>
                            <code>f2b5</code>
                            <span>mdi-google-controller-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-drive"></i>
                            <code>f2b6</code>
                            <span>mdi-google-drive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-earth"></i>
                            <code>f2b7</code>
                            <span>mdi-google-earth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-glass"></i>
                            <code>f2b8</code>
                            <span>mdi-google-glass</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-keep"></i>
                            <code>f6db</code>
                            <span>mdi-google-keep</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-maps"></i>
                            <code>f5f5</code>
                            <span>mdi-google-maps</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-nearby"></i>
                            <code>f2b9</code>
                            <span>mdi-google-nearby</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-pages"></i>
                            <code>f2ba</code>
                            <span>mdi-google-pages</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-photos"></i>
                            <code>f6dc</code>
                            <span>mdi-google-photos</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-physical-web"></i>
                            <code>f2bb</code>
                            <span>mdi-google-physical-web</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-play"></i>
                            <code>f2bc</code>
                            <span>mdi-google-play</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-plus"></i>
                            <code>f2bd</code>
                            <span>mdi-google-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-plus-box"></i>
                            <code>f2be</code>
                            <span>mdi-google-plus-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-translate"></i>
                            <code>f2bf</code>
                            <span>mdi-google-translate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-google-wallet"></i>
                            <code>f2c0</code>
                            <span>mdi-google-wallet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-gradient"></i>
                            <code>f69f</code>
                            <span>mdi-gradient</span>
                        </div>
                        <div>
                            <i class="mdi mdi-grease-pencil"></i>
                            <code>f648</code>
                            <span>mdi-grease-pencil</span>
                        </div>
                        <div>
                            <i class="mdi mdi-grid"></i>
                            <code>f2c1</code>
                            <span>mdi-grid</span>
                        </div>
                        <div>
                            <i class="mdi mdi-grid-off"></i>
                            <code>f2c2</code>
                            <span>mdi-grid-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-group"></i>
                            <code>f2c3</code>
                            <span>mdi-group</span>
                        </div>
                        <div>
                            <i class="mdi mdi-guitar-electric"></i>
                            <code>f2c4</code>
                            <span>mdi-guitar-electric</span>
                        </div>
                        <div>
                            <i class="mdi mdi-guitar-pick"></i>
                            <code>f2c5</code>
                            <span>mdi-guitar-pick</span>
                        </div>
                        <div>
                            <i class="mdi mdi-guitar-pick-outline"></i>
                            <code>f2c6</code>
                            <span>mdi-guitar-pick-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hackernews"></i>
                            <code>f624</code>
                            <span>mdi-hackernews</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hamburger"></i>
                            <code>f684</code>
                            <span>mdi-hamburger</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hand-pointing-right"></i>
                            <code>f2c7</code>
                            <span>mdi-hand-pointing-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hanger"></i>
                            <code>f2c8</code>
                            <span>mdi-hanger</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hangouts"></i>
                            <code>f2c9</code>
                            <span>mdi-hangouts</span>
                        </div>
                        <div>
                            <i class="mdi mdi-harddisk"></i>
                            <code>f2ca</code>
                            <span>mdi-harddisk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headphones"></i>
                            <code>f2cb</code>
                            <span>mdi-headphones</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headphones-box"></i>
                            <code>f2cc</code>
                            <span>mdi-headphones-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headphones-settings"></i>
                            <code>f2cd</code>
                            <span>mdi-headphones-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headset"></i>
                            <code>f2ce</code>
                            <span>mdi-headset</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headset-dock"></i>
                            <code>f2cf</code>
                            <span>mdi-headset-dock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-headset-off"></i>
                            <code>f2d0</code>
                            <span>mdi-headset-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart"></i>
                            <code>f2d1</code>
                            <span>mdi-heart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-box"></i>
                            <code>f2d2</code>
                            <span>mdi-heart-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-box-outline"></i>
                            <code>f2d3</code>
                            <span>mdi-heart-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-broken"></i>
                            <code>f2d4</code>
                            <span>mdi-heart-broken</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-half-outline"></i>
                            <code>f6dd</code>
                            <span>mdi-heart-half-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-half-part"></i>
                            <code>f6de</code>
                            <span>mdi-heart-half-part</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-half-part-outline"></i>
                            <code>f6df</code>
                            <span>mdi-heart-half-part-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-outline"></i>
                            <code>f2d5</code>
                            <span>mdi-heart-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-heart-pulse"></i>
                            <code>f5f6</code>
                            <span>mdi-heart-pulse</span>
                        </div>
                        <div>
                            <i class="mdi mdi-help"></i>
                            <code>f2d6</code>
                            <span>mdi-help</span>
                        </div>
                        <div>
                            <i class="mdi mdi-help-circle"></i>
                            <code>f2d7</code>
                            <span>mdi-help-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-help-circle-outline"></i>
                            <code>f625</code>
                            <span>mdi-help-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hexagon"></i>
                            <code>f2d8</code>
                            <span>mdi-hexagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hexagon-multiple"></i>
                            <code>f6e0</code>
                            <span>mdi-hexagon-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hexagon-outline"></i>
                            <code>f2d9</code>
                            <span>mdi-hexagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-highway"></i>
                            <code>f5f7</code>
                            <span>mdi-highway</span>
                        </div>
                        <div>
                            <i class="mdi mdi-history"></i>
                            <code>f2da</code>
                            <span>mdi-history</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hololens"></i>
                            <code>f2db</code>
                            <span>mdi-hololens</span>
                        </div>
                        <div>
                            <i class="mdi mdi-home"></i>
                            <code>f2dc</code>
                            <span>mdi-home</span>
                        </div>
                        <div>
                            <i class="mdi mdi-home-map-marker"></i>
                            <code>f5f8</code>
                            <span>mdi-home-map-marker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-home-modern"></i>
                            <code>f2dd</code>
                            <span>mdi-home-modern</span>
                        </div>
                        <div>
                            <i class="mdi mdi-home-outline"></i>
                            <code>f6a0</code>
                            <span>mdi-home-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-home-variant"></i>
                            <code>f2de</code>
                            <span>mdi-home-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hook"></i>
                            <code>f6e1</code>
                            <span>mdi-hook</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hook-off"></i>
                            <code>f6e2</code>
                            <span>mdi-hook-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hops"></i>
                            <code>f2df</code>
                            <span>mdi-hops</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hospital"></i>
                            <code>f2e0</code>
                            <span>mdi-hospital</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hospital-building"></i>
                            <code>f2e1</code>
                            <span>mdi-hospital-building</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hospital-marker"></i>
                            <code>f2e2</code>
                            <span>mdi-hospital-marker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-hotel"></i>
                            <code>f2e3</code>
                            <span>mdi-hotel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-houzz"></i>
                            <code>f2e4</code>
                            <span>mdi-houzz</span>
                        </div>
                        <div>
                            <i class="mdi mdi-houzz-box"></i>
                            <code>f2e5</code>
                            <span>mdi-houzz-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human"></i>
                            <code>f2e6</code>
                            <span>mdi-human</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-child"></i>
                            <code>f2e7</code>
                            <span>mdi-human-child</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-female"></i>
                            <code>f649</code>
                            <span>mdi-human-female</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-greeting"></i>
                            <code>f64a</code>
                            <span>mdi-human-greeting</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-handsdown"></i>
                            <code>f64b</code>
                            <span>mdi-human-handsdown</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-handsup"></i>
                            <code>f64c</code>
                            <span>mdi-human-handsup</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-male"></i>
                            <code>f64d</code>
                            <span>mdi-human-male</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-male-female"></i>
                            <code>f2e8</code>
                            <span>mdi-human-male-female</span>
                        </div>
                        <div>
                            <i class="mdi mdi-human-pregnant"></i>
                            <code>f5cf</code>
                            <span>mdi-human-pregnant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image"></i>
                            <code>f2e9</code>
                            <span>mdi-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-album"></i>
                            <code>f2ea</code>
                            <span>mdi-image-album</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-area"></i>
                            <code>f2eb</code>
                            <span>mdi-image-area</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-area-close"></i>
                            <code>f2ec</code>
                            <span>mdi-image-area-close</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-broken"></i>
                            <code>f2ed</code>
                            <span>mdi-image-broken</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-broken-variant"></i>
                            <code>f2ee</code>
                            <span>mdi-image-broken-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter"></i>
                            <code>f2ef</code>
                            <span>mdi-image-filter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-black-white"></i>
                            <code>f2f0</code>
                            <span>mdi-image-filter-black-white</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-center-focus"></i>
                            <code>f2f1</code>
                            <span>mdi-image-filter-center-focus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-center-focus-weak"></i>
                            <code>f2f2</code>
                            <span>mdi-image-filter-center-focus-weak</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-drama"></i>
                            <code>f2f3</code>
                            <span>mdi-image-filter-drama</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-frames"></i>
                            <code>f2f4</code>
                            <span>mdi-image-filter-frames</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-hdr"></i>
                            <code>f2f5</code>
                            <span>mdi-image-filter-hdr</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-none"></i>
                            <code>f2f6</code>
                            <span>mdi-image-filter-none</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-tilt-shift"></i>
                            <code>f2f7</code>
                            <span>mdi-image-filter-tilt-shift</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-filter-vintage"></i>
                            <code>f2f8</code>
                            <span>mdi-image-filter-vintage</span>
                        </div>
                        <div>
                            <i class="mdi mdi-image-multiple"></i>
                            <code>f2f9</code>
                            <span>mdi-image-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-import"></i>
                            <code>f2fa</code>
                            <span>mdi-import</span>
                        </div>
                        <div>
                            <i class="mdi mdi-inbox"></i>
                            <code>f686</code>
                            <span>mdi-inbox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-inbox-arrow-down"></i>
                            <code>f2fb</code>
                            <span>mdi-inbox-arrow-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-inbox-arrow-up"></i>
                            <code>f3d1</code>
                            <span>mdi-inbox-arrow-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-incognito"></i>
                            <code>f5f9</code>
                            <span>mdi-incognito</span>
                        </div>
                        <div>
                            <i class="mdi mdi-infinity"></i>
                            <code>f6e3</code>
                            <span>mdi-infinity</span>
                        </div>
                        <div>
                            <i class="mdi mdi-information"></i>
                            <code>f2fc</code>
                            <span>mdi-information</span>
                        </div>
                        <div>
                            <i class="mdi mdi-information-outline"></i>
                            <code>f2fd</code>
                            <span>mdi-information-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-information-variant"></i>
                            <code>f64e</code>
                            <span>mdi-information-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-instagram"></i>
                            <code>f2fe</code>
                            <span>mdi-instagram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-instapaper"></i>
                            <code>f2ff</code>
                            <span>mdi-instapaper</span>
                        </div>
                        <div>
                            <i class="mdi mdi-internet-explorer"></i>
                            <code>f300</code>
                            <span>mdi-internet-explorer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-invert-colors"></i>
                            <code>f301</code>
                            <span>mdi-invert-colors</span>
                        </div>
                        <div>
                            <i class="mdi mdi-itunes"></i>
                            <code>f676</code>
                            <span>mdi-itunes</span>
                        </div>
                        <div>
                            <i class="mdi mdi-jeepney"></i>
                            <code>f302</code>
                            <span>mdi-jeepney</span>
                        </div>
                        <div>
                            <i class="mdi mdi-jira"></i>
                            <code>f303</code>
                            <span>mdi-jira</span>
                        </div>
                        <div>
                            <i class="mdi mdi-jsfiddle"></i>
                            <code>f304</code>
                            <span>mdi-jsfiddle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-json"></i>
                            <code>f626</code>
                            <span>mdi-json</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keg"></i>
                            <code>f305</code>
                            <span>mdi-keg</span>
                        </div>
                        <div>
                            <i class="mdi mdi-kettle"></i>
                            <code>f5fa</code>
                            <span>mdi-kettle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key"></i>
                            <code>f306</code>
                            <span>mdi-key</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key-change"></i>
                            <code>f307</code>
                            <span>mdi-key-change</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key-minus"></i>
                            <code>f308</code>
                            <span>mdi-key-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key-plus"></i>
                            <code>f309</code>
                            <span>mdi-key-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key-remove"></i>
                            <code>f30a</code>
                            <span>mdi-key-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-key-variant"></i>
                            <code>f30b</code>
                            <span>mdi-key-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard"></i>
                            <code>f30c</code>
                            <span>mdi-keyboard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-backspace"></i>
                            <code>f30d</code>
                            <span>mdi-keyboard-backspace</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-caps"></i>
                            <code>f30e</code>
                            <span>mdi-keyboard-caps</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-close"></i>
                            <code>f30f</code>
                            <span>mdi-keyboard-close</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-off"></i>
                            <code>f310</code>
                            <span>mdi-keyboard-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-return"></i>
                            <code>f311</code>
                            <span>mdi-keyboard-return</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-tab"></i>
                            <code>f312</code>
                            <span>mdi-keyboard-tab</span>
                        </div>
                        <div>
                            <i class="mdi mdi-keyboard-variant"></i>
                            <code>f313</code>
                            <span>mdi-keyboard-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-kodi"></i>
                            <code>f314</code>
                            <span>mdi-kodi</span>
                        </div>
                        <div>
                            <i class="mdi mdi-label"></i>
                            <code>f315</code>
                            <span>mdi-label</span>
                        </div>
                        <div>
                            <i class="mdi mdi-label-outline"></i>
                            <code>f316</code>
                            <span>mdi-label-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lambda"></i>
                            <code>f627</code>
                            <span>mdi-lambda</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lamp"></i>
                            <code>f6b4</code>
                            <span>mdi-lamp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lan"></i>
                            <code>f317</code>
                            <span>mdi-lan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lan-connect"></i>
                            <code>f318</code>
                            <span>mdi-lan-connect</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lan-disconnect"></i>
                            <code>f319</code>
                            <span>mdi-lan-disconnect</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lan-pending"></i>
                            <code>f31a</code>
                            <span>mdi-lan-pending</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-c"></i>
                            <code>f671</code>
                            <span>mdi-language-c</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-cpp"></i>
                            <code>f672</code>
                            <span>mdi-language-cpp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-csharp"></i>
                            <code>f31b</code>
                            <span>mdi-language-csharp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-css3"></i>
                            <code>f31c</code>
                            <span>mdi-language-css3</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-html5"></i>
                            <code>f31d</code>
                            <span>mdi-language-html5</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-javascript"></i>
                            <code>f31e</code>
                            <span>mdi-language-javascript</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-php"></i>
                            <code>f31f</code>
                            <span>mdi-language-php</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-python"></i>
                            <code>f320</code>
                            <span>mdi-language-python</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-python-text"></i>
                            <code>f321</code>
                            <span>mdi-language-python-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-swift"></i>
                            <code>f6e4</code>
                            <span>mdi-language-swift</span>
                        </div>
                        <div>
                            <i class="mdi mdi-language-typescript"></i>
                            <code>f6e5</code>
                            <span>mdi-language-typescript</span>
                        </div>
                        <div>
                            <i class="mdi mdi-laptop"></i>
                            <code>f322</code>
                            <span>mdi-laptop</span>
                        </div>
                        <div>
                            <i class="mdi mdi-laptop-chromebook"></i>
                            <code>f323</code>
                            <span>mdi-laptop-chromebook</span>
                        </div>
                        <div>
                            <i class="mdi mdi-laptop-mac"></i>
                            <code>f324</code>
                            <span>mdi-laptop-mac</span>
                        </div>
                        <div>
                            <i class="mdi mdi-laptop-off"></i>
                            <code>f6e6</code>
                            <span>mdi-laptop-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-laptop-windows"></i>
                            <code>f325</code>
                            <span>mdi-laptop-windows</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lastfm"></i>
                            <code>f326</code>
                            <span>mdi-lastfm</span>
                        </div>
                        <div>
                            <i class="mdi mdi-launch"></i>
                            <code>f327</code>
                            <span>mdi-launch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-layers"></i>
                            <code>f328</code>
                            <span>mdi-layers</span>
                        </div>
                        <div>
                            <i class="mdi mdi-layers-off"></i>
                            <code>f329</code>
                            <span>mdi-layers-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lead-pencil"></i>
                            <code>f64f</code>
                            <span>mdi-lead-pencil</span>
                        </div>
                        <div>
                            <i class="mdi mdi-leaf"></i>
                            <code>f32a</code>
                            <span>mdi-leaf</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-off"></i>
                            <code>f32b</code>
                            <span>mdi-led-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-on"></i>
                            <code>f32c</code>
                            <span>mdi-led-on</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-outline"></i>
                            <code>f32d</code>
                            <span>mdi-led-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-variant-off"></i>
                            <code>f32e</code>
                            <span>mdi-led-variant-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-variant-on"></i>
                            <code>f32f</code>
                            <span>mdi-led-variant-on</span>
                        </div>
                        <div>
                            <i class="mdi mdi-led-variant-outline"></i>
                            <code>f330</code>
                            <span>mdi-led-variant-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-library"></i>
                            <code>f331</code>
                            <span>mdi-library</span>
                        </div>
                        <div>
                            <i class="mdi mdi-library-books"></i>
                            <code>f332</code>
                            <span>mdi-library-books</span>
                        </div>
                        <div>
                            <i class="mdi mdi-library-music"></i>
                            <code>f333</code>
                            <span>mdi-library-music</span>
                        </div>
                        <div>
                            <i class="mdi mdi-library-plus"></i>
                            <code>f334</code>
                            <span>mdi-library-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lightbulb"></i>
                            <code>f335</code>
                            <span>mdi-lightbulb</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lightbulb-on"></i>
                            <code>f6e7</code>
                            <span>mdi-lightbulb-on</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lightbulb-on-outline"></i>
                            <code>f6e8</code>
                            <span>mdi-lightbulb-on-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lightbulb-outline"></i>
                            <code>f336</code>
                            <span>mdi-lightbulb-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-link"></i>
                            <code>f337</code>
                            <span>mdi-link</span>
                        </div>
                        <div>
                            <i class="mdi mdi-link-off"></i>
                            <code>f338</code>
                            <span>mdi-link-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-link-variant"></i>
                            <code>f339</code>
                            <span>mdi-link-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-link-variant-off"></i>
                            <code>f33a</code>
                            <span>mdi-link-variant-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-linkedin"></i>
                            <code>f33b</code>
                            <span>mdi-linkedin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-linkedin-box"></i>
                            <code>f33c</code>
                            <span>mdi-linkedin-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-linux"></i>
                            <code>f33d</code>
                            <span>mdi-linux</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock"></i>
                            <code>f33e</code>
                            <span>mdi-lock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock-open"></i>
                            <code>f33f</code>
                            <span>mdi-lock-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock-open-outline"></i>
                            <code>f340</code>
                            <span>mdi-lock-open-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock-outline"></i>
                            <code>f341</code>
                            <span>mdi-lock-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock-pattern"></i>
                            <code>f6e9</code>
                            <span>mdi-lock-pattern</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lock-plus"></i>
                            <code>f5fb</code>
                            <span>mdi-lock-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-login"></i>
                            <code>f342</code>
                            <span>mdi-login</span>
                        </div>
                        <div>
                            <i class="mdi mdi-login-variant"></i>
                            <code>f5fc</code>
                            <span>mdi-login-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-logout"></i>
                            <code>f343</code>
                            <span>mdi-logout</span>
                        </div>
                        <div>
                            <i class="mdi mdi-logout-variant"></i>
                            <code>f5fd</code>
                            <span>mdi-logout-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-looks"></i>
                            <code>f344</code>
                            <span>mdi-looks</span>
                        </div>
                        <div>
                            <i class="mdi mdi-loop"></i>
                            <code>f6ea</code>
                            <span>mdi-loop</span>
                        </div>
                        <div>
                            <i class="mdi mdi-loupe"></i>
                            <code>f345</code>
                            <span>mdi-loupe</span>
                        </div>
                        <div>
                            <i class="mdi mdi-lumx"></i>
                            <code>f346</code>
                            <span>mdi-lumx</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnet"></i>
                            <code>f347</code>
                            <span>mdi-magnet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnet-on"></i>
                            <code>f348</code>
                            <span>mdi-magnet-on</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnify"></i>
                            <code>f349</code>
                            <span>mdi-magnify</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnify-minus"></i>
                            <code>f34a</code>
                            <span>mdi-magnify-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnify-minus-outline"></i>
                            <code>f6eb</code>
                            <span>mdi-magnify-minus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnify-plus"></i>
                            <code>f34b</code>
                            <span>mdi-magnify-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-magnify-plus-outline"></i>
                            <code>f6ec</code>
                            <span>mdi-magnify-plus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mail-ru"></i>
                            <code>f34c</code>
                            <span>mdi-mail-ru</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mailbox"></i>
                            <code>f6ed</code>
                            <span>mdi-mailbox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map"></i>
                            <code>f34d</code>
                            <span>mdi-map</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker"></i>
                            <code>f34e</code>
                            <span>mdi-map-marker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-circle"></i>
                            <code>f34f</code>
                            <span>mdi-map-marker-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-minus"></i>
                            <code>f650</code>
                            <span>mdi-map-marker-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-multiple"></i>
                            <code>f350</code>
                            <span>mdi-map-marker-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-off"></i>
                            <code>f351</code>
                            <span>mdi-map-marker-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-plus"></i>
                            <code>f651</code>
                            <span>mdi-map-marker-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-map-marker-radius"></i>
                            <code>f352</code>
                            <span>mdi-map-marker-radius</span>
                        </div>
                        <div>
                            <i class="mdi mdi-margin"></i>
                            <code>f353</code>
                            <span>mdi-margin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-markdown"></i>
                            <code>f354</code>
                            <span>mdi-markdown</span>
                        </div>
                        <div>
                            <i class="mdi mdi-marker"></i>
                            <code>f652</code>
                            <span>mdi-marker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-marker-check"></i>
                            <code>f355</code>
                            <span>mdi-marker-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-martini"></i>
                            <code>f356</code>
                            <span>mdi-martini</span>
                        </div>
                        <div>
                            <i class="mdi mdi-material-ui"></i>
                            <code>f357</code>
                            <span>mdi-material-ui</span>
                        </div>
                        <div>
                            <i class="mdi mdi-math-compass"></i>
                            <code>f358</code>
                            <span>mdi-math-compass</span>
                        </div>
                        <div>
                            <i class="mdi mdi-matrix"></i>
                            <code>f628</code>
                            <span>mdi-matrix</span>
                        </div>
                        <div>
                            <i class="mdi mdi-maxcdn"></i>
                            <code>f359</code>
                            <span>mdi-maxcdn</span>
                        </div>
                        <div>
                            <i class="mdi mdi-medical-bag"></i>
                            <code>f6ee</code>
                            <span>mdi-medical-bag</span>
                        </div>
                        <div>
                            <i class="mdi mdi-medium"></i>
                            <code>f35a</code>
                            <span>mdi-medium</span>
                        </div>
                        <div>
                            <i class="mdi mdi-memory"></i>
                            <code>f35b</code>
                            <span>mdi-memory</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu"></i>
                            <code>f35c</code>
                            <span>mdi-menu</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-down"></i>
                            <code>f35d</code>
                            <span>mdi-menu-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-down-outline"></i>
                            <code>f6b5</code>
                            <span>mdi-menu-down-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-left"></i>
                            <code>f35e</code>
                            <span>mdi-menu-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-right"></i>
                            <code>f35f</code>
                            <span>mdi-menu-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-up"></i>
                            <code>f360</code>
                            <span>mdi-menu-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-menu-up-outline"></i>
                            <code>f6b6</code>
                            <span>mdi-menu-up-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message"></i>
                            <code>f361</code>
                            <span>mdi-message</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-alert"></i>
                            <code>f362</code>
                            <span>mdi-message-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-bulleted"></i>
                            <code>f6a1</code>
                            <span>mdi-message-bulleted</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-bulleted-off"></i>
                            <code>f6a2</code>
                            <span>mdi-message-bulleted-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-draw"></i>
                            <code>f363</code>
                            <span>mdi-message-draw</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-image"></i>
                            <code>f364</code>
                            <span>mdi-message-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-outline"></i>
                            <code>f365</code>
                            <span>mdi-message-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-plus"></i>
                            <code>f653</code>
                            <span>mdi-message-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-processing"></i>
                            <code>f366</code>
                            <span>mdi-message-processing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-reply"></i>
                            <code>f367</code>
                            <span>mdi-message-reply</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-reply-text"></i>
                            <code>f368</code>
                            <span>mdi-message-reply-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-settings"></i>
                            <code>f6ef</code>
                            <span>mdi-message-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-settings-variant"></i>
                            <code>f6f0</code>
                            <span>mdi-message-settings-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-text"></i>
                            <code>f369</code>
                            <span>mdi-message-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-text-outline"></i>
                            <code>f36a</code>
                            <span>mdi-message-text-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-message-video"></i>
                            <code>f36b</code>
                            <span>mdi-message-video</span>
                        </div>
                        <div>
                            <i class="mdi mdi-meteor"></i>
                            <code>f629</code>
                            <span>mdi-meteor</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone"></i>
                            <code>f36c</code>
                            <span>mdi-microphone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone-off"></i>
                            <code>f36d</code>
                            <span>mdi-microphone-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone-outline"></i>
                            <code>f36e</code>
                            <span>mdi-microphone-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone-settings"></i>
                            <code>f36f</code>
                            <span>mdi-microphone-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone-variant"></i>
                            <code>f370</code>
                            <span>mdi-microphone-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microphone-variant-off"></i>
                            <code>f371</code>
                            <span>mdi-microphone-variant-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microscope"></i>
                            <code>f654</code>
                            <span>mdi-microscope</span>
                        </div>
                        <div>
                            <i class="mdi mdi-microsoft"></i>
                            <code>f372</code>
                            <span>mdi-microsoft</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minecraft"></i>
                            <code>f373</code>
                            <span>mdi-minecraft</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus"></i>
                            <code>f374</code>
                            <span>mdi-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus-box"></i>
                            <code>f375</code>
                            <span>mdi-minus-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus-box-outline"></i>
                            <code>f6f1</code>
                            <span>mdi-minus-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus-circle"></i>
                            <code>f376</code>
                            <span>mdi-minus-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus-circle-outline"></i>
                            <code>f377</code>
                            <span>mdi-minus-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-minus-network"></i>
                            <code>f378</code>
                            <span>mdi-minus-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mixcloud"></i>
                            <code>f62a</code>
                            <span>mdi-mixcloud</span>
                        </div>
                        <div>
                            <i class="mdi mdi-monitor"></i>
                            <code>f379</code>
                            <span>mdi-monitor</span>
                        </div>
                        <div>
                            <i class="mdi mdi-monitor-multiple"></i>
                            <code>f37a</code>
                            <span>mdi-monitor-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-more"></i>
                            <code>f37b</code>
                            <span>mdi-more</span>
                        </div>
                        <div>
                            <i class="mdi mdi-motorbike"></i>
                            <code>f37c</code>
                            <span>mdi-motorbike</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mouse"></i>
                            <code>f37d</code>
                            <span>mdi-mouse</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mouse-off"></i>
                            <code>f37e</code>
                            <span>mdi-mouse-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mouse-variant"></i>
                            <code>f37f</code>
                            <span>mdi-mouse-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-mouse-variant-off"></i>
                            <code>f380</code>
                            <span>mdi-mouse-variant-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-move-resize"></i>
                            <code>f655</code>
                            <span>mdi-move-resize</span>
                        </div>
                        <div>
                            <i class="mdi mdi-move-resize-variant"></i>
                            <code>f656</code>
                            <span>mdi-move-resize-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-movie"></i>
                            <code>f381</code>
                            <span>mdi-movie</span>
                        </div>
                        <div>
                            <i class="mdi mdi-multiplication"></i>
                            <code>f382</code>
                            <span>mdi-multiplication</span>
                        </div>
                        <div>
                            <i class="mdi mdi-multiplication-box"></i>
                            <code>f383</code>
                            <span>mdi-multiplication-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-box"></i>
                            <code>f384</code>
                            <span>mdi-music-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-box-outline"></i>
                            <code>f385</code>
                            <span>mdi-music-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-circle"></i>
                            <code>f386</code>
                            <span>mdi-music-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note"></i>
                            <code>f387</code>
                            <span>mdi-music-note</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-bluetooth"></i>
                            <code>f5fe</code>
                            <span>mdi-music-note-bluetooth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-bluetooth-off"></i>
                            <code>f5ff</code>
                            <span>mdi-music-note-bluetooth-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-eighth"></i>
                            <code>f388</code>
                            <span>mdi-music-note-eighth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-half"></i>
                            <code>f389</code>
                            <span>mdi-music-note-half</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-off"></i>
                            <code>f38a</code>
                            <span>mdi-music-note-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-quarter"></i>
                            <code>f38b</code>
                            <span>mdi-music-note-quarter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-sixteenth"></i>
                            <code>f38c</code>
                            <span>mdi-music-note-sixteenth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-music-note-whole"></i>
                            <code>f38d</code>
                            <span>mdi-music-note-whole</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nature"></i>
                            <code>f38e</code>
                            <span>mdi-nature</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nature-people"></i>
                            <code>f38f</code>
                            <span>mdi-nature-people</span>
                        </div>
                        <div>
                            <i class="mdi mdi-navigation"></i>
                            <code>f390</code>
                            <span>mdi-navigation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-near-me"></i>
                            <code>f5cd</code>
                            <span>mdi-near-me</span>
                        </div>
                        <div>
                            <i class="mdi mdi-needle"></i>
                            <code>f391</code>
                            <span>mdi-needle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nest-protect"></i>
                            <code>f392</code>
                            <span>mdi-nest-protect</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nest-thermostat"></i>
                            <code>f393</code>
                            <span>mdi-nest-thermostat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-network"></i>
                            <code>f6f2</code>
                            <span>mdi-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-network-download"></i>
                            <code>f6f3</code>
                            <span>mdi-network-download</span>
                        </div>
                        <div>
                            <i class="mdi mdi-network-question"></i>
                            <code>f6f4</code>
                            <span>mdi-network-question</span>
                        </div>
                        <div>
                            <i class="mdi mdi-network-upload"></i>
                            <code>f6f5</code>
                            <span>mdi-network-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-new-box"></i>
                            <code>f394</code>
                            <span>mdi-new-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-newspaper"></i>
                            <code>f395</code>
                            <span>mdi-newspaper</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nfc"></i>
                            <code>f396</code>
                            <span>mdi-nfc</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nfc-tap"></i>
                            <code>f397</code>
                            <span>mdi-nfc-tap</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nfc-variant"></i>
                            <code>f398</code>
                            <span>mdi-nfc-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nodejs"></i>
                            <code>f399</code>
                            <span>mdi-nodejs</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note"></i>
                            <code>f39a</code>
                            <span>mdi-note</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-multiple"></i>
                            <code>f6b7</code>
                            <span>mdi-note-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-multiple-outline"></i>
                            <code>f6b8</code>
                            <span>mdi-note-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-outline"></i>
                            <code>f39b</code>
                            <span>mdi-note-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-plus"></i>
                            <code>f39c</code>
                            <span>mdi-note-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-plus-outline"></i>
                            <code>f39d</code>
                            <span>mdi-note-plus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-note-text"></i>
                            <code>f39e</code>
                            <span>mdi-note-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-notification-clear-all"></i>
                            <code>f39f</code>
                            <span>mdi-notification-clear-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-npm"></i>
                            <code>f6f6</code>
                            <span>mdi-npm</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nuke"></i>
                            <code>f6a3</code>
                            <span>mdi-nuke</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric"></i>
                            <code>f3a0</code>
                            <span>mdi-numeric</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-0-box"></i>
                            <code>f3a1</code>
                            <span>mdi-numeric-0-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-0-box-multiple-outline"></i>
                            <code>f3a2</code>
                            <span>mdi-numeric-0-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-0-box-outline"></i>
                            <code>f3a3</code>
                            <span>mdi-numeric-0-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-1-box"></i>
                            <code>f3a4</code>
                            <span>mdi-numeric-1-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-1-box-multiple-outline"></i>
                            <code>f3a5</code>
                            <span>mdi-numeric-1-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-1-box-outline"></i>
                            <code>f3a6</code>
                            <span>mdi-numeric-1-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-2-box"></i>
                            <code>f3a7</code>
                            <span>mdi-numeric-2-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-2-box-multiple-outline"></i>
                            <code>f3a8</code>
                            <span>mdi-numeric-2-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-2-box-outline"></i>
                            <code>f3a9</code>
                            <span>mdi-numeric-2-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-3-box"></i>
                            <code>f3aa</code>
                            <span>mdi-numeric-3-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-3-box-multiple-outline"></i>
                            <code>f3ab</code>
                            <span>mdi-numeric-3-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-3-box-outline"></i>
                            <code>f3ac</code>
                            <span>mdi-numeric-3-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-4-box"></i>
                            <code>f3ad</code>
                            <span>mdi-numeric-4-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-4-box-multiple-outline"></i>
                            <code>f3ae</code>
                            <span>mdi-numeric-4-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-4-box-outline"></i>
                            <code>f3af</code>
                            <span>mdi-numeric-4-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-5-box"></i>
                            <code>f3b0</code>
                            <span>mdi-numeric-5-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-5-box-multiple-outline"></i>
                            <code>f3b1</code>
                            <span>mdi-numeric-5-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-5-box-outline"></i>
                            <code>f3b2</code>
                            <span>mdi-numeric-5-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-6-box"></i>
                            <code>f3b3</code>
                            <span>mdi-numeric-6-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-6-box-multiple-outline"></i>
                            <code>f3b4</code>
                            <span>mdi-numeric-6-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-6-box-outline"></i>
                            <code>f3b5</code>
                            <span>mdi-numeric-6-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-7-box"></i>
                            <code>f3b6</code>
                            <span>mdi-numeric-7-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-7-box-multiple-outline"></i>
                            <code>f3b7</code>
                            <span>mdi-numeric-7-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-7-box-outline"></i>
                            <code>f3b8</code>
                            <span>mdi-numeric-7-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-8-box"></i>
                            <code>f3b9</code>
                            <span>mdi-numeric-8-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-8-box-multiple-outline"></i>
                            <code>f3ba</code>
                            <span>mdi-numeric-8-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-8-box-outline"></i>
                            <code>f3bb</code>
                            <span>mdi-numeric-8-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-box"></i>
                            <code>f3bc</code>
                            <span>mdi-numeric-9-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-box-multiple-outline"></i>
                            <code>f3bd</code>
                            <span>mdi-numeric-9-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-box-outline"></i>
                            <code>f3be</code>
                            <span>mdi-numeric-9-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-plus-box"></i>
                            <code>f3bf</code>
                            <span>mdi-numeric-9-plus-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-plus-box-multiple-outline"></i>
                            <code>f3c0</code>
                            <span>mdi-numeric-9-plus-box-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-numeric-9-plus-box-outline"></i>
                            <code>f3c1</code>
                            <span>mdi-numeric-9-plus-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nut"></i>
                            <code>f6f7</code>
                            <span>mdi-nut</span>
                        </div>
                        <div>
                            <i class="mdi mdi-nutrition"></i>
                            <code>f3c2</code>
                            <span>mdi-nutrition</span>
                        </div>
                        <div>
                            <i class="mdi mdi-oar"></i>
                            <code>f67b</code>
                            <span>mdi-oar</span>
                        </div>
                        <div>
                            <i class="mdi mdi-octagon"></i>
                            <code>f3c3</code>
                            <span>mdi-octagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-octagon-outline"></i>
                            <code>f3c4</code>
                            <span>mdi-octagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-octagram"></i>
                            <code>f6f8</code>
                            <span>mdi-octagram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-odnoklassniki"></i>
                            <code>f3c5</code>
                            <span>mdi-odnoklassniki</span>
                        </div>
                        <div>
                            <i class="mdi mdi-office"></i>
                            <code>f3c6</code>
                            <span>mdi-office</span>
                        </div>
                        <div>
                            <i class="mdi mdi-oil"></i>
                            <code>f3c7</code>
                            <span>mdi-oil</span>
                        </div>
                        <div>
                            <i class="mdi mdi-oil-temperature"></i>
                            <code>f3c8</code>
                            <span>mdi-oil-temperature</span>
                        </div>
                        <div>
                            <i class="mdi mdi-omega"></i>
                            <code>f3c9</code>
                            <span>mdi-omega</span>
                        </div>
                        <div>
                            <i class="mdi mdi-onedrive"></i>
                            <code>f3ca</code>
                            <span>mdi-onedrive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-opacity"></i>
                            <code>f5cc</code>
                            <span>mdi-opacity</span>
                        </div>
                        <div>
                            <i class="mdi mdi-open-in-app"></i>
                            <code>f3cb</code>
                            <span>mdi-open-in-app</span>
                        </div>
                        <div>
                            <i class="mdi mdi-open-in-new"></i>
                            <code>f3cc</code>
                            <span>mdi-open-in-new</span>
                        </div>
                        <div>
                            <i class="mdi mdi-openid"></i>
                            <code>f3cd</code>
                            <span>mdi-openid</span>
                        </div>
                        <div>
                            <i class="mdi mdi-opera"></i>
                            <code>f3ce</code>
                            <span>mdi-opera</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ornament"></i>
                            <code>f3cf</code>
                            <span>mdi-ornament</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ornament-variant"></i>
                            <code>f3d0</code>
                            <span>mdi-ornament-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-owl"></i>
                            <code>f3d2</code>
                            <span>mdi-owl</span>
                        </div>
                        <div>
                            <i class="mdi mdi-package"></i>
                            <code>f3d3</code>
                            <span>mdi-package</span>
                        </div>
                        <div>
                            <i class="mdi mdi-package-down"></i>
                            <code>f3d4</code>
                            <span>mdi-package-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-package-up"></i>
                            <code>f3d5</code>
                            <span>mdi-package-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-package-variant"></i>
                            <code>f3d6</code>
                            <span>mdi-package-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-package-variant-closed"></i>
                            <code>f3d7</code>
                            <span>mdi-package-variant-closed</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-first"></i>
                            <code>f600</code>
                            <span>mdi-page-first</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-last"></i>
                            <code>f601</code>
                            <span>mdi-page-last</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-layout-body"></i>
                            <code>f6f9</code>
                            <span>mdi-page-layout-body</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-layout-footer"></i>
                            <code>f6fa</code>
                            <span>mdi-page-layout-footer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-layout-header"></i>
                            <code>f6fb</code>
                            <span>mdi-page-layout-header</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-layout-sidebar-left"></i>
                            <code>f6fc</code>
                            <span>mdi-page-layout-sidebar-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-page-layout-sidebar-right"></i>
                            <code>f6fd</code>
                            <span>mdi-page-layout-sidebar-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-palette"></i>
                            <code>f3d8</code>
                            <span>mdi-palette</span>
                        </div>
                        <div>
                            <i class="mdi mdi-palette-advanced"></i>
                            <code>f3d9</code>
                            <span>mdi-palette-advanced</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panda"></i>
                            <code>f3da</code>
                            <span>mdi-panda</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pandora"></i>
                            <code>f3db</code>
                            <span>mdi-pandora</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panorama"></i>
                            <code>f3dc</code>
                            <span>mdi-panorama</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panorama-fisheye"></i>
                            <code>f3dd</code>
                            <span>mdi-panorama-fisheye</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panorama-horizontal"></i>
                            <code>f3de</code>
                            <span>mdi-panorama-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panorama-vertical"></i>
                            <code>f3df</code>
                            <span>mdi-panorama-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-panorama-wide-angle"></i>
                            <code>f3e0</code>
                            <span>mdi-panorama-wide-angle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-paper-cut-vertical"></i>
                            <code>f3e1</code>
                            <span>mdi-paper-cut-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-paperclip"></i>
                            <code>f3e2</code>
                            <span>mdi-paperclip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-parking"></i>
                            <code>f3e3</code>
                            <span>mdi-parking</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pause"></i>
                            <code>f3e4</code>
                            <span>mdi-pause</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pause-circle"></i>
                            <code>f3e5</code>
                            <span>mdi-pause-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pause-circle-outline"></i>
                            <code>f3e6</code>
                            <span>mdi-pause-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pause-octagon"></i>
                            <code>f3e7</code>
                            <span>mdi-pause-octagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pause-octagon-outline"></i>
                            <code>f3e8</code>
                            <span>mdi-pause-octagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-paw"></i>
                            <code>f3e9</code>
                            <span>mdi-paw</span>
                        </div>
                        <div>
                            <i class="mdi mdi-paw-off"></i>
                            <code>f657</code>
                            <span>mdi-paw-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pen"></i>
                            <code>f3ea</code>
                            <span>mdi-pen</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil"></i>
                            <code>f3eb</code>
                            <span>mdi-pencil</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil-box"></i>
                            <code>f3ec</code>
                            <span>mdi-pencil-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil-box-outline"></i>
                            <code>f3ed</code>
                            <span>mdi-pencil-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil-circle"></i>
                            <code>f6fe</code>
                            <span>mdi-pencil-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil-lock"></i>
                            <code>f3ee</code>
                            <span>mdi-pencil-lock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pencil-off"></i>
                            <code>f3ef</code>
                            <span>mdi-pencil-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pentagon"></i>
                            <code>f6ff</code>
                            <span>mdi-pentagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pentagon-outline"></i>
                            <code>f700</code>
                            <span>mdi-pentagon-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-percent"></i>
                            <code>f3f0</code>
                            <span>mdi-percent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pharmacy"></i>
                            <code>f3f1</code>
                            <span>mdi-pharmacy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone"></i>
                            <code>f3f2</code>
                            <span>mdi-phone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-bluetooth"></i>
                            <code>f3f3</code>
                            <span>mdi-phone-bluetooth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-classic"></i>
                            <code>f602</code>
                            <span>mdi-phone-classic</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-forward"></i>
                            <code>f3f4</code>
                            <span>mdi-phone-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-hangup"></i>
                            <code>f3f5</code>
                            <span>mdi-phone-hangup</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-in-talk"></i>
                            <code>f3f6</code>
                            <span>mdi-phone-in-talk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-incoming"></i>
                            <code>f3f7</code>
                            <span>mdi-phone-incoming</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-locked"></i>
                            <code>f3f8</code>
                            <span>mdi-phone-locked</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-log"></i>
                            <code>f3f9</code>
                            <span>mdi-phone-log</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-minus"></i>
                            <code>f658</code>
                            <span>mdi-phone-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-missed"></i>
                            <code>f3fa</code>
                            <span>mdi-phone-missed</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-outgoing"></i>
                            <code>f3fb</code>
                            <span>mdi-phone-outgoing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-paused"></i>
                            <code>f3fc</code>
                            <span>mdi-phone-paused</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-plus"></i>
                            <code>f659</code>
                            <span>mdi-phone-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-settings"></i>
                            <code>f3fd</code>
                            <span>mdi-phone-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-phone-voip"></i>
                            <code>f3fe</code>
                            <span>mdi-phone-voip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pi"></i>
                            <code>f3ff</code>
                            <span>mdi-pi</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pi-box"></i>
                            <code>f400</code>
                            <span>mdi-pi-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-piano"></i>
                            <code>f67c</code>
                            <span>mdi-piano</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pig"></i>
                            <code>f401</code>
                            <span>mdi-pig</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pill"></i>
                            <code>f402</code>
                            <span>mdi-pill</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pillar"></i>
                            <code>f701</code>
                            <span>mdi-pillar</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pin"></i>
                            <code>f403</code>
                            <span>mdi-pin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pin-off"></i>
                            <code>f404</code>
                            <span>mdi-pin-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pine-tree"></i>
                            <code>f405</code>
                            <span>mdi-pine-tree</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pine-tree-box"></i>
                            <code>f406</code>
                            <span>mdi-pine-tree-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pinterest"></i>
                            <code>f407</code>
                            <span>mdi-pinterest</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pinterest-box"></i>
                            <code>f408</code>
                            <span>mdi-pinterest-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pistol"></i>
                            <code>f702</code>
                            <span>mdi-pistol</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pizza"></i>
                            <code>f409</code>
                            <span>mdi-pizza</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plane-shield"></i>
                            <code>f6ba</code>
                            <span>mdi-plane-shield</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play"></i>
                            <code>f40a</code>
                            <span>mdi-play</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play-box-outline"></i>
                            <code>f40b</code>
                            <span>mdi-play-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play-circle"></i>
                            <code>f40c</code>
                            <span>mdi-play-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play-circle-outline"></i>
                            <code>f40d</code>
                            <span>mdi-play-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play-pause"></i>
                            <code>f40e</code>
                            <span>mdi-play-pause</span>
                        </div>
                        <div>
                            <i class="mdi mdi-play-protected-content"></i>
                            <code>f40f</code>
                            <span>mdi-play-protected-content</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playlist-check"></i>
                            <code>f5c7</code>
                            <span>mdi-playlist-check</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playlist-minus"></i>
                            <code>f410</code>
                            <span>mdi-playlist-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playlist-play"></i>
                            <code>f411</code>
                            <span>mdi-playlist-play</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playlist-plus"></i>
                            <code>f412</code>
                            <span>mdi-playlist-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playlist-remove"></i>
                            <code>f413</code>
                            <span>mdi-playlist-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-playstation"></i>
                            <code>f414</code>
                            <span>mdi-playstation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plex"></i>
                            <code>f6b9</code>
                            <span>mdi-plex</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus"></i>
                            <code>f415</code>
                            <span>mdi-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-box"></i>
                            <code>f416</code>
                            <span>mdi-plus-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-box-outline"></i>
                            <code>f703</code>
                            <span>mdi-plus-box-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-circle"></i>
                            <code>f417</code>
                            <span>mdi-plus-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-circle-multiple-outline"></i>
                            <code>f418</code>
                            <span>mdi-plus-circle-multiple-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-circle-outline"></i>
                            <code>f419</code>
                            <span>mdi-plus-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-network"></i>
                            <code>f41a</code>
                            <span>mdi-plus-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-one"></i>
                            <code>f41b</code>
                            <span>mdi-plus-one</span>
                        </div>
                        <div>
                            <i class="mdi mdi-plus-outline"></i>
                            <code>f704</code>
                            <span>mdi-plus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pocket"></i>
                            <code>f41c</code>
                            <span>mdi-pocket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pokeball"></i>
                            <code>f41d</code>
                            <span>mdi-pokeball</span>
                        </div>
                        <div>
                            <i class="mdi mdi-polaroid"></i>
                            <code>f41e</code>
                            <span>mdi-polaroid</span>
                        </div>
                        <div>
                            <i class="mdi mdi-poll"></i>
                            <code>f41f</code>
                            <span>mdi-poll</span>
                        </div>
                        <div>
                            <i class="mdi mdi-poll-box"></i>
                            <code>f420</code>
                            <span>mdi-poll-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-polymer"></i>
                            <code>f421</code>
                            <span>mdi-polymer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pool"></i>
                            <code>f606</code>
                            <span>mdi-pool</span>
                        </div>
                        <div>
                            <i class="mdi mdi-popcorn"></i>
                            <code>f422</code>
                            <span>mdi-popcorn</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pot"></i>
                            <code>f65a</code>
                            <span>mdi-pot</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pot-mix"></i>
                            <code>f65b</code>
                            <span>mdi-pot-mix</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pound"></i>
                            <code>f423</code>
                            <span>mdi-pound</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pound-box"></i>
                            <code>f424</code>
                            <span>mdi-pound-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-power"></i>
                            <code>f425</code>
                            <span>mdi-power</span>
                        </div>
                        <div>
                            <i class="mdi mdi-power-plug"></i>
                            <code>f6a4</code>
                            <span>mdi-power-plug</span>
                        </div>
                        <div>
                            <i class="mdi mdi-power-plug-off"></i>
                            <code>f6a5</code>
                            <span>mdi-power-plug-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-power-settings"></i>
                            <code>f426</code>
                            <span>mdi-power-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-power-socket"></i>
                            <code>f427</code>
                            <span>mdi-power-socket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-prescription"></i>
                            <code>f705</code>
                            <span>mdi-prescription</span>
                        </div>
                        <div>
                            <i class="mdi mdi-presentation"></i>
                            <code>f428</code>
                            <span>mdi-presentation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-presentation-play"></i>
                            <code>f429</code>
                            <span>mdi-presentation-play</span>
                        </div>
                        <div>
                            <i class="mdi mdi-printer"></i>
                            <code>f42a</code>
                            <span>mdi-printer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-printer-3d"></i>
                            <code>f42b</code>
                            <span>mdi-printer-3d</span>
                        </div>
                        <div>
                            <i class="mdi mdi-printer-alert"></i>
                            <code>f42c</code>
                            <span>mdi-printer-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-printer-settings"></i>
                            <code>f706</code>
                            <span>mdi-printer-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-priority-high"></i>
                            <code>f603</code>
                            <span>mdi-priority-high</span>
                        </div>
                        <div>
                            <i class="mdi mdi-priority-low"></i>
                            <code>f604</code>
                            <span>mdi-priority-low</span>
                        </div>
                        <div>
                            <i class="mdi mdi-professional-hexagon"></i>
                            <code>f42d</code>
                            <span>mdi-professional-hexagon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-projector"></i>
                            <code>f42e</code>
                            <span>mdi-projector</span>
                        </div>
                        <div>
                            <i class="mdi mdi-projector-screen"></i>
                            <code>f42f</code>
                            <span>mdi-projector-screen</span>
                        </div>
                        <div>
                            <i class="mdi mdi-publish"></i>
                            <code>f6a6</code>
                            <span>mdi-publish</span>
                        </div>
                        <div>
                            <i class="mdi mdi-pulse"></i>
                            <code>f430</code>
                            <span>mdi-pulse</span>
                        </div>
                        <div>
                            <i class="mdi mdi-puzzle"></i>
                            <code>f431</code>
                            <span>mdi-puzzle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-qqchat"></i>
                            <code>f605</code>
                            <span>mdi-qqchat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-qrcode"></i>
                            <code>f432</code>
                            <span>mdi-qrcode</span>
                        </div>
                        <div>
                            <i class="mdi mdi-qrcode-scan"></i>
                            <code>f433</code>
                            <span>mdi-qrcode-scan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-quadcopter"></i>
                            <code>f434</code>
                            <span>mdi-quadcopter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-quality-high"></i>
                            <code>f435</code>
                            <span>mdi-quality-high</span>
                        </div>
                        <div>
                            <i class="mdi mdi-quicktime"></i>
                            <code>f436</code>
                            <span>mdi-quicktime</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radar"></i>
                            <code>f437</code>
                            <span>mdi-radar</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radiator"></i>
                            <code>f438</code>
                            <span>mdi-radiator</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radio"></i>
                            <code>f439</code>
                            <span>mdi-radio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radio-handheld"></i>
                            <code>f43a</code>
                            <span>mdi-radio-handheld</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radio-tower"></i>
                            <code>f43b</code>
                            <span>mdi-radio-tower</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radioactive"></i>
                            <code>f43c</code>
                            <span>mdi-radioactive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radiobox-blank"></i>
                            <code>f43d</code>
                            <span>mdi-radiobox-blank</span>
                        </div>
                        <div>
                            <i class="mdi mdi-radiobox-marked"></i>
                            <code>f43e</code>
                            <span>mdi-radiobox-marked</span>
                        </div>
                        <div>
                            <i class="mdi mdi-raspberrypi"></i>
                            <code>f43f</code>
                            <span>mdi-raspberrypi</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-end"></i>
                            <code>f440</code>
                            <span>mdi-ray-end</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-end-arrow"></i>
                            <code>f441</code>
                            <span>mdi-ray-end-arrow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-start"></i>
                            <code>f442</code>
                            <span>mdi-ray-start</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-start-arrow"></i>
                            <code>f443</code>
                            <span>mdi-ray-start-arrow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-start-end"></i>
                            <code>f444</code>
                            <span>mdi-ray-start-end</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ray-vertex"></i>
                            <code>f445</code>
                            <span>mdi-ray-vertex</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rdio"></i>
                            <code>f446</code>
                            <span>mdi-rdio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-react"></i>
                            <code>f707</code>
                            <span>mdi-react</span>
                        </div>
                        <div>
                            <i class="mdi mdi-read"></i>
                            <code>f447</code>
                            <span>mdi-read</span>
                        </div>
                        <div>
                            <i class="mdi mdi-readability"></i>
                            <code>f448</code>
                            <span>mdi-readability</span>
                        </div>
                        <div>
                            <i class="mdi mdi-receipt"></i>
                            <code>f449</code>
                            <span>mdi-receipt</span>
                        </div>
                        <div>
                            <i class="mdi mdi-record"></i>
                            <code>f44a</code>
                            <span>mdi-record</span>
                        </div>
                        <div>
                            <i class="mdi mdi-record-rec"></i>
                            <code>f44b</code>
                            <span>mdi-record-rec</span>
                        </div>
                        <div>
                            <i class="mdi mdi-recycle"></i>
                            <code>f44c</code>
                            <span>mdi-recycle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reddit"></i>
                            <code>f44d</code>
                            <span>mdi-reddit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-redo"></i>
                            <code>f44e</code>
                            <span>mdi-redo</span>
                        </div>
                        <div>
                            <i class="mdi mdi-redo-variant"></i>
                            <code>f44f</code>
                            <span>mdi-redo-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-refresh"></i>
                            <code>f450</code>
                            <span>mdi-refresh</span>
                        </div>
                        <div>
                            <i class="mdi mdi-regex"></i>
                            <code>f451</code>
                            <span>mdi-regex</span>
                        </div>
                        <div>
                            <i class="mdi mdi-relative-scale"></i>
                            <code>f452</code>
                            <span>mdi-relative-scale</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reload"></i>
                            <code>f453</code>
                            <span>mdi-reload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-remote"></i>
                            <code>f454</code>
                            <span>mdi-remote</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rename-box"></i>
                            <code>f455</code>
                            <span>mdi-rename-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reorder-horizontal"></i>
                            <code>f687</code>
                            <span>mdi-reorder-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reorder-vertical"></i>
                            <code>f688</code>
                            <span>mdi-reorder-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-repeat"></i>
                            <code>f456</code>
                            <span>mdi-repeat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-repeat-off"></i>
                            <code>f457</code>
                            <span>mdi-repeat-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-repeat-once"></i>
                            <code>f458</code>
                            <span>mdi-repeat-once</span>
                        </div>
                        <div>
                            <i class="mdi mdi-replay"></i>
                            <code>f459</code>
                            <span>mdi-replay</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reply"></i>
                            <code>f45a</code>
                            <span>mdi-reply</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reply-all"></i>
                            <code>f45b</code>
                            <span>mdi-reply-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-reproduction"></i>
                            <code>f45c</code>
                            <span>mdi-reproduction</span>
                        </div>
                        <div>
                            <i class="mdi mdi-resize-bottom-right"></i>
                            <code>f45d</code>
                            <span>mdi-resize-bottom-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-responsive"></i>
                            <code>f45e</code>
                            <span>mdi-responsive</span>
                        </div>
                        <div>
                            <i class="mdi mdi-restart"></i>
                            <code>f708</code>
                            <span>mdi-restart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-restore"></i>
                            <code>f6a7</code>
                            <span>mdi-restore</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rewind"></i>
                            <code>f45f</code>
                            <span>mdi-rewind</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rewind-outline"></i>
                            <code>f709</code>
                            <span>mdi-rewind-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rhombus"></i>
                            <code>f70a</code>
                            <span>mdi-rhombus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rhombus-outline"></i>
                            <code>f70b</code>
                            <span>mdi-rhombus-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ribbon"></i>
                            <code>f460</code>
                            <span>mdi-ribbon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-road"></i>
                            <code>f461</code>
                            <span>mdi-road</span>
                        </div>
                        <div>
                            <i class="mdi mdi-road-variant"></i>
                            <code>f462</code>
                            <span>mdi-road-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-robot"></i>
                            <code>f6a8</code>
                            <span>mdi-robot</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rocket"></i>
                            <code>f463</code>
                            <span>mdi-rocket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-roomba"></i>
                            <code>f70c</code>
                            <span>mdi-roomba</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rotate-3d"></i>
                            <code>f464</code>
                            <span>mdi-rotate-3d</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rotate-left"></i>
                            <code>f465</code>
                            <span>mdi-rotate-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rotate-left-variant"></i>
                            <code>f466</code>
                            <span>mdi-rotate-left-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rotate-right"></i>
                            <code>f467</code>
                            <span>mdi-rotate-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rotate-right-variant"></i>
                            <code>f468</code>
                            <span>mdi-rotate-right-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rounded-corner"></i>
                            <code>f607</code>
                            <span>mdi-rounded-corner</span>
                        </div>
                        <div>
                            <i class="mdi mdi-router-wireless"></i>
                            <code>f469</code>
                            <span>mdi-router-wireless</span>
                        </div>
                        <div>
                            <i class="mdi mdi-routes"></i>
                            <code>f46a</code>
                            <span>mdi-routes</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rowing"></i>
                            <code>f608</code>
                            <span>mdi-rowing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rss"></i>
                            <code>f46b</code>
                            <span>mdi-rss</span>
                        </div>
                        <div>
                            <i class="mdi mdi-rss-box"></i>
                            <code>f46c</code>
                            <span>mdi-rss-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ruler"></i>
                            <code>f46d</code>
                            <span>mdi-ruler</span>
                        </div>
                        <div>
                            <i class="mdi mdi-run"></i>
                            <code>f70d</code>
                            <span>mdi-run</span>
                        </div>
                        <div>
                            <i class="mdi mdi-run-fast"></i>
                            <code>f46e</code>
                            <span>mdi-run-fast</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sale"></i>
                            <code>f46f</code>
                            <span>mdi-sale</span>
                        </div>
                        <div>
                            <i class="mdi mdi-satellite"></i>
                            <code>f470</code>
                            <span>mdi-satellite</span>
                        </div>
                        <div>
                            <i class="mdi mdi-satellite-variant"></i>
                            <code>f471</code>
                            <span>mdi-satellite-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-saxophone"></i>
                            <code>f609</code>
                            <span>mdi-saxophone</span>
                        </div>
                        <div>
                            <i class="mdi mdi-scale"></i>
                            <code>f472</code>
                            <span>mdi-scale</span>
                        </div>
                        <div>
                            <i class="mdi mdi-scale-balance"></i>
                            <code>f5d1</code>
                            <span>mdi-scale-balance</span>
                        </div>
                        <div>
                            <i class="mdi mdi-scale-bathroom"></i>
                            <code>f473</code>
                            <span>mdi-scale-bathroom</span>
                        </div>
                        <div>
                            <i class="mdi mdi-scanner"></i>
                            <code>f6aa</code>
                            <span>mdi-scanner</span>
                        </div>
                        <div>
                            <i class="mdi mdi-school"></i>
                            <code>f474</code>
                            <span>mdi-school</span>
                        </div>
                        <div>
                            <i class="mdi mdi-screen-rotation"></i>
                            <code>f475</code>
                            <span>mdi-screen-rotation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-screen-rotation-lock"></i>
                            <code>f476</code>
                            <span>mdi-screen-rotation-lock</span>
                        </div>
                        <div>
                            <i class="mdi mdi-screwdriver"></i>
                            <code>f477</code>
                            <span>mdi-screwdriver</span>
                        </div>
                        <div>
                            <i class="mdi mdi-script"></i>
                            <code>f478</code>
                            <span>mdi-script</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sd"></i>
                            <code>f479</code>
                            <span>mdi-sd</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seal"></i>
                            <code>f47a</code>
                            <span>mdi-seal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-search-web"></i>
                            <code>f70e</code>
                            <span>mdi-search-web</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-flat"></i>
                            <code>f47b</code>
                            <span>mdi-seat-flat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-flat-angled"></i>
                            <code>f47c</code>
                            <span>mdi-seat-flat-angled</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-individual-suite"></i>
                            <code>f47d</code>
                            <span>mdi-seat-individual-suite</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-legroom-extra"></i>
                            <code>f47e</code>
                            <span>mdi-seat-legroom-extra</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-legroom-normal"></i>
                            <code>f47f</code>
                            <span>mdi-seat-legroom-normal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-legroom-reduced"></i>
                            <code>f480</code>
                            <span>mdi-seat-legroom-reduced</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-recline-extra"></i>
                            <code>f481</code>
                            <span>mdi-seat-recline-extra</span>
                        </div>
                        <div>
                            <i class="mdi mdi-seat-recline-normal"></i>
                            <code>f482</code>
                            <span>mdi-seat-recline-normal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-security"></i>
                            <code>f483</code>
                            <span>mdi-security</span>
                        </div>
                        <div>
                            <i class="mdi mdi-security-home"></i>
                            <code>f689</code>
                            <span>mdi-security-home</span>
                        </div>
                        <div>
                            <i class="mdi mdi-security-network"></i>
                            <code>f484</code>
                            <span>mdi-security-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-select"></i>
                            <code>f485</code>
                            <span>mdi-select</span>
                        </div>
                        <div>
                            <i class="mdi mdi-select-all"></i>
                            <code>f486</code>
                            <span>mdi-select-all</span>
                        </div>
                        <div>
                            <i class="mdi mdi-select-inverse"></i>
                            <code>f487</code>
                            <span>mdi-select-inverse</span>
                        </div>
                        <div>
                            <i class="mdi mdi-select-off"></i>
                            <code>f488</code>
                            <span>mdi-select-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-selection"></i>
                            <code>f489</code>
                            <span>mdi-selection</span>
                        </div>
                        <div>
                            <i class="mdi mdi-send"></i>
                            <code>f48a</code>
                            <span>mdi-send</span>
                        </div>
                        <div>
                            <i class="mdi mdi-serial-port"></i>
                            <code>f65c</code>
                            <span>mdi-serial-port</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server"></i>
                            <code>f48b</code>
                            <span>mdi-server</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-minus"></i>
                            <code>f48c</code>
                            <span>mdi-server-minus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-network"></i>
                            <code>f48d</code>
                            <span>mdi-server-network</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-network-off"></i>
                            <code>f48e</code>
                            <span>mdi-server-network-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-off"></i>
                            <code>f48f</code>
                            <span>mdi-server-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-plus"></i>
                            <code>f490</code>
                            <span>mdi-server-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-remove"></i>
                            <code>f491</code>
                            <span>mdi-server-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-server-security"></i>
                            <code>f492</code>
                            <span>mdi-server-security</span>
                        </div>
                        <div>
                            <i class="mdi mdi-settings"></i>
                            <code>f493</code>
                            <span>mdi-settings</span>
                        </div>
                        <div>
                            <i class="mdi mdi-settings-box"></i>
                            <code>f494</code>
                            <span>mdi-settings-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shape-circle-plus"></i>
                            <code>f65d</code>
                            <span>mdi-shape-circle-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shape-plus"></i>
                            <code>f495</code>
                            <span>mdi-shape-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shape-polygon-plus"></i>
                            <code>f65e</code>
                            <span>mdi-shape-polygon-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shape-rectangle-plus"></i>
                            <code>f65f</code>
                            <span>mdi-shape-rectangle-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shape-square-plus"></i>
                            <code>f660</code>
                            <span>mdi-shape-square-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-share"></i>
                            <code>f496</code>
                            <span>mdi-share</span>
                        </div>
                        <div>
                            <i class="mdi mdi-share-variant"></i>
                            <code>f497</code>
                            <span>mdi-share-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shield"></i>
                            <code>f498</code>
                            <span>mdi-shield</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shield-outline"></i>
                            <code>f499</code>
                            <span>mdi-shield-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shopping"></i>
                            <code>f49a</code>
                            <span>mdi-shopping</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shopping-music"></i>
                            <code>f49b</code>
                            <span>mdi-shopping-music</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shovel"></i>
                            <code>f70f</code>
                            <span>mdi-shovel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shovel-off"></i>
                            <code>f710</code>
                            <span>mdi-shovel-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shredder"></i>
                            <code>f49c</code>
                            <span>mdi-shredder</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shuffle"></i>
                            <code>f49d</code>
                            <span>mdi-shuffle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shuffle-disabled"></i>
                            <code>f49e</code>
                            <span>mdi-shuffle-disabled</span>
                        </div>
                        <div>
                            <i class="mdi mdi-shuffle-variant"></i>
                            <code>f49f</code>
                            <span>mdi-shuffle-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sigma"></i>
                            <code>f4a0</code>
                            <span>mdi-sigma</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sigma-lower"></i>
                            <code>f62b</code>
                            <span>mdi-sigma-lower</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sign-caution"></i>
                            <code>f4a1</code>
                            <span>mdi-sign-caution</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal"></i>
                            <code>f4a2</code>
                            <span>mdi-signal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-2g"></i>
                            <code>f711</code>
                            <span>mdi-signal-2g</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-3g"></i>
                            <code>f712</code>
                            <span>mdi-signal-3g</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-4g"></i>
                            <code>f713</code>
                            <span>mdi-signal-4g</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-hspa"></i>
                            <code>f714</code>
                            <span>mdi-signal-hspa</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-hspa-plus"></i>
                            <code>f715</code>
                            <span>mdi-signal-hspa-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-signal-variant"></i>
                            <code>f60a</code>
                            <span>mdi-signal-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-silverware"></i>
                            <code>f4a3</code>
                            <span>mdi-silverware</span>
                        </div>
                        <div>
                            <i class="mdi mdi-silverware-fork"></i>
                            <code>f4a4</code>
                            <span>mdi-silverware-fork</span>
                        </div>
                        <div>
                            <i class="mdi mdi-silverware-spoon"></i>
                            <code>f4a5</code>
                            <span>mdi-silverware-spoon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-silverware-variant"></i>
                            <code>f4a6</code>
                            <span>mdi-silverware-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sim"></i>
                            <code>f4a7</code>
                            <span>mdi-sim</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sim-alert"></i>
                            <code>f4a8</code>
                            <span>mdi-sim-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sim-off"></i>
                            <code>f4a9</code>
                            <span>mdi-sim-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sitemap"></i>
                            <code>f4aa</code>
                            <span>mdi-sitemap</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-backward"></i>
                            <code>f4ab</code>
                            <span>mdi-skip-backward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-forward"></i>
                            <code>f4ac</code>
                            <span>mdi-skip-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-next"></i>
                            <code>f4ad</code>
                            <span>mdi-skip-next</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-next-circle"></i>
                            <code>f661</code>
                            <span>mdi-skip-next-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-next-circle-outline"></i>
                            <code>f662</code>
                            <span>mdi-skip-next-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-previous"></i>
                            <code>f4ae</code>
                            <span>mdi-skip-previous</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-previous-circle"></i>
                            <code>f663</code>
                            <span>mdi-skip-previous-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skip-previous-circle-outline"></i>
                            <code>f664</code>
                            <span>mdi-skip-previous-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skull"></i>
                            <code>f68b</code>
                            <span>mdi-skull</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skype"></i>
                            <code>f4af</code>
                            <span>mdi-skype</span>
                        </div>
                        <div>
                            <i class="mdi mdi-skype-business"></i>
                            <code>f4b0</code>
                            <span>mdi-skype-business</span>
                        </div>
                        <div>
                            <i class="mdi mdi-slack"></i>
                            <code>f4b1</code>
                            <span>mdi-slack</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sleep"></i>
                            <code>f4b2</code>
                            <span>mdi-sleep</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sleep-off"></i>
                            <code>f4b3</code>
                            <span>mdi-sleep-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-smoking"></i>
                            <code>f4b4</code>
                            <span>mdi-smoking</span>
                        </div>
                        <div>
                            <i class="mdi mdi-smoking-off"></i>
                            <code>f4b5</code>
                            <span>mdi-smoking-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-snapchat"></i>
                            <code>f4b6</code>
                            <span>mdi-snapchat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-snowflake"></i>
                            <code>f716</code>
                            <span>mdi-snowflake</span>
                        </div>
                        <div>
                            <i class="mdi mdi-snowman"></i>
                            <code>f4b7</code>
                            <span>mdi-snowman</span>
                        </div>
                        <div>
                            <i class="mdi mdi-soccer"></i>
                            <code>f4b8</code>
                            <span>mdi-soccer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sofa"></i>
                            <code>f4b9</code>
                            <span>mdi-sofa</span>
                        </div>
                        <div>
                            <i class="mdi mdi-solid"></i>
                            <code>f68c</code>
                            <span>mdi-solid</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort"></i>
                            <code>f4ba</code>
                            <span>mdi-sort</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort-alphabetical"></i>
                            <code>f4bb</code>
                            <span>mdi-sort-alphabetical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort-ascending"></i>
                            <code>f4bc</code>
                            <span>mdi-sort-ascending</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort-descending"></i>
                            <code>f4bd</code>
                            <span>mdi-sort-descending</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort-numeric"></i>
                            <code>f4be</code>
                            <span>mdi-sort-numeric</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sort-variant"></i>
                            <code>f4bf</code>
                            <span>mdi-sort-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-soundcloud"></i>
                            <code>f4c0</code>
                            <span>mdi-soundcloud</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-branch"></i>
                            <code>f62c</code>
                            <span>mdi-source-branch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit"></i>
                            <code>f717</code>
                            <span>mdi-source-commit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-end"></i>
                            <code>f718</code>
                            <span>mdi-source-commit-end</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-end-local"></i>
                            <code>f719</code>
                            <span>mdi-source-commit-end-local</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-local"></i>
                            <code>f71a</code>
                            <span>mdi-source-commit-local</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-next-local"></i>
                            <code>f71b</code>
                            <span>mdi-source-commit-next-local</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-start"></i>
                            <code>f71c</code>
                            <span>mdi-source-commit-start</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-commit-start-next-local"></i>
                            <code>f71d</code>
                            <span>mdi-source-commit-start-next-local</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-fork"></i>
                            <code>f4c1</code>
                            <span>mdi-source-fork</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-merge"></i>
                            <code>f62d</code>
                            <span>mdi-source-merge</span>
                        </div>
                        <div>
                            <i class="mdi mdi-source-pull"></i>
                            <code>f4c2</code>
                            <span>mdi-source-pull</span>
                        </div>
                        <div>
                            <i class="mdi mdi-speaker"></i>
                            <code>f4c3</code>
                            <span>mdi-speaker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-speaker-off"></i>
                            <code>f4c4</code>
                            <span>mdi-speaker-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-speaker-wireless"></i>
                            <code>f71e</code>
                            <span>mdi-speaker-wireless</span>
                        </div>
                        <div>
                            <i class="mdi mdi-speedometer"></i>
                            <code>f4c5</code>
                            <span>mdi-speedometer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-spellcheck"></i>
                            <code>f4c6</code>
                            <span>mdi-spellcheck</span>
                        </div>
                        <div>
                            <i class="mdi mdi-spotify"></i>
                            <code>f4c7</code>
                            <span>mdi-spotify</span>
                        </div>
                        <div>
                            <i class="mdi mdi-spotlight"></i>
                            <code>f4c8</code>
                            <span>mdi-spotlight</span>
                        </div>
                        <div>
                            <i class="mdi mdi-spotlight-beam"></i>
                            <code>f4c9</code>
                            <span>mdi-spotlight-beam</span>
                        </div>
                        <div>
                            <i class="mdi mdi-spray"></i>
                            <code>f665</code>
                            <span>mdi-spray</span>
                        </div>
                        <div>
                            <i class="mdi mdi-square-inc"></i>
                            <code>f4ca</code>
                            <span>mdi-square-inc</span>
                        </div>
                        <div>
                            <i class="mdi mdi-square-inc-cash"></i>
                            <code>f4cb</code>
                            <span>mdi-square-inc-cash</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stackexchange"></i>
                            <code>f60b</code>
                            <span>mdi-stackexchange</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stackoverflow"></i>
                            <code>f4cc</code>
                            <span>mdi-stackoverflow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stadium"></i>
                            <code>f71f</code>
                            <span>mdi-stadium</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stairs"></i>
                            <code>f4cd</code>
                            <span>mdi-stairs</span>
                        </div>
                        <div>
                            <i class="mdi mdi-star"></i>
                            <code>f4ce</code>
                            <span>mdi-star</span>
                        </div>
                        <div>
                            <i class="mdi mdi-star-circle"></i>
                            <code>f4cf</code>
                            <span>mdi-star-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-star-half"></i>
                            <code>f4d0</code>
                            <span>mdi-star-half</span>
                        </div>
                        <div>
                            <i class="mdi mdi-star-off"></i>
                            <code>f4d1</code>
                            <span>mdi-star-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-star-outline"></i>
                            <code>f4d2</code>
                            <span>mdi-star-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-steam"></i>
                            <code>f4d3</code>
                            <span>mdi-steam</span>
                        </div>
                        <div>
                            <i class="mdi mdi-steering"></i>
                            <code>f4d4</code>
                            <span>mdi-steering</span>
                        </div>
                        <div>
                            <i class="mdi mdi-step-backward"></i>
                            <code>f4d5</code>
                            <span>mdi-step-backward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-step-backward-2"></i>
                            <code>f4d6</code>
                            <span>mdi-step-backward-2</span>
                        </div>
                        <div>
                            <i class="mdi mdi-step-forward"></i>
                            <code>f4d7</code>
                            <span>mdi-step-forward</span>
                        </div>
                        <div>
                            <i class="mdi mdi-step-forward-2"></i>
                            <code>f4d8</code>
                            <span>mdi-step-forward-2</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stethoscope"></i>
                            <code>f4d9</code>
                            <span>mdi-stethoscope</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sticker"></i>
                            <code>f5d0</code>
                            <span>mdi-sticker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stocking"></i>
                            <code>f4da</code>
                            <span>mdi-stocking</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stop"></i>
                            <code>f4db</code>
                            <span>mdi-stop</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stop-circle"></i>
                            <code>f666</code>
                            <span>mdi-stop-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stop-circle-outline"></i>
                            <code>f667</code>
                            <span>mdi-stop-circle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-store"></i>
                            <code>f4dc</code>
                            <span>mdi-store</span>
                        </div>
                        <div>
                            <i class="mdi mdi-store-24-hour"></i>
                            <code>f4dd</code>
                            <span>mdi-store-24-hour</span>
                        </div>
                        <div>
                            <i class="mdi mdi-stove"></i>
                            <code>f4de</code>
                            <span>mdi-stove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-subdirectory-arrow-left"></i>
                            <code>f60c</code>
                            <span>mdi-subdirectory-arrow-left</span>
                        </div>
                        <div>
                            <i class="mdi mdi-subdirectory-arrow-right"></i>
                            <code>f60d</code>
                            <span>mdi-subdirectory-arrow-right</span>
                        </div>
                        <div>
                            <i class="mdi mdi-subway"></i>
                            <code>f6ab</code>
                            <span>mdi-subway</span>
                        </div>
                        <div>
                            <i class="mdi mdi-subway-variant"></i>
                            <code>f4df</code>
                            <span>mdi-subway-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sunglasses"></i>
                            <code>f4e0</code>
                            <span>mdi-sunglasses</span>
                        </div>
                        <div>
                            <i class="mdi mdi-surround-sound"></i>
                            <code>f5c5</code>
                            <span>mdi-surround-sound</span>
                        </div>
                        <div>
                            <i class="mdi mdi-svg"></i>
                            <code>f720</code>
                            <span>mdi-svg</span>
                        </div>
                        <div>
                            <i class="mdi mdi-swap-horizontal"></i>
                            <code>f4e1</code>
                            <span>mdi-swap-horizontal</span>
                        </div>
                        <div>
                            <i class="mdi mdi-swap-vertical"></i>
                            <code>f4e2</code>
                            <span>mdi-swap-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-swim"></i>
                            <code>f4e3</code>
                            <span>mdi-swim</span>
                        </div>
                        <div>
                            <i class="mdi mdi-switch"></i>
                            <code>f4e4</code>
                            <span>mdi-switch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sword"></i>
                            <code>f4e5</code>
                            <span>mdi-sword</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sync"></i>
                            <code>f4e6</code>
                            <span>mdi-sync</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sync-alert"></i>
                            <code>f4e7</code>
                            <span>mdi-sync-alert</span>
                        </div>
                        <div>
                            <i class="mdi mdi-sync-off"></i>
                            <code>f4e8</code>
                            <span>mdi-sync-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tab"></i>
                            <code>f4e9</code>
                            <span>mdi-tab</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tab-unselected"></i>
                            <code>f4ea</code>
                            <span>mdi-tab-unselected</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table"></i>
                            <code>f4eb</code>
                            <span>mdi-table</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-column-plus-after"></i>
                            <code>f4ec</code>
                            <span>mdi-table-column-plus-after</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-column-plus-before"></i>
                            <code>f4ed</code>
                            <span>mdi-table-column-plus-before</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-column-remove"></i>
                            <code>f4ee</code>
                            <span>mdi-table-column-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-column-width"></i>
                            <code>f4ef</code>
                            <span>mdi-table-column-width</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-edit"></i>
                            <code>f4f0</code>
                            <span>mdi-table-edit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-large"></i>
                            <code>f4f1</code>
                            <span>mdi-table-large</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-row-height"></i>
                            <code>f4f2</code>
                            <span>mdi-table-row-height</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-row-plus-after"></i>
                            <code>f4f3</code>
                            <span>mdi-table-row-plus-after</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-row-plus-before"></i>
                            <code>f4f4</code>
                            <span>mdi-table-row-plus-before</span>
                        </div>
                        <div>
                            <i class="mdi mdi-table-row-remove"></i>
                            <code>f4f5</code>
                            <span>mdi-table-row-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tablet"></i>
                            <code>f4f6</code>
                            <span>mdi-tablet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tablet-android"></i>
                            <code>f4f7</code>
                            <span>mdi-tablet-android</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tablet-ipad"></i>
                            <code>f4f8</code>
                            <span>mdi-tablet-ipad</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag"></i>
                            <code>f4f9</code>
                            <span>mdi-tag</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-faces"></i>
                            <code>f4fa</code>
                            <span>mdi-tag-faces</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-heart"></i>
                            <code>f68a</code>
                            <span>mdi-tag-heart</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-multiple"></i>
                            <code>f4fb</code>
                            <span>mdi-tag-multiple</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-outline"></i>
                            <code>f4fc</code>
                            <span>mdi-tag-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-plus"></i>
                            <code>f721</code>
                            <span>mdi-tag-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-remove"></i>
                            <code>f722</code>
                            <span>mdi-tag-remove</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tag-text-outline"></i>
                            <code>f4fd</code>
                            <span>mdi-tag-text-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-target"></i>
                            <code>f4fe</code>
                            <span>mdi-target</span>
                        </div>
                        <div>
                            <i class="mdi mdi-taxi"></i>
                            <code>f4ff</code>
                            <span>mdi-taxi</span>
                        </div>
                        <div>
                            <i class="mdi mdi-teamviewer"></i>
                            <code>f500</code>
                            <span>mdi-teamviewer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-telegram"></i>
                            <code>f501</code>
                            <span>mdi-telegram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-television"></i>
                            <code>f502</code>
                            <span>mdi-television</span>
                        </div>
                        <div>
                            <i class="mdi mdi-television-guide"></i>
                            <code>f503</code>
                            <span>mdi-television-guide</span>
                        </div>
                        <div>
                            <i class="mdi mdi-temperature-celsius"></i>
                            <code>f504</code>
                            <span>mdi-temperature-celsius</span>
                        </div>
                        <div>
                            <i class="mdi mdi-temperature-fahrenheit"></i>
                            <code>f505</code>
                            <span>mdi-temperature-fahrenheit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-temperature-kelvin"></i>
                            <code>f506</code>
                            <span>mdi-temperature-kelvin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tennis"></i>
                            <code>f507</code>
                            <span>mdi-tennis</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tent"></i>
                            <code>f508</code>
                            <span>mdi-tent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-terrain"></i>
                            <code>f509</code>
                            <span>mdi-terrain</span>
                        </div>
                        <div>
                            <i class="mdi mdi-test-tube"></i>
                            <code>f668</code>
                            <span>mdi-test-tube</span>
                        </div>
                        <div>
                            <i class="mdi mdi-text-shadow"></i>
                            <code>f669</code>
                            <span>mdi-text-shadow</span>
                        </div>
                        <div>
                            <i class="mdi mdi-text-to-speech"></i>
                            <code>f50a</code>
                            <span>mdi-text-to-speech</span>
                        </div>
                        <div>
                            <i class="mdi mdi-text-to-speech-off"></i>
                            <code>f50b</code>
                            <span>mdi-text-to-speech-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-textbox"></i>
                            <code>f60e</code>
                            <span>mdi-textbox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-texture"></i>
                            <code>f50c</code>
                            <span>mdi-texture</span>
                        </div>
                        <div>
                            <i class="mdi mdi-theater"></i>
                            <code>f50d</code>
                            <span>mdi-theater</span>
                        </div>
                        <div>
                            <i class="mdi mdi-theme-light-dark"></i>
                            <code>f50e</code>
                            <span>mdi-theme-light-dark</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thermometer"></i>
                            <code>f50f</code>
                            <span>mdi-thermometer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thermometer-lines"></i>
                            <code>f510</code>
                            <span>mdi-thermometer-lines</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thumb-down"></i>
                            <code>f511</code>
                            <span>mdi-thumb-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thumb-down-outline"></i>
                            <code>f512</code>
                            <span>mdi-thumb-down-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thumb-up"></i>
                            <code>f513</code>
                            <span>mdi-thumb-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thumb-up-outline"></i>
                            <code>f514</code>
                            <span>mdi-thumb-up-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-thumbs-up-down"></i>
                            <code>f515</code>
                            <span>mdi-thumbs-up-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ticket"></i>
                            <code>f516</code>
                            <span>mdi-ticket</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ticket-account"></i>
                            <code>f517</code>
                            <span>mdi-ticket-account</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ticket-confirmation"></i>
                            <code>f518</code>
                            <span>mdi-ticket-confirmation</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ticket-percent"></i>
                            <code>f723</code>
                            <span>mdi-ticket-percent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tie"></i>
                            <code>f519</code>
                            <span>mdi-tie</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tilde"></i>
                            <code>f724</code>
                            <span>mdi-tilde</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timelapse"></i>
                            <code>f51a</code>
                            <span>mdi-timelapse</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer"></i>
                            <code>f51b</code>
                            <span>mdi-timer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer-10"></i>
                            <code>f51c</code>
                            <span>mdi-timer-10</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer-3"></i>
                            <code>f51d</code>
                            <span>mdi-timer-3</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer-off"></i>
                            <code>f51e</code>
                            <span>mdi-timer-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer-sand"></i>
                            <code>f51f</code>
                            <span>mdi-timer-sand</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timer-sand-empty"></i>
                            <code>f6ac</code>
                            <span>mdi-timer-sand-empty</span>
                        </div>
                        <div>
                            <i class="mdi mdi-timetable"></i>
                            <code>f520</code>
                            <span>mdi-timetable</span>
                        </div>
                        <div>
                            <i class="mdi mdi-toggle-switch"></i>
                            <code>f521</code>
                            <span>mdi-toggle-switch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-toggle-switch-off"></i>
                            <code>f522</code>
                            <span>mdi-toggle-switch-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip"></i>
                            <code>f523</code>
                            <span>mdi-tooltip</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip-edit"></i>
                            <code>f524</code>
                            <span>mdi-tooltip-edit</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip-image"></i>
                            <code>f525</code>
                            <span>mdi-tooltip-image</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip-outline"></i>
                            <code>f526</code>
                            <span>mdi-tooltip-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip-outline-plus"></i>
                            <code>f527</code>
                            <span>mdi-tooltip-outline-plus</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooltip-text"></i>
                            <code>f528</code>
                            <span>mdi-tooltip-text</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tooth"></i>
                            <code>f529</code>
                            <span>mdi-tooth</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tor"></i>
                            <code>f52a</code>
                            <span>mdi-tor</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tower-beach"></i>
                            <code>f680</code>
                            <span>mdi-tower-beach</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tower-fire"></i>
                            <code>f681</code>
                            <span>mdi-tower-fire</span>
                        </div>
                        <div>
                            <i class="mdi mdi-traffic-light"></i>
                            <code>f52b</code>
                            <span>mdi-traffic-light</span>
                        </div>
                        <div>
                            <i class="mdi mdi-train"></i>
                            <code>f52c</code>
                            <span>mdi-train</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tram"></i>
                            <code>f52d</code>
                            <span>mdi-tram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-transcribe"></i>
                            <code>f52e</code>
                            <span>mdi-transcribe</span>
                        </div>
                        <div>
                            <i class="mdi mdi-transcribe-close"></i>
                            <code>f52f</code>
                            <span>mdi-transcribe-close</span>
                        </div>
                        <div>
                            <i class="mdi mdi-transfer"></i>
                            <code>f530</code>
                            <span>mdi-transfer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-transit-transfer"></i>
                            <code>f6ad</code>
                            <span>mdi-transit-transfer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-translate"></i>
                            <code>f5ca</code>
                            <span>mdi-translate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-treasure-chest"></i>
                            <code>f725</code>
                            <span>mdi-treasure-chest</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tree"></i>
                            <code>f531</code>
                            <span>mdi-tree</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trello"></i>
                            <code>f532</code>
                            <span>mdi-trello</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trending-down"></i>
                            <code>f533</code>
                            <span>mdi-trending-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trending-neutral"></i>
                            <code>f534</code>
                            <span>mdi-trending-neutral</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trending-up"></i>
                            <code>f535</code>
                            <span>mdi-trending-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-triangle"></i>
                            <code>f536</code>
                            <span>mdi-triangle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-triangle-outline"></i>
                            <code>f537</code>
                            <span>mdi-triangle-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trophy"></i>
                            <code>f538</code>
                            <span>mdi-trophy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trophy-award"></i>
                            <code>f539</code>
                            <span>mdi-trophy-award</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trophy-outline"></i>
                            <code>f53a</code>
                            <span>mdi-trophy-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trophy-variant"></i>
                            <code>f53b</code>
                            <span>mdi-trophy-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-trophy-variant-outline"></i>
                            <code>f53c</code>
                            <span>mdi-trophy-variant-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-truck"></i>
                            <code>f53d</code>
                            <span>mdi-truck</span>
                        </div>
                        <div>
                            <i class="mdi mdi-truck-delivery"></i>
                            <code>f53e</code>
                            <span>mdi-truck-delivery</span>
                        </div>
                        <div>
                            <i class="mdi mdi-truck-trailer"></i>
                            <code>f726</code>
                            <span>mdi-truck-trailer</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tshirt-crew"></i>
                            <code>f53f</code>
                            <span>mdi-tshirt-crew</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tshirt-v"></i>
                            <code>f540</code>
                            <span>mdi-tshirt-v</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tumblr"></i>
                            <code>f541</code>
                            <span>mdi-tumblr</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tumblr-reblog"></i>
                            <code>f542</code>
                            <span>mdi-tumblr-reblog</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tune"></i>
                            <code>f62e</code>
                            <span>mdi-tune</span>
                        </div>
                        <div>
                            <i class="mdi mdi-tune-vertical"></i>
                            <code>f66a</code>
                            <span>mdi-tune-vertical</span>
                        </div>
                        <div>
                            <i class="mdi mdi-twitch"></i>
                            <code>f543</code>
                            <span>mdi-twitch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-twitter"></i>
                            <code>f544</code>
                            <span>mdi-twitter</span>
                        </div>
                        <div>
                            <i class="mdi mdi-twitter-box"></i>
                            <code>f545</code>
                            <span>mdi-twitter-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-twitter-circle"></i>
                            <code>f546</code>
                            <span>mdi-twitter-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-twitter-retweet"></i>
                            <code>f547</code>
                            <span>mdi-twitter-retweet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ubuntu"></i>
                            <code>f548</code>
                            <span>mdi-ubuntu</span>
                        </div>
                        <div>
                            <i class="mdi mdi-umbraco"></i>
                            <code>f549</code>
                            <span>mdi-umbraco</span>
                        </div>
                        <div>
                            <i class="mdi mdi-umbrella"></i>
                            <code>f54a</code>
                            <span>mdi-umbrella</span>
                        </div>
                        <div>
                            <i class="mdi mdi-umbrella-outline"></i>
                            <code>f54b</code>
                            <span>mdi-umbrella-outline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-undo"></i>
                            <code>f54c</code>
                            <span>mdi-undo</span>
                        </div>
                        <div>
                            <i class="mdi mdi-undo-variant"></i>
                            <code>f54d</code>
                            <span>mdi-undo-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-unfold-less"></i>
                            <code>f54e</code>
                            <span>mdi-unfold-less</span>
                        </div>
                        <div>
                            <i class="mdi mdi-unfold-more"></i>
                            <code>f54f</code>
                            <span>mdi-unfold-more</span>
                        </div>
                        <div>
                            <i class="mdi mdi-ungroup"></i>
                            <code>f550</code>
                            <span>mdi-ungroup</span>
                        </div>
                        <div>
                            <i class="mdi mdi-unity"></i>
                            <code>f6ae</code>
                            <span>mdi-unity</span>
                        </div>
                        <div>
                            <i class="mdi mdi-untappd"></i>
                            <code>f551</code>
                            <span>mdi-untappd</span>
                        </div>
                        <div>
                            <i class="mdi mdi-update"></i>
                            <code>f6af</code>
                            <span>mdi-update</span>
                        </div>
                        <div>
                            <i class="mdi mdi-upload"></i>
                            <code>f552</code>
                            <span>mdi-upload</span>
                        </div>
                        <div>
                            <i class="mdi mdi-usb"></i>
                            <code>f553</code>
                            <span>mdi-usb</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-arrange-above"></i>
                            <code>f554</code>
                            <span>mdi-vector-arrange-above</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-arrange-below"></i>
                            <code>f555</code>
                            <span>mdi-vector-arrange-below</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-circle"></i>
                            <code>f556</code>
                            <span>mdi-vector-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-circle-variant"></i>
                            <code>f557</code>
                            <span>mdi-vector-circle-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-combine"></i>
                            <code>f558</code>
                            <span>mdi-vector-combine</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-curve"></i>
                            <code>f559</code>
                            <span>mdi-vector-curve</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-difference"></i>
                            <code>f55a</code>
                            <span>mdi-vector-difference</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-difference-ab"></i>
                            <code>f55b</code>
                            <span>mdi-vector-difference-ab</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-difference-ba"></i>
                            <code>f55c</code>
                            <span>mdi-vector-difference-ba</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-intersection"></i>
                            <code>f55d</code>
                            <span>mdi-vector-intersection</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-line"></i>
                            <code>f55e</code>
                            <span>mdi-vector-line</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-point"></i>
                            <code>f55f</code>
                            <span>mdi-vector-point</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-polygon"></i>
                            <code>f560</code>
                            <span>mdi-vector-polygon</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-polyline"></i>
                            <code>f561</code>
                            <span>mdi-vector-polyline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-rectangle"></i>
                            <code>f5c6</code>
                            <span>mdi-vector-rectangle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-selection"></i>
                            <code>f562</code>
                            <span>mdi-vector-selection</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-square"></i>
                            <code>f001</code>
                            <span>mdi-vector-square</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-triangle"></i>
                            <code>f563</code>
                            <span>mdi-vector-triangle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vector-union"></i>
                            <code>f564</code>
                            <span>mdi-vector-union</span>
                        </div>
                        <div>
                            <i class="mdi mdi-verified"></i>
                            <code>f565</code>
                            <span>mdi-verified</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vibrate"></i>
                            <code>f566</code>
                            <span>mdi-vibrate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-video"></i>
                            <code>f567</code>
                            <span>mdi-video</span>
                        </div>
                        <div>
                            <i class="mdi mdi-video-off"></i>
                            <code>f568</code>
                            <span>mdi-video-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-video-switch"></i>
                            <code>f569</code>
                            <span>mdi-video-switch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-agenda"></i>
                            <code>f56a</code>
                            <span>mdi-view-agenda</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-array"></i>
                            <code>f56b</code>
                            <span>mdi-view-array</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-carousel"></i>
                            <code>f56c</code>
                            <span>mdi-view-carousel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-column"></i>
                            <code>f56d</code>
                            <span>mdi-view-column</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-dashboard"></i>
                            <code>f56e</code>
                            <span>mdi-view-dashboard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-day"></i>
                            <code>f56f</code>
                            <span>mdi-view-day</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-grid"></i>
                            <code>f570</code>
                            <span>mdi-view-grid</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-headline"></i>
                            <code>f571</code>
                            <span>mdi-view-headline</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-list"></i>
                            <code>f572</code>
                            <span>mdi-view-list</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-module"></i>
                            <code>f573</code>
                            <span>mdi-view-module</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-parallel"></i>
                            <code>f727</code>
                            <span>mdi-view-parallel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-quilt"></i>
                            <code>f574</code>
                            <span>mdi-view-quilt</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-sequential"></i>
                            <code>f728</code>
                            <span>mdi-view-sequential</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-stream"></i>
                            <code>f575</code>
                            <span>mdi-view-stream</span>
                        </div>
                        <div>
                            <i class="mdi mdi-view-week"></i>
                            <code>f576</code>
                            <span>mdi-view-week</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vimeo"></i>
                            <code>f577</code>
                            <span>mdi-vimeo</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vine"></i>
                            <code>f578</code>
                            <span>mdi-vine</span>
                        </div>
                        <div>
                            <i class="mdi mdi-violin"></i>
                            <code>f60f</code>
                            <span>mdi-violin</span>
                        </div>
                        <div>
                            <i class="mdi mdi-visualstudio"></i>
                            <code>f610</code>
                            <span>mdi-visualstudio</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vk"></i>
                            <code>f579</code>
                            <span>mdi-vk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vk-box"></i>
                            <code>f57a</code>
                            <span>mdi-vk-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vk-circle"></i>
                            <code>f57b</code>
                            <span>mdi-vk-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vlc"></i>
                            <code>f57c</code>
                            <span>mdi-vlc</span>
                        </div>
                        <div>
                            <i class="mdi mdi-voice"></i>
                            <code>f5cb</code>
                            <span>mdi-voice</span>
                        </div>
                        <div>
                            <i class="mdi mdi-voicemail"></i>
                            <code>f57d</code>
                            <span>mdi-voicemail</span>
                        </div>
                        <div>
                            <i class="mdi mdi-volume-high"></i>
                            <code>f57e</code>
                            <span>mdi-volume-high</span>
                        </div>
                        <div>
                            <i class="mdi mdi-volume-low"></i>
                            <code>f57f</code>
                            <span>mdi-volume-low</span>
                        </div>
                        <div>
                            <i class="mdi mdi-volume-medium"></i>
                            <code>f580</code>
                            <span>mdi-volume-medium</span>
                        </div>
                        <div>
                            <i class="mdi mdi-volume-off"></i>
                            <code>f581</code>
                            <span>mdi-volume-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-vpn"></i>
                            <code>f582</code>
                            <span>mdi-vpn</span>
                        </div>
                        <div>
                            <i class="mdi mdi-walk"></i>
                            <code>f583</code>
                            <span>mdi-walk</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wallet"></i>
                            <code>f584</code>
                            <span>mdi-wallet</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wallet-giftcard"></i>
                            <code>f585</code>
                            <span>mdi-wallet-giftcard</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wallet-membership"></i>
                            <code>f586</code>
                            <span>mdi-wallet-membership</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wallet-travel"></i>
                            <code>f587</code>
                            <span>mdi-wallet-travel</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wan"></i>
                            <code>f588</code>
                            <span>mdi-wan</span>
                        </div>
                        <div>
                            <i class="mdi mdi-washing-machine"></i>
                            <code>f729</code>
                            <span>mdi-washing-machine</span>
                        </div>
                        <div>
                            <i class="mdi mdi-watch"></i>
                            <code>f589</code>
                            <span>mdi-watch</span>
                        </div>
                        <div>
                            <i class="mdi mdi-watch-export"></i>
                            <code>f58a</code>
                            <span>mdi-watch-export</span>
                        </div>
                        <div>
                            <i class="mdi mdi-watch-import"></i>
                            <code>f58b</code>
                            <span>mdi-watch-import</span>
                        </div>
                        <div>
                            <i class="mdi mdi-watch-vibrate"></i>
                            <code>f6b0</code>
                            <span>mdi-watch-vibrate</span>
                        </div>
                        <div>
                            <i class="mdi mdi-water"></i>
                            <code>f58c</code>
                            <span>mdi-water</span>
                        </div>
                        <div>
                            <i class="mdi mdi-water-off"></i>
                            <code>f58d</code>
                            <span>mdi-water-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-water-percent"></i>
                            <code>f58e</code>
                            <span>mdi-water-percent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-water-pump"></i>
                            <code>f58f</code>
                            <span>mdi-water-pump</span>
                        </div>
                        <div>
                            <i class="mdi mdi-watermark"></i>
                            <code>f612</code>
                            <span>mdi-watermark</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-cloudy"></i>
                            <code>f590</code>
                            <span>mdi-weather-cloudy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-fog"></i>
                            <code>f591</code>
                            <span>mdi-weather-fog</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-hail"></i>
                            <code>f592</code>
                            <span>mdi-weather-hail</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-lightning"></i>
                            <code>f593</code>
                            <span>mdi-weather-lightning</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-lightning-rainy"></i>
                            <code>f67d</code>
                            <span>mdi-weather-lightning-rainy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-night"></i>
                            <code>f594</code>
                            <span>mdi-weather-night</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-partlycloudy"></i>
                            <code>f595</code>
                            <span>mdi-weather-partlycloudy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-pouring"></i>
                            <code>f596</code>
                            <span>mdi-weather-pouring</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-rainy"></i>
                            <code>f597</code>
                            <span>mdi-weather-rainy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-snowy"></i>
                            <code>f598</code>
                            <span>mdi-weather-snowy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-snowy-rainy"></i>
                            <code>f67e</code>
                            <span>mdi-weather-snowy-rainy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-sunny"></i>
                            <code>f599</code>
                            <span>mdi-weather-sunny</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-sunset"></i>
                            <code>f59a</code>
                            <span>mdi-weather-sunset</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-sunset-down"></i>
                            <code>f59b</code>
                            <span>mdi-weather-sunset-down</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-sunset-up"></i>
                            <code>f59c</code>
                            <span>mdi-weather-sunset-up</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-windy"></i>
                            <code>f59d</code>
                            <span>mdi-weather-windy</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weather-windy-variant"></i>
                            <code>f59e</code>
                            <span>mdi-weather-windy-variant</span>
                        </div>
                        <div>
                            <i class="mdi mdi-web"></i>
                            <code>f59f</code>
                            <span>mdi-web</span>
                        </div>
                        <div>
                            <i class="mdi mdi-webcam"></i>
                            <code>f5a0</code>
                            <span>mdi-webcam</span>
                        </div>
                        <div>
                            <i class="mdi mdi-webhook"></i>
                            <code>f62f</code>
                            <span>mdi-webhook</span>
                        </div>
                        <div>
                            <i class="mdi mdi-webpack"></i>
                            <code>f72a</code>
                            <span>mdi-webpack</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wechat"></i>
                            <code>f611</code>
                            <span>mdi-wechat</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weight"></i>
                            <code>f5a1</code>
                            <span>mdi-weight</span>
                        </div>
                        <div>
                            <i class="mdi mdi-weight-kilogram"></i>
                            <code>f5a2</code>
                            <span>mdi-weight-kilogram</span>
                        </div>
                        <div>
                            <i class="mdi mdi-whatsapp"></i>
                            <code>f5a3</code>
                            <span>mdi-whatsapp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wheelchair-accessibility"></i>
                            <code>f5a4</code>
                            <span>mdi-wheelchair-accessibility</span>
                        </div>
                        <div>
                            <i class="mdi mdi-white-balance-auto"></i>
                            <code>f5a5</code>
                            <span>mdi-white-balance-auto</span>
                        </div>
                        <div>
                            <i class="mdi mdi-white-balance-incandescent"></i>
                            <code>f5a6</code>
                            <span>mdi-white-balance-incandescent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-white-balance-iridescent"></i>
                            <code>f5a7</code>
                            <span>mdi-white-balance-iridescent</span>
                        </div>
                        <div>
                            <i class="mdi mdi-white-balance-sunny"></i>
                            <code>f5a8</code>
                            <span>mdi-white-balance-sunny</span>
                        </div>
                        <div>
                            <i class="mdi mdi-widgets"></i>
                            <code>f72b</code>
                            <span>mdi-widgets</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wifi"></i>
                            <code>f5a9</code>
                            <span>mdi-wifi</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wifi-off"></i>
                            <code>f5aa</code>
                            <span>mdi-wifi-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wii"></i>
                            <code>f5ab</code>
                            <span>mdi-wii</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wiiu"></i>
                            <code>f72c</code>
                            <span>mdi-wiiu</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wikipedia"></i>
                            <code>f5ac</code>
                            <span>mdi-wikipedia</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-close"></i>
                            <code>f5ad</code>
                            <span>mdi-window-close</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-closed"></i>
                            <code>f5ae</code>
                            <span>mdi-window-closed</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-maximize"></i>
                            <code>f5af</code>
                            <span>mdi-window-maximize</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-minimize"></i>
                            <code>f5b0</code>
                            <span>mdi-window-minimize</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-open"></i>
                            <code>f5b1</code>
                            <span>mdi-window-open</span>
                        </div>
                        <div>
                            <i class="mdi mdi-window-restore"></i>
                            <code>f5b2</code>
                            <span>mdi-window-restore</span>
                        </div>
                        <div>
                            <i class="mdi mdi-windows"></i>
                            <code>f5b3</code>
                            <span>mdi-windows</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wordpress"></i>
                            <code>f5b4</code>
                            <span>mdi-wordpress</span>
                        </div>
                        <div>
                            <i class="mdi mdi-worker"></i>
                            <code>f5b5</code>
                            <span>mdi-worker</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wrap"></i>
                            <code>f5b6</code>
                            <span>mdi-wrap</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wrench"></i>
                            <code>f5b7</code>
                            <span>mdi-wrench</span>
                        </div>
                        <div>
                            <i class="mdi mdi-wunderlist"></i>
                            <code>f5b8</code>
                            <span>mdi-wunderlist</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xaml"></i>
                            <code>f673</code>
                            <span>mdi-xaml</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xbox"></i>
                            <code>f5b9</code>
                            <span>mdi-xbox</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xbox-controller"></i>
                            <code>f5ba</code>
                            <span>mdi-xbox-controller</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xbox-controller-off"></i>
                            <code>f5bb</code>
                            <span>mdi-xbox-controller-off</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xda"></i>
                            <code>f5bc</code>
                            <span>mdi-xda</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xing"></i>
                            <code>f5bd</code>
                            <span>mdi-xing</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xing-box"></i>
                            <code>f5be</code>
                            <span>mdi-xing-box</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xing-circle"></i>
                            <code>f5bf</code>
                            <span>mdi-xing-circle</span>
                        </div>
                        <div>
                            <i class="mdi mdi-xml"></i>
                            <code>f5c0</code>
                            <span>mdi-xml</span>
                        </div>
                        <div>
                            <i class="mdi mdi-yeast"></i>
                            <code>f5c1</code>
                            <span>mdi-yeast</span>
                        </div>
                        <div>
                            <i class="mdi mdi-yelp"></i>
                            <code>f5c2</code>
                            <span>mdi-yelp</span>
                        </div>
                        <div>
                            <i class="mdi mdi-yin-yang"></i>
                            <code>f67f</code>
                            <span>mdi-yin-yang</span>
                        </div>
                        <div>
                            <i class="mdi mdi-youtube-play"></i>
                            <code>f5c3</code>
                            <span>mdi-youtube-play</span>
                        </div>
                        <div>
                            <i class="mdi mdi-zip-box"></i>
                            <code>f5c4</code>
                            <span>mdi-zip-box</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>