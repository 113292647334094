import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxSpinnerService } from "ngx-spinner";
import { element } from "protractor";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import moment from "moment";
import Swal from "sweetalert2";
import {
  Period,
  SessionObject,
  Voucher,                                                                         
  PopupTable,
  Job,
  Stock
} from "../../shared/model/ERP Models/Logics.model";
import { GenerateInventoryDocument, SaveMaterialRequest } from "../../shared/model/ERP Models/Payloads.model";
import { GenralLedgerService } from "../../shared/service/ERP Services/genral-ledger.service";
import { InventoryPricingService } from "../../shared/service/ERP Services/inventory-pricing.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { da } from 'date-fns/locale';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})
export class MaterialComponent implements OnInit {
  
  constructor(
    private apiservice: InventoryPricingService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService
  ) { }

  PeriodList: Period[] = [];
  VourcherList: Voucher[] = [];
  JobList : Job[] = [];
  StoreList :any[] = [];
  DataObjectID: string = "GL";
  StocksList : Stock[] = []
  DataRowID: string = "1";
  show: boolean = false;
  @ViewChild("configModal") public ConfigModal: ModalDirective;
  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  PopUpTableData: PopupTable[] = [];
  Session: SessionObject = {
    BranchCode: "01",
    FinancialCode: "2019",
    CompanyCode: "01",
    ModuleCode: "GL",
    DocumentCode: "JV"
  };

  SelectedPeriod: Period | any = {};
  SelectedVoucher: string;
  SelectedJob: any | Job = {};
  selectedStore :any
  selectedDate:any
  DocumentCode: string;
  DataObject = {
    JB: {},
    STK: {},
    QTY: {}
  };
  BodyRows: number[] = [1];
  St_Reference = ""
  St_RequestedBy = ""
  St_Remarks = ""
  FilesList;

  CurrentDate: string = moment().format('DD-MM-YYYY').toString();
  CurrentDatePopulate: string = moment().format('YYYY-MM-DD').toString();

  enablePassBtn : boolean = false;
  savedHeader : number;

  ngOnInit(): void {
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = "RQ";
    this.Session.FinancialCode = SessionObject?.year.year;
    this.Session.ModuleCode = "PO";
    this.Session.BranchCode = "01";
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode

    console.log('CurrentDate: ',this.CurrentDate)
    this.loadPeriods(this.Session);
    this.loadStores(this.Session);
    this.loadStocks();
    this.loadJobs();
    this.dtOptions = {
      pagingType: "full_numbers",
    };
  }

  loadStocks() {
    this.apiservice.getStocks().subscribe(
      (data) => {
        this.StocksList = data;
      }
    )
  }

  passTransaction(){
    this.apiservice.passMaterialRequestTransaction(this.savedHeader ,  this.Session.UserName ).subscribe(
      data => {
        this._toastr.success("Request Passed");
      }
    )
  }

  SaveTransaction()
  {
    let Payload : SaveMaterialRequest =
    {
      "poReqHeadersDTO": 
                        {
                          "docNo": this.isNull(Number.parseInt(this.DocumentCode)),
                          "docDate": this.isNull(this.selectedDate),
                          "costCentreCode": this.isNull(this.DataObject.JB['0']?.RowObject.costCentreDTO.code),
                          "createdBy": this.isNull(this.Session.UserName),
                          "remarks": this.isNull(this.St_Remarks),
                          "requestedBy": this.isNull(this.St_RequestedBy),
                          "periodId": this.isNull(this.SelectedPeriod.Id),
                          "jcJobId": this.isNull(this.DataObject.JB['0']?.RowObject.id),
                          "fyer": this.isNull(this.SelectedPeriod.FinancialYearID),
                          "storeBrncCode": this.isNull(this.Session.BranchCode),
                          "storeCode": this.isNull(this.getStoreValues().storeMasterIdentity.code),
                          "storeBrncCompCode": this.isNull(this.Session.CompanyCode),
                          "modsModule": this.isNull(this.Session.ModuleCode),
                          "docType": this.isNull(this.Session.DocumentCode),
                          "code": this.isNull(this.SelectedVoucher)
                        },
      "poReqDetailsDTOList": [],
      "poReqFileDTOS":[]
    };

    for( let i=1; i <= this.BodyRows.length-1; i++)
    {
      Payload.poReqDetailsDTOList.push(
        {
          "desc": this.isNull(this.DataObject.STK[i.toString()]?.Name),
          "scStockMasterId": this.isNull(this.DataObject.STK[i.toString()]?.RowObject.id),
          "stokId": null,
          "qty":this.isNull(this.DataObject.QTY[i]),
          "uomsCode": this.isNull(this.DataObject.STK[i.toString()]?.RowObject.itemUom)
        }
      )
    }
    
    for (let i=0; i< this.FilesList.length ; i++)
    {
      Payload.poReqFileDTOS.push(
        {
          "createdBy": this.isNull(this.Session.UserName),
          "description": this.FilesList[i].Description
        }
      )
    }

    let SendObject: FormData = new FormData();

    SendObject.append('request', JSON.stringify(Payload))

    for (let i=0; i< this.OrginalFileList.length ; i++)
    {
      SendObject.append('files',this.OrginalFileList[i])
    }

    this.apiservice.saveMaterialRequest(SendObject).subscribe(
      (data : Object | any) => {
        this._toastr.success("Request SUbmitted");
        this.enablePassBtn = true;
        this.savedHeader = data.poReqHeadersDTO.id;
      },
      error => {
        this._toastr.error("Error Occurred while saving");
      }
    )
  
  }

  isNull(Field) {
    return Field? Field : null;
  }

  getStoreValues()
  {
    return this.StoreList.filter( x => x.storeMasterIdentity.code == this.selectedStore)[0]
  }

  loadJobs()
  {
    this.apiservice.getJobs(0,9999999).subscribe(
      (data: any) => {
        this.JobList = data;
        this.dtTrigger.next();
      }
    )
  }


  popUpTableValueSelected(event, row: PopupTable) {
    let CheckFlag = event.currentTarget.checked;
    if (CheckFlag) {
      this.PopUpTableData.forEach((x) => (x.Selected = false));
      row.Selected = true;
      this.DataObject[this.DataObjectID][this.DataRowID] = row;
      if(this.DataRowID == '0') return
      if (
        Object.keys(this.DataObject[this.DataObjectID]).length ==
        this.BodyRows.length
      ) {
        this.BodyRows.push(Object.keys(this.BodyRows).length + 1);
      }
    }
  }

  validateCompleteRow(row)
  {

  }

  generateDocumentCode() {
    let Payload: GenerateInventoryDocument = {
      vTypeDocType: this.Session.DocumentCode,
      vTypeModsModule: this.Session.ModuleCode,
      fyerId: this.SelectedPeriod.FinancialYearID.toString(),
      brncCode: this.Session.BranchCode,
      code: this.SelectedVoucher,
      storeBrncCompCode: this.Session.CompanyCode,
      modsModule: this.Session.ModuleCode,
      storeCode: this.selectedStore
    };
    this.apiservice.generateDocumentCode(Payload, this.SelectedPeriod.Id).subscribe((data: any) => {
          this.DocumentCode = data;
        },
        (error) => {this._toastr.error("Error in API","Error");}
      );
  }

  loadPeriods(Session: SessionObject) {
    if (!Session.BranchCode || !Session.CompanyCode || !Session.FinancialCode)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiservice
      .getPeriodByCompanyAndBranch(
        Session.CompanyCode,
        Session.FinancialCode,
        Session.BranchCode
      ).subscribe((data) => {
          console.log('loadPeriods: ',data)
          this.spinner.hide();
          data.forEach((element) => {
            this.PeriodList.push({
              Id: element.id,
              Period: element.period,
              FromDate: element.fromDate,
              ToDate: element.toDate,
              FinancialYearID: element.fyerId,
            });
          });
          this.PeriodList.sort((a, b) => {
            return Number.parseInt(a.Period) - Number.parseInt(b.Period);
          }); // Sorting the Periods
          this.SelectedPeriod = this.PeriodList[0];
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  loadStores(Session: SessionObject) {
    if (!Session.CompanyCode)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiservice.getStores(Session.CompanyCode).subscribe((data) => {
        this.StoreList = data
        this.selectedStore = this.StoreList[0].storeMasterIdentity.code;
        this.loadVoucherCode(this.Session)
        console.log('loadStores: ',this.StoreList)
        this.spinner.hide();
    },
    (error) => {
        this.spinner.hide();
    }
    );
  }

  loadVoucherCode(Session: SessionObject) {
    this.spinner.show();
    this.apiservice
      .getVoucherCode(
        Session.CompanyCode,
        Session.DocumentCode,
        Session.FinancialCode,
        Session.ModuleCode,
        this.selectedStore
      ).subscribe((data: any[]) => {
          this.spinner.hide();
          this.VourcherList = [];
          data.forEach((element) => {
            this.VourcherList.push({
              Code: element.code,
              Description: element.description,
            });
          });
          console.log('VourcherList: ',this.VourcherList)
          this.SelectedVoucher = this.VourcherList[0].Code;
        },
        (error) => {
          this.spinner.hide();
          this._toastr.error("Error in API","Error");
        }
      );
  }

  setStoreCode(event){
    this.selectedStore = event.target.value;
    this.loadVoucherCode(this.Session);
  }

  setVoucherCode(event) {
    this.SelectedVoucher = event.target.value;
  }

  setDateFromPeriod(event) {
    let ChangePeriod = event.target.value;
    this.SelectedPeriod = this.PeriodList.filter(
      (x) => x.Period == ChangePeriod
    )[0];
  }

  showMaterialVoucher() {
    if (this.SelectedPeriod && this.SelectedVoucher) {
      this.show = true;
      this.generateDocumentCode();
      // this.getCurrencyList();
    }
  }

  dateChange(event){
    this.selectedDate = event.target.value
    var check = moment(this.selectedDate).isBetween(moment(this.SelectedPeriod.FromDate).add(-1,'day'), moment(this.SelectedPeriod.ToDate).add(11,'day'));
    console.log(this.selectedDate,check)
    if(check == false){
        Swal.fire({
            title: "Date must be between '"+this.SelectedPeriod.FromDate +"' and '"+this.SelectedPeriod.ToDate+"'",
            type: 'error'
        });
        this.selectedDate = this.SelectedPeriod.FromDate
        return;
    }
    console.log('selectedDate: ',this.selectedDate)
  }

  OpenSelectModal(pDataObjectID: string, pDataRowID: string) {
    this.PopUpTableData = [];
    this.rerender();
    this.DataObjectID = pDataObjectID;
    this.DataRowID = pDataRowID;

    if(pDataObjectID == 'JB' && pDataRowID == '0')
    {
      if(this.JobList.length != 0) {
        this.JobList.forEach( job => {
          this.PopUpTableData.push( {
              "Code": job.code,
              "Name": job.description,
              "RowObject": job,
              "Selected": false
            })
        })
      }
    
     this.ConfigModal.show();
    
    }

    if(pDataObjectID == 'STK')
    {
      if(this.StocksList.length != 0) {
        this.StocksList.forEach( Stock => {
          this.PopUpTableData.push( {
              "Code": Stock.itemCode,
              "Name": Stock.description,
              "RowObject": Stock,
              "Selected": false
            })
        })
      }
    
     this.ConfigModal.show();
    
    }



  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  filesUploaded(e) {
    this.OrginalFileList = e.target.files;
    Array.from(e.target.files).forEach((x:any)=>x.Description = "");
    this.FilesList = e.target.files;
    this.apiservice.saveMaterialRequest(e.target.files.item(0)).subscribe(data=>{},err=>{});
  }
 OrginalFileList
  deleteFile(name, lastModified)
  {

    let Temp = Array.from(this.FilesList);
    this.FilesList = Temp.filter( (x:any) => {
      return ( x.name != name && x.lastModified != lastModified)
    })
}

  
}
