<div class="card ">
    <div class="card-body">
        <h4 class="card-title">Device Visit</h4>
        <div id="visitor" style="height:253px; width:100%;" class="m-t-20"></div>
        <!-- row -->
        <div class="row m-t-30 m-b-15">
            <!-- column -->
            <div class="col-4 birder-right text-left">
                <h4 class="m-b-0">60%
                    <small>
                        <i class="ti-arrow-up text-success"></i>
                    </small>
                </h4>Desktop</div>
            <!-- column -->
            <div class="col-4 birder-right text-center">
                <h4 class="m-b-0">28%
                    <small>
                        <i class="ti-arrow-down text-danger"></i>
                    </small>
                </h4>Mobile</div>
            <!-- column -->
            <div class="col-4 text-right">
                <h4 class="m-b-0">12%
                    <small>
                        <i class="ti-arrow-up text-success"></i>
                    </small>
                </h4>Tablet</div>
        </div>
    </div>
</div>