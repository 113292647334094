<div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url(assets/images/big/auth-bg.jpg) no-repeat center center;">
    <div class="auth-box">
        <div id="loginform" [ngClass]="{'d-none': recoverform}">
            <div class="logo">
                <span class="db"><img src="assets/images/login-logo.png" class="login-logo" alt="logo" /></span>
                <h5 class="font-medium m-b-20">Sign In to Admin</h5>
            </div>
            <!-- Form -->
            <div class="row">
                <div class="col-12">
                  <ng-container *ngIf="loginStep && loginStep === 1">
                    <form class="form" role="form" (ngSubmit)="tryGenerateOTP()" [formGroup]="loginForm">
                      <div class="input-group mb-3" [ngClass]="{'input-group-focus':focus===true}">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                        </div>
                        <input  type="text" class="form-control" placeholder="Name..." (focus)="focus=true" (blur)="focus=false" name="username" id="username" formControlName="username">
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                        </div>
                        <input  type="password" required="required" placeholder="Password" class="form-control" (focus)="focus1=true" (blur)="focus1=false" name="password" id="password" formControlName="password">
                      </div>
                      <div class="form-group row" [ngClass]="{'input-group-focus':focus1===true}">
                        <div class="col-md-12">
                          <div class="custom-control custom-checkbox">
                            <input required="required" type="checkbox" class="custom-control-input" id="customCheck1">
                            <label class="custom-control-label" for="customCheck1">Remember me</label>
                            <a (click)="showRecoverForm()" href="javascript:void(0)" id="to-recover" class="text-dark float-right"><i class="fa fa-lock m-r-5"></i> Forgot pwd?</a>
                          </div>
                        </div>
                      </div>
                      <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                          <button class="btn btn-block btn-lg btn-info" type="submit">Log In</button>
                        </div>
                      </div>
                      <!--<div class="form-group m-b-0 m-t-10">
                        <div class="col-sm-12 text-center">
                          Don't have an account? <a href="#/authentication/signup" class="text-info m-l-5"><b>Sign Up</b></a>
                        </div>
                      </div>-->
                    </form>
                  </ng-container>
           <!-- <!-       <ng-container *ngIf="loginStep && loginStep === 2">
                    <form class="form" role="form" (ngSubmit)="loginStep2()" [formGroup]="yearForm">
                      <div class="input-group mb-3" [ngClass]="{'input-group-focus':focus1===true}">
                       
                      </div>
                      <div class="input-group mb-3">
                        <div class="flex-inputs">
                          <label>Company Code: </label>
                          <input type="text" class="form-control" placeholder="Company Code" value="{{allLoginData?.glUserMastersDetailsDTO?.compCode}}" name="companycode" id="companycode" readonly/>
                        </div>
                      </div>
                     
                      <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                          <button class="btn btn-block btn-lg btn-info" type="submit">Proceed</button>
                        </div>
                      </div>
                    </form>
                  </ng-container> -->

                  <!--- OTP COMPONENT-->
                  <ng-container *ngIf="loginStep && loginStep === 3">
                    <form class="form" role="form"  [formGroup]="loginForm">
                     
                     
                      <div class="input-group mb-3">
                        <div class="flex-inputs">
                          <input type="text" class="form-control" placeholder="Verification Code" value="" name="otp" id="otp" formControlName="otp"/>
                        </div>
                      </div>
                     
                      <div class="form-group text-center">
                        <div class="col-xs-12 p-b-20">
                          <button class="btn btn-block btn-lg btn-info" (click)="login()" type="submit">Proceed</button>
                        </div>
                      </div> 

                      <div class="form-group text-center">
                        <a style="text-align: center;"  (click)="generateOTP('sms')"  href="javascript:void(0)" id="resendCode" class="text-dark">Resend code By SMS</a>
                        <!-- <div class="col-xs-12 p-b-20">
                          <button class="btn btn-block btn-lg btn-info"  (click)="generateOTP()" type="submit">Resend verification code</button>
                        </div> -->
                      </div> 
                      <div class="form-group text-center">
                        <a style="text-align: center;"  (click)="generateOTP('email')"  href="javascript:void(0)" id="resendCode" class="text-dark">Resend code By Email</a>
                        <!-- <div class="col-xs-12 p-b-20">
                          <button class="btn btn-block btn-lg btn-info"  (click)="generateOTP()" type="submit">Resend verification code</button>
                        </div> -->
                      </div>                   
                       </form>
                  </ng-container>
                  <!-----OPT COMPOENNT END-->
                </div>
            </div>
        </div>
        <div id="recoverform" [ngClass]="{'d-block': recoverform}">
            <div class="logo">
                <span class="db"><img src="assets/images/logo-icon.png" alt="logo" /></span>
                <h5 class="font-medium m-b-20">Recover Password</h5>
                <span>Enter your Email and instructions will be sent to you!</span>
            </div>
            <div class="row m-t-20">
                <!-- Form -->
                <form class="col-12">
                    <!-- email -->
                    <div class="form-group row">
                        <div class="col-12">
                            <input class="form-control form-control-lg" type="email" required="" placeholder="Username">
                        </div>
                    </div>
                    <!-- pwd -->
                    <div class="row m-t-20">
                        <div class="col-12">
                            <button class="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

