<div class="card bg-light">
    <div class="card-body">
        <h4 class="card-title">Temp Guide</h4>
        <div class="d-flex align-items-center flex-row m-t-30">
            <div class="display-5 text-info">
                <i class="wi wi-day-showers"></i>
                <span>73
                    <sup>°</sup>
                </span>
            </div>
            <div class="m-l-10">
                <h3 class="m-b-0">Saturday</h3>
                <small>Ahmedabad, India</small>
            </div>
        </div>
        <table class="table no-border mini-table m-t-20">
            <tbody>
                <tr>
                    <td class="text-muted">Wind</td>
                    <td class="font-medium">ESE 17 mph</td>
                </tr>
                <tr>
                    <td class="text-muted">Humidity</td>
                    <td class="font-medium">83%</td>
                </tr>
                <tr>
                    <td class="text-muted">Pressure</td>
                    <td class="font-medium">28.56 in</td>
                </tr>
                <tr>
                    <td class="text-muted">Cloud Cover</td>
                    <td class="font-medium">78%</td>
                </tr>
            </tbody>
        </table>
        <ul class="row list-style-none text-center m-t-30">
            <li class="col-3">
                <h4 class="text-info">
                    <i class="wi wi-day-sunny"></i>
                </h4>
                <span class="d-block text-muted">09:30</span>
                <h3 class="m-t-5">70
                    <sup>°</sup>
                </h3>
            </li>
            <li class="col-3">
                <h4 class="text-info">
                    <i class="wi wi-day-cloudy"></i>
                </h4>
                <span class="d-block text-muted">11:30</span>
                <h3 class="m-t-5">72
                    <sup>°</sup>
                </h3>
            </li>
            <li class="col-3">
                <h4 class="text-info">
                    <i class="wi wi-day-hail"></i>
                </h4>
                <span class="d-block text-muted">13:30</span>
                <h3 class="m-t-5">75
                    <sup>°</sup>
                </h3>
            </li>
            <li class="col-3">
                <h4 class="text-info">
                    <i class="wi wi-day-sprinkle"></i>
                </h4>
                <span class="d-block text-muted">15:30</span>
                <h3 class="m-t-5">76
                    <sup>°</sup>
                </h3>
            </li>
        </ul>
    </div>
</div>