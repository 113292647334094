<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Horizontal Grid</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="1">
                                </div>
                            </div>
                            <div class="col-md-11">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-11">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-2">
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-3">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-9">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-5">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-7">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Grid With Row Label</h4>
                <form action="#">
                    <div class="form-body">
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="1">
                                </div>
                            </div>
                            <div class="col-md-11">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-11">
                                </div>
                            </div>
                        </div>
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-2">
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10">
                                </div>
                            </div>
                        </div>
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-3">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-9">
                                </div>
                            </div>
                        </div>
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8">
                                </div>
                            </div>
                        </div>
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-5">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-7">
                                </div>
                            </div>
                        </div>
                        <label>Row Label </label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Grid With Label</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="1">
                                </div>
                            </div>
                            <div class="col-md-11">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-11">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-2">
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-10">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-3">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-9">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-4">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-8">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-5">
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-7">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Right Offset</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-12">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-11">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-9">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-7">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-5">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-3">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-2">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-left">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Left Offset</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-12">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-11 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-11 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-9 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-7 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-7 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-5 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-3 ml-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-2 ml-auto0">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1 ml-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="11">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Centered Input</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-12">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 m-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8 m-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 m-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 m-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 m-auto">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-2 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-4 text-right">Label</label>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-4">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-3 text-right">Label</label>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-6">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-2 text-right">Label</label>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-8">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-1 text-right">Label</label>
                            <div class="col-md-10">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="col-md-10">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-center">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Centered Input with Default label</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-12">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 m-auto">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-10 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9 m-auto">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-8 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 m-auto">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-6 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 m-auto">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-4 m-auto">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2 m-auto">
                                <div class="form-group">
                                    <label>Label</label>
                                    <input type="text" class="form-control" placeholder="col-md-2 m-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions m-t-25">
                        <div class="text-center">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Grid With Row Label</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-1">
                                            <input type="text" class="form-control" placeholder="col-md-1">
                                        </div>
                                        <div class="col-md-11">
                                            <input type="text" class="form-control" placeholder="col-md-11">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <input type="text" class="form-control" placeholder="col-md-2">
                                        </div>
                                        <div class="col-md-10">
                                            <input type="text" class="form-control" placeholder="col-md-10">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <input type="text" class="form-control" placeholder="col-md-3">
                                        </div>
                                        <div class="col-md-9">
                                            <input type="text" class="form-control" placeholder="col-md-9">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" class="form-control" placeholder="col-md-4">
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-8">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <input type="text" class="form-control" placeholder="col-md-5">
                                        </div>
                                        <div class="col-md-7">
                                            <input type="text" class="form-control" placeholder="col-md-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-lg-1">Label</label>
                                <div class="col-lg-11">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <input type="text" class="form-control" placeholder="col-md-6">
                                        </div>
                                        <div class="col-md-6">
                                            <input type="text" class="form-control" placeholder="col-md-6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Grid With Inline Input Label</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-2">
                                        </div>
                                        <label class="col-md-4">Label </label>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="row">
                                        <label class="col-md-4 text-right">Label </label>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-10">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-3">
                                        </div>
                                        <label class="col-md-4">Label </label>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div class="row">
                                        <label class="col-md-4 text-right">Label </label>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-9">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-4">
                                        </div>
                                        <label class="col-md-4">Label </label>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <label class="col-md-4 text-right">Label </label>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-8">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-5">
                                        </div>
                                        <label class="col-md-4">Label </label>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <label class="col-md-4 text-right">Label </label>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-7">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-6">
                                        </div>
                                        <label class="col-md-4">Label </label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <label class="col-md-4 text-right">Label </label>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control" placeholder="col-md-6">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Multiple Input With Default Label</h4>
                <form action="#">
                    <div class="form-body">
                        <label>Label </label>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Input &amp; First Row">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Input &amp; Second Row">
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Second Input &amp; First Row">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Second Input &amp; Second Row">
                                </div>
                            </div>
                        </div>
                        <label>Multiple width Input Label</label>
                        <div class="row">
                            <div class="col-md-7">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Input &amp; First Row">
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Second Input &amp; First Row">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Input &amp; Second Row">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Second Input &amp; Second Row">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Multiple Input With Inline Label</h4>
                <form action="#">
                    <div class="form-body">
                        <div class="form-group row">
                            <label class="col-md-2">Label </label>
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First Input &amp; First Row">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First Input &amp; Second Row">
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Second Input &amp; First Row">
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Second Input &amp; Second Row">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-2">Multiple width Input Label </label>
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First Input &amp; First Row">
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Second Input &amp; First Row">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First Input &amp; Second Row">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Second Input &amp; Second Row">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-actions">
                        <div class="text-right">
                            <button type="submit" class="btn btn-info mr-2">Submit</button>
                            <button type="reset" class="btn btn-dark">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>