export class CandidateSearchDto {

  id: number;

  name: string;

  email: string;

  phone: string;

  constructor() {
  }

}
