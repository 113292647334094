<!-- ============================================================== -->
<!-- Visits -->
<!-- ============================================================== -->
<div class="row">
    <!-- column -->
    <div class="col-sm-12 col-lg-4">
        <app-device-visits></app-device-visits>
    </div>
    <!-- column -->

    <!-- column -->
    <div class="col-sm-12 col-lg-8">
        <app-visitors></app-visitors>
    </div>
</div>
<!-- ============================================================== -->
<!-- Info Box -->
<!-- ============================================================== -->
<app-info-box></app-info-box>
<!-- ============================================================== -->
<!-- Visits -->
<!-- ============================================================== -->
<app-visits-bounce></app-visits-bounce>
<!-- ============================================================== -->
<!-- User -->
<!-- ============================================================== -->
<app-user-details></app-user-details>
<!-- ============================================================== -->
<!-- Browser-Profile-Activity -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-lg-4">
        <app-browser-stats></app-browser-stats>
        <app-user-profile></app-user-profile>
    </div>
    <div class="col-sm-12 col-lg-8">
        <app-activity></app-activity>
    </div>
</div>
