import { Component, OnInit } from '@angular/core';
import {HrDocumentTypeService} from '../../shared/service/hr-document-type.service';

@Component({
  selector: 'app-managedocs',
  templateUrl: './managedocs.component.html',
  styleUrls: ['./managedocs.component.css']
})
export class ManagedocsComponent implements OnInit {

  documentTypes: any[];
  docCode: any = '';

  constructor(
    private hrDocumentTypeService: HrDocumentTypeService
  ) { }

  ngOnInit(): void {
    this.hrDocumentTypeService.getAllDocumentTypes().subscribe(doctypeData => {
      this.documentTypes = doctypeData;
    }, error => {
      console.log(error);
    });
  }

  changeDocType(event) {
    if (event.target && event.target.value) {
      this.hrDocumentTypeService.generateCodeDocument(event.target.value).subscribe(generatedCode => {
        this.docCode = generatedCode;
      }, error => {
        if (error?.error.text) {
          this.docCode = error?.error.text;
        }
      });
    }
  }

}
