import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {InvoiceRouting} from './invoice.routing';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {InvoiceComponent} from './invoice.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';


@NgModule({
  declarations: [InvoiceComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(InvoiceRouting),
    NgxDatatableModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    PdfViewerModule
  ]
})
export class InvoiceModule {
}
