import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {NotificationDto} from './notificationdto';
import Utils from '../../utils/Utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) {
  }

  getAllUndeletedNotifications(page: number, size: number): Observable<any> {
    return this.http.get<any>(environment.host + '/api/notification/allUndeleted?page=' + page + '&size=' + size, Utils.getHttpOptions());
  }

  readNotification(id: number): Observable<NotificationDto> {
    return this.http.get<NotificationDto>(environment.host + `/api/notification/read/${id}`, Utils.getHttpOptions());
  }

  deleteNotification(id: number) {
    return this.http.get<NotificationDto>(environment.host + `/api/notification/delete/${id}`, Utils.getHttpOptions());
  }

  countUnreadNotifications(): Observable<any> {
    return this.http.get<any>(environment.host + '/api/notification/countUnreadNotifications', Utils.getHttpOptions());
  }

  readAllNotifications() {
    return this.http.get<NotificationDto>(environment.host + `/api/notification/readAllNotifications`, Utils.getHttpOptions());
  }
}
