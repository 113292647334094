import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CandidateListComponent} from './candidate/list/candidateList.component';
import {RouterModule} from '@angular/router';
import {RecruitmentRouting} from './recruitment.routing';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {CandidateDetailsComponent} from './candidate/candidate-details/candidate-details.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RecruitmentRequestListComponent} from './request/list/recruitmentRequestList.component';
import {RecruitmentRequestDetailsComponent} from './request/request-details/recruitment-request-details.component';
import {CandidateSelectionListComponent} from './selection/list/candidateSelectionList.component';
import {CandidateSelectionDetailsComponent} from './selection/candidate-selection-details/candidate-selection-details.component';


@NgModule({
  declarations: [CandidateListComponent, CandidateDetailsComponent, RecruitmentRequestListComponent, RecruitmentRequestDetailsComponent, CandidateSelectionListComponent, CandidateSelectionDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(RecruitmentRouting),
    NgxDatatableModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule
  ]
})
export class RecruitmentModule {
}
