import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiService} from '../../shared/service/api.service';
import {HrDocumentRequestService} from '../../shared/service/hr-document-request.service';
import {LocalstorageService} from '../../shared/service/localstorage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from 'src/app/shared/service/company.service';
import {DOC_REQ_ALL} from 'src/app/app.constants';
import {AuthService} from 'src/app/shared/service/auth.service';
import DateUtils from '../../utils/DateUtils';

interface SessionObject {
  CompanyCode?: string;
  BranchCode?: string;
  Token?: string;
  FinancialCode?: string;
  FYearID?: string;
  DocumentCode?: string;
  ModuleCode?: string;
  UserName?: string;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  Session: SessionObject = {
    BranchCode: '',
    FinancialCode: '',
    CompanyCode: '',
    ModuleCode: '',
    DocumentCode: 'LR',
    FYearID: ''
  };
  List: any = [];
  approvalStatusOption: any = DOC_REQ_ALL;
  selectedCompany: any;
  companies = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  pages: { selected: boolean; pagenumber: number }[] = [];
  selectedPage = 1;
  autoSelectCompany;
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;
  columns = [
    {prop: 'name', name: 'Name'},
    {prop: 'docNo', name: 'Document Number'},
    {prop: 'docDate', name: 'Document Date'},
    {prop: 'createdBy', name: 'Created By'},
    {prop: 'documentType', name: 'Document Type'},
    {name: 'Action', width: 50}
  ];
  initialFlag = true;
  TotalPages: number;

  constructor(
      private formBuilder: FormBuilder,
      private hrDocumentRequestService: HrDocumentRequestService,
      private apiService: ApiService,
      private cd: ChangeDetectorRef,
      private modalService: NgbModal,
      private _toastr: ToastrService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private spinner: NgxSpinnerService,
      private companyService: CompanyService,
      private localstorage: LocalstorageService,
      private authService: AuthService
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 1200);
    };
  }

  ngOnInit(): void {
    this.setSessionData();
    this.loadCompanies();
    this.loadDocumentRequests();
  }

  New() {
    this.router.navigate(['/hrdocuments/documentrequest'], {queryParams: {id: 'null'}});
  }

  ListDetails(RequestID) {
    this.router.navigate(['/hrdocuments/documentrequest'], {queryParams: {id: RequestID}});
  }

  loadDocumentRequests() {
    this.spinner.show();
    this.hrDocumentRequestService.searchDocumentRequests(DOC_REQ_ALL, this.Session.CompanyCode, 0, this.pageSize).subscribe(data => {
      this.List = data.content;
      // this.List.forEach(x=> x.docDate = moment(x.docDate).format('DD/MM/YYYY'))
      this.List.forEach(x => x.docDate = DateUtils.get(x.docDate));
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  loadCompanies() {
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
      const local = this.localstorage.getLocalStorageData();
      if (this.authService.isSelfServiceUser()) {
        this.autoSelectCompany = this.companies.map(x => x.code).indexOf(local.glUserMastersDetailsDTO.compCode);
      }
    });
  }

  filterHrDocRequestsByStatus(event) {
    this.spinner.show();
    this.approvalStatusOption = event.target.value;
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatusOption, this.selectedCompany, 0, this.pageSize).subscribe((data: any) => {
      this.List = data.content;
      // this.List.forEach(x=> x.docDate = moment(x.docDate).format('DD/MM/YYYY'))
      this.List.forEach(x => x.docDate = DateUtils.get(x.docDate));
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      if (this.initialFlag) {
        for (let i = 1; i <= data.totalPages; i++) {
          this.pages.push({selected: i == 1 ? true : false, pagenumber: i});
        }
        this.initialFlag = false;
        this.TotalPages = data.totalPages;
      }
      this.spinner.hide();
    });
  }

  filterHrDocRequestsByCompany(event) {
    this.spinner.show();
    this.selectedCompany = event.target.value;
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatusOption, this.selectedCompany, 0, this.pageSize).subscribe((data: any) => {
      this.List = data.content;
      // this.List.forEach(x=> x.docDate = moment(x.docDate).format('DD/MM/YYYY'))
      this.List.forEach(x => x.docDate = DateUtils.get(x.docDate));
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      if (this.initialFlag) {
        for (let i = 1; i <= data.totalPages; i++) {
          this.pages.push({selected: i == 1 ? true : false, pagenumber: i});
        }
        this.initialFlag = false;
        this.TotalPages = data.totalPages;
      }
      this.spinner.hide();
    });
  }

  applyPage(PageNumber, ButtonStatus) {
    if (!ButtonStatus) {
      this.selectedPage = PageNumber;
      this.searchPaginated(PageNumber - 1);
      this.pages.forEach(x => x.selected = x.pagenumber == PageNumber ? true : false);
    } else {
      if (ButtonStatus == 'Next') {
        this.searchPaginated(this.selectedPage);
        this.pages.forEach(x => x.selected = x.pagenumber == this.selectedPage + 1 ? true : false);
        this.selectedPage = this.selectedPage + 1;
      } else if (ButtonStatus == 'First') {
        this.searchPaginated(0);
        this.pages.forEach(x => x.selected = x.pagenumber == 1 ? true : false);
        this.selectedPage = 1;
      } else if (ButtonStatus == 'Last') {
        this.searchPaginated(this.TotalPages - 1);
        this.pages.forEach(x => x.selected = x.pagenumber == this.TotalPages ? true : false);
        this.selectedPage = this.TotalPages;
      }
    }
  }

  searchPaginated(event: any) {
    this.spinner.show();
    this.hrDocumentRequestService.searchDocumentRequests(this.approvalStatusOption, this.selectedCompany, event.pageIndex, event.pageSize).subscribe((data: any) => {
      this.List = data.content;
      // this.List.forEach(x=> x.docDate = moment(x.docDate).format('DD/MM/YYYY'))
      this.List.forEach(x => x.docDate = DateUtils.get(x.docDate));
      if (this.initialFlag) {
        for (let i = 1; i <= data.totalPages; i++) {
          this.pages.push({selected: i == 1 ? true : false, pagenumber: i});
        }
        this.initialFlag = false;
        this.TotalPages = data.totalPages;
        this.pageSize = data.size;
        this.pageIndex = data.number;
        this.totalElements = data.totalElements;
      }
      this.spinner.hide();
    });
  }

  displayPagination(PageNumber) {
    if (this.selectedPage <= 3) {
      return (PageNumber <= 5);
    }
    if (this.selectedPage > 3 && this.selectedPage < this.TotalPages - 2) {
      return (PageNumber >= this.selectedPage - 2 && PageNumber <= this.selectedPage + 2);
    }

    if (this.selectedPage >= this.TotalPages - 2) {
      return (PageNumber >= this.TotalPages - 4);
    }
  }

  selectPage(e) {
    this.searchPaginated((this.selectedPage - 1));
    this.pages.forEach(x => x.selected = x.pagenumber == this.selectedPage ? true : false);
  }

  private setSessionData() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = 'JV';
    this.Session.ModuleCode = 'PP';
    this.Session.BranchCode = '01';
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode;
    this.Session.FYearID = SessionObject.glUserMastersDetailsDTO.currentFinancialYear;
  }


}
