<div class="row">
  <!-- column -->
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Filter Option</h4>
        <h5 class="card-subtitle">This is the filerable Data table</h5>
        <form class="w-50 m-b-30">
          <input type='text' class="form-control" placeholder='Type to filter the name column...' (keyup)='updateFilter($event)' />
        </form>
        <ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
          [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
        </ngx-datatable>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Editing Option</h4>
        <h5 class="card-subtitle">This is the editing Data table option you can double click on text</h5>

        <ngx-datatable #mydatatable class="material" [headerHeight]="50" [limit]="5" [columnMode]="'force'" [footerHeight]="50" [rowHeight]="'auto'"
          [rows]="rows">
          <ngx-datatable-column name="Name">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
              <span title="Double click to edit" (dblclick)="editing[rowIndex + '-name'] = true" *ngIf="!editing[rowIndex + '-name']">
                {{value}}
              </span>
              <input autofocus (blur)="updateValue($event, 'name', rowIndex)" *ngIf="editing[rowIndex+ '-name']" type="text" [value]="value"
              />
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Gender">
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row" let-value="value">
              <span title="Double click to edit" (dblclick)="editing[rowIndex + '-gender'] = true" *ngIf="!editing[rowIndex + '-gender']">
                {{value}}
              </span>
              <select *ngIf="editing[rowIndex + '-gender']" (change)="updateValue($event, 'gender', rowIndex)" [value]="value">
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Age">
            <ng-template ngx-datatable-cell-template let-value="value">
              {{value}}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>