<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Recruitment Requisition Form</h2>
        <form [formGroup]="requestFormGroup">
          <div class="form-body">
            <div class="row">

              <table class="table">
                <tbody>
                <tr>
                  <h4 class="card-title">Job Specification</h4>
                </tr>
                <tr>
                  <td>
                    <p>Reference Number</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.referenceNumber" class="form-control"
                             disabled
                             formControlName="referenceNumber"
                             id="referenceNumber" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Vacancy Job Title</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.vacancyJobTitle" class="form-control"
                             formControlName="vacancyJobTitle"
                             id="vacancyJobTitle"
                             placeholder="Vacancy Job Title" type="text"/>
                    </div>
                  </td>

                </tr>
                <tr>

                  <td>
                    <p>Company</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="filterByCompany($event)"
                              class="form-control leave-type-section" id="company">
                        <option *ngFor="let company of companies"
                                [selected]="company.code == selectedCompanyCode"
                                [value]="company.code">{{company.code}} {{company.name}}</option>
                      </select>
                    </div>
                  </td>

                  <td>
                    <p>Department</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setDepartment($event)"
                              class="form-control leave-type-section">
                        <option *ngFor="let department of departments"
                                [selected]="department.code == selectedDepartmentCode"
                                [value]="department.code">{{department.code}} {{department.description}}</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr>

                  <td>
                    <p>Salary Gross Package</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.salaryGrossPackage"
                             class="form-control"
                             formControlName="salaryGrossPackage" id="salaryGrossPackage"
                             placeholder="Salary Gross Package"
                             type="text">
                    </div>
                  </td>
                  <td>
                    <p>Required By Date</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="formatDate(recruitmentRequestDto?.requiredByDate)"
                             class="form-control"
                             formControlName="requiredByDate" id="requiredByDate"
                             placeholder="Required By Date"
                             type="date">
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Additional Nos Required</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.additionalNosRequired"
                             class="form-control"
                             formControlName="additionalNosRequired"
                             id="additionalNosRequired"
                             placeholder="Additional Nos Required" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Present Strength</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.presentStrength"
                             class="form-control"
                             formControlName="presentStrength" id="presentStrength"
                             placeholder="Present Strength"
                             type="text">
                    </div>
                  </td>
                </tr>

                <tr>


                  <td>
                    <p>Position</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setPosition($event)"
                              class="form-control leave-type-section">
                        <option *ngFor="let position of positions"
                                [selected]="position.code == selectedPositionCode"
                                [value]="position.code">{{position.code}} {{position.description}}</option>
                      </select>
                    </div>
                  </td>

                  <td>
                    <p>Grade</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="setGrade($event)" class="form-control leave-type-section">
                        <option *ngFor="let grade of grades"
                                [selected]="grade.code == selectedGradeCode"
                                [value]="grade.code">{{grade.code}} {{grade.description}}</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr>
                  <h4 class="card-title">Work location & Status </h4>
                </tr>

                <tr>
                  <td>
                    <p>Work Location</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.workLocation"
                             class="form-control"
                             formControlName="workLocation" id="workLocation"
                             placeholder="Work Location"
                             type="text">
                    </div>
                  </td>

                  <td>
                    <p>Recruitment Type</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <select (change)="changeType($event)"
                              class="form-control leave-type-section">
                        <option [selected]="'New' === recruitmentRequestDto?.recruitmentType"
                                [value]="'New'">New
                        </option>
                        <option
                            [selected]="'Replacement' === recruitmentRequestDto?.recruitmentType"
                            [value]="'Replacement'">Replacement
                        </option>
                      </select>
                    </div>
                  </td>

                </tr>

                <tr *ngIf="isReplacement">


                  <td>
                    <p>Reason For Replacement</p>
                  </td>
                  <td colspan="3">
                    <div class="form-group col-sm-15">
                      <input [value]="recruitmentRequestDto?.reasonForReplacement"
                             class="form-control"
                             formControlName="reasonForReplacement" id="reasonForReplacement"
                             placeholder="Reason For Replacement"
                             type="text">
                    </div>
                  </td>

                </tr>

                <tr *ngIf="isReplacement">

                  <td>
                    <p>Replacement Number</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (click)="selectEmployee()"
                             [value]="replacementEmployee?replacementEmployee.empNo:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                  <td>
                    <p>Replacement Name</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (click)="selectEmployee()"
                             [value]="replacementEmployee?replacementEmployee.name:''"
                             class="form-control" readonly>
                    </div>
                  </td>
                </tr>

                <tr>
                  <h4 class="card-title">Preferences</h4>
                </tr>

                <tr>
                  <td>
                    <p>Languages & Nationalities</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.languagesAndNationalities"
                             class="form-control"
                             formControlName="languagesAndNationalities"
                             id="languagesAndNationalities"
                             placeholder="Languages & Nationalities"
                             type="text">
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <td></td>

                <tr>
                  <td>
                    <p>Skill 1</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.skill1"
                             class="form-control"
                             formControlName="skill1" id="skill1"
                             placeholder="Skill 1"
                             type="text">
                    </div>
                  </td>

                  <td>
                    <p>Skill 2</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.skill2"
                             class="form-control"
                             formControlName="skill2" id="skill2"
                             placeholder="Skill 2"
                             type="text">
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>Skill 3</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.skill3"
                             class="form-control"
                             formControlName="skill3" id="skill3"
                             placeholder="Skill 3"
                             type="text">
                    </div>
                  </td>

                  <td>
                    <p>Skill 4</p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input [value]="recruitmentRequestDto?.skill4"
                             class="form-control"
                             formControlName="skill4" id="skill4"
                             placeholder="Skill 4"
                             type="text">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p *ngIf="jobDescBlob == null">Job Description File</p>
                    <p *ngIf="jobDescBlob != null">
                      <button
                          (click)="downloadFile(jobDescBlob,recruitmentRequestDto.jobDescFileName)">
                        Job Description File
                      </button>
                    </p>
                  </td>
                  <td>
                    <div class="form-group col-sm-10">
                      <input (change)="jobDescFileUpload($event)"
                             class="form-control file-input" id="jobDescFile"
                             multiple type="file">
                    </div>
                  </td>
                </tr>


                <tr>
                  <td>
                    <span *ngIf="canConfirm()">
                      <button (click)="confirm()"

                              class="btn btn-pass-section btn-primary mb-2">Confirm
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canReject()">
                      <button (click)="reject()"

                              class="btn btn-pass-section btn-warning mb-2"
                              style="background-color: red">Reject
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canUpdate()">
                      <button (click)="update()"

                              class="btn btn-pass-section btn-success mb-2">Update
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canSave()">
                      <button (click)="save()"

                              class="btn btn-pass-section btn-success mb-2">
                        Save
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canPass()">
                      <button (click)="pass()"

                              class="btn btn-pass-section btn-success mb-2">
                        Pass
                      </button>&nbsp;
                    </span>
                    <span *ngIf="canReset()">
                      <button (click)="reset()"

                              class="btn btn-pass-section btn-warning mb-2">
                        Cancel
                      </button>
                    </span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                </tbody>
              </table>
            </div>


          </div>
        </form>


      </div>
    </div>

  </div>
</div>
