import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { GenralLedgerService } from "../../shared/service/ERP Services/genral-ledger.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Period,
  SessionObject,
  Voucher,
  PopupTable
} from "../../shared/model/ERP Models/Logics.model";
import { GenerateDocument, SaveTransactionPayload, GlTrxDetail ,GlTrxHeadersDTO } from "../../shared/model/ERP Models/Payloads.model";
import {
  GetCurrenciesObject,
  GetGLCodes,
  GetSLCodes,
  GetCategories,
  GetCostCenters,
} from "../../shared/model/ERP Models/APIResponse.model";
import { NgxSpinnerService } from "ngx-spinner";
import { element } from "protractor";
import { Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';
import moment from "moment";
import Swal from "sweetalert2";
import DateUtils from '../../utils/DateUtils';
@Component({
  selector: "app-journalvoucher",
  templateUrl: "./journalvoucher.component.html",
  styleUrls: ["./journalvoucher.component.css"],
})
export class JournalvoucherComponent implements OnInit {
  PeriodList: Period[] = [];
  VourcherList: Voucher[] = [];
  CurrencyList: GetCurrenciesObject[] = [];
  GLCodeList: GetGLCodes[] = [];
  SLCodeList: GetSLCodes[] = [];
  CostCentersList: GetCostCenters[] = [];
  SumCredit:number;
  SumDebit:number;

  CategoriesList: GetCategories[] = [];
  CostCodeList: any[] = [];
  PopUpTableData: PopupTable[] = [];
  BodyRows: number[] = [1];

  Session: SessionObject = {
    BranchCode: "01",
    FinancialCode: "2019",
    CompanyCode: "01",
    ModuleCode: "GL",
    DocumentCode: "JV",
  };

  SelectedPeriod: Period | any = {};
  SelectedVoucher: string;
  SelectedCurrency: GetCurrenciesObject | any = {};
  DocumentCode: string;
  SelectedGLCode: GetGLCodes;
  SelectedSLCode: GetSLCodes;
  SelectedReference: string = '';
  SelectedBeing: string = '';

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  GlSelectorCheckFlag: boolean = false;
  GlCode1: string[];
  SlCode1: string[];
  SlDescription: string[];
  DataObjectID: string = "GL";
  DataObject = {
    GL: {},
    SL: {},
    CAT: {},
    CCC: {},
    Debit: {},
    Credit: {},
    CCD: {},
  };
  DataRowID: string = "1";
  CurrentDate: string = DateUtils.get();//moment().format('DD-MM-YYYY').toString();
  CurrentDatePopulate: string = DateUtils.get();//moment().format('YYYY-MM-DD').toString();;

  @ViewChild("configModal") public ConfigModal: ModalDirective;
  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;

  constructor(
    private apiservice: GenralLedgerService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService
  ) {}
  show: boolean = false;

  ngOnInit(): void {
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = "JV";
    this.Session.FinancialCode = SessionObject?.year.year;
    this.Session.ModuleCode = "GL";
    this.Session.BranchCode = "01";
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode
    this.loadPeriods(this.Session);
    this.loadVoucherCode(this.Session);
    this.loadGLCodes();
    this.loadCostCenters();
    this.dtOptions = {
      pagingType: "full_numbers",
    };
    this.loadCategories();
  }

  showJournalVoucher() {
    if (this.SelectedPeriod && this.SelectedVoucher) {
      this.show = true;
      this.generateDocumentCode();
      this.getCurrencyList();
    }
  }

  setDateFromPeriod(event) {
    let ChangePeriod = event.target.value;
    this.SelectedPeriod = this.PeriodList.filter(
      (x) => x.Period == ChangePeriod
    )[0];
  }

  currencyChanged(event) {
    let CurrencyCode = event.target.value;
    this.SelectedCurrency = this.CurrencyList.filter(
      (x) => x.code == CurrencyCode
    ).pop();
  }

  setVoucherCode(event) {
    this.SelectedVoucher = event.target.value;
  }

  fetchTotal(Type: string) {
    let Sum = 0;
    if (this.DataObject[Type]) {
      Object.keys(this.DataObject[Type]).forEach((x) => {
        if(isNaN(Number.parseFloat(this.DataObject[Type][x])))
          return;
        Sum += Number.parseFloat(this.DataObject[Type][x]);
      });
    }
    if(Type == "Credit")
      this.SumCredit = Sum;
    if(Type == "Debit")
      this.SumDebit = Sum;
    return Sum.toString();
  }

  getCurrencyList() {
    this.spinner.show();
    this.apiservice.getCurrencies().subscribe(
      (data: GetCurrenciesObject[]) => {
        this.spinner.hide();
        this.CurrencyList = data;
        this.SelectedCurrency = this.CurrencyList[0];
      },
      (error) => {
        this.spinner.hide();
        this._toastr.error("Error in API","Error");
      }
    );
  }

  loadCostCode(SLId: string, SLType: string) {
    if(!SLType || !SLId)
      return; // throw error later
    this.spinner.show();
    this.apiservice.getCostCode(SLId, SLType).subscribe(
      (data:any)=>
      {
        this.CostCodeList = data;
        this.PopUpTableData = [];
        this.rerender();
        this.CostCodeList.forEach(x=>
          {
            this.PopUpTableData.push({"Code":x.id, "Name":x.longDescription, "Selected": false, "RowObject":x})
          })
        this.spinner.hide();
      },
      error=>
      {
        this.spinner.hide();
        this._toastr.error("Error in API","Error");
      }
    )
  }

  loadCostCenters() {
    this.apiservice.getCostCenters().subscribe((data: GetCostCenters[]) => {
      this.CostCentersList = data;
    });
  }

  generateDocumentCode() {
    let Payload: GenerateDocument = {
      vTypeDocType: this.Session.DocumentCode,
      vTypeModsModule: this.Session.ModuleCode,
      fyerId: this.SelectedPeriod.FinancialYearID.toString(),
      brncCode: this.Session.BranchCode,
      code: this.SelectedVoucher,
      brncCompCode: this.Session.CompanyCode,
      modsModule: this.Session.ModuleCode,
    };
    this.apiservice
      .generateDocumentCode(Payload, this.SelectedPeriod.Id)
      .subscribe(
        (data: any) => {
          this.DocumentCode = data;
        },
        (error) => {this._toastr.error("Error in API","Error");}
      );
  }

  loadVoucherCode(Session: SessionObject) {
    this.spinner.show();
    this.apiservice
      .getVoucherCode(
        Session.CompanyCode,
        Session.DocumentCode,
        Session.FinancialCode
      )
      .subscribe(
        (data: any[]) => {
          this.spinner.hide();
          this.VourcherList = [];
          data.forEach((element) => {
            this.VourcherList.push({
              Code: element.code,
              Description: element.description,
            });
          });
          this.SelectedVoucher = this.VourcherList[0].Code;
        },
        (error) => {
          this.spinner.hide();
          this._toastr.error("Error in API","Error");
        }
      );
  }

  loadGLCodes() {
    this.apiservice.getAllGLCodes().subscribe((data: any) => {
      this.GLCodeList = data.content ? data.content : {};
      this.GLCodeList.forEach((x) => {
        this.PopUpTableData.push({
          Code: x.code,
          Name: x.description,
          Selected: false,
          RowObject: x,
        });
      });
      this.dtTrigger.next();
    });
  }
  popUpTableValueSelected(event, row: PopupTable) {
    let CheckFlag = event.currentTarget.checked;
    if (CheckFlag) {
      this.PopUpTableData.forEach((x) => (x.Selected = false));
      row.Selected = true;
      this.DataObject[this.DataObjectID][this.DataRowID] = row;
      if (
        Object.keys(this.DataObject[this.DataObjectID]).length ==
        this.BodyRows.length
      ) {
        this.BodyRows.push(Object.keys(this.BodyRows).length + 1);
      }
    }
  }

  OpenSelectModal(pDataObjectID: string, pDataRowID: string) {
    this.PopUpTableData = [];
    this.DataObjectID = pDataObjectID;
    this.DataRowID = pDataRowID;

    if (pDataObjectID == "GL") {
      if (this.GLCodeList.length != 0) {
        this.GLCodeList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.description,
            Code: x.code,
            Selected: false,
            RowObject: x
          });
        });
        this.rerender();
        this.ConfigModal.show();
      }
    }

    if (pDataObjectID == "SL") {
      if (this.SLCodeList.length != 0) {
        this.SLCodeList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.slName,
            Code: x.slCode,
            Selected: false,
            RowObject: x
          });
        });

        this.rerender();
        this.ConfigModal.show();
      } else {
        this.loadSLCodes();
      }
    }

    if (pDataObjectID == "CAT") {
      if (this.CategoriesList.length != 0) {
        this.CategoriesList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.description,
            Code: x.code,
            Selected: false,
            RowObject: x
          });
        });
        this.rerender();
        this.ConfigModal.show();
      } else {
        this.loadCategories();
        this.rerender();
        this.ConfigModal.show();
      }
    }
    // Cost Codes
    if (pDataObjectID == "CCD") {
      if(Object.keys(this.DataObject.SL).length == 0)
        return
      if (this.CostCodeList.length != 0) {
        this.CostCodeList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.longDescription,
            Code: x.id,
            Selected: false,
            RowObject: x
          });
        });
        this.rerender();
        this.ConfigModal.show();
      } else {
        if(this.DataObject.SL[this.DataRowID])
          this.loadCostCode(this.DataObject.SL[this.DataRowID].RowObject.id,this.DataObject.SL[this.DataRowID].RowObject.slType );
        this.rerender();
        this.ConfigModal.show();
      }
    }
    // Cost Centers
    if (pDataObjectID == "CCC") {
      if (this.CostCentersList.length != 0) {
        this.CostCentersList.forEach((x) => {
          this.PopUpTableData.push({
            Name: x.description,
            Code: x.code,
            Selected: false,
            RowObject: x,
          });
        });
        this.rerender();
        this.ConfigModal.show();
      } else {
        this.loadCostCenters();
        this.rerender();
        this.ConfigModal.show();
      }
    }
  }

  loadCategories() {
    this.spinner.show();
    this.apiservice.getCategories().subscribe(
      (data: GetCategories[]) => {
        this.spinner.hide();
        this.CategoriesList = data;
        this.PopUpTableData = [];
        this.CategoriesList.forEach((x) => {
          this.PopUpTableData.push({
            Code: x.code,
            Name: x.description,
            Selected: false,
            RowObject: x,
          });
        });
      },
      (error) => {
        this.spinner.hide();
        this._toastr.error("Error in API","Error");
      }
    );
  }

  saveTransaction()
  {
    if(this.SumCredit != this.SumDebit)
    {
      this._toastr.error("The sum of credit must be equal to sum of debit.");
      return;
    }
    if(!this.SelectedBeing)
    {
      this._toastr.error("Please provide value in <b>Being</b>","Error",{
        enableHtml: true,
        closeButton: true,
        timeOut: 3000
      } );
      return;
    }
    if(!this.SelectedReference)
    {
      this._toastr.error("Please provide value in <b>Reference</b>","Error",{
        enableHtml: true,
        closeButton: true,
        timeOut: 3000
      });
      return;
    }  
    let Payload: SaveTransactionPayload = 
                  {
                    "glTrxHeadersDTO":
                                      {
                                        "docNo" : this.DocumentCode?this.DocumentCode:null,
                                        "being" : this.SelectedBeing?this.SelectedBeing:null,
                                        "exchangeRate": this.SelectedCurrency.purchaseRate?this.SelectedCurrency.purchaseRate:null,
                                        "periodId": this.SelectedPeriod.Id?this.SelectedPeriod.Id:-1,
                                        "reference": this.SelectedReference?this.SelectedReference:null,
                                        "brncCode": this.Session.BranchCode?this.Session.BranchCode:null,
                                        "compCode": this.Session.CompanyCode?this.Session.CompanyCode:null,
                                        "docType": "JV",
                                        "modsModule":"GL",
                                        "fyerId": this.SelectedPeriod.FinancialYearID,
                                        "glVrCode": this.SelectedVoucher
                                      },
                    "glTrxDetails": []
                  };

      for(let i=1; i<=this.BodyRows.length-1; i++)
      {
        Payload.glTrxDetails.push(
          {
            "brncCode": this.Session.BranchCode,
            "brncCompCode": this.Session.CompanyCode,
            "glmsCode": this.DataObject.GL[i.toString()].Code?this.DataObject.GL[i.toString()].Code:null,
            "glmsCompCode": this.DataObject.GL[i.toString()].RowObject?this.DataObject.GL[i.toString()].RowObject.compCode:null,
            "expsCode": this.DataObject.CCD[i.toString()]?this.DataObject.CCD[i.toString()].Code:null,
            "expsCompCode": this.Session.CompanyCode,
            "amount": this.DataObject.Credit[i.toString()]? this.DataObject.Credit[i.toString()]: this.DataObject.Debit[i.toString()]? this.DataObject.Debit[i.toString()]:null,
            "fCurAmount": 1000, // just hardcode
            "crDr":  this.DataObject.Credit[i.toString()]? "C" : this.DataObject.Debit[i.toString()]? "D" : null,
            "costCenterCode":this.DataObject.CCC[i.toString()]? this.DataObject.CCC[i.toString()].Code: null,
            "slCode": this.DataObject.SL[i.toString()].RowObject?.id? this.DataObject.SL[i.toString()].RowObject?.id: null,
            "slType": this.DataObject.SL[i.toString()].RowObject.slType? this.DataObject.SL[i.toString()].RowObject.slType: null
          }
        )
      }
      this.apiservice.saveTransation(Payload).subscribe(data=>
        {
          this._toastr.success("Sucessfully Passed","Success");
        },
        error=>
        {
          this._toastr.error("Error in API","Error");
        })
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadPeriods(Session: SessionObject) {
    if (!Session.BranchCode || !Session.CompanyCode || !Session.FinancialCode)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiservice
      .getPeriodByCompanyAndBranch(
        Session.CompanyCode,
        Session.FinancialCode,
        Session.BranchCode
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          data.forEach((element) => {
            this.PeriodList.push({
              Id: element.id,
              Period: element.period,
              FromDate: element.fromDate,
              ToDate: element.toDate,
              FinancialYearID: element.fyerId,
            });
          });
          this.PeriodList.sort((a, b) => {
            return Number.parseInt(a.Period) - Number.parseInt(b.Period);
          }); // Sorting the Periods
          this.SelectedPeriod = this.PeriodList[0];
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  loadSLCodes() {
    if (!this.DataObject["GL"][this.DataRowID] || !this.Session.CompanyCode)
      {
        this.PopUpTableData = [];
        this.rerender();
        return;
      }
    else {
      this.PopUpTableData = [];
      this.rerender();
      this.spinner.show();
      this.apiservice
        .getSLCodes(
          this.DataObject["GL"][this.DataRowID].Code,
          this.Session.CompanyCode
        )
        .subscribe(
          (data: GetSLCodes[]) => {
            data.forEach((x) => {
              this.PopUpTableData.push({
                Name: x.slName,
                Code: x.slCode,
                Selected: false,
                RowObject: x,
              });
            });
            if(this.PopUpTableData.length > 0 )
              this.rerender();
            this.ConfigModal.show();
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }
}
