<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Branch/Store Bin Location</h4>

        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-3">
                <label>
                  Branch
                </label>
              </div>
              <div class="col-lg-5">
                <div class="form-group">
                  <input type="text" class="form-control"/>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="text" disabled class="form-control"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <label>
                  Store
                </label>
              </div>
              <div class="col-lg-5">
                <div class="form-group">
                  <input type="text" class="form-control"/>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="text" disabled class="form-control"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12"></div>
        </div>

        <!--<ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [rows]='rows'></ngx-datatable>-->
        <ngx-datatable #table
                       class='material'
                       [columns]="columns"
                       [columnMode]="'force'"
                       [scrollbarH]="scrollBarHorizontal"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [limit]="10"
                       [rows]="rows">
          <ng-container *ngFor='let column of columns' >
            <ngx-datatable-column *ngIf='column.name === "Bin Location"' name="Bin Location" prop="binLocation">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.storeBinLocationIdentity?.binLocation}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Bin Location Code"' name="Bin Location Code" prop="description">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.storeMaster?.description}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Store Master Description"' name="Store Master Description" prop="picture">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.picture}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Created By"' name="Created By" prop="createdBy">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.createdBy}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Actions"' name="Actions" prop="actions">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div class="button-flex">
                  <button class="btn btn-primary">View Details</button>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
