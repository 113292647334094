<div class="row">
<div class="col-lg-12">
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4 class="card-title mb-0">HR Document Requests</h4>
        <button class="new btn btn-success mt-2 mb-2" (click)="New()">New HR Document Request</button>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <select (change)="filterHrDocRequestsByStatus($event)" class="form-control hrdoc-type-section">
            <option value="6" selected>All</option>
            <option value="5">Pending</option>
            <option value="4">Passed</option>
            <option value="3">Manager Approved</option>
            <option value="2">Rejected</option>
            <option value="1">Approved</option>
          </select>

          <select [disabled]='!!autoSelectCompany' (change)="filterHrDocRequestsByCompany($event)" class="form-control hrdoc-type-section">
            <option selected>Company</option>
            <option *ngFor="let company of companies; let i = index" [selected]='autoSelectCompany? i==autoSelectCompany: i==0' [value]="company.code">{{company.name}}</option>
         </select>
        </div>
      </div>
      <ngx-datatable
          #table
          class='material'
          [columns]="columns"
          [columnMode]="'force'"
          [scrollbarH]="scrollBarHorizontal"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [rows]="List">
        <ng-container *ngFor='let column of columns'>
            <ngx-datatable-column *ngIf='column.name === "Employee Name"' name="Name" prop="employeeName">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row?.employeeName}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Number"' name="Document Number" prop="docNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row.voucherCode}} {{row.docNo}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Date"' name="Document Date" prop="docDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row.docDate}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Created By"' name="Created By" prop="createdBy">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row.createdBy}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Type"' name="Document Type" prop="documentType">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{row.documentTypeDescription}}</span>
              </ng-template>
            </ngx-datatable-column>
          <ngx-datatable-column *ngIf='column.name === "Action"' name="Action" [sortable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <button class="btn btn-primary" (click)="ListDetails(row.id)">Details</button>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>
      </ngx-datatable>
      <div class="pagination-set">
        <mat-paginator (page)="searchPaginated($event)" [length]="totalElements"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                       aria-label="Select page"></mat-paginator>
      </div>
    </div>
  </div>
</div>
      </div>
