import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {IEmployee} from '../model/employee';
import {environment} from '../../../environments/environment';
import {IEmployeePage} from '../model/iemployee-page';
import { EmployeeEnquiry } from '../model/employee-enquiry';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {



  constructor(private http: HttpClient) { }


  getAll(): Observable<IEmployee[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IEmployee[]>(environment.host + '/api/employees/all', httpOptions);
  }

  getByName(name: string): Observable<IEmployee[]> {
    return this.http.get<IEmployee[]>(environment.host + '/api/employees/name/' + name);
  }

  getEmployeePage(pageNumber: number, pageSize: number): Observable<IEmployeePage> {
    let params = new HttpParams();
    params = params.append('page', pageNumber.toString());
    params = params.append('size', pageSize.toString());
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };
    return this.http.get<IEmployeePage>(environment.host + '/api/employees', httpOptions);
  }

  getByEmpNo(empNo: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IEmployee>(environment.host + '/api/employee/empno/' + empNo, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getByEmpNoOrName(empNameOrNumber: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IEmployee>(environment.host + '/api/employees/nameornum/' + empNameOrNumber + '/' + empNameOrNumber, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  searchEmployee(employeeFilterData: any, startPage: any, itemsPerPage: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.post(`${environment.host}/api/employees/search?page=${startPage}&size=${itemsPerPage}`, employeeFilterData, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  exportEmployeeReport(employeeFilterData: any, reportType: any): Observable<any> {

    return this.http.post(`${environment.host}/api/employee-reporting/export?reportType=${reportType}`, employeeFilterData,   {responseType: 'blob', headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPrefessions(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/professions`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getNationalities(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/nationalities`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getDepartments(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/departments`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getDepartmentsByCompany(company : any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/departments/company/${company}`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getPositions(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/categories`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCostCenters(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    //return this.http.get(`${environment.host}/api/costcentres?page=${startPage}&size=${pageSize}`, httpOptions)
    return this.http.get(`${environment.host}/api/costcentres`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getCities(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };

    return this.http.get(`${environment.host}/api/cities`, httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  updateEmployee(employee: IEmployee): Observable<IEmployee> {
    return this.http.put<IEmployee>(environment.host + '/api/employee/update', employee)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getEmployeesForCurrentHrCoordinator(userCode : string , compCode : string) : Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(`${environment.host}/api/employees/employees-for-hr-coordinator?userCode=${userCode}&compCode=${compCode}`, httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getDocumentRequestApprovals(id: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(`${environment.host}/api/approvals/document-request/${id}`, httpOptions)
    .pipe(
        retry(1),
        catchError(this.handleError)
    );
  }
}
