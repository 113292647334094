import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cashpayment',
  templateUrl: './cashpayment.component.html',
  styleUrls: ['./cashpayment.component.css']
})
export class CashpaymentComponent implements OnInit {
  
  numbers = [];
  show: boolean = false;
  constructor() {
    this.numbers = new Array(30).fill(0).map((x, i) => i);
  }
  public ngOnInit() {}
  showJV() {
    this.show = !this.show;
  }

}
