<div class="row">
  <!-- column -->
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Category/ Grade Master</h4>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <select (change)="listCompanyData($event)" class="form-control">
                    <ng-container *ngFor="let company of listCompany">
                      <option value="{{company.code}}">{{company.name}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-md-5 col-sm-12">
                <div class="form-group">
                  <input type="text" disabled="disabled" [value]="companyName" class="form-control"/>
                </div>
              </div>
              <div class="col-md-2 col-sm-12">
                <button (click)="openAddSalaryMaster(content3)" class="btn btn-info btn-full-section">
                  <div>
                    <i class="ti-plus font-20"></i><span>Add Grade</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
                           [footerHeight]="50" [rowHeight]="'auto'" [limit]="10" [rows]='rows'>
              <ng-container *ngFor='let column of columns'>
                <ngx-datatable-column *ngIf='column.name === "Code"' name="Code" prop="code">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.code}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf='column.name === "Description"' name="Description" prop="description">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.description}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf='column.name === "Salary From"' name="Salary From" prop="salaryfrom">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.salaryfrom}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf='column.name === "Salary To"' name="Salary To" prop="salaryto">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.salaryto}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf='column.name === "Created By"' name="Created By" prop="createdBy">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.createdBy}}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column *ngIf='column.name === "Action"' name="Action" prop="action">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button class="btn btn-primary" (click)="open1(content1, row)">Details</button>
                  </ng-template>
                </ngx-datatable-column>
              </ng-container>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content3 let-modal>
  <form role="form" (ngSubmit)="saveGrades(modal)" [formGroup]="saveGradeForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Category/Salary Grade</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" (focusout)="checkCodeLength($event)" required="required" placeholder="Code" name="code" id="code" formControlName="code"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" required="required" placeholder="Salary Grade"
                       name="salarygrade" id="salarygrade" formControlName="salarygrade"/>
              </div>
            </div>
            <div class="col-md-9 col-sm-12">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Description" name="description" id="description"
                       formControlName="description"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <input type="number" class="form-control" name="salaryFrom" id="salaryFrom" formControlName="salaryFrom"
                       placeholder="Salary From"/>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <input type="number" class="form-control" name="salaryTo" id="salaryTo" formControlName="salaryTo"
                       placeholder="Salary To"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Save</button>
      <button type="button" class="btn btn-outline-dark" #closeSaveGrade (click)="modal.close('Save click')">Close</button>
    </div>
  </form>
</ng-template>
<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Salary Definition - Percentage Wise</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <div class="form-group">
              <input type="text" class="form-control" disabled="disabled" [value]="currentGradeData.code"/>
            </div>
          </div>
          <div class="col-md-9 col-sm-12">
            <div class="form-group">
              <input type="text" class="form-control" disabled="disabled" [value]="currentGradeData.description"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="table-responsive">
                <form [formGroup]="percentageCalForm">
                  <table formArrayName="percentageInputList" class="table">
                    <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Description</th>
                      <th scope="col">Percentage</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let data of percentageCalForm.controls['percentageInputList']?.controls; let i = index">
                      <ng-container [formGroupName]="i">
                        <tr>
                          <th scope="row">{{percentageDataList[i].code}}</th>
                          <td>{{percentageDataList[i].description}}</td>
                          <td>
                            <div class="form-group">
                              <input type="number" min="0" formControlName="percentage" max="100" placeholder="Percentage" (focusout)="savePercentageData($event, percentageDataList[i])" class="form-control"/>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <!--<tr>
					  <th scope="row">1</th>
					  <td>Housing Allowance</td>
					  <td>
						<div class="form-group">
						  <input type="number" placeholder="Percentage" class="form-control" value="15"/>
						</div>
					  </td>
					</tr>
					<tr>
					  <th scope="row">2</th>
					  <td>Transport Alowance</td>
					  <td>
						<div class="form-group">
						  <input type="number" placeholder="Percentage" class="form-control" value="10"/>
						</div>
					  </td>
					</tr>
					<tr>
					  <th scope="row">3</th>
					  <td>Committe Allowance</td>
					  <td>
						<div class="form-group">
						  <input type="number" placeholder="Percentage" class="form-control" value="5"/>
						</div>
					  </td>
					</tr>
					<tr>
					  <th scope="row">
						<div class="form-group">
						  <input type="text" class="form-control"/>
						</div>
					  </th>
					  <td>
						<div class="form-group">
						  <input type="text" disabled="disabled" class="form-control"/>
						</div>
					  </td>
					  <td>
						<div class="form-group">
						  <input type="text" class="form-control"/>
						</div>
					  </td>
					</tr>-->
                    <tr>
                      <td colspan="3">
                        <button (click)="openSalaryMaster(content2)" class="btn btn-primary btn-full-section">Add Salary
                          Definition
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" scope="row" class="text-reset">
                        <span><b>Total</b></span>
                      </td>
                      <td>
                        <div class="form-group">
                          <input type="number" [value]="totalPercentage" class="form-control"/>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Salary Master</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <!--<div class="col-lg-12">
        <div class="form-group">
          <input type="text" (keyup)="filterPercentages($event)" placeholder="Find Percentage" class="form-control"/>
        </div>
      </div>-->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-12">
                  <ng-container *ngIf="differencePercentageList && differencePercentageList.length > 0">
                    <form [formGroup]="percentageNewCalForm">
                      <table formArrayName="percentageNewInputList" class="table">
                        <thead>
                        <tr>
                          <th scope="col">Description</th>
                          <th scope="col">Percentage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let data of percentageNewCalForm.controls['percentageNewInputList']?.controls; let i = index">
                          <ng-container [formGroupName]="i">
                            <tr>
                              <td>{{percentageNewDataList[i].description}}</td>
                              <td>
                                <div class="form-group">
                                  <input (focusout)="checkValidationSum($event, i)" [disabled]="(disableCompletion[i] > 0)" [readOnly]="(disableCompletion[i] > 0)" formControlName="percentage" type="number" class="form-control"/>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </ng-container>
                        </tbody>
                      </table>
                    </form>
                  </ng-container>
                  <ng-container *ngIf="(differencePercentageList && differencePercentageList.length == 0)">
                    <p>
                      All Percentages has been added
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="saveAllNewPercentageData(); modal.close('Save click')">Close</button>
  </div>
</ng-template>
