import { Injectable } from '@angular/core';
import { LEAVE_REQ_APPROVED, LEAVE_REQ_PENDING, LEAVE_REQ_REJECTED } from '../../app.constants';


@Injectable({
  providedIn: 'root'
})
export class HrpGenericServiceService {

  constructor() { }

  
  public getApprovalStatusbyCode(approvalStatusCode): string {
    switch(approvalStatusCode) {
      case LEAVE_REQ_PENDING :
      return 'pending'

      case LEAVE_REQ_APPROVED :
        return 'approved';

        case LEAVE_REQ_REJECTED:
          return 'rejected';
    }
  }

}
