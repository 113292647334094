<div class="row">
    <div class="col-lg-12">
        <div class="card  bg-light no-card-border">
            <div class="card-body">
                <div class="d-flex align-items-center">
                    <div class="m-r-10">
                        <img src="assets/images/users/2.jpg" alt="user" width="60" class="rounded-circle" />
                    </div>
                    <div>
                        <h3 class="m-b-0">Welcome back!</h3>
                        <span>Monday, 9 March 2019</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>