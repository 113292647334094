<div class="card">
    <div class="card-body">
        <h4 class="card-title">Browser States</h4>
        <table class="table vm m-t-30 no-border">
            <tbody>
                <tr>
                    <td style="width:20px">
                        <img src="assets/images/browser/chrome-logo.png" alt="logo">
                    </td>
                    <td>Google Chrome</td>
                    <td align="right">
                        <span class="badge badge-pill badge-info">23%</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/browser/firefox-logo.png" alt="logo">
                    </td>
                    <td>Mozila Firefox</td>
                    <td align="right">
                        <span class="badge badge-pill badge-success">15%</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/browser/safari-logo.png" alt="logo">
                    </td>
                    <td>Apple Safari</td>
                    <td align="right">
                        <span class="badge badge-pill badge-primary">07%</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/browser/internet-logo.png" alt="logo">
                    </td>
                    <td>Internet Explorer</td>
                    <td align="right">
                        <span class="badge badge-pill badge-warning">09%</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/browser/opera-logo.png" alt="logo">
                    </td>
                    <td>Opera mini</td>
                    <td align="right">
                        <span class="badge badge-pill badge-danger">23%</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="assets/images/browser/internet-logo.png" alt="logo">
                    </td>
                    <td>Microsoft edge</td>
                    <td align="right">
                        <span class="badge badge-pill badge-cyan">09%</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>