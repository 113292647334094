<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Internationalization of datepickers</h3>
        <ngb-datepicker [(ngModel)]="model"></ngb-datepicker>
      </div>
    </div>
  </div>
</div>
