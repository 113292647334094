import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HrpHeadersService} from './hrp-headers.service';
import {CandidateSearchDto} from '../dto/recruitment/candidate/candidateSearchDto';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CandidateDto} from '../dto/recruitment/candidate/candidateDto';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentCandidateService {


  constructor(private http: HttpClient, private headers: HrpHeadersService) {
  }


  searchCandidate(candidateDto: CandidateSearchDto, page: number, size: number): Observable<any> {
    const endPoint = `${environment.host}/api/recruitment/candidate/search?page=${page}&size=${size}`;
    return this.http.post<any>(endPoint, candidateDto, this.headers.getHttpOptions('POST')).pipe(
        catchError(() => {
          return throwError('Unable to get candidate list !');
        })
    );
  }


  delete(id) {
    const endPoint = `${environment.host}/api/recruitment/candidate/delete/${id}`;
    return this.http.delete<any>(endPoint, this.headers.getHttpOptions('DELETE')).pipe(
        catchError(() => {
          return throwError('Unable to delete candidate !');
        })
    );
  }

  saveCandidate(candidateDto: CandidateDto, cbAttachment: File, otherFileAttachment: File) {
    const endPoint = `${environment.host}/api/recruitment/candidate/save`;
    const formData: FormData = new FormData();
    if (cbAttachment) {
      formData.append('cbAttachment', cbAttachment);
      candidateDto.cbAttachmentName = cbAttachment.name;
    }
    if (otherFileAttachment) {
      formData.append('otherFileAttachment', otherFileAttachment);
      candidateDto.otherFileAttachmentName = otherFileAttachment.name;
    }
    formData.append('request', JSON.stringify(candidateDto));
    return this.http.post<any>(endPoint, formData, this.headers.getHttpOptions('POST', true)).pipe(
        catchError(() => {
          return throwError('Unable to save candidate !');
        })
    );
  }

  updateCandidate(candidateDto: CandidateDto, cbAttachment: File, otherFileAttachment: File) {
    const endPoint = `${environment.host}/api/recruitment/candidate/update`;
    const formData: FormData = new FormData();
    if (cbAttachment) {
      formData.append('cbAttachment', cbAttachment);
      candidateDto.cbAttachmentName = cbAttachment.name;
    }
    if (otherFileAttachment) {
      formData.append('otherFileAttachment', otherFileAttachment);
      candidateDto.otherFileAttachmentName = otherFileAttachment.name;
    }
    formData.append('request', JSON.stringify(candidateDto));
    return this.http.put<any>(endPoint, formData, this.headers.getHttpOptions('PUT', true)).pipe(
        catchError(() => {
          return throwError('Unable to update candidate !');
        })
    );
  }

  downloadCbAttachment(id) {
    const endPoint = `${environment.host}/api/recruitment/candidate/download/cbAttachment/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      responseType: 'blob'
    };
    return this.http.get(endPoint, {
      responseType: 'blob',
      headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})
    }).pipe(
        catchError(() => {
          return throwError('Unable to download file !');
        })
    );
  }

  downloadOtherFileAttachment(id) {
    const endPoint = `${environment.host}/api/recruitment/candidate/download/otherFileAttachment/${id}`;
    return this.http.get(endPoint, {
      responseType: 'blob',
      headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})
    }).pipe(
        catchError(() => {
          return throwError('Unable to download file !');
        })
    );
  }
}
