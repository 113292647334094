import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {EmployeeEnquiryRouting} from './employeeenquiry.routing';
import { EnquiryviewComponent } from './enquiryview/enquiryview.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(EmployeeEnquiryRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [EnquiryviewComponent],
  providers: [NgbModal]
})
export class EmployeeenquiryModule {}
