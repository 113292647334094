<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Vertical Bar Chart</h4>
                <ngx-charts-bar-vertical class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="single" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines"
                    [barPadding]="40" [view]="view" [roundDomains]="roundDomains" (select)="select($event)" (legendLabelClick)="onLegendLabelClick($event)">
                </ngx-charts-bar-vertical>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Horizontal bar chart</h4>
                <ngx-charts-bar-horizontal class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="single" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines"
                    [barPadding]="20" [roundDomains]="roundDomains" (legendLabelClick)="onLegendLabelClick($event)" (select)="select($event)">
                </ngx-charts-bar-horizontal>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Group Vertical bar chart</h4>
                <ngx-charts-bar-vertical-2d class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="multi" [gradient]="gradient"
                    [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (legendLabelClick)="onLegendLabelClick($event)"
                    [showGridLines]="showGridLines" [barPadding]="barPadding" [groupPadding]="20" [roundDomains]="roundDomains"
                    (select)="select($event)">
                </ngx-charts-bar-vertical-2d>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Group horizontal bar chart</h4>
                <ngx-charts-bar-horizontal-2d class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="multi"
                    [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                    (legendLabelClick)="onLegendLabelClick($event)" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showGridLines]="showGridLines" [barPadding]="barPadding"
                    [groupPadding]="20" [roundDomains]="roundDomains" (select)="select($event)">
                </ngx-charts-bar-horizontal-2d>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Line chart</h4>
                <ngx-charts-line-chart class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="dateDataWithOrWithoutRange"
                    [legend]="showLegend" (legendLabelClick)="onLegendLabelClick($event)" [gradient]="gradient" [xAxis]="showXAxis"
                    [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [timeline]="timeline" [showGridLines]="showGridLines" [curve]="curve"
                    [rangeFillOpacity]="rangeFillOpacity" [roundDomains]="roundDomains" [tooltipDisabled]="true" (select)="select($event)">
                </ngx-charts-line-chart>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Area chart</h4>
                <ngx-charts-area-chart class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="dateData" [legend]="showLegend"
                    (legendLabelClick)="onLegendLabelClick($event)" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis"
                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                    [autoScale]="autoScale" [timeline]="timeline" [showGridLines]="showGridLines" [roundDomains]="roundDomains"
                    [curve]="curve" [tooltipDisabled]="tooltipDisabled" (select)="select($event)">
                </ngx-charts-area-chart>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Stacked chart</h4>
                <ngx-charts-area-chart-stacked class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="dateData"
                    [legend]="showLegend" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" (legendLabelClick)="onLegendLabelClick($event)"
                    [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                    [timeline]="timeline" [showGridLines]="showGridLines" [roundDomains]="roundDomains" [tooltipDisabled]="tooltipDisabled"
                    [curve]="curve" (select)="select($event)">
                </ngx-charts-area-chart-stacked>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Normalize Area chart</h4>
                <ngx-charts-area-chart-normalized class="chart-container" [scheme]="colorScheme" [schemeType]="schemeType" [results]="dateData"
                    [legend]="showLegend" [gradient]="gradient" [xAxis]="showXAxis" (legendLabelClick)="onLegendLabelClick($event)"
                    [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [timeline]="timeline" [showGridLines]="showGridLines" [roundDomains]="roundDomains"
                    [tooltipDisabled]="tooltipDisabled" [curve]="curve" (select)="select($event)">
                </ngx-charts-area-chart-normalized>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pie chart</h4>
                <ngx-charts-pie-chart class="chart-container" [scheme]="colorScheme" [results]="single" [legend]="showLegend" [explodeSlices]="explodeSlices"
                    [labels]="showLabels" [doughnut]="doughnut" [arcWidth]="arcWidth" (legendLabelClick)="onLegendLabelClick($event)"
                    [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" (select)="select($event)">
                </ngx-charts-pie-chart>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Advanced pie chart</h4>
                <ngx-charts-advanced-pie-chart class="chart-container" [scheme]="colorScheme" [results]="single" (legendLabelClick)="onLegendLabelClick($event)"
                    [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" (select)="select($event)">
                </ngx-charts-advanced-pie-chart>
            </div>
        </div>
    </div>
</div>