<div class="card">
    <div class="card-body">
        <h4 class="card-title">Feeds</h4>
        <div class="feed-widget scrollable">
            <ul class="list-style-none feed-body m-0 p-b-20">
                <li class="feed-item">
                    <div class="feed-icon bg-info">
                        <i class="far fa-bell"></i>
                    </div>
                    <a href="">You have 4 pending tasks.</a>
                    <span class="ml-auto font-12 text-muted">Just Now</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-success">
                        <i class="ti-server"></i>
                    </div>
                    <a href=""> Server #1 overloaded.</a>
                    <span class="ml-auto font-12 text-muted">2 Hours ago</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-warning">
                        <i class="ti-shopping-cart"></i>
                    </div>
                    <a href="">New order received.</a>
                    <span class="ml-auto font-12 text-muted">31 May</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-danger">
                        <i class="ti-user"></i>
                    </div>
                    <a href="">New user registered.</a>
                    <span class="ml-auto font-12 text-muted">30 May</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-inverse">
                        <i class="far fa-bell"></i>
                    </div>
                    <a href="">New user registered.</a>
                    <span class="ml-auto font-12 text-muted">27 May</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-info">
                        <i class="far fa-bell"></i>
                    </div>
                    <a href="">You have 4 pending tasks.</a>
                    <span class="ml-auto font-12 text-muted">Just Now</span>
                </li>
                <li class="feed-item">
                    <div class="feed-icon bg-danger">
                        <i class="ti-user"></i>
                    </div>
                    <a href="">New user registered.</a>
                    <span class="ml-auto font-12 text-muted">30 May</span>
                </li>
            </ul>
        </div>
    </div>
</div>