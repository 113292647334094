import { Routes } from '@angular/router';
import {LeaverequestComponent} from './leaverequest/leaverequest.component';
import {LeaveapprovalsComponent} from './leaveapprovals/leaveapprovals.component';
import {LeaveplanningComponent} from './leaveplanning/leaveplanning.component';
import {ListComponent} from './list/list.component';
import {LeaveAuthGuardService as AuthGuard} from './list/leave-auth-guard.service'
import {LeavereturnComponent} from './leavereturn/leavereturn.component';

export const LeaveRouting: Routes = [
  {
    path: '',
    children: [
      { 
        path: 'leaverequest',
        component: LeaverequestComponent
      }, {
        path: 'leaveapprove',
        component: LeaveapprovalsComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'leaveplanning',
        component: LeaveplanningComponent
      }, {
        path: 'leavereturn',
        component: LeavereturnComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'list',
        component: ListComponent
      }
    ]
  }
];
