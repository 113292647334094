import { Routes } from '@angular/router';
import {HrdocrequestComponent} from './hrdocrequest/hrdocrequest.component';
import {HrdocapprovalComponent} from './hrdocapproval/hrdocapproval.component';
import {ListComponent} from './list/list.component'
import {LeaveAuthGuardService as AuthGuard} from '../leavesection/list/leave-auth-guard.service'


export const HrDocumentRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'documentrequest',
        component: HrdocrequestComponent
      },
      {
        path: 'documentapproval',
        component: HrdocapprovalComponent,
        canActivate: [AuthGuard]
        
      },
      {
        path: 'list',
        component: ListComponent
      }
    ]
  }
];
