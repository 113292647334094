import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {PopupTable} from '../../leavesection/leaverequest/leaverequest.component';
import {AuthService} from '../../shared/service/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  PopUpTableData: PopupTable[] = [];

  @ViewChild('configModal') public ConfigModal: ModalDirective;

  constructor(private dialog: MatDialogRef<EmployeeListComponent>, private authService: AuthService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.PopUpTableData = [];
    this.spinner.show();
    const employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
    if (employeesData != null) {
      employeesData.forEach((element: any) => {
        this.PopUpTableData.push({
          Code: element.id,
          EmpNo: element.empNo,
          Name: element.name,
          Selected: false,
          RowObject: element
        });
      });
    }
    this.spinner.hide();
  }

  popUpTableValueSelected(row) {
    this.dialog.close(row.RowObject);
  }

}
