import { Component } from '@angular/core';


@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html'
})
export class ProductInfoComponent {
  constructor() {}
}
