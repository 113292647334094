import { Injectable } from '@angular/core';
import { Session} from '../model/ERP Models/Logics.model'
@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
 
  SessionObject: Session;
  constructor() {
    this.SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
   }

  getLocalStorageData(): Session
  {
    return this.SessionObject;
  }

 


}
