import {Component, OnInit, ViewChild} from '@angular/core';
import {ILeaveRequestHeader} from '../../shared/model/ileave-request';
import {LEAVE_REQ_ALL} from '../../app.constants';
import {Router} from '@angular/router';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {CompanyService} from 'src/app/shared/service/company.service';
import {EmployeesService} from 'src/app/shared/service/employees.service';
import {ToastrService} from 'ngx-toastr';
import DateUtils from '../../utils/DateUtils';

@Component({
  selector: 'app-leaveapprovals',
  templateUrl: './leaveapprovals.component.html',
  styleUrls: ['./leaveapprovals.component.css']
})
export class LeaveapprovalsComponent implements OnInit {

  leaveRequests: ILeaveRequestHeader[] = [];
  approvalStatus: any = LEAVE_REQ_ALL;
  approvalStatusOption: any;
  selectedCompany: any;
  selectedDepartment: any;

  editing = {};
  rows = [];
  companies = [];
  departments = [];
  status: string;

  sessionObject = {
    user: '',
    token: '',
    company: ''
  };

  searchResultPagesArray: any[] = [];
  searchResultCurrentPagesArray: any[] = [];
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;

  loadingIndicator = true;
  reorderable = true;

  columns = [
    {prop: 'empNo', name: 'Employee Number'},
    {prop: 'name', name: 'Employee Name'},
    {prop: 'fromDate', name: 'From Date'},
    {prop: 'toDate', name: 'To Date'},
    {prop: 'company', name: 'Company'},
    {prop: 'remarks', name: 'Remarks'},
    {prop: 'remarks', name: 'status'},
    {prop: 'leaveBalance', name: 'Leave Balance'},
    {prop: 'handoverToEmpNo', name: 'Handover To (Emp No)'},
    {prop: 'handoverToEmpName', name: 'Handover To (Emp Name)'},
    {name: 'Actions', width: 50}
  ];

  scrollBarHorizontal = (window.innerWidth < 1200);

  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  constructor(
      private router: Router,
      private leaveRequestService: LeaveRequestService,
      private companyService: CompanyService,
      private employeeService: EmployeesService,
      private spinner: NgxSpinnerService,
      private _toastr: ToastrService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 1200);
    };
  }

  getStatus(leaveRequest: any) {
    if (leaveRequest.rejected === 'Y') {
      return 'Rejected';
    } else if (leaveRequest.confirmed === 'Y') {
      return 'Approved';
    } else if (leaveRequest.managerApproved === true) {
      return 'Manager Approved';
    } else if (leaveRequest.passed === 'Y') {
      return 'Passed';
    }
    return 'Pending';
  }

  ngOnInit(): void {
    this.initializeSessionObject();
    this.loadRequestApprovals(0, this.pageSize);
    this.loadCompanies();
  }


  initializeSessionObject() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.sessionObject.token = SessionObject.id_token;
    this.sessionObject.user = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.sessionObject.company = SessionObject?.glUserMastersDetailsDTO?.compCode;
  }

  openDetails(id) {
    this.router.navigate(['/leaves/leaverequest'], {queryParams: {id: id, src: 'approval'}});
  }

  loadCompanies() {
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
    });
  }

  loadRequestApprovals(page: number, size: number) {
    this.spinner.show();
    this.leaveRequestService.searchLeaveRequests(this.sessionObject.user, this.sessionObject.company, this.approvalStatus, this.selectedCompany, this.selectedDepartment, page, size).subscribe((data: any) => {
      data.content = data.content;
      this.leaveRequests = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }


  private pass(leaveRequestId: number) {
    this.leaveRequestService.pass(leaveRequestId).subscribe(data => {
      this._toastr.success('Leave request has been passed');
      this.loadRequestApprovals(0, this.pageSize);
    }, err => {
      this._toastr.error('Error Occurred');
    });
  }

  filterLeaveRequestsByCompany(event) {
    this.spinner.show();
    this.selectedCompany = event.target.value;
    this.loadDedpartmentsForCompany(this.selectedCompany);
    this.leaveRequestService.searchLeaveRequests(this.sessionObject.user, this.sessionObject.company, this.approvalStatusOption, this.selectedCompany, this.selectedDepartment, 0, this.pageSize).subscribe((data: any) => {
      data.content = data.content;
      this.leaveRequests = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  filterLeaveRequestsByDepartment(event) {
    this.spinner.show();
    this.selectedDepartment = event.target.value;
    this.leaveRequestService.searchLeaveRequests(this.sessionObject.user, this.sessionObject.company, this.approvalStatusOption, this.selectedCompany, this.selectedDepartment, 0, this.pageSize).subscribe((data: any) => {
      this.refreshStatus();
      data.content = data.content;
      this.leaveRequests = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  filterLeaveRequests(event) {
    this.spinner.show();
    this.approvalStatusOption = event.target.value;
    this.leaveRequestService.searchLeaveRequests(this.sessionObject.user, this.sessionObject.company, event.target.value, this.selectedCompany, this.selectedDepartment, 0, this.pageSize).subscribe((data: any) => {
      this.refreshStatus();
      data.content = data.content;
      this.leaveRequests = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  private refreshStatus() {
    if (this.approvalStatusOption === '6') {
      this.status = 'All';
    } else if (this.approvalStatusOption === '5') {
      this.status = 'Pending';
    } else if (this.approvalStatusOption === '4') {
      this.status = 'Passed';
    } else if (this.approvalStatusOption === '3') {
      this.status = 'Manager Approved';
    } else if (this.approvalStatusOption === '2') {
      this.status = 'Rejected';
    } else if (this.approvalStatusOption === '1') {
      this.status = 'Approved';
    }
  }


  loadDedpartmentsForCompany(selectedCompany: any) {
    this.employeeService.getDepartmentsByCompany(selectedCompany).subscribe(data => {
      this.departments = data;
    });
  }

  searchPaginated(event: any) {
    this.spinner.show();
    this.leaveRequestService.searchLeaveRequests(this.sessionObject.user, this.sessionObject.company, this.approvalStatus, this.selectedCompany, this.selectedDepartment, event.pageIndex, event.pageSize).subscribe((data: any) => {
      data.content = data.content;
      this.leaveRequests = data.content;
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    });
  }

  managerApprove(leaveRequestId) {
    this.spinner.show();
    this.leaveRequestService.managerApprove(leaveRequestId).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('Leave request has been approved');
      window.location.reload();
    });
  }

  private remarksForReject(leaveRequestId: number) {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {
        const remarks = result.value;
        this.reject(remarks, leaveRequestId);
      } else {
        this.spinner.hide();
        this._toastr.error('Remarks are mandatory');
      }

    });
  }


  private reject(remarks: string, leaveRequestId: number) {
    this.spinner.show();
    this.leaveRequestService.reject(remarks, leaveRequestId).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('Leave request has been Rejected');
      this.loadRequestApprovals(0, this.pageSize);
    });
  }

  private remarksForApprove(leaveRequestId: number) {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Approve',
    }).then((result) => {
      if (result.value) {
        const remarks = result.value;
        this.approve(remarks, leaveRequestId);
      }
    });
  }

  private approve(remarks: string, leaveRequestId: number) {
    this.leaveRequestService.approve(remarks, leaveRequestId).subscribe(data => {
      this._toastr.success('Leave request has been Approved');
      this.loadRequestApprovals(0, this.pageSize);
    });
  }

  private formatDate(date: string | null): string {
    return date ? DateUtils.get(date, 'DD-MM-YYYY') : '';
  }
}
