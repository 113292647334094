<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Project Assigning</h4>
                <h6 class="card-subtitle">This is the basic horizontal form with labels on left and form controls on right in one line. To use add
                    class
                    <code>form-horizontal</code> to the form tag and give class
                    <code>row</code> with form-group.</h6>
            </div>
            <hr>
            <form class="form-horizontal">
                <div class="card-body">
                    <h4 class="card-title">Personal Info</h4>
                    <div class="form-group row">
                        <label for="fname" class="col-sm-3 text-right control-label col-form-label">First Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="fname" placeholder="First Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">Last Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="lname" placeholder="Last Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email1" class="col-sm-3 text-right control-label col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="email1" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="cono1" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="cono1" placeholder="Contact No Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <h4 class="card-title">Requirements</h4>
                    <div class="form-group row">
                        <label for="com1" class="col-sm-3 text-right control-label col-form-label">Company</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="com1" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-right control-label col-form-label">Interested In</label>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Desiging</option>
                                <option>Development</option>
                                <option>Videography</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-right control-label col-form-label">Budget</label>
                        <div class="col-sm-9">
                            <select class="form-control">
                                <option>Choose Your Option</option>
                                <option>Less then $5000</option>
                                <option>$5000 - $10000</option>
                                <option>$10000 - $20000</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-right control-label col-form-label">Select File</label>
                        <div class="col-sm-9">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">Upload</span>
                                </div>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="inputGroupFile01">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="abpro" class="col-sm-3 text-right control-label col-form-label">About Project</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="abpro" placeholder="About Project Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Employee Profile</h4>
                <h6 class="card-subtitle">This is the employee profile form with labels on left and form controls on right in one line two controls.
                    To use add class
                    <code>form-horizontal</code> to the form tag and give class
                    <code>row</code> with form-group.</h6>
            </div>
            <hr>
            <form class="form-horizontal">
                <div class="card-body">
                    <h4 class="card-title">Employee User</h4>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="fname2" class="col-sm-3 text-right control-label col-form-label">First Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="fname2" placeholder="First Name Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="lname2" class="col-sm-3 text-right control-label col-form-label">Last Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="lname2" placeholder="Last Name Here">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="uname1" class="col-sm-3 text-right control-label col-form-label">Username</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" id="uname1" placeholder="Username Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="nname" class="col-sm-3 text-right control-label col-form-label">Nick Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="nname" placeholder="Nick Name Here">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <h4 class="card-title">Contact Info &amp; Bio</h4>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="email2" class="col-sm-3 text-right control-label col-form-label">Email</label>
                                <div class="col-sm-9">
                                    <input type="email" class="form-control" id="email2" placeholder="Email Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="web1" class="col-sm-3 text-right control-label col-form-label">Website</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="web1" placeholder="http://">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="cono12" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="cono12" placeholder="Contact No Here">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group row">
                                <label for="bio1" class="col-sm-3 text-right control-label col-form-label">Bio</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="bio1" placeholder="Bio Here">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Employee Timing</h4>
                <h6 class="card-subtitle">This is the employee timing form with labels on left and form controls on right in one line. To use add class
                    <code>form-horizontal</code> to the form tag and give class
                    <code>row</code> with form-group.</h6>
            </div>
            <hr>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group row">
                        <label for="ename" class="col-sm-3 text-right control-label col-form-label">Employee Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="ename" placeholder="Employee Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="pname" class="col-sm-3 text-right control-label col-form-label">Project Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="pname" placeholder="Project Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="date" class="col-sm-3 text-right control-label col-form-label">Date</label>
                        <div class="col-sm-9">
                            <input type="date" class="form-control" id="date">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="rate" class="col-sm-3 text-right control-label col-form-label">Rate Per Hour</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="rate" placeholder="Rate Per Hour Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="stime" class="col-sm-3 text-right control-label col-form-label">Start Time</label>
                        <div class="col-sm-9">
                            <input type="time" class="form-control" id="stime">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="etime" class="col-sm-3 text-right control-label col-form-label">End Time</label>
                        <div class="col-sm-9">
                            <input type="time" class="form-control" id="etime">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="note1" class="col-sm-3 text-right control-label col-form-label">Notes</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="note1" placeholder="Notes Here">
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Event Registration</h4>
                <h6 class="card-subtitle">This is the event registration form with labels on left and form controls on right in one line. To use add
                    class
                    <code>form-horizontal</code> to the form tag and give class
                    <code>row</code> with form-group.</h6>
            </div>
            <hr>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group row">
                        <label for="fullname" class="col-sm-3 text-right control-label col-form-label">Full Name</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="fullname" placeholder="Full Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="title1" class="col-sm-3 text-right control-label col-form-label">Title</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="title1" placeholder="Title Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="com12" class="col-sm-3 text-right control-label col-form-label">Company</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="com12" placeholder="Company Name Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email20" class="col-sm-3 text-right control-label col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" id="email20" placeholder="Email Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="cono3" class="col-sm-3 text-right control-label col-form-label">Contact No</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" id="cono3" placeholder="Contact No Here">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 text-right control-label col-form-label">Existing Customer</label>
                        <div class="col-sm-9">
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="customControlValidation2" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation2">Yes</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="customControlValidation3" name="radio-stacked" required>
                                <label class="custom-control-label" for="customControlValidation3">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group m-b-0 text-right">
                        <button type="submit" class="btn btn-info waves-effect waves-light">Save</button>
                        <button type="submit" class="btn btn-dark waves-effect waves-light">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-12">
        <div class="card ">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Other Sample Horizontal form</h4>
            </div>
            <form action="#" class="form-horizontal">
                <div class="form-body">
                    <div class="card-body">
                        <h6 class="card-subtitle">This is the sample horizontal form with labels on left and form controls on right in one line. To
                            use add class
                            <code>form-horizontal</code> to the form tag and give class
                            <code>row</code> with form-group.</h6>
                        <h4 class="card-title">Person Info</h4>
                    </div>
                    <hr class="m-t-0 m-b-40">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">First Name</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control" placeholder="John doe">
                                        <small class="form-control-feedback"> This is inline help </small>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group has-danger row">
                                    <label class="control-label text-right col-md-3">Last Name</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control form-control-danger" placeholder="12n">
                                        <small class="form-control-feedback"> This field has error. </small>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Gender</label>
                                    <div class="col-md-9">
                                        <select class="form-control custom-select">
                                            <option value="">Male</option>
                                            <option value="">Female</option>
                                        </select>
                                        <small class="form-control-feedback"> Select your gender. </small>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Date of Birth</label>
                                    <div class="col-md-9">
                                        <input type="date" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Category</label>
                                    <div class="col-md-9">
                                        <select class="form-control custom-select" data-placeholder="Choose a Category" tabindex="1">
                                            <option value="Category 1">Category 1</option>
                                            <option value="Category 2">Category 2</option>
                                            <option value="Category 3">Category 5</option>
                                            <option value="Category 4">Category 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Membership</label>
                                    <div class="col-md-9">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="customRadio3" name="member" class="custom-control-input">
                                            <label class="custom-control-label" for="customRadio3">Free</label>
                                        </div>
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="customRadio4" name="member" class="custom-control-input">
                                            <label class="custom-control-label" for="customRadio4">Paid</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <h4 class="card-title">Address</h4>
                    </div>
                    <hr class="m-t-0 m-b-40">
                    <div class="card-body">
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Address 1</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Address 2</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">City</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">State</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Post Code</label>
                                    <div class="col-md-9">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label class="control-label text-right col-md-3">Country</label>
                                    <div class="col-md-9">
                                        <select class="form-control custom-select">
                                            <option>Country 1</option>
                                            <option>Country 2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <!--/span-->
                        </div>
                        <!--/row-->
                    </div>
                    <hr>
                    <div class="form-actions">
                        <div class="card-body">
                            <div class="text-right">
                                <button type="submit" class="btn btn-info">Submit</button>
                                <button type="button" class="btn btn-dark">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Row -->
<!-- Row -->
<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Form with left Icons</h4>
                <h6 class="card-subtitle">Use Bootstrap's predefined grid classes for horizontal form</h6>
                <form class="form-horizontal p-t-20">
                    <div class="form-group row">
                        <label for="username" class="col-sm-3 control-label">Username*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="ti-user"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="username" placeholder="Username">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email4" class="col-sm-3 control-label">Email*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="ti-email"></i>
                                    </span>
                                </div>
                                <input type="email" class="form-control" id="email4" placeholder="Enter email">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="web" class="col-sm-3 control-label">Website</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="ti-world"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" id="web" placeholder="Enter Website Name">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 control-label">Password*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="ti-lock"></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control" id="exampleInputpwd4" placeholder="Enter pwd">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 control-label">Re Password*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="ti-lock"></i>
                                    </span>
                                </div>
                                <input type="password" class="form-control" id="exampleInputpwd5" placeholder="Re Enter pwd">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="offset-sm-3 col-sm-9">
                            <div class="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="checkbox2" value="check">
                                <label class="custom-control-label" for="checkbox2">Check Me Out !</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row m-b-0">
                        <div class="offset-sm-3 col-sm-9">
                            <button type="submit" class="btn btn-info waves-effect waves-light">Sign in</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Form with right Icon</h4>
                <h6 class="card-subtitle">Use Bootstrap's predefined grid classes for horizontal form</h6>
                <form class="form-horizontal p-t-20">
                    <div class="form-group row">
                        <label for="uname" class="col-sm-3 control-label">Username*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="text" class="form-control" id="uname" placeholder="Username">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="ti-user"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="email2" class="col-sm-3 control-label">Email*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="ti-email"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="web10" class="col-sm-3 control-label">Website</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="text" class="form-control" id="web10" placeholder="Enter Website Name">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="ti-world"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="pass3" class="col-sm-3 control-label">Password*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="password" class="form-control" id="pass3" placeholder="Enter pwd">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="ti-lock"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="pass4" class="col-sm-3 control-label">Re Password*</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input type="password" class="form-control" id="pass4" placeholder="Re Enter pwd">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="ti-lock"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="offset-sm-3 col-sm-9">
                            <div class="custom-control custom-checkbox mr-sm-2">
                                <input type="checkbox" class="custom-control-input" id="checkbox4" value="check">
                                <label class="custom-control-label" for="checkbox4">Check Me Out !</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row m-b-0">
                        <div class="offset-sm-3 col-sm-9 ">
                            <button type="submit" class="btn btn-info waves-effect waves-light">Sign in</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Row -->