<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Basic Editor</h4>
                <h6 class="card-subtitle">quill editor with angular and TypeScript. ngx-quill is the new angular 2 and beyond implementation of ngQuill.
                    <a href="https://github.com/KillerCodeMonkey/ngx-quill" target="_blank">Official website</a>
                </h6>
                <quill-editor [style]="{height: '200px'}"></quill-editor>
            </div>
        </div>

    </div>
</div>