import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LeaveRouting} from './leave.routing';
import {LeaverequestComponent} from './leaverequest/leaverequest.component';
import {LeaveapprovalsComponent} from './leaveapprovals/leaveapprovals.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {LeaveplanningComponent} from './leaveplanning/leaveplanning.component';
import {LeavereturnComponent} from './leavereturn/leavereturn.component';
import {ListComponent} from './list/list.component';
import {DataTablesModule} from 'angular-datatables';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(LeaveRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    SelectDropDownModule,
    DataTablesModule,
    MatPaginatorModule,
  ],
  declarations: [
  LeaverequestComponent,
  LeaveapprovalsComponent,
  LeaveplanningComponent,
  LeavereturnComponent,
  ListComponent],
  providers: [NgbModal]
})
export class LeaveModule {}
