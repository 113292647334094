<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Document Type</h4>
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Document Type
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select (change)="changeDocType($event)" class="form-control">
                            <ng-container *ngFor="let docType of documentTypes">
                              <option value="{{docType.docType}}">{{docType.description}}</option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Code
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input [value]="docCode" type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Description
                        </label>
                      </div>
                      <div class="col-lg-8 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          GL Code
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          SL Code
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          GL Code Other Charges
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          SL Code Other Charges
                        </label>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" disabled class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Auto Doc
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Starting Serial No
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="number" class="form-control"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Reinitiaalise Doc For Year
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Posting to A/C
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>No Posting</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Freeze Doc After Print
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Document Printing on
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Blank Sheet</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Invoice type
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          inventory Effect
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Voucher Code
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Return Type
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Approval Levels
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <input type="text" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label><input type="checkbox"/><span>Workshop Related</span></label>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Scaffolding
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Work Order Related
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <label>
                          Cash Document
                        </label>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="form-group">
                          <select class="form-control">
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
