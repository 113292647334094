import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HrpHeadersService} from './hrp-headers.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CandidateSelectionSearchDto} from '../dto/recruitment/selection/candidateSelectionSearchDto';
import {CandidateSelectionDto} from '../dto/recruitment/selection/candidateSelectionDto';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentCandidateSelectionService {


  constructor(private http: HttpClient, private headers: HrpHeadersService) {
  }


  search(candidateSelectionSearchDto: CandidateSelectionSearchDto, page: number, size: number): Observable<any> {
    const endPoint = `${environment.host}/api/recruitment/selection/search?page=${page}&size=${size}`;
    return this.http.post<any>(endPoint, candidateSelectionSearchDto, this.headers.getHttpOptions('POST')).pipe(
        catchError(() => {
          return throwError('Unable to get Candidate Selection list !');
        })
    );
  }


  delete(candidateSelectionNumber) {
    const endPoint = `${environment.host}/api/recruitment/selection/delete/${candidateSelectionNumber}`;
    return this.http.delete<any>(endPoint, this.headers.getHttpOptions('DELETE')).pipe(
        catchError(() => {
          return throwError('Unable to delete Candidate Selection !');
        })
    );
  }

  save(candidateSelectionDto: CandidateSelectionDto) {
    const endPoint = `${environment.host}/api/recruitment/selection/save`;
    return this.http.post<any>(endPoint, candidateSelectionDto, this.headers.getHttpOptions('POST', true)).pipe(
        catchError(() => {
          return throwError('Unable to save Candidate Selection !');
        })
    );
  }

  update(candidateSelectionDto: CandidateSelectionDto) {
    const endPoint = `${environment.host}/api/recruitment/selection/update`;
    return this.http.put<any>(endPoint, candidateSelectionDto, this.headers.getHttpOptions('PUT', true)).pipe(
        catchError(() => {
          return throwError('Unable to update Candidate Selection !');
        })
    );
  }

  confirm(id) {
    const endPoint = `${environment.host}/api/recruitment/selection/confirm/${id}`;
    return this.http.put<any>(endPoint, null, this.headers.getHttpOptions('PUT')).pipe(
        catchError(() => {
          return throwError('Unable to confirm Candidate Selection !');
        })
    );
  }

  generateDocNumber(compCode) {
    const endPoint = `${environment.host}/api/recruitment/selection/generateDocNumber/${compCode}`;
    return this.http.get<any>(endPoint, this.headers.getHttpOptions('GET')).pipe(
        catchError(() => {
          return throwError('Unable to generate Candidate Selection Doc Number !');
        })
    );
  }

  reject(candidateSelectionDto: CandidateSelectionDto) {
    const endPoint = `${environment.host}/api/recruitment/selection/reject`;
    return this.http.put<any>(endPoint, candidateSelectionDto, this.headers.getHttpOptions('PUT', true)).pipe(
        catchError(() => {
          return throwError('Unable to reject Candidate Selection !');
        })
    );
  }

  pass(id) {
    const endPoint = `${environment.host}/api/recruitment/selection/pass/${id}`;
    return this.http.put<any>(endPoint, null, this.headers.getHttpOptions('PUT')).pipe(
        catchError(() => {
          return throwError('Unable to pass Candidate Selection !');
        })
    );
  }


}
