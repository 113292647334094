import moment from 'moment';

export default class DateUtils {
    static get(current?: Date|string|undefined|null, format?: string): string {
        if (!current) {
            current = new Date();
        }

        let date: any;
        if (current instanceof Date) {
            date = moment(current);
        } else {
            date = moment(current, format);
        }

        return date.format('DD-MM-YYYY');
    }
}
