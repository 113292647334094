<div class="card">
    <div class="card-body p-b-0">
        <h4 class="card-title">Projects of the Month</h4>
        <div class="table-responsive">
            <table class="table v-middle">
                <thead>
                    <tr>
                        <th class="border-top-0">Team Lead</th>
                        <th class="border-top-0">Project</th>
                        <th class="border-top-0">Weeks</th>
                        <th class="border-top-0">Budget</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="m-r-10">
                                    <img src="assets/images/users/d1.jpg" alt="user" class="rounded-circle" width="45">
                                </div>
                                <div class="">
                                    <h4 class="m-b-0 font-16">Hanna Gover</h4>
                                    <span>hgover@gmail.com</span>
                                </div>
                            </div>
                        </td>
                        <td>Elite Admin</td>
                        <td>35</td>
                        <td class="font-medium">$96K</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="m-r-10">
                                    <img src="assets/images/users/d2.jpg" alt="user" class="rounded-circle" width="45">
                                </div>
                                <div class="">
                                    <h4 class="m-b-0 font-16 font-medium">Daniel Kristeen</h4>
                                    <span>Kristeen@gmail.com</span>
                                </div>
                            </div>
                        </td>
                        <td>Elite Admin</td>
                        <td>35</td>
                        <td class="font-medium">$96K</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="m-r-10">
                                    <img src="assets/images/users/d3.jpg" alt="user" class="rounded-circle" width="45">
                                </div>
                                <div class="">
                                    <h4 class="m-b-0 font-16 font-medium">Julian Josephs</h4>
                                    <span>Josephs@gmail.com</span>
                                </div>
                            </div>
                        </td>
                        <td>Elite Admin</td>
                        <td>35</td>
                        <td class="font-medium">$96K</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="m-r-10">
                                    <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" width="45">
                                </div>
                                <div class="">
                                    <h4 class="m-b-0 font-16 font-medium">Jan Petrovic</h4>
                                    <span>hgover@gmail.com</span>
                                </div>
                            </div>
                        </td>
                        <td>Elite Admin</td>
                        <td>35</td>
                        <td class="font-medium">$96K</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="d-flex align-items-center">
                                <div class="m-r-10">
                                    <img src="assets/images/users/d2.jpg" alt="user" class="rounded-circle" width="45">
                                </div>
                                <div class="">
                                    <h4 class="m-b-0 font-16 font-medium">Daniel Kristeen</h4>
                                    <span>Kristeen@gmail.com</span>
                                </div>
                            </div>
                        </td>
                        <td>Elite Admin</td>
                        <td>35</td>
                        <td class="font-medium">$96K</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>