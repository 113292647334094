<br>

<div *ngIf="!show" class="col mx-auto w-75 p-3 shadowdiv">

    <div id="JVSelector">
        <br>
        <div class="row">

            <div class="col-sm">
                <h4> Select Period and Voucher </h4>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-3"> <label> Period </label> </div>

            <div class="col-sm">
                <select class=" form-control " (change)="setDateFromPeriod($event)">
                    <option *ngFor="let period of PeriodList" [value]="period.Period"> {{ period.Period }}</option>
                </select>
            </div>

            <div class="col-sm">
                <input [value]="SelectedPeriod.FromDate? SelectedPeriod.FromDate: '' " class="form-control" disabled>
            </div>

            <div class="col-sm">
                <input [value]="SelectedPeriod.ToDate? SelectedPeriod.ToDate: '' " class="form-control" disabled>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-3"> <label> Voucher Period </label> </div>

            <div class="col">
                <select class="form-control" (change)="setVoucherCode($event)">
                    <option *ngFor="let voucher of VourcherList;let i = index" [selected]="i==0" [value]="voucher.Code">
                        {{ voucher.Code +' - '+ voucher.Description}}
                    </option>
                </select>
            </div>

        </div>

        <br>

        <div class="row">
            <div class="col">
                <button class="btn btn-primary mr-3" (click)="showJournalVoucher()"> OK </button>
                <button class="btn btn-primary"> SAVE </button>
            </div>


        </div>

    </div>

</div>

<div *ngIf="show" class="col-md-12 p-3 shadowdiv">
    <div id="JVDetailed">
        <h4>Journal Voucher</h4>
        <hr>

        <div class="row">
            <div class="col-sm-2">
                <label>Document Number</label>
            </div>

            <div class="col-sm-1">
                <input class="form-control mr-2" type="text" [value]="SelectedVoucher" disabled>

            </div>

            <div class="col-sm-2">
                <input class=" form-control " type="text" [value]="DocumentCode?DocumentCode:''" disabled>
            </div>

            <div class="col-sm">
                <label>Doc. Date</label>
            </div>

            <div class="col-sm">
                <input class="form-control" type="text" [value]="SelectedPeriod.ToDate" disabled>
            </div>

            <div class="col-sm">
                <input type="checkbox" class="mr-1" disabled>
                <label>Rejected</label>
            </div>

            <div class="col-sm">

                <input type="checkbox" class="mr-1" disabled>
                <label>Passed</label>

            </div>

            <div class="col-sm">
                <input type="checkbox" class="mr-1" disabled>
                <label>Confirmed</label>
            </div>

        </div>

        <div class=" row mt-2">
            <div class=" col-sm-2">
                <label>Reference</label>
            </div>

            <div class=" col-sm-3">
                <input class="form-control" [(ngModel)]="SelectedReference">
            </div>

            <div class=" col-sm">
                <button class=" btn btn-primary"> EMPLOYEE LOAN </button>
            </div>

            <div class=" col-sm">
                <button class=" btn btn-primary"> SETTLEMENT </button>
            </div>

            <div class=" col-sm">
                <h3> <button class="btn btn-success" (click)="saveTransaction()" [disabled]="!(!!SumCredit || !!SumDebit)"> PASS </button></h3>
            </div>

        </div>

        <div class=" row mt-2">
            <div class=" col-sm-2">
                <label>Being</label>
            </div>

            <div class=" col-sm-3">
                <input class=" form-control" [(ngModel)]="SelectedBeing">
            </div>

            <div class=" col-sm-5">

            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-2">
                <label>Currency</label>
            </div>

            <div class=" col-sm">
                <select class="form-control" (change)="currencyChanged($event)">
                    <option *ngFor="let currency of CurrencyList">
                        {{ currency.code }}
                    </option>
                </select>
            </div>

            <div class=" col-sm">
                <label>Exhange Rate </label>
            </div>

            <div class=" col-sm">
                <input [value]="SelectedCurrency.purchaseRate? SelectedCurrency.purchaseRate: '' " class="form-control"
                    disabled>
            </div>

            <div class=" col-sm">
                <label>Created By </label>
            </div>

            <div class=" col-sm">
                <input class=" form-control" [value]="Session.UserName" disabled>
            </div>

            <div class=" col-sm">
                <label>Created On </label>
            </div>

            <div class=" col-sm">
                <input class=" form-control" [value]="CurrentDate" disabled>
            </div>

        </div>

        <br>
        <hr>
        <br>

        <div class="row">
            <div class="col" style="overflow-x: scroll;">
                <table class="table table-bordered table-hover">
                    <thead >
                        <th>SNo.</th>
                        <th>GL Code</th>
                        <th>SL Code</th>
                        <th>SL Desc</th>
                        <th>Cost Code</th>
                        <th>Category</th>
                        <th>Debit Amount</th>
                        <th>Credit Amount</th>
                        <th>Bank Code </th>
                        <th>Invoice No. </th>
                        <th>Invoice Date </th>
                        <th>Description </th>
                        <th>Ship.No. </th>
                        <th>Ship.Exp.Code </th>
                        <th>Asset Exp</th>
                        <th>Due Date</th>
                        <th>LPO No. </th>
                    </thead >
                    <tbody>
                        <tr *ngFor="let row of BodyRows">
                            <td>{{DataObject['GL'][row]?row:''}}</td>
                            <td (click)="OpenSelectModal('GL',row)"
                                [innerHTML]="DataObject['GL'][row]? DataObject['GL'][row].Code: ''"> </td>

                            <td (click)="OpenSelectModal('SL',row)"
                                [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Code: ''"> </td>
                            <td [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Name: ''"> </td>
                            <td (click)="OpenSelectModal('CCD',row)"
                                [innerHTML]="DataObject['CCD'][row]? DataObject['CCD'][row].id: ''"> </td>
                            <td (click)="OpenSelectModal('CAT',row)"
                                [innerHTML]="DataObject['CAT'][row]? DataObject['CAT'][row].Name: ''"> </td>
                            <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Debit'][row]"
                                    [disabled]="!!DataObject['Credit'][row]"> </td>
                            <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Credit'][row]"
                                    [disabled]="!!DataObject['Debit'][row]"> </td>
                            <td (click)="OpenSelectModal('CCC',row)"
                                [innerHTML]="DataObject['CCC'][row]? DataObject['CCC'][row].Name: ''">
                            </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="date" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="date" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                        </tr>

                        <tr>
                            <td colspan="5"></td>
                            <th class="text-right"> Total</th>
                            <td> <b>{{DataObject.Debit? fetchTotal('Debit'):''}} </b> </td>
                            <td> <b>{{DataObject.Credit? fetchTotal('Credit'):''}} </b> </td>
                            <th colspan="9" class="text-left"> Diff. Amount: <b>{{SumCredit-SumDebit}}</b></th>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

        <br>
        <br>
        <hr>
        <div class="row">
            <div class="col-sm-6">
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>GL Desc</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>Branch</label>
                    </div>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-4">
                                <input type="text" class="form-control">
                            </div>
                            <div class="col-sm-8">
                                <input type="text" disabled class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>Cost Desc</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>BOQ Desc</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>Category Desc</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-4 mt-2">
                        <label>Asset Run Cost</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="row mt-2">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6 mt-2">
                                <label>Settled Amount </label>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6 mt-2">
                                <label>Area</label>
                            </div>
                            <div class="col-sm-6">
                                <input type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Cost centre</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Exp Desc. </label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Bank GL</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Bank SL</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Bank Desc</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
            <div class="modal-header text-center">
                <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container table-responsive">
                <div>
                    <table class=" w-50 text-center  " id="GLTable" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="compact">
                        <thead class="darkHeaders text-center ">
                            <tr>
                                <th> </th>
                                <th> Code </th>
                                <th> Name </th>
                            </tr>
                        </thead>
                        <tbody class=" text-center">
                            <tr *ngFor="let row of PopUpTableData" [class]="row? row.Selected==true?'reedd':'':''">
                                <td> <input type="radio" (change)="popUpTableValueSelected($event, row)"
                                        [checked]="row.Selected"> </td>
                                <td> {{ row.Code }} </td>
                                <td> {{ row.Name }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>