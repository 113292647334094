import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {AuthService} from '../service/auth.service';
import {NotificationService} from './notification.service';
import {NotificationDto} from './notificationdto';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification;

  public config: PerfectScrollbarConfigInterface = {};
  public notificationList: NotificationDto[];
  public notificationPageSize = 10;
  public unreadNotifications = 0;
  public undeletedNotificationsCount = 0;
  private intervals = [];
  public maxNotificationSize = 10;

  constructor(
      private modalService: NgbModal,
      public authService: AuthService,
      private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.getAllUndeletedNotification(0, this.notificationPageSize);
    const id = setInterval(callback => {
      this.getAllUndeletedNotification(0, this.notificationPageSize);
    }, 10000);
    this.intervals.push({'id': id});
  }

  getAllUndeletedNotification(page, size) {
    this.notificationService.getAllUndeletedNotifications(page, size).subscribe(result => {
      this.notificationList = result.content;
      this.undeletedNotificationsCount = result.totalElements;
    });
    this.notificationService.countUnreadNotifications().subscribe(count => {
      this.unreadNotifications = count;
    });
    if (this.notificationPageSize > this.undeletedNotificationsCount) {
      this.notificationPageSize = this.maxNotificationSize;
    }
  }


  readNotification(notification: NotificationDto) {
    if (!notification.read) {
      this.unreadNotifications--;
      notification.read = true;
      this.notificationService.readNotification(notification.id).subscribe();
    }
  }

  deleteNotification(notification: NotificationDto) {
    if (!notification.deleted) {
      notification.deleted = true;
      this.notificationList.splice(this.notificationList.indexOf(notification), 1);
      this.notificationService.deleteNotification(notification.id).subscribe();
    }
  }

  showMore() {
    if (this.notificationPageSize < this.undeletedNotificationsCount) {
      this.notificationPageSize = this.notificationPageSize + this.maxNotificationSize;
      this.getAllUndeletedNotification(0, this.notificationPageSize);
    }
  }

  showLess() {
    if (this.notificationPageSize > this.maxNotificationSize) {
      this.notificationPageSize = this.notificationPageSize - this.maxNotificationSize;
      this.getAllUndeletedNotification(0, this.notificationPageSize);
    }
  }


  readAllNotifications() {
    this.notificationList.forEach(notification => notification.read = true);
    this.unreadNotifications = 0;
    this.notificationService.readAllNotifications().subscribe();
  }
}
