<section id="ngx">
    <div class="card">
        <div class="card-body">
            <div class="card-block">
                <div class="board">
                    <!-- Navigation Area (Circular Tabs) -->
                    <msw-navbar></msw-navbar>
                    <!-- End Navigation Area (Circular Tabs) -->

                    <!-- Content Area -->
                    <div class="tab-content">
                        <!-- Nested view  -->
                        <router-outlet> </router-outlet>
                    </div>
                    <!-- End Content Area -->
                </div>
                <!-- For Debugging: show our valid formData -->
            </div>
        </div>
    </div>        
</section>