<div class="card bg-light-info no-card-border">
    <div class="card-body text-center">
        <div class="profile-pic m-b-20 m-t-20">
            <img src="assets/images/users/5.jpg" width="150" class="rounded-circle" alt="user">
            <h4 class="m-t-20 m-b-0">Daniel Kristeen</h4>
            <a href="mailto:danielkristeen@gmail.com">danielkristeen@gmail.com</a>
        </div>

    </div>
    <div class="p-25 border-top m-t-15">
        <div class="row text-center">
            <div class="col-6 border-right">
                <a href="#" class="link d-flex align-items-center justify-content-center font-medium">
                    <i class="mdi mdi-message font-20 m-r-5"></i>Message</a>
            </div>
            <div class="col-6">
                <a href="#" class="link d-flex align-items-center justify-content-center font-medium">
                    <i class="mdi mdi-developer-board font-20 m-r-5"></i>Portfolio</a>
            </div>
        </div>
    </div>
</div>