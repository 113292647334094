import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import {environment} from '../../../../environments/environment';
import { HeadersService } from '../../service/headers.service'
import { SaveTransactionPayload } from '../../model/ERP Models/Payloads.model'
@Injectable({
  providedIn: 'root'
})
export class GenralLedgerService {

  constructor(
    private http: HttpClient,
    private headers: HeadersService
  ) {}

  getPeriodByCompanyAndBranch(CompnayCode, FinancialCode, BranchCode): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/glFinPeriod/get-by-comp-and-branch?compCode=${CompnayCode}&financialYear=${FinancialCode}&branchCode=${BranchCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getVoucherCode(CompanyCode, DocumentType, FinancialYear): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/glvoucher/get-by-doc-type?compCode=${CompanyCode}&vTypeDocType=${DocumentType}&financialYear=${FinancialYear}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  generateDocumentCode(Payload, PeriodID): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/glTrxHeadersService/generate-doc-no/${PeriodID}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  getCurrencies(): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/currencies`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getAllGLCodes(): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/glMaster/readAll/01?page=0&size=100000`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getSLCodes(GLCode: string, CompanyCode: string): Observable<any>
  {
    const EndPoint = `${environment.hostErp}/api/glMaster/readAll/SL/${GLCode}?compCode=${CompanyCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getCategories()
  {
    const EndPoint = `${environment.hostErp}/api/categories/all`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getCostCode(SL_ID: string, SL_TYPE: string) 
  {
    const EndPoint = `${environment.hostErp}/api/jc-expenses/find-by-sl?slId=${SL_ID}&slType=${SL_TYPE}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getCostCenters()
  {
    const EndPoint = `${environment.hostErp}/api/costcentres`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  saveTransation(Payload: SaveTransactionPayload)
  {
    const EndPoint = `${environment.hostErp}/api/transaction/save`;
    return this.http.post(EndPoint, Payload ,this.headers.getHttpOptions('POST'));
  }

  getGLCodesForBR()
  {
    const EndPoint = `${environment.hostErp}/api/glMaster/all/by-bank-account/01`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getBanks(CompanyCode, GLCode)
  {
    const EndPoint = `${environment.hostErp}/api/glBanks/read-by-glMaster?compCode=${CompanyCode}&glmaster=${GLCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }
}
