import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ProvisionRouting} from './provision.routing';
import { InquiryComponent } from './inquiry/inquiry.component';
import { EmployeeDetailComponent } from './employee-detail/employee-detail.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ProvisionRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [
    InquiryComponent,
    EmployeeDetailComponent
  ]
})
export class ProvisionModule {}
