<div class="row">
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4>Declarative usage</h4>
				<h6 class="mt-3">Body only</h6>
				<ngb-toast>
				  I am a simple static toast.
				</ngb-toast>

				<h6 class="mt-3">With a text header</h6>
				<ngb-toast header="Hello" [autohide]="false">
				  I am a simple static toast with a header.
				</ngb-toast>
				<ngb-alert type="secondary" [dismissible]="false">Clicking on the close icon won't do anything in this example.</ngb-alert>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4>Using a Template as header</h4>
				<ngb-toast>
					<ng-template ngbToastHeader>
					    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M16.896 10l-4.896-8-4.896 8-7.104-4 3 11v5h18v-5l3-11-7.104 4zm-11.896 10v-2h14v2h-14zm14.2-4h-14.4l-1.612-5.909 4.615 2.598 4.197-6.857 4.197 6.857 4.615-2.598-1.612 5.909z"/></svg>
					    <strong class="mx-1">Fancy</strong>header here
					</ng-template>
					Hello, I am toast. Have you noticed my header has been generated from a Template?
				</ngb-toast>
				<ngb-alert type="secondary" [dismissible]="false">Clicking on the close icon won't do anything in this example.</ngb-alert>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4>Closeable toast</h4>
				<ngb-toast *ngIf="show" header="Click my close icon →" [autohide]="false"
				  (hide)="close()">
				  If you close me, I will automatically re-appear after a few seconds.
				</ngb-toast>
				<p *ngIf="!show">I'll be back!</p>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4>Prevent autohide on mouseover</h4>
				<p>
				  In this demo, you can show a toast by clicking the button below. It will hide itself after a 5 seconds delay unless you simply hover it with your mouse.
				</p>
				<button class="btn btn-primary" (click)="showauto = true">
				  Click me to show a toast
				</button>
				<hr *ngIf="showauto">
				<ngb-toast
				  *ngIf="showauto"
				  header="Autohide can be cancelled"
				  [delay]="5000"
				  [autohide]="autohide"
				  (mouseenter)="autohide = false"
				  (mouseleave)="autohide = true"
				  (hide)="showauto = false; autohide = true"
				  [class.bg-warning]="!autohide"
				>
				  <div *ngIf="autohide">
				    Try to mouse hover me.
				  </div>
				  <div *ngIf="!autohide">
				    I will remain visible until you leave again.
				  </div>
				</ngb-toast>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-body">
				<h4>Toast management service</h4>
				<button class="btn btn-primary" (click)="showStandard()" ngbTooltip="Will disappear in 5s">Standard toast</button>&nbsp;
				<button class="btn btn-success" (click)="showSuccess()" ngbTooltip="Will disappear in 10s">Success toast</button>&nbsp;

				<ng-template #dangerTpl>
				  <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24"><path d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z"/></svg>
				  Danger Danger !
				</ng-template>
				<button class="btn btn-danger" (click)="showDanger(dangerTpl)" ngbTooltip="Will disappear in 15s">Danger toast</button>&nbsp;

				<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
			</div>
		</div>
	</div>
</div>
<!-- End Row -->