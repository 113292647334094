<div class="email-container px-3">
    <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-2 bg-white border-right">

            <h2 class="mb-0 pt-4 text-center font-weight-bold"> Mailbox <a
                    class="text-dark float-right custom-control-button" (click)="openClleft()"
                    href="javascript:void(0)"><i class="ti-menu"></i></a> </h2>
        </div>
        <div class="col-sm-9 col-md-9 col-lg-9 col-xl-10 bg-white">
            <div class="pb-3 pt-3 d-flex align-items-center">
                <div class="b-btn mr-1" [style.display]="(id || !type) ? 'inline-block' : 'none'" data-toggle="tooltip"
                    data-placement="top" data-animation="false" title="Back to {{type}}">
                    <button (click)="getBack()" type="button" class="btn btn-dark btn-sm">
                        <i class="fa fa-arrow-left"></i>
                    </button>
                </div>

                <div class="b-btn mr-1" *ngIf="id" data-toggle="tooltip" data-placement="top" data-animation="false"
                    title="Delete">
                    <button (click)="trash()" type="button" class="btn btn-dark btn-sm">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>

                <div class="b-btn mr-1"
                    [style.display]="(router.url == '/apps/email/mail-compose' || id) ? 'none' : 'inline-block'"
                    data-toggle="tooltip" data-placement="top" data-animation="false" title="Refresh">
                    <button type="button" class="btn btn-dark btn-sm"><span class="fas fa-sync-alt"></span></button>
                </div>

                <div ngbDropdown class="btn-group"
                    [style.display]="(router.url == '/apps/email/mail-compose') ? 'none' : 'block'">
                    <button class="btn btn-warning btn-sm" type="button" ngbDropdownToggle
                        aria-controls="dropdown-basic">
                        More
                    </button>
                    <div class="" id="dropdown-basic" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="setAsRead()">Mark as read</a>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="setAsUnRead()">Mark as unread</a>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="deleteCheckedMail()">Delete</a>
                    </div>
                </div>

                <div class="ml-auto">
                    <span class="mr-1 text-dark"><b>1</b>–<b>50</b> of <b>50</b></span>
                    <div class="btn-group btn-group-sm">
                        <button type="button" class="btn btn-dark">
                            <span class="fa fa-chevron-left"></span>
                        </button>
                        <button type="button" class="btn btn-dark">
                            <span class="fa fa-chevron-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-lg-3 col-xl-2 bg-white border-right">
            <div class="inbox-panel p-2 bg-white" [ngClass]="status ? 'showlpanel' : ''">

                <a [routerLink]="['mail-compose']" class="btn btn-danger rounded-pill btn-block mt-3"
                    role="button">COMPOSE</a>

                <div class="mailbox bg-white">
                    <ul class="list-group custom-group message-center" style="height: unset;">
                        <li class="py-2">
                            <h6 class="font-weight-normal text-muted text-uppercase mb-0 mt-3 font-12">Mailbox</h6>
                        </li>
                        <li class="list-group-item p-0">
                            <a [routerLink]="['mail-list/inbox']"
                                class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa-inbox fas font-18 mr-2"></i>
                                Inbox
                                <div class="ml-auto">
                                    <span class="badge badge-info badge-pill">3</span>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item p-0">
                            <a [routerLink]="['mail-list/sent']"
                                class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa-paper-plane fas font-18 mr-2"></i>
                                Sent Mail
                            </a>
                        </li>
                        <li class="list-group-item p-0">
                            <a [routerLink]="['mail-list/drafts']"
                                class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa-box fas font-18 mr-2"></i>
                                Drafts
                                <div class="ml-auto">
                                    <span class="badge badge-info badge-pill">2</span>
                                </div>
                            </a>
                        </li>
                        <li class="list-group-item p-0">
                            <a [routerLink]="['mail-list/trash']"
                                class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa-history fas font-18 mr-2"></i>
                                Trash
                            </a>
                        </li>
                        <li class="py-2">
                            <h6 class="font-weight-normal text-muted text-uppercase mb-0 mt-3 font-12">Filters</h6>
                        </li>
                        <li class="list-group-item p-0">
                            <a [routerLink]="['mail-list/starred']"
                                class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fas fa-star font-18 mr-2"></i>
                                Starred
                            </a>
                        </li>
                        <li class="py-2">
                            <h6 class="font-weight-normal text-muted text-uppercase mb-0 mt-3 font-12">Labels</h6>
                        </li>
                        <li class="list-group-item p-0">
                            <a href="#" class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa fa-circle text-danger font-18 mr-2"></i>
                                Notes
                            </a>
                        </li>
                        <li class="list-group-item p-0">
                            <a href="#" class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa fa-circle text-warning font-18 mr-2"></i>
                                Personal
                            </a>
                        </li>
                        <li class="list-group-item p-0">
                            <a href="#" class="message-item d-flex align-items-center border-0 py-3 font-weight-normal">
                                <i class="fa fa-circle text-info font-18 mr-2"></i>
                                Travel
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-9 col-lg-9 col-xl-10 bg-white">
            <div class="inbox-right-panel">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>


</div>