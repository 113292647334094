import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  DOC_REQ_ALL,
  DOC_REQ_APPROVED,
  DOC_REQ_MANAGERAPPROVED,
  DOC_REQ_PASSED,
  DOC_REQ_PENDING,
  DOC_REQ_REJECTED
} from '../../app.constants';
import {Observable} from 'rxjs';
import {IHrDocumentRequest} from '../model/ihr-document-request';
import {environment} from '../../../environments/environment';
import {HrpHeadersService} from '../service/hrp-headers.service';
import {HrpGenericServiceService} from '../service/hrp-generic-service.service';
import DateUtils from '../../utils/DateUtils';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HrDocumentRequestService {

  constructor(private http: HttpClient,private headers: HrpHeadersService,private hrpGenericService : HrpGenericServiceService) { }

  save(hrDocumentRequest: IHrDocumentRequest): Observable<IHrDocumentRequest> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post<IHrDocumentRequest>(environment.host + '/api/document-request/add', hrDocumentRequest, httpOptions);
  }

  approve(ids: any): Observable<IHrDocumentRequest> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IHrDocumentRequest>(environment.host + `/api/document-request/approve?ids=`+ids, httpOptions);
  }

  managerApprove(id: number): Observable<IHrDocumentRequest> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IHrDocumentRequest>(environment.host + `/api/document-request/manager-approve/${id}`, httpOptions);
  }


  reject(ids: any): Observable<IHrDocumentRequest> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IHrDocumentRequest>(environment.host + `/api/document-request/reject?ids=`+ids, httpOptions);
  }

  getByEmployee(empNo: any): Observable<IHrDocumentRequest[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IHrDocumentRequest[]>(environment.host + '/api/document-request/employee/' + empNo, httpOptions);
  }

  getDocumentNumber(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any>(environment.host + '/api/document-request/generate-document-number', httpOptions);
  }


  downloadDocument(docId: any, docType: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      responseType: 'blob'
    };
    return  this.http.get(environment.host + '/api/document-request/download/' + docId + '/' + docType, {responseType: 'blob', headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})});
  }

  passDocument(createdDocumentRequestId: any , user: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('user', user);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params
    };
    return this.http.get<any>(environment.host + '/api/document-request/pass/'+createdDocumentRequestId, httpOptions);
  }


  private getApprovalStatusbyCode(approvalStatusCode): string {
    switch (approvalStatusCode) {
      case DOC_REQ_ALL :
        return 'all';
      case DOC_REQ_PENDING :
        return 'pending';
      case DOC_REQ_MANAGERAPPROVED:
        return 'manager approved';
      case DOC_REQ_PASSED:
        return 'passed';
      case DOC_REQ_APPROVED :
        return 'approved';
      case DOC_REQ_REJECTED:
        return 'rejected';
    }
  }

  getList(): Observable<any>{
    const Url = `${environment.host + '/api/document-request/all'}`
    return this.http.get<any>(Url, this.headers.getHttpOptions('GET'));
  }

  getDetails(id): Observable<any>{
    const Url = `${environment.host + `/api/document-request/${id}`}`
    return this.http.get<any>(Url, this.headers.getHttpOptions('GET'));
  }

  getHrDocumentRequestVouchers(CompCode, FyId): Observable<any>
  {
    const EndPoint = `${environment.host}/api/hr-document-request/vouchers?compCode=${CompCode}&financialYear=${FyId}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  searchDocumentRequests(status : any , company : any , page : number , size : number) : Observable<any> {
    let approvalStatusName: string = this.getApprovalStatusbyCode(status);
    let Payload : any = {
      'company' :  company,
      'approvalStatus' : approvalStatusName
    }
    const EndPoint = `${environment.host}/api/hr-document-request/search?page=${page}&size=${size}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  sendPendingHrDocumentRequestsReport() {
    var date = new Date();
    date.setMonth(date.getMonth() - 2);
    return this.http.post<any>(environment.host + '/api/hr-document-request/report?from=' + DateUtils.get(date, 'DD-MM-YYYY'), null, this.headers.getHttpOptions('POST'));
  }
}
