<br>
    <div class="col-md-12 shadowdiv">
        <div class="p-5">
            <div class="">
                <h4>Cash Payment-Approval</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <table class="table table-bordered table-responsive">
                        <thead>
                            <th scope="col">Level</th>
                            <th scope="col">Code</th>
                            <th scope="col">Doc. Date</th>
                            <th scope="col">Doc. No.</th>
                            <th scope="col">Approval Remarks</th>
                            <th scope="col">Rejection Remarks</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td><i class="fa fa-download" aria-hidden="true"></i></td>
                                <td><i class="fa fa-check" aria-hidden="true"></i></td>
                                <td><i class="fa fa-times" aria-hidden="true"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-4 mt-2">
                                        Pass on
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-sm-4 mt-2">
                                        Pass By
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>