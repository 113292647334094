import {HttpHeaders} from '@angular/common/http';

export default class Utils {
  static getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
  }
}
