<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Default pagination</h4>
                <h6 class="card-subtitle">This is basic Default pagination</h6>
                <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>

				<div>directionLinks = false</div>
				<ngb-pagination [collectionSize]="70" [(page)]="page" [directionLinks]="false"></ngb-pagination>

				<div>boundaryLinks = true</div>
				<ngb-pagination [collectionSize]="70" [(page)]="page" [boundaryLinks]="true"></ngb-pagination>

				<hr>

				<pre>Current page: {{page}}</pre>
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Advanced pagination</h4>
                <h6 class="card-subtitle">This is Advanced pagination</h6>
                <div>maxSize = 5, rotate = false</div>
                <ngb-pagination [collectionSize]="120" [(page)]="page2" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>

				<div>maxSize = 5, rotate = true</div>
				<ngb-pagination [collectionSize]="120" [(page)]="page2" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>

				<div>maxSize = 5, rotate = true, ellipses = false</div>
				<ngb-pagination [collectionSize]="120" [(page)]="page2" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>

				<hr>

				<pre>Current page: {{page2}}</pre>
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pagination size</h4>
                <h6 class="card-subtitle">This is Pagination size</h6>
                <ngb-pagination [collectionSize]="50" [(page)]="currentPage" size="lg"></ngb-pagination>
				<ngb-pagination [collectionSize]="50" [(page)]="currentPage"></ngb-pagination>
				<ngb-pagination [collectionSize]="50" [(page)]="currentPage" size="sm"></ngb-pagination>
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Pagination Alignment</h4>
                <h6 class="card-subtitle">Change the alignment of pagination components with flexbox utilities.</h6>
                <ngb-pagination class="d-flex justify-content-start" [collectionSize]="70" [(page)]="page"></ngb-pagination>
                <ngb-pagination class="d-flex justify-content-center" [collectionSize]="70" [(page)]="page"></ngb-pagination>
                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="70" [(page)]="page"></ngb-pagination>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Disabled pagination</h4>
                <h6 class="card-subtitle">This is Disabled pagination</h6>
                <p>Pagination control can be disabled:</p>
				<ngb-pagination [collectionSize]="70" [(page)]="disablepage" [disabled]='isDisabled'></ngb-pagination>
				<hr>
				<button class="btn btn-sm btn-outline-primary" (click)="toggleDisabled()">
				  Toggle disabled
				</button>
  			</div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Custom links <span class="badge badge-success text-white">New</span></h4>
                <h6 class="card-subtitle">A pagination with customized links:</h6>
                <ngb-pagination [collectionSize]="70" [(page)]="pagecustom" aria-label="Custom pagination">
                      <ng-template ngbPaginationPrevious>Prev</ng-template>
                      <ng-template ngbPaginationNext>Next</ng-template>
                      <ng-template ngbPaginationNumber let-p>{{ getPageSymbol(p) }}</ng-template>
                </ngb-pagination>
                    <hr>

                    <pre>Current page: {{pagecustom}}</pre>
            </div>
        </div>
    </div>
</div>