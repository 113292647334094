import { Component, OnInit } from '@angular/core';
import {LeaveRequestService} from '../../shared/service/leave-request.service';

@Component({
  selector: 'app-leaveplanning',
  templateUrl: './leaveplanning.component.html',
  styleUrls: ['./leaveplanning.component.css']
})
export class LeaveplanningComponent implements OnInit {

  leavePlanningList: any[];
  leavePlanFilteredList: any[];
  months: any[];

  constructor(
    private leaveRequestService: LeaveRequestService
  ) { }

  ngOnInit(): void {
    this.months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    this.leaveRequestService.findLeaveRequestByDept().subscribe(resData => {
      console.log(resData);
      this.leavePlanningList = resData;
      this.leavePlanFilteredList = [];
      this.leavePlanningList.map(leavePlan => {
        let monthSectionArr = [];
        let randColor = Math.floor(Math.random() * 16777215).toString(16);
        this.months.forEach(monthData => {
          let startDateAll = new Date(leavePlan?.fromDate);
          let startDate = startDateAll.getDate();
          let startMonth = startDateAll.getMonth();
          let startYear = startDateAll.getFullYear();

          let endDateAll = new Date(leavePlan?.toDate);
          let endDate = endDateAll.getDate();
          let endMonth = endDateAll.getMonth();
          let endYear = endDateAll.getFullYear();

          console.log(endMonth);

          let enableVisual = 0;

          if (monthData === startMonth || monthData === endMonth) {
            enableVisual = 1;
          }

          let startAndEndMonth = 0;

          if (startMonth === endMonth) {
            startAndEndMonth = 1;
          }

          let startOrMidOrEndMonth = 0;

          if (monthData === startMonth) {
            startOrMidOrEndMonth = 0;
          } else if (monthData === endMonth) {
            startOrMidOrEndMonth = 2;
          } else if (monthData < startMonth && monthData > endMonth) {
            startOrMidOrEndMonth = 1;
          } else if (monthData < endMonth) {
            startOrMidOrEndMonth = 3;
          } else if (monthData > startMonth) {
            startOrMidOrEndMonth = 3;
          } else {
            startOrMidOrEndMonth = 4;
          }

          let startMonthPercentage = Math.ceil((startDate / this.getDaysInMonth(startMonth, startYear).length) * 100);

          let endMonthPercentage = 0;
          if (startYear === endYear) {
            endMonthPercentage = Math.ceil((endDate / this.getDaysInMonth(endMonth, endYear).length) * 100);
          }

          let startingDateMarginPercentage = 0;
          let middlePercentage = 0;

          if (startAndEndMonth === 1) {
            startingDateMarginPercentage = Math.ceil((startDate / this.getDaysInMonth(startMonth, startYear).length) * 100);
            if (endDate === startDate) {
              middlePercentage = Math.ceil((1 / this.getDaysInMonth(startMonth, startYear).length) * 100);
            } else {
              middlePercentage = Math.ceil(((endDate - startDate) / this.getDaysInMonth(startMonth, startYear).length) * 100);
            }
          }

          monthSectionArr.push({startOrMidOrEndMonth: startOrMidOrEndMonth, startMonthPercentage: startMonthPercentage, endMonthPercentage: endMonthPercentage, startAndEndMonth: startAndEndMonth, startingDateMarginPercentage: startingDateMarginPercentage, middlePercentage: middlePercentage, enableVisual: enableVisual});
        });

        this.leavePlanFilteredList.push({leaveData: leavePlan, monthSectionArr: monthSectionArr, randColor: randColor});

      });

    });
  }

  getDaysInMonth(month, year) {
    let date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  checkLeaveStartEndMid(leavePlan) {
    //console.log(monthData, leavePlan);
    //console.log(leavePlan?.fromDate);
    //console.log();

    let monthSectionArr = [];

   /* let startDateAll = new Date(leavePlan?.fromDate);
    let startDate = startDateAll.getDate();
    let startMonth = startDateAll.getMonth();
    let startYear = startDateAll.getFullYear();

    let startMonthPercentage = Math.ceil((startDate / this.getDaysInMonth(startMonth, startYear).length) * 100);
    console.log(startMonthPercentage);*/

    this.months.forEach(monthData => {

      let startDateAll = new Date(leavePlan?.fromDate);
      let startDate = startDateAll.getDate();
      let startMonth = startDateAll.getMonth();
      let startYear = startDateAll.getFullYear();

      let endDateAll = new Date(leavePlan?.toDate);
      let endDate = endDateAll.getDate();
      let endMonth = endDateAll.getMonth();
      let endYear = endDateAll.getFullYear();

      let startOrMidOrEndMonth = 0;

      if (monthData === startMonth) {
        startOrMidOrEndMonth = 0;
      } else if (monthData === endMonth) {
        startOrMidOrEndMonth = 2;
      } else if (monthData < startMonth && monthData > endMonth) {
        startOrMidOrEndMonth = 1;
      } else if (monthData < endMonth) {
        startOrMidOrEndMonth = 3;
      } else if (monthData > startMonth) {
        startOrMidOrEndMonth = 3;
      } else {
        startOrMidOrEndMonth = 4;
      }

      let startMonthPercentage = Math.ceil((startDate / this.getDaysInMonth(startMonth, startYear).length) * 100);

      let endMonthPercentage = 0;
      if (startYear === endYear) {
        endMonthPercentage = Math.ceil((endDate / this.getDaysInMonth(endMonth, endYear).length) * 100);
      }

      let startAndEndMonth = 0;

      if (startMonth === endMonth) {
        startAndEndMonth = 1;
      }

      console.log(startAndEndMonth);

      monthSectionArr.push({startOrMidOrEndMonth: startOrMidOrEndMonth, startMonthPercentage: startMonthPercentage, endMonthPercentage: endMonthPercentage, startAndEndMonth: startAndEndMonth});
    });

    return monthSectionArr;
  }

}
