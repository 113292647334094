import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {LeaveRequestService} from './leave-request.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import jwt_decode from 'jwt-decode';
import {
  ADMIN_USER_ROLE,
  ERP_CONFIRMATION,
  HR_CORDINATOR_USER_ROLE,
  LINE_MANAGER_USER_ROLE,
  MANAGEMENT_USER_ROLE,
  MGT_TEST_USER_ROLE,
  RECRUITMENT,
  SELF_SERVICE_USER_ROLE
} from 'src/app/app.constants';


type JwtToken = {
  authToken: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  SERVER_API_URL1 = 'api/authenticate';
  LOG_OUT ='api/logout';

  constructor(
    private http: HttpClient,
    private router: Router,
    private LeaveService: LeaveRequestService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  tryGenerateOTP(data): Observable<boolean> {
    return this.http.post(environment.host + '/api/authorize', data).pipe(
        catchError(error => {
          return this.handleErrorOnLogin;
        }),
        map(
            (res) => {
              if (!res) {
                return false;
              }
              return true;
            },
            (err) => {
              return false;
            }
        ),
    );
  }

  login(data): Observable<any> {
    let returnedRes :any;
    return this.http.post(environment.host + '/' + this.SERVER_API_URL1, data).pipe(
      catchError(error => {
       this.spinner.hide();
       this.toastr.error('Error Occurred');
       return  this.handleErrorOnLogin;
      }),
      map(
        (res) => {
          returnedRes = res;
          if(returnedRes?.glUserMastersDetailsDTO?.roles?.length>=1){
          this.onAuthenticationSuccess(res, data.rememberMe, false);
          return res;
          } else{
            this.spinner.hide();
            this.toastr.error('The user doesnot have any roles');
          }
        },
        (err) => {
          console.log('err');
        }
      ),

    );
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['']);

    //  this.http.post(environment.host + '/' + this.LOG_OUT).pipe(
    //   map(
    //     (res) => {
    //       this.onAuthenticationSuccess(res, data.rememberMe);
    //       return res;
    //     },
    //     (err) => {
    //       console.log('err');
    //     }
    //   )
    // );
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  onAuthenticationSuccess(res, rememberMe, reload): void {
    sessionStorage.setItem('token', res.id_token);
    localStorage.setItem('token', res.id_token);
    this.LeaveService.getAllEmployees(res.glUserMastersDetailsDTO.compCode).subscribe(
        data => {
          localStorage.setItem('AllEmployees', JSON.stringify(data));
          if (reload) {
            location.reload();
          }
        }
    );
  }


  getCurrentUserInformations() : any {
    let loginsessiondata = localStorage.getItem('loginsessiondata') ?JSON.parse(localStorage.getItem('loginsessiondata')) : null ;
    return loginsessiondata?.glUserMastersDetailsDTO;
  }
   handleErrorOnLogin(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  getDecodedAccessToken(): any {
    let token = localStorage.getItem('token');
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  getcurrentUserRoles() : string[] {
    return this.getDecodedAccessToken()?.roles.split(',');
  }

  isSelfServiceUser() : boolean {
    return this.getcurrentUserRoles().includes(SELF_SERVICE_USER_ROLE)
  }

  isLinemanagerUser() : boolean {
    return this.getcurrentUserRoles().includes(LINE_MANAGER_USER_ROLE)
  }

  isErp_ConfirmationUser(): boolean {
    return this.getcurrentUserRoles().includes(ERP_CONFIRMATION);
  }

  isHrCoordinatorUser() : boolean {
    return this.getcurrentUserRoles().includes(HR_CORDINATOR_USER_ROLE)
  }

  isAdminUser() : boolean {
    return this.getcurrentUserRoles().includes(ADMIN_USER_ROLE)
  }

  isManagementUser(): boolean {
    return this.getcurrentUserRoles().includes(MANAGEMENT_USER_ROLE);
  }

  isMgtTestUser(): boolean {
    return this.getcurrentUserRoles().includes(MGT_TEST_USER_ROLE);
  }

  isRecruitmentUser(): boolean {
    return this.getcurrentUserRoles().includes(RECRUITMENT);
  }

}
