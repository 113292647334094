import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../shared/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardMAuthGuardService implements CanActivate {

  constructor(public router: Router , private authService: AuthService) { }

  canActivate() {

    if (this.authService.isManagementUser()) {
      return true;
    }
    return false;
  }
}
