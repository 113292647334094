import { Component, OnInit } from '@angular/core';
import {IEmployee} from '../../shared/model/employee';
import {EmployeesService} from '../../shared/service/employees.service';
import {Router} from '@angular/router';

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {

  employees: IEmployee[] = [];
  currentSelectedPage:number = 0;
  totalPages: number = 0;
  pageIndexes: Array<number> = [];
  pageSize: number = 10;
  maxTotalPages: number = 33;
  maxPageExceeded: boolean = false;
  scrollBarHorizontal = (window.innerWidth < 1200);

  page = new Page();

  rows = [];
  columns = [{ prop: 'empNo', name: 'Employee Number' }, { prop: 'name', name: 'Employee Name', width: 200 }, { prop: 'nationnality', name: 'Nationality' }, { prop: 'doj', name: 'Date of join' }, { prop: 'empCode', name: 'Employee Code' }, {name: 'Actions'}];

  constructor(
    private employeeService: EmployeesService,
    private router: Router
  ) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 1200);
    };
  }

  ngOnInit(): void {
    this.loadEmployees(0, this.pageSize);
  }

  setPage(pageInfo) {
    console.log(pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.employeeService.getEmployeePage(this.page.pageNumber, 10).subscribe(pagedData => {
      console.log(pagedData);
      //this.page = pagedData.page;
      //this.rows = pagedData.data;
    });
  }

  loadEmployees(index: number, pageSize: number) {
    this.employeeService.getEmployeePage(index, pageSize).subscribe(data => {
      this.employees = data.content;
      this.rows = data.content;
      if (data.totalPages < this.maxTotalPages) {
        this.totalPages =  this.maxTotalPages;
      } else {
        this.totalPages = this.maxTotalPages;
        this.maxPageExceeded = true;
      }
      this.pageIndexes = Array(this.totalPages).fill(0).map((x, i) => i);
      this.currentSelectedPage = data.number;
    });
  }

  getPaginationWithIndex(index : number){
    this.loadEmployees(index, this.pageSize);
  }

  active(index: number) {
    console.log("current "+ this.currentSelectedPage +" ****  index"+index)
    if(this.currentSelectedPage == index ){
      return {
        active: true
      };
    }
  }

  nextClick(){
    if(this.currentSelectedPage < this.totalPages-1){
      this.loadEmployees(++this.currentSelectedPage, this.pageSize);
    }
  }

  previousClick(){
    if(this.currentSelectedPage > 0){
      this.loadEmployees(--this.currentSelectedPage, this.pageSize);
    }
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10);  //Ignore time
  }
  checkDetails(employee: IEmployee){
    this.router.navigate(['employee-details/' + employee.empNo]);
  }

}
