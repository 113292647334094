import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../environments/environment';
import {throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-list',
  templateUrl: './invoice.component.html'
})
export class InvoiceComponent implements OnInit {

  showMessage = true;
  pdfSrc: any;

  constructor(private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private activatedRoute: ActivatedRoute, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.spinner.show();
    const id = this.activatedRoute.snapshot.queryParams['id'];
    this.downloadPdf(id).subscribe(attachment => {
      const pdfBlob = new Blob([attachment]);
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
      };
      fileReader.readAsArrayBuffer(pdfBlob);
      this.showMessage = false;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.router.navigate(['/authentication/404']);
    });
  }


  downloadPdf(id) {
    this.spinner.show();
    const endPoint = `${environment.host}/api/invoice/targetsa/${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      responseType: 'blob'
    };
    return this.http.get(endPoint, {
      responseType: 'blob',
      headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})
    }).pipe(
        catchError(() => {
          return throwError('Unable to download file !');
        })
    );
  }


}
