import {Component, OnInit, ViewChild} from '@angular/core';
import {UomService} from '../../shared/service/uom.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-uommain',
  templateUrl: './uommain.component.html',
  styleUrls: ['./uommain.component.css']
})
export class UommainComponent implements OnInit {

  rows = [];
  columns = [{ prop: 'code', name: 'Code' }, { prop: 'description', name: 'Description' }, { prop: 'picture', name: 'Format' }, { prop: 'updatedBy', name: 'Updated By' }, {name: 'Actions', width: 50}];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(
    private uomService: UomService
  ) { }

  ngOnInit(): void {
    this.uomService.getAllUom().subscribe(uomList => {
      console.log(uomList);
      this.rows = uomList;
    });
  }

}
