<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Left</h4>
                <h6 class="card-subtitle">Add span with
                    <code>.input-group-text</code> class before
                    <code>&lt;input&gt;</code>
                </h6>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">@</span>
                    </div>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Both Side</h4>
                <h6 class="card-subtitle">Add span with
                    <code>.input-group-text</code> class before &amp; after
                    <code>&lt;input&gt;</code>
                </h6>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                    <div class="input-group-append">
                        <span class="input-group-text">.00</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Right</h4>
                <h6 class="card-subtitle">Add span with
                    <code>.input-group-text</code> class after
                    <code>&lt;input&gt;</code>
                </h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                    <div class="input-group-append">
                        <span class="input-group-text">@</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Left Icon</h4>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="far fa-user"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Both Side Icon</h4>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="far fa-user"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="wi wi-night-cloudy-gusts"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input Addons Right Icon</h4>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="far fa-user"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Addons With Checkbox</h4>
<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Right Checkbox</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-prepend</code> before input field.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <div>
                                    <input type="checkbox" id="checkbox3" value="check">
                                    <label for="checkbox3"></label>
                                </div>
                            </div>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with checkbox">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Checkbox &amp; Button</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-prepend &amp; append</code> before and afetr input field.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <div>
                                    <input type="checkbox" id="checkbox2" value="check">
                                    <label for="checkbox2"></label>
                                </div>
                            </div>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with checkbox">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-info" type="button">Go!</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Left Checkbox</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-append</code> after input field.</h6>
                <form class="m-t-30">
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Text input with checkbox">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <div>
                                    <input type="checkbox" id="checkbox4" value="check">
                                    <label for="checkbox4"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Addons With Radio</h4>
<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Left Radio</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-prepend</code> before input field.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <div>
                                    <input type="radio" id="customRadio5" name="customRadio">
                                    <label for="customRadio5"></label>
                                </div>
                            </div>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with radio button">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Radio &amp; Button</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-prepend &amp; append</code> before and afetr input field.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-info" type="button">Go!</button>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with radio button">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <div>
                                    <input type="radio" id="customRadio6" name="customRadio">
                                    <label for="customRadio6"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Right Radio</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-append</code> after input field.</h6>
                <form class="m-t-30">
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Text input with radio button">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <div>
                                    <input type="radio" id="customRadio7" name="customRadio">
                                    <label for="customRadio7"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Addons With Buttons</h4>
<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Color Button Left</h4>
                <h6 class="card-subtitle">To use add
                    <code>button</code> in the prepend div.</h6>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-info" type="button">Go!</button>
                        </div>
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Color Buttons Both</h4>
                <h6 class="card-subtitle">To use add
                    <code>button</code> in the prepend and append div.</h6>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-danger" type="button">Love It!</button>
                        </div>
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-info" type="button">Hate It!</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Color Button Right</h4>
                <h6 class="card-subtitle">To use add
                    <code>button</code> in the append div.</h6>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-info" type="button">Go!</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With icon Button Left</h4>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-info" type="button">
                                <i class="ti-settings"></i>
                            </button>
                        </div>
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With icon Buttons Both</h4>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-info" type="button">
                                <i class="ti-pencil"></i>
                            </button>
                        </div>
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-success" type="button">
                                <i class="ti-search"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With icon Button Right</h4>
                <form class="m-t-20">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-danger" type="button">
                                <i class="ti-heart"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Multiple Addons</h4>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Left Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-text</code> class in the input-group-prepend class.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                            <span class="input-group-text">0.00</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Right Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>input-group-text</code> class in the input-group-append class.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                        <div class="input-group-append">
                            <span class="input-group-text">$</span>
                            <span class="input-group-text">0.00</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Multiple Inputs with Addons</h4>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input With Right Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>form-control</code> two times.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">First and last name</span>
                        </div>
                        <input type="text" class="form-control">
                        <input type="text" class="form-control">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Input With Left Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>form-control</code> two times.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control">
                        <input type="text" class="form-control">
                        <div class="input-group-append">
                            <span class="input-group-text">First and last name</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Button Addons</h4>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button With Left Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>button</code> in the prepend class.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                        </div>
                        <input type="text" class="form-control" placeholder="Left Side" aria-label="" aria-describedby="basic-addon1">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Button With Right Side</h4>
                <h6 class="card-subtitle">To use add
                    <code>button</code> in the append class.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Right Side" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Multiple Buttons With Left Side</h4>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                        </div>
                        <input type="text" class="form-control" placeholder="Left Side" aria-label="" aria-describedby="basic-addon1">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Multiple Buttons With Left Side</h4>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Right Side" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                            <button class="btn mr-0 btn-outline-secondary" type="button">Button</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<h4 class="card-title m-t-40">Addons With Dropdown Buttons</h4>
<div class="row">
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Left Side Dropdown</h4>
                <h6 class="card-subtitle">To use add <code>dropdown</code> in the prepend class div.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
							<div ngbDropdown>
	                            <button class="btn mr-0 btn-outline-secondary dropdown-toggle" type="button" ngbDropdownToggle>Dropdown</button>
	                            <div class="dropdown-menu" ngbDropdownMenu>
	                                <a class="dropdown-item" href="#" ngbDropdownItem>Action</a>
	                                <a class="dropdown-item" href="#" ngbDropdownItem>Another action</a>
	                                <a class="dropdown-item" href="#" ngbDropdownItem>Something else here</a>
	                                <div role="separator" class="dropdown-divider"></div>
	                                <a class="dropdown-item" href="#" ngbDropdownItem>Separated link</a>
	                            </div>
							</div>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with dropdown button">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Right Side Dropdown</h4>
                <h6 class="card-subtitle">To use add
                    <code>dropdown</code> in the append class div.</h6>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Text input with dropdown button">
                        <div class="input-group-append" ngbDropdown>
                            <button class="btn mr-0 btn-outline-secondary dropdown-toggle" type="button" ngbDropdownToggle>Dropdown</button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" ngbDropdownItem href="#">Action</a>
                                <a class="dropdown-item" ngbDropdownItem href="#">Another action</a>
                                <a class="dropdown-item" ngbDropdownItem href="#">Something else here</a>
                                <div role="separator" class="dropdown-divider"></div>
                                <a class="dropdown-item" ngbDropdownItem href="#">Separated link</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Left Side Dropdown Segments</h4>
                <form class="m-t-20">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <button type="button" class="btn mr-0 btn-outline-secondary">Action</button>
							<div class="btn-group" role="group" ngbDropdown>
	                            <button type="button" class="btn mr-0 btn-outline-secondary dropdown-toggle dropdown-toggle-split rounded-0 border-left-0" ngbDropdownToggle>
	                                <span class="sr-only">Toggle Dropdown</span>
	                            </button>
	                            <div class="dropdown-menu" ngbDropdownMenu>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Action</a>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Another action</a>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Something else here</a>
	                                <div role="separator" class="dropdown-divider"></div>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Separated link</a>
	                            </div>
							</div>
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with segmented dropdown button">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Addons With Right Side Dropdown Segments</h4>
                <form class="m-t-20">
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Text input with segmented dropdown button">
                        <div class="input-group-append">
                            <button type="button" class="btn mr-0 btn-outline-secondary">Action</button>
                            <div class="btn-group" role="group" ngbDropdown>
	                            <button type="button" class="btn mr-0 btn-outline-secondary dropdown-toggle dropdown-toggle-split rounded-0 border-left-0" ngbDropdownToggle>
	                                <span class="sr-only">Toggle Dropdown</span>
	                            </button>
	                            <div class="dropdown-menu" ngbDropdownMenu>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Action</a>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Another action</a>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Something else here</a>
	                                <div role="separator" class="dropdown-divider"></div>
	                                <a class="dropdown-item" ngbDropdownItem href="#">Separated link</a>
	                            </div>
							</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>