import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiService} from '../../shared/service/api.service';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';

import {ActivatedRoute, Router} from '@angular/router';
import {LEAVE_REQ_ALL} from 'src/app/app.constants';
import DateUtils from '../../utils/DateUtils';

interface SessionObject {
  CompanyCode?: string;
  BranchCode?: string;
  Token?: string;
  FinancialCode?: string;
  FYearID?: string;
  DocumentCode?: string;
  ModuleCode?: string;
  UserName?: string;
}

const STATUS = {
  REJECTED: 'REJECTED',
  CONFIRMED: 'CONFIRMED',
  MANAGER_APPROVED: 'MANAGER APPROVED',
  PASSED: 'PASSED',
  PENDING: 'PENDING',
};

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  Session: SessionObject = {
    BranchCode: '',
    FinancialCode: '',
    CompanyCode: '',
    ModuleCode: '',
    DocumentCode: 'LR',
    FYearID: ''
  };
  pages: { selected: boolean; pagenumber: number }[] = [];
  selectedPage = 1;
  pagesTd;
  searchResultLeavePages: any;
  searchResultLeavePagesArray: any[] = [];
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;
  columns = [
    {prop: 'employeeName', name: 'Employee Name'},
    {prop: 'employeeWsDTO.profession', name: 'employeeWsDTO Profession'},
    {prop: 'docNo', name: 'Document Number'},
    {prop: 'fromDate', name: 'From Date'},
    {prop: 'toDate', name: 'To Date'},
    {prop: 'status', name: 'Status'},
    {prop: 'leaveBalance', name: 'Leave Balance'},
    {name: 'Action', width: 50}
  ];
  LeavesList: any = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  initialFlag = true;
  TotalPages: number;

  constructor(
      private formBuilder: FormBuilder,
      private leaveRequestService: LeaveRequestService,
      private apiService: ApiService,
      private cd: ChangeDetectorRef,
      private modalService: NgbModal,
      private spinner: NgxSpinnerService,
      private _toastr: ToastrService,
      private router: Router,
      private activatedRoute: ActivatedRoute
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 1200);
    };
  }

  ngOnInit(): void {

    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = 'JV';
    this.Session.ModuleCode = 'PP';
    this.Session.BranchCode = '01';
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode;
    this.Session.FYearID = SessionObject.glUserMastersDetailsDTO.currentFinancialYear;

    // this.getListOfLeaves()
    this.getListOfLeavesByPages(0, 10);
    this.selectedPage = 1;
  }

  NewLeave() {
    this.router.navigate(['/leaves/leaverequest'], {queryParams: {id: 'null', src: 'list'}});
  }

  ListDetails(RequestID) {
    this.router.navigate(['/leaves/leaverequest'], {queryParams: {id: RequestID, src: 'list'}});
  }


  parseDate(date: string): string {
    return date && DateUtils.get(date, 'DD-MM-YYYY');
  }

  getStatus(data: any): string {
    if (data.rejected === 'Y') {
      return STATUS.REJECTED;
    }
    if (data.confirmed === 'Y') {
      return STATUS.CONFIRMED;
    }
    if (data.managerApproved) {
      return STATUS.MANAGER_APPROVED;
    }
    if (data.passed === 'Y') {
      return STATUS.PASSED;
    }
    return STATUS.PENDING;
  }

  getListOfLeavesByPages(page, size) {
    this.spinner.show();
    this.leaveRequestService.searchLeaveRequests(this.Session.UserName, this.Session.CompanyCode, LEAVE_REQ_ALL, this.Session.CompanyCode, null, page, size).subscribe(searchResult => {
      this.spinner.hide();
      this.LeavesList = searchResult.content;
      this.searchResultLeavePages = searchResult.totalPages;
      this.pageSize = searchResult.size;
      this.pageIndex = searchResult.number;
      this.totalElements = searchResult.totalElements;
      if (this.initialFlag) {
        for (let i = 1; i <= searchResult.totalPages; i++) {
          this.pages.push({selected: i == 1 ? true : false, pagenumber: i});
        }
        this.initialFlag = false;
        this.TotalPages = searchResult.totalPages;
      }


    });
  }

  displayPagination(PageNumber) {
    if (this.selectedPage <= 3) {
      return (PageNumber <= 5);
    }

    if (this.selectedPage > 3 && this.selectedPage < this.TotalPages - 2) {
      return (PageNumber >= this.selectedPage - 2 && PageNumber <= this.selectedPage + 2);
    }

    if (this.selectedPage >= this.TotalPages - 2) {
      return (PageNumber >= this.TotalPages - 4);
    }
  }

  searchLeavePaginated(event: any) {

    this.spinner.show();
    this.leaveRequestService.searchLeaveRequests(this.Session.UserName, this.Session.CompanyCode, LEAVE_REQ_ALL, this.Session.CompanyCode, null, event.pageIndex, event.pageSize).subscribe(searchResult => {
      this.searchResultLeavePages = searchResult.totalPages;
      this.LeavesList = searchResult.content;
      this.pageSize = searchResult.size;
      this.pageIndex = searchResult.number;
      this.totalElements = searchResult.totalElements;
      this.spinner.hide();
    }, errorSection => {
      this.spinner.hide();
    });
  }

  selectPage(e) {
    this.getListOfLeavesByPages((this.selectedPage - 1), 10);
    this.pages.forEach(x => x.selected = x.pagenumber == this.selectedPage ? true : false);
  }

}
