import { Component, OnInit } from '@angular/core';
import {IEmployee} from '../../shared/model/employee';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EmployeesService} from '../../shared/service/employees.service';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {

  employee: IEmployee;
  employeeForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeService: EmployeesService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.employeeForm = this.formBuilder.group({
      id: ['', Validators.required],
      pmlId: ['', Validators.required],
      empNo: ['', Validators.required],
      name: ['', Validators.required],
      nationality: ['', null],
      position: ['', null],
      dob: ['', null],
      gratuityProv: ['', null],
      gratuityDays: ['', null],
      levBalDays: ['', null],
      levBalprov: ['', null],
      doj: ['', null],
      compCode: ['', null],
      ppEmpTypeId: ['', null]
    });
    this.loadEmployee();
  }

  loadEmployee() {
    let empNo = this.route.snapshot.params['empNo'];
    this.employeeService.getByEmpNo(empNo).subscribe(data =>{
      this.employee = data;
      console.log(this.employee);
      this.employeeForm = this.createEmployeeForm(this.employee);
    })
  }

  searchEmployee() {
    console.log(this.employeeForm.controls['empNo'].value);
    let empno = this.employeeForm.controls['empNo'].value;
    this.employeeService.getByEmpNo(empno).subscribe(data => {
      this.employeeForm = this.createEmployeeForm(data);
    });
  }

  createEmployeeForm(value: IEmployee) {

    return this.formBuilder.group({
      id: [value ? value.employeeIdentity.id : null, Validators.required],
      pmlId: [value ? value.employeeIdentity.pmlId : null, Validators.required],
      empNo: [value ? value.empNo : null, Validators.required],
      name: [value ? value.name : null, Validators.required],
      nationality: [value ? value.nationality.description : null],
      position: [value ? value.profession.description : null],
      dob: [value ? this.fromJsonDate(value.dob) : null],
      gratuityProv: [value ? value.gratuityProv ? value.gratuityProv : 0 : 0],
      gratuityDays: [value ? value.gratuityDays ? value.gratuityDays : 0 : 0],
      levBalDays: [value ? value.levBalDays ? value.levBalDays : 0 : 0],
      levBalprov: [value ? value.levBalprov ? value.levBalprov : 0 : 0],
      doj: [value ? this.fromJsonDate(value.doj) : null],
      compCode: [value ? value.compCode : null],
      ppEmpTypeId: [value ? value.employeeType.description : null]
      /*
      doj: [value ? this.fromJsonDate(value.doj) : null],
      dob: [value ? this.fromJsonDate(value.dob) : null],
      nationality: [value ? value.nationality : null],
      compCode: [value ? value.compCode : null],
      matnPmlId: [value ? value.matnPmlId : null ],
      matnMatId: [value ? value.matnMatId : null],
      ppEmpTypeId: [value ? value.ppEmpTypeId : null],
      levBalDays: [value ? value.levBalDays ? value.levBalDays : 0 : 0],
      levBalprov: [value ? value.levBalprov ? value.levBalprov : 0 : 0],
      gratuityProv: [value ? value.gratuityProv ? value.gratuityProv : 0 : 0],
      gratuityDays: [value ? value.gratuityDays ? value.gratuityDays : 0 : 0],
      nationnality: [value ? value.nationnality.description : null],
      position: [value ? value.profession.description : null, ]*/
    });
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10);
  }

}
