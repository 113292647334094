import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LINE_MANAGER_USER_ROLE, SELF_SERVICE_USER_ROLE } from 'src/app/app.constants';
import { AuthService } from 'src/app/shared/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LeaveAuthGuardService implements CanActivate {

  constructor(public router: Router , private authService : AuthService) { }

  canActivate(){

    if(this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()){
      return true;
    }
    if (this.authService.isSelfServiceUser()){
      // this.router.navigate(['authentication/404']);
       return false;
    }
    return true;
  }
}
