import { Component, AfterViewInit } from '@angular/core';

@Component({
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.css']
})
export class Dashboard2Component implements AfterViewInit {
  constructor() {}

  ngAfterViewInit() {}
}
