import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DocumentTypeRouting} from './document-types.routing';
import { ManagedocsComponent } from './managedocs/managedocs.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(DocumentTypeRouting),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [
  ManagedocsComponent]
})
export class DocumentTypesModule {}
