import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { DataTableDirective } from 'angular-datatables';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import {AdminService} from '../../shared/service/admin.service'
import Swal from 'sweetalert2';
interface EmployeeNode {
  name: string;
  children?: EmployeeNode[];
}

const TREE_DATA: EmployeeNode[] = [
  {
    name: 'ABBASS',
    children: [
      {name: 'SREERAJ'},
      {name: 'Brahim'},
      {name: 'Tashfeen'},
    ]
  }, {
    name: 'SREERAJ',
    children: [
      {name: 'SREERAJ'},
      {name: 'Brahim'},
      {name: 'Tashfeen'},
    ]
  },
];
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface Manager {
  id: number;
  empNo: number;
  pmlId: number;
  name: string;
  doj: string;
  dob: string;
  compCode: string;
  companyName: string;
  departement: Departement;
  profession: string;
  nationality: string;
  matnPmlId?: any;
  matnMatId?: any;
  ppEmpTypeId?: any;
  levBalDays: number;
  levBalprov: number;
  gratuityProv: number;
  gratuityDays: number;
  grade?: any;
  gender: string;
  bankAcno?: any;
  bankName: string;
  position?: any;
  costCenter?: any;
  accumAbsentDays: number;
}

interface Departement {
  code: string;
  company?: any;
  description: string;
}

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.css']
})
export class MappingComponent implements OnInit {

  ManagersList: Manager[] = []
  SelectedManager: Manager;
  EmployeeList: String[] = [];
  PopUpTableData: any[] = [];

  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  @ViewChild("configModal") public ConfigModal: ModalDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  flag = false;

  constructor(public AdminService: AdminService, private spinner: NgxSpinnerService,) { 
    this.AdminService.getAllManagerEmployees().subscribe(data =>{
      this.dataSource.data = data;
    });
   }
  private _transformer = (node: EmployeeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  ngOnInit(): void {
    this.spinner.show()
    this.AdminService.getManagers().subscribe(
      (data: Manager[])=>
      {
        this.ManagersList = data;
        this.SelectedManager = data[0];
        this.selectManager(this.SelectedManager.empNo)
        this.spinner.hide();
      },
      error=> {
        console.log('Error');
        this.spinner.hide();
      }
    )

  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  popUpTableValueSelected(row) {
    row.Selected = true;
    this.EmployeeList.push(row.Name);
    let Payload = {
      "managerEmpNo" : this.SelectedManager.empNo,
      "empNos" : [ ...row.RowObject.empNo
      ]
  }
    this.spinner.show
    this.AdminService.assignEmployeesToLineManager(Payload).subscribe(
      data=> {
        this.spinner.hide()
        Swal.fire('Manager Assigned')
        this.ConfigModal.hide()
        
      },
      error=> 
      {
        this.spinner.hide()
        Swal.fire('Error in API')
        this.ConfigModal.hide()
      }
      
      
    )
  }

 
  selectManager(empNo)
  {
    this.SelectedManager =this.ManagersList.filter(x=>x.empNo == empNo)[0];
    this.spinner.show();
    this.AdminService.getManagerEmployees(empNo).subscribe(
      data => {
        this.EmployeeList = data.employees;
        this.spinner.hide();
      }
      )
      error=> {
        console.log('Error');
        this.spinner.hide();
      }
      
  }

  selectEmployee() {
    this.PopUpTableData = [];
    let Data = localStorage.getItem('AllEmployees')? JSON.parse(localStorage.getItem('AllEmployees')) : null
    if(Data){
      Data.forEach((element:any) => {
        this.PopUpTableData.push({
          Code : element.id,
          Name : element.name,
          Selected : this.EmployeeList.includes(element.name)? true : false,
          RowObject: element  
        })
      })};
  
    if(!this.flag)
      this.dtTrigger.next();
    else
      this.rerender()

    this.flag = true
    this.ConfigModal.show();
   
  }

}
