import {EmployeeReportDisplayColumn} from './shared/model/employee-report-display-column';

export const DOC_REQ_APPROVED = '1';
export const DOC_REQ_REJECTED = '2';
export const DOC_REQ_MANAGERAPPROVED = '3';
export const DOC_REQ_PASSED = '4';
export const DOC_REQ_PENDING = '5';
export const DOC_REQ_ALL = '6';


export const LEAVE_REQ_APPROVED = '1';
export const LEAVE_REQ_REJECTED = '2';
export const LEAVE_REQ_MANAGERAPPROVED = '3';
export const LEAVE_REQ_PASSED = '4';
export const LEAVE_REQ_PENDING = '5';
export const LEAVE_REQ_ALL = '6';

export const SELF_SERVICE_USER_ROLE = 'SELF_SERVICE_USER';
export const LINE_MANAGER_USER_ROLE = 'LINE_MANAGER';
export const ADMIN_USER_ROLE = 'ADMIN';
export const HR_CORDINATOR_USER_ROLE = 'HR_CORDINATOR';
export const MANAGEMENT_USER_ROLE = 'MANAGEMENT';
export const MGT_TEST_USER_ROLE = 'MGT_TEST';
export const ERP_CONFIRMATION = 'ERP_CONFIRMATION';
export const RECRUITMENT = 'RECRUITMENT';

export const EMPLOYEE_REPORT_DISPLAY_COLUMNS : EmployeeReportDisplayColumn[] = [
    {code : 'SHOW_DOB_COLUMN' , label : 'Date Of Birth'},
    {code : 'SHOW_DOJ_COLUMN' , label : 'Date Of Join'},
    {code : 'SHOW_PASSEPORT_COLUMN' , label : 'Passeport'},
    {code : 'SHOW_COMPANY_COLUMN' , label : 'Company'},
    {code : 'SHOW_NATIONALITY_COLUMN' , label : 'Nationality'},
    {code : 'SHOW_DEPARTMENT_COLUMN' , label : 'Department'},
    {code : 'SHOW_PROFESSION_COLUMN' , label : 'Profession'},
    {code : 'SHOW_STATUS_COLUMN' , label : 'Status'},
    {code : 'SHOW_EMP_TYPE_COLUMN' , label : 'Employee Type'},
    {code : 'SHOW_LAST_TICKET_DATE_COLUMN' , label : 'Last Ticket Date'},
    {code : 'SHOW_ACCU_UNPAID_DAYS_COLUMN' , label : ' Accu Unpaid Days'},
    {code : 'SHOW_ACCU_ABSENT_DAYS_COLUMN' , label : ' Accu Absent Days'},
    {code : 'SHOW_UNPAID_DAYS_COLUMN' , label : 'Unpaid Days'},
    {code : 'SHOW_ABSENT_DAYS_COLUMN' , label : 'Absent Days'},
    {code : 'SHOW_LAST_PROV_CALC_DATE_COLUMN' , label : 'Last Proc Calc Date'},
];
