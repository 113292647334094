<br>

<div *ngIf="!show" class="col mx-auto w-75 p-3 shadowdiv">

    <div id="JVSelector">
        <br>
        <div class="row">

            <div class="col-sm">
                <h4> Select Period and Voucher </h4>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-3"> <label> Period </label> </div>

            <div class="col-sm">
                <select class=" form-control " (change)="setDateFromPeriod($event)">
                    <option *ngFor="let period of PeriodList" [value]="period.Period"> {{ period.Period }}</option>
                </select>
            </div>
 
            <div class="col-sm">
                <input [value]="SelectedPeriod.FromDate? SelectedPeriod.FromDate: '' " class="form-control" disabled>
            </div>

            <div class="col-sm">
                <input [value]="SelectedPeriod.ToDate? SelectedPeriod.ToDate: '' " class="form-control" disabled>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-3"> <label> Stores </label> </div>

            <div class="col">
                <select class="form-control" (change)="setStoreCode($event)">
                    <option *ngFor="let store of StoreList;let i = index" [selected]="i==0" [value]="store.storeMasterIdentity.code">
                        {{ store.description}}
                    </option>
                    <!-- <option value=""></option> -->
                </select>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-3"> <label> Voucher Code </label> </div>

            <div class="col">
                <select [disabled]="VourcherList.length==0" class="form-control" (change)="setVoucherCode($event)">
                    <option *ngFor="let voucher of VourcherList;let i = index" [selected]="i==0" [value]="voucher.Code">
                        {{ voucher.Code +' - '+ voucher.Description}}
                    </option>
                    <!-- <option value=""></option> -->
                </select>
            </div>
        </div>

        <br>

        <div class="row">
            <div class="col">
                <button class="btn btn-primary mr-3" (click)="showMaterialVoucher()"> OK </button>
                <button class="btn btn-primary"> SAVE </button>
            </div>


        </div>

    </div>

</div>

<div *ngIf="show" class="col-md-12 p-3 shadowdiv">
    <div id="JVDetailed">
        <h4>MATERIAL REQUEST - CENTRAL ST.</h4>
        <hr>

        <div class="row">
            <div class="col-sm-1 mt-2">
                <label>Document</label>
            </div>
            <div class="col-sm-1">
                <input class="form-control mr-2" type="text" [value]="SelectedVoucher" disabled>
            </div>
            <div class="col-sm-3">
                <input class=" form-control " type="text" [value]="DocumentCode?DocumentCode:''" disabled>
            </div>

            <div class="col-sm-2"></div>

            <!-- ///////// -->
            <div class="col-sm-1">
                <input type="checkbox" class="mr-1" disabled>
                <label>Rejected</label>
            </div>
            <!-- ///////// -->
            <div class="col-sm-1">
                <input type="checkbox" class="mr-1" disabled>
                <label>Cancelled</label>
            </div>
            <!-- ///////// -->
            <div class="col-sm-1">
                <input type="checkbox" class="mr-1" disabled>
                <label>Confirmed</label>
            </div>

            <div class="col-sm-2" style="margin-top: -10px;">
                {{enablepassBtn}}
                <button class="btn btn-success"  (click)="passTransaction()" style="width: 50%;padding: 5% 3%;float: right;"> PASS </button>
                <button class="btn btn-primary" (click)="SaveTransaction()" style="width: 50%;padding: 5% 3%;float: right;"> SAVE </button>
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-2">
                <label>Job</label>
            </div>
            <div class="col-sm-1">
                <input (click)="OpenSelectModal('JB', '0')" class="form-control mr-2" type="text" [value]="DataObject.JB['0']?.Code ? DataObject.JB['0']?.Code : '' ">
            </div>
            <div class="col-sm-3">
                <input class=" form-control " type="text" [value]="DataObject.JB['0']?.Name ? DataObject.JB['0']?.Name : ''" disabled>
            </div>

            <div class="col-sm-1"></div>

            <!-- ////////// -->
            <div class=" col-sm-1 mt-2">
                <label>Date</label>
            </div>
            <div class="col-sm-2">
                <input class="form-control" type="date" (change)="dateChange($event)" [value]="selectedDate">
            </div>
 
            <!-- ////////// -->
            <div class="col-sm mt-2 ml-3">
                <input type="checkbox" class="mr-1 ml-2" disabled>
                <label>Passed</label>
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-2">
                <label>Costcenter</label>
            </div>
            <div class="col-sm-1">
                <input class="form-control mr-2" type="text" [value]="DataObject.JB['0'] ? DataObject.JB['0']?.RowObject.costCentreDTO.code : '' " disabled>
            </div>
            <div class="col-sm-3">
                <input class=" form-control " type="text" [value]="DataObject.JB['0'] ? DataObject.JB['0']?.RowObject.costCentreDTO.description : '' " disabled>
            </div>
            
            <div class="col-sm-1"></div>

            <!-- ////////// -->
            <div class=" col-sm-1 mt-2">
                <label>Required On</label>
            </div>
            <div class="col-sm-2">
                <input class="form-control" type="date" [value]="">
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-2">
                <label>Reference</label>
            </div>
            <div class=" col-sm-4">
                <input [(ngModel)]="St_Reference" class="form-control">
            </div>
            
            <div class="col-sm-1"></div>

            <!-- ////////// -->
            <div class=" col-sm-1 mt-2">
                <label>Created On</label>
            </div>
            <div class="col-sm-2">
                <input class="form-control" type="text" [value]="CurrentDate" disabled>
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-1">
                <label>Requested By</label>
            </div>
            <div class=" col-sm-4">
                <input [(ngModel)]="St_RequestedBy" class="form-control">
            </div>
            
            <div class="col-sm-1"></div>

            <!-- ////////// -->
            <div class=" col-sm-1 mt-2">
                <label>Created By</label>
            </div>
            <div class="col-sm-2">
                <input class="form-control" type="text" [value]="Session.UserName" disabled>
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-2">
                <label>Remarks</label>
            </div>
            <div class=" col-sm-4">
                <input [(ngModel)]="St_Remarks" class=" form-control">
            </div>
        </div>

        <div class=" row mt-2">
            <div class=" col-sm-1 mt-2">
                <label>Store</label>
            </div>
            <div class=" col-sm-1">
                <input class=" form-control" [value]="getStoreValues().storeMasterIdentity.code" disabled>
            </div>
            <div class=" col-sm-3">
                <input class=" form-control" [value]="getStoreValues().description" disabled>
            </div>
            <div class="col-sm-5"></div>
            <div class=" col-sm-2">
                <button class="btn btn-primary" style="padding: 5% 0;width: 100%;">Item Enquiry</button>
            </div>
        </div>

        <br>
        <hr>
        <br>

        <div class="row">
            <div class="col" style="overflow-x: scroll;">
                <table class="table table-bordered table-hover">
                    <thead >
                        <th>Item Code</th>
                        <th>Description</th>
                        <th>Cost Code</th>
                        <th>UOM</th>
                        <th>Quantity Required</th>
                        <th>Qty Ordered Till Date</th>
                       
                    </thead >
                    <tbody>
                        <tr *ngFor='let row of BodyRows'>
                            <td (click)="OpenSelectModal('STK',row)"
                                [innerHTML]="DataObject['STK'][row]? DataObject['STK'][row].Code: ''"
                                > 
                            </td>

                            <td [innerHTML]="DataObject['STK'][row]? DataObject['STK'][row].Name: ''"
                                >
                            </td> 
                            <td></td>
                            <td [innerHTML]="DataObject['STK'][row]? DataObject['STK'][row].RowObject.itemUom: ''"
                                >
                            </td> 
                            <td> <input [(ngModel)]="DataObject['QTY'][row]" class="form-control" type="number"> </td>
                            <td></td>
                        </tr>
                        <!-- <tr *ngFor="let row of BodyRows">
                            <td>{{DataObject['GL'][row]?row:''}}</td>
                            <td (click)="OpenSelectModal('GL',row)"
                                [innerHTML]="DataObject['GL'][row]? DataObject['GL'][row].Code: ''"> </td>

                            <td (click)="OpenSelectModal('SL',row)"
                                [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Code: ''"> </td>
                            <td [innerHTML]="DataObject['SL'][row]? DataObject['SL'][row].Name: ''"> </td>
                            <td (click)="OpenSelectModal('CCD',row)"
                                [innerHTML]="DataObject['CCD'][row]? DataObject['CCD'][row].id: ''"> </td>
                            <td (click)="OpenSelectModal('CAT',row)"
                                [innerHTML]="DataObject['CAT'][row]? DataObject['CAT'][row].Name: ''"> </td>
                            <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Debit'][row]"
                                    [disabled]="!!DataObject['Credit'][row]"> </td>
                            <td> <input type="number" class=" form-control " [(ngModel)]="DataObject['Credit'][row]"
                                    [disabled]="!!DataObject['Debit'][row]"> </td>
                            <td (click)="OpenSelectModal('CCC',row)"
                                [innerHTML]="DataObject['CCC'][row]? DataObject['CCC'][row].Name: ''">
                            </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="date" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                            <td> <input type="date" class="form-control"  > </td>
                            <td> <input type="text" class="form-control"  > </td>
                        </tr> -->

                        <!-- <tr>
                            <td colspan="5"></td>
                            <th class="text-right"> Total</th>
                            <td> <b>{{DataObject.Debit? fetchTotal('Debit'):''}} </b> </td>
                            <td> <b>{{DataObject.Credit? fetchTotal('Credit'):''}} </b> </td>
                            <th colspan="9" class="text-left"> Diff. Amount: <b>{{SumCredit-SumDebit}}</b></th>
                        </tr> -->
                    </tbody>
                </table>
            </div>

        </div>

        <br>
        <br>
        <hr>
        <div class="row">
            <div class="col-sm-6">
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Sub Group</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>

                    <div class="col-sm-3 mt-2">
                        <label>Store QOH</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-1">
                        <label>Total MRs Qty On S.Grp</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>

                    <div class="col-sm-3 mt-1">
                        <label>S.Group Budget</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>UOM Desc</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>

                    <div class="col-sm-3 mt-1">
                        <label>Con.Factor</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Remarks</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>Pur Dept.Rem.</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>BOQ</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-3 mt-2">
                        <label>CC Desc</label>
                    </div>
                    <div class="col-sm-9">
                        <input type="text" class="form-control">
                    </div>
                </div>
                
            </div>
            <div class="col-sm-6">
                <div class="row mt-2">
                    <div class="col-sm-2 mt-2">
                        <label>Comp. QOH</label>
                    </div>
                    <div class="col-sm-3">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-1 mt-3">
                        <label>ACTUAL</label>
                    </div>
                    <div class="col-sm-3"></div>
                    <div class="col-sm-1 mt-3">
                        <label>BUDGET</label>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2 mt-2">
                        <label>Material</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2 mt-2">
                        <label>Manpower</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2 mt-2">
                        <label>Equipment</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>    
                    <div class="col-sm-2 mt-2">
                        <label>Subcontractor</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2 mt-2">
                        <label>Expenses</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2 mt-2">
                        <label>Total</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                    <div class="col-sm-4">
                        <input type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div> 
    
    <hr>

    <h4>Attach Documents</h4>

    <input class="form-control" (change)="filesUploaded($event)" type="file" multiple>

    <table class="table">
        
        <tr>
            <th>File Name</th>
            <th>Description</th>
            <th>Created By</th>
            <th>Created On</th>
        </tr>

        <tr *ngFor="let file of FilesList; let i=index">
            <td> {{ file.name}} </td>
            <td> <input class="from-control" [(ngModel)]="FilesList[i].Description" type="text"> </td>
            <td> {{ Session.UserName }} </td>
            <td> {{ file.lastModifiedDate}}
                <button class="btn btn-outline-danger ml-5" (click)="deleteFile(file.name, file.lastModified)"> Delete </button>    
            </td>

        </tr>

    </table>
</div>


<div id="configModal" bsModal #configModal="bs-modal" class="modal fade bs-example-modal-lg text-center" tabindex="-1"
    role="dialog" aria-labelledby="classInfo" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content bg-light-gray w-75 mx-auto" style="background-color: rgb(240, 240, 240)">
            <div class="modal-header text-center">
                <button type="button" (click)="ConfigModal.hide()" class="close" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container table-responsive">
                <div>
                    <table class=" w-50 text-center  " id="GLTable" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" class="compact">
                        <thead class="darkHeaders text-center ">
                            <tr>
                                <th> </th>
                                <th> Code </th>
                                <th> Name </th>
                            </tr>
                        </thead>
                        <tbody class=" text-center">
                            <tr *ngFor="let row of PopUpTableData" [class]="row? row.Selected==true?'reedd':'':''">
                                <td> <input type="radio" (change)="popUpTableValueSelected($event, row)"
                                        [checked]="row.Selected"> </td>
                                <td> {{ row.Code }} </td>
                                <td> {{ row.Name }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>