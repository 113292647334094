export class CandidateSelectionDto {

  id: number;

  candidateID: number;

  compCode: string;

  offeredDesignation: string;

  offeredSalary: string;

  positionAdvertisedDate: Date;

  newspaperSource: string;

  onlineSource: string;

  othersSource: string;

  noCandidateInterviewed: string;

  noShortlistedInterviewed: string;

  interviewee1Id: number;

  interviewee1PmlId: number;

  interviewee1Remarks: string;

  interviewee2Id: number;

  interviewee2PmlId: number;

  interviewee2Remarks: string;

  interviewee3Id: number;

  interviewee3PmlId: number;

  interviewee3Remarks: string;

  createOn: Date;

  updateOn: Date;

  currentUserAllowedToConfirmOrReject: boolean;

  status: string;

  approvalLevel: string;

  rejectedBy: string;

  rejectRemark: string;

  confirmedBy: string[];

  createdById: number;

  createdByPmlId: number;

  recruitmentRequestId: number;

  recruitmentRequestTitle: string;

  recruitmentRequestSalaryGrossPackage: string;

  constructor() {
  }

}
