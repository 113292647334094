import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HrpHeadersService} from './hrp-headers.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {RequestSearchDto} from '../dto/recruitment/request/requestSearchDto';
import {RecruitmentRequestDto} from '../dto/recruitment/request/recruitmentRequestDto';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentRequestService {


  constructor(private http: HttpClient, private headers: HrpHeadersService) {
  }


  search(requestSearchDto: RequestSearchDto, page: number, size: number): Observable<any> {
    const endPoint = `${environment.host}/api/recruitment/request/search?page=${page}&size=${size}`;
    return this.http.post<any>(endPoint, requestSearchDto, this.headers.getHttpOptions('POST')).pipe(
        catchError(() => {
          return throwError('Unable to get recruitment request list !');
        })
    );
  }


  delete(requestNumber) {
    const endPoint = `${environment.host}/api/recruitment/request/delete/${requestNumber}`;
    return this.http.delete<any>(endPoint, this.headers.getHttpOptions('DELETE')).pipe(
        catchError(() => {
          return throwError('Unable to delete recruitment request !');
        })
    );
  }

  save(recruitmentRequestDto: RecruitmentRequestDto, jobDescFile: File) {
    const endPoint = `${environment.host}/api/recruitment/request/save`;
    const formData: FormData = new FormData();
    if (jobDescFile) {
      formData.append('jobDescFile', jobDescFile);
      recruitmentRequestDto.jobDescFileName = jobDescFile.name;
    }
    formData.append('request', JSON.stringify(recruitmentRequestDto));
    return this.http.post<any>(endPoint, formData, this.headers.getHttpOptions('POST', true)).pipe(
        catchError(() => {
          return throwError('Unable to save recruitment request !');
        })
    );
  }

  update(recruitmentRequestDto: RecruitmentRequestDto, jobDescFile: File) {
    const endPoint = `${environment.host}/api/recruitment/request/update`;
    const formData: FormData = new FormData();
    if (jobDescFile) {
      formData.append('jobDescFile', jobDescFile);
      recruitmentRequestDto.jobDescFileName = jobDescFile.name;
    }
    formData.append('request', JSON.stringify(recruitmentRequestDto));
    return this.http.put<any>(endPoint, formData, this.headers.getHttpOptions('PUT', true)).pipe(
        catchError(() => {
          return throwError('Unable to update recruitment request !');
        })
    );
  }

  confirm(requestNumber) {
    const endPoint = `${environment.host}/api/recruitment/request/confirm/${requestNumber}`;
    return this.http.put<any>(endPoint, null, this.headers.getHttpOptions('PUT')).pipe(
        catchError(() => {
          return throwError('Unable to confirm recruitment request !');
        })
    );
  }

  generateDocNumber(compCode) {
    const endPoint = `${environment.host}/api/recruitment/request/generateDocNumber/${compCode}`;
    return this.http.get<any>(endPoint, this.headers.getHttpOptions('GET')).pipe(
        catchError(() => {
          return throwError('Unable to generate recruitment request Doc Number !');
        })
    );
  }

  reject(recruitmentRequestDto: RecruitmentRequestDto) {
    const endPoint = `${environment.host}/api/recruitment/request/reject`;
    return this.http.put<any>(endPoint, recruitmentRequestDto, this.headers.getHttpOptions('PUT', true)).pipe(
        catchError(() => {
          return throwError('Unable to reject recruitment request !');
        })
    );
  }

  pass(requestNumber) {
    const endPoint = `${environment.host}/api/recruitment/request/pass/${requestNumber}`;
    return this.http.put<any>(endPoint, null, this.headers.getHttpOptions('PUT')).pipe(
        catchError(() => {
          return throwError('Unable to pass recruitment request !');
        })
    );
  }

  downloadJobDescFile(referenceNumber) {
    const endPoint = `${environment.host}/api/recruitment/request/download/jobDescFile/${referenceNumber}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      responseType: 'blob'
    };
    return this.http.get(endPoint, {
      responseType: 'blob',
      headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})
    }).pipe(
        catchError(() => {
          return throwError('Unable to download file !');
        })
    );
  }
}
