<!-- Template-driven Form Validaton Starts -->
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title mb-0 text-white">Simple validation Form</h4>
            </div>
            <div class="card-body">
                <form class="form" (ngSubmit)="onTemplateFormSubmit()" #f="ngForm" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="floatEmail">Email</label>
                                    <input type="email" class="form-control" id="floatEmail" name="floatEmail" ngModel required email #floatEmail="ngModel" [ngModelOptions]="{updateOn: 'blur'}">
                                    <small class="form-text text-danger" *ngIf="!floatEmail.valid && (floatEmail.dirty || floatEmail.touched)">Please enter a valid email!</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="floatPassword">Password</label>
                                    <input type="password" class="form-control" id="floatPassword" name="floatPassword" ngModel required minlength="4" maxlength="24" [ngModelOptions]="{updateOn: 'blur'}"
                                        #floatPassword="ngModel">
                                    <small class="form-text text-danger" *ngIf="!floatPassword.valid && (floatPassword.dirty || floatPassword.touched)">Password characters length must be between 4 and 24 !</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="floattextArea">Textarea</label>
                                    <textarea class="form-control" rows="3" id="floattextArea" name="floattextArea" ngModel required #textArea="ngModel" [ngModelOptions]="{updateOn: 'blur'}"></textarea>
                                    <small class="form-text text-danger" *ngIf="!textArea.valid && (textArea.dirty || textArea.touched)">This field is required!</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <button type="button" class="btn btn-success btn-raised mr-1" [disabled]="!f.valid">Submit</button>
                                <button type="button" class="btn btn-dark btn-raised">Cancel</button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</div>
<!-- Template-driven Form Validaton Ends -->
<!-- Reactive Form Validaton Starts -->
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-dark">
                <h4 class="card-title mb-0 text-white">Regular Form (Reactive Form)</h4>
            </div>
            <div class="card-body">
                <div class="card-block">
                    <form [formGroup]="regularForm" (ngSubmit)="onReactiveFormSubmit()" novalidate>
                        <div class="form-group">
                            <label for="inputEmail">Email</label>
                            <input type="email" class="form-control" id="inputEmail" formControlName="inputEmail" required>
                            <small class="form-text text-danger" *ngIf="!regularForm.get('inputEmail').valid && (regularForm.get('inputEmail').dirty || regularForm.get('inputEmail').touched)">Please enter a valid email!</small>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>

                            <input type="password" class="form-control" id="password" formControlName="password" required>
                            <small class="form-text text-danger" *ngIf="!regularForm.get('password').valid && (regularForm.get('password').dirty || regularForm.get('password').touched)">Password characters length must be between 4 and 24 !</small>
                        </div>
                        <div class="form-group">
                            <label for="textArea">Textarea</label>
                            <textarea class="form-control" rows="3" id="textArea" formControlName="textArea" required></textarea>
                            <small class="form-text text-danger" *ngIf="!regularForm.get('textArea').valid && (regularForm.get('textArea').dirty || regularForm.get('textArea').touched)">This field is required!</small>
                        </div>
                        <div class="form-group">
                            <label class>Radios</label>

                            <div class="" *ngFor="let radioOption of radioOptions">
                                <div class="custom-control custom-radio">
                                  <input type="radio" id="customRadio2" class="custom-control-input" [value]="radioOption" formControlName="radioOption">
                                  <label class="custom-control-label" for="customRadio2">{{ radioOption }}</label>
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-success btn-raised mr-1" [disabled]="!regularForm.valid">Submit</button>
                            <button type="button" class="btn btn-dark btn-raised">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Reactive Form Validaton Ends -->



<!-- Custom Form Validaton Starts -->
<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-success">
                <h4 class="card-title mb-0 text-white">Custom Validations (Template-driven Form)</h4>
            </div>
            <div class="card-body">
                <div class="card-block">
                    <form (ngSubmit)="onCustomFormSubmit()" #vform="ngForm">

                        <div class="form-group">
                            <label for="required">Required</label>
                            <input type="text" class="form-control" ngModel name="required" #required="ngModel" required placeholder="required" />
                            <small class="form-text text-danger" *ngIf="required.errors?.required">required error</small>

                        </div>
                        <div class="form-group">
                            <label for="minlength">Minlength</label>
                            <input type="text" class="form-control" ngModel name="minlength" #minlength="ngModel" placeholder="minlength 5" minlength="5"
                            />
                            <small class="form-text text-danger" *ngIf="minlength.errors?.minlength">minlength error</small>
                        </div>
                        <div class="form-group">
                            <label for="maxlength">Maxlength</label>
                            <input type="text" class="form-control" ngModel name="maxlength" #maxlength="ngModel" placeholder="maxlength 5" maxlength="5"
                            />
                            <small class="form-text text-danger" *ngIf="maxlength.errors?.maxlength">required error</small>
                        </div>
                        <div class="form-group">
                            <label for="rangelength">Rangelength</label>
                            <input type="text" class="form-control" ngModel name="rangelength" #rangelength="ngModel" placeholder="rangeLength [5, 9]"
                                [rangeLength]="[5, 9]" />
                            <small class="form-text text-danger" *ngIf="rangelength.errors?.rangeLength">rangelength error</small>
                        </div>
                        <div class="form-group">
                            <label for="pattern">Pattern</label>
                            <input type="text" class="form-control" ngModel name="pattern" #pattern="ngModel" placeholder="[a-z]{6}" pattern="[a-z]{6}"
                            />
                            <small class="form-text text-danger" *ngIf="pattern.errors?.pattern">pattern error</small>
                        </div>
                        <div class="form-group">
                            <label for="base64">Base64</label>
                            <input type="text" class="form-control" ngModel name="base64" #base64="ngModel" placeholder="base64" base64 />
                            <small class="form-text text-danger" *ngIf="base64.errors?.base64">base64 error</small>
                        </div>
                        <div class="form-group">
                            <label for="creditCard">CreditCard</label>
                            <input type="text" class="form-control" ngModel name="creditCard" #creditCard="ngModel" placeholder="creditCard" creditCard
                            />
                            <small class="form-text text-danger" *ngIf="creditCard.errors?.creditCard">creditCard error</small>
                        </div>
                        <div class="form-group">
                            <label for="date">Date</label>
                            <input type="date" class="form-control" ngModel name="date" #date="ngModel" placeholder="date" date />
                            <small class="form-text text-danger" *ngIf="date.errors?.date">date error</small>
                        </div>
                        <div class="form-group">
                            <label for="dateISO">DateISO</label>
                            <input type="text" class="form-control" ngModel name="dateISO" #dateISO="ngModel" placeholder="dateISO" dateISO />
                            <small class="form-text text-danger" *ngIf="dateISO.errors?.dateISO">dateISO error</small>
                        </div>
                        <div class="form-group">
                            <label for="maxDate">MaxDate</label>
                            <input type="date" class="form-control" ngModel name="maxDate" #maxDate="ngModel" placeholder="maxDate 2017-11-11" maxDate="2017-11-11"
                            />
                            <small class="form-text text-danger" *ngIf="maxDate.errors?.maxDate">maxDate error</small>
                        </div>
                        <div class="form-group">
                            <label for="minDate">MinDate</label>
                            <input type="date" class="form-control" ngModel name="minDate" #minDate="ngModel" placeholder="minDate 2017-11-11" minDate="2017-11-11"
                            />
                            <small class="form-text text-danger" *ngIf="minDate.errors?.minDate">minDate error</small>
                        </div>
                        <div class="form-group">
                            <label for="digits">Digits</label>
                            <input type="text" class="form-control" ngModel name="digits" #digits="ngModel" placeholder="digits" digits />
                            <small class="form-text text-danger" *ngIf="digits.errors?.digits">digits error</small>
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" ngModel name="email" #email="ngModel" placeholder="email" email />
                            <small class="form-text text-danger" *ngIf="email.errors?.email">email error</small>
                        </div>
                        <div class="form-group">
                            <label for="equal">Equal</label>
                            <input type="text" class="form-control" ngModel name="equal" #equal="ngModel" placeholder="equal to apex" equal="apex" />
                            <small class="form-text text-danger" *ngIf="equal.errors?.equal">equal error</small>
                        </div>
                        <div class="form-group">
                            <label for="notEqual">NotEqual</label>
                            <input type="text" class="form-control" ngModel name="notEqual" #notEqual="ngModel" placeholder="not equal to apex" notEqual="apex"
                            />
                            <small class="form-text text-danger" *ngIf="notEqual.errors?.notEqual">notEqual error</small>
                        </div>
                        <div class="form-group">
                            <label for="password">EqualTo</label>
                            <input type="text" class="form-control" ngModel name="password" #password="ngModel" placeholder="password" required />
                            <small class="form-text text-danger" *ngIf="password.errors?.required">required error</small>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" ngModel name="equalTo" #equalTo="ngModel" placeholder="confirm password" [equalTo]="password"
                            />
                            <small class="form-text text-danger" *ngIf="equalTo.errors?.equalTo">equalTo error</small>
                        </div>
                        <div class="form-group">
                            <label for="oldPassword">NotEqualTo</label>
                            <input type="text" class="form-control" ngModel name="oldPassword" #oldPassword="ngModel" placeholder="password" required
                            />
                            <small class="form-text text-danger" *ngIf="oldPassword.errors?.required">required error</small>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" ngModel name="notEqualTo" #notEqualTo="ngModel" placeholder="confirm password" [notEqualTo]="oldPassword"
                            />
                            <small class="form-text text-danger" *ngIf="notEqualTo.errors?.notEqualTo">notEqualTo error</small>
                        </div>
                        <div class="form-group">
                            <label for="gt">Gt</label>
                            <input type="text" class="form-control" ngModel name="gt" #gt="ngModel" placeholder="gt 20" gt="20" />
                            <small class="form-text text-danger" *ngIf="gt.errors?.gt">gt error</small>
                        </div>
                        <div class="form-group">
                            <label for="lt">Lt</label>
                            <input type="text" class="form-control" ngModel name="lt" #lt="ngModel" placeholder="lt 10" lt="10" />
                            <small class="form-text text-danger" *ngIf="lt.errors?.lt">lt error</small>
                        </div>
                        <div class="form-group">
                            <label for="json">Json</label>
                            <input type="text" class="form-control" ngModel name="json" #json="ngModel" placeholder="json" json />
                            <small class="form-text text-danger" *ngIf="json.errors?.json">json error</small>
                        </div>
                        <div class="form-group">
                            <label for="max">Max</label>
                            <input type="text" class="form-control" ngModel name="max" #max="ngModel" placeholder="max 10" max="10" />
                            <small class="form-text text-danger" *ngIf="max.errors?.max">max error</small>
                        </div>
                        <div class="form-group">
                            <label for="min">Min</label>
                            <input type="text" class="form-control" ngModel name="min" #min="ngModel" placeholder="min 10" min="10" />
                            <small class="form-text text-danger" *ngIf="min.errors?.min">min error</small>
                        </div>
                        <div class="form-group">
                            <label for="number">Number</label>
                            <input type="text" class="form-control" ngModel name="number" #number="ngModel" placeholder="number" number />
                            <small class="form-text text-danger" *ngIf="number.errors?.number">number error</small>
                        </div>
                        <div class="form-group">
                            <label for="phone">Phone</label>
                            <input type="text" class="form-control" ngModel name="phone" #phone="ngModel" placeholder="phone" phone />
                            <small class="form-text text-danger" *ngIf="phone.errors?.phone">phone error</small>
                        </div>
                        <div class="form-group">
                            <label for="url">Url</label>
                            <input type="text" class="form-control" ngModel name="url" #url="ngModel" placeholder="url" url />
                            <small class="form-text text-danger" *ngIf="url.errors?.url">url error</small>
                        </div>
                        <div class="form-group">
                            <label for="uuid">Uuid</label>
                            <input type="text" class="form-control" ngModel name="uuid" #uuid="ngModel" placeholder="uuid" uuid />
                            <small class="form-text text-danger" *ngIf="uuid.errors?.uuid">uuid error</small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
