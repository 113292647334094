<div class="card-body compose">
   
    <form method="get" action="#">
        <div class="form-group">
            <input type="text" class="form-control" placeholder="To">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Cc / Bcc">
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Subject">
        </div>
        <div class="form-group">
            <textarea class="form-control" placeholder="Message" rows="10"></textarea>
        </div>
        <div class="form-group float-right">
            <a [routerLink]="['../mail-list/inbox']" class="btn btn-secondary">Cancel</a>
            <button class="btn btn-main" type="submit">Send</button>            
        </div>
    </form>

 </div>
 
