import {Routes} from '@angular/router';
import {CandidateListComponent} from './candidate/list/candidateList.component';
import {CandidateDetailsComponent} from './candidate/candidate-details/candidate-details.component';
import {RecruitmentAuthGuardService} from './recruitment-auth-guard.service';
import {RecruitmentRequestListComponent} from './request/list/recruitmentRequestList.component';
import {RecruitmentRequestDetailsComponent} from './request/request-details/recruitment-request-details.component';
import {CandidateSelectionDetailsComponent} from './selection/candidate-selection-details/candidate-selection-details.component';
import {CandidateSelectionListComponent} from './selection/list/candidateSelectionList.component';


export const RecruitmentRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'candidate/list',
        component: CandidateListComponent,
        canActivate: [RecruitmentAuthGuardService]
      },
      {
        path: 'candidate/details',
        component: CandidateDetailsComponent,
        canActivate: [RecruitmentAuthGuardService]
      },
      {
        path: 'request/list',
        component: RecruitmentRequestListComponent,
        canActivate: [RecruitmentAuthGuardService]
      },
      {
        path: 'request/details',
        component: RecruitmentRequestDetailsComponent,
        canActivate: [RecruitmentAuthGuardService]
      }
      ,
      {
        path: 'candidate/selection/list',
        component: CandidateSelectionListComponent,
        canActivate: [RecruitmentAuthGuardService]
      },
      {
        path: 'candidate/selection/details',
        component: CandidateSelectionDetailsComponent,
        canActivate: [RecruitmentAuthGuardService]
      }
    ]
  }
];
