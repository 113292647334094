import {Component, OnInit, ViewChild} from '@angular/core';
import {IHrDocumentRequest} from '../../shared/model/ihr-document-request';
import {IHrDocumentType} from '../../shared/model/hrDocumentType';
import {IEmployee} from '../../shared/model/employee';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {EmployeesService} from '../../shared/service/employees.service';
import {HrDocumentTypeService} from '../../shared/service/hr-document-type.service';
import {LocalstorageService} from '../../shared/service/localstorage.service';
import {HrDocumentRequestService} from '../../shared/service/hr-document-request.service';
import {isNumeric} from 'rxjs/util/isNumeric';
import {NgxSpinnerService} from 'ngx-spinner';
import {LeaveRequestService} from 'src/app/shared/service/leave-request.service';
import {ApiService} from 'src/app/shared/service/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import moment from 'moment';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {AuthService} from 'src/app/shared/service/auth.service';
import {LINE_MANAGER_USER_ROLE, SELF_SERVICE_USER_ROLE} from 'src/app/app.constants';
import * as constants from '../../app.constants';
import {ToastrService} from 'ngx-toastr';
import DateUtils from '../../utils/DateUtils';

interface SessionObject
{
    CompanyCode?: string;
    BranchCode?: string;
    Token?: string;
    FinancialCode?: string;
    FYearID?:string;
    DocumentCode?: string;
    ModuleCode?: string;
    UserName?: string
}

interface SaveDocNumberPayload {
  docNo: number;
  documentType: string;
  emplPmlId: any;
  emplId: any;
  docDate: string;
  fyerId: any;
  vtypeDocType: string;
  vtypeModsModule: string;
  code: string;
  compCode: string;
  period: number;
  createdBy: string;
}
export interface PopupTable
{
    Code: string;
    Name: string;
    Selected: boolean;
    RowObject?: any;
}

@Component({
  selector: 'app-hrdocrequest',
  templateUrl: './hrdocrequest.component.html',
  styleUrls: ['./hrdocrequest.component.css']
})
export class HrdocrequestComponent implements OnInit {

  dtOptions: DataTables.Settings = {
  };
  PopUpTableData: PopupTable[] = [];
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  @ViewChild("configModal") public ConfigModal: ModalDirective;
  hrdocumentRequest: IHrDocumentRequest = new IHrDocumentRequest();
  hrDocumentTypes: IHrDocumentType[] = [];
  selectedEmployee: any;
  selectedEmployeeDocRequests: IHrDocumentRequest[];
  selectedDocumentType: IHrDocumentType;
  hrdocumentRequestForm: FormGroup;
  employees: IEmployee[];
  allEmployees: IEmployee[];
  allMappedEmployees: any[];
  singleSelect: any = [];
  documentNumber: any = '';
  docDate: any;
  DocumentTypes: any[] = [];

  validationForm: boolean;
  validationFormType: boolean;
  DetailsPopulateObject :any = {}

  editing = {};
  rows = [];
  temp = [];
  source:any;
  PeriodList: any[] = [];
  VourcherList: any[] = [];
  SelectedPeriod: any = {};
  isHrSaveEnabled : boolean = false;
  FromObject =
  {
    "EmployeeType":"",
    "DOJ":"",
    "Location":"",
    "Profession":"",
    "Department":"",
    "Position":"",
    "DocumentType":"",
    "EmpNo":"Select Employee",
    "Name":""
  }
  show: boolean = false;
  Session: SessionObject = {
    BranchCode: "",
    FinancialCode: "",
    CompanyCode: "",
    ModuleCode: "",
    DocumentCode: "LR",
  };
  SelectedVoucher: any = {};
  DocumentCode: string = '';
  createdDocumentRequestId: any = {};
  isPassBtnEnabled: boolean = false;
  id;
  isEmployeePopupDisabled : boolean = false;
  currentUserInformations:any;
  isDetailsFlag: boolean = false;
  autoSelectVoucher: boolean = false;
  passedFlag: boolean = false;
  hrCoordinatorEmployees : any =[];
  isDownloadButtonVisible : boolean = false;
  documentSaved = false;
  currentUserAllowedToManagerApprove: any = false;
  currentUserAllowedToApproveCurrentLevel = false;
  documentRejected: boolean;
  documentApproved: boolean;
  documentManagerApprove: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeesService,
    private hrDocumentTypeService: HrDocumentTypeService,
    private leaveRequestService: LeaveRequestService,
    private hrdocumentRequestService: HrDocumentRequestService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private authService : AuthService,
    private localstorage: LocalstorageService,
    private _toastr: ToastrService,
    private router: Router,
    private hrDocumentRequestService: HrDocumentRequestService,
  ) { }

  ngOnInit(): void {
    if(this.authService.isSelfServiceUser())
      this.autoSelectVoucher = true;
    this.isPassed = false;
    if (this.activatedRoute.snapshot.queryParams['id']) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.id = params['id'];
        this.source = params['src'];
        if(this.id && this.id != 'null' && this.id != '')
        {

          this.getDetails();
          this.show = true;
          this.documentSaved = true;
        } else {
          this.documentSaved = false;
        }
      });
    }else{
      this.id = ''
    }

    this.loadHrDocumentTypes();
    //this.loadEmployees();
    this.hrdocumentRequestForm = this.createHrdocumentRequestForm(null);
    this.generateDocumentNumber();
    this.getDocumentTypes();

    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = "JV";
    this.Session.ModuleCode = "PP";
    this.Session.BranchCode = "01";
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode
    this.Session.FYearID = SessionObject.glUserMastersDetailsDTO.currentFinancialYear;
    this.loadPeriods(this.Session);
    if(this.authService.getcurrentUserRoles().find(x=> x === constants.HR_CORDINATOR_USER_ROLE))
      this.loadVoucherCodes();

    this.validationForm = false;
    this.validationFormType = false;
    this.fillCurrentUserInformations();
    this.loadHrCoordinatorEmployees();
  }

  selectedEmployeeName = "Select Employee"
  SubEmployee:any
  flag = false;
  selectEmployee()
  {
    if( this.passedFlag || this.authService.isSelfServiceUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser())
    return
    this.PopUpTableData = [];
    this.spinner.show();
    let Data : any = [];
     if(this.authService.isHrCoordinatorUser()){
       // Data = this.hrCoordinatorEmployees;
       Data = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
     } else {
      Data = localStorage.getItem('AllEmployees')? JSON.parse(localStorage.getItem('AllEmployees')) : null
    }
    if(Data)
    Data.forEach((element:any) => {
      this.PopUpTableData.push(
        {
          Code : element.id ,
          Name : element.name ,
          Selected : false ,
          RowObject: element
        }

      )
    });
    if(!this.flag)
    this.dtTrigger.next();
    else
    this.rerender()
    this.flag = true
    this.ConfigModal.show();
    this.spinner.hide()
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  popUpTableValueSelected(row) {
      this.selectedEmployeeName = row.Name;
      this.PopUpTableData.forEach(x=>x.Selected = false)
      this.selectedEmployee = {employeeIdentity:{id:null, pmlId:null}}
      this.selectedEmployee.employeeIdentity.id  = row.RowObject.id
      this.selectedEmployee.employeeIdentity.pmlId = row.RowObject.pmlId
      this.spinner.show()
      this.employeeService.getByEmpNo(row.RowObject.empNo).subscribe(data => {
        this.spinner.hide()
        this.selectedEmployee = data;
        this.getSelectedEmpDocRequests(this.selectedEmployee.empNo);
        this.fillHrDocument(this.selectedEmployee);
      },
      error=>
      {
        this.spinner.hide()
      });
      row.Selected = true;
      this.ConfigModal.hide();
  }

  fillHrDocument(data)
  {
    this.FromObject.EmployeeType = data.profession
    //this.FromObject.DOJ = moment(data.doj,'YYYY-MM-DD').format('DD/MM/YYYY')
    this.FromObject.DOJ = DateUtils.get(data.doj,'YYYY-MM-DD');
    this.FromObject.Department = data.departement.description
    this.FromObject.Location = data.nationality
    this.FromObject.Position = data.profession
    this.FromObject.Profession = data.profession
    //HRP-128
    // this.FromObject.EmpNo = data.empNo
    this.FromObject.EmpNo = data.empNo;
    this.FromObject.Name = data.name

  }

  loadVoucherCodes() {
    this.hrdocumentRequestService.getHrDocumentRequestVouchers(this.Session.CompanyCode, this.Session.FYearID).subscribe(
      (data:any[])=>{
        this.VourcherList = data;
        this.SelectedVoucher = this.VourcherList[0];
        if(this.VourcherList.length == 0) {
          this._toastr.error("no voucher found");
          this.router.navigate(['hrdocuments/list'])
        }
        return this.SelectedVoucher;
      },
      error=> {
        this._toastr.error("Error in getting vouchers");
      }
    )
  }

  DB()
  {

  }

  getDocumentTypes()
  {
    this.hrDocumentTypeService.getDocumentTypes().subscribe(
      data=>
      {
        this.DocumentTypes = data;
        this.DocumentTypes.forEach((x:any)=>
        {
              if (this.DetailObject? x.code == this.DetailObject.documentType : false)
              x.selected = true
              else
              x.selected = false
        })

      },
      error=> {
        this._toastr.error("Error in getting document types");
      }
    )
  }

  showDocRequests()
  {
      if (this.SelectedPeriod && this.SelectedVoucher) {
        this.show = true;
        //HRP-123 Missing document number when user creates a new HR request
        // check if selectedVocher is not emty then get doc num
        if(JSON.stringify(this.SelectedVoucher) !== '{}'){
        let Payload =
          this.hrDocumentTypeService.generateDocumentNumber(this.SelectedPeriod.Id,
            {
              "docType": this.SelectedVoucher?.code? this.SelectedVoucher?.code : null,
              "compCode": this.Session.CompanyCode? this.Session.CompanyCode : null,
              "code": this.SelectedVoucher?.docType? this.SelectedVoucher?.docType : null,
              "fyerId": this.Session.FYearID? this.Session.FYearID : null,
              "modsModule": 'PP'
            }).subscribe(
              data=>{
                if( this.id === 'null' || this.id === ''){
                  this.documentNumber = data;
                  this.hrdocumentRequestForm.controls['docNo'].patchValue(data);
                  // this.DetailsPopulateObject.DocNo = data;
                }
           },
              error=>{
                this._toastr.error("Error in getting document number");
              }
            )

        }else{
          // if selected Vocher is empty call getHrDocumentRequestVouchers and assign value to SelectedVoucher
          this.hrdocumentRequestService.getHrDocumentRequestVouchers(this.Session.CompanyCode, this.Session.FYearID).subscribe(
            (data:any[])=>{
              this.VourcherList = data;
              this.SelectedVoucher = this.VourcherList[0];
              let Payload =
              this.hrDocumentTypeService.generateDocumentNumber(this.SelectedPeriod.Id,
                {
                  "docType": this.SelectedVoucher?.code? this.SelectedVoucher?.code : null,
                  "compCode": this.Session.CompanyCode? this.Session.CompanyCode : null,
                  "code": this.SelectedVoucher?.docType? this.SelectedVoucher?.docType : null,
                  "fyerId": this.Session.FYearID? this.Session.FYearID : null,
                  "modsModule": 'PP'
                }).subscribe(
                  data=>{
                    if( this.id === 'null' || this.id === ''){
                      this.documentNumber = data;
                      this.hrdocumentRequestForm.controls['docNo'].patchValue(data);
                      // this.DetailsPopulateObject.DocNo = data;
                    }  },
                  error=>{
                    this._toastr.error("Error in getting vouchers");
                  }
                )
            },
            error=>
            {
              this._toastr.error("Error in getting vouchers");
            }
          )
        }

      }
  }

  setDateFromPeriod(event) {
    let ChangePeriod = event.target.value;
    this.SelectedPeriod = this.PeriodList.filter(
      (x) => x.Period == ChangePeriod
    )[0];
  }

  loadPeriods(Session: SessionObject) {
    if (!Session.BranchCode || !Session.CompanyCode || !Session.FYearID)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiService
      .getPeriodByCompanyAndBranch(
        Session.CompanyCode,
        Session.FYearID,
        Session.BranchCode
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          data.forEach((element) => {
            let month = element.period.length == 1? '0'+element.period: element.period
            if(month !=  moment().format('MM') )
              return
            this.PeriodList.push({
              Id: element.id,
              Period: element.period,
              FromDate: element.fromDate,
              ToDate: element.toDate,
              FinancialYearID: element.fyerId,
            });
          });
          this.PeriodList.sort((a, b) => {
            return Number.parseInt(a.Period) - Number.parseInt(b.Period);
          }); // Sorting the Periods
          this.SelectedPeriod = this.PeriodList[0];
          //if(this.localstorage.getLocalStorageData().glUserMastersDetailsDTO.roles.includes(SELF_SERVICE_USER_ROLE) || this.localstorage.getLocalStorageData().glUserMastersDetailsDTO.roles.includes(LINE_MANAGER_USER_ROLE))
          this.showDocRequests()
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  config = {
    displayKey: "name",
    search: true,
    limitTo: 7,
  };

  columns = [{ prop: 'documentType', name: 'Document Type' }, { prop: 'docNo', name: 'Document No' }, { prop: 'docDate', name: 'Document Date' }, {name: 'Actions'}];

  pageSize: any = 8;
  page: any =1
  count: any = 10;
  searchedEmployee = '';
  initialFlag = false;
  initialFlagTrue()
  {
    this.initialFlag = true;
  }

  loadEmployees() {
    this.employeeService.getEmployeePage(this.page,this.pageSize).subscribe(data => {
      this.employees = data.content;
      this.allEmployees = data.content;
      this.count = data.totalPages;
      this.allMappedEmployees = this.employees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
    this.employeeService.getAll().subscribe(data => {
      this.allEmployees = data;
      this.allMappedEmployees = this.allEmployees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
  }

  generateDocumentNumber(){
    this.hrdocumentRequestService.getDocumentNumber().subscribe(data =>{
      // this.hrdocumentRequestForm.controls['docNo'].patchValue(data);
      this.documentNumber = data;
    });
  }

  onChange(event){
    this.selectedDocumentType = event.target.value;
  }

  isPassed: boolean;

  isOwnerOfDocument(): boolean {
    const currentUserId = this.authService.getCurrentUserInformations().employee.id;
    if (this.id === 'null') {
      return true;
    }
    return currentUserId === this.selectedEmployee?.id;
  }

  isSaveDisabled(): boolean {
    if (!this.isOwnerOfDocument() && !this.authService.isHrCoordinatorUser()) { return true; }
    return !this.isOwnerOfDocument() || this.isPassed || !this.selectedDocumentType;
  }

  isPassDisabled(): boolean {
    if (!this.isOwnerOfDocument() && !this.authService.isHrCoordinatorUser()) { return true; }
    return !this.isOwnerOfDocument() || this.isPassed || !this.documentSaved;
  }

  canConfirm(): boolean {
    if (!this.currentUserAllowedToApproveCurrentLevel || this.documentApproved || this.documentRejected || !this.isPassed || !this.documentManagerApprove) {
      return false;
    }
    return true;
  }

  canApprove(): boolean {
    if (!this.currentUserAllowedToManagerApprove || this.documentApproved || this.documentRejected || !this.isPassed || this.documentManagerApprove) {
      return false;
    }
    return true;
  }

  DetailObject;

  getDetails()
  {
    this.isDownloadButtonVisible = false;
    this.hrdocumentRequestService.getDetails(this.id).subscribe(
      data => {
        if(data.passed === 'Y') {
          this.isDetailsFlag = true;
          this.isPassed = true;
        }
        this.DetailObject = data;
        this.isPassBtnEnabled = true;
        this.createdDocumentRequestId = this.id;
        this.DetailsPopulateObject.DocNo = data?.docNo;
        this.selectedDocumentType = data.documentType;
        //this.DetailsPopulateObject.CreateDate = moment().format('DD-MM-YYYY')
        this.DetailsPopulateObject.CreateDate = DateUtils.get();
        this.documentNumber = data.docNo;
        this.DocumentTypes.forEach(x=>{
          if (x.code == data.documentType)
           x.selected = true
        })
        this.currentUserAllowedToManagerApprove = data?.currentUserAllowedToManagerApprove;
        this.currentUserAllowedToApproveCurrentLevel = data?.currentUserAllowedToApproveCurrentLevel;
        console.log(data);
        this.documentRejected = data?.rejected;
        this.documentApproved = data?.approved;
        this.documentManagerApprove = data?.managerApproved;

        this.employeeService.getByEmpNo(this.DetailObject.empno).subscribe(
          data=>
          {
            this.FromObject.EmployeeType = 'TBA';
            //this.FromObject.DOJ = moment(data.doj,'YYYY-MM-DD').format('DD/MM/YYYY');
            this.FromObject.DOJ = DateUtils.get(data.doj,'YYYY-MM-DD');
            this.FromObject.Profession = data.profession;
            this.FromObject.Location = data.nationality;
            this.FromObject.Department = data.departement.description;
            this.FromObject.Position = 'TBA' ;
            this.FromObject.Name = data.name;
            this.FromObject.EmpNo = data.empNo;
            this.selectedEmployee = data;

            this.getSelectedEmpDocRequests(data.empNo);
            //this.loaddHrDocumentRequestInfos();
          })

        this.employeeService.getDocumentRequestApprovals(this.id).subscribe(
            data => {
              data.forEach((element) => {
                    if (this.currentUserInformations.employee.empNo === element.empNo) {
                      this.isDownloadButtonVisible = true;
                      return;
                    }
                  }
              );
            }
        );


      }
    )
  }

  onEmployeeChange(employee){
    console.log(employee);
    console.log(employee.value.value.empNo);
    this.selectedEmployee = employee.value.value;
    this.hrdocumentRequestForm = this.createHrdocumentRequestForm(employee.value.value);
    this.getSelectedEmpDocRequests(employee.value.value.empNo);
    this.loaddHrDocumentRequestInfos();
  }

  searchChange($event) {
    /*console.log($event);
    let empNo = $event;
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      this.selectedEmployee = data;
      this.hrdocumentRequestForm = this.createHrdocumentRequestForm(data);
      this.getSelectedEmpDocRequests(empNo);
    })*/
    let empNameOrNumber = $event;

    if (empNameOrNumber !== '' && empNameOrNumber.replace(/\s/g, '').length) {
      console.log(empNameOrNumber);
      if (!isNaN(Number(empNameOrNumber))) {
        this.employeeService.getByEmpNoOrName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      } else {
        this.employeeService.getByName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      }
    } else {
      if (this.allEmployees) {
        this.allMappedEmployees = this.allEmployees.map(dataEmp => {
          return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
        });
      }
    }
  }

  onSubmit() {

  }

  handleEmployeeDropdownPageChange(event) {
    this.page = event;
    this.loadEmployees();
  }

  EmployeeFilterChanged() {
    if (this.searchedEmployee === '') {
      this.loadEmployees();
    } else {
      if (isNumeric(this.searchedEmployee)) {
        this.employeeService.getByEmpNo(this.searchedEmployee).subscribe(data => {
          this.employees = new Array(data);
        });
      } else {
        this.employeeService.getByName(this.searchedEmployee).subscribe(data => {
          this.employees = data;
        });
      }
    }
  }

  loadHrDocumentTypes() {
    this.hrDocumentTypeService.load().subscribe(data => {
      console.log(data);
      this.hrDocumentTypes = data;


    });
  }

  loadHrCoordinatorEmployees() {
    this.employeeService.getEmployeesForCurrentHrCoordinator(this.Session.UserName , this.Session.CompanyCode).subscribe(data => {
      this.hrCoordinatorEmployees = data;
    })
  }


  createHrdocumentRequestForm(value: IEmployee) {
    return this.formBuilder.group({
      docNo: new FormControl({ value: this.documentNumber, disabled: true }),
      //docDate: new FormControl({ value: moment().format('DD/MM/YYYY'), disabled: true }),
      docDate: new FormControl({ value: DateUtils.get(), disabled: true }),
      empNo: [value ? value.empNo : null],
      name: [value ? value.name : null],
      empType: [value ? value.employeeType.description : null],
      doj: new FormControl({ value: value ? this.fromJsonDate(value.doj) : null, disabled: true }),
      dob: new FormControl({ value: value ? this.fromJsonDate(value.dob) : null, disabled: true }),
      location: new FormControl({ value: '', disabled: true }),
      profession: [value ? value.profession.description : null],
      departement: [value ? value.departement.description : null],
      position: [value ? value.grade : null],
      documentType: ['']
    });
  }

  loaddHrDocumentRequestInfos() {
    let empNo = this.hrdocumentRequestForm.controls['empNo'].value;
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      this.selectedEmployee = data;
      this.hrdocumentRequestForm = this.createHrdocumentRequestForm(data);
      this.getSelectedEmpDocRequests(empNo);
      this.validationForm = true;
    });
  }

  changeDocType() {
    if (this.hrdocumentRequestForm.controls['documentType'].value) {
      this.validationFormType = true;
    }
  }

  private getSelectedEmpDocRequests(empNo: any) {
    this.rows = [];
    this.hrdocumentRequestService.getByEmployee(empNo).subscribe(data => {
      console.log(data);
      if (data.length > 0) {
        this.selectedEmployeeDocRequests = data;
        let tableData = data.map(dataSet => {
          return {docNo: dataSet.docNo, docDate: dataSet.docDate};
        });
        this.rows = data;
        //this.rows.forEach(x=> x.docDate = moment(x.docDate,'YYYY-MM-DD').format('DD/MM/YYYY'))
        this.rows.forEach(x=> x.docDate = DateUtils.get(x.docDate,'YYYY-MM-DD'));
        console.log(tableData);
      }
    });
  }

  next() {
    let empNo = this.hrdocumentRequestForm.controls['empNo'].value + 1;
    this.getEmployee(empNo);
    this.getSelectedEmpDocRequests(empNo);
  }

  previous() {
    let empNo = this.hrdocumentRequestForm.controls['empNo'].value - 1;
    this.getEmployee(empNo);
    this.getSelectedEmpDocRequests(empNo);
  }

  passDocumentRequest(){
      this.isPassBtnEnabled = false;
      this.isHrSaveEnabled = true;
      this.passedFlag = true;
    this.spinner.show();
      this.hrdocumentRequestService.passDocument(this.createdDocumentRequestId, this.Session.UserName).subscribe(data =>{
      this.spinner.hide();
      this._toastr.success("HR Document Request has been Passed");
      this.isPassed = true;
      },err=>{
        this.spinner.hide();
        this._toastr.error("Error Occurred");
      })
  }

  saveHrDocumentRequest()
  {
    this.spinner.show();
    if(this.id && this.id != 'null' && this.id != '')
    this.saveHrDocumentRequest2(this.id, true)
    else
    this.saveHrDocumentRequest2(this.id, false)

  // });
  }

  saveHrDocumentRequest2(RequestID, UpdateFlag) {
    this.isHrSaveEnabled = true;
    let Payload: SaveDocNumberPayload = {
      docNo: this.isNull(this.hrdocumentRequestForm?.controls?.docNo?.value)? this.hrdocumentRequestForm?.controls?.docNo?.value :this.DetailsPopulateObject.DocNo?this.DetailsPopulateObject.DocNo: null,
      documentType: this.isNull(this.selectedDocumentType),
      emplPmlId: this.isNull(this.selectedEmployee?.pmlId),
      emplId: this.isNull(this.selectedEmployee?.id),
      docDate: this.isNull(moment(this.hrdocumentRequestForm?.controls?.docDate?.value,'DD/MM/YYYY').format('YYYY-MM-DD')),
     // docDate: this.isNull(DateUtils.get(this.hrdocumentRequestForm?.controls?.docDate?.value,'DD/MM/YYYY')),
      fyerId: this.isNull(this.Session.FYearID),
      vtypeDocType: this.isNull(this.SelectedVoucher.docType),
      vtypeModsModule : this.isNull("PP"),
      code: this.isNull( this.SelectedVoucher.code),
      compCode: this.isNull(this.Session.CompanyCode),
      period: this.isNull(this.SelectedPeriod.Id),
      createdBy: this.isNull(this.Session.UserName)
    }

    if(!UpdateFlag)
    {
      this.hrDocumentTypeService.saveDocRequest(Payload).subscribe(
        (data:any)=>
          {
            this.isHrSaveEnabled = false;
            this.spinner.hide();
            this._toastr.success("HR Document Request has been saved");
            this.documentSaved = true;
            this.createdDocumentRequestId = data?.id;
            this.isPassBtnEnabled = true;
            this.DetailsPopulateObject.DocNo = data?.docNo;
            this.router.navigate(['hrdocuments/list']);
          },
        error=>
          {
            this.isHrSaveEnabled = false;
            this.spinner.hide();
            this._toastr.error("Error in getting vouchers");
         }
      )
    }
    else
    {
      this.hrDocumentTypeService.updateDocReq(Payload, RequestID).subscribe(
        (data:any)=>
          {
            this.isHrSaveEnabled = false;
            this.spinner.hide();
            this._toastr.success("HR Document Request has been saved");
            this.documentSaved = true;
            this.createdDocumentRequestId = data?.id;
            this.isPassBtnEnabled = true;
            this.DetailsPopulateObject.DocNo = data?.docNo;
            this.router.navigate(['hrdocuments/list']);
          },
        error=>
          {
            this.isHrSaveEnabled = false;
            this.spinner.hide();
            this._toastr.error("Error in getting vouchers");
         }
      )
    }


  }

  isNull(Input)
  {
    return Input? Input: null;
  }


  downloadDocument(documentRequest: IHrDocumentRequest) {
    console.log(documentRequest);
    this.hrdocumentRequestService.downloadDocument(this.id, documentRequest.documentType).subscribe(data =>{
      const blob = new Blob([data],{type: 'application/pdf'});
      if(window.navigator && window.navigator.msSaveOrOpenBlob){
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const value = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = value;
      link.download = documentRequest?.documentTypeDescription + '.pdf';
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      setTimeout(function() {
        window.URL.revokeObjectURL(value);
        link.remove;
      },100);

    })
  }

  private getEmployee(empNo: any) {
    this.employeeService.getByEmpNo(empNo).subscribe(
      (data) => {
        this.selectedEmployee = data;
        this.hrdocumentRequestForm = this.createHrdocumentRequestForm(data);
        if (data == null) {

        }
      },
      (error) => {

      });
  }

  fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10);
  }

  trackByIndex(index: number) {
    return index;
  }

  private fillCurrentUserInformations() {
    let currentUser = this.authService.getCurrentUserInformations();
    this.currentUserInformations = currentUser;
    if (this.authService.getcurrentUserRoles().find(x => x === SELF_SERVICE_USER_ROLE) || this.authService.getcurrentUserRoles().find(x => x === LINE_MANAGER_USER_ROLE)) {
     this.isEmployeePopupDisabled = true;
     this.selectedEmployee = currentUser?.employee;
     this.FromObject.Name = currentUser?.employee?.name;
     this.FromObject.EmpNo = currentUser?.employee?.empNo;
     //this.FromObject.DOJ = moment(currentUser?.employee?.dob,'YYYY-MM-DD').format('DD/MM/YYYY');
     this.FromObject.DOJ = DateUtils.get(currentUser?.employee?.dob,'YYYY-MM-DD');
     this.FromObject.Department = currentUser?.employee?.departement?.description;
     this.FromObject.DocumentType = currentUser?.employee?.documentType;
     this.FromObject.EmployeeType = currentUser?.employee?.employeeType;
     this.FromObject.Location = currentUser?.employee?.location;
     this.FromObject.Position = currentUser?.employee?.position;
     this.FromObject.Profession = currentUser?.employee?.profession;
     // this.fillLeaveRequestForm(currentUser?.employee);
    }
  }
  backHrDoc(){
    if(this.source == 'list')
    this.router.navigate(['/leaves/list'])
    if(this.source == 'approval')
    this.router.navigate(['/leaves/leaveapprove'])
  }

  confirm() {
    this.spinner.show();
    this.hrDocumentRequestService.approve(this.id).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('Document Request Approved');
      this.router.navigate(['hrdocuments/list']);
    }, err => {
      this.spinner.hide();
      this._toastr.error('Error Occurred');
    });
  }

  managerApprove() {
    this.spinner.show();
    this.hrDocumentRequestService.managerApprove(this.id).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('Document request has been approved');
      this.router.navigate(['hrdocuments/documentapproval']);
    }, err => {
      this.spinner.hide();
      this._toastr.error('Error Occurred');
    });
  }
}
