import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {IHrDocumentType} from '../model/hrDocumentType';
import {environment} from '../../../environments/environment';
import { HeadersService } from './headers.service';
import { HrpHeadersService } from './hrp-headers.service';

interface GeneratDocNumberPayload {
  docType: string;
  modsModule: string;
  fyerId: string;
  code: string;
  compCode: string;
}

interface SaveDocNumberPayload {
  docNo: number;
  documentType: string;
  emplPmlId: number;
  emplId: number;
  docDate: string;
  fyerId: number;
  vtypeDocType: string;
  vtypeModsModule: string;
  code: string;
  compCode: string;
  period: number;
  createdBy: string;
}
@Injectable({
  providedIn: 'root'
})
export class HrDocumentTypeService {

  constructor(private http: HttpClient, private header: HrpHeadersService) { }

  load() : Observable<IHrDocumentType[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<IHrDocumentType[]>(environment.host + '/api/document-types', httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getDocumentType(code: any): Observable<IHrDocumentType> {
    return this.http.get<IHrDocumentType>(environment.host + '/api/document-types/' + code)
    .pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllDocumentTypes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(environment.host + '/api/voucher-types/all', httpOptions);
  }

  generateCodeDocument(docType: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<any[]>(`${environment.host}/api/voucher-types/generate-code?docType=${docType}`, httpOptions);
  }

  getDocumentTypes(): Observable<any>
  {
    return this.http.get(`${environment.host}/api/document-types`, this.header.getHttpOptions('GET'))
  }

  generateDocumentNumber(PeriodID, Payload: GeneratDocNumberPayload)
  {
    const API_URL=`${environment.host}/api/document-request/generate-doc-no/${PeriodID}`
    return this.http.post(API_URL, Payload, this.header.getHttpOptions('POST'))
  }

  saveDocRequest(Payload: SaveDocNumberPayload)
  {
    const API_URL=`${environment.host}/api/document-request/add`
    return this.http.post(API_URL, Payload, this.header.getHttpOptions('POST'))
  }

  updateDocReq(Payload: SaveDocNumberPayload, HrDocId: number){

    const API_URL=`${environment.host}/api/document-request/update/${HrDocId}`
    return this.http.put(API_URL, Payload, this.header.getHttpOptions('PUT'))

  }


  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


}
