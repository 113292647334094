<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h4 class="card-title">Candidate Selection List</h4>
          <!--          <button (click)="listDetails(null)" class="new btn btn-success mt-2 mb-2">New Candidate-->
          <!--            Selection-->
          <!--          </button>-->
        </div>
        <div class="row">
          <div class="col-lg-12">
            <form (ngSubmit)="(searchCandidatesSelection())"
                  [formGroup]="candidateSelectionFormGroup">
              <table class="table">
                <tbody>
                <tr>
                  <td>
                    <p>Id</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="id" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Candidate Id</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="candidateId" type="text"/>
                    </div>
                  </td>

                  <td>
                    <p>Company</p>
                  </td>
                  <td>
                    <div class="form-group">
                      <input class="form-control" formControlName="compCode" type="text"/>
                    </div>
                  </td>

                </tr>

                <tr>
                  <td>
                    <button class="btn btn-primary btn-euquiry">Search</button>
                  </td>
                </tr>

                </tbody>
              </table>
            </form>
          </div>
        </div>

        <ngx-datatable #table
                       [columnMode]="'force'"
                       [columns]="columns"
                       [footerHeight]="50"
                       [headerHeight]="50"
                       [rowHeight]="'auto'"
                       [rows]="rows"
                       [scrollbarH]="scrollBarHorizontal"
                       class='material'>
          <ng-container *ngFor='let column of columns'>
            <ngx-datatable-column *ngIf='column.name === "Id"' name="Id" prop="id">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.id}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "Company"' name="Company"
                                  prop="compCode">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.compCode}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "Candidate"' name="Candidate"
                                  prop="candidateID">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.candidateID}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "Recruitment Request"'
                                  name="Recruitment Request"
                                  prop="recruitmentRequest">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{row?.recruitmentRequestId}} {{row?.recruitmentRequestTitle}}
                    </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column *ngIf='column.name === "Status"' name="Status"
                                  prop="status">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <span>
                        {{getStatus(row)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Action"' [sortable]="false" name="Action">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <button (click)="listDetails(row?.id)" class="btn btn-info m-1 "><i
                    class="fas fa-info-circle"></i></button>
                <button (click)="confirm(row?.id)"
                        *ngIf="row?.currentUserAllowedToConfirmOrReject"
                        class="btn btn-primary m-1 "><i
                    class="fas fa-check"></i></button>
                <button (click)="reject(row?.id)"
                        *ngIf="row?.currentUserAllowedToConfirmOrReject"
                        class="btn btn-warning m-1 "
                        style="background-color: red"><i
                    class="fas fa-times"></i></button>
                <!--                <button (click)="delete(row?.referenceNumber)" class="btn btn-warning m-1 "-->
                <!--                        style="background-color: red"><i-->
                <!--                    class="fas fa-times"></i></button>-->
              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>

        <div class="pagination-set">
          <mat-paginator (page)="searchByPageCandidates($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>

      </div>
    </div>
  </div>
</div>
