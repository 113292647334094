<div class="row">
  <div class="col-md-12 col-sm-12">
    <div class="form-group">
      <ng-container>
        <ngx-select-dropdown (change)="onEmployeeChange($event)" (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [config]="config" [options]="allMappedEmployees"></ngx-select-dropdown>
      </ng-container>
    </div>
  </div>
</div>

<div id="salaryIncrementChart" style="width:100%; height:600px;"></div>

