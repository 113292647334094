import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {RequestSearchDto} from '../../../shared/dto/recruitment/request/requestSearchDto';
import {RecruitmentRequestService} from '../../../shared/service/recruitment-request-service';
import {RecruitmentRequestDto} from '../../../shared/dto/recruitment/request/recruitmentRequestDto';
import moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './recruitmentRequestList.component.html',
  styleUrls: ['./recruitmentRequestList.component.css']
})
export class RecruitmentRequestListComponent implements OnInit {

  rows: RecruitmentRequestDto[];
  sessionObject = {
    user: '',
    token: '',
    company: ''
  };
  requestFormGroup: FormGroup;
  requestSearchDto: RequestSearchDto;
  columns = [
    {prop: 'referenceNumber', name: 'Reference Number'},
    {prop: 'compCode', name: 'Company'},
    {prop: 'requiredByDate', name: 'Required By Date'},
    {prop: 'vacancyJobTitle', name: 'Vacancy Job Title'},
    {prop: 'workLocation', name: 'Work Location'},
    {prop: 'recruitmentType', name: 'RecruitmentType'},
    {prop: 'status', name: 'Status'},
    {prop: 'action', name: 'Action', width: 50}
  ];
  pageIndex = 0;
  pageSize = 10;
  totalElements = 0;
  scrollBarHorizontal = (window.innerWidth < 1200);

  constructor(private recruitmentRequestService: RecruitmentRequestService, private router: Router, private spinner: NgxSpinnerService,
              private _toastr: ToastrService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.instantiateForm();
    this.loadRecruitmentRequest();
  }

  instantiateForm() {
    this.requestFormGroup = this.formBuilder.group({
      referenceNumber: [''],
      compCode: [''],
      requiredByDate: [''],
      vacancyJobTitle: [''],
      workLocation: [''],
      recruitmentType: ['']
    });
  }

  searchByRecruitmentRequest(event) {
    this.requestSearchDto = this.requestFormGroup.value;
    this.spinner.show();
    this.recruitmentRequestService.search(this.requestSearchDto, event.pageIndex, event.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  searchRecruitmentRequest() {
    this.requestSearchDto = this.requestFormGroup.value;
    this.spinner.show();
    this.recruitmentRequestService.search(this.requestSearchDto, 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  loadRecruitmentRequest() {
    this.spinner.show();
    this.recruitmentRequestService.search(new RequestSearchDto(), 0, this.pageSize).subscribe(data => {
      this.rows = data.content;
      this.pageSize = data.size;
      this.pageIndex = data.number;
      this.totalElements = data.totalElements;
      this.spinner.hide();
    }, error => {
      this._toastr.error(error);
      this.spinner.hide();
    });
  }

  initializeSessionObject() {
    const SessionObject = JSON.parse(localStorage.getItem('loginsessiondata'));
    this.sessionObject.token = SessionObject.id_token;
    this.sessionObject.user = SessionObject?.glUserMastersDetailsDTO?.userCode;
    this.sessionObject.company = SessionObject?.glUserMastersDetailsDTO?.compCode;
  }

  listDetails(requestNumber) {
    this.router.navigate(['/recruitment/request/details'], {queryParams: {id: requestNumber}});
  }

  delete(requestNumber) {
    if (confirm('Are you sure you want to delete the recruitment request [' + requestNumber + '] ?')) {
      this.spinner.show();
      this.recruitmentRequestService.delete(requestNumber).subscribe(data => {
        this.spinner.hide();
        this._toastr.success('The recruitment request [' + requestNumber + '] was successfully deleted !');
        this.loadRecruitmentRequest();
      }, error => {
        this._toastr.error(error);
        this.spinner.hide();
      });
    }
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  confirm(requestNumber) {
    if (confirm('Are you sure you want to confirm the recruitment request [' + requestNumber + '] ?')) {
      this.spinner.show();
      this.recruitmentRequestService.confirm(requestNumber).subscribe(data => {
        this.spinner.hide();
        this._toastr.success('The recruitment request [' + requestNumber + '] was successfully confirmed !');
        this.loadRecruitmentRequest();
      }, error => {
        this._toastr.error(error);
        this.spinner.hide();
      });
    }
  }


  reject(requestNumber) {
    Swal.fire({
      title: 'Remarks',
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      confirmButtonColor: '#d33'
    }).then((result) => {
      if (result.value) {
        const remark = result.value;
        const recruitmentRequestDto = new RecruitmentRequestDto();
        recruitmentRequestDto.referenceNumber = requestNumber;
        recruitmentRequestDto.rejectRemark = remark;
        this.spinner.show();
        this.recruitmentRequestService.reject(recruitmentRequestDto).subscribe(data => {
          this.spinner.hide();
          this._toastr.success('The recruitment request [' + requestNumber + '] was successfully rejected !');
          this.loadRecruitmentRequest();
        }, error => {
          this._toastr.error(error);
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }

    });
  }

  getStatus(row) {
    const status = row.status;
    const approvalLevel = row.approvalLevel;
    if (status === 'PENDING') {
      return 'Pending';
    } else if (status === 'PASSED') {
      return 'Passed';
    } else if (status === 'CONFIRMING') {
      return 'Confirm ' + approvalLevel;
    } else if (status === 'CONFIRMED') {
      return 'Confirmed';
    } else if (status === 'REJECTED') {
      return 'Rejected';
    }
    return 'N/A';
  }

}
