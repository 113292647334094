<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Provision Inquiry</h4>
        <form [formGroup]="employeeForm">
          <div class="row">
            <div class="col-lg-3 col-sm-12 col-md-6">
              <div class="form-group">
                <input type="hidden" class="form-control" placeholder="" id="id"
                       formControlName="id">
                <input type="hidden" class="form-control" placeholder=""
                       id="matnPmlId" formControlName="pmlId">
                <label>Employee ID:</label>
                <input type="text" class="form-control" placeholder=""
                       id="empNo" formControlName="empNo" (keyup.enter)="searchEmployee()">
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="form-group">
                <label>
                  Employee Name:
                </label>
                <input type="text" class="form-control" placeholder="" id="name"
                       formControlName="name" disabled />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <button class="btn btn-primary btn-full-width">Execute</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  Nationality:
                </label>
                <input type="text" class="form-control" placeholder=""
                       id="nationality" formControlName="nationality" disabled>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-sm-12 col-md-6">
              <div class="form-group">
                <label>
                  Position:
                </label>
                <input type="text" class="form-control" placeholder=""
                       id="position" formControlName="position" disabled>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label>
                  Date of Birth:
                </label>
                <input class="form-control" placeholder="DOB" name="dob"
                       type="text" formControlName="dob" id="dob" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Company Code:</label>
                <input type="text" class="form-control" placeholder=""
                       id="compCode" formControlName="gratuityDays" disabled>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Gratuity Prov.</label>
                <input type="text" class="form-control" placeholder=""
                       id="matnPmlId" formControlName="gratuityProv" disabled>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Date of Joined:</label>
                <input class="form-control" placeholder="" name="doj"
                       formControlName="doj" id="doj" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="form-group">
                <label>
                  Leave Balance Days:
                </label>
                <input type="text" class="form-control" placeholder=""
                       id="levBalDays" formControlName="levBalDays" disabled>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label>
                  Leave Balance Prov.
                </label>
                <input type="text" class="form-control" placeholder=""
                       id="levBalprov" formControlName="levBalprov" disabled>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-12">
              <div class="form-group">
                <label>
                  Ticket Due Days:
                </label>
                <input type="text" class="form-control" formControlName="compCode" placeholder=""
                       id="ticketDueDate" disabled>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Employee Type:</label>
                <input type="text" class="form-control" formControlName="ppEmpTypeId" placeholder=""
                       id="ticketDueDate1" disabled>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
