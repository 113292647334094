import { Routes } from '@angular/router';
import {ManagedocsComponent} from './managedocs/managedocs.component';


export const DocumentTypeRouting: Routes = [
  {
    path: '',
    children: [
      {
        path: 'managedocs',
        component: ManagedocsComponent
      }
    ]
  }
];
