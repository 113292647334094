<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
        <h4 class="card-title">Document Request - Approval
        </h4>
       </div>
       <div class="col-sm-5" *ngIf="selected.length>=1">
        <button style="margin-left: 280px;margin-bottom: 5px;" class="btn btn-primary"[disabled] ="isApproveDisabled" (click)="approveSelected()">Approve</button>
        </div>
        <div class="col-sm-1" *ngIf="selected.length>=1">
          <button style="margin-bottom: 5px;background-color: #ffab01" class="btn btn-primary" [disabled] ="isRejectDisabled" (click)="rejectSelected()">Reject</button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <select (change)="filterHrDocRequests($event)" class="form-control leave-type-section">
              <option value="6" selected>All</option>
              <option value="5">Pending</option>
              <option value="4">Passed</option>
              <option value="3">Manager Approved</option>
              <option value="2">Rejected</option>
              <option value="1">Approved</option>
            </select>
          </div>
        </div>
        <ngx-datatable #table class='material' [columns]="columns" [columnMode]="'force'"
                       [headerHeight]="50"
                       [footerHeight]="50" [rowHeight]="'auto'" [rows]="rows"
                       [selectionType]="'checkbox'" (select)='onSelect($event)'
                       [selectAllRowsOnPage]="false">
          <!-- check box start-->
          <ngx-datatable-column [width]="70" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false"
                                [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value"
                         let-allRowsSelected="allRowsSelected"
                         let-selectFn="selectFn">
              <!-- <label class="datatable-checkbox"> -->
              <input type="checkbox" [checked]="allRowsSelected"
                     (change)="selectFn(!allRowsSelected)"/>
              <!-- </label> -->
            </ng-template>

            <ng-template ngx-datatable-cell-template let-value="value" let-row="row"
                         let-isSelected="isSelected"
                         let-onCheckboxChangeFn="onCheckboxChangeFn">
              <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)"/>
            </ng-template>
          </ngx-datatable-column>
        <!-- checkbox end-->
          <ng-container *ngFor='let column of columns' >


            <ngx-datatable-column *ngIf='column.name === "Document Number"' name="Document Number" prop="docNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.voucherCode}} {{row?.docNo}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Type"' name="Document Type" prop="documentType">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.documentTypeDescription}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Employee"' name="Employee" prop="empNo">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.employeeName}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Status"' name="Document Status" prop="status">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{getStatus(row)}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Document Date"' name="Document Date" prop="docDate">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        {{row?.docDate | date}}
                    </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column *ngIf='column.name === "Actions"' name="Actions" prop="remarks">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div class="confirmation-section"
                     *ngIf="row.passed == 'Y' && row.managerApproved == true && row.approved ==  false && row.rejected ==  false">
                  <button *ngIf="row.currentUserAllowedToApproveCurrentLevel"
                          class="btn btn-primary m-1 " (click)="approve(row?.id)"><i
                      class="fas fa-check"></i></button>
                  <button
                      *ngIf="row.currentUserAllowedToApproveCurrentLevel"
                      class="btn btn-warning m-1 " (click)="reject(row?.id)"><i
                      class="fas fa-times"></i></button>
                  <button class="btn btn-info m-1 " (click)="showDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>
                <div class="approval-section"
                     *ngIf="row.passed == 'Y' && row.managerApproved == false && row.approved ==  false && row.rejected == false ">
                  <button *ngIf="row.currentUserAllowedToManagerApprove" class="btn btn-success "
                          (click)="managerApprove(row.id)"><i class="fas fa-check"></i></button>
                  <button
                      *ngIf="row.currentUserAllowedToManagerApprove"
                      class="btn btn-warning m-1 " (click)="reject(row?.id)"><i
                      class="fas fa-times"></i></button>
                  <button class="btn btn-info m-1 " (click)="showDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>
                <div class="approval-section"
                     *ngIf="row.passed == 'N' || row.approved == true || row.rejected == true">
                  <button class="btn btn-info m-1 " (click)="showDetails(row.id)"><i
                      class="fas fa-info-circle"></i></button>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ng-container>
        </ngx-datatable>
        <div class="pagination-set">
          <mat-paginator (page)="searchPaginated($event)" [length]="totalElements"
                         [pageIndex]="pageIndex"
                         [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"
                         aria-label="Select page"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
