<div class="row">
    <div class="col-lg-12">
        <div class="card bg-light">
            <div class="card-body">
                <div class="d-flex no-block align-items-center m-b-10">
                    <h4 class="card-title">User Details</h4>
                </div>
                <div class="table-responsive">
                    <table class="table bg-white table-bordered nowrap display">
                        <thead>
                            <tr>
                                <th> </th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Role</th>
                                <th>Age</th>
                                <th>Joining date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation2" required>
                                        <label class="custom-control-label" for="customControlValidation2"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="30" /> Genelia Deshmukh</a>
                                </td>
                                <td>genelia@gmail.com</td>
                                <td>+123 456 789</td>
                                <td>
                                    <span class="label label-danger">Designer</span>
                                </td>
                                <td>23</td>
                                <td>12-10-2014</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation3" required>
                                        <label class="custom-control-label" for="customControlValidation3"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" width="30" /> Arijit Singh</a>
                                </td>
                                <td>arijit@gmail.com</td>
                                <td>+234 456 789</td>
                                <td>
                                    <span class="label label-info">Developer</span>
                                </td>
                                <td>26</td>
                                <td>10-09-2014</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation4" required>
                                        <label class="custom-control-label" for="customControlValidation4"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/3.jpg" alt="user" class="rounded-circle" width="30" /> Govinda jalan</a>
                                </td>
                                <td>govinda@gmail.com</td>
                                <td>+345 456 789</td>
                                <td>
                                    <span class="label label-success">Accountant</span>
                                </td>
                                <td>28</td>
                                <td>1-10-2013</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation5" required>
                                        <label class="custom-control-label" for="customControlValidation5"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/4.jpg" alt="user" class="rounded-circle" width="30" /> Hritik Roshan</a>
                                </td>
                                <td>hritik@gmail.com</td>
                                <td>+456 456 789</td>
                                <td>
                                    <span class="label label-inverse">HR</span>
                                </td>
                                <td>25</td>
                                <td>2-10-2017</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation6" required>
                                        <label class="custom-control-label" for="customControlValidation6"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/5.jpg" alt="user" class="rounded-circle" width="30" /> John Abraham</a>
                                </td>
                                <td>john@gmail.com</td>
                                <td>+567 456 789</td>
                                <td>
                                    <span class="label label-danger">Manager</span>
                                </td>
                                <td>23</td>
                                <td>10-9-2015</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation7" required>
                                        <label class="custom-control-label" for="customControlValidation7"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/6.jpg" alt="user" class="rounded-circle" width="30" /> Pawandeep kumar</a>
                                </td>
                                <td>pawandeep@gmail.com</td>
                                <td>+678 456 789</td>
                                <td>
                                    <span class="label label-warning">Chairman</span>
                                </td>
                                <td>29</td>
                                <td>10-5-2013</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation8" required>
                                        <label class="custom-control-label" for="customControlValidation8"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/7.jpg" alt="user" class="rounded-circle" width="30" /> Ritesh Deshmukh</a>
                                </td>
                                <td>ritesh@gmail.com</td>
                                <td>+123 456 789</td>
                                <td>
                                    <span class="label label-danger">Designer</span>
                                </td>
                                <td>35</td>
                                <td>05-10-2012</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation9" required>
                                        <label class="custom-control-label" for="customControlValidation9"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/8.jpg" alt="user" class="rounded-circle" width="30" /> Salman Khan</a>
                                </td>
                                <td>salman@gmail.com</td>
                                <td>+234 456 789</td>
                                <td>
                                    <span class="label label-info">Developer</span>
                                </td>
                                <td>27</td>
                                <td>11-10-2014</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation10" required>
                                        <label class="custom-control-label" for="customControlValidation10"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="30" /> Govinda jalan</a>
                                </td>
                                <td>govinda@gmail.com</td>
                                <td>+345 456 789</td>
                                <td>
                                    <span class="label label-success">Accountant</span>
                                </td>
                                <td>18</td>
                                <td>12-5-2017</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customControlValidation11" required>
                                        <label class="custom-control-label" for="customControlValidation11"></label>
                                    </div>
                                </td>
                                <td>
                                    <a href="javscript:void(0)">
                                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" width="30" /> Sonu Nigam</a>
                                </td>
                                <td>sonu@gmail.com</td>
                                <td>+456 456 789</td>
                                <td>
                                    <span class="label label-inverse">HR</span>
                                </td>
                                <td>36</td>
                                <td>18-5-2009</td>

                                <td>
                                    <button type="button" class="btn btn-sm btn-icon btn-pure btn-outline delete-row-btn" data-toggle="tooltip" data-original-title="Delete">
                                        <i class="ti-close" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>