import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  LEAVE_REQ_ALL,
  LEAVE_REQ_APPROVED,
  LEAVE_REQ_MANAGERAPPROVED,
  LEAVE_REQ_PASSED,
  LEAVE_REQ_PENDING,
  LEAVE_REQ_REJECTED
} from '../../app.constants';

import {Observable} from 'rxjs';
import {ILeaveRequest, ILeaveRequestHeader} from '../model/ileave-request';
import {environment} from '../../../environments/environment';
import {ILeaveType} from '../model/ileave-type';
import {HrpHeadersService} from './hrp-headers.service';
import DateUtils from '../../utils/DateUtils';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LeaveRequestService {


  constructor(private http: HttpClient, private headers: HrpHeadersService) { }

  saveHeader(leaveRequest: ILeaveRequestHeader): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.host + '/api/leave-request/add', leaveRequest, httpOptions);
  }

  saveLeaveRequest(leaveRequest: ILeaveRequest): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.host + '/api/leave-request/add-with-details', leaveRequest, httpOptions);
  }

  getAll() : Observable<ILeaveRequestHeader[]> {
    return this.http.get<ILeaveRequestHeader[]>(environment.host + '/api/leave-request/all');
  }

  searchLeaveRequests(currentUser : any , userCompany : any ,approvalStatus : any , selectedCompany : any , selectedDepartment : any , page: number , size : number): Observable<any> {
    let approvalStatusName: string = this.getApprovalStatusbyCode(approvalStatus);
    let Payload : any = {
      'currentUser': currentUser,
      'userCompany': userCompany,
      'company' :  selectedCompany,
      'approvalStatus' : approvalStatusName,
      'department' : selectedDepartment
    }
    const EndPoint = `${environment.host}/api/leave-request/search?page=${page}&size=${size}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
 }

  
  getByCompany(currentUser : any , userCompany : any ,compCode: any , status , page : number , size : number) : Observable<ILeaveRequestHeader[]> {
    let params = new HttpParams();
    params = params.append('currentUser', currentUser);
    params = params.append('userCompany', userCompany);
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }),
        params : params
      };
      let approvalStatusName: string = this.getApprovalStatusbyCode(status);
      if(approvalStatusName === undefined) approvalStatusName = "pending";
      return this.http.get<ILeaveRequestHeader[]>(environment.host + '/api/leave-request/' + approvalStatusName+'/'+compCode+'?page='+page+'&size='+size, httpOptions);
  }

  checkLeaveRequestDetailLineDates(start: Date, end: Date, empNo: any): Observable<Boolean> {
    let params = new HttpParams();
    params = params.append('start', this.formatDate(start));
    params = params.append('end', this.formatDate(end));
    params = params.append('employee', empNo);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };
    return this.http.get<Boolean>(environment.host + '/api/leave-request/check-dates', httpOptions);
  }

  pass(leaverequestId: number): Observable<ILeaveRequestHeader> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<ILeaveRequestHeader>(environment.host + '/api/leave-request/pass/'+leaverequestId, httpOptions);
  }

  approve(remarks,leaverequestId: number): Observable<ILeaveRequestHeader> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    const userName = SessionObject.glUserMastersDetailsDTO.userCode
    console.log(userName);
    return this.http.get<ILeaveRequestHeader>(environment.host + `/api/leave-request/approve/${leaverequestId}?approvalRemarks=${remarks}&user=${userName}`, httpOptions);
  }


  reject(remarks,leaverequestId: number): Observable<ILeaveRequestHeader> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    const userName = SessionObject.glUserMastersDetailsDTO.userCode
    console.log(userName);
    return this.http.get<ILeaveRequestHeader>(environment.host + `/api/leave-request/reject/${leaverequestId}?rejectionRemarks=${remarks}&user=${userName}`, httpOptions);
  }

  getLeaveTypes(): Observable<ILeaveType[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<ILeaveType[]>(environment.host + '/api/leave-types/all', httpOptions);
  }

  findLeaveRequestByDept(): Observable<ILeaveType[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get<ILeaveType[]>(environment.host + '/api/leave-request/find-by-dates-and-department?start=01/01/2020&end=01/01/202', httpOptions);
  }

  private formatDate(date: Date): string {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate() + '';
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1) + '';
    const year = date.getFullYear() + '';
    return `${day}/${month}/${year}`;
  }

  private getApprovalStatusbyCode(approvalStatusCode): string {
    switch (approvalStatusCode) {
      case LEAVE_REQ_PENDING :
        return 'pending';
      case LEAVE_REQ_MANAGERAPPROVED:
        return 'manager approved';
      case LEAVE_REQ_PASSED:
        return 'passed';
      case LEAVE_REQ_APPROVED :
        return 'approved';
      case LEAVE_REQ_REJECTED:
        return 'rejected';
      case LEAVE_REQ_ALL :
        return 'all';
    }
  }

  saveLeaveTransaction(Payload): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/add-with-details`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  updateLeaveTransaction(Payload): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/update`;
    return this.http.put(EndPoint, Payload, this.headers.getHttpOptions('PUT'));
  }

  generateDocumentCode(Payload, PeriodID): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/generate-doc-no/${PeriodID}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  getLastLeaveRequestByDept(compCode, departmentCode, currentEmpNo , page, size): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/last-leave-requests-by-department/?compCode=${compCode}&departmentCode=${departmentCode}&currentEmpNo=${currentEmpNo}&page=${page}&size=${size}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getOnLeaveByDept(compCode, departmentCode): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/employees-on-leave-by-department/?compCode=${compCode}&departmentCode=${departmentCode}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }
  getLastLeaveTakenByEmployee(employee): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/last-leave-requests-by-employee?empId=${employee}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  } 
  getListOfLeaves(): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/created`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }
  getListOfLeavesByPage(user , company, pageNum , size): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/created/?user=${user}&company=${company}&page=${pageNum}&size=${size}`;
    return this.http.get(EndPoint , this.headers.getHttpOptions('GET'));
  }
  getLeaveRequestById(id , currentUser , userCompany): Observable<any>
  {
    let params = new HttpParams();
    params = params.append('currentUser', currentUser);
    params = params.append('userCompany', userCompany);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      params: params
    };
    const EndPoint = `${environment.host}/api/leave-request/${id}`;
    return this.http.get<ILeaveRequestHeader[]>(EndPoint, httpOptions);
  }

  uploadFiles(FormDataObject: FormData): Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/files/upload`;
    return this.http.post(EndPoint, FormDataObject, this.headers.getHttpOptions('POST', true));
  }

  managerApprove(leaveRequestId) : Observable<any>
  {
    const EndPoint = `${environment.host}/api/leave-request/manager-approve/${leaveRequestId}`;
    return this.http.get(EndPoint, this.headers.getHttpOptions('GET'));
  }

  getAllEmployees(company : any) : Observable<any>
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    const EndPoint = `${environment.host}/api/employees/all-employees/company/${company}`;
    return this.http.get(EndPoint,httpOptions);
  }

  downloadDocument(id: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }),
      responseType: 'blob'
    };
    return  this.http.get(environment.host + '/api/leave-request/download/' + id , {responseType: 'blob', headers: new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('token')}`})});
 
  }


  logOut(){
    const logout = `${environment.host}/api/logout`;
    return 

  }

  searchLeaveReturn(selectedCompany: any, page: number, size: number) {
    const Payload: any = {
      'company': selectedCompany,
    };
    const EndPoint = `${environment.host}/api/leave-return/search?page=${page}&size=${size}`;
    return this.http.post(EndPoint, Payload, this.headers.getHttpOptions('POST'));
  }

  sendPendingLeaveRequestsReport() {
    var date = new Date();
    date.setMonth(date.getMonth() - 2);
    return this.http.post<any>(environment.host + '/api/leave-request/report?from=' + DateUtils.get(date, 'DD-MM-YYYY'), null, this.headers.getHttpOptions('POST'));
  }
}
