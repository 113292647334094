<div class="row">
    <!-- Column -->
    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card">
            <div class="card-body">
                <div class="m-t-30 text-center">
                    <img src="assets/images/users/5.jpg" class="rounded-circle" width="150" />
                    <h4 class="card-title m-t-10">{{Session.UserName}}</h4>
                    <h6 class="card-subtitle">{{profession}} {{companyName}}</h6>
                    <div class="row text-center justify-content-md-center">
                        <div class="col-4">
                            <a href="javascript:void(0)" class="link">
                                <i class="icon-people m-r-10"></i>
                                {{Session.CompanyCode}}
                            </a>
                        </div>
                        <div class="col-4">
                            <a href="javascript:void(0)" class="link">
                                <i class="icon-picture m-r-10"></i>
                                {{empNo}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <hr> </div>
            <div class="card-body">
                <small class="text-muted">Email address </small>
                <h6>{{email || "--"}}</h6>
                <small class="text-muted p-t-30 db">Phone</small>
                <h6>{{phoneNo || '--'}}</h6>
                <small class="text-muted p-t-30 db">Address</small>
                <h6>71 Pilgrim Avenue Chevy Chase, MD 20815</h6>
                <div class="map-box">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470029.1604841957!2d72.29955005258641!3d23.019996818380896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C+Gujarat!5e0!3m2!1sen!2sin!4v1493204785508"
                        width="100%" height="150" frameborder="0" style="border:0" allowfullscreen></iframe>
                </div>
                <small class="text-muted p-t-30 db">Social Profile</small>
                <br/>
                <button class="btn btn-circle btn-secondary">
                    <i class="fab fa-facebook"></i>
                </button>
                <button class="btn btn-circle btn-secondary">
                    <i class="fab fa-twitter"></i>
                </button>
                <button class="btn btn-circle btn-secondary">
                    <i class="fab fa-youtube"></i>
                </button>
            </div>
        </div>
    </div>
    <!-- Column -->
    <!-- Column -->
    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">
            <ngb-tabset type="pills" class="custom-pills">
                <ngb-tab title="Timeline">
                    <ng-template ngbTabContent>
                        <div class="card-body">
                            <div class="profiletimeline">
                                <div class="sl-item">
                                    <div class="sl-left">
                                        <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" />
                                    </div>
                                    <div class="sl-right">
                                        <div>
                                            <a href="#" class="link">John Doe</a>
                                            <span class="sl-date">5 minutes ago</span>
                                            <p>assign a new task
                                                <a href="#"> Design weblayout</a>
                                            </p>
                                            <div class="row">
                                                <div class="col-lg-3 col-md-6 m-b-20">
                                                    <img src="assets/images/big/img1.jpg" class="img-fluid radius" />
                                                </div>
                                                <div class="col-lg-3 col-md-6 m-b-20">
                                                    <img src="assets/images/big/img2.jpg" class="img-fluid radius" />
                                                </div>
                                                <div class="col-lg-3 col-md-6 m-b-20">
                                                    <img src="assets/images/big/img3.jpg" class="img-fluid radius" />
                                                </div>
                                                <div class="col-lg-3 col-md-6 m-b-20">
                                                    <img src="assets/images/big/img4.jpg" class="img-fluid radius" />
                                                </div>
                                            </div>
                                            <div class="like-comm">
                                                <a href="javascript:void(0)" class="link m-r-10">2 comment</a>
                                                <a href="javascript:void(0)" class="link m-r-10">
                                                    <i class="fa fa-heart text-danger"></i> 5 Love</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="sl-item">
                                    <div class="sl-left">
                                        <img src="assets/images/users/2.jpg" alt="user" class="rounded-circle" /> </div>
                                    <div class="sl-right">
                                        <div>
                                            <a href="#" class="link">John Doe</a>
                                            <span class="sl-date">5 minutes ago</span>
                                            <div class="m-t-20 row">
                                                <div class="col-md-3 col-xs-12">
                                                    <img src="assets/images/big/img1.jpg" alt="user" class="img-fluid radius" />
                                                </div>
                                                <div class="col-md-9 col-xs-12">
                                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec
                                                        odio. Praesent libero. Sed cursus ante dapibus diam. </p>
                                                    <a href="#" class="btn btn-success">
                                                        Design weblayout</a>
                                                </div>
                                            </div>
                                            <div class="like-comm m-t-20">
                                                <a href="javascript:void(0)" class="link m-r-10">2 comment</a>
                                                <a href="javascript:void(0)" class="link m-r-10">
                                                    <i class="fa fa-heart text-danger"></i> 5 Love</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="sl-item">
                                    <div class="sl-left">
                                        <img src="assets/images/users/3.jpg" alt="user" class="rounded-circle" /> </div>
                                    <div class="sl-right">
                                        <div>
                                            <a href="#" class="link">John Doe</a>
                                            <span class="sl-date">5 minutes ago</span>
                                            <p class="m-t-10"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent
                                                libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum
                                                imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue
                                                semper
                                            </p>
                                        </div>
                                        <div class="like-comm m-t-20">
                                            <a href="javascript:void(0)" class="link m-r-10">2 comment</a>
                                            <a href="javascript:void(0)" class="link m-r-10">
                                                <i class="fa fa-heart text-danger"></i> 5 Love</a>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="sl-item">
                                    <div class="sl-left">
                                        <img src="assets/images/users/4.jpg" alt="user" class="rounded-circle" /> </div>
                                    <div class="sl-right">
                                        <div>
                                            <a href="#" class="link">John Doe</a>
                                            <span class="sl-date">5 minutes ago</span>
                                            <blockquote class="m-t-10">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Profile">
                    <ng-template ngbTabContent>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3 col-xs-6 b-r">
                                    <strong>Full Name</strong>
                                    <br>
                                    <p class="text-muted">{{Session.UserName}}</p>
                                </div>
                                <div class="col-md-3 col-xs-6 b-r">
                                    <strong>Mobile</strong>
                                    <br>
                                    <p class="text-muted">{{phoneNo || "--"}}</p>
                                </div>
                                <div class="col-md-3 col-xs-6 b-r">
                                    <strong>Email</strong>
                                    <br>
                                    <p class="text-muted">{{email || "--"}}</p>
                                </div>
                                <div class="col-md-3 col-xs-6">
                                    <strong>Location</strong>
                                    <br>
                                    <p class="text-muted">{{location.substring(0, location.indexOf('/'))}}</p>
                                </div>
                            </div>
                            <hr>
                            <p class="m-t-30">Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                                imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer
                                tincidunt.Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.
                                Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.</p>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen book. It has survived not only five centuries
                            </p>
                            <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                and more recently with desktop publishing software like Aldus PageMaker including versions
                                of Lorem Ipsum.</p>
                            <h4 class="font-medium m-t-30">Skill Set</h4>
                            <hr>
                            <h5 class="m-t-30">Wordpress
                                <span class="pull-right">80%</span>
                            </h5>
                            <div class="progress">
                                <div class="progress-bar bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width:80%; height:6px;">
                                    <span class="sr-only">50% Complete</span>
                                </div>
                            </div>
                            <h5 class="m-t-30">HTML 5
                                <span class="pull-right">90%</span>
                            </h5>
                            <div class="progress">
                                <div class="progress-bar bg-info" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width:90%; height:6px;">
                                    <span class="sr-only">50% Complete</span>
                                </div>
                            </div>
                            <h5 class="m-t-30">jQuery
                                <span class="pull-right">50%</span>
                            </h5>
                            <div class="progress">
                                <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="width:50%; height:6px;">
                                    <span class="sr-only">50% Complete</span>
                                </div>
                            </div>
                            <h5 class="m-t-30">Photoshop
                                <span class="pull-right">70%</span>
                            </h5>
                            <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width:70%; height:6px;">
                                    <span class="sr-only">50% Complete</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Settings">
                    <ng-template ngbTabContent>
                        <div class="card-body">
                            <form class="form-horizontal form-material" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                                <div class="form-group">
                                    <label class="col-md-12">Full Name</label>
                                    <div class="col-md-12">
                                        <input formControlName="Fullname" type="text" placeholder="Johnathan Doe" class="form-control form-control-line">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="example-email" class="col-md-12">Email</label>
                                    <div class="col-md-12">
                                        <input formControlName="Email" type="email" placeholder="johnathan@admin.com" class="form-control form-control-line" name="example-email" id="example-email">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-12">Password</label>
                                    <div class="col-md-12">
                                        <input formControlName="Password" type="password" value="password" class="form-control form-control-line">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-12">Phone No</label>
                                    <div class="col-md-12">
                                        <input formControlName="PhoneNo" type="text" placeholder="123 456 7890" class="form-control form-control-line">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-md-12">Message</label>
                                    <div class="col-md-12">
                                        <textarea formControlName="Message" rows="5" class="form-control form-control-line"></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-12">Select Country</label>
                                    <div class="col-sm-12">
                                        <select class="form-control form-control-line" formControlName="Country">
                                            <option>London</option>
                                            <option>India</option>
                                            <option>Usa</option>
                                            <option>Canada</option>
                                            <option>Thailand</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-12">
                                        <button type="submit" class="btn btn-success" [disabled]="!FormData.valid">Update Profile</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
    <!-- Column -->
</div>