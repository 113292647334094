import { Component, OnInit } from '@angular/core';
import {ChartsService} from '../../shared/service/charts.service';
import * as HighCharts from 'highcharts';
import {EmployeesService} from '../../shared/service/employees.service';
import {IEmployee} from '../../shared/model/employee';

@Component({
  selector: 'app-employeesalaryincrement',
  templateUrl: './employeesalaryincrement.component.html',
  styleUrls: ['./employeesalaryincrement.component.css']
})
export class EmployeesalaryincrementComponent implements OnInit {

  chart: any;
  category: any[] = [];
  seriesBasic: any[] = [];
  seriesDataList: any[] = [];

  employees: IEmployee[];
  allEmployees: IEmployee[];
  allMappedEmployees: any[];
  count: any = 10;
  pageSize: any = 8;
  page: any =1;
  config = {
    displayKey: "name",
    search: true,
    limitTo: 7,
  };

  constructor(
    private chartsService: ChartsService,
    private employeeService: EmployeesService
  ) { }

  loadEmployees() {
    this.employeeService.getEmployeePage(this.page,this.pageSize).subscribe(data => {
      this.employees = data.content;
      this.allEmployees = data.content;
      this.count = data.totalPages;
      this.allMappedEmployees = this.employees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
    this.employeeService.getAll().subscribe(data => {
      this.allEmployees = data;
      this.allMappedEmployees = this.allEmployees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
  }

  searchChange($event) {
    /*console.log($event);
    let empNo = $event;
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      this.selectedEmployee = data;
      this.hrdocumentRequestForm = this.createHrdocumentRequestForm(data);
      this.getSelectedEmpDocRequests(empNo);
    })*/
    let empNameOrNumber = $event;

    if (empNameOrNumber !== '' && empNameOrNumber.replace(/\s/g, '').length) {
      console.log(empNameOrNumber);
      if (!isNaN(Number(empNameOrNumber))) {
        this.employeeService.getByEmpNoOrName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      } else {
        this.employeeService.getByName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      }
    } else {
      if (this.allEmployees) {
        this.allMappedEmployees = this.allEmployees.map(dataEmp => {
          return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
        });
      }
    }
  }

  onEmployeeChange(employee){
    console.log(employee);
    console.log(employee.value.value.empNo);
    if (employee.value.value.empNo) {
      this.chartVisualize(employee.value.value.empNo);
    }
  }

  ngOnInit(): void {
    this.loadEmployees();
    //this.chartVisualize(2);
    function groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    }
  }

  chartVisualize(employeeNum) {
    this.chartsService.getEmployeeBySalaryIncrementChart(employeeNum).subscribe(chartData => {
      console.log(chartData);
      let years = chartData['series'];

      let seriesDataArray = [];
      Object.keys(chartData['data']).map(function(key, index) {
        seriesDataArray.push(chartData['data'][key])
        return seriesDataArray;
      });

      seriesDataArray = seriesDataArray.map(seriesAltered => {
        return {type: undefined, name: seriesAltered.name, data: seriesAltered.data};
      });

      let yearsArr = [];
      years.map(yearsData => {
        yearsArr.push(yearsData.toString());
      });

      let dataArraySet = [
        {type: undefined, name: 'Transport Allow', data: [100, 0]},
        {type: undefined, name: 'Mobile Allow', data: [100, 100]}
      ];

      console.log(dataArraySet);
      console.log(seriesDataArray);
      console.log(yearsArr);


      HighCharts.chart('salaryIncrementChart', {
        chart: {
          type: 'column'
        },

        title: {
          text: 'Employee Salary Increments'
        },

        xAxis: {
          categories: years
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: 'Number'
          }
        },

        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },

        series: seriesDataArray
      });
    });
  }

}
