import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MappingComponent} from './mapping/mapping.component'
import { AdminRoutingModule } from './admin-routing.module';
import { MatTreeModule   } from '@angular/material/tree';
import { MatIconModule   } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmployeeAccountComponent } from './employee-account/employee-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApprovalManagementComponent } from './approval-management/approval-management.component';

@NgModule({
  declarations: [MappingComponent, EmployeeAccountComponent, ApprovalManagementComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatTreeModule,
    MatIconModule,
    DataTablesModule, 
    ModalModule.forRoot(),
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AdminModule { }
