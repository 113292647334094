import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class HrpHeadersService {


  constructor(private router: Router, private spinner: NgxSpinnerService) {
  }

  getHttpOptions(Method: string, DisableContentType: boolean = false) {
    this.checkTokenValidity();
    let headerObject = new HttpHeaders();
    let token = `Bearer ${localStorage.getItem('token')}`;

    if (!DisableContentType) {
      headerObject = headerObject.append(
          'Content-Type',
          'application/json'
      );
    }

    headerObject = headerObject.append(
        'Authorization',
        token
    );
    headerObject = headerObject.append('Authorization-Type', 'Preemptive');
    headerObject = headerObject.append(
        'Access-Control-Allow-Headers',
        'Content-Type'
    );
    headerObject = headerObject.append('Access-Control-Allow-Methods', Method);
    headerObject = headerObject.append('Access-Control-Allow-Origin', '*');
    const httpOptions = {
      headers: headerObject,
    };
    return httpOptions;
  }

  private getDecodedAccessToken(): any {
    const token = localStorage.getItem('token');
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  checkTokenValidity() {
    if (new Date().getTime() / 1000 > this.getDecodedAccessToken()?.exp) {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['']);
      this.spinner.hide();
    }
  }
}
