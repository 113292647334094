import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EmployeesService} from '../../shared/service/employees.service';
import {ApiService} from '../../shared/service/api.service';
import {LocalstorageService} from '../../shared/service/localstorage.service';
import {VoucherService} from '../../shared/service/voucher.service';
import {LeaveRequestService} from '../../shared/service/leave-request.service';
import {IEmployee} from '../../shared/model/employee';
import {IVoucher} from '../../shared/model/ivoucher';
import {ILeaveType} from '../../shared/model/ileave-type';
import {ILeavePreparation} from '../../shared/model/leave-request.model';
import {ILeaveRequest, ILeaveRequestDetails, ILeaveRequestHeader} from '../../shared/model/ileave-request';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {DataTableDirective} from 'angular-datatables';
import {HR_CORDINATOR_USER_ROLE} from 'src/app/app.constants';
import {AuthService} from 'src/app/shared/service/auth.service';
import DateUtils from '../../utils/DateUtils';

interface SessionObject{
  CompanyCode?: string;
  BranchCode?: string;
  Token?: string;
  FinancialCode?: string;
  FYearID?:string;
  DocumentCode?: string;
  ModuleCode?: string;
  UserName?: string
}

interface SavePayload {
  header: Header;
  details: Detail[];
}

interface Detail {
  id?: number,
  fromDate: string;
  toDate: string;
  numberOfDays: number;
  leaveTypeId: number;
  createdBy: string;
}
export interface PopupTable{
  Code: string;
  EmpNo: string;
  Name: string;
  Selected: boolean;
  RowObject?: any;
}
interface Header{
  id?:number,
  empId: number;
  pmlId: number;
  compCode: string;
  fyerId: number;
  vtypeDocType: string;
  vtypeModsModule: string;
  code: string;
  period: number;
  brncCode: string;
  remarks: string;
  createdBy: string;
  docNo: number;
  rejectRemarks: string;
  carrier: string;
  address: string;
  reason: string;
  travelDate: string;
  telephone: string;
  subEmpId: number;
  subPmlId: number;
  housingNumber: string;
  street: string;
  city: string;
  state: string;
  region: string;
}

@Component({
  selector: 'app-leaverequest',
  templateUrl: './leaverequest.component.html',
  styleUrls: ['./leaverequest.component.css']
})
export class LeaverequestComponent implements OnInit {
  previousVal: any;
  @ViewChild('LeaveTypeModal') leaveTypeModal;
  PeriodList: any[] = [];
  VourcherList: any[] = [];
  SelectedPeriod: any = {};
  show: boolean = false;
  PopUpTableData: PopupTable[] = [];
  leaveRequestHeaderForm: FormGroup;
  leaveRequestDetailsForm: FormGroup;
  employee: IEmployee;
  vouchers: IVoucher[];
  leavetypes: ILeaveType[];
  reqDetToDate = '';
  selectedLeavetype: any;
  formValidity: boolean;
  currentLeaveType: any;
  selectedEmployee: any;
  allMappedEmployees: any[];
  employees: IEmployee[];
  allEmployees: IEmployee[];  CurrentDate: string = moment().format('DD-MM-YYYY').toString();
  // allEmployees: IEmployee[];  CurrentDate: string = DateUtils.get();
  CurrentDatePopulate: string = moment().format('YYYY-MM-DD').toString();
  // CurrentDatePopulate: string = DateUtils.get();
  savedHeader: number;
  RejectedFlag: boolean = false
  PassedFlag: boolean = false
  ConfirmedFlag: boolean = false
  managerApproveFlag: boolean = false
  canAddLeaveSectionRowFlag = true;
  ConfirmedCheckbox: boolean = false
  currentEmployeeId: any;
  isCurrentUserAllowedToApprove: any = false;
  currentUserAllowedToApproveCurrentLevel = false;
  currentLeaveRequest : any;
  NewFieldsObject = {
    TravelDate: '',
    TelephoneNumber: '',
    Carrier: '',
    AbsentDays: '7',
    Reason: '',
    HouseNumber: '',
    Street: '',
    City: '',
    State: '',
    Region: '',
    SubEmployeeId: '',
    SubEmployeeNo: '',
    SubEmployeeName: ''
  };
  DocumentNumber: string = '';
  enablePassBtn : boolean = false;
  pageSize: any = 8;
  page: any = 1;
  count: any = 10;
  searchedEmployee = '';

  empFormData: FormGroup;
  section2: boolean = false;
  isSaveEnabled : boolean= true;
  isSubmitted: boolean = false;
  isCorrectLeave : boolean = true;
  hrCoordinatorEmployees : any =[];
  config = {
    displayKey: "name",
    search: true,
    limitTo: 7,
  };

  Session: SessionObject = {
    BranchCode: "",
    FinancialCode: "",
    CompanyCode: "",
    ModuleCode: "",
    DocumentCode: "LR",
    FYearID:""
  };
  SelectedVoucher: {docType:string; code:string; description:string}={docType:'', code:'', description:''};
  DataObject = {
    "Body": {
      1:{}
    },
    "Remarks":""
  }
  BodyRows: number[] = [1]
  CurrentRow: number;
  SumNumOfDays: number = 0;
  OrginalFileList
  FilesList
  Flag_Details_Add: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) private datatableElement: DataTableDirective;
  disableVoucherPeriod: boolean = true;

  @ViewChild("configModal") public ConfigModal: ModalDirective;
  constructor(
    private formBuilder: FormBuilder,
    private employeeService: EmployeesService,
    private voucherService: VoucherService,
    private leaveRequestService: LeaveRequestService,
    private apiService: ApiService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService : AuthService,
    private localstorage: LocalstorageService
  ) { }

  id:any
  source:any;
  empNo:any
  empName:any
  CompaniesList: any[] = [];
  currentUserInformations : any = {};
  isEmployeePopupDisabled : boolean = false;
  setCompanyDropDown;
  isSection3Visible : boolean = false;
  leaveRequestApplyDate = new Date();

  documentSaved: boolean;
  documentPassed: boolean;
  documentRejected : boolean;
  documentApproved : boolean;
  documentManagerApprove: boolean;

  //Stepper variables
  isReviewMode: boolean;
  currentStep: number;

  levBalDays: Number;

  ngOnInit(): void {
    this.currentStep = 0;
    this.empFormData = this.formBuilder.group({
      empId: new FormControl('', [Validators.required]),
      empName: new FormControl('', [Validators.required])
    });

    this.initializeSessionObject();
    this.CompaniesList = JSON.parse(localStorage.getItem('Companies'))

    const local = this.localstorage.getLocalStorageData();
    if( this.authService.isSelfServiceUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()){
      this.setCompanyDropDown = this.CompaniesList.map(x=>x.code).indexOf(local.glUserMastersDetailsDTO.compCode) ;
      this.loadVoucherCodes();
    }
    if(this.authService.isHrCoordinatorUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()){
      this.isSection3Visible = true;
    }
    if (this.activatedRoute.snapshot.queryParams['id']) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.id = params['id'];
        this.source = params['src'];
      });
    }else{
      this.id = ''
    }

    if (this.id == 'null' || this.id == '') {
      this.isReviewMode = false;
      if (this.authService.isHrCoordinatorUser()) {
        this.isReviewMode = true;
      }
      this.show = false;
      this.Flag_Details_Add = false;
      this.documentSaved = false;
    } else {
      this.isReviewMode = false;
      if (this.authService.isHrCoordinatorUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()) {
        this.isReviewMode = true; // Stepper should not be displayed to Hr Coordinator and Line Manager Users
      }
      this.getLeaveRequestById(this.id);
      this.show = true;
      this.Flag_Details_Add = true;
      this.documentSaved = true;
    }

    this.loadVouchers();
    this.loadLeaveTypes();
    //this.loadEmployees();
    //this.loadVoucherCodes();

    if (this.authService.getcurrentUserRoles().find(x => x === HR_CORDINATOR_USER_ROLE))
      this.loadHrCoordinatorEmployees();


    this.leaveRequestHeaderForm = this.createLeavePreparationForm(null);
    this.leaveRequestDetailsForm = this.createLeaveRequestDetailsForm(null);
    this.formValidity = false;
    this.checkValidity();
    this.fillCurrentUserInformations();
  }

  /**Stepper functions*/
  stepsData = [
    { name: "Leave Type", requirement: () => this.canAddLeaveSectionRowFlag },
    { name: "Step 2", requirement: () => !this.NewFieldsObject.SubEmployeeId },
    { name: "Step 3", requirement: () => false },
    { name: "Step 4", requirement: () => false }
  ];
  canGoNextStep() {
    return this.stepsData[this.currentStep].requirement();
  }
  nextStep() {
    if (this.currentStep < this.stepsData.length) this.currentStep++;
  }
  previousStep() {
    if (this.currentStep > 0) this.currentStep--;
  }
  canGoToStep(index) {
    for (let i = 0; i < index; i++) {
      if (this.stepsData[i].requirement()) return false;
    }
    return true;
  }
  setStep(index) {
    if (this.canGoToStep(index)) {
      if (index >= 0 && index <= this.stepsData.length - 1) this.currentStep = index;
    }
  }
  /**End of stepper functions */


  enableSave() {
    if(this.empFormData.valid) {
      if(this.section2) {
        return false;
      }
    }
     return true;
  }
  selectedEmployeeName = "Select Employee";
  fetchedempNo = "";
  SubEmployee:any
  private initializeSessionObject() {
    let SessionObject = JSON.parse(localStorage.getItem("loginsessiondata"));
    this.Session.Token = SessionObject.id_token;
    this.Session.BranchCode = SessionObject?.glUserMastersDetailsDTO?.branchCode;
    this.Session.CompanyCode = SessionObject?.glUserMastersDetailsDTO?.compCode;
    this.Session.DocumentCode = "JV";
    this.Session.ModuleCode = "PP";
    this.Session.BranchCode = "01";
    this.Session.UserName = SessionObject.glUserMastersDetailsDTO.userCode;
    this.Session.FYearID = SessionObject.glUserMastersDetailsDTO.currentFinancialYear;
    this.loadPeriods(this.Session);
  }

  private fillCurrentUserInformations() {
    let currentUser = this.authService.getCurrentUserInformations();
    this.currentUserInformations = currentUser;
    if (this.authService.isSelfServiceUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()) {
      this.isEmployeePopupDisabled = true;
      this.selectedEmployeeName = currentUser?.employee?.name;
      this.fetchedempNo = currentUser?.employee?.empNo;
      // this.selectedEmployeeName = currentUser?.name;
      this.selectedEmployee = currentUser?.employee;
      this.employee = currentUser.employee;
      this.selectedEmployee = {employeeIdentity:{id:null, pmlId:null}}
      this.selectedEmployee.employeeIdentity.id  = currentUser?.employee.id;
      this.selectedEmployee.employeeIdentity.pmlId = currentUser?.employee.pmlId;
      this.fillLeaveRequestForm(currentUser?.employee);
    }
  }

  popUpTableValueSelected(row) {
  if(this.EmployeeSelection == 1){
    this.selectedEmployeeName = row.Name;
    this.PopUpTableData.forEach(x=>x.Selected = false)
    this.selectedEmployee = {employeeIdentity:{id:null, pmlId:null}}
    this.selectedEmployee.employeeIdentity.id  = row.RowObject.id
    this.selectedEmployee.employeeIdentity.pmlId = row.RowObject.pmlId
    this.spinner.show()
    this.employeeService.getByEmpNo(row.RowObject.empNo).subscribe(data => {
      this.spinner.hide()
      this.employee = data;
      this.fillLeaveRequestForm(data);
    },error=>{
      this.spinner.hide()
    });
    row.Selected = true;
  }
  if(this.EmployeeSelection == 2){
    this.PopUpTableData.forEach(x=>x.Selected = false)
    this.NewFieldsObject.SubEmployeeId = row.Code;
    this.NewFieldsObject.SubEmployeeNo = row.EmpNo;
    this.NewFieldsObject.SubEmployeeName = row.Name;
    row.Selected = true;
    this.spinner.show()
    this.employeeService.getByEmpNo(row.RowObject.empNo).subscribe(data => {
      this.SubEmployee = data;
      this.spinner.hide()
    },error=>{
      this.spinner.hide()
    });
  }
  this.ConfigModal.hide();
  }
  flag = false;
  EmployeeSelection;

  selectEmployee(pEmployeeSelection){
    let employeeOnLeaveSelectopn : number = 1;
    let handoverEmployeeSelection : number = 2;

    if (this.ConfirmedFlag) {
      return
    }

    if (pEmployeeSelection == employeeOnLeaveSelectopn) {
      if (this.authService.isSelfServiceUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser())
        return;
      if (this.authService.isHrCoordinatorUser()) {
        // this.fillEmployeeSelectorWithHrCoordinatorEmployees(pEmployeeSelection);
        this.fillEmployeeSelectorWithEmployees(pEmployeeSelection);
      } else {
        this.fillEmployeeSelectorWithEmployeesOnSameDepartment(pEmployeeSelection);
      }
    } else if (pEmployeeSelection == handoverEmployeeSelection) {
      if (this.authService.isSelfServiceUser() || this.authService.isLinemanagerUser() || this.authService.isErp_ConfirmationUser()) {
        this.fillEmployeeSelectorWithEmployeesOnSameDepartment(pEmployeeSelection);
      } else if (this.authService.isHrCoordinatorUser()) {
        this.fillEmployeeSelectorWithEmployees(pEmployeeSelection);
        // this.fillEmployeeSelectorWithHrCoordinatorEmployees(pEmployeeSelection);

      }
    }

    if(!this.flag)
      this.dtTrigger.next();
    else
      this.rerender()

    this.flag = true
    this.ConfigModal.show();
    this.spinner.hide()

  }

  private fillEmployeeSelectorWithEmployeesOnSameDepartment(pEmployeeSelection: any) {
    this.EmployeeSelection = pEmployeeSelection;
    this.PopUpTableData = [];
    this.spinner.show();
    let employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
    const local = this.localstorage.getLocalStorageData();
    let deptCode = local ? local.glUserMastersDetailsDTO?.employee?.departement?.code : null;
    if (employeesData && deptCode) {
      employeesData.forEach((element: any) => {
        if (element.departmentCode === deptCode && this.NewFieldsObject?.SubEmployeeId !== element.id && this.selectedEmployee?.employeeIdentity?.id !== element.id) {
          this.PopUpTableData.push({
            Code: element.id,
            EmpNo: element.empNo,
            Name: element.name,
            Selected: false,
            RowObject: element
          });
        }
      });
    }
  }

  private fillEmployeeSelectorWithEmployees(pEmployeeSelection: any) {
    this.EmployeeSelection = pEmployeeSelection;
    this.PopUpTableData = [];
    this.spinner.show();
    const employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
    if (employeesData) {
      employeesData.forEach((element: any) => {
        if (this.NewFieldsObject?.SubEmployeeId !== element.id && this.selectedEmployee?.employeeIdentity?.id !== element.id) {
          this.PopUpTableData.push({
            Code: element.id,
            EmpNo: element.empNo,
            Name: element.name,
            Selected: false,
            RowObject: element
          });
        }
      });
    }
  }

  updateCheck(oldData: any, newData: any) {

    Object.keys(oldData.header).forEach(keys => {
      if (oldData.header[keys] == null) {
        oldData.header[keys] = '';
      }
    });


    if (oldData.header.housingNumber != newData.header.housingNumber) {
      return true;
    }
    if (oldData.header.street != newData.header.street) {
      return true;
    }

    if (oldData.header.state != newData.header.state) {
      return true;
    }

    if (oldData.header.city != newData.header.city) {
      return true;
    }

    if (oldData.header.region != newData.header.region) {
      return true;
    }


    if (oldData.header.subsituteemployeeWsDTO.id != newData.header.subEmpId) {
      return true;
    }

    if (oldData.header.travelDate != newData.header.travelDate) {
      return true;
    }


    if (oldData.header.carrier != newData.header.carrier) {
      return true;
    }

    if (oldData.header.telephone != newData.header.telephone) {
      return true;
    }


    if (oldData.header.reason != newData.header.reason) {
      return true;
    }

    if (oldData.details.length !== newData.details.length) {
      return true;
    }

    if (oldData.details[0].leaveType.id != newData.details[0].leaveTypeId) {
      return true;
    }

    if (oldData.details[0].fromDate != moment(newData.details[0].fromDate, 'YYYY-MM-DD').format('DD-MM-YYYY')) {
      return true
    }

    if (oldData.details[0].numberOfDays != newData.details[0].numberOfDays) {
      return true
    }


    return false

  }


  private pushPopUpTableDataElement(element: any) {
    this.PopUpTableData.push({
      Code: element.id,
      EmpNo: element.empNo,
      Name: element.name,
      Selected: false,
      RowObject: element
    });
  }

  private fillEmployeeSelectorWithAllEmployees(pEmployeeSelection: any) {
    this.EmployeeSelection = pEmployeeSelection;
    this.PopUpTableData = [];
    this.spinner.show();
    let employeesData = localStorage.getItem('AllEmployees') ? JSON.parse(localStorage.getItem('AllEmployees')) : null;
    const local = this.localstorage.getLocalStorageData();
    let deptCode = local ? local.glUserMastersDetailsDTO?.employee?.departement?.code : null;
    if (employeesData && deptCode) {
      employeesData.forEach((element: any) => {
          this.PopUpTableData.push({
            Code: element.id,
            EmpNo : element.empNo,
            Name: element.name,
            Selected: false,
            RowObject: element
          });
      });
    }
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  checkDateValidations(event){
    this.section2 = true;
    if ( moment(event).isBefore(moment(this.CurrentDatePopulate).add(-3, 'days'))) {
     // this.section2 = false
      this._toastr.error('The Minimum authorized date is today\'s date - 3');
      setTimeout(() => {
        this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]].StartDate = this.CurrentDatePopulate;
      }, 500);
    }
    if(!!this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]]?.StartDate)
    {

      let LastStartDate = this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-2]]?.StartDate;
      let LastEndDate = moment(moment(LastStartDate,'YYYY-MM-DD').add(this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-2]]?.NoOfDays,'days')).format('YYYY-MM-DD')
      if(!isNaN(Date.parse(LastEndDate)) && !moment(event).isSame(LastEndDate)){
      //  this.section2 = false
        this._toastr.error('Date ranges cannot overlap');
        setTimeout(() => {
          this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]].StartDate = LastEndDate;
        }, 500);
      }
    }
  }

  addBodyRow(){
    this.isCorrectLeave = true;
    let LastDataObject:any = this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]]
      if( !LastDataObject?.LeaveType || !LastDataObject?.NoOfDays || !LastDataObject?.StartDate ){
        this.isCorrectLeave = false;
        return
      }

    this.BodyRows.push(this.BodyRows[this.BodyRows.length-1]+1);
    this.DataObject.Body[this.CurrentRow+1] = {"StartDate":'', "NoOfDays":0, };
    this.levBalDays = null;
  }

  deleteFile(name, lastModified){
    let Temp = Array.from(this.FilesList);
    this.FilesList = Temp.filter( (x:any) => {
      return ( x.name != name && x.lastModified != lastModified)
    })
  }

  FilesDateList: any[] = [];

  filesUploaded(e) {
    this.OrginalFileList = e.target.files;
    Array.from(e.target.files).forEach((x:any)=>x.Description = "");
    this.FilesList = e.target.files;
    this.FilesDateList = []
    for(let i=0; i<this.FilesList.length; i++){
      this.FilesDateList.push(moment(new Date(this.FilesList[i].lastModifiedDate.toString()).toISOString()).format('YYYY-MM-DD HH:MM:SS'))
    }
  }

  passTransaction(){
    //TODO: ONCE API return 200, these properties should be on success
    this.spinner.show();
    this.documentPassed = true;
    this.enablePassBtn = false;
    this.enablePassBtn = false;
    this.ConfirmedFlag = true;
    this.isSaveEnabled = false;
    this.PassedFlag = true;
    this.leaveRequestService.pass(this.savedHeader).subscribe(data => {
      this.spinner.hide();
      this._toastr.success("Leave request has been Passed");
      this.router.navigate(['/leaves/list']);
    },err=>{
      this.spinner.hide();
      this._toastr.error("Error Occurred")
    });
  }



  saveTransaction(shouldBePass: boolean) {
    if (this.id && this.id != 'null' && this.id != '') {
      this.saveTransaction2(this.id, true, shouldBePass);
    } else {
      this.saveTransaction2(this.id, false, shouldBePass);
    }
    this.documentSaved = true;
  }

  saveTransaction2(RequestID: string, UpdateFlag: boolean, shouldBePass: boolean) {

    this.spinner.show();
    this.isSubmitted = true;
    if(this.empFormData.invalid){
      return
    }
    this.isSaveEnabled = false;
    let Payload: SavePayload ={
      header:{
        "empId" : this.selectedEmployee.employeeIdentity.id ? this.selectedEmployee.employeeIdentity.id : null,
        "pmlId" : this.selectedEmployee.employeeIdentity.pmlId ? this.selectedEmployee.employeeIdentity.pmlId : null,
        "compCode": this.Session.CompanyCode ? this.Session.CompanyCode : null,
        "fyerId":this.SelectedPeriod.FinancialYearID ? this.SelectedPeriod.FinancialYearID : null,
        "vtypeModsModule": this.Session.ModuleCode ? this.Session.ModuleCode : null,
        "vtypeDocType": this.SelectedVoucher.docType ? this.SelectedVoucher.docType : null,
        "code":this.SelectedVoucher.code? this.SelectedVoucher.code : null,
        "period" : this.SelectedPeriod.Id ? this.SelectedPeriod.Id : null,
        "brncCode" : this.Session.BranchCode ? this.Session.BranchCode : null,
        "remarks" : this.leaveRequestHeaderForm.controls['remarks'].value ? this.leaveRequestHeaderForm.controls['remarks'].value : null,
        "createdBy" : this.Session.UserName ? this.Session.UserName : null,
        "docNo": Number.parseInt(this.DocumentNumber),
        "rejectRemarks": "",
        "carrier"      :this.NewFieldsObject.Carrier,
        "address"     :"",
        "reason"      :this.NewFieldsObject.Reason,
        "travelDate"  :this.NewFieldsObject.TravelDate,
        "telephone"   :this.NewFieldsObject.TelephoneNumber,
        "subEmpId"    :this.SubEmployee?.id,// this was null so added ?
        "subPmlId"    :this.SubEmployee?.pmlId,
        "housingNumber"  : this.NewFieldsObject.HouseNumber,
        "street"         : this.NewFieldsObject.Street,
        "city"           : this.NewFieldsObject.City,
        "state"          : this.NewFieldsObject.State,
        "region"         : this.NewFieldsObject.Region
      },
      details:[]
    }
    Object.keys(this.DataObject.Body).forEach(BodyObject=>{
      if(this.DataObject.Body[BodyObject]?.StartDate && this.DataObject.Body[BodyObject]?.LeaveType?.id && this.DataObject.Body[BodyObject]?.NoOfDays)
      Payload.details.push({
        "fromDate": this.DataObject.Body[BodyObject]?.StartDate ? this.DataObject.Body[BodyObject]?.StartDate : null,
        "leaveTypeId" : this.DataObject.Body[BodyObject]?.LeaveType?.id ? this.DataObject.Body[BodyObject]?.LeaveType?.id : null,
        "createdBy" :  this.Session.UserName ? this.Session.UserName : null,
        "numberOfDays": this.DataObject.Body[BodyObject]?.NoOfDays ? this.DataObject.Body[BodyObject]?.NoOfDays : null,
        "toDate":  moment(moment(this.DataObject.Body[BodyObject]?.StartDate,'YYYY-MM-DD').add(this.DataObject.Body[BodyObject]?.NoOfDays-1,'days')).format('YYYY-MM-DD')
      })
    })

    if(!UpdateFlag){
      this.spinner.show();
    this.leaveRequestService.saveLeaveTransaction(Payload).subscribe(data=>
      {
        this.id = data.header.id;
        this.currentEmployeeId = data.header.empId;
        this.isSaveEnabled = true;
        this.spinner.hide();
        this._toastr.success("Leave Request has been saved");
        this.enablePassBtn = true;
        this.savedHeader = data?.header?.id;
        this.DocumentNumber = data?.header?.docNo;
        let request = {
          leaveRequestHeaderId: this.savedHeader,
          files : []
        }

        if(this.FilesList && this.FilesList.length>0){
          for(let i=0;i<this.FilesList.length; i++){
            request.files.push({createdBy: this.Session.UserName, description: this.FilesList[i].Description })
          }
          let SendObject: FormData = new FormData();
          SendObject.append('request', JSON.stringify(request))
          for(let i=0;i<this.FilesList.length; i++){
            SendObject.append('files', this.FilesList[i])
          }
          this.leaveRequestService.uploadFiles(SendObject).subscribe(data=>{})
          }
        if (shouldBePass) {
          this.passTransaction();
        } else {
          this.router.navigate(['/leaves/list']);
        }
        },error=>{
          this.isSaveEnabled = true;
          this.spinner.hide();
          this._toastr.error('Error in Requesting Leave');
        }
      )}
      else {
        this.isSaveEnabled = true;
        let isChanged: boolean  = this.updateCheck(this.previousVal,Payload)

        if (!isChanged) {
          this.spinner.hide();
          this._toastr.error("Unchanged Leave Request")
        } else {
        Payload.header.id= Number.parseInt(RequestID)
        Payload.details.forEach(detail => {
            detail.id = Number.parseInt(RequestID)
          })
          this.spinner.show();
        this.leaveRequestService.updateLeaveTransaction(Payload).subscribe(data=>
          {
            this.isSaveEnabled = true;
            this.spinner.hide();
            this._toastr.success("Leave Request has been updated");
            this.enablePassBtn = true;
            this.savedHeader = data?.id;
            this.DocumentNumber = data?.docNo;
            let request = {
              leaveRequestHeaderId: this.savedHeader,
              files : []
            }

            if(this.FilesList && this.FilesList.length>0){
              for (let i = 0; i < this.FilesList.length; i++) {
                request.files.push({createdBy: this.Session.UserName, description: this.FilesList[i].Description});
              }
              let SendObject: FormData = new FormData();
              SendObject.append('request', JSON.stringify(request));
              for (let i = 0; i < this.FilesList.length; i++) {
                SendObject.append('files', this.FilesList[i]);
              }
              this.leaveRequestService.uploadFiles(SendObject).subscribe(data => {
              });
            }
            if (shouldBePass) {
              this.passTransaction();
            } else {
              this.router.navigate(['/leaves/list']);
            }
          },error=>{
              this.isSaveEnabled = true;
              this._toastr.error('Error in Requesting Leave');
            }
          )
        }
      }
  }

  deleteBodyRow(row){
    delete this.DataObject.Body[row.toString()];
    this.BodyRows = this.BodyRows.filter(x=>x!=row);
    this.CurrentRow = Number.parseInt(Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]);
  }

  fetchEndDate(row){
    let StartDate = this.DataObject.Body[row.toString()]?.StartDate,
        NumberOfDays = this.DataObject.Body[row.toString()]?.NoOfDays
    if(!StartDate || !NumberOfDays)
      return '';
     return moment(moment(StartDate,'YYYY-MM-DD').add((NumberOfDays-1),'days')).format('YYYY-MM-DD');
  }

  private remarksForApprove () {
    Swal.fire({
      title: "Remarks",
      input: 'textarea',
      showCancelButton: true,
      confirmButtonText:'Approve',
    }).then((result) => {
      if (result.value) {
        const remarks = result.value;
        this.approve(remarks,this.id);
      }
    });
  }

  private approve(remarks: string, leaveRequestId: number) {
    this.spinner.show();
    this.leaveRequestService.approve(remarks, leaveRequestId).subscribe(
        data => {
          this.spinner.hide();
          this._toastr.success('Leave request has been Approved');
          this.router.navigate(['/leaves/leaveapprove']);
        },
        error => {
          this.spinner.hide();
          this._toastr.error(error.error);
        });
  }

  managerApprove() {
    this.spinner.show();
    this.leaveRequestService.managerApprove(this.id).subscribe(data => {
      this.spinner.hide();
      this._toastr.success('Leave request has been approved');
      this.router.navigate(['/leaves/leaveapprove']);
    });
  }

  setVoucherCode(e){
    const VoucherString: string = e.target.value;
    this.SelectedVoucher.docType = VoucherString.split('|')[0] ;
    this.SelectedVoucher.code = VoucherString.split('|')[1] ;
    this.SelectedVoucher.description = VoucherString.split('|')[2] ;
  }

  setCompany(e) {
    const CompnayCode: string = e.target.value;
    this.Session.CompanyCode = CompnayCode;
    this.loadVoucherCodes();
    this.disableVoucherPeriod = false;
  }

  LeaveReqByIdList: any = [];

  private fillEmployeeSelectorWithHrCoordinatorEmployees(pEmployeeSelection: any) {
    this.EmployeeSelection = pEmployeeSelection;
    this.PopUpTableData = [];
    if (pEmployeeSelection == 1) {
      this.hrCoordinatorEmployees.forEach((element: any) => {
        this.pushPopUpTableDataElement(element);
      });
    } else if (this.employee != null) {
      this.hrCoordinatorEmployees.forEach((element: any) => {
        if (element.empNo != this.employee.empNo) {
          this.pushPopUpTableDataElement(element);
        }
      });
    }
  }

  isOwnerOfDocument(): boolean {
    if (this.authService.isHrCoordinatorUser()) {
      return true;
    }
    const currentUserId = this.authService.getCurrentUserInformations().employee.id;
    if (this.id === 'null') {
      return true;
    }
    return currentUserId === this.currentEmployeeId;
  }

  isOwnerOfRequest(): boolean {
    const currentUserId = this.authService.getCurrentUserInformations().employee.id;
    if (this.id === 'null') {
      return true;
    }
    return currentUserId === this.currentEmployeeId;
  }

  isSaveDisabled(): boolean {
    if (!this.isOwnerOfDocument()) {
      return true;
    }
    return this.documentPassed;
  }

  isPassDisabled(): boolean {
    if (!this.isOwnerOfDocument()) {
      return true;
    }
    return !this.documentSaved || this.documentPassed;
  }

  isSubmitEnabled(): boolean {
    if (!this.isOwnerOfDocument()) {
      return false;
    }
    if (this.documentSaved) {
      return !this.documentPassed;
    }
    return !this.documentSaved;
  }

  canConfirm(): boolean {
    if (!this.currentUserAllowedToApproveCurrentLevel || this.documentApproved || this.documentRejected || !this.documentPassed || !this.documentManagerApprove) {
      return false;
    }
    return true;
  }

  canApprove(): boolean {
    if (!this.isCurrentUserAllowedToApprove || this.documentApproved || this.documentRejected || !this.documentPassed || this.documentManagerApprove) {
      return false;
    }
    return true;
  }

  getLeaveRequestById(id){
    this.leaveRequestService.getLeaveRequestById(id,this.Session.UserName , this.Session.CompanyCode).subscribe(data => {
      this.LeaveReqByIdList = data;
      this.previousVal = data;
      this.RejectedFlag = this.LeaveReqByIdList.header.rejected === 'Y'? true : false ;
      this.ConfirmedFlag = this.LeaveReqByIdList.header.passed === 'Y'? true : false ;
      this.PassedFlag = this.LeaveReqByIdList.header.passed === 'Y'? true : false ;
      this.ConfirmedCheckbox = this.LeaveReqByIdList.header.confirmed === 'Y'? true : false ;
      this.managerApproveFlag = this.LeaveReqByIdList.header.managerApproved ;
      this.documentPassed = this.PassedFlag;
      this.documentRejected = this.RejectedFlag;
      this.documentApproved = this.ConfirmedCheckbox;
      this.documentManagerApprove = this.managerApproveFlag;
      if(!this.authService.isHrCoordinatorUser() && this.localstorage.getLocalStorageData().glUserMastersDetailsDTO.employee.id !== data?.header?.employeeWsDTO?.id)
      this.ConfirmedFlag = true;
      this.isSaveEnabled = this.LeaveReqByIdList.header.passed === 'Y'? false : true ;
      this.currentEmployeeId = this.LeaveReqByIdList.header.employeeWsDTO.id;
      this.isCurrentUserAllowedToApprove = this.LeaveReqByIdList.header.currentUserAllowedToApprove;
      this.currentUserAllowedToApproveCurrentLevel = this.LeaveReqByIdList.header.currentUserAllowedToApproveCurrentLevel;
      for (let i = 0; i < this.LeaveReqByIdList.details.length; i++) {
        this.DataObject.Body[(i + 1).toString()] = {
          'LeaveType': this.LeaveReqByIdList.details[i].leaveType,
          'NoOfDays': this.LeaveReqByIdList.details[i].numberOfDays,
          'StartDate': moment(this.LeaveReqByIdList.details[i].fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
          // "StartDate":  DateUtils.get(this.LeaveReqByIdList.details[i].fromDate,'YYYY-MM-DD'),
        };
      }
      this.canAddLeaveSectionRowFlag = false;
      this.DocumentNumber = this.isNull(this.LeaveReqByIdList.header.docNo, '');
      this.employee = this.isNull(this.LeaveReqByIdList.header.employeeWsDTO, '');
      this.selectedEmployee = this.isNull(this.LeaveReqByIdList.header.employeeWsDTO, '');
      this.selectedEmployee = {employeeIdentity: {id: null, pmlId: null}};
      this.selectedEmployee.employeeIdentity.id = this.selectedEmployee.id;
      this.selectedEmployee.employeeIdentity.pmlId = this.selectedEmployee.pmlId;

      this.SubEmployee = this.LeaveReqByIdList.header.subsituteemployeeWsDTO;
      this.NewFieldsObject.AbsentDays = '',
          this.NewFieldsObject.Carrier = this.isNull(data.header.carrier, '');
      this.NewFieldsObject.City = this.isNull(data.header.city,'')
      this.NewFieldsObject.HouseNumber = this.isNull(data.header.housingNumber,'')
      this.NewFieldsObject.Reason = this.isNull(data.header.reason,'')
      this.NewFieldsObject.Region = this.isNull(data.header.region, '')
      this.NewFieldsObject.State = this.isNull(data.header.state, '')
      this.NewFieldsObject.Street = this.isNull(data.header.street, '')
      this.NewFieldsObject.SubEmployeeId = this.isNull(data.header.subsituteemployeeWsDTO?.id, '');
      this.NewFieldsObject.SubEmployeeNo = this.isNull(data.header.subsituteemployeeWsDTO?.empNo, '');
      this.NewFieldsObject.SubEmployeeName = this.isNull(data.header.subsituteemployeeWsDTO?.name, '');
      this.NewFieldsObject.TelephoneNumber = this.isNull(data.header.telephone, '')
      this.NewFieldsObject.TravelDate = this.isNull(data.header.travelDate, '')

      if(this.LeaveReqByIdList.details.length > 0){
        this.CurrentRow = this.LeaveReqByIdList.details.length
      }

      this.selectedEmployeeName = this.LeaveReqByIdList.header.employeeWsDTO.name;
      this.fetchedempNo = this.LeaveReqByIdList.header.employeeWsDTO.empNo;
      this.enablePassBtn = true;
      this.savedHeader = data.header.id;
      this.leaveRequestApplyDate = data.header.createdOn;
      this.fillLeaveRequestFormById(data)
      this.getLastLeaveRequestByDept()
      this.getOnLeaveByDept()
      this.getLastLeaveTakenByEmployee()
    })
  }

  isNull(Field, Alternate=null) {
    return Field? Field : Alternate;
  }

  fillLeaveRequestFormById(data: any) {
    this.DocumentNumber = data.header.docNo
    this.SelectedVoucher.code = data.header.voucherCode;
    this.currentLeaveRequest = data;
    //hRP-128
    this.leaveRequestHeaderForm.controls['emp_number'].setValue(data.header.employeeWsDTO.empNo);
    this.leaveRequestHeaderForm.controls['doj'].patchValue(this.fromJsonDate(data.header.employeeWsDTO.doj));
    this.leaveRequestHeaderForm.controls['emp_name'].patchValue(data.header.employeeWsDTO.name);
    this.leaveRequestHeaderForm.controls['leave_option'].patchValue('L05');
    this.leaveRequestHeaderForm.controls['job_title'].patchValue(data.header.employeeWsDTO.profession);
    this.leaveRequestHeaderForm.controls['department'].patchValue(data.header.employeeWsDTO.departement.description);
    this.leaveRequestHeaderForm.controls['grade'].patchValue(data.header.employeeWsDTO.grade);
    this.leaveRequestHeaderForm.controls['remarks'].patchValue(data.header.remarks);
    this.leaveRequestHeaderForm.controls['accumAbsentDays'].patchValue(data.header.employeeWsDTO.accumAbsentDays);
  }

  showLeavesRequests() {
    let Payload = {
      "docType" : this.SelectedVoucher.docType,
      "modsModule" : "PP",
      "fyerId"  : this.SelectedPeriod.FinancialYearID,
      "code" : this.SelectedVoucher.code,
      "compCode" : this.Session.CompanyCode
    }

    let PeriodId = this.SelectedPeriod.Id;

  //   this.leaveRequestService.generateDocumentCode(Payload, PeriodId)
  //   .subscribe(data => {this.DocumentNumber = data}
  //   ,error =>{
  //     this._toastr.error('Error in Generating Document Code')
  //   }
  // )
   if(this.SelectedVoucher.docType!==""){
    this.leaveRequestService.generateDocumentCode(Payload, PeriodId).subscribe(data => {
      if(this.id == 'null' || this.id == ''){
        this.DocumentNumber = data;
      }

    } ,error =>{
      this._toastr.error('Error in Generating Document Code')
    }
  )
  } else{
    this.loadVoucherCodes();
    this.apiService.getLeaveRequestVouchers(this.Session.CompanyCode, this.Session.FYearID).subscribe(
      (data:any[])=>{
        this.spinner.hide()
        this.VourcherList = data;
        this.SelectedVoucher = this.VourcherList[0];
        Payload = {
          "docType" : this.SelectedVoucher.docType,
          "modsModule" : "PP",
          "fyerId"  : this.SelectedPeriod.FinancialYearID,
          "code" : this.SelectedVoucher.code,
          "compCode" : this.Session.CompanyCode
        }

        this.leaveRequestService.generateDocumentCode(Payload, PeriodId).subscribe(data => {

          if(this.id == 'null' || this.id == ''){
            this.DocumentNumber = data;
          }
        } ,error =>{
          this._toastr.error('Error in Generating Document Code');
        }
      )
      },
      error=>
      {
        this.spinner.hide();
        this._toastr.error("Error in getting vouchers");
      }
    )
  }
if (this.SelectedPeriod && this.SelectedVoucher) {
  this.show = true;
}

  if (this.SelectedPeriod && this.SelectedVoucher) {
    this.show = true;
  }
  }

  LastLeaveReqList:any = []
  getLastLeaveRequestByDept(){
    let departmentCode = this.currentLeaveRequest?.header.employeeWsDTO.departement.code;
    let compCode = this.currentLeaveRequest?.header.employeeWsDTO.compCode;
    let currentEmpNo = this.currentLeaveRequest?.header.employeeWsDTO.empNo;
    this.leaveRequestService.getLastLeaveRequestByDept(compCode, departmentCode, currentEmpNo, '0', '1').subscribe(data => {
      this.LastLeaveReqList = data
    })
  }

  OnLeaveList:any = []
  getOnLeaveByDept(){
    this.leaveRequestService.getOnLeaveByDept('01', 'C09').subscribe(data => {
      this.OnLeaveList = data
    })
  }

  LastLeaveTakenList:any = []
  getLastLeaveTakenByEmployee(){
    this.leaveRequestService.getLastLeaveTakenByEmployee(this.currentEmployeeId).subscribe(data => {
      this.LastLeaveTakenList = data
    })
  }

  disableAddButton()
  {
    let LastDataObject:any = this.DataObject.Body[Object.keys(this.DataObject.Body)[Object.keys(this.DataObject.Body).length-1]]
      if( !LastDataObject?.LeaveType || !LastDataObject?.NoOfDays || !LastDataObject?.StartDate )
        return true
      return false;
  }

  setDateFromPeriod(event) {
    let ChangePeriod = event.target.value;
    this.SelectedPeriod = this.PeriodList.filter(
      (x) => x.Period == ChangePeriod
    )[0];
  }

  loadPeriods(Session: SessionObject) {
    if (!Session.BranchCode || !Session.CompanyCode || !Session.FYearID)
      return; // In case sessions doesn't hold values;

    this.spinner.show();
    this.apiService
      .getPeriodByCompanyAndBranch(
        Session.CompanyCode,
        Session.FYearID,
        Session.BranchCode
      )
      .subscribe(
        (data) => {
          this.spinner.hide();
          data.forEach((element) => {
            let month = element.period.length == 1? '0'+element.period: element.period
            if(month !=  moment().format('MM') )
              return
            this.PeriodList.push({
              Id: element.id,
              Period: element.period,
              FromDate: element.fromDate,
              ToDate: element.toDate,
              FinancialYearID: element.fyerId,
            });
          });
          this.PeriodList.sort((a, b) => {
            return Number.parseInt(a.Period) - Number.parseInt(b.Period);
          }); // Sorting the Periods
          this.SelectedPeriod = this.PeriodList[0];
         // if(this.localstorage.getLocalStorageData().glUserMastersDetailsDTO.roles.includes(SELF_SERVICE_USER_ROLE) || this.localstorage.getLocalStorageData().glUserMastersDetailsDTO.roles.includes(LINE_MANAGER_USER_ROLE))
          this.showLeavesRequests()
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  loadVoucherCodes()
  {
    this.spinner.show()
    this.apiService.getLeaveRequestVouchers(this.Session.CompanyCode, this.Session.FYearID).subscribe(
      (data:any[])=>{
        this.spinner.hide()
        this.VourcherList = data;
        this.SelectedVoucher = this.VourcherList[0];
      },
      error=>
      {
        this.spinner.hide();
        this._toastr.error("Error in getting vouchers");
      }
    )
  }

  searchChange($event) {
    let empNameOrNumber = $event;
    if(empNameOrNumber == "1") return
    if (empNameOrNumber !== '' && empNameOrNumber.replace(/\s/g, '').length) {
      if (!isNaN(Number(empNameOrNumber))) {
        this.employeeService.getByEmpNoOrName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      } else {
        this.employeeService.getByName(empNameOrNumber).subscribe(data => {
          this.allMappedEmployees = data.map(dataEmp => {
            return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
          });
        });
      }
    } else {
      if (this.allEmployees) {
        this.allMappedEmployees = this.allEmployees.map(dataEmp => {
          return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
        });
      }
    }
  }

  loadEmployees() {
    this.employeeService.getEmployeePage(this.page,this.pageSize).subscribe(data => {
      this.employees = data.content;
      this.allEmployees = data.content;
      this.count = data.totalPages;
      this.allMappedEmployees = this.employees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
    this.employeeService.getAll().subscribe(data => {
      this.allEmployees = data;
      this.allMappedEmployees = this.allEmployees.map(dataEmp => {
        return {name: dataEmp.empNo + '-' + dataEmp.name, value: dataEmp};
      });
    });
  }


  changeLeaveType(leaveType, even) {
    this.leaveRequestDetailsForm.controls['leave_type'].patchValue(leaveType.id);
  }

  loadVouchers() {
    this.voucherService.load().subscribe(data => {
      this.vouchers = data;
      if(this.vouchers.length == 0) {
        this._toastr.error('no  voucher found.')
        this.router.navigate(['leaves/list'])
      }
    });
  }

  disableLeaveTypeButton(leaveTypeId, i) {
    let BodyRows = Object.keys(this.DataObject.Body);
    let exisitngLeaveTypeIds = [];
    for(let i=0; i<BodyRows.length; i++)
      if (this.DataObject.Body[BodyRows[i]]['LeaveType'] )
        exisitngLeaveTypeIds.push(this.DataObject.Body[BodyRows[i]]['LeaveType'].id )

    return exisitngLeaveTypeIds.includes(leaveTypeId)

  }

  selectLeaveTypeOption(leaveType, leaveTypeModal) {
    this.DataObject.Body[this.CurrentRow.toString()]['LeaveType']= leaveType;
    this.currentLeaveType = leaveType;
    this.selectedLeavetype = leaveType;
    this.leaveRequestDetailsForm.controls['leave_type'].patchValue(leaveType.id);
    this.levBalDays = this.updateLevBalDays(leaveType);
    leaveTypeModal.close();
    this.checkValidity();
  }

  updateLevBalDays(leaveType): Number {
    if (leaveType.id === 1) {
      return this.employee?.levBalDays;
    }
    return leaveType.maxDaysAllow;
  }

  onEmployeeChange(employee) {
    if (employee?.value?.value) {
      this.selectedEmployee = employee.value.value;
      this.employeeService.getByEmpNo(this.selectedEmployee?.empNo).subscribe(data => {
        this.employee = data;

        this.fillLeaveRequestForm(data);
      });
    }
  }

  evaluateLeaveBalance(NumberOfDays, a) {
    this.canAddLeaveSectionRowFlag = false;
    if ((Number.parseInt(this.levBalDays.toString()) - this.DataObject.Body[a].NoOfDays) < 0) {
      this._toastr.error('insufficient Leave Balance');
      this.DataObject.Body[a.toString()]['NoOfDays'] = null;
      this.canAddLeaveSectionRowFlag = true;
    }
    if (this.DataObject.Body[a]?.NoOfDays != null && this.DataObject.Body[a]?.NoOfDays < 7 && this.DataObject.Body[a]?.LeaveType.id === 1) {
      this._toastr.error('The Minimum number of days should be at least 7 days');
      this.DataObject.Body[a.toString()]['NoOfDays'] = null;
      this.canAddLeaveSectionRowFlag = true;
    }
  }

  loadLeaveTypes() {
    this.leaveRequestService.getLeaveTypes().subscribe(data => {
      if (this.currentUserInformations.employee.gender === 'M') {
        this.leavetypes = data.filter(leavetype => leavetype.id !== 6);
      } else if (this.currentUserInformations.employee.gender === 'F') {
        this.leavetypes = data.filter(leavetype => leavetype.id !== 7);
      }
    });
  }

  loadHrCoordinatorEmployees() {
    this.employeeService.getEmployeesForCurrentHrCoordinator(this.Session.UserName , this.Session.CompanyCode).subscribe(data => {
      this.hrCoordinatorEmployees = data;
    })
  }


  changeLeaveTypeButton(leaveTypeModal, row) {
    this.CurrentRow = row;
    this.modalService.open(leaveTypeModal, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    }, (reason) => {

    });
  }

  createLeavePreparationForm(value: ILeavePreparation) {
    return this.formBuilder.group({
      leave_option: [value ? value.leave_option : 'L05'],
      reject_checkbox: [value ? value.reject_checkbox : null],
      pass_checkbox: [value ? value.pass_checkbox : null],
      confirm_checkbox: [value ? value.confirm_checkbox : null],
      emp_number: [value ? value.emp_number : null, Validators.required],
      emp_name: [ '', Validators.required],
      job_title: [value ? value.job_title : null, Validators.required],
      department: [value ? value.department : null, Validators.required],
      site: [value ? value.site : null, Validators.required],
      grade: [value ? value.grade : null, Validators.required],
      tkt: [value ? value.tkt : null, Validators.required],
      return_date: [value ? value.return_date : null, Validators.required],
      apply_date: [this.fromJsonDate(new Date()),  Validators.required],
      doj: [value ? value.doj : null, Validators.required],
      accumAbsentDays: [value ? value.accumAbsentDays : null],
      house_no: [value ? value.house_no : null],
      street: [value ? value.street : null],
      state: [value ? value.state : null],
      rej: [value ? value.rej : null],
      remarks: [value ? value.remarks : null],
      house_no1: [value ? value.house_no1 : null],
      street1: [value ? value.street1 : null],
      city: [value ? value.city : null],
      state1: [value ? value.state1 : null],
      country: [value ? value.country : null],
    });
  }

  createLeaveRequestDetailsForm(value : any) {
    return this.formBuilder.group({
      leave_type: [value ? value.leave_type : null],
      empty: [value ? value.empty : null],
      sfromdate: [new Date()],
      days: [value ? value.days : null],
      stodate: new FormControl(""),
      leave_type1: [value ? value.leave_type1 : null],
      empty1: [value ? value.empty1 : null],
      sfromDate1: [value ? value.sfromDate1 : null],
      days1: [value ? value.days1 : null],
      stoDate: [value ? value.stoDate : null],
      leave_type2: [value ? value.leave_type2 : null],
      empty2: [value ? value.empty2 : null],
      sfromDate2: [value ? value.sfromDate2 : null],
      days2: [value ? value.days2 : null],
      stoDate2: [value ? value.stoDate2 : null],
      leave_balance_days: [value ? value.leave_balance_days : null],
      request_leave: [value ? value.request_leave : null],
      balance_leave: [value ? value.balance_leave : null],

    });
  }

  private fromJsonDate(jDate): string {
    const bDate: Date = new Date(jDate);
    return bDate.toISOString().substring(0, 10);
  }

  private getDateFromPickerDate(pickerDate : any) : Date {
    let date = new Date(pickerDate.year, pickerDate.month -1, pickerDate.day, 0, 0, 0, 0);
    return date;
  }

  private getEmployee(empNo: number) {
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      if (data == null) {
      } else {
        this.employee = data;
        this.fillLeaveRequestForm(data);
      }
    });
  }

  onSubmit() {
  }

  loadEmployeeInfos() {
    this.checkValidity();
    let empNo = this.leaveRequestHeaderForm.controls['emp_number'].value;
    this.employeeService.getByEmpNo(empNo).subscribe(data => {
      this.employee = data;
      this.fillLeaveRequestForm(data);
    });
  }

  checkValidity() {
    if (
      this.leaveRequestHeaderForm.value.emp_number &&
      this.leaveRequestHeaderForm.value.emp_name &&
      this.leaveRequestHeaderForm.value.leave_option &&
      this.selectedLeavetype &&
      this.leaveRequestDetailsForm.value.days &&
      this.reqDetToDate
    ) {
      this.formValidity = true;
    } else {
      this.formValidity = false;
    }
  }

  saveLeaveRequest() {

    let leaveRequestHeader: ILeaveRequestHeader = this.createLeaveRequestHeader();
    let leaverequestDetails: ILeaveRequestDetails =  this.createLeaveRequestDetails();
    let leaveRequest : ILeaveRequest = new ILeaveRequest();
    leaveRequest.header = leaveRequestHeader;
    let details : ILeaveRequestDetails[] = [];
    details.push(leaverequestDetails);
    leaveRequest.details = details;
    this.leaveRequestService.saveLeaveRequest(leaveRequest).subscribe(
      (savedData) => {
        this._toastr.success("Leave Request has been saved");
     },
      (error) => {
        this._toastr.error("Error Occurred while saving leave request");
      });

  }

  private createLeaveRequestDetails() {
    let details: ILeaveRequestDetails = new ILeaveRequestDetails();
    let leaveType = this.leaveRequestDetailsForm.controls['leave_type'].value;
    let fromDate = this.leaveRequestDetailsForm.controls['sfromdate'].value;
    let days = this.leaveRequestDetailsForm.controls['days'].value;
    let toDate = this.leaveRequestDetailsForm.controls['stoDate'].value;

    details.fromDate = this.getDateFromPickerDate(fromDate);
    details.toDate = new Date(toDate);
    details.numberOfDays = days;
    details.leaveTypeId = leaveType;

    return details;
  }

  private createLeaveRequestHeader() {
    let leaverequestHeader: ILeaveRequestHeader = new ILeaveRequestHeader();
    let selectedVoucher = this.leaveRequestHeaderForm.controls['leave_option'].value;
    let applyDate = this.leaveRequestHeaderForm.controls['apply_date'].value;
    let remarks = this.leaveRequestHeaderForm.controls['remarks'].value;

    this.vouchers.forEach(voucher => {
      if (voucher.voucherIdentity.code == selectedVoucher) {
        leaverequestHeader.voucher = voucher;
      }
    });
    leaverequestHeader.employee = this.employee;
    leaverequestHeader.applyDate = applyDate;
    leaverequestHeader.remarks = remarks;
    return leaverequestHeader;
  }

  private fillLeaveRequestForm(data: any) {
    this.leaveRequestHeaderForm.controls['emp_number'].setValue(data.empNo);
    this.leaveRequestHeaderForm.controls['doj'].patchValue(this.fromJsonDate(data.doj));
    this.leaveRequestHeaderForm.controls['emp_name'].patchValue(data.name);
    this.leaveRequestHeaderForm.controls['leave_option'].patchValue('L05');
    this.leaveRequestHeaderForm.controls['job_title'].patchValue(data.profession);
    this.leaveRequestHeaderForm.controls['department'].patchValue(data.departement.description);
    this.leaveRequestHeaderForm.controls['grade'].patchValue(data.grade);
    this.leaveRequestHeaderForm.controls['accumAbsentDays'].patchValue(data.accumAbsentDays);
  }

  fillRequestDetailsToDate(){
    if (this.employee && this.employee.empNo) {
      let fromDate = this.leaveRequestDetailsForm.controls['sfromdate'].value;
      let days = this.leaveRequestDetailsForm.controls['days'].value;
      if(fromDate != null && days != null){
        let _fromDate = this.getDateFromPickerDate(fromDate);
        let toDate = new Date();
        toDate.setDate(_fromDate.getDate() + days);
        this.leaveRequestService.checkLeaveRequestDetailLineDates(_fromDate,toDate, this.employee.empNo).subscribe(valid =>{
          if(valid){
            this.leaveRequestDetailsForm.controls['stoDate'].setValue(this.fromJsonDate(toDate),{ emitEvent: true });
            this.reqDetToDate = this.fromJsonDate(toDate);
            this.cd.detectChanges()
          } else {
            this.leaveRequestDetailsForm.controls['sfromdate'].setErrors({'incorrect': true});
            this.reqDetToDate = '';
          }
        });
      }
    }

  }

  back(){
    if(this.source == 'list')
    this.router.navigate(['/leaves/list'])
    if(this.source == 'approval')
    this.router.navigate(['/leaves/leaveapprove'])
  }

  downloadLeaverequestFile(id : number , fileName : string) {
    this.leaveRequestService.downloadDocument(id).subscribe(data =>{
      const blob = new Blob([data]);
      if(window.navigator && window.navigator.msSaveOrOpenBlob){
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const value = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = value;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      setTimeout(function() {
        window.URL.revokeObjectURL(value);
        link.remove;
      },100);

    })
  }

  public get empNumber(): AbstractControl {
    return this.leaveRequestHeaderForm.get('emp_number');
  }

  public get empNumber1(): AbstractControl {
    return this.leaveRequestHeaderForm.get('emp_number1');
  }

  public get jobTitle(): AbstractControl {
    return this.leaveRequestHeaderForm.get('job_title');
  }

  public get department(): AbstractControl {
    return this.leaveRequestHeaderForm.get('department');
  }

  public get site(): AbstractControl {
    return this.leaveRequestHeaderForm.get('site');
  }

  public get grade(): AbstractControl {
    return this.leaveRequestHeaderForm.get('grade');
  }

  public get ticket(): AbstractControl {
    return this.leaveRequestHeaderForm.get('tkt');
  }

  public get getReturnDate(): AbstractControl {
    return this.leaveRequestHeaderForm.get('return_date');
  }

  public get getApplyDate(): AbstractControl {
    return this.leaveRequestHeaderForm.get('apply_date');
  }

  public get getDoj(): AbstractControl {
    return this.leaveRequestHeaderForm.get('doj');
  }

  private formatDate(date: string | null): string {
    return date ? DateUtils.get(date) : '';
  }

}
