<div class="card">
    <div class="card-body">
        <ng-template #modalContent let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Event action occurred</h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div> Action:
                    <pre>{{ modalData?.action }}</pre> </div>
                <div> Event:
                    <pre>{{ modalData?.event | json }}</pre> </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
            </div>
        </ng-template>
        <div class="row">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-success" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                        Previous </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate"> Today </div>
                    <div class="btn btn-info" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false">
                        Next </div>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-4 text-right">
                <div class="btn-group">
                    <div class="btn btn-info" (click)="view = 'month'" [class.active]="view === 'month'"> Month </div>
                    <div class="btn btn-success" (click)="view = 'week'" [class.active]="view === 'week'"> Week </div>
                    <div class="btn btn-warning" (click)="view = 'day'" [class.active]="view === 'day'"> Day </div>
                </div>
            </div>
        </div>
        <br>
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"> </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"> </mwl-calendar-day-view>
        </div>
    </div>
</div>